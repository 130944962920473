export const textColorByCode = (code: string) => {
    const colorsBase = {
        '#FFFFFF': '#000000',
        '#CFDFFF': '#2750AE',
        '#D0F0FD': '#0B76B7',
        '#C2F5E9': '#06A09B',
        '#D1F7C4': '#338A17',
        '#FFEAB6': '#B87503',
        '#FEE2D5': '#D74D26',
        '#FFDCE5': '#BA1E45',
        '#FFDAF6': '#B2158B',
        '#EDE2FE': '#6B1CB0',
        '#EEEEEE': '#444444',
        '#9CC7FF': '#2750AE',
        '#77D1F3': '#0B76B7',
        '#72DDC3': '#06A09B',
        '#93E088': '#338A17',
        '#FFD66E': '#B87503',
        '#FFA981': '#D74D26',
        '#FF9EB7': '#BA1E45',
        '#F99DE2': '#B2158B',
        '#CDB0FF': '#6B1CB0',
        '#CCCCCC': '#444444',
        '#2750AE': '#CFDFFF',
        '#18BFFF': '#D0F0FD',
        '#0ECAC3': '#C2F5E9',
        '#20C933': '#D1F7C4',
        '#FCB400': '#FFEAB6',
        '#FF6F2C': '#FEE2D5',
        '#F82B60': '#FFDCE5',
        '#FF08C2': '#FFDAF6',
        '#8B46FF': '#EDE2FE',
        '#666666': '#EEEEEE',
        '#0B76B7': '#D0F0FD',
        '#06A09B': '#C2F5E9',
        '#338A17': '#D1F7C4',
        '#B87503': '#FFEAB6',
        '#D74D26': '#FEE2D5',
        '#BA1E45': '#FFDCE5',
        '#B2158B': '#FFDAF6',
        '#6B1CB0': '#EDE2FE',
        '#444444': '#EEEEEE'
    };
    return colorsBase[code];
};