import React from 'react';
import styled from 'styled-components';
import { faSort } from '@fortawesome/pro-regular-svg-icons';
import { faSortUp, faSortDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { ESortTypes } from '../../../entities/AssetManagement/global';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.5rem;
    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    svg {
        cursor: pointer;
        color: ${colorStack.middleGrey};
    }
`;

const Icons: Record<ESortTypes, IconDefinition> = {
    [ESortTypes.None]: faSort,
    [ESortTypes.Ascending]: faSortUp,
    [ESortTypes.Descending]: faSortDown
};

const NextSort: Record<ESortTypes, ESortTypes> = {
    [ESortTypes.None]: ESortTypes.Ascending,
    [ESortTypes.Ascending]: ESortTypes.Descending,
    [ESortTypes.Descending]: ESortTypes.None
};

interface IHeaderCellProps {
    direction?: ESortTypes;
    headerText: string;
    onSort(order: ESortTypes);
}

export const HeaderCell = (props: IHeaderCellProps) => {
    return (
        <Wrapper>
            <span>{props.headerText}</span>
            {props.direction && <FontAwesomeIcon icon={Icons[props.direction]} onClick={() => props.onSort(NextSort[props.direction])} />}
        </Wrapper>
    );
};
