import React, { FC, useMemo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { sizes } from '../../../styleHelpers/breakpoint';
import { useSgContentAlignment } from '../../../tools/legalDocTools/sogeHooks';
import { SgAlignTo } from '../../../entities/LegalDoc/ISogeStyles';

const alignMargin = 20;

interface IWrapperProps {
    alignTo?: SgAlignTo;
    mobileOffsetPosition?: number;
}

const Wrapper = styled.div<IWrapperProps>`
    position: absolute;
    left: 0;
    background: ${colorStack.whiteGrey};
    min-width: 320px;
    ${boxShadow()};
    border-radius: 4px;
    max-height: 40vh;
    overflow: auto;
    z-index: 5;
    padding: 1rem;
    margin-top: 0.1rem;
    opacity: 0;
    ${({ alignTo, mobileOffsetPosition }) => !!alignTo && css`
        opacity: 1;

        ${alignTo === 'left' && css`
            left: 0;
            right: ${mobileOffsetPosition ? 0 : 'auto'};
        `}

        ${alignTo === 'right' && css`
            left: ${mobileOffsetPosition ? 0 : 'auto'};
            right: 0;
        `}

        ${mobileOffsetPosition && css`
            position: fixed;
            top: ${mobileOffsetPosition}px;
        `}
    `}
`;

interface IReferencePreviewPopupProps {
    className?: string;
}

export const ReferencePreviewPopup: FC<React.PropsWithChildren<IReferencePreviewPopupProps>> = ({ children, className = '' }) => {
    const { wrapperRef, alignTo, innerWidth } = useSgContentAlignment<HTMLDivElement>(alignMargin);
    const [fixedPositionOffsetOnMobile, setFixedPositionOffsetOnMobile] = useState<number>(undefined);

    useEffect(() => {
        const parentElement = wrapperRef.current?.parentElement;

        const calulateFixedPositionOnMobile = () => {
            if (innerWidth < sizes.tablet) {
                const parentRect = parentElement?.getBoundingClientRect();
                const offset = parentRect && parentRect.top + parentRect.height;
                typeof offset === 'number' && setFixedPositionOffsetOnMobile(offset);
            } else {
                setFixedPositionOffsetOnMobile(undefined);
            }
        };

        ['mouseover', 'touchstart'].forEach(eventName => parentElement?.addEventListener(eventName, calulateFixedPositionOnMobile));
        return () => {
            ['mouseover', 'touchstart'].forEach(eventName => parentElement?.removeEventListener(eventName, calulateFixedPositionOnMobile));
        };
    }, [innerWidth]);

    return (
        <Wrapper ref={wrapperRef} alignTo={alignTo} className={`${className} footnote-popup`} mobileOffsetPosition={fixedPositionOffsetOnMobile}>
            {children}
        </Wrapper>
    );
};
