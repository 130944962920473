import * as actionTypes from '../actions/actionTypes/companyTypes';
import { ICompany } from '../entities/ICompany';
import { IEntityOccurences } from '../entities/IEntityOccurences';
import { INews } from '../entities/INews';

export interface ICompanyReducer {
    company: ICompany;
    companyRequested: boolean;
    companyLoaded: boolean;
    companyExpertisesRequested: boolean;
    companyExpertisesLoaded: boolean;
    companyExpertises: IEntityOccurences[];
    publicCompanies: ICompany[];
    privateCompanies: ICompany[];
    filteredComapnies: ICompany[];
    allCompanies: ICompany[];
    publicCompaniesRequested: boolean;
    privateCompaniesRequested: boolean;
    allCompaniesRequested: boolean;
    isSuccesfulyCompanyAction: boolean;
    requestErrorMessage: string;
    organization: ICompany;
    organizationRequested: boolean;
    organizationLoaded: boolean;
    organizationExpertisesRequested: boolean;
    organizationExpertisesLoaded: boolean;
    organizationExpertises: IEntityOccurences[];
    publicOrganization: ICompany[];
    publicOrganizationRequested: boolean;
}

const defaultState = (): ICompanyReducer => ({
    company: undefined,
    companyRequested: false,
    companyLoaded: false,
    companyExpertisesRequested: false,
    companyExpertisesLoaded: false,
    companyExpertises: [],
    publicCompanies: [],
    privateCompanies: [],
    allCompanies: [],
    filteredComapnies: [],
    publicCompaniesRequested: false,
    privateCompaniesRequested: false,
    allCompaniesRequested: false,
    isSuccesfulyCompanyAction: true,
    requestErrorMessage: undefined,
    organization: undefined,
    organizationRequested: false,
    organizationLoaded: false,
    organizationExpertisesRequested: false,
    organizationExpertisesLoaded: false,
    organizationExpertises: [],
    publicOrganization: [],
    publicOrganizationRequested: false
});

export default (state = defaultState(), action): ICompanyReducer => {
    switch (action.type) {
        case actionTypes.GET_ORGANIZATION_REQUESTED: {
            return {
                ...state,
                organizationRequested: true
            };
        }

        case actionTypes.GET_ORGANIZATION_COMPLETED: {
            return {
                ...state,
                organizationRequested: false,
                organizationLoaded: true,
                organization: action.organization as ICompany
            };
        }

        case actionTypes.CLEAR_LOADED_ORGANIZATION: {
            return {
                ...state,
                organizationRequested: false,
                organizationLoaded: false,
                organization: undefined
            };
        }

        case actionTypes.GET_ORGANIZATION_EXPERTISES_REQUESTED: {
            return {
                ...state,
                organizationExpertisesRequested: true
            };
        }

        case actionTypes.GET_ORGANIZATION_EXPERTISES_COMPLETED: {
            return {
                ...state,
                organizationExpertisesRequested: false,
                organizationExpertisesLoaded: true,
                organizationExpertises: action.expertises as IEntityOccurences[]
            };
        }

        case actionTypes.GET_PUBLIC_ORGANIZATION_REQUESTED: {
            return {
                ...state,
                publicOrganizationRequested: true
            };
        }

        case actionTypes.GET_PUBLIC_ORGANIZATION_COMPLETED: {
            return {
                ...state,
                publicOrganizationRequested: false,
                publicOrganization: action.publicOrganization || []
            };
        }

        case actionTypes.GET_COMPANY_REQUESTED: {
            return {
                ...state,
                companyRequested: true
            };
        }

        case actionTypes.GET_COMPANY_COMPLETED: {
            return {
                ...state,
                companyRequested: false,
                companyLoaded: true,
                company: action.company as ICompany
            };
        }

        case actionTypes.CLEAR_LOADED_COMPANY: {
            return {
                ...state,
                companyExpertisesRequested: false,
                companyExpertisesLoaded: false,
                companyRequested: false,
                companyLoaded: false,
                company: undefined,
                companyExpertises: []
            };
        }

        case actionTypes.GET_COMPANY_EXPERTISES_REQUESTED: {
            return {
                ...state,
                companyExpertisesRequested: true
            };
        }

        case actionTypes.GET_COMPANY_EXPERTISES_COMPLETED: {
            return {
                ...state,
                companyExpertisesRequested: false,
                companyExpertisesLoaded: true,
                companyExpertises: action.expertises as IEntityOccurences[]
            };
        }

        case actionTypes.GET_PUBLIC_COMPANIES_REQUESTED: {
            return {
                ...state,
                publicCompaniesRequested: true
            };
        }

        case actionTypes.GET_PUBLIC_COMPANIES_COMPLETED: {
            return {
                ...state,
                publicCompaniesRequested: false,
                publicCompanies: action.publicCompanies || []
            };
        }

        case actionTypes.GET_PRIVATE_COMPANIES_REQUESTED: {
            return {
                ...state,
                privateCompaniesRequested: true
            };
        }

        case actionTypes.GET_PRIVATE_COMPANIES_COMPLETED: {
            return {
                ...state,
                privateCompaniesRequested: false,
                privateCompanies: action.privateCompanies || []
            };
        }

        case actionTypes.SUCCESSFULL_ACTION_EDIT_COMPANY: {
            return {
                ...state,
                isSuccesfulyCompanyAction: true
            };
        }

        case actionTypes.FAILED_ACTION_EDIT_COMPANY: {
            return {
                ...state,
                isSuccesfulyCompanyAction: false,
                requestErrorMessage: action.error
            };
        }

        case actionTypes.SUCCESSFULL_ACTION_CREATE_COMPANY: {
            return {
                ...state,
                company: action.company as ICompany,
                isSuccesfulyCompanyAction: true
            };
        }

        case actionTypes.FAILED_ACTION_CREATE_COMPANY: {
            return {
                ...state,
                isSuccesfulyCompanyAction: false,
                requestErrorMessage: action.error
            };
        }

        case actionTypes.SUCCESSFULL_ACTION_DELETE_COMPANY: {
            return {
                ...state,
                isSuccesfulyCompanyAction: true
            };
        }

        case actionTypes.FAILED_ACTION_DELETE_COMPANY: {
            return {
                ...state,
                isSuccesfulyCompanyAction: false,
                requestErrorMessage: action.error
            };
        }

        case actionTypes.GET_FILTERED_COMPANIES_COMPLETED: {
            return {
                ...state,
                filteredComapnies: action.filteredComapnies
            };
        }

        case actionTypes.GET_ALL_COMPANIES_COMPLETED: {
            return {
                ...state,
                allCompaniesRequested: true,
                allCompanies: action.allCompanies
            };
        }

        case actionTypes.GET_ALL_COMPANIES_REQUESTED: {
            return {
                ...state,
                allCompaniesRequested: true
            };
        }

        default: {
            return state;
        }
    }
};