import { faCalendar, faPipe, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import DatePicker, { DatePickerProps } from 'react-date-picker/dist/entry.nostyle';
import { DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Calendar from 'react-calendar';

import { ELcid } from '../../../entities/ILanguage';
import { IState } from '../../../reducers';
import { IProfileReducer } from '../../../reducers/profileReducer';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { languageList } from '../../../tools/languageTools';

const ClearWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: .9rem;
`;

const Wrapper = styled.div<{isRange: boolean}>`
    font-family: 'Roboto', sans-serif;
    width: 100%;
    ${props => props.isRange && css`
        height: 350px;
    `}
    .react-date-picker__calendar {
        &--closed {
            display: none;
        }
        &--open {
            display: block;
            margin: 3px 0 0 0;
        }
    }
    .react-daterange-picker__calendar {
        &--closed {
            display: none;
        }
        &--open {
            display: block;
            margin: 3px 0 0 0;
        }
    }
    .react-daterange-picker {
        &__range-divider {
            display: none;
        }
    }
    .custom-calendar-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        > div {
            border: none;
            display: flex;
            justify-content: space-between;
            height: 40px;
            padding: 0 13px;
            > div {
                width: 100%;
                cursor: pointer;
                ${props => !props.isRange && css`
                    opacity: 0;
                `}
                input, span {
                    cursor: pointer;
                }
            }
        }
        .react-date-picker {
            &__wrapper {
                button {
                    cursor: pointer;
                    &:nth-child(2) {
                        margin: 0 .9rem 0 auto;
                    }
                }
            }
        }
    }
    .react-calendar {
        background: ${colorStack.white};
        padding: 24px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        width: 328px;
        z-index: 999;
        border: none;
        position: relative;
        &__navigation{
            display: flex;
            padding: 0 1rem;
            height: 40px;
            justify-content: space-between;
            button {
                color: ${colorStack.middleBlue};
                ${fontSizeAndHeight[13]};
                cursor: pointer;
            }
            &__prev2-button {
                margin: 0 2rem 0 0;
            }
            &__next2-button {
                margin: 0 0 0 2rem;
            }
            &__label {
                ${fontSizeAndHeight[13]};
            }
        }
        &__tile {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            ${fontSizeAndHeight[13]};
            color: ${colorStack.content};
            &--active {
                background: ${colorStack.bodyBg};
                font-weight: 600;
                border-radius: 0px;
            }
            &--hasActive {
                background: ${colorStack.whiteBlue};
            }
            &--now {
                background: ${colorStack.whiteGrey};
            }
            &--rangeStart {
                background: ${colorStack.whiteBlue};
                border-radius: 8px 0 0 8px;
            }
            &--rangeEnd {
                background: ${colorStack.whiteBlue};
                border-radius: 0 8px 8px 0;
            }
            &--rangeBothEnds {
                background: ${colorStack.whiteBlue};
                border-radius: 8px 8px;
            }
        }
        &__decade-view {
            &__years {
                &__year {
                    ${fontSizeAndHeight[13]};
                    cursor: pointer;
                    &:hover {
                        border: 2px solid ${colorStack.middleBlue};
                        font-weight: 600;
                    }
                }
            }
        }
        &__year-view {
            &__months {
                &__month {
                    ${fontSizeAndHeight[13]};
                    cursor: pointer;
                    &:hover {
                        border: 2px solid ${colorStack.middleBlue};
                        font-weight: 600;
                    }
                }
            }
        }
        &__month-view {
            &__days {
                &__day {
                    cursor: pointer;
                    color: ${colorStack.content};
                    &--neighboringMonth {
                        color: ${colorStack.disabled};
                    }
                    &:hover {
                        border: 2px solid ${colorStack.middleBlue};
                        font-weight: 600;
                    }
                }
            }
            &__weekdays {
                margin: 0 0 0.8rem 0;
                &__weekday {
                    font-weight: 600;
                    ${fontSizeAndHeight[13]};
                    text-align: center;
                    cursor: default;
                    abbr {
                        text-decoration: none;
                        color: ${colorStack.content};
                    }
                }
            }
        }
    }
`;

type IProps = DatePickerProps & DateRangePickerProps & {
    noIcons?: boolean;
    rangePicker?: boolean;
    onChange(value: Value);
};

export const CustomCalendar: FC<React.PropsWithChildren<IProps>> = props => {
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    const datePickerProps = { // we need this, because in DatePicker module we have bug related to TS
        ...props,
        locale: languageList[currentUserProfile?.preferredLanguage] || languageList[ELcid.Fr]
    };
    return (
        <Wrapper isRange={props.rangePicker}>
            {props.rangePicker ? (
                <Calendar
                    {...props}
                    selectRange
                    data-lc="js-lc-calendarWrapper"
                    className="custom-calendar-wrapper"
                    locale={languageList[currentUserProfile?.preferredLanguage] || languageList[ELcid.Fr]}
                />
            ) : (
                <DatePicker
                    {...datePickerProps}
                    data-lc="js-lc-calendarWrapper"
                    calendarClassName="custom-calendar"
                    className="custom-calendar-wrapper"
                    calendarIcon={!props.noIcons && <FontAwesomeIcon icon={faCalendar} color={colorStack.darkGrey} />}
                    clearIcon={!props.noIcons && <ClearWrapper><FontAwesomeIcon icon={faXmark}  color={colorStack.darkGrey} /><FontAwesomeIcon icon={faPipe} color={colorStack.ligthGrey} /></ClearWrapper>}
                />
            )}
        </Wrapper>
    );
};