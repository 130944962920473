import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { IArticleAttachment, ISogeArticle } from '../../../../entities/ISoge';
import { getFileExt, extToMime } from '../../../../tools/clusterFilesystemTools';
import { findContentObjectById, parseStyleString } from '../../../../tools/legalDocTools/sogeContentTools';
import { ContentImage } from '../ContentImage';
import { FileIcon } from '../../../Organization/ClustersV2/ClusterDocuments/FileManager/FileIcon';

export const AttachmentWrapper = styled.button`
    position: relative;
    display: flex;
    align-items: baseline;
    margin: .5rem 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    > svg {
        margin: 0 .5rem 0 0;
    }
    &:hover {
        font-weight: 500;
    }
`;

export const DownloadContainer = styled.div`
    position: absolute;
    bottom: 0;
`;

interface IArticleAttachmentProps {
    attachment: IArticleAttachment;
    article: ISogeArticle;
    currentVersionId: string;
    onDownloadAttachment(fileId: string, ext: string);
}

export const ArticleAttachment: FC<React.PropsWithChildren<IArticleAttachmentProps>> = ({ attachment, article, currentVersionId, onDownloadAttachment }) => {
    const [hover, setHover] = useState<boolean>(false);
    const fileExt = useMemo(() => {
        return getFileExt(attachment.fileName);
    }, [attachment.fileName]);

    const onClick = useCallback(() => {
        onDownloadAttachment(attachment.fileId, fileExt.ext);
    }, [onDownloadAttachment, attachment.fileId, fileExt]);

    const vStyle = useMemo(() => {
        if (attachment?.contentObjectId && article) {
            const contentObject = findContentObjectById(article, attachment.contentObjectId);
            return parseStyleString(contentObject?.style?.parameters?.['v:shape.@style']);
        }
    }, [article?.id, attachment?.contentObjectId]);

    const onDocumentHoverHandler = useCallback((e) => {
        setHover(isHover => !isHover);
    }, []);

    return (
        <AttachmentWrapper onMouseEnter={onDocumentHoverHandler} onMouseLeave={onDocumentHoverHandler} onClick={onClick} data-diff-attachment data-ext={fileExt.ext} data-fallback={attachment.fileName}>
            {hover && (
                <DownloadContainer>
                    <FileIcon isCustomIcon mimeType={extToMime[fileExt.ext]} />
                </DownloadContainer>
            )}
            <ContentImage
                articleId={article.id}
                architextVersionId={article.architextLastVersion.id}
                fileId={attachment.iconFileId}
                currentVersionId={currentVersionId}
                vStyle={vStyle}
                fallback={attachment.fileName}
            />
        </AttachmentWrapper>
    );
};
