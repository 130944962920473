import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { DateAgoType } from './DateAgo';
import { DateType } from './Date';
import { PartyType } from './Party';

const Wrapper = styled.div`
    font-size: 16px;
    color: ${colorStack.content};
    margin-top: 4px;
    white-space: nowrap;
    max-width: 100%;

    > div {
        margin-bottom: 2px;
    }
    svg {
        padding: 4px 8px 4px 4px;
        color: ${colorStack.darkGrey};
        vertical-align: middle;
    }
`;

interface ILastEditedTypeProps {
    format?: 'datetime' | 'date' | 'relativeDate'
    user: {
        id: string;
        name: string;
        picture: string;
        lastName: string;
        firstName: string;
    };
    date: string;
    preventRedirection?: boolean;
}

export const LastEditedType = (props: ILastEditedTypeProps) => {
    return <Wrapper>
        <PartyType elements={[{...props.user, type: 'Personal'}]} preventRedirection={props.preventRedirection} />
        {props.date &&
            <>
                <FontAwesomeIcon icon={faClock} />&nbsp;{props.format === 'relativeDate' ? <DateAgoType>{props.date}</DateAgoType> : <DateType datetime={props.format === 'datetime'}>{props.date}</DateType>}
            </>
        }
    </Wrapper>;
};