import React, { Component } from 'react';
import queryString from 'query-string-for-all';
import { connect } from 'react-redux';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import 'intl';
import 'intl/locale-data/jsonp/en.js';
import * as loadjs from 'loadjs';

import { IState } from './reducers/index';
import { IConfigReducer } from './reducers/configReducer';
import { IContextReducer } from './reducers/contextReducer';
import * as configActions from './actions/configActions';
import * as contextActions from './actions/contextActions';
import { EMsalErrorCode } from './tools/authTools';
import { instanceConfig } from './instance';
import { GlobalLoader } from './components/GlobalLoader';
import { ShadowAuth } from './components/Auth/ShadowAuth';
import IntlProviderComponent from './IntlProvider';
import { SidebarTocProvider } from './components/Soge/SidebarTocContext/SidebarTocContext';
import { CustomRouter } from './tools/customRouter';
import { GlobalLayout } from './components/GlobalLayout';

type AppProps = typeof configActions & typeof contextActions & IConfigReducer & IContextReducer;

const { enableTracking } = instanceConfig;

// tslint:disable-next-line: no-var-requires
const reactAI = enableTracking && require('react-appinsights').reactAI;

interface AppState {
    errorMessage?: string;
    loaded?: boolean;
}

class App extends Component<AppProps, AppState> {
    state: AppState = {
        errorMessage: '',
        loaded: false
    };

    componentDidMount() {
        const isError = window.location.href.match('#error');
        const errorParams = queryString.parse(location.href.split('#')[1]);
        const errorMessage = errorParams && errorParams.error_description as string;
        const { PasswordResetRequested, PasswordResetCancelled } = EMsalErrorCode;
        if (!this.props.googlePlacesLoaded) {
            loadjs('https://maps.googleapis.com/maps/api/js?key=AIzaSyBCl8uUA0bTn1sn6QEn-OvQLkLYpcoITOA&libraries=places&callback=Function.prototype', () => {
                this.props.setGPlacesLoaded();
            });
        }

        if (isError && !errorMessage.match(PasswordResetRequested) && !errorMessage.match(PasswordResetCancelled)) {
            this.setState({
                errorMessage,
                loaded: true
            });
        } else {
            this.props.getApplicationConfig().then((config) => {
                this.setState({
                    loaded: true
                });
            });
        }
    }

    componentDidUpdate() {
        if (this.props.appConfig.appInsights && this.props.appConfig.appInsights.instrumentationKey && instanceConfig.enableTracking) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: this.props.appConfig.appInsights.instrumentationKey,
                    extensions: [reactAI],
                    disableTelemetry: !instanceConfig.enableTracking,
                    disableExceptionTracking: !instanceConfig.enableTracking,
                    extensionConfig: {
                        [reactAI.extensionId]: { debug: true },
                        [reactAI.extensionId]: { history: history }
                    }
                }
            });

            if (!appInsights.context) {
                appInsights.loadAppInsights();
            }
        }
    }

    render() {
        const { errorMessage, loaded } = this.state;
        if (loaded && errorMessage) {
            return (
                <div>
                    {errorMessage}
                </div>
            );
        }
        if (loaded && this.props.appConfigLoaded) {
            return (
                <IntlProviderComponent>
                    <ShadowAuth>
                        <SidebarTocProvider>
                            <GlobalLayout>
                                <CustomRouter />
                            </GlobalLayout>
                        </SidebarTocProvider>
                    </ShadowAuth>
                </IntlProviderComponent>
            );
        }

        return (
            <GlobalLoader isLoading />
        );
    }
}

export default connect(
    (state: IState) => ({
        ...state.config,
        ...state.context
    }),
    {
        ...configActions,
        ...contextActions
    }
)(App as any);
