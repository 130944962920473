import React, { FC } from 'react';

interface IProps {
    to: string;
    className?: string;
    title?: string;
    onClick?: any;
}

export const ExternalLink: FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <a href={props.to} rel="noopener noreferrer" target="_blank" className={props.className} title={props.title} onClick={props.onClick}>
            {props.children}
        </a>
    );
};