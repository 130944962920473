import styled from 'styled-components';

export const SavingWrapper = styled.div`
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    z-index: 9;
    > div {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 170px;
        height: 30px;
    }
`;