import { safeJSONParse } from './generalTools';
import { textContentFromHtmlString } from './string';

export interface IErrorResponse {
    response: {
        statusText: string;
        status: number;
        data: string;
    }
}

export const getErrorMessage = (error: any): string => { // "error" can be literally anything, this is not a bug
    const jsonData = typeof error === 'string' && safeJSONParse<IErrorResponse>(error);
    const extractedHtmlError = jsonData?.response?.data && textContentFromHtmlString(jsonData.response.data);
    const statusTextMessage = jsonData?.response?.statusText;
    const msg = extractedHtmlError || statusTextMessage || error?.data?.Message || error?.data?.message || error?.Message || error?.message || error?.data || error;

    try {
        return typeof msg === 'string' ? msg : JSON.stringify(msg);
    } catch (e) {
        return  `${msg}`;
    }
};

export const is404 = (error: string) => {
    try {
        const errorObject = JSON.parse(error) as IErrorResponse;
        return errorObject?.response?.status === 404;
    } catch (e) {
        return false;
    }
};

export const getErrorByCode = (errorCode: number, errors: Record<number, string>) => {
    const errorKey = Object.keys(errors || {}).find(key => parseInt(key, 10) === errorCode);
    return errors[errorKey];
};
