import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faFileContract, faSword, faCopyright, faGavel, faBolt, faCompassDrafting, faMoneyBillWave, faTrademark, faTruckField, faUser, faBagShopping, faBuildingFlag, faGift, faBook, faBuilding , faTicket, faQuestion, faLandmarkFlag, faComment, faBuildingColumns } from '@fortawesome/pro-regular-svg-icons';

import { colorStack } from '../../../../styleHelpers/colors';

const Square = styled.span`
    color: ${colorStack.darkBlue};
    display: flex;
    background-color: ${colorStack.lightBlue};
    height: 48px;
    width: 48px;
    border-radius: 8px;
    margin: 12px -1px;
    align-items: center;
    justify-content: center;
`;

const clusterIconMap = {
    contract: faFileContract,
    coi: faSword,
    corporate: faCopyright,
    dispute: faGavel,
    'no-link-coi-create': faSword,
    'distribution-mf-brand': faBagShopping,
    'franchise-mf-brand': faBuildingFlag,
    'gift-send': faGift,
    'gift-receive': faGift,
    'invitation-send': faTicket,
    'invitation-receive': faTicket,
    'legal-support': faQuestion,
    lobbying: faLandmarkFlag,
    negotiation: faComment,
    'patronage-v2-create': faBuildingColumns,
    'poa-create': faBolt,
    'quotation-create': faCompassDrafting,
    'sponsoring-v2-create': faMoneyBillWave,
    'trade-associations': faTrademark,
    'supply-mf-brand': faTruckField,
    'lease-lot': faBuilding,
    'knowledge-management':	faBook,
    basic: faUser
};

const getClusterIcon = (clusterType: string) => {
    if (clusterType.startsWith('contract-')) return clusterIconMap.contract;
    if (clusterType.startsWith('coi-')) return clusterIconMap.coi;
    if (clusterType.startsWith('corporate-')) return clusterIconMap.corporate;
    return clusterIconMap[clusterType] || clusterIconMap.basic;
};

interface IClusterIconTypeProps {
    clusterTemplate: string;
}

export const ClusterIconType = (props: IClusterIconTypeProps) =>
    <Square><FontAwesomeIcon icon={getClusterIcon(props.clusterTemplate)} size="2x" /></Square>
;
