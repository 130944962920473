import * as actionTypes from '../actions/actionTypes/languageTypes';
import { ILanguage, ELcid, IClusterMessageTranslation, ISingleLcidTranslations, ISingleTranslate } from '../entities/ILanguage';

export interface ILanguageReducer {
    userLanguage: ELcid;
    languageLoaded: boolean;
    managedLanguages: ILanguage[];
    clusterActivityTranslation: IClusterMessageTranslation;
    workspaceTranslations: {
        [key: string]: ISingleTranslate[]
    };
    translations: {
        [key in ELcid]?: ISingleLcidTranslations;
    }
}

const defaultState = (): ILanguageReducer => ({
    userLanguage: undefined,
    languageLoaded: false,
    managedLanguages: undefined,
    clusterActivityTranslation: undefined,
    translations: {},
    workspaceTranslations: {}
});

export default (state = defaultState(), action): ILanguageReducer => {
    switch (action.type) {
        case actionTypes.SET_USER_LANGUAGE: {
            return {
                ...state,
                userLanguage: action.lcid || ELcid.Fr
            };
        }

        case actionTypes.LOAD_NEW_LANG: {
            return {
                ...state,
                languageLoaded: false
            };
        }

        case actionTypes.FINISH_LOAD_NEW_LANG: {
            return {
                ...state,
                languageLoaded: true
            };
        }

        case actionTypes.SET_MANAGE_LANGUAGES: {
            return {
                ...state,
                managedLanguages: action.managedLanguages
            };
        }

        case actionTypes.SET_CLUSTER_TRANSLATION_MESSAGES: {
            return {
                ...state,
                clusterActivityTranslation: action.clusterActivityTranslation
            };
        }

        case actionTypes.SET_TRANSLATIONS: {
            const payload: actionTypes.ILanguageTypes['SET_TRANSLATIONS'] = action;
            return {
                ...state,
                translations: {
                    ...(state.translations || {}),
                    [payload.lcid]: payload.translations
                },
                languageLoaded: true
            };
        }
        case actionTypes.MERGE_TRANSLATIONS: {
            const payload: actionTypes.ILanguageTypes['MERGE_TRANSLATIONS'] = action;
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [payload.lcid]: {
                        ...state.translations[payload.lcid],
                        ...payload.translations
                    }
                }
            };
        }
        case actionTypes.SET_WORKSPACE_TRANSLATIONS: {
            const payload: actionTypes.ILanguageTypes['SET_WORKSPACE_TRANSLATIONS'] = action;
            return {
                ...state,
                workspaceTranslations: {
                    ...state.workspaceTranslations,
                    [payload.orgId]: payload.translations
                }
            };
        }

        default: {
            return state;
        }
    }
};
