import React, { FC } from 'react';
import styled from 'styled-components';

import { IValue } from '../../../entities/IPickers';
import { IPickerProps, CorePicker } from './CorePicker';

const Wrapper = styled.div``;

type IProps = IPickerProps & {
    staticData?: IValue[];
};

export const StaticDataPicker: FC<React.PropsWithChildren<IProps>> = props => {

    const getData = (search: string): Promise<IValue[]> => {
            return Promise.resolve(props.staticData.filter(elem => elem.text.trim().toLocaleLowerCase().includes(search.trim().toLocaleLowerCase())));
    };

    return (
        <Wrapper>
            <CorePicker {...props} getData={props.getData || getData} />
        </Wrapper>
    );
};
