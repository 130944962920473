import { Dispatch } from 'redux';
import queryString from 'query-string';

import { IState } from '../reducers';
import { Http } from '../tools/http';
import * as actionTypes from './actionTypes/profileTypes';
import { getOnboardingProfileScore } from './onboardingActions';
import { action } from '../tools/reduxTools';
import { IRelation, IFullUserProfile, IUserQuerySearchResult, ISingleMandeHolder, IUserRegistrationValidation } from '../entities/IProfile';
import { ICheckingMailbox } from '../entities/MailBox/ICheckingMailbox';
import { objectKeysToCamelCase } from '../tools/objectTools';
import { UserProfileBody } from '../entities/IDeclaration';
import { ISearchResponse, IUserBrief } from '../entities/IGlobal';
import { ISinglePoa } from '../entities/IPOA';
import { ISearch } from '../entities/ISearch';
import { ISearchResults } from '../entities/IFilters';
import * as actionSearchTypes from './actionTypes/searchTypes';
import { EUserPrivacy } from '../entities/IRoleManager';

export const getDirectoryUers = (search: string, page: number, size: number, filter: any) => action<Promise<ISearch>>((dispatch, getState, http: Http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({
        page: page,
        size: size,
        lcid: userLanguage
    });
    const body = {
        departments: filter.departments,
        teams: filter.teams,
        languages: filter.languages,
        barAdmissions: filter.barAdmissions,
        expertises: filter.expertises,
        competences: filter.competences,
        businessSectors: filter.businessSectors,
        countries: filter.countries,
        talents: filter.talents,
        roleFunctions: filter.roleFunctions,
        roleCategories: filter.roleCategories,
        firstReferentials: filter.firstReferentials,
        secondReferentials: filter.secondReferentials,
        thirdReferentials: filter.thirdReferentials,
        legalEntities: filter.cabinet,
        search
    };
    const url = `/directory-users/search?${query}`;
    return http.profilesApiPost(url, body)
        .then((searchResult: ISearch) => {
            dispatch({
                type: actionSearchTypes.GET_SEARCH_RESULT,
                searchResult: {
                    items: searchResult.items,
                    facets: searchResult.facets,
                    count: searchResult.count
                }
            });
            return searchResult;
        }).catch(err => {
            throw new Error(err);
        });
});

export const checkFinalRegistrationRule = () => action<Promise<any>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiGet('/users/final-registration/is-active');
});

export const getCurrentUserProfileNew = () => action<Promise<IFullUserProfile>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    dispatch({ type: actionTypes.GET_CURRENT_USER_PROFILE_REQUESTED });

    return http.profilesApiGet('/users/me/full').then((currentUserProfile: IFullUserProfile) => {
        dispatch({
            type: actionTypes.GET_CURRENT_USER_PROFILE_COMPLETED,
            currentUserProfile: objectKeysToCamelCase<IFullUserProfile>(currentUserProfile)
        });

        dispatch(getOnboardingProfileScore(objectKeysToCamelCase<IFullUserProfile>(currentUserProfile)));
        return currentUserProfile;
    });
});

export const getExploreDirectoryUsers = (page: number = 0, size: number = 48, body, loadSingleData: boolean) => action<Promise<ISearchResults>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({
        page: page,
        size: size,
        lcid: userLanguage
    });
    return http.profilesApiPost(`/directory-users/search?${query}`, body);
});

export const getProfileById = (id: string, lcid: number = 1033): Promise<IFullUserProfile> => ((dispatch: Dispatch, getState, http: Http) => {
    return http.profilesApiGet(`/users/${id}/full?lcid=${lcid}&getExternalProfileData=true`);
}) as any;

export const getUserProfileByQuery = (body: UserProfileBody, page: number = 0, size: number = 10) => action<Promise<IUserQuerySearchResult>, IState>((dispatch: Dispatch, getState, http: Http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({ page, size, lcid: userLanguage });

    return http.profilesApiPost(`/users/search?${query}`, body);
});

export const getProfilePublicInfo = (userId: string, lcid: number = 1033): Promise<IFullUserProfile> => ((dispatch: Dispatch, getState, http: Http) => {
    return http.apiGet(`/users/${userId}/public-info?lcid=${lcid}`);
}) as any;

export const getOtherUserPolicy = (userId: string = ''): Promise<any> => ((dispatch: Dispatch, getState, http: Http) => {
    return http.paymentApiGet(`/User/${userId}/policies`);
}) as any;

export const changePassword = (userB2Cid: string, password: string): Promise<string> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPatch(`/ChangeUserPassword`, {userB2CId: userB2Cid, newPassword: password});
}) as any;

export const accountExists = (email: string): Promise<boolean> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/AccountExists?emailAddres=${encodeURIComponent(email)}`);
}) as any;

export const isUserSuperAdmin = (): Promise<boolean> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet('/users/me/is-super-admin')
        .then(result => {
            dispatch({
                type: actionTypes.GET_USER_SUPER_ADMIN_PERMISSIONS,
                isSuperAdmin: result
            });

            return result;
        });
}) as any;

export const getMailboxUrls = () => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet('/mailbox/geturls')
        .then((result: ICheckingMailbox) => {
            dispatch({
                type: actionTypes.GET_MAILBOX_URLS,
                result
            });
            return result;
        });
}) as any;

export const getUnreadOutlookEmail = (email: string) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/mailbox/emails/count?emailAddress=${email}&read=false`)
        .then(result => {
            dispatch({
                type: actionTypes.GET_UNREAD_EMAILS,
                result
            });

            return result;
        });
}) as any;

export const addProfilePicture = (userId: string, file: Blob) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const form = new FormData();
    form.append('fileImg', file); // not a File instance so no encoding needed for the filename here

    return http.apiUpload(`/AddPictureToExpert?idExpertToEdit=${userId}`, form, undefined);
}) as any;

export const editProfile = (profile: IFullUserProfile): Promise<any> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPost(`/EditExpert`, profile);
}) as any;

export const getAllRoleCategories = (lcid: number = 1033) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/GetAllRoleCategories?lcid=${lcid}`);
}) as any;

export const updateRelationsBetweenEntities = (currentEmail: string, targetEmail: string, relations: IRelation[], lcid: number = 1033) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPost(`/UpdateRelationsBetweenEntities?clusterEmailAddressSource=${encodeURIComponent(currentEmail)}&clusterEmailAddressTarget=${encodeURIComponent(targetEmail)}&lcid=${lcid}`, relations);
}) as any;

export const getAllRelationsBetweenEntities = (currentEmail: string, targetEmail: string, lcid: number = 1033): Promise<IRelation[]> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPost(`/GetAllRelationsBetweenEntities?clusterEmailAddressSource=${encodeURIComponent(currentEmail)}&clusterEmailAddressTarget=${encodeURIComponent(targetEmail)}&lcid=${lcid}`);
}) as any;

export const getAllRelationsByCategory = (category: string, lcid: number = 1033): Promise<IRelation[]> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/GetAllRelationsByCategory?categoryEnum=${category}&lcid=${lcid}`);
}) as any;

export const getReferentialListByName = (referentialName: string, name: string, size: number = 10, page: number = 1, lcid: number = 1033) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/Get${referentialName}ListByName?${(!!name ? name.trim() !== '' : '') && `name=${encodeURIComponent(name)}&`}size=${size}&page=${page}&lcid=${lcid}`);
}) as any;

export const sendVerificationCode = (email: string) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiPost(`/users/me/settings/email/generate-code?email=${email}`);
}) as any;

export const checkUsedEmail = (email: string) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/users/me/settings/change-email/is-used?email=${email}`);
}) as any;

export const changeUserEmail = (oldEmail: string, newEmail: string, verificationKey: string) => action<Promise<{}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        oldEmail,
        newEmail,
        verificationKey
    };
    return http.profilesApiPut(`/users/me/settings/email`, body);
});

export const updateProfile = (userId: string, body: IFullUserProfile) => action<Promise<IFullUserProfile>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    body.firstName = body.firstName.trim();
    body.lastName = body.lastName.trim();
    return http.profilesApiPut(`/users/${userId}?lcid=${userLanguage}`, body);
});

export const getProfileMandates = (userId: string, page: number, timeout?: number) => action<Promise<ISearchResponse<ISingleMandeHolder>>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/users/${userId}/corporate-mandates?lcid=${userLanguage}&page=${page}&size=100`, undefined, undefined, timeout);
});

export const getMandatForExport = (userId: string, timeout?: number) => action<Promise<ISearchResponse<ISingleMandeHolder>>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    const body = {
        lcid: userLanguage
    };
    return http.profilesApiPost(`/users/${userId}/corporate-mandates/as-datatable?page=0&size=100`, body, undefined, timeout);
});

export const getProfilePoa = (userId: string, timeout?: number) => action<Promise<ISearchResponse<ISinglePoa>>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/users/${userId}/poa-mandates/as-delegated?lcid=${userLanguage}&size=999999`, undefined, undefined, timeout);
});

export const sendValidation = (userId: string, body: IUserRegistrationValidation) => action<Promise<ISearchResponse<ISinglePoa>>>((dispatch, getState, http) => {
    return http.profilesApiPut(`/users/${userId}/validate-registration`, body);
});

export const changeUserPrivacy = (userId: string, body: EUserPrivacy) => action<Promise<ISearchResponse<ISinglePoa>>>((dispatch, getState, http) => {
    return http.profilesApiPut(`/users/${userId}/privacy-settings`, {privacy: body});
});

export const checkUserInHrApi = (query: string) => action<Promise<ISearchResponse<IUserBrief>>>((dispatch, getState, http) => {
    return http.profilesApiGet(`/users/hr/search?query=${query}`);
});
