import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInfoSquare, faPen } from '@fortawesome/pro-light-svg-icons';
import { rgba } from 'polished';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';

const Wrapper = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.darkBlue};
    svg {
        margin-right: .5rem;
    }
`;

const DisclaimerWrapper = styled(Wrapper)`
    display: flex;
    align-items: center;
    width: 100%;
    order: 5;
    margin-top: .5rem;
    ${media.tablet`
        width: auto;
        order: 0;
        margin-top: 0;
    `}
`;

const Mode = styled(Wrapper)<{ editMode?: boolean }>`
    margin-right: .5rem;
    border-radius: 4px;
    display: inline-block;
    padding: .2rem .3rem;
    white-space: nowrap;
    ${({ editMode }) => editMode ? css`
        background: ${rgba(colorStack.darkBlue, .1)};
        color: ${colorStack.darkBlue};
    ` : css`
        background: ${colorStack.ligthGrey};
        color: ${colorStack.darkBlue};
    `}
`;

interface IDisclaimerProps {
    editMode?: boolean;
}

export const Disclaimer: FC<React.PropsWithChildren<IDisclaimerProps>> = ({ editMode }) => (
    <>
        <DisclaimerWrapper>
            <FormattedMessage id="norms.codeDisclaimer"  />
        </DisclaimerWrapper>
    </>
);
