import * as actionTypes from './actionTypes/cookiesTypes';

export const showCookiesAction = () => ({
    type: actionTypes.ACCEPT_COOKIES
});

export const hideCookiesAction = () => ({
    type: actionTypes.DECLINE_COOKIES
});

export const blockCookieAction = () => ({
    type: actionTypes.BLOCK_COOKIES
});

export const unblockCookieAction = () => ({
    type: actionTypes.UNBLOCK_COOKIES
});
