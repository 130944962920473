import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCodeBranch, faPen } from '@fortawesome/pro-light-svg-icons';

import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { Checkbox } from '../../../../Common/Inputs/Checkbox';

const BranchRowWrapper = styled.div`
    padding: 0.2rem .5rem;
    display: flex;
    align-items: center;
    font-size: ${fontSize[13]};
    white-space: nowrap;
    > span {
        margin: 0 .5rem;
        > svg {
            margin-right: .5rem;
        }
    }
`;

const CheckWrapper = styled.span`
    width: 20px;
    > div {
        height: 15px;
    }
`;

const InputWrapper = styled.span`
    display: flex;
    align-items: center;
    .lcr-form-block {
        margin-bottom: 0;
    }
`;

interface IBranchRowProps {
    versionName?: string;
    isSelected: boolean;
    branchId: string;
    isNewBranch?: boolean;
    editMode?: boolean;
    branchName: string;
    onSelect?(branchId: string, isSelected: boolean): void;
    onEditClick?(): void;
}

export const BranchRow: FC<React.PropsWithChildren<IBranchRowProps>> = ({ versionName, isSelected, branchId, isNewBranch, editMode, children, branchName, onSelect, onEditClick }) => {
    const onSelectClick = useCallback(() => {
        onSelect?.(branchId, isSelected);
    }, [onSelect, branchId, isSelected]);

    return (
        <BranchRowWrapper>
            <CheckWrapper>
                <Checkbox checked={isSelected} onChange={onSelectClick} />
            </CheckWrapper>
            {versionName &&
                <span>
                    <FontAwesomeIcon icon={faBookmark} />
                    {versionName}
                </span>
            }
            <InputWrapper>
                <FontAwesomeIcon icon={faCodeBranch} />
                {isNewBranch && editMode ? (
                    children
                ) : (
                    <strong>{branchName}</strong>
                )}
            </InputWrapper>
            <span>
                {isNewBranch && !editMode && (
                    <Button leftIco={faPen} typeSchema={EButtonTypeSchema.TEXT} onClick={onEditClick}>
                        <FormattedMessage id="global.edit"  />
                    </Button>
                )}
            </span>
        </BranchRowWrapper>
    );
};
