import React, { FC } from 'react';
import styled from 'styled-components';

import { EProfileType } from '../../../../entities/IGlobal';
import { Avatar } from '../../Avatar/Avatar';
import { Badge, EBadgeColor, EBadgeShape, EBadgeSize } from '../../Badge/Badge';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

interface IProps {
    elements: {
        id: string;
        picture: string;
        name: string;
        lastName: string;
        firstName: string;
        type: 'LegalEntity' | 'Personal';
    }[];
    preventRedirection?: boolean;
}

export const PartyType: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper>
            {props.elements?.length > 0 &&
                <Avatar
                    picture={props.elements?.[0]?.picture}
                    firstName={props.elements?.[0]?.name || `${props.elements?.[0]?.firstName || ''} ${props.elements?.[0]?.lastName || ''}`}
                    lastName=""
                    id={props.elements?.[0]?.id}
                    type={props.elements?.[0]?.type === 'LegalEntity' ? EProfileType.Company : props.elements?.[0]?.type === 'Personal' ? EProfileType.Personal : props.elements?.[0]?.type}
                    extended
                    preventRedirection={props.preventRedirection}
                />
            }
            {props.elements?.length > 1 &&
                <Badge color={EBadgeColor.GRAY} size={EBadgeSize.SMALL} shape={EBadgeShape.CIRCLE} placeholder={`+${props.elements?.length < 100 ? props.elements?.length - 1 : 99}`} />
            }
        </Wrapper>
    );
};