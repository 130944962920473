import React, { FC, useMemo, ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { faListOl, faAlignLeft, faClipboardCheck, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import { EBranchStatus, ERelatedItemType, EBranchNotificationType, IFullBranch } from '../../../entities/ISoge';
import { EAlertType } from '../../../entities/IAlert';
import { INotification } from '../../../entities/INotification';
import { EAmendmentStep } from '../Amendments/Steps/ISteps';
import { IState } from '../../../reducers';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { updateBranchDescription } from '../../../actions/sogeActions';
import { getSogeAmendmentUrl } from '../../../tools/legalDocTools/sogeTools';
import { useOnlyBranchContent } from '../../../tools/legalDocTools/sogeHooks';
import { useAlert } from '../../../tools/hooks';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { LinkButton } from '../../Common/Buttons/Button';
import { Button } from '../../Common/Buttons/NewButton';
import { Switch } from '../../Common/Switch/Switch';
import { DropdownMenu } from '../../Common/Buttons/DropdownMenu';

const HeaderRow = styled.div`
    display: flex;
    align-items: center;
`;

const MinorChange = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    > span {
        font-size: ${fontSize[13]};
        margin-right: .5rem;
    }
`;

const CheckIcon = styled(FontAwesomeIcon)`
    color: ${colorStack.middleGreen};
    position: absolute;
    right: 0.6rem;
    font-size: ${fontSize[16]};
`;

const StyledLink = styled(LinkButton) <{ successBg?: boolean }>`
    ${({ successBg }) => successBg && css`
        position: relative;
    `}
`;

const ButtonsWrapper = styled.div`
    margin: 1rem -.5rem;
    display: flex;
`;

interface IApprovalButton {
    color: string;
    text: ReactNode;
}

interface IBranchActionsProps {
    organizationUrlName: string;
    versionId: string;
    documentElementCommonId: string;
    branchId: string;
    branch: IFullBranch;
    notificationObject?: INotification;
    showRequestPicker();
    showBranchPicker();
    onBranchAbandon();
    onReactivateClick();
    onMinorChangeChanged(isMinorChange: boolean): void;
}

export const BranchActions: FC<React.PropsWithChildren<IBranchActionsProps>> = ({ organizationUrlName, versionId, documentElementCommonId, branchId, branch, notificationObject, showRequestPicker, showBranchPicker, onBranchAbandon, onReactivateClick, onMinorChangeChanged }) => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const history = useNavigate();
    const { documentationId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const { branchContent } = useOnlyBranchContent(documentationId, versionId, documentElementCommonId, branch?.id);
    const hasAmendment = !!branch?.amendmentRequestId || (!!notificationObject?.data?.ObjectId && notificationObject?.key === EBranchNotificationType?.AmendmentRequestUploadSucceeded);
    const hasMetadata = !!branch?.textStatus;
    const hasPullRequests = !!branch?.pullRequestIds?.length;
    const branchStatus = branch?.status;

    const approvalBtn: IApprovalButton = useMemo(() => {
        if (branchStatus === EBranchStatus.WaitingForApproval && hasPullRequests) return {
            color: colorStack.darkOrange,
            text: <FormattedMessage id="norms.status.waitingForApproval" />
        };
        if (branchStatus === EBranchStatus.Rejected && hasPullRequests) return {
            color: colorStack.middleRed,
            text: <FormattedMessage id="norms.status.rejected" />
        };
        if (branchStatus === EBranchStatus.Rejected && !hasPullRequests) return {
            color: colorStack.middleRed,
            text: <FormattedMessage id="norms.status.rejected" />
        };
        if (branchStatus === EBranchStatus.Approved) return {
            color: colorStack.middleGreen,
            text: <FormattedMessage id="norms.status.approved" />
        };
    }, [hasPullRequests, branchStatus]);

    const metadataUrl = useMemo(() => {
        if (!branch) {
            return '';
        }

        return branch.relatedItemType === ERelatedItemType.Article
            ? `/orgs/${organizationUrlName}/documentation/versions/${versionId}/branches/${documentElementCommonId}/${branchId}/article/${branch?.relatedItemCrossId}`
            : `/orgs/${organizationUrlName}/documentation/versions/${versionId}/branches/${documentElementCommonId}/${branchId}/structure-level/${branch?.relatedItemCrossId}`;
    }, [organizationUrlName, versionId, branchId, documentElementCommonId, branch]);

    const amendmentLink = useMemo(() => {
        return getSogeAmendmentUrl(
            organizationUrlName,
            hasAmendment && EAmendmentStep.Commit,
            !hasAmendment && { branchId: branch.id, crossId: branch.relatedItemCrossId, crossType: branch.relatedItemType, versionId },
            hasAmendment && (branch?.amendmentRequestId || notificationObject?.data?.ObjectId)
        );
    }, [hasAmendment, organizationUrlName, branch?.id, branch?.relatedItemCrossId, branch?.amendmentRequestId, versionId, notificationObject?.data?.ObjectId]);

    const onGoToAmendmentClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        history(amendmentLink);
    }, [hasAmendment, amendmentLink, branchContent]);

    const onChangeMinorChange = useCallback((value: boolean) => {
        onMinorChangeChanged(value);
        dispatch(updateBranchDescription(documentationId, versionId, branch.id, branch.lcid, branch.description, value))
            .catch((error) => {
                addAlert(
                    <FormattedMessage
                        id="norms.error.somethingWentWrong"
                        values={{ error }}
                    />,
                    EAlertType.ERROR
                );
            });
    }, [documentationId, versionId, branch, onMinorChangeChanged]);

    return (
        <>
            <HeaderRow>
                <strong><FormattedMessage id="norms.whatDoYouWantToModify" /></strong>
                <MinorChange>
                    <FormattedMessage id="norms.minorChange" />
                    <Switch
                        state={branch.isMinorChange}
                        changeHandler={onChangeMinorChange}
                    />
                </MinorChange>
            </HeaderRow>
            <ButtonsWrapper>
                <StyledLink
                    dataLc="js-lc-button-branch-amendment"
                    to={amendmentLink}
                    ico={faAlignLeft}
                    rightIco={hasAmendment && faCheck}
                    borderColor={colorStack.darkBlue} fontColor={hasAmendment ? colorStack.middleGreen : colorStack.darkBlue}
                    bgColor={colorStack.ligthGrey}
                    successBg={hasAmendment}
                    onClick={onGoToAmendmentClick}
                >
                    <FormattedMessage id="norms.label.amendment" />
                </StyledLink>
                <StyledLink
                    dataLc="js-lc-button-branch-metadata"
                    to={metadataUrl}
                    ico={faTag}
                    borderColor={colorStack.darkBlue} fontColor={hasMetadata ? colorStack.middleGreen : colorStack.darkBlue}
                    bgColor={colorStack.ligthGrey}
                    successBg={hasMetadata}
                >
                    <FormattedMessage id="global.metadata" />
                    {hasMetadata && <CheckIcon icon={faCheck} />}
                </StyledLink>
                <StyledLink dataLc="js-lc-button-branch-structure" to={`/orgs/${organizationUrlName}/documentation/versions/${versionId}/branches/${documentElementCommonId}/${branchId}/manage-structure`} ico={faListOl} bgColor={colorStack.white} borderColor={colorStack.darkBlue} fontColor={colorStack.darkBlue}>
                    <span>Structure</span>
                </StyledLink>
                <DropdownMenu
                    button={<Button leftIco={faClipboardCheck}>{approvalBtn?.text || <FormattedMessage id="norms.label.approval" />}</Button>}
                    links={[
                        { name: <FormattedMessage id="norms.button.startApproval" />, action: showBranchPicker, visible: !hasPullRequests && branchStatus === EBranchStatus.Active },
                        { name: <FormattedMessage id="norms.button.goToApproval" />, action: showRequestPicker, visible: (hasPullRequests && branchStatus === EBranchStatus.Active) || branchStatus === EBranchStatus.Approved || (hasPullRequests && branchStatus === EBranchStatus.Rejected) || (hasPullRequests && branchStatus === EBranchStatus.WaitingForApproval) },
                        { name: <FormattedMessage id="norms.status.reject" />, action: onBranchAbandon, visible: branchStatus === EBranchStatus.Active && !hasPullRequests },
                        { name: <FormattedMessage id="norms.label.reactivate" />, action: onReactivateClick, visible: branchStatus === EBranchStatus.Rejected && !hasPullRequests }
                    ]}
                />
            </ButtonsWrapper>
        </>
    );
};
