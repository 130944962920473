import React, { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getReferentials } from '../../../../actions/globalActions';
import { EConditionScopeType } from '../../../../entities/AssetManagement/global';
import { EContextList, EReferenceType, EReferentials } from '../../../../entities/IGlobal';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { modalsShadow } from '../../../../styleHelpers/mixins/shadow';
import { useOrganization } from '../../../../tools/organizationHooks';
import { EPillColorSchema, Pill } from '../../Pill/Pill';
import { MultilineTextWithEllipsis } from '../../TextWithEllipsis/MultilineTextWithEllipsis';
import { currencySymbol as getCurrencySymbol} from '../../../../tools/MapCurrency';
import { findRefByUniqueKey } from '../../../../tools/referentials';

type GetReferentials = ReturnType<typeof getReferentials>;

const WrapperForPill = styled.div`
    display: flex;
    align-items: center;
`;

const More = styled.div`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.middleBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > span {
        font-weight: 700;
    }
    &:hover {
        > div {
            display: flex;
        }
    }
`;

const InnerTooltip = styled.div`
    background: ${colorStack.white};
    border-radius: 4px;
    ${modalsShadow()};
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    display: flex;
`;

const Tooltip = styled.div`
    position: absolute;
    display: none;
    top: 40%;
    z-index: 10;
    left: 0;
    width: 700px;
`;

const LeftMockMargin = styled.div`
    width: 40px;
    height: 100%;
`;

const TagsList = styled.div`
    max-width: 100%;
    display: flex;
    gap: 8px;
`;

interface IPillElement {
    displayName: string;
    id: string;
    referenceType: string;
    scopeName: string;
    value: number;
    scope?: {
        scopeId: string;
        scopeType: EConditionScopeType;
    }
}

interface IProps {
    elements: IPillElement[];
}

export const PillType: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);
    const [currencySymbol, setCurrencySymbol] = useState<string>(undefined);

    useEffect(() => {
        if (currentOrganization?.details?.defaultCurrency?.uniqueKey) {
            const { uniqueKey } = currentOrganization.details.defaultCurrency;

            dispatch<GetReferentials>(getReferentials('', EReferentials.Currency, EContextList.Platform)).then((referentials) => {
                const referential = findRefByUniqueKey(uniqueKey, referentials);

                if (referential) {
                    setCurrencySymbol(getCurrencySymbol(referential.name));
                }
            });
        }
    }, [currentOrganization?.details?.defaultCurrency]);

    let getConditionBgColor = (elem: IPillElement) => {
        if (elem.referenceType === 'Tag') {
            return EPillColorSchema.YELLOW;
        }

        if (elem.referenceType === 'LegalEntity' || elem.scope?.scopeType === EConditionScopeType.InvestmentVehicle) {
            return EPillColorSchema.RED;
        }

        if (elem.referenceType === 'ScopedCondition' || elem.referenceType === 'TextCondition' || elem.referenceType === 'ComparisionCondition') {
            return EPillColorSchema.GREEN;
        }
    };

    let getConditionLink = (elem: IPillElement): string | undefined => {
        let id: string;

        if (elem.scope?.scopeType === EConditionScopeType.InvestmentVehicle || elem.scope?.scopeType === EConditionScopeType.Investor) {
            id = elem.scope.scopeId;
        } else if (elem.referenceType === EReferenceType.LegalEntity) {
            id = elem.id;
        }

        if (id) {
            return `/company/${id}`;
        }

        return undefined;
    };

    const getCellText = () => {
        const firstElement = props.elements?.[0];

        if (firstElement?.referenceType === 'ComparisionCondition') {
            return `${props.elements?.[0]?.displayName} ${props.elements?.[0]?.value} M${currencySymbol}`;
        } else {
            return firstElement?.referenceType === 'ScopedCondition' && firstElement?.scopeName && firstElement.scopeName !== firstElement.displayName
                ? `${firstElement.displayName} ${firstElement.scopeName}`
                : firstElement?.displayName;
        }
    };

    return (
        <WrapperForPill>
            <span>
                <MultilineTextWithEllipsis numberOfLines={2}>{getCellText()}</MultilineTextWithEllipsis>
            </span>
            {props.elements?.length > 1 &&
                <More>
                    <span>&nbsp;+{props.elements?.length - 1}</span>
                    <Tooltip>
                        <LeftMockMargin />
                        <InnerTooltip>
                            <Scrollbars autoHide autoHeight autoHeightMax={130}>
                                <TagsList>
                                    {props.elements.map((elem, index) =>
                                        <>
                                            {elem.referenceType === 'ComparisionCondition' ? (
                                                <Pill
                                                    key={`${elem.id}-${index}`}
                                                    colorSchema={getConditionBgColor(elem)}
                                                    value={`${elem.value.toString()} M${currencySymbol}`}
                                                    text={elem.displayName}
                                                    id={elem.id}
                                                />
                                            ) : (
                                                <Pill
                                                    colorSchema={getConditionBgColor(elem)}
                                                    key={`${elem.id}-${index}`}
                                                    text={elem.referenceType === 'ScopedCondition' && elem.scope?.scopeType !== EConditionScopeType.InvestmentVehicle ? `${elem.displayName} ${elem.scopeName}` : elem.displayName}
                                                    link={getConditionLink(elem)}
                                                    id={elem.id}
                                                />
                                            )}
                                        </>
                                    )}
                                </TagsList>
                            </Scrollbars>
                        </InnerTooltip>
                    </Tooltip>
                </More>
            }
        </WrapperForPill>
    );
};
