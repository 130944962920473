import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { cardsShadow } from '../../../styleHelpers/mixins/shadow';
import { colorStack } from '../../../styleHelpers/colors';
import { ISelectInputProps, SelectInput } from '../SelectInput/SelectInput';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';

const Wrapper = styled.div<{disabled?: boolean}>`
    .ms-Dropdown {
        font-family: 'Roboto', sans-serif;
        .ms-Dropdown-title {
            font-family: 'Roboto', sans-serif;
            border: none;
            ${cardsShadow()};
            &:hover {
                border-bottom: 2px solid ${colorStack.middleBlue};
            }
        }
    }
    ${props => props.disabled && css`
        .ms-Dropdown-title {
            background: ${colorStack.ligthGrey};
            color: ${colorStack.disabled};
        }
    `}
`;

interface IProps extends ISelectInputProps {
    testingDataAttr?: string;
}

export const NewDesignDropdown: FC<React.PropsWithChildren<IProps>> = props => {
    const { inputLabel, required, ...restProps } = props;
    return (
        <Wrapper disabled={props.disabled}>
            {inputLabel &&
                <LabelWithEllipsis tooltipMsg={props.tooltipMsg} tooltipTitle={props.tooltipTitle}>
                    {inputLabel}
                    {required &&
                        <MandatoryFieldStar />
                    }
                </LabelWithEllipsis>
            }
            <SelectInput {...restProps}/>
        </Wrapper>
    );
};