import styled, { css } from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';

interface WrapperProps {
    isActive?: boolean;
    notificationPage?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid ${colorStack.ligthGrey};
    background-color: transparent;
    ${props => props.isActive && css`
        background-color: ${colorStack.white};
    `};
    ${props => props.notificationPage && css`
        border-bottom: 0;
        padding-top: 0;
    `}
`;

export const Image = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
`;

export const Badge = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: -6px;
    border-radius: 100%;
    &.P2PInvitation{
        background: url('/assets/images/icons/invite.png') no-repeat center;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    width: calc(100% - 150px);
`;

export const Text = styled.div`
    color: ${colorStack.black};
    font-size: ${fontSize[13]};
    font-weight: 400;
    padding: 0 30px 0 0;
`;

export const Metas = styled.div`
    color: ${colorStack.disabled};
    font-size: ${fontSize[10]};
    margin: 5px 0 0 0;
`;

export const P2PSection = styled.div`
    display: flex;
    .accept{
        background: ${colorStack.lightBlue};
        border: 1px solid ${colorStack.blue};
        border-radius: 100%;
        cursor: pointer;
        width: 40px;
        height: 40px;
        position: relative;
        &:before{
            content: '';
            display: block;
            background: url('/assets/images/icons/accept.png') no-repeat right;
            position: absolute;
            top:0;
            bottom:0;
            left:0;
            right: 0;
            width: 14px;
            height: 10px;
            margin: auto;
        }
    }
    .decline{
        border-radius: 100%;
        background: ${colorStack.ligthGrey};
        border: 1px solid ${colorStack.middleGrey};
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin: 0 10px 0 0;
        position: relative;
        &:before{
            content: '';
            display: block;
            background: url('/assets/images/icons/decline.png') no-repeat right;
            position: absolute;
            top:0;
            bottom:0;
            left:0;
            right: 0;
            width: 12px;
            height: 12px;
            margin: auto;
        }
    }
`;

export const SmallDot = styled.div`
    border-radius: 100%;
    width: 8px;
    height: 8px;
    align-self: self-end;
    margin-top: 7px;
    background-color: ${colorStack.lightBlue};
`;

export const DisabledButton = styled.button`
    opacity : ${props => props.disabled ? 0.5 : 1};
`;

export const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 7px;
    max-width: 97%;
`;
