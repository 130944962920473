import * as actionTypes from '../actions/actionTypes/legalEntitiesTypes';
import { ILegalEntityDisplay, ISearchLegalEntitiesValue } from '../entities/ILegalEntities';

export interface ILegalEntitiesReducer {
    legalEntitiesList: ILegalEntityDisplay[];
    legalEntitiesFacets: { [key: string]: { value: { Name: string; id: string } }[] };
    legalEntitiesCount: number;
    legalEntitiesListLoaded: boolean;
}

const defaultState = (): ILegalEntitiesReducer => ({
    legalEntitiesList: undefined,
    legalEntitiesFacets: undefined,
    legalEntitiesCount: undefined,
    legalEntitiesListLoaded: false
});

export default (state = defaultState(), action): ILegalEntitiesReducer => {
    switch (action.type) {
        case actionTypes.GET_LEGAL_ENTITIES: {
            return {
                ...state,
                legalEntitiesList: action.legalEntitiesResult.list,
                legalEntitiesFacets: action.legalEntitiesResult.facets,
                legalEntitiesCount: action.legalEntitiesResult.count,
                legalEntitiesListLoaded: true
            };
        }
        case actionTypes.CLEAR_RESULTS: {
            return {
                ...state,
                legalEntitiesList: undefined,
                legalEntitiesFacets: undefined,
                legalEntitiesCount: undefined,
                legalEntitiesListLoaded: false
            };
        }
        default: {
            return state;
        }
    }
};