import React from 'react';
import { useIntl } from 'react-intl';

interface SuccessfulSubmissionProps {

}

export const SuccessfulSubmission = (props: SuccessfulSubmissionProps) => {
    const intl = useIntl();
    return (
        <div>
            <span dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'giftinvitation.success.great' })
            }} />
            <span dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'giftinvitation.success.paragraph' })
            }} />
        </div>
    );
};