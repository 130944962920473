import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { cardsShadow, headersShadow } from '../../../styleHelpers/mixins/shadow';

const Card = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    overflow: hidden;
    transition: all .2s;
    position: relative;
    span {
        margin-top: 30px;
    }
    &:hover {
        cursor: pointer;
    }
`;

const CardContainer = styled.div`
    position: relative;
    width: 100%;
    height: inherit;
`;

const LabelWrapper = styled.div`
    height: 100%;
    label {
        height: inherit;
        width: 100%;
    }
`;

const Wrapper = styled.div<{ maxSize?: number }>`
    width: 48%;
    max-width: 300px;
    border-radius: 8px;
    ${headersShadow()};
    background: ${colorStack.white};
    transition: all .3s;
    &:hover {
        ${cardsShadow()};
    }
    ${(props) =>
        props.maxSize &&
        css`
            max-width:${`${props.maxSize}px`};
        `}
    input[type="radio"] {
        display: none;
    }
    input:checked + ${CardContainer} {
        &:after {
            content: '';
            border: 3px solid ${colorStack.middleBlue};
            height: inherit;
            width: 300px;
            ${(props) =>
        props.maxSize &&
        css`
                width:${`${props.maxSize}px`};
            `}
            position: absolute;
            top: 0;
            left: -3px;
            border-radius: 7px;
        }
    }

    input:checked + ${CardContainer} ${Card} {
        &:before {
            content: "✔";
            position: absolute;
            width: 0px;
            right: 22px;
            top: 5px;
            color: white;
            z-index: 1;
        }
        &:after {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-left: 25px solid ${colorStack.middleBlue};
            border-right: 25px solid transparent;
            border-top: 25px solid ${colorStack.middleBlue};
            border-bottom: 25px solid transparent;
            right: 0px;
            top: 0px;
            transform: rotate(90deg);
        }
    }

`;

const Image = styled.div<{ src: string }>`
    ${props => props.src && css`
        background-image: url(${props.src});
    `}
    border-radius: 4px 4px 0 0;
    width: 100%;
    background-size: cover;
    height: 136px;
    background-repeat: no-repeat;
    background-position: center;
`;

const Title = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[25]};
    margin: 2rem 2rem 1rem 2rem;
`;

const Description = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    margin: 0 2rem 1rem 2rem;
`;

interface IProps {
    field?: string;
    templateKey?: string;
    value: string;
    imageKey: string;
    name: string;
    description: string;
    maxSize?: number;
    isChecked?: boolean;
    testingDataAttr?: string;
    onClick?(templateKey: string);
}

export const SelectCard: FC<React.PropsWithChildren<IProps>> = props => {

    const checkImage = useCallback(path => {
        try {
            const img = require(`../../../../wwwroot/assets/images/wizardCard/${path}.png`);
            return img;
        } catch (e) {
            return false;
        }
    }, [props.imageKey]);

    const onClick = () => {
        props.onClick(props.templateKey);
    };

    return (
        <>
            {checkImage(props.imageKey) &&
                <Wrapper maxSize={props?.maxSize} data-lc={`js-lc-select-card-${props.value}`} >
                    <LabelWrapper>
                        <label>
                            {props.field ?
                                <Field
                                    type="radio"
                                    name={props.field}
                                    value={props.value}
                                />
                            : <input onClick={onClick} type="radio" checked={props.isChecked} />}
                            <CardContainer>
                                <Card>
                                    <Image src={checkImage(props.imageKey)} data-lc={props.testingDataAttr} />
                                    <Title>
                                        <FormattedMessage id={props.name} />
                                    </Title>
                                    <Description>
                                        <FormattedMessage id={props.description} />
                                    </Description>
                                </Card>
                            </CardContainer>
                        </label>
                    </LabelWrapper>
                </Wrapper>
            }
        </>
    );
};