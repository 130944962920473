import * as actionTypes from '../actions/actionTypes/onboardingTypes';

export interface IOnboardingReducer {
    fields: {
        accout: boolean;
        profile: {
            total: number;
            picture: boolean;
            expertisesList: boolean;
            companyList: boolean;
            degreesList: boolean;
            roleCategory: boolean;
        };
        invite: boolean[];
        publish: boolean[];
        feedback: boolean;
    };
    onboardingLoaded: {
        account: boolean;
        profile: boolean;
        invite: boolean;
    };
    onboardingLoadedAll: boolean;
    totalPercentage: number;
    profileTotalScore: number;
}

const defaultState = (): IOnboardingReducer => ({
    fields: {
        accout: true,
        profile: {
            total: 0,
            picture: false,
            expertisesList: false,
            companyList: false,
            degreesList: false,
            roleCategory: false
        },
        invite: [false, false, false],
        publish: [false],
        feedback: false
    },
    onboardingLoaded: {
        account: true,
        profile: false,
        invite: false
    },
    onboardingLoadedAll: false,
    totalPercentage: 33.33,
    profileTotalScore: 33.33
});

const stepPercentage = 10;
const stepPercentageScore = 33.33;

const calculateTotal = (profile: number, step: number = stepPercentage) => Math.round((step * profile));
const calculateTotalScore = (profile: number, publish: boolean, invite: boolean[], step: number = stepPercentageScore) => Math.round(step + (step * profile) + (publish ? step : 0) + Math.round(step * invite.filter(stepCount => !!stepCount).length * 0.333));

export default (state = defaultState(), action): IOnboardingReducer => {
    switch (action.type) {
        case actionTypes.ONBOARDING_GET_PROFILE_SCORE: {
            const fields = {
                ...state.fields,
                profile: {
                    ...action.profileScoreDetails,
                    total: (action.score * 10)
                }
            };

            const onboardingLoaded = {
                ...state.onboardingLoaded,
                profile: true
            };

            const profileTotalScore = calculateTotal(fields.profile.total);

            return {
                ...state,
                fields,
                profileTotalScore,
                onboardingLoaded,
                onboardingLoadedAll: Object.keys(onboardingLoaded).filter(key => !!onboardingLoaded[key]).length === 3
            };
        }

        case actionTypes.ONBOARDING_GET_BLOG_SCORE: {
            const fields = {
                ...state.fields,
                publish: [action.isAnyArticlePublished]
            };

            const totalPercentage = calculateTotalScore(fields.profile.total, fields.publish[0], fields.invite);

            return {
                ...state,
                fields,
                totalPercentage
            };
        }

        case actionTypes.ONBOARDING_GET_INVITE_SCORE: {
            const fields = {
                ...state.fields,
                invite: new Array(3).fill(false).map((item, index) => index + 1 <= action.score)
            };

            const onboardingLoaded = {
                ...state.onboardingLoaded,
                invite: true
            };

            const totalPercentage = calculateTotalScore(fields.profile.total, fields.publish[0], fields.invite);

            return {
                ...state,
                fields,
                totalPercentage,
                onboardingLoaded,
                onboardingLoadedAll: Object.keys(onboardingLoaded).filter(key => !!onboardingLoaded[key]).length === 3
            };
        }

        default: {
            return state;
        }
    }
};
