import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string-for-all';
import { useNavigate } from 'react-router-dom';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import { SogeLangOption } from '../SogeHeader/SogeLangOption';
import { Button } from '../../Common/Buttons/NewButton';
import { DropdownWrapper, LangDropdownButton, SgDropdown } from './Styles';
import { useDropdown } from '../../../tools/hooks';
import { languageIcons, languageLabels } from '../../../tools/languageTools';
import { ELcid } from '../../../entities/ILanguage';
import { IState } from '../../../reducers';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { setSogeDocumentationLanguage, setUniqueVersionId } from '../../../actions/sogeActions';
import { changeInterfaceLanguage } from '../../../actions/languageActions';
import { useNormsConfiguration } from '../../../tools/legalDocTools/sogeHooks';
import { SGDocLanguageKey } from '../../../entities/ISoge';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';

type SetLang = ReturnType<typeof setSogeDocumentationLanguage>;

const Wrapper = styled(DropdownWrapper) <{ disabled?: boolean }>`
    ${props => props.disabled && css`
        opacity: .5;
    `}
`;

interface ILangSelectProps {
    className?: string;
    disabled?: boolean;
    callbackOnly?: boolean;
    languages?: ELcid[];
    currnetLcid?: ELcid;
    versionId?: string;
    bgColor?: string;
    onSelectLang?(lcid: ELcid);
}

export const LangSelect: FC<React.PropsWithChildren<ILangSelectProps>> = ({ className, disabled, callbackOnly, onSelectLang, languages, children, currnetLcid, versionId, bgColor }) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [langWrapperRef, langDropdownOpen, langToggle, langCloseDropdown] = useDropdown();
    const sogeDocLanguages = languages || [];
    const { documentationLcid, languageData, currentUniqueVersionId } = useSelector<IState, ISogeReducer>(state => ({
        ...state.soge
    }));
    const { isUiLanguageSynchronizedWithDocumentLanguage } = useNormsConfiguration();
    const changeLanguage = useCallback((selectedLcid: ELcid) => {
        if (!callbackOnly) {
            const query = queryString.parse(window.location.search);
            const { lcid, ...newQuery } = query;
            const newQueryString = queryString.stringify(newQuery);
            const url = [window.location.pathname, newQueryString].filter(part => !!part).join('?');
            history(url);

            localStorage.setItem(SGDocLanguageKey, `${selectedLcid}`);
            dispatch<SetLang>(setSogeDocumentationLanguage(selectedLcid));
            versionId && dispatch(setUniqueVersionId(languageData?.[versionId]?.[selectedLcid]?.uniqueVersionId));
            isUiLanguageSynchronizedWithDocumentLanguage && dispatch(changeInterfaceLanguage(selectedLcid));
        }
        langCloseDropdown();
        onSelectLang?.(selectedLcid);
    }, [callbackOnly, onSelectLang, languageData, currentUniqueVersionId, isUiLanguageSynchronizedWithDocumentLanguage]);

    const currentLang = callbackOnly ? currnetLcid : documentationLcid;

    return (
        <Wrapper ref={langWrapperRef} className={className || ''} disabled={disabled}>
            {sogeDocLanguages.length === 0 ?
                <Spinner size={ESpinnerSize.BUTTONSIZE} />
                : <Button data-lc="js-lc-button-togglelang" rightIco={langDropdownOpen ? faChevronUp : faChevronDown} onClick={langToggle} disabled={disabled}>
                    <LangDropdownButton isOpen={langDropdownOpen}>
                        {currentLang ? (
                            <>
                                <img src={languageIcons[currentLang]} />
                                <FormattedMessage id={languageLabels[currentLang].id} />
                            </>
                        ) : children}
                    </LangDropdownButton>
                </Button>
            }
            {langDropdownOpen && (
                <SgDropdown isCropped>
                    {sogeDocLanguages.map(lcid => (
                        <SogeLangOption data-lc="js-lc-button-changelanguage" key={lcid} lcid={lcid} onClick={changeLanguage} />
                    ))}
                </SgDropdown>
            )}
        </Wrapper>
    );
};

export default LangSelect;