import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { EConfigurationOfAvailableUnits, IClusterStakeProperty, EStakeDetailsSubType, EStakeDetailsType } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { ErrorText } from './StakeStyles';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { ETextComponentSubType, TextComponent } from '../../../../Common/Inputs/TextComponent';
import { DEFAULT_CURRENCY_KEY, EReferentials, IReferencial, IViewReferential } from '../../../../../entities/IGlobal';
import { getReferentials } from '../../../../../actions/globalActions';
import { TranslateReferentials } from '../../../../../tools/referentials';
import { numberFormatter } from '../../../../../tools/numberTools';
import { IValue } from '../../../../../entities/IPickers';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div``;

const EditMode = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    > div {
        width: 100%;
    }
`;

interface INumberContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: {
        errorCode: number;
        message: string;
    };
    context?: 'PLE' | 'Cluster';
    base?: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

let debouncer: ReturnType<typeof setTimeout>;

export const NumberContent: FC<React.PropsWithChildren<INumberContentProps>> = ({ base, context, error, data, editStake, propertieId, setEditingData }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    // tslint:disable-next-line:no-null-keyword
    const [content, setContent] = useState<string>(null);
    // tslint:disable-next-line:no-null-keyword
    const [unit, setUnit] = useState<IViewReferential>(null);
    // tslint:disable-next-line:no-null-keyword
    const [units, setUnits] = useState<IReferencial[]>(null);
    const referentialIdentity = data.configuration?.referentialIdentity;

    useEffect(() => {
        return () => {
            clearTimeout(debouncer);
        };
    }, []);

    useEffect(() => {
        setContent(data?.value?.number || data?.value?.numericValue?.number as number);
        setUnit(data?.value?.unit || data?.value?.numericValue?.unit as string || data?.value?.baseUnit || data?.configuration?.value?.unit);
        if ((data?.configuration?.availableUnits?.configuration || data?.configuration?.availableUnits?.Configuration) === EConfigurationOfAvailableUnits.DynamicValues) {
            dispatch<GetReferentials>(getReferentials('', (data?.configuration?.availableUnits?.referentialType || data?.configuration?.availableUnits?.ReferentialType), (data?.configuration?.availableUnits?.referentialContext || data?.configuration?.availableUnits?.ReferentialContext)))
                .then(res => {
                    if (!units) {
                        setUnits(res);
                    }
                    if (!data?.value?.unit && !data?.value?.numericValue?.unit) {
                        const defaultCurrencyRef = res.find(elem => elem.Key === DEFAULT_CURRENCY_KEY);
                        // tslint:disable-next-line:no-null-keyword
                        const value = ({ number: data?.value?.number || data?.value?.numericValue?.number, unit: data?.value?.unit || data?.value?.numericValue?.unit as string || data?.value?.baseUnit || { key: defaultCurrencyRef.id, parentId: defaultCurrencyRef.referentialIdentityId, uniqueKey: defaultCurrencyRef.Key } });
                        setEditingData({
                            ...data,
                            value: data.type === EStakeDetailsType.NumberAndRisk ? { riskValue: data.value?.riskValue, numericValue: value } : value,
                            id: propertieId
                        });
                    }
                });
        } else if ((data?.configuration?.availableUnits?.configuration || data?.configuration?.availableUnits?.Configuration) === EConfigurationOfAvailableUnits.DefinedValues) {
            setUnits((data?.configuration?.availableUnits?.valueKeys || data?.configuration?.availableUnits?.ValueKeys)?.map(elem => ({
                // tslint:disable-next-line:no-null-keyword
                Key: null,
                id: elem,
                name: elem,
                // tslint:disable-next-line:no-null-keyword
                logicalId: null
            })));
        }
    }, [data, editStake]);

    const changeNumber = (value: string, currency: IValue) => {
        const regex = /^\d*\.?(?:\d{1,6})?$/g;
        clearTimeout(debouncer);
        if (!unit && currency?.data) {
            changeUnit(currency?.data, value);
        }
        if (regex.test(value) || value === '') {
            if (data.subType === EStakeDetailsSubType.Percentage && parseInt(value, 10) > 100) {
                setContent('100');
                value = '100';
            } else {
                // tslint:disable-next-line:no-null-keyword
                setContent(value !== '' ? value : null);
            }
            return new Promise(resolve => {
                debouncer = setTimeout(async () => {
                    try {
                        const valueIdentity = referentialIdentity === 'Custom' ?
                            // tslint:disable-next-line:no-null-keyword
                            parseInt(value, 10) : ({ number: (value !== '' ? value.replace(' ', '') : null), unit: { key: currency.data.id || currency.data.key, parentId: currency.data.referentialIdentityId || currency.data.parentId, uniqueKey: currency.data.Key || currency.data.uniqueKey } });
                        setEditingData({
                            ...data,
                            value: data.type === EStakeDetailsType.NumberAndRisk ? { riskValue: data.value?.riskValue, numericValue: valueIdentity } : valueIdentity,
                            id: propertieId
                        });
                    } catch (e) {
                        resolve(false);
                    }
                }, 1000);
            });
        }
    };

    const changeUnit = (unitElem, valueString: string) => {
        setUnit({ key: unitElem.id, parentId: unitElem.referentialIdentityId, uniqueKey: unitElem.Key });
        const value = ({ number: valueString || content, unit: { key: unitElem.id || unitElem.key, parentId: unitElem.referentialIdentityId || unitElem.parentId, uniqueKey: unitElem.Key || unitElem.uniqueKey } });
        setEditingData({
            ...data,
            value: data.type === EStakeDetailsType.NumberAndRisk ? { riskValue: data.value?.riskValue, numericValue: value } : value,
            id: propertieId
        });
    };

    const getSubType = () => {
        if (data?.configuration?.availableUnits?.referentialType === EReferentials.Currency || data?.configuration?.availableUnits?.ReferentialType === EReferentials.Currency) return ETextComponentSubType.CURRENCY;
        if (data?.subType === EStakeDetailsSubType.Percentage) return ETextComponentSubType.PERCENTAGE;
        return undefined;
    };

    return (
        <Wrapper>
            {editStake ? (
                <div>
                    <EditMode>
                        <TextComponent
                            onChange={changeNumber}
                            subType={getSubType()}
                            value={content !== null ? content : ''}
                            placeholder={intl.formatMessage({ id: 'cluster.placeholder.number' })}
                            error={error?.errorCode === 666}
                            currencies={units?.sort((a, b) => a.name.localeCompare(b.name))}
                            selectedCurrency={(unit?.key && units) ? { key: unit.key, text: units?.find(elem => elem.id === unit?.key)?.name, data: unit } : undefined}
                        />
                        {(data?.subType !== EStakeDetailsSubType.Percentage && data?.configuration?.availableUnits?.referentialType !== EReferentials.Currency && data?.configuration?.availableUnits?.referentialType !== EReferentials.Percentage && data?.configuration?.availableUnits?.ReferentialType !== EReferentials.Currency) &&
                            <>
                                {units?.length > 1 ? (
                                    <SelectInput
                                        value={(units && unit?.key) ? [{ key: unit?.key, text: units?.find(elem => elem.Key === unit?.key || elem.id === unit?.key)?.name, data: unit }] : undefined}
                                        onChange={(option: IValue) => {
                                            if (option?.data) {
                                                changeUnit(option.data, undefined);
                                            }
                                        }}
                                        options={units?.sort((a, b) => a.name.localeCompare(b.name))?.map(elem => ({
                                            key: elem.Key,
                                            text: elem.name,
                                            data: elem
                                        }))}
                                        type={EDropDownType.SEARCH}
                                    />
                                ) : (
                                    <span><TranslateReferentials type={data?.configuration?.availableUnits?.referentialType || data?.configuration?.availableUnits?.ReferentialType} context={data?.configuration?.availableUnits?.referentialContext || data?.configuration?.availableUnits?.ReferentialContext} uniqueKey={units?.[0]?.Key} /></span>
                                )}
                            </>
                        }
                    </EditMode>
                    {(error) &&
                        <ErrorText customColor={error.errorCode === 666 && colorStack.darkOrange}>{error?.message}</ErrorText>
                    }
                </div>
            ) : (
                <>
                    {(data.value?.number && !base) &&
                        <>
                            <span>{numberFormatter(data.value?.number)}</span>
                            {data.value?.unit && <span> <TranslateReferentials type={data.configuration.availableUnits.referentialType || data.configuration.availableUnits.ReferentialType} context={data.configuration.availableUnits.referentialContext || data.configuration.availableUnits.ReferentialContext} uniqueKey={data?.value?.unit?.uniqueKey} /></span>}
                        </>
                    }
                    {(data.value?.baseNumber && base) &&
                        <>
                            <span>{numberFormatter(data.value?.baseNumber)}</span>
                            {data.value?.baseUnit && <span> <TranslateReferentials type={data.configuration.availableUnits.referentialType || data.configuration.availableUnits.ReferentialType} context={data.configuration.availableUnits.referentialContext || data.configuration.availableUnits.ReferentialContext} uniqueKey={data.value.baseUnit?.uniqueKey} /></span>}
                        </>
                    }
                </>
            )}
        </Wrapper>
    );
};
