import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';

export enum EProgressBarSize {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM'
}

export enum EProgressBarColorTheme {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    GREEN = 'GREEN',
    RED = 'RED',
    ORANGE = 'ORANGE'
}

const Wrapper = styled.div<IProps>`
    border-radius: 4px;
    height: 8px;
    width: 100%;
    background: ${colorStack.ligthGrey};
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: .3rem 0 0 0;
    ${props => props.size === EProgressBarSize.MEDIUM && css`
        height: 16px;
    `};
    &:before {
        content: '';
        display: block;
        background: ${colorStack.middleBlue};
        border-radius: 4px;
        height: 8px;
        width: 100%;
        position: relative;
        transition: right .3s;
        ${props => props.size === EProgressBarSize.MEDIUM && css`
            height: 16px;
        `};
        ${props => props.percent && css`
            right: ${`calc(100% - ${props.percent}%)`};
        `}
        ${props => props.themeColor === EProgressBarColorTheme.DISABLED && css`
            background: ${colorStack.disabled};
        `};
        ${props => props.themeColor === EProgressBarColorTheme.GREEN && css`
            background: ${colorStack.middleGreen};
        `};
        ${props => props.themeColor === EProgressBarColorTheme.RED && css`
            background: ${colorStack.middleRed};
        `};
        ${props => props.themeColor === EProgressBarColorTheme.ORANGE && css`
            background: ${colorStack.middleOrange};
        `};
    }
`;

interface IProps {
    size?: EProgressBarSize;
    themeColor?: EProgressBarColorTheme;
    percent: number;
}

export const ProgressBar: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper percent={props.percent} size={props.size || EProgressBarSize.SMALL} themeColor={props.themeColor || EProgressBarColorTheme.ACTIVE} />
    );
};