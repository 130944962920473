import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faList, faCaretCircleDown, faCalendarDay, faPaperclip, faToggleOn,
faHashtag, faCoin, faHourglassHalf, faPercentage, faUser, faEuroSign, faRulerCombined } from '@fortawesome/pro-regular-svg-icons';

interface ISetPropertiesIcon {
    type: string;
}

export const SetPropertiesIcon: FC<React.PropsWithChildren<ISetPropertiesIcon>> = (props) => {
    const iconList = {
        Text: <FontAwesomeIcon icon={faAlignLeft} />,
        Enum: <FontAwesomeIcon icon={faAlignLeft} />,
        Simple: <FontAwesomeIcon icon={faAlignLeft} />,
        Number: <FontAwesomeIcon icon={faHashtag} />,
        Surface: <FontAwesomeIcon icon={faRulerCombined} />,
        Currency: <FontAwesomeIcon icon={faCoin} />,
        Amount: <FontAwesomeIcon icon={faHourglassHalf} />,
        Duration: <FontAwesomeIcon icon={faHourglassHalf} />,
        Date: <FontAwesomeIcon icon={faCalendarDay} />,
        LegalDate: <FontAwesomeIcon icon={faCalendarDay} />,
        AnniversaryDate: <FontAwesomeIcon icon={faCalendarDay} />,
        Reference: <FontAwesomeIcon icon={faList} />,
        Cluster: <FontAwesomeIcon icon={faAlignLeft} />,
        Percentage: <FontAwesomeIcon icon={faPercentage} />,
        Person: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        MultipleParty: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        LegalEntity: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        FilteredByOurGroup: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        Party: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        Subsidiary: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        MultipleLegalEntity: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        People: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        MultiplePeople: <img src="assets/images/ClusterLogos/people-stakes.svg" />,
        Address: <FontAwesomeIcon icon={faAlignLeft} />,
        Boolean: <FontAwesomeIcon icon={faToggleOn} />,
        Quality: <FontAwesomeIcon icon={faCaretCircleDown} />,
        NumberAndSelect: <FontAwesomeIcon icon={faCaretCircleDown} />,
        CurrencyNumberAndSingleSelect: <FontAwesomeIcon icon={faCaretCircleDown} />,
        Attachment: <FontAwesomeIcon icon={faPaperclip} />,
        MultipleAttachments: <FontAwesomeIcon icon={faPaperclip} />,
        URL: <FontAwesomeIcon icon={faPaperclip} />,
        SingleSelect: <FontAwesomeIcon icon={faCaretCircleDown} />,
        Radio: <FontAwesomeIcon icon={faCaretCircleDown} />,
        MultipleSelect: <FontAwesomeIcon icon={faList} />,
        SinglePerson: <FontAwesomeIcon icon={faUser} />,
        EuroSign: <FontAwesomeIcon icon={faEuroSign} />
    };
    return (
        <>
            {iconList[props.type]}
        </>
    );
};