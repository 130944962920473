import ct, { Timezone } from 'countries-and-timezones';
import moment from 'moment';
import { IntlShape, useIntl } from 'react-intl';

import { getCountryFromLanguageString } from './languageTools';

export const getListYears = (): Array<{ key: string; text: string; }> => {
    let result: Array<{ key: string; text: string; }> = new Array<{ key: string; text: string; }>();

    for (let index = (new Date()).getFullYear(); index > (new Date()).getFullYear() - 100; index--) {
        result.push({ key: index.toString(), text: index.toString() });
    }

    return result;
};

export const standardMinutes = (minutes: number): string => {
    return minutes >= 10 ? minutes.toString() : `0${minutes}`;
};

export const isToday = (date: string) => {
    const someDate = new Date(date);
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
};

export const getData = (date: string, todayText?: string, intl?: IntlShape) => {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();
    const hours = new Date(date).getHours();
    const minutes = new Date(date).getMinutes();
    const fullDate = `${day}.${month}.${year}`;
    const today = intl?.formatMessage({ id: 'global.today' });
    /* const period = (hours > 11) ? 'PM' : 'AM'; */
    const result = `${hours}:${standardMinutes(minutes)} ${isToday(date) ? (todayText || today) : fullDate} `;

    return result;
};

export const usDateFormat = (date: string, timeOn = false) => {
    const dateObj = new Date(date);

    const dateUs = dateObj.toLocaleDateString();

    const time = dateObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return timeOn ? `${dateUs} | ${time}` : `${dateUs}`;
};

export const shortDate = () => {
    const getCurrentDate = new Date();
    const currentDay = getCurrentDate.getDate();
    const currentMonth = getCurrentDate.getMonth() + 1;
    const currentYear = getCurrentDate.getFullYear();
    const currentDate = `${currentDay}_${currentMonth}_${currentYear}`;

    return currentDate;
};

export const numberOfDays = (date: string) => {
    const someDate = new Date(date).getTime() / (1000 * 60 * 60 * 24);
    const today = new Date().getTime() / (1000 * 60 * 60 * 24);
    return Math.floor(today - someDate);
};

export const getCurrentIANATimeZone = (): Timezone => {
    try {
        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return ct.getTimezone(timezoneName);
    } catch (e) { // fallback for browsers that doesn't support Intl (IE...)
        const allTimezones = ct.getAllTimezones();
        const userAgentCountry = getCountryFromLanguageString(window.navigator.language || window.navigator.languages[0]);
        const year = new Date().getFullYear();
        const jan = new Date(year, 0, 1);
        const jul = new Date(year, 6, 1);
        const utcOffset = -jan.getTimezoneOffset();
        const dstOffset = -jul.getTimezoneOffset();
        const matchingTimezones = Object.values(allTimezones).filter(tz => {
            return tz.dstOffset === dstOffset && tz.utcOffset === utcOffset;
        });
        const matchingtimeZoneByCountry = matchingTimezones.find(tz => tz.country === userAgentCountry) || matchingTimezones[0];
        return matchingtimeZoneByCountry?.aliasOf ? ct.getTimezone(matchingtimeZoneByCountry.aliasOf) : matchingtimeZoneByCountry;
    }
};

export const getDaysOfMonth = [
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
];

export const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const days = ['1', '2', '3', '4', '5', '6', '7'];

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const translatedDaysNames = (intl: IntlShape): { key: string, text: string, data: string }[] => {
    return days.map(elem => ({
        key: elem,
        text: intl.formatMessage({ id: `global.dayName.${elem}` }),
        data: elem
    }));
};

export const translatedMonthNames = (intl: IntlShape): { key: string, text: string, data: string }[] => {
    return months.map(elem => ({
        key: elem,
        text: intl.formatMessage({ id: `global.monthName.${elem}` }),
        data: elem
    }));
};

export const formatDurationDate = (date: string) => {
    const intl = useIntl();
    const day = moment.duration(date).days();
    const dayTranslation = intl?.formatMessage({ id: 'global.day' });
    const monthTranslation = intl?.formatMessage({ id: 'global.month' });
    const yearTranslation = intl?.formatMessage({ id: 'global.year' });
    const month = moment.duration(date).months();
    const year = moment.duration(date).years();
    const result = `${day > 0 ? `${day} ${dayTranslation} ` : ''} ${month > 0 ? `${month} ${monthTranslation}` : ''} ${year > 0 ? `${year} ${yearTranslation}` : ''}`;
    return result;
};

export const getTimeFromNow = (date: string) => {
    const intl = useIntl();
    const minutes = Math.round(moment.duration(moment(new Date()).diff(moment.utc(date))).asMinutes());
    const houres = Math.round(moment.duration(moment(new Date()).diff(moment.utc(date))).asHours());
    const daysNumber = Math.round(moment.duration(moment(new Date()).diff(moment.utc(date))).asDays());
    let result = '';
    if (minutes < 1) {
        result = intl.formatMessage({ id: 'global.small.sec' });
    } else if (minutes >= 1 && minutes < 60) {
        result = `${minutes} ${intl.formatMessage({ id: 'global.small.min' })}`;
    } else if (minutes >= 60 && houres < 24) {
        result = `${houres} ${intl.formatMessage({ id: 'global.small.hours' })}`;
    } else if (houres > 24 && daysNumber < 2) {
        result = `${daysNumber} ${intl.formatMessage({ id: 'global.small.day' })}`;
    } else if (daysNumber >= 2) {
        result = `${daysNumber} ${intl.formatMessage({ id: 'global.small.days' })}`;
    }
    return result;
};

export const getTimeSeparator = (date: Date): number[] => {
    const finalDate = date.setHours(0, 0, 0, 0);
    return [moment().diff(finalDate, 'days'), moment().diff(finalDate, 'weeks'), moment().diff(finalDate, 'months')];
};