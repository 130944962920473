import React, { FC } from 'react';

import { ITree, ITreeViewProps } from './ITreeView';
import { Tree } from './Tree';

export { Icon } from './Tree';
export { ETreeExpandIcon as ITreeExpandIcon } from './ITreeView';
export type { ITree, ITreePathItem } from './ITreeView';

interface IProps extends ITreeViewProps {
    treeData: ITree[];
}

export const TreeView: FC<React.PropsWithChildren<IProps>> = ({ treeData, sort, ...props }) => {
    const sortedTreeData = sort ? [...(treeData || []).sort((a, b) => (a.order ?? a.orderNumber ?? 0) - (b.order ?? b.orderNumber ?? 0))] : treeData;

    return (
        <>
            {sortedTreeData.map(treeItem => (
                <Tree
                    {...props}
                    sort={sort}
                    treeItem={treeItem}
                    key={treeItem.id || treeItem.commonLevelId}
                    level={0}
                    renderPath={[{
                        id: treeItem.id,
                        name: treeItem.name,
                        level: 0,
                        commonLevelId: treeItem.commonLevelId
                    }]}
                />
            ))}
        </>
    );
};
