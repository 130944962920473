import React, { FC, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
// import { rgba } from 'polished';
import * as jsDiff from 'diff';
import * as jsondiffpatch from 'jsondiffpatch';

import { IBranchCompare } from '../../../entities/ISoge';
import { rebuildHtml, IDomList, getStructureList, IComparedStructureElement, prepareArticlesInStructure, getModifiedSentencesDiff, mergeIdenticallyStyledTextRunsInDom } from '../../../tools/legalDocTools/sogeDiffTools';
import { arrayToObject } from '../../../tools/arrayTools';

import { colorStack } from '../../../styleHelpers/colors';
import { StructureLevel, IStructureLevelCommonProps } from '../LegalDocument/StructureLevel';
import { DiffObject } from './DiffObjects';

const Wrapper = styled.div`
    display: flex;
`;

const BranchCol = styled.div<{ hidenCol?: boolean }>`
    width: 0;
    ${({ hidenCol }) => !hidenCol && css`
        flex: 1;
        width: 50%;
    `}
`;

interface IHtmlDiffWrapperProps {
    left?: boolean;
    right?: boolean;
    singleCol?: boolean;
    highlightChanges: boolean;
}

const HtmlDiffWrapper = styled.div<IHtmlDiffWrapperProps>`
    .footnote-popup {
        display: none;
    }
    ins, .added {
        text-decoration: none;
        ${({ right, singleCol }) => (right || singleCol) && css`
            color: ${colorStack.middleRed};
            text-decoration: line-through;
        `}
        ${({ left }) => left && css`
            display: none;
        `}
    }
    del, .removed {
        text-decoration: none;
        ${({ right }) => right && css`
            display: none;
        `}
        ${({ left, singleCol }) => (left || singleCol) && css`
            color: ${colorStack.middleGreen};
        `}
    }
    ${({ highlightChanges }) => !highlightChanges && css`
        display: none;
    `}
`;

const OriginalContentWrapper = styled.div<{ highlightChanges: boolean }>`
    ${({ highlightChanges }) => highlightChanges && css`
        overflow: hidden;
        height: 0;
    `}
`;

interface IBranchDiffProps extends IStructureLevelCommonProps {
    branchComparison: IBranchCompare;
    comparedBranchVersionId: string;
    highlightChanges: boolean;
    sideBySide: boolean;
}

export const BranchDiff: FC<React.PropsWithChildren<IBranchDiffProps>> = ({ branchComparison, versionId, organizationUrlName, comparedBranchVersionId, highlightChanges, sideBySide, currentDownloadingElementId, onStructureDetailsClick, onRootStructureDetailsClick, onArticleDetailsClick, getStructureLink, getArticleUrl, onStructureDownloadClick, onArticleDownloadClick, onCopyUrlClick }) => {
    const [diffResult, setDiffResult] = useState<IComparedStructureElement[]>([]);

    const leftRef = useRef<HTMLDivElement>();
    const rightRef = useRef<HTMLDivElement>();

    const debounceRef = useRef<ReturnType<typeof setTimeout>>(undefined);

    useEffect(() => {
        const diffpatcher = jsondiffpatch.create({
            objectHash(obj: IDomList) {
                return obj.textValue;
            }
        });

        const callback = () => {
            clearTimeout(debounceRef.current);
            debounceRef.current = setTimeout(() => {
                if (leftRef.current && rightRef.current) {
                    mergeIdenticallyStyledTextRunsInDom(leftRef.current);
                    mergeIdenticallyStyledTextRunsInDom(rightRef.current);

                    const leftStructureList = getStructureList(leftRef.current);
                    const rightStructureList = getStructureList(rightRef.current);

                    const leftStructureByCommonId = arrayToObject(leftStructureList, item => item.commonId);
                    const rightStructureByCommonId = arrayToObject(rightStructureList, item => item.commonId);

                    const structureDiff = jsDiff.diffArrays(leftStructureList, rightStructureList, {
                        comparator: (a, b) => a.structureTitle === b.structureTitle
                    });

                    const structureDiffWithArticles = prepareArticlesInStructure(structureDiff, leftStructureByCommonId, rightStructureByCommonId);

                    const comparedArticles: IComparedStructureElement[] = structureDiffWithArticles.map(structureDiffObject => {
                        const { isArticle, leftDomList, rightDomList } = structureDiffObject;

                        const articleDiff = isArticle && jsDiff.diffArrays(leftDomList, rightDomList, {
                            comparator: (a, b) => a.textValue === b.textValue
                        });

                        const modifiedSentences = isArticle && getModifiedSentencesDiff(diffpatcher, leftDomList, rightDomList);

                        return {
                            ...structureDiffObject,
                            diffResultHtml: isArticle && rebuildHtml(articleDiff, modifiedSentences)
                        };
                    });

                    setDiffResult(comparedArticles);
                }
            }, 300);
        };

        const observer = new MutationObserver(callback);

        observer.observe(leftRef.current, { attributes: true, childList: true, subtree: true });
        observer.observe(rightRef.current, { attributes: true, childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    return (
        <Wrapper>
            <BranchCol>
                <HtmlDiffWrapper left={sideBySide} singleCol={!sideBySide} highlightChanges={highlightChanges}>
                    {diffResult.map((diffElement) => (
                        <DiffObject key={diffElement.id} diffElement={diffElement} />
                    ))}
                </HtmlDiffWrapper>
                <OriginalContentWrapper ref={leftRef} highlightChanges={highlightChanges}>
                    <StructureLevel
                        structureLevel={branchComparison.source}
                        structureId={branchComparison.source.commonLevelId}
                        versionId={versionId}
                        organizationUrlName={organizationUrlName}
                        disableStructureLinks
                        isDiffView
                        currentDownloadingElementId={currentDownloadingElementId}
                        onStructureDetailsClick={onStructureDetailsClick}
                        onRootStructureDetailsClick={onRootStructureDetailsClick}
                        onArticleDetailsClick={onArticleDetailsClick}
                        getStructureLink={getStructureLink}
                        getArticleUrl={getArticleUrl}
                        onStructureDownloadClick={onStructureDownloadClick}
                        onArticleDownloadClick={onArticleDownloadClick}
                        onCopyUrlClick={onCopyUrlClick}
                    />
                </OriginalContentWrapper>
            </BranchCol>
            <BranchCol hidenCol={highlightChanges && !sideBySide}>
                {sideBySide && (
                    <HtmlDiffWrapper right highlightChanges={highlightChanges}>
                        {diffResult.map((diffElement) => (
                            <DiffObject key={diffElement.id} diffElement={diffElement} />
                        ))}
                    </HtmlDiffWrapper>
                )}
                <OriginalContentWrapper ref={rightRef} highlightChanges={highlightChanges}>
                    <StructureLevel
                        structureLevel={branchComparison.target}
                        structureId={branchComparison.target.commonLevelId}
                        versionId={comparedBranchVersionId}
                        organizationUrlName={organizationUrlName}
                        disableStructureLinks
                        isDiffView
                        currentDownloadingElementId={currentDownloadingElementId}
                        onStructureDetailsClick={onStructureDetailsClick}
                        onRootStructureDetailsClick={onRootStructureDetailsClick}
                        onArticleDetailsClick={onArticleDetailsClick}
                        getStructureLink={getStructureLink}
                        getArticleUrl={getArticleUrl}
                        onStructureDownloadClick={onStructureDownloadClick}
                        onArticleDownloadClick={onArticleDownloadClick}
                        onCopyUrlClick={onCopyUrlClick}
                    />
                </OriginalContentWrapper>
            </BranchCol>
        </Wrapper>
    );
};
