import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

interface IWrapperProps {
    inputVisible: boolean;
    newDesign: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
    position: relative;
    transition: all 200ms;
    min-height: 32px;
    background-color: ${colorStack.white};
    &:focus {
        outline: 0;
    }
    ${props => props.inputVisible && css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `}
`;

export const Inner = styled.div<{ newDesign: boolean }>`
    ${props => !props.newDesign && css`
        padding: 3px 25px 0 3px;
    `}
    border: solid 2px ${colorStack.ligthGrey};
    :focus {
        border: solid 2px ${colorStack.middleBlue};
    }
    :hover {
        border: solid 2px ${colorStack.middleBlue};
    }
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 40px;
    ${props => props.newDesign && css`
        input {
            outline: none;
            background: white;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: ${fontSize[20]};
            padding: 8px;
            flex: 1;
            border: 0;
        }
    `}
    ${props => !props.newDesign && css`
        input {
            flex: 1;
            min-width: 120px;
            border: 0;
            padding: 0;
            margin: 0;
            margin-bottom: 0;
            margin-left: 0.2rem;
            font-size: ${fontSize[13]};
            height: auto;
            min-height: 26px;
            &:focus {
                outline: 0;
            }
        }
    `}
`;

export const Placeholder = styled.div`
    color: ${colorStack.middleGrey};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.3rem;
    font-size: ${fontSize[13]};
`;

export const ClearTagsButton = styled.button.attrs({ type: 'button' })`
    position: absolute;
    bottom: 12px;
    right: 10px;
    width: 10px;
    height: 10px;
    outline: 0;
    cursor: pointer;

    &:after, &:before {
        content: '';
        height: 2px;
        width: 14px;
        background: ${colorStack.darkBlue};
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;