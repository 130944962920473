import React, { FC } from 'react';

import { LcLogo } from '../CutomIcons/LcLogo';

export enum ESizeLogo {
    XS = 16,
    S = 24,
    SM = 32,
    M = 50
}

interface IProps {
    size: ESizeLogo;
}

export const Logo: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <LcLogo width={props.size} />
    );
};
