import React, { FC, useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { IFormData, EDeclarationTypeEnum } from '../../../../entities/IDeclaration';
import { RadioButton } from '../../../Common/Inputs/RadioButton';
import { ETextComponentSubType, TextComponent } from '../../../Common/Inputs/TextComponent';
import { getReferentials } from '../../../../actions/globalActions';
import { EReferentials, EContextList, IReferencial } from '../../../../entities/IGlobal';
import { getClusterTemplates, uploadAttachment } from '../../../../actions/clustersActions';
import { MandatoryFieldStar } from '../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import * as PopupStyles from '../../Common/Styles';
import { DatePickerWrapper } from '../../../Common/DatePicker/DatePicker';
import { SelectInput } from '../../../Common/SelectInput/SelectInput';
import { IClusterTemplate, ETemplateKeys } from '../../../../entities/IClusters';
import { NewRT } from '../../../Common/RichText/NewRT';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { Row } from '../../../Common/Row/Row';
import { EDropDownType } from '../../../Common/DropDown/DropDown';
import { IValue } from '../../../../entities/IPickers';
import { formDataEncodeAndAppendFile } from '../../../../tools/files';
import { EUploaderFileFormat, IFileUploaderChangeEvent, IUploderFileError, Uploader } from '../../../Common/Attachment/Uploader';

type UploadAttachment = ReturnType<typeof uploadAttachment>;
type GetReferentials = ReturnType<typeof getReferentials>;
type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const SDocumentsRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
`;

const SInfo = styled.div`
    display: grid;
    grid-column: 1/3;
    margin-bottom: 1rem;
    grid-row: 6/6;
`;

const InnerRowFlex = styled.div`
    display: flex;
    align-items: center;
    > div {
        width: 100%;
    }
`;

interface IProps {
    organizationId: string;
}

export const Description: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { setFieldValue, values, errors } = useFormikContext<IFormData>();
    const [giftInvitationOptions, setGiftInvitationOptions] = useState<IReferencial[]>(undefined);
    const [valueRadio, setValueRadio] = useState<IReferencial[]>(undefined);
    const [unitItems, setUnitItems] = useState<IReferencial[]>(undefined);
    const [inputBlur, setInputBlur] = useState({
        nature: false,
        unit: false,
        number: false
    });
    const [templates, setTemplates] = useState<IClusterTemplate[]>(undefined);
    const [errorLoadingFiles, setErrorLoadingFiles] = useState<IUploderFileError[]>([]);

    const isGiftReciveAvailable = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.GiftReceive); }, [templates]);
    const isGiftOfferdAvailable = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.GiftSend); }, [templates]);
    const isInvitationReciveAvailable = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.InvitationReceive); }, [templates]);
    const isInvitationOfferedAvailable = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.InvitationSend); }, [templates]);

    useEffect(() => {
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
            setTemplates(clusterTemplates);
        });
    }, []);

    useEffect(() => {
        setFieldValue('step', 3);

        dispatch<GetReferentials>(getReferentials('', values.formData.declarationType === 'gift' ? EReferentials.GiftNature : EReferentials.InvitationNature, EContextList.GiftInvitation)).then(response => {
            setGiftInvitationOptions(response);
        });

        dispatch<GetReferentials>(getReferentials('', EReferentials.GiftValue, EContextList.GiftInvitation)).then(response => {
            setValueRadio(response);
        });
        dispatch<GetReferentials>(getReferentials('', EReferentials.Currency, EContextList.Platform)).then(response => {
            setUnitItems(response);
        });
    }, []);

    const handleBlur = useCallback((name: string) => {
        setInputBlur({
            ...inputBlur,
            [name]: true
        });
    }, [inputBlur]);

    const removeFile = (id: string) => {
        setFieldValue('formData.declaration.attachments', values.formData.declaration?.attachments.filter(file => file.fileId !== id));
    };

    const fileInputChange = (e: IFileUploaderChangeEvent) => {
        const file = e.rawFile;
        const form = new FormData();
        formDataEncodeAndAppendFile(form, 'attachment', file);
        dispatch<UploadAttachment>(uploadAttachment(form)).then(res => {
            const attachments = values.formData.declaration?.attachments || [];

            setFieldValue('formData.declaration.attachments', [...attachments, {
                name: res.name,
                directUri: res.directUri,
                size: res.size,
                fileId: res.fileId
            }]);
            setErrorLoadingFiles(state => state.filter(elem => elem.fileId !== e.fileId));
        }).catch((err: string) => {
            setErrorLoadingFiles(state => [...state, { fileId: e.fileId, error: err }]);
        });
    };

    const radioButtonChange = useCallback((type: 'offered' | 'received') => {
        if (type === 'received') {
            setFieldValue('formData.declaration.type', values.formData.declarationType === 'gift' ? EDeclarationTypeEnum.receivedGift : EDeclarationTypeEnum.receivedInvitation);
        }
        if (type === 'offered') {
            setFieldValue('formData.declaration.type', values.formData.declarationType === 'gift' ? EDeclarationTypeEnum.offeredGift : EDeclarationTypeEnum.offeredInvitation);
        }
        setFieldValue('formData.thirdParties', [{
            userData: {
                firstName: '',
                lastName: '',
                picture: '',
                Address: '',
                DataType: '',
                IsPublic: true,
                LegalForm: {
                    id: '',
                    Name: '',
                    Parents: []
                },
                Name: '',
                Picture: '',
                Size: '',
                TypeOfLegalEntity: {
                    id: '',
                    Name: '',
                    Parents: []
                },
                id: ''
            },
            fullName: '',
            jobTitle: '',
            legalEntity: undefined,
            email: '',
            relationshipNature: undefined,
            sensitivePerson: undefined,
            userId: undefined
        }]);
    }, [values.formData.declarationType]);

    const changeAmountNumber = (valueText: string, currency: IValue) => {
        setFieldValue('formData.declaration.amount.unit', currency?.key ? { key: currency?.data?.id || currency?.data?.key, uniqueKey: currency?.data?.Key || currency?.data?.uniqueKey, parentId: currency?.data?.parentId } : undefined);
        const value = valueText.replace(',', '.').replaceAll(' ', '');
        if (/^\d+\.?\d{0,6}$/.test(value) || value === '') {
            setFieldValue('amountValue', valueText.replaceAll(' ', ''));
            setFieldValue('formData.declaration.amount.number', value.length > 0 ? Number(value) : '');
        }
    };

    const estimatedRadio = valueRadio?.[0];
    const trueRadio = valueRadio?.[1];

    const changeRtHandler = (text: string) => {
        const contextLength = text ? new DOMParser().parseFromString(text?.replace(/[^\x20-\x7E]/gmi, '').trim(), 'text/html').documentElement.textContent.trim() : '';
        setFieldValue('formData.declaration.context', text || '');
        setFieldValue('contextLength', contextLength.length > 0);
    };

    return (
        <Wrapper>
            <Row numberOfElements={2} noBorder>
                <div>
                    <LabelWithEllipsis required>
                        {values.formData.declarationType === 'invitation'
                            ? <FormattedMessage id="giftinvitation.description.invitationType" />
                            : <FormattedMessage id="giftinvitation.description.giftType" />
                        }
                    </LabelWithEllipsis>
                    <InnerRowFlex>
                        {((values.formData.declarationType === 'gift' && isGiftReciveAvailable) || (values.formData.declarationType === 'invitation' && isInvitationReciveAvailable)) &&
                            <RadioButton
                                name="formData.declaration.type"
                                testingDataAttr="js-lc-radio-receivedGift"
                                value={values.formData.declarationType === 'gift' ? EDeclarationTypeEnum.receivedGift : EDeclarationTypeEnum.receivedInvitation}
                                label={values.formData.declarationType === 'gift'
                                    ? intl.formatMessage({ id: 'giftinvitation.description.receivedGift' })
                                    : intl.formatMessage({ id: 'giftinvitation.description.receivedInvitation' })}
                                checked={values.formData.declaration.type === EDeclarationTypeEnum.receivedGift || values.formData.declaration.type === EDeclarationTypeEnum.receivedInvitation}
                                onClick={() => radioButtonChange('received')}
                            />
                        }
                        {((values.formData.declarationType === 'gift' && isGiftOfferdAvailable) || (values.formData.declarationType === 'invitation' && isInvitationOfferedAvailable)) &&
                            <RadioButton
                                name="formData.declaration.type"
                                testingDataAttr="js-lc-radio-offeredGift"
                                value={values.formData.declarationType === 'gift' ? EDeclarationTypeEnum.offeredGift : EDeclarationTypeEnum.offeredInvitation}
                                label={values.formData.declarationType === 'gift'
                                    ? intl.formatMessage({ id: 'giftinvitation.description.offeredGift' })
                                    : intl.formatMessage({ id: 'giftinvitation.description.offeredInvitation' })}
                                checked={values.formData.declaration.type === EDeclarationTypeEnum.offeredGift || values.formData.declaration.type === EDeclarationTypeEnum.offeredInvitation}
                                onClick={() => radioButtonChange('offered')}
                            />
                        }
                    </InnerRowFlex>
                </div>

            </Row>
            <Row numberOfElements={2} noBorder>
                <div>
                    <LabelWithEllipsis required>
                        <FormattedMessage id="giftinvitation.description.nature" />
                    </LabelWithEllipsis>
                    <SelectInput
                        value={values.formData.declaration?.nature?.id ? [{ key: values.formData.declaration?.nature?.id, text: values.formData.declaration?.nature?.name, data: values.formData.declaration?.nature }] : undefined}
                        onChange={(val) => setFieldValue('formData.declaration.nature', val?.data)}
                        options={giftInvitationOptions?.map(elem => ({
                            key: elem.id,
                            text: elem.name,
                            data: elem
                        }))}
                        type={EDropDownType.DEFAULT}
                    />
                </div>
                <div>
                    <LabelWithEllipsis required>
                        {values.formData.declarationType === 'invitation'
                            ? <FormattedMessage id="giftinvitation.description.invitationDate" />
                            : <FormattedMessage id="giftinvitation.description.giftDate" />
                        }
                    </LabelWithEllipsis>
                    <DatePickerWrapper
                        value={values.formData.declaration.declarationDate}
                        onChange={(val) => setFieldValue('formData.declaration.declarationDate', val ? new Date(new Date(val).setHours(7)) : undefined)}
                    />
                </div>
            </Row>
            <Row numberOfElements={2} noBorder>
                <div>
                    <LabelWithEllipsis required>
                        <FormattedMessage id="giftinvitation.description.value" />
                    </LabelWithEllipsis>
                    <InnerRowFlex>
                        {trueRadio &&
                            <RadioButton
                                name="formData.declaration.value"
                                testingDataAttr="js-lc-radio-valueTrue"
                                value={trueRadio?.id}
                                label={trueRadio?.name}
                                checked={values.formData.declaration?.value?.id === trueRadio?.id}
                                onClick={() => setFieldValue('formData.declaration.value', trueRadio)}
                            />
                        }
                        {estimatedRadio &&
                            <RadioButton
                                name="formData.declaration.value"
                                testingDataAttr="js-lc-radio-valueEstimated"
                                value={estimatedRadio?.id}
                                label={estimatedRadio?.name}
                                checked={values.formData.declaration?.value?.id === estimatedRadio?.id}
                                onClick={() => setFieldValue('formData.declaration.value', estimatedRadio)}
                            />
                        }
                    </InnerRowFlex>
                </div>
                <div>
                    <LabelWithEllipsis required>
                        <FormattedMessage id="giftinvitation.description.amount" />
                    </LabelWithEllipsis>
                    <InnerRowFlex>
                        {unitItems?.length > 0 &&
                            <TextComponent
                                name="formData.declaration.amount.number"
                                value={values.amountValue}
                                error={inputBlur.number && !!errors?.formData?.declaration?.amount?.number}
                                onBlur={() => handleBlur('number')}
                                onChange={changeAmountNumber}
                                subType={ETextComponentSubType.CURRENCY}
                                currencies={unitItems?.sort((a, b) => a.name.localeCompare(b.name))}
                                selectedCurrency={(values.formData.declaration.amount.unit?.key && unitItems) ? { key: values.formData.declaration.amount.unit.key, text: unitItems?.find(elem => elem.id === values.formData.declaration.amount.unit?.key)?.name, data: values.formData.declaration.amount.unit } : undefined}
                            />
                        }
                    </InnerRowFlex>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis required>
                        <FormattedMessage id="giftinvitation.description.context" />
                    </LabelWithEllipsis>
                    <div>
                        <NewRT
                            content={values.formData.declaration.context}
                            onChange={changeRtHandler}
                        />
                    </div>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <Uploader
                        files={values.formData.declaration?.attachments?.map(file => ({ fileId: file.fileId, fileName: file.name, fileSize: file.size })) || []}
                        label={intl.formatMessage({ id: 'giftinvitation.description.attachment' })}
                        fileFormats={[EUploaderFileFormat.JPEG, EUploaderFileFormat.PNG, EUploaderFileFormat.PDF, EUploaderFileFormat.DOCX, EUploaderFileFormat.XLS, EUploaderFileFormat.XLSX]}
                        onFileChange={e => fileInputChange(e)}
                        removeAction={removeFile}
                        cancelAction={(fileId: string) => setErrorLoadingFiles(state => state.filter(elem => elem.fileId !== fileId))}
                        error={errorLoadingFiles}
                    />
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <SInfo>
                    <PopupStyles.RequiredInformation>
                        <PopupStyles.Title>
                            <MandatoryFieldStar />
                            <FormattedMessage id="giftinvitation.requiredInfo" />
                        </PopupStyles.Title>
                    </PopupStyles.RequiredInformation>
                </SInfo>
            </Row>
        </Wrapper>
    );
};
