import { ELcid, ISingleLcidTranslations, ISingleTranslate } from '../../entities/ILanguage';

export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_MANAGE_LANGUAGES = 'SET_MANAGE_LANGUAGES';
export const LOAD_NEW_LANG = 'LOAD_NEW_LANG';
export const FINISH_LOAD_NEW_LANG = 'FINISH_LOAD_NEW_LANG';
export const SET_CLUSTER_TRANSLATION_MESSAGES = 'SET_CLUSTER_TRANSLATION_MESSAGES';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_WORKSPACE_TRANSLATIONS = 'SET_WORKSPACE_TRANSLATIONS';
export const MERGE_TRANSLATIONS = 'MERGE_TRANSLATIONS';

export interface ILanguageTypes {
    SET_TRANSLATIONS: {
        lcid: ELcid;
        translations: ISingleLcidTranslations;
    };
    SET_WORKSPACE_TRANSLATIONS: {
        translations: ISingleTranslate[];
        orgId: string;
    }
    MERGE_TRANSLATIONS: {
        lcid: ELcid;
        translations: ISingleTranslate[];
    }
}
