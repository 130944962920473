import React, { FC, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faTimes } from '@fortawesome/pro-light-svg-icons';

import { ISgStakeholder } from '../../../entities/ISoge';
import { StakeholderCell } from './StakeholderCell';
import { LinkButton } from '../../Common/Buttons/Button';
import { IconButton, EIconButtonTypeSchema } from '../../Common/Buttons/IconButton';
import { colorStack } from '../../../styleHelpers/colors';

interface IStakeholderTableRowProps {
    stakeholder: ISgStakeholder;
    editMode?: boolean;
    onRemoveClick?(stakeholderId: string);
}

export const StakeholderTableRow: FC<React.PropsWithChildren<IStakeholderTableRowProps>> = ({ stakeholder, editMode, onRemoveClick }) => {
    const onRemoveClickCallback = useCallback(() => {
        onRemoveClick?.(stakeholder.id);
    }, [stakeholder.id, onRemoveClick]);

    return (
        <tr>
            <td><StakeholderCell stakeholder={stakeholder} />
            </td>
            <td>
                <LinkButton newWindow href={`mailto:${stakeholder.emailContact}`} ico={<FontAwesomeIcon icon={faEnvelopeOpenText} />} bgColor="transparent" fontColor={colorStack.middleBlue} margin="0 0 0 1rem">
                    <span>Mail</span>
                </LinkButton>
            </td>
            <td>
                {editMode && (
                    <IconButton
                        typeSchema={EIconButtonTypeSchema.TERTIARY}
                        icon={faTimes}
                        onClick={onRemoveClickCallback}
                    />
                )}
            </td>
        </tr>
    );
};