import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
`;

export const LastStep = () => {
    return (
        <Wrapper>
            <FormattedMessage id="legalSupport.success"  />
        </Wrapper>
    );
};