import { action } from '../tools/reduxTools';
import { ITheme } from '../entities/IConfiguration';
import { delay } from '../tools/generalTools';

// possibly move to adminActions, this is just mock
export const getTheme = () => action<Promise<ITheme>>(async (dispatch, getState, http) => {
    // simulate 3sec API response
    await delay(3000);

    // uncomment to test mock theme
    // return typed<ITheme>({
    //     activeColor: '#f28103',
    //     textColor: '#573ede'
    // });
    return undefined;
});
