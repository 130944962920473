const urlRegex = new RegExp(/^(http|https):\/\//);
const wwwRegex = new RegExp(/^www.(.*)/);
const validUrl = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i);

export const toValidLink = (link: string) => {
    if (!link) {
        return link;
    }

    return urlRegex.test(link) ? link : `http://${wwwRegex.test(link) ? '' : 'www.'}${link}`;
};

export const isValidLink = (link: string) => {
    return validUrl.test(link);
};

export const getBaseUrl = () => window.location.origin || `${window.location.protocol}//${window.location.host}`;
