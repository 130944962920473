import * as actionTypes from '../actions/actionTypes/globalTypes';
import { IReferencial } from '../entities/IGlobal';

export interface IGlobalReducer {
    referentials: {
        [key: string]: {
            [key: string]: IReferencial[];
        }
    };
    currencyRatio: {
        [key: string]: {
            [key: string]: number;
        }
    };
    progressData: number;
}

const defaultState = (): IGlobalReducer => ({
    referentials: {},
    currencyRatio: {},
    progressData: 0
});

export default (state = defaultState(), action): IGlobalReducer => {
    switch (action.type) {
        case actionTypes.SET_REFERENTIAL: {
            const payload: actionTypes.IGlobalTypes['SET_REFERENTIAL'] = action;
            return {
                ...state,
                referentials: payload.referentialsList
            };
        }
        case actionTypes.SET_CURRENCY_RATIO: {
            const payload: actionTypes.IGlobalTypes['SET_CURRENCY_RATIO'] = action;
            return {
                ...state,
                currencyRatio: payload.currencyRatio
            };
        }
        case actionTypes.SET_PROGRESS_DATA: {
            const payload: actionTypes.IGlobalTypes['SET_PROGRESS_DATA'] = action;
            return {
                ...state,
                progressData: payload.progressData
            };
        }
        default: {
            return state;
        }
    }
};