import React, { Component, createRef, ReactNode, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-light-svg-icons';

import { List, ListItem, ListTextWrapper, GroupHeader } from './PickerList';
import { Inner, Wrapper, Placeholder, ClearTagsButton } from './PickerWrapper';
import { ITag, ITagGroup, ETagPickerColor } from './ITagPicker';
import { EKeyCode } from '../../../tools/keyboardTools';
import { clearStringForSearch } from '../../../tools/string';
import { Loader } from '../Loader/Loader';
import { Pill } from '../Pill/Pill';

export type { ITag } from './ITagPicker';
export { ETagPickerColor as TagPickerColor } from './ITagPicker';

interface ITagGroupById<T = any> {
    [groupId: string]: ITag<T>[];
}

interface TagPickerState {
    inputValue: string;
    inputVisible: boolean;
    currentIndex: number;
    loading: boolean;
    allApiTags: ITag[];
}

interface TagPickerProps<T = unknown> {
    error?: boolean;
    allTags?: ITag<T>[];
    selectedTags: ITag<T>[];
    allowCreatingTags?: boolean;
    noResults?: ReactNode;
    limit?: number;
    tagColor?: ETagPickerColor;
    placeholder?: ReactNode;
    className?: string;
    noClearButton?: boolean;
    disabled?: boolean;
    groups?: {
        [groupId: string]: ReactNode;
    };
    suggestionIcon?: ReactNode | IconDefinition;
    allowQuerySearch?: boolean;
    defaultQuerySearch?: string;
    newDesign?: boolean;
    clearTagsOnInputChange?: boolean;
    onChange(tags: ITag<T>[], query?: string);
    onBlur?(e: React.FocusEvent<HTMLInputElement>);
    onClickOutside?(value: string): void;
    resolveTags?(query: string): Promise<ITag<T>[]>;
    renderSuggestion?(tag: ITag<T>): ReactNode;
    renderTag?(tag: ITag<T>, onRemoveTag: (tagId: string) => void): ReactNode;
    onClearSearchBox?()
}

export class TagPicker<T extends unknown> extends Component<TagPickerProps<T>, TagPickerState> {
    inputRef: React.RefObject<HTMLInputElement> = createRef();
    wrapperRef: React.RefObject<HTMLDivElement> = createRef();
    scrollerRef: React.RefObject<HTMLUListElement> = createRef();
    selectedElementRef: React.RefObject<HTMLLIElement> = createRef();
    isScrolling: boolean;
    scrollingDebounce: ReturnType<typeof setTimeout>;
    inputDebounce: ReturnType<typeof setTimeout>;

    constructor(props: TagPickerProps<T>) {
        super(props);

        this.state = {
            currentIndex: -1,
            inputValue: props.defaultQuerySearch || '',
            inputVisible: false,
            loading: false,
            allApiTags: []
        };
    }

    componentDidMount() {
        document.addEventListener('click', this.onClickOutside, true);
        document.addEventListener('keydown', this.handleKeyboard, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickOutside, true);
        document.removeEventListener('keydown', this.handleKeyboard, true);
    }

    componentDidUpdate(prevProps: TagPickerProps<T>) {
        if (this.props.clearTagsOnInputChange && prevProps.selectedTags !== this.props.selectedTags) {
            this.setState({ inputValue: '' });
        }
    }

    handleKeyboard = (e: KeyboardEvent) => {
        const { inputVisible, inputValue, allApiTags } = this.state;
        const { selectedTags, onChange, resolveTags, allTags, allowCreatingTags, limit, allowQuerySearch } = this.props;
        if (!inputVisible) return;
        const key = e.which || e.keyCode;

        if ([EKeyCode.Down, EKeyCode.Up, EKeyCode.Escape, EKeyCode.Enter].indexOf(key) !== -1) {
            e.preventDefault();
        }

        if (key === EKeyCode.Escape) {
            this.collapse();
        }

        if (!inputValue && key === EKeyCode.Backspace && selectedTags.length) {
            onChange(selectedTags.slice(0, selectedTags.length - 1));
        }

        if (key === EKeyCode.Down || key === EKeyCode.Up) {
            this.setState(currentState => {
                const filteredResults = !!resolveTags
                    ? currentState.allApiTags
                    : allTags.filter(this.filterTags);

                const currentIndex = key === EKeyCode.Down
                    ? currentState.currentIndex >= filteredResults.length - 1 ? 0 : currentState.currentIndex + 1 // down
                    : currentState.currentIndex <= 0 ? filteredResults.length - 1 : currentState.currentIndex - 1; // up

                return {
                    currentIndex
                };
            }, () => {
                const scroller = this.scrollerRef?.current;
                const currentLi = this.selectedElementRef?.current;

                if (scroller && currentLi) {
                    const elementPos = currentLi.offsetTop + currentLi.clientHeight;
                    const scrollerPos = scroller.clientHeight + scroller.scrollTop;

                    if (elementPos > scrollerPos) {
                        this.isScrolling = true;
                        scroller.scrollTo(0, scroller.scrollTop + (elementPos - scrollerPos));
                    }

                    if (currentLi.offsetTop < scroller.scrollTop) {
                        this.isScrolling = true;
                        scroller.scrollTo(0, currentLi.offsetTop);
                    }

                    clearTimeout(this.scrollingDebounce);
                    this.scrollingDebounce = setTimeout(() => {
                        this.isScrolling = false;
                    }, 150);
                }
            });
        }

        if (key === EKeyCode.Enter) {
            const selectedItem = ((!!resolveTags ? allApiTags : allTags.filter(this.filterTags)) || [])[this.state.currentIndex];

            if (limit && selectedTags?.length >= limit) {
                return;
            }

            if (selectedItem) {
                onChange([...selectedTags, selectedItem]);
            } else if (allowQuerySearch) {
                onChange([...selectedTags], inputValue);
            } else if (allowCreatingTags) {
                onChange([...selectedTags, {
                    value: inputValue
                }]);
            }

            this.setState({
                inputValue: !selectedItem && allowQuerySearch ? inputValue : '',
                loading: !!resolveTags,
                inputVisible: !allowQuerySearch
            }, () => {
                this.getApiTags();
            });
        }
    }

    collapse = () => {
        const { allowQuerySearch } = this.props;
        this.setState(state => ({
            inputVisible: false,
            inputValue: allowQuerySearch ? state.inputValue : '',
            currentIndex: -1
        }));
    }

    onClickOutside = (e: MouseEvent) => {
        const wrapperRef = this.wrapperRef && this.wrapperRef.current;

        if (this.state.inputVisible && wrapperRef && !wrapperRef.contains(e.target as Node)) {
            this.state.inputValue  &&
            this.props.onClickOutside?.(this.state.inputValue);
            this.collapse();
        }
    }

    removeTag = (tagId: string) => {
        this.props.onChange(this.props.selectedTags.filter(tag => (tag.id !== tagId)), 'removeTag');
        const inputRef = this.inputRef && this.inputRef.current;
        inputRef?.focus();
    }

    onClick = () => {
        const { resolveTags, allowQuerySearch } = this.props;

        if (!this.state.inputVisible) {
            this.setState(state => ({
                ...state,
                inputVisible: true,
                inputValue: allowQuerySearch ? state.inputValue : '',
                currentIndex: -1,
                loading: !!resolveTags,
                allApiTags: []
            }));

            this.getApiTags('');
        }
    }

    onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { resolveTags, clearTagsOnInputChange, onChange, selectedTags, limit } = this.props;
        const inputValue = e.target.value;

        this.setState({
            inputValue,
            inputVisible: true,
            currentIndex: -1,
            loading: !!resolveTags,
            allApiTags: []
        });

        if (clearTagsOnInputChange && limit && selectedTags?.length === limit) {
            onChange([]);
        }

        clearTimeout(this.inputDebounce);
        !!resolveTags && (this.inputDebounce = setTimeout(() => {
            this.getApiTags(inputValue);
        }, 600));
    }

    onItemClick = (clickedTag: ITag) => {
        const { onChange, selectedTags, resolveTags, limit } = this.props;

        if (limit && selectedTags?.length >= limit) {
            return;
        }

        onChange([...selectedTags, clickedTag]);
        this.setState({
            inputValue: '',
            currentIndex: -1,
            loading: !!resolveTags
        }, () => {
            const inputRef = this.inputRef && this.inputRef.current;
            inputRef?.focus?.();
            this.getApiTags('');
        });
    }

    onAddNewTagClick = () => {
        const { onChange, selectedTags, resolveTags, allowQuerySearch } = this.props;
        const { inputValue } = this.state;

        if (allowQuerySearch) {
            onChange([...selectedTags], inputValue);
        } else {
            onChange([...selectedTags, {
                value: inputValue
            }]);
        }

        this.setState({
            inputValue: allowQuerySearch ? inputValue : '',
            currentIndex: -1,
            loading: !!resolveTags,
            inputVisible: !allowQuerySearch
        }, () => {
            if (!allowQuerySearch) {
                const inputRef = this.inputRef && this.inputRef.current;
                inputRef?.focus?.();
                this.getApiTags('');
            }
        });
    }

    filterTags = (tag: ITag) => {
        const { selectedTags } = this.props;

        if (selectedTags.findIndex(tagObj => tagObj.value === tag.value) !== -1) {
            return false;
        }

        if (
            this.state.inputValue.trim() &&
            !clearStringForSearch(tag.value).match(clearStringForSearch(this.state.inputValue))
        ) {
            return false;
        }

        return true;
    }

    onDropdownItemMouseEnter = (currentIndex: number) => {
        if (!this.isScrolling) {
            this.setState({ currentIndex });
        }
    }

    getApiTags = (query?: string) => {
        const { resolveTags, selectedTags } = this.props;
        resolveTags?.(query || '').then(allApiTags => {
            this.setState({
                allApiTags: (allApiTags || []).filter(tag => {
                    return selectedTags.findIndex(selectedTag => selectedTag.value === tag.value) === -1;
                }),
                loading: false
            });
        });
    }

    onClearClick = () => {
        const { onChange, resolveTags, onClearSearchBox } = this.props;
        onClearSearchBox();
        onChange([]);
        this.setState({
            inputValue: '',
            currentIndex: -1,
            loading: !!resolveTags
        }, () => {
            const inputRef = this.inputRef?.current;
            inputRef?.focus?.();
            this.getApiTags('');
        });
    }

    getGroupedItems = (tags: ITag<T>[]): ITagGroup<T>[] => {
        const { groups } = this.props;
        if (groups) {
            const grouppedById: ITagGroupById<T> = tags.reduce((tagGroup, tag, index) => ({
                ...tagGroup,
                [tag.groupId]: [...(tagGroup[tag.groupId] || []), {
                    ...tag,
                    index: index
                }]
            }), {});
            return Object.keys(grouppedById).map(groupId => ({
                header: groups[groupId],
                groupId,
                items: grouppedById[groupId]
            }));
        } else {
            return [{
                header: undefined,
                groupId: undefined,
                items: tags,
                singleGroup: true
            }];
        }
    }

    render() {
        const { allTags, selectedTags, resolveTags, allowCreatingTags, noResults, limit, placeholder, tagColor, className, noClearButton,
            disabled, suggestionIcon, allowQuerySearch, renderSuggestion, renderTag, newDesign } = this.props;
        const { inputValue, inputVisible, currentIndex, loading, allApiTags } = this.state;
        const filteredTags: ITagGroup<T>[] = this.getGroupedItems(!!resolveTags
            ? allApiTags
            : (allTags || []).filter(this.filterTags));
        const isLimitReached = typeof limit === 'number' && selectedTags.length >= limit;

        const tagsLength = filteredTags.reduce((sum, tagGroup) => sum + tagGroup.items.length, 0);

        return (
            <Wrapper
                tabIndex={0}
                newDesign={newDesign}
                onClick={!disabled ? this.onClick : undefined}
                inputVisible={inputVisible}
                ref={this.wrapperRef}
                className={className || ''}
            >
                {(placeholder && !selectedTags.length && !inputValue && !inputVisible) && (
                    <Placeholder>{placeholder}</Placeholder>
                    )}
                <Inner newDesign={newDesign}>
                    {selectedTags?.map(tag => (
                        <Fragment key={tag.id || tag.value}>
                            {renderTag?.(tag, this.removeTag) || <Pill onClick={this.removeTag} text={tag.value} id={tag.id} />}
                        </Fragment>
                    ))}
                    {(inputVisible || (allowQuerySearch && !!inputValue) || newDesign) && (
                        <input
                            type="text"
                            value={inputValue}
                            onChange={this.onInputChange}
                            autoFocus={!(allowQuerySearch && !!inputValue) && !newDesign}
                            ref={this.inputRef}
                            disabled={disabled}
                            onFocus={this.onClick}
                            onBlur={this.props.onBlur}
                        />
                    )}
                    {!noClearButton && (
                        <ClearTagsButton onClick={this.onClearClick} />
                    )}
                </Inner>
                <List visible={inputVisible && !isLimitReached} ref={this.scrollerRef}>
                    {!!loading && (
                        <ListTextWrapper>
                            <Loader loading={loading} />
                        </ListTextWrapper>
                    )}
                    {allowQuerySearch && !!inputValue && (
                        <ListItem
                            innerRef={this.selectedElementRef}
                            selected
                            tag={{ value: inputValue }}
                            onClick={this.onAddNewTagClick}
                            index={-1}
                            icon={faSearch}
                        />
                    )}
                    {filteredTags?.map((tagGroup, groupIndex) => (
                        <Fragment key={tagGroup.groupId || groupIndex}>
                            {!tagGroup.singleGroup && (
                                <GroupHeader>{tagGroup.header}</GroupHeader>
                            )}
                            {tagGroup.items.map((tag, index) => {
                                const tagIndex = tag.index || index;
                                return (
                                    <ListItem
                                        innerRef={currentIndex === tagIndex ? this.selectedElementRef : undefined}
                                        selected={currentIndex === tagIndex}
                                        key={tag.id || tag.value}
                                        tag={tag}
                                        onClick={this.onItemClick}
                                        index={tagIndex}
                                        onMouseEnter={this.onDropdownItemMouseEnter}
                                        icon={suggestionIcon}
                                        renderSuggestion={renderSuggestion}
                                    />
                                );
                            })}
                        </Fragment>
                    ))}
                    {allowCreatingTags && !tagsLength && !!inputValue && (
                        <ListItem
                            add
                            innerRef={this.selectedElementRef}
                            selected
                            tag={{ value: inputValue }}
                            onClick={this.onAddNewTagClick}
                            index={-1}
                        />
                    )}
                    {!allowCreatingTags && !tagsLength && !loading && !allowQuerySearch && (
                        <ListTextWrapper>
                            {noResults || <FormattedMessage id="tagpicker.noresults" />}
                        </ListTextWrapper>
                    )}
                </List>
            </Wrapper>
        );
    }
}
