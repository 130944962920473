import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setAccessToken } from '../../actions/contextActions';

export const ShadowAuth: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const dispatch = useDispatch();
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (params.get('shadowAuth') === '1') {
            dispatch(setAccessToken(params.get('token'), '', true));
            setLoaded(true);
        } else {
            setLoaded(true);
        }
    }, [params.get('token')]);

    return <>{loaded && children}</>;
};
