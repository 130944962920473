import { Dispatch } from 'redux';

import { action, IDispatch } from '../tools/reduxTools';
import { ELcid } from '../entities/ILanguage';
import { ISingleStakeDefinitionList, ISingleStakeDefinitionGet, IStakeEditingObject, IAttachmentResponse, IStakeDataGridSettings, IStakeDataGridSource } from '../entities/IClusterStake';
import { IState } from '../reducers';
import * as actionTypes from './actionTypes/stakesTypes';
import { IDirectoryContent } from '../entities/IClustersFilesystem';

export const getStakeDefinitionList = () => action<Promise<ISingleStakeDefinitionList[]>>((dispatch: Dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiGet(`/stake-definitions?lcid=${language.userLanguage || ELcid.En}`).then((stakesDefinitionList) => {
        dispatch<IDispatch<actionTypes.IStakeTypes['GET_STAKES_DEFINISION_LIST']>>({
            type: actionTypes.GET_STAKES_DEFINISION_LIST,
            stakesDefinitionList
        });
        return stakesDefinitionList;
    });
});

export const downloadAttachment = (clusterId: string, currentPath: string, name: string) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/metadata`, {
        name,
        path: currentPath,
        includeFiles: true
    });
});

export const getSingleStakeDefinition = (stakeDefinitionId: string | number) => action<Promise<ISingleStakeDefinitionGet>, IState>((dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiGet(`/stake-definitions/${stakeDefinitionId}?lcid=${language.userLanguage || ELcid.En}`);
});

export const updateStakeDefinition = (clusterId: string, stakeId: string, body: IStakeEditingObject) => action<Promise<any>>((dispatch, getState, http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/stakes/${stakeId}`, body);
});

export const addStakeToCluster = (clusterId: string, body: IStakeEditingObject) => action<Promise<any>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/stakes`, body);
});

export const uploadAttachment = (clusterId: string, body: FormData, callback: (e: ProgressEvent) => void) => action<Promise<IAttachmentResponse>>((dispatch, getState, http) => {
    return http.clustersApiUpload(`/clusters/${clusterId}/stakes/attachments`, body, callback);
});

export const uploadSessionAttachment = (sessionId: string, body: FormData) => action<Promise<IAttachmentResponse>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/sessions/${sessionId}/stakes/attachments`, body);
});

export const getStakeDataGridSettings = (organization: string, stakeId: string) => action<Promise<IStakeDataGridSettings>, IState>((dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organization}/stake-datagrid/${stakeId}/settings?lcid=${language.userLanguage || ELcid.En}`);
});

export const getStakeDataGridSource = (organization: string, stakeId: string) => action<Promise<IStakeDataGridSource[]>, IState>((dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organization}/stake-definition/${stakeId}?lcid=${language.userLanguage || ELcid.En}`);
});

export const getRightStakesDefinition = (clusterId: string) => action<Promise<ISingleStakeDefinitionGet[]>>(async (dispatch, getState, http) => {
    return http.clustersApiGet(`/clusters/${clusterId}/stake-definitions`);
});