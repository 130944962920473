import { IItemResults } from '../../entities/IFilters';

export const GET_EXPLORE_ELEMENTS = 'GET_EXPLORE_ELEMENTS';
export const GET_AND_MERGE_EXPLORE_ELEMENTS = 'GET_AND_MERGE_EXPLORE_ELEMENTS';
export const CLEAR_EXPLORE_ELEMENTS = 'CLEAR_EXPLORE_ELEMENTS';

export interface IExploreTypes {
    [GET_EXPLORE_ELEMENTS]: {
            items: Map<string, IItemResults>;
            facets: {[key: string]: any};
            count: number;
            itemsOnPageCount: number;
            page: number;
            size: number;
    }
    [GET_AND_MERGE_EXPLORE_ELEMENTS]: {
            items: Map<string, IItemResults>;
            facets: {[key: string]: any};
            count: number;
            itemsOnPageCount: number;
            page: number;
            size: number;
    }
}
