export enum EFilesystemItemType {
    Folder = 0,
    File = 1
}

export enum EFilesystemSubType {
    Specific = 'Specific',
    Generic = 'Generic',
    Trashbin = 'Trashbin'
}

export interface IDirectoryContent {
    parents?: string[];
    fileId?: string;
    description?: string;
    name: string;
    items?: IDirectoryContent[];
    type: EFilesystemItemType;
    isSendSignature?: boolean;
    isApproved?: boolean;
    envelopeId?: string;
    lastModificationDate?: string;
    user?: {
        firstName: string;
        lastName: string;
        jobTitle: string;
        department: string;
        picture: string;
        cabinet: string;
        id: string;
    };
    contentType?: string;
    directUri?: string;
    subType: EFilesystemSubType;
    locked?: boolean;
    permissions?: string[];
    size?: number;
}

export interface ISelectedRows<T> {
    [key: string]: {
        index: number;
        name: string;
        item: T;
    }
}

export enum EClusterFSError {
    DirectoryDoesntExits = 1100,
    ItemAlreadyExists = 1000
}

export interface IUploadFile {
    file: File;
    name: string;
}

export interface IDirectoryTreeData {
    locked: boolean;
    subType: EFilesystemSubType;
}

export interface IGIAttachmentUploadResponse {
    contentType: string;
    directUri: string;
    fileId: string;
    isSendSignature: boolean;
    lastModificationDate: string;
    name: string;
    parents: [];
    size: number;
    type: number
}

export interface IBodyForCopyMoveActions {
    recipientClusterId: string;
    items: {
        item: string;
        finalItemName: string;
        type: number;
    }[];
    path: string;
    recipientPath: string;
}

export enum ECellType {
    File = 'File',
    Folder = 'Folder'
}

export const mimeToText: { [key: string]: string } = {
    'text/plain': 'Notepad',

    'image/bmp': 'image',
    'image/gif': 'image',
    'image/jpeg': 'image',
    'image/png': 'image',
    'image/tiff': 'image',
    'image/x-tiff': 'image',
    'image/webp': 'image',

    'application/pdf': 'PDF',

    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word',
    'application/msword': 'Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'Word',
    'application/vnd.oasis.opendocument.text': 'Word',
    'application/rtf': 'Word',
    'application/x-rtf': 'Word',
    'text/richtext': 'Word',

    'application/powerpoint': 'PowerPoint',
    'application/vnd.ms-powerpoint': 'PowerPoint',
    'application/x-mspowerpoint': 'PowerPoint',
    'application/mspowerpoint': 'PowerPoint',
    'application/vnd.openxmlformats-officedocument.presentationml.template': 'PowerPoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'PowerPoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slide': 'PowerPoint',
    'application/vnd.oasis.opendocument.presentation': 'PowerPoint',

    'text/csv': 'Excel',
    'application/vnd.ms-excel': 'Excel',
    'application/vnd.oasis.opendocument.spreadsheet': 'Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'Excel'
};