import React, { FC, ReactNode, useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { faChevronUp, faChevronDown, faPen, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WhiteBox } from '../WhiteBox/WhiteBox';
import { WhiteBoxHeader } from '../WhiteBox/WhiteBoxHeader';
import { Button, EButtonTypeSchema } from '../Buttons/NewButton';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { newDesignThemeSwitch } from '../../NewDesignCommon/NewDesignSwitch/newDesignTheme';

const Wrapper = styled(WhiteBox) <{ transparent?: boolean; pageBreakOn?: boolean; }>`
    margin-bottom: 1rem;
    ${props => props.transparent && css`
        background: transparent;
        border: none;
    `}
    ${props => props.pageBreakOn && css`
        page-break-inside:avoid;
    `}

    ${(props) => newDesignThemeSwitch(props, () =>
    css`
            margin-bottom: 7px;
        `
)}`;

const Content = styled.div<{ withIcon?: boolean; noMargin?: boolean, isTitle?: boolean }>`
    margin-top: 2rem;
    ${props => (props.withIcon || props.noMargin) && css`
        margin-top: 1rem;
    `}
    ${props => (props.noMargin && !props.title) && css`
        margin-top: 0rem;
    `}

    ${(props) => newDesignThemeSwitch(props, () => css`margin-top: 0;`
)}`;

const HeaderButton = styled.button<{ withIcon?: boolean }>`
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${colorStack.darkBlue};
    svg {
        margin-right: 1rem;
    }
    ${props => props.withIcon && css`
        font-size: ${fontSize[13]};
        font-weight: normal;
        svg {
            margin-right: .5rem;
        }
    `}

    ${(props) => newDesignThemeSwitch(props, (theme) =>
    css`
            color: ${theme.colors.primary};
            font-family: ${theme.font.primary};
            font-size: ${fontSize[16]};
        `
)}`;

const Separator = styled.div`
    ${(props) => newDesignThemeSwitch(props, () =>
    css`
            height: 1px;
            margin: 10px -${fontSize[16]};
            background-color: ${colorStack.ligthGrey};
        `
)}`;

const EditButton = styled.button`
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.2rem 0 0.2rem 0.2rem;

    svg {
        margin-right: 0.5rem;
    }
`;

const EditWrapper = styled.div`
    button + button {
        margin-left: 0.5rem;
    }
`;

interface IProps {
    title?: ReactNode;
    editMode?: boolean;
    enableEditMode?: boolean;
    transparent?: boolean;
    nonCollapsable?: boolean;
    isCollapsed?: boolean;
    withIcon?: ReactNode;
    noMargin?: boolean;
    rightCol?: ReactNode;
    submitLabel?: ReactNode;
    submitIco?: IconDefinition;
    counter?: ReactNode;
    scrollId?: string;
    pageBreakOn?: boolean;
    onEditClick?();
    onCancelClick?();
    onSavelClick?();
}

export const WhiteCollapsedSection: FC<React.PropsWithChildren<IProps>> = ({ onSavelClick, children, title, onEditClick, onCancelClick, editMode, enableEditMode, transparent, nonCollapsable, withIcon, rightCol, submitLabel, submitIco, counter, noMargin, isCollapsed, scrollId, pageBreakOn }) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    useEffect(() => {
        setCollapsed(isCollapsed);
    }, [isCollapsed]);

    const onHeaderClick = useCallback(() => {
        setCollapsed(currentState => !currentState);
    }, []);

    return (
        <Wrapper transparent={transparent}>
            {scrollId &&
                <a data-scrollId={scrollId} data-scrollName={title} />
            }
            <WhiteBoxHeader rightSide={((!!onEditClick && enableEditMode && !collapsed) || rightCol) && (
                <>
                    {rightCol}
                    {!rightCol && (
                        <>
                            {editMode ? (
                                <EditWrapper>
                                    <Button type="button" typeSchema={EButtonTypeSchema.TERTIARY} onClick={onCancelClick}>
                                        <FormattedMessage id="global.cancel" />
                                    </Button>
                                    {onSavelClick ? (
                                        <Button leftIco={submitIco} onClick={onSavelClick}>
                                            {submitLabel || <FormattedMessage id="global.saveChanges"  />}
                                        </Button>
                                    ) : (
                                        <Button type="submit" leftIco={submitIco}>
                                            {submitLabel || <FormattedMessage id="global.saveChanges" />}
                                        </Button>
                                    )}
                                </EditWrapper>
                            ) : (
                                <EditButton onClick={onEditClick} type="button">
                                    <FontAwesomeIcon icon={faPen} />
                                    <FormattedMessage id="global.edit" />
                                </EditButton>
                            )}
                        </>
                    )}
                </>
            )}>
                <HeaderButton onClick={!nonCollapsable && onHeaderClick} withIcon={!!withIcon} type="button">
                    {!nonCollapsable && (
                        <FontAwesomeIcon icon={collapsed ? faChevronDown : faChevronUp} size="xs" />
                    )}
                    {withIcon}
                    {title || ''}
                    {counter}
                </HeaderButton>
            </WhiteBoxHeader>
            {(!collapsed || nonCollapsable) && (
                <>
                    <Separator />
                    <Content withIcon={!!withIcon} noMargin={noMargin} isTitle={!!title}>
                        {children}
                    </Content>
                </>
            )}
        </Wrapper>
    );
};