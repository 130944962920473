import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../../../styleHelpers/colors';

interface IFolderIconProps {
    marginRight?: boolean;
    trash?: boolean;
    color?: string;
}

const IconWrapper = styled.span<IFolderIconProps>`
    position: relative;
    svg {
        font-size: 16px;
    }
    .overlay {
        position: absolute;
        left: 0;
        top: 0.125em;
    }

    ${props => props.marginRight && css`
        margin-right: 0.5rem;
    `}
`;

export const FolderIcon: FC<React.PropsWithChildren<IFolderIconProps>> = props => (
    <IconWrapper marginRight={props.marginRight}>
        {props.trash ?
            <FontAwesomeIcon icon={faTrashCan} />
            :
            <FontAwesomeIcon
                color={props.color || colorStack.middleOrange}
                icon={faFolder}
            />
        }
    </IconWrapper>
);
