import React, { FC, useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';

import { ITableOfContentsNode } from '../../../entities/ISoge';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { DropdownSpinnerWrapper } from './Styles';
import { LinkButton } from '../../Common/Buttons/Button';
import { StructureDetailsDropdown } from '../LegalDocument/StructureDetailsDropdown';
import { ITreePathItem } from '../../Common/TreeView/TreeView';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';

export const ReferenceText = styled.span`
    display: inline-flex;
    align-items: center;
    padding: .2rem 0.5rem;
    margin-left: 1rem;
    border: 1px solid ${colorStack.middleGrey};
    color: ${colorStack.middleGrey};
    border-radius: 4px;
    font-size: ${fontSize[13]};
    svg {
        margin-right: .5rem;
    }
`;

export const ReadButton = styled(LinkButton)`
    margin-left: 1rem;
    visibility: hidden;
    > a {
        padding: 0.3rem .5rem;
    }
`;

export const LinkWrapper = styled.span`
    display: inline-flex;
    width: 100%;
    padding: 0;
    font-size: ${fontSize[13]};
    min-height: 2rem;
    align-items: center;
    margin-left: -2rem;
    position: relative;
    &:hover {
        ${ReferenceText} {
            display: none;
        }
        ${ReadButton} {
            display: inline-flex;
            visibility: visible;
        }
    }
`;

export const ExpandButton = styled.button`
    margin: 0;
    padding: .2rem 0.5rem .2rem 2.5rem;
    border: 0;
    background: transparent;
    outline: none;
    display: inline;
    font-size: ${fontSize[16]};
    color: ${colorStack.darkBlue};
    cursor: pointer;
    text-align: left;
`;

export const LastChildLink = styled(Link)`
    font-size: ${fontSize[16]};
    color: ${colorStack.darkBlue};
    padding: 0.2rem 0.5rem 0.2rem 2.5rem;
    display: inline-block;
    &:hover {
        text-decoration: none;
    }
`;

interface ISgTocItemProps {
    hasChildren: boolean;
    item: ITableOfContentsNode;
    url: string;
    renderPath: ITreePathItem[];
    currentDownloadingElementId?: string;
    documentVersionName?: string;
    readElementLabel?: ReactNode;
    noRedirect?: boolean;
    disableReadButton?: boolean;
    expandChildren(): void;
    onCopyUrlClick?(documentVersionName: string, commonLevelId: string, articleCommonLevelId?: string);
    downloadStructureLevel?(levelId: string, structureLevelRootCommonLevelId: string, levelName: string);
    onReadClick?(): void;
}

export const SgTocItem: FC<React.PropsWithChildren<ISgTocItemProps>> = ({ hasChildren, item, url, currentDownloadingElementId, renderPath, documentVersionName, readElementLabel, noRedirect, disableReadButton, expandChildren, onCopyUrlClick, downloadStructureLevel, onReadClick }) => {
    const onDownloadClick = useCallback(() => {
        downloadStructureLevel?.(item.commonLevelId, renderPath[0].commonLevelId, item.name);
    }, [downloadStructureLevel, item, renderPath]);

    const redirectUrl = noRedirect ? '#' : url;

    return (
        <LinkWrapper>
            {hasChildren ? (
                <ExpandButton onClick={expandChildren} type="button">{item.name}</ExpandButton>
            ) : (
                <LastChildLink to={redirectUrl} onClick={onReadClick}>{item.name}</LastChildLink>
            )}
            {(onCopyUrlClick || downloadStructureLevel) && (
                <StructureDetailsDropdown
                    commonElementId={item.commonLevelId}
                    onDownloadClick={onDownloadClick}
                    onCopyUrlClick={onCopyUrlClick}
                    documentVersionName={documentVersionName}
                />
            )}
            {(currentDownloadingElementId && currentDownloadingElementId === item.commonLevelId) && (
                <DropdownSpinnerWrapper>
                    <Spinner size={ESpinnerSize.SMALL} />
                </DropdownSpinnerWrapper>
            )}
            {item.hasReferenceText && (
                <ReferenceText>
                    <FontAwesomeIcon icon={faFileAlt} />
                    <FormattedMessage id="norms.label.text" />
                </ReferenceText>
            )}
            {!disableReadButton && (
                <ReadButton ico={faFileAlt} to={redirectUrl} bgColor={rgba(colorStack.darkBlue, .1)} fontColor={colorStack.middleBlue} onClick={onReadClick}>
                    {readElementLabel || <FormattedMessage id="norms.label.read" />}
                </ReadButton>
            )}
        </LinkWrapper>
    );
};
