export const wopiFunc = () => {
    let office_frame = document.createElement('iframe');
    let frameholder = document.getElementById('frameholder');
    office_frame.name = 'office_frame';
    office_frame.id = 'office_frame';
    office_frame.title = 'Office Online Frame';
    office_frame.setAttribute('allowfullscreen', 'false');
    office_frame.setAttribute('class', 'custom-iframe-wopi');
    /* office_frame.setAttribute('width', '100%');
    office_frame.setAttribute('height', '700px'); */
    frameholder.appendChild(office_frame);
    document.getElementById('office_form').submit();
}