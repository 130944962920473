import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons';

import { IFullBranch, IBranchWithContents } from '../../../entities/ISoge';
import { getBranchUrl } from '../../../tools/legalDocTools/sogeTools';
import { fontSize } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    padding: 0 1rem 1rem 1rem;
    font-size: ${fontSize[13]};
    margin-top: -.5rem;
    > span, li {
        margin-bottom: .3rem;
        display: block;
    }
    li svg {
        margin-right: .3rem;
    }
`;

interface IRelatedBranchesProps {
    organizationUrlName: string;
    versionId: string;
    branch: IFullBranch;
    allRelatedBranches: IFullBranch[];
}

export const RelatedBranches: FC<React.PropsWithChildren<IRelatedBranchesProps>> = ({ branch, organizationUrlName, versionId, allRelatedBranches }) => {
    const relatedBranches = useMemo(() => {
        return branch?.id && (allRelatedBranches || []).filter(relatedBranch => relatedBranch.id !== branch.id);
    }, [branch?.id, allRelatedBranches]);

    return (
        <>
            {!!relatedBranches?.length && (
                <Wrapper>
                    <FormattedMessage id="norms.relatedBranches" />
                    <ul>
                        {(relatedBranches || []).map(relatedBranch => (
                            <li key={relatedBranch.id}>
                                <Link to={getBranchUrl(organizationUrlName, versionId, relatedBranch.relatedItemCrossId, relatedBranch.id)}>
                                    <FontAwesomeIcon icon={faCodeBranch} />
                                    {relatedBranch.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Wrapper>
            )}
        </>
    );
};
