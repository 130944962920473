import React, { FC } from 'react';

import { IAddress } from '../../../entities/IProfile';
import { IAddressGlobal } from '../../../entities/IGlobal';

// IAddress interface is deprecated
interface IGoogleAddressViewProps {
    address: IAddress;
}
interface IGoogleAddressViewGlobalProps {
    address: IAddressGlobal;
}

// Deprecated use addressFormatterGlobal from tools/addressFormatter
export const GoogleAddressView: FC<React.PropsWithChildren<IGoogleAddressViewProps>> = ({ address }) => (
    <>
        {address?.Number || ''}{address?.Street && ` ${address?.Street}`}{(address?.Street || address?.Number) && ', '}{address?.ZipCode}{address?.ZipCode && ', '}{address?.City || ''}{address?.Country && ', '}{address?.Country || ''}
    </>
);

// Deprecated use addressFormatterGlobal from tools/addressFormatter
export const GoogleAddressViewGlobal: FC<React.PropsWithChildren<IGoogleAddressViewGlobalProps>> = ({ address }) => (
    <>
        {address?.number}{address?.street && ` ${address?.street}`}{(address?.street || address?.number) && ', '}{address?.zipCode}{address?.zipCode && ', '}{address?.city}{address?.city && ', '}{address?.country}
    </>
);
