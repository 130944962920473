import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { Avatar } from '../../../Common/Avatar/Avatar';

const SingleElementWrapper = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem 1rem;
    transition: background-color .2s ease;
    svg {
        color: ${colorStack.darkGrey};
    }
    &:hover {
        background-color: ${colorStack.lightBlue};
    }
`;

const Icon = styled(FontAwesomeIcon)`
    min-width: 25px;
`;

const ContextInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContextName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    color: ${colorStack.content};
    text-overflow: ellipsis;
    width: 200px;
    margin: 0 0 0 15px;
`;

const UserPosition = styled.span`
    color: ${colorStack.disabled};
    margin: 0 15px;
    font-size: ${fontSize[10]};
`;

interface ISingleElement {
    textNode: string | React.ReactElement;
    additionalInfo?: number | string | React.ReactElement;
    imagePath?: string;
    icon?: FontAwesomeIconProps['icon'];
    link?: string;
    orgName?: string;
    orgId?: string;
    actionHandler?();
    selectHandler?(orgId: string);
    contextSwitch?(name: string);
}

// tslint:disable-next-line:no-null-keyword
export const SingleElement: FC<React.PropsWithChildren<ISingleElement>> = ({ imagePath, icon, selectHandler, actionHandler, textNode, additionalInfo = null, link, contextSwitch, orgName, orgId }) => {
    const history = useNavigate();
    const onClick = useCallback(() => {
        contextSwitch?.(orgName);
        actionHandler?.();
        selectHandler?.(orgId);
        link && history(link);
    }, [link, orgName, orgId]);

    return (
        <SingleElementWrapper onClick={onClick}>
            {icon ? (
                <Icon icon={icon} size="lg" />
            ) : (
                <Avatar
                    picture={imagePath}
                    firstName=""
                    lastName=""
                    id="noId"
                    preventRedirection
                />
            )}
            <ContextInfoWrapper>
                <ContextName>
                    {textNode}
                </ContextName>
                {additionalInfo &&
                    <UserPosition>
                        {additionalInfo}
                    </UserPosition>
                }
            </ContextInfoWrapper>
        </SingleElementWrapper>
    );
};