import React, { ReactNode } from 'react';

import { ESpinnerSize, Spinner } from '../Spinner/Spinner';
import * as Components from './Components';

export interface ILoaderProps {
    loading: boolean;
    className?: string;
    label?: string | React.ReactNode;
    saving?: boolean;
    size?: ESpinnerSize;
    children?: ReactNode;
}

export class Loader extends React.Component<ILoaderProps, {}> {

    render() {
        const { className, label,  size = ESpinnerSize.LARGE, loading, saving } = this.props;
        return (
            <div className="loader-wrapper">
                {loading ?
                    <div className={className}>
                        <Spinner size={size} label={label} />
                    </div> : (
                        saving ? (
                            <div>
                                <Components.SavingWrapper>
                                    <Spinner size={size} label={label} />
                                </Components.SavingWrapper>
                                {this.props.children}
                            </div>
                        ) : (this.props.children)
                    )}
            </div>);
    }
}
