import { IReferencial, IViewReferential } from './IGlobal';
import { ISIngleLegalEntity } from './ILegalEntities';

export interface IFormData {
    formData: {
        declarationType: string;
        declaration: {
            type: string;
            nature: {
                id: string;
                name: string;
                key: string;
                logicalId: 0,
                parents: string[];
            }
            declarationDate: Date;
            value: {
                id: string;
                name: string;
                key: string;
                logicalId: 0,
                parents: string[];
            };
            amount: {
                number: number;
                unit: IViewReferential;
                baseNumber: number;
                baseUnit: IViewReferential
            };
            attachments: {
                name: string;
                directUri: string;
                size: number;
                fileId: string;
            }[];
            context: string
        };
        thirdParties: {
            userData: {
                firstName: string;
                lastName: string;
                picture: string;
                Address: string;
                DataType: string;
                IsPublic: boolean;
                LegalForm: {
                    id: string;
                    Name: string;
                    Parents: string[];
                };
                Name: string;
                Picture: string;
                Size: string;
                TypeOfLegalEntity: {
                    id: string;
                    Name: string;
                    Parents: string[];
                };
                id: string;
            }
            fullName: string;
            jobTitle: string;
            legalEntity: ISIngleLegalEntity;
            email: string;
            relationshipNature: {
                id: string;
                name: string;
                key: string;
                logicalId: 0,
                parents: string[];
            };
            sensitivePerson: boolean;
            userId: string
        }[];
        information: {
            fullName: string;
            firstName: string,
            lastName: string,
            jobTitle: string;
            picture: string;
            legalEntity: ISIngleLegalEntity
            manager: {
                firstName: string;
                lastName: string;
                jobTitle: string;
                picture: string;
                cabinet: string;
                id: string;
            };
            userId: string
        };
        message: {
            subject: string;
            body: string
        }
    };
    step: number;
    amountValue: string;
    readPolicy: boolean;
    contextLength: boolean;
}

export interface IBeneficiary {
    userData: {
        firstName: string;
        lastName: string;
        picture: string;
        Address: string;
        DataType: string;
        IsPublic: boolean;
        LegalForm: {
            id: string;
            Name: string;
            Parents: string[];
        };
        Name: string;
        Picture: string;
        Size: string;
        TypeOfLegalEntity: {
            id: string;
            Name: string;
            Parents: string[];
        };
        id: string;
    }
    fullName: string;
    jobTitle: string;
    legalEntity: ISIngleLegalEntity;
    email: string;
    relationshipNature: {
        id: string;
        name: string;
        key: string;
        logicalId: 0,
        parents: string[];
    };
    sensitivePerson: boolean;
    userId: string
}

export interface UserProfileBody {
    search: string,
    hideCurrentUser: boolean;
}

export enum EDeclarationTypeEnum {
    offeredGift = 'OfferedGift',
    receivedGift = 'ReceivedGift',
    offeredInvitation = 'OfferedInvitation',
    receivedInvitation = 'ReceivedInvitation',
    CoiNoLink = 'CoiNoLink',
    CoiElectedPosition = 'CoiElectedPosition',
    CoiFamilyInterest = 'CoiFamilyInterest',
    CoiFinance = 'CoiFinance',
    CoiAssociationMandate = 'CoiAssociationMandate',
    CoiOtherLink = 'CoiOtherLink'
}

export enum EDeclarationIdEnum {
    Gift = 10,
    Invitation = 20,
    COI = 30,
    NoLinkCoi = 40
}

export interface IFocusedElementTag {
    firstName: string;
    lastName: string;
    picture: string;
    Address: string;
    DataType: string;
    IsPublic: boolean;
    LegalForm: {
        id: string;
        Name: string;
        Parents: string[];
    };
    Name: string;
    Picture: string;
    Size: string;
    TypeOfLegalEntity: {
        id: string;
        Name: string;
        Parents: string[];
    };
    id: string;
}