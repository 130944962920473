import styled from 'styled-components';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

export const RefTextTag = styled.span`
    display: inline-block;
    background: ${colorStack.whiteBlue};
    color: ${({ theme }) => theme.colors?.secondary || colorStack.darkBlue};
    font-size: ${fontSize[13]};
    padding: .2rem .5rem;
    margin-left: 0.2rem;
`;
