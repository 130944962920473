import React, { FC, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EPOAContext, IPOAContext } from '.';

const Wrapper = styled.div``;

interface IProps {}

export const Step3: FC<IProps> = props => {
    const intl = useIntl();
    const POAContext = useContext<IPOAContext>(EPOAContext);

    useEffect(() => {
        POAContext.modifyData(undefined, undefined, 1);
    }, []);

    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'poa.wizard.step3.content'})}} />
        </Wrapper>
    );
};