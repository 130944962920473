import React from 'react';

import { Icon } from '../../../entities/IGlobal';

export const Members = (props: Icon) => (
    <svg version="1.1" width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
        <g>
            <g>
                <path d="M511.642,429.784l-13.692-93.269c-4.715-32.165-23.622-60.775-51.966-77.637c21.974-16.789,36.184-43.248,36.184-72.975
			c0-50.612-41.177-91.79-91.79-91.79c-14.41,0-28.437,3.365-41.08,9.694c-16.486-35.631-52.574-59.645-93.293-59.645
			c-41.28,0-76.948,24.465-93.283,59.655c-12.647-6.334-26.683-9.704-41.098-9.704c-50.613,0-91.79,41.177-91.79,91.79
			c0,29.728,14.212,56.186,36.184,72.975c-28.294,16.832-47.243,45.416-51.966,77.633L0.359,429.785
			c-2.945,20.059,12.623,38.054,32.885,38.054c23.256,0,423.072,0,445.512,0C499.032,467.839,514.584,449.831,511.642,429.784z
			 M358.395,137.915c9.417-6.28,20.509-9.67,31.984-9.67c31.793,0,57.657,25.865,57.657,57.657
			c0,31.799-25.865,57.671-57.657,57.671c-22.975,0-43.57-13.71-52.698-34.213C353.622,188.551,360.581,162.943,358.395,137.915z
			 M256.004,78.294c37.599,0,68.649,30.451,68.649,68.649c0,37.48-30.276,68.663-68.649,68.663
			c-37.88,0-68.649-30.832-68.649-68.663C187.355,109.106,218.138,78.294,256.004,78.294z M63.964,185.903
			c0-31.791,25.865-57.657,57.657-57.657c11.474,0,22.566,3.389,31.984,9.669c-2.224,25.481,5.06,51.009,20.714,71.446
			c-9.128,20.504-29.724,34.213-52.698,34.213C89.83,243.574,63.964,217.702,63.964,185.903z M119.378,427.069
			c-0.324,2.22-0.428,4.618-0.344,6.637H34.282l13.541-92.24c5.382-36.711,36.895-63.759,73.8-63.759
			c8.779,0,17.41,1.535,25.547,4.502C135.043,305.962,135.11,319.829,119.378,427.069z M357.378,433.706H154.63
			c-0.941,0-1.601-0.823-1.471-1.693l15.694-106.985c6.316-42.982,43.231-75.288,87.151-75.288
			c43.003,0,80.721,31.483,87.151,75.285l15.693,106.978C358.981,432.912,358.296,433.706,357.378,433.706z M392.966,433.705
			c0.082-1.989-0.021-4.425-0.345-6.646c-16.239-110.697-15.229-120.23-27.791-144.851c8.137-2.967,16.768-4.502,25.547-4.502
			c36.876,0,68.414,27.016,73.801,63.763l13.539,92.236H392.966z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
);