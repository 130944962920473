import queryString from 'query-string-for-all';

import { Http } from '../tools/http';
import * as actionTypes from './actionTypes/searchTypes';
import { ISearch } from '../entities/ISearch';
import { action } from '../tools/reduxTools';

export const getSearchResult = (search: string, page: number, size: number, filter: any) => action<Promise<ISearch>>((dispatch, getState, http: Http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({
        page: page,
        size: size,
        lcid: userLanguage
    });
    const body = {
        departments: filter.departments,
        teams: filter.teams,
        languages: filter.languages,
        barAdmissions: filter.barAdmissions,
        expertises: filter.expertises,
        competences: filter.competences,
        businessSectors: filter.businessSectors,
        countries: filter.countries,
        talents: filter.talents,
        roleFunctions: filter.roleFunctions,
        roleCategories: filter.roleCategories,
        firstReferentials: filter.firstReferentials,
        secondReferentials: filter.secondReferentials,
        thirdReferentials: filter.thirdReferentials,
        search
    };
    const url = `/users/search?${query}`;
    return http.profilesApiPost(url, body)
        .then((searchResult: ISearch) => {
            return searchResult;
        }).catch(err => {
            throw new Error(err);
        });
});

export const getPLEPeople = (search: string, page: number, size: number, filter: any, legalEntityId: string) => action<Promise<ISearch>>((dispatch, getState, http: Http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({
        page: page,
        size: size,
        lcid: userLanguage
    });
    const body = {
        departments: filter.departments,
        teams: filter.teams,
        languages: filter.languages,
        barAdmissions: filter.barAdmissions,
        expertises: filter.expertises,
        competences: filter.competences,
        businessSectors: filter.businessSectors,
        countries: filter.countries,
        talents: filter.talents,
        roleFunctions: filter.roleFunctions,
        roleCategories: filter.roleCategories,
        firstReferentials: filter.firstReferentials,
        secondReferentials: filter.secondReferentials,
        thirdReferentials: filter.thirdReferentials,
        search
    };
    const url = `/legal-entities/${legalEntityId}/users/search?${query}`;
    return http.profilesApiPost(url, body)
        .then((searchResult: ISearch) => {
            dispatch({
                type: actionTypes.GET_SEARCH_RESULT,
                searchResult: {
                    items: searchResult.items,
                    facets: searchResult.facets,
                    count: searchResult.count
                }
            });
            return searchResult;
        }).catch(err => {
            throw new Error(err);
        });
});

export const clearSearchResult = () => ({
    type: actionTypes.CLEAR_SEARCH_RESULT
});
