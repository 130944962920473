import React, { useState, useEffect, useCallback, FunctionComponent, FC } from 'react';
import styled from 'styled-components';

import { IClusterStakeProperty, EStakeDetailsSubType } from '../../../../../entities/IClusterStake';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { IValue } from '../../../../../entities/IPickers';
import { UsersAndLegalEntitiesPicker } from '../../../../Common/Pickers/UsersAndLegalEntitiesPicker';
import { EProfileType } from '../../../../../entities/IGlobal';

const Wrapper = styled.div`
    span {
        overflow-wrap: break-word;
    }
`;

const PickerWrapper = styled.div`
    margin-top: 6px;
`;

interface IPartyContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    context?: 'PLE' | 'Cluster';
    setEditingData(propertie: IClusterStakeProperty);
}

export const PartyContent: FC<React.PropsWithChildren<IPartyContentProps>> = (props) => {
    const [content, setContent] = useState<IValue[]>(undefined);
    const LegalEntity = 'legalentity';
    const Person = 'Person';
    useEffect(() => {
        const finalData = Array.isArray(props.data?.value) ? props.data?.value : [props.data?.value];
        setContent(finalData?.map(elem => elem ? ({
            key: elem.id,
            text: elem.Name || elem.name,
            data: elem
            // tslint:disable-next-line:no-null-keyword
        }) : null) || []);
    }, [props.data, props.editStake]);

    const setFieldValue = useCallback((value: IValue[]) => {
        setContent(value);
        props.setEditingData({
            ...props.data,
            value: value?.length > 0 ? ({
                text: value?.[0]?.text,
                emailContact: value?.[0]?.data?.emailContact,
                id: value?.[0]?.data?.id,
                name: value?.[0]?.data ? (value?.[0]?.data?.Name || value?.[0]?.data?.name || `${value?.[0]?.data?.firstName} ${value?.[0]?.data?.lastName}`) : undefined,
                picture: value?.[0]?.data?.picture || value?.[0]?.data?.Picture,
                subTitle: '',
                cabinet: value?.[0].data?.cabinet,
                jobTitle: value?.[0].data?.jobTitle,
                type: value?.[0]?.data?.dataType || value?.[0]?.data?.type || 'personal'
                // tslint:disable-next-line:no-null-keyword
            }) : null,
            id: props.propertieId
        });
    }, [props.propertieId, props.data, props.setEditingData]);

    return (
        <Wrapper>
            {props.editStake ? (
                <PickerWrapper>
                    <UsersAndLegalEntitiesPicker
                        value={content}
                        onSelectElement={setFieldValue}
                        filteredCompanies={props.data.subType === EStakeDetailsSubType.FilteredByOurGroup}
                        allCompanies={props.data.subType !== EStakeDetailsSubType.FilteredByOurGroup}
                        hideButtons={props.data.subType === EStakeDetailsSubType.FilteredByOurGroup}
                        errorMsg={props.error}
                    />
                </PickerWrapper>
            ) : (
                <>
                    {(!!content && content?.[0]?.data) &&
                        <ProfilesSummary
                            key={content?.[0]?.data?.id || content?.[0]?.data?.Id}
                            type={content?.[0]?.data?.type?.toLocaleLowerCase() === LegalEntity ? EProfileType.Company : content?.[0]?.data?.type?.toLocaleLowerCase() || content?.[0]?.data?.DataType && (content?.[0]?.data?.DataType === Person ? EProfileType.Personal : EProfileType.Company)}
                            profile={content?.[0]?.data ? {
                                picture: content?.[0]?.data?.picture || content?.[0]?.data?.Picture,
                                firstName: content?.[0]?.data?.name || content?.[0]?.data?.Name,
                                id: content?.[0]?.data?.id || content?.[0]?.data?.Id,
                                jobTitle: content?.[0]?.data?.subTitle
                            } : undefined}
                        />
                    }
                </>
            )}
        </Wrapper>
    );
};
