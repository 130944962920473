import React, { FC, useCallback, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { SogeLangOption, LangOptionButton } from '../SogeHeader/SogeLangOption';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { useDropdown } from '../../../tools/hooks';
import { languageIcons, languageLabels } from '../../../tools/languageTools';
import { ELcid } from '../../../entities/ILanguage';
import { ITableOfContentsVersion, ISgVersionLcid, IFeatureBranchCompare } from '../../../entities/ISoge';
import { DropdownWrapper, LangDropdownButton, SgDropdown } from '../Components/Styles';
import { StatusTag } from '../Components/StatusTag';

const VersionTag = styled(StatusTag)`
    margin-left: .5rem;
`;

const VersionName = styled.div`
    margin: 5px 0 5px 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
`;

const Languages = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

interface ICompareVersionSelectProps {
    versions: ITableOfContentsVersion[];
    selectedLanguage: ELcid;
    featureBranchesResponse?: IFeatureBranchCompare;
    isBranchPreview?: boolean;
    versionLcid: ISgVersionLcid;
    selectedVersionName: string;
    onChangeVersion(lcid: ELcid, versionId?: string, versionName?: string, featureBranchId?: string): void;
}

export const CompareVersionSelect: FC<React.PropsWithChildren<ICompareVersionSelectProps>> = ({ onChangeVersion, versions, selectedLanguage, versionLcid, selectedVersionName, featureBranchesResponse, isBranchPreview }) => {
    const [langWrapperRef, langDropdownOpen, langToggle, langCloseDropdown] = useDropdown();

    const changeVersion = useCallback((selectedLcid: ELcid, versionId: string, versionName: string, featureBranchId?: string) => {
        onChangeVersion(selectedLcid, versionId, versionName, featureBranchId);
        langCloseDropdown();
    }, [onChangeVersion]);

    return (
        <DropdownWrapper ref={langWrapperRef}>
            <Button typeSchema={EButtonTypeSchema.TEXT} onClick={langToggle}>
                <LangDropdownButton isOpen={langDropdownOpen}>
                    {!!selectedLanguage ? (
                        <>
                            <span>{selectedVersionName}</span>
                            <img src={languageIcons[selectedLanguage]} />
                            <FormattedMessage id={languageLabels[selectedLanguage].id} />
                        </>
                    ) : (
                        <FormattedMessage id="norms.compare.nothing" />
                    )}
                    {langDropdownOpen ? (
                        <FontAwesomeIcon icon={faSortUp} size="lg" color={colorStack.black} />
                    ) : (
                        <FontAwesomeIcon icon={faSortDown} size="lg" color={colorStack.black} />
                    )}
                </LangDropdownButton>
            </Button>
            {langDropdownOpen && (
                <SgDropdown alignTo="left">
                    <LangOptionButton onClick={() => changeVersion(undefined, undefined, undefined, undefined)}>
                        <FormattedMessage id="norms.compare.nothing" />
                    </LangOptionButton>
                    {(versions || []).map(version => (
                        <Fragment key={version.id}>
                            <VersionName>
                                {version.name}
                                <VersionTag status={version.documentVersion?.status} />
                            </VersionName>
                            {!isBranchPreview ? (
                                <Languages>
                                    {(versionLcid[version.versionId]?.availableLcid || []).map(lcid => (
                                        <SogeLangOption
                                            key={lcid}
                                            lcid={lcid}
                                            onClick={changeVersion}
                                            versionId={version.versionId}
                                            versionName={version.name}
                                        />
                                    ))}
                                </Languages>
                            ) : (
                                <Languages>
                                    {(versionLcid[version.versionId]?.availableLcid || []).map((lcid, index) => (
                                        <SogeLangOption
                                            key={lcid}
                                            lcid={lcid}
                                            onClick={changeVersion}
                                            versionId={version.versionId}
                                            versionName={version.name + index}
                                            isMainBranch={true}
                                        />

                                    ))}
                                    {featureBranchesResponse && featureBranchesResponse?.otherComparableBranches?.map((el, index) => (
                                        el?.normativeDocumentationVersionId === version?.versionId && (
                                            <SogeLangOption
                                                key={el?.id}
                                                lcid={el?.lcid}
                                                onClick={changeVersion}
                                                versionId={el?.normativeDocumentationVersionId}
                                                versionName={el?.name}
                                                isBranchPreview={isBranchPreview}
                                                status={el?.status}
                                                featureBranchId={el?.id}
                                                featureBranchName={el?.name}
                                            />
                                        )
                                    ))}
                                </Languages>
                            )}
                        </Fragment>
                    ))}
                </SgDropdown>
            )}
        </DropdownWrapper>
    );
};
