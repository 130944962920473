import React, { useEffect, useState, useCallback, FC } from 'react';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { ErrorText } from './StakeStyles';
import { IValue } from '../../../../../entities/IPickers';
import { EProfileType } from '../../../../../entities/IGlobal';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';

interface IProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    context?: 'PLE' | 'Cluster';
    setEditingData(propertie: IClusterStakeProperty);
}

export const MultiplePeopleContent: FC<React.PropsWithChildren<IProps>> = ({ context, error, data, editStake, propertieId, setEditingData }) => {
    const [content, setContent] = useState<IValue[]>([]);
    useEffect(() => {
        const finalData = data?.value ? (Array.isArray(data?.value) ? data?.value : [data?.value]) : undefined;
        setContent(finalData?.map(elem => elem ? ({
            key: elem?.id,
            text: `${elem?.firstName} ${elem?.lastName}`,
            data: elem
        // tslint:disable-next-line:no-null-keyword
        }) : null ) || []);
    }, [data, editStake]);

    const setFieldValue = (value: IValue[]) => {
        setEditingData({
            ...data,
            // tslint:disable-next-line:no-null-keyword
            value: value?.length > 0 ? value.map(elem => elem.data) : null,
            id: propertieId
        });
    };

    return (
        <div>
            {editStake ? (
                <>
                    <UsersPicker
                        value={content}
                        onSelectElementMulti={setFieldValue}
                        multiSelect
                    />
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                    <>
                        {!!content?.length && content.map(person =>
                            <ProfilesSummary
                                key={person?.data?.id}
                                type={EProfileType.Personal}
                                profile={person?.data ? {
                                    picture: person?.data?.picture || person?.data?.Picture,
                                    firstName: person?.data?.firstName || person?.data?.FirstName,
                                    id: person?.data?.id,
                                    lastName: person?.data?.lastName || person?.data?.LastName,
                                    jobTitle: person?.data?.jobTitle || person?.data?.JobTitle,
                                    department: person?.data?.department || person?.data?.Department
                                } : undefined}
                            />
                        )}
                    </>
                )}
        </div>
    );
};
