import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    background: ${colorStack.bodyBg};
    height: 32px;
    display: inline-flex;
    overflow: hidden;
    border-radius: 8px;
`;

const Button = styled.button<{ active: boolean }>`
    color: ${colorStack.middleBlue};
    background: ${colorStack.bodyBg};
    font-weight: 500;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${fontSizeAndHeight[16]};
    border: 2px solid ${colorStack.bodyBg};
    cursor: pointer;
    transition: all .3s;
    ${({ active }) => active && css`
        border: 2px solid ${colorStack.ligthGrey};
        background: ${colorStack.white};
    `}
    &:first-child {
        border-radius: 8px 0 0 8px;
    }
    &:last-child {
        border-radius: 0 8px 8px 0;
    }
`;

interface IProps {
    buttons: {
        text: string;
        active: boolean;
        action();
    }[];
}

export const GroupButton: FC<IProps> = props => {
    return (
        <Wrapper>
            {props.buttons?.map(elem => (
                <Button active={elem.active} onClick={elem.action} type="button">
                    {elem.text}
                </Button>
            ))}
        </Wrapper>
    );
};