import { EReferentials, EContextList } from '../entities/IGlobal';

export const referentialConfiguration = {
    Nationality: {
        referentialContext: EContextList.LegalEntity,
        referentialType: EReferentials.Nationality,
        value: undefined
    },
    Group: {
        referentialContext: EContextList.LegalEntity,
        referentialType: EReferentials.Group,
        value: undefined
    },
    Position: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.Position,
        value: undefined
    },
    SecondaryEstablishmentsAndBranches: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.QualityBranches,
        value: undefined
    },
    SubsidiaryAndParticipations: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.QualityParticipations,
        value: undefined
    },
    TypeOfLegalEntity: {
        referentialContext: EContextList.LegalEntity,
        referentialType: EReferentials.TypeOfLegalEntity,
        value: undefined
    },
    LegalForm: {
        referentialContext: EContextList.LegalEntity,
        referentialType: EReferentials.LegalForm,
        value: undefined
    },
    FirstReferential: {
        referentialContext: EContextList.Platform,
        referentialType: EReferentials.FirstReferential,
        value: undefined
    },
    SecondReferential: {
        referentialContext: EContextList.Platform,
        referentialType: EReferentials.SecondReferential,
        value: undefined
    },
    ThirdReferential: {
        referentialContext: EContextList.Platform,
        referentialType: EReferentials.ThirdReferential,
        value: undefined
    },
    Information: {
        referentialContext: EContextList.NormativeDocumentation,
        referentialType: EReferentials.Information,
        value: undefined
    },
    Administration: {
        referentialContext: EContextList.NormativeDocumentation,
        referentialType: EReferentials.Administration,
        value: undefined
    },
    Thematic: {
        referentialContext: EContextList.NormativeDocumentation,
        referentialType: EReferentials.Thematic,
        value: undefined
    },
    Country: {
        referentialContext: EContextList.Platform,
        referentialType: EReferentials.Country,
        value: undefined
    },
    QualityBranches: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.QualityBranches,
        value: undefined
    },
    QualityParticipations: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.QualityParticipations,
        value: undefined
    },
    NumberOfEmployees: {
        referentialContext: EContextList.LegalEntity,
        referentialType: EReferentials.NumberOfEmployees,
        value: undefined
    },
    DocumentLabel: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.DocumentLabel,
        value: undefined
    },
    Clauses: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.Clauses,
        value: undefined
    },
    TypeOfActivity: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.TypeOfActivity,
        value: undefined
    },
    AliasType: {
        referentialContext: EContextList.Corporate,
        referentialType: EReferentials.AliasType,
        value: undefined
    },
    GiftNature: {
        referentialContext: EContextList.GiftInvitation,
        referentialType: EReferentials.GiftNature,
        value: undefined
    },
    GiftRelationshipNature: {
        referentialContext: EContextList.GiftInvitation,
        referentialType: EReferentials.GiftRelationshipNature,
        value: undefined
    },
    GiftValue: {
        referentialContext: EContextList.GiftInvitation,
        referentialType: EReferentials.GiftValue,
        value: undefined
    },
    GiftDescription: {
        referentialContext: EContextList.GiftInvitation,
        referentialType: EReferentials.GiftDescription,
        value: undefined
    },
    TypeOfMilestone: {
        referentialContext: EContextList.Stake,
        referentialType: EReferentials.TypeOfMilestone,
        value: undefined
    },
    ContractTypeReferential: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.ContractTypeReferential,
        value: undefined
    },
    Assignment: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.Assignment,
        value: undefined
    },
    NonCompeteProvision: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.NonCompeteProvision,
        value: undefined
    },
    ChangeOfControl: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.ChangeOfControl,
        value: undefined
    },
    GeographicalArea: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.GeographicalArea,
        value: undefined
    },
    PerformanceObligation: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.PerformanceObligation,
        value: undefined
    },
    TypeOfRenewal: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.TypeOfRenewal,
        value: undefined
    },
    BasicPrice: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.BasicPrice,
        value: undefined
    },
    Exclusivity: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.Exclusivity,
        value: undefined
    },
    ApplicableLaw: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.ApplicableLaw,
        value: undefined
    },
    ProductAndServices: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.ProductAndServices,
        value: undefined
    },
    Cancellation: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.Cancellation,
        value: undefined
    },
    ContractDuration: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.ContractDuration,
        value: undefined
    },
    GenericQualifiaction: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.GenericQualifiaction,
        value: undefined
    },
    ContractTermType: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.ContractTermType,
        value: undefined
    },
    BusinessSector: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.BusinessSector,
        value: undefined
    },
    Liability: {
        referentialContext: EContextList.Contract,
        referentialType: EReferentials.Liability,
        value: undefined
    }
};