import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';
import { SubjectMessage } from '../../../../Common/Inputs/SubjectMessage';
import { Alert } from '../../../../Common/Alert/Alert';

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

export const SponsoringStep6: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    useEffect(() => {
        if (!eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.approvalMessage) {
            eApprovalContext.modifyData(intl.formatMessage({id: 'eApproval.wizard.step6.eapproval-sponsoring.message'}, {lineBrake: '\r\n'}), 'body', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData(intl.formatMessage({id: 'eApproval.wizard.step6.eapproval-sponsoring.subject'}), 'subject', props.context, props.stepName, props.stepNumber);
        }
    }, []);

    const onBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        eApprovalContext.modifyData(value, 'body', props.context, props.stepName, props.stepNumber);
    };

    const onSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        eApprovalContext.modifyData(value, 'subject', props.context, props.stepName, props.stepNumber);
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <Alert
                    transparentRight
                    type={EAlertType.ERROR}
                    typeSchema={EAlertTypeSchema.SECONDARY}
                    withoutClose
                    withoutProgress
                    visible
                    alertId="1"
                >
                    <FormattedMessage id={`eApproval.wizard.step6.${[EApprovalTypes.Sponsoring]}.warning`} />
                </Alert>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <FormattedMessage id={`eApproval.wizard.step6.${[EApprovalTypes.Sponsoring]}.context`} />
                    <SubjectMessage
                        inputValue={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.approvalMessage?.subject}
                        textareaValue={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.approvalMessage?.body}
                        inputChange={onSubjectChange}
                        textareaChange={onBodyChange}
                        mandatoryStarSubject
                    />
                </div>
            </Row>
        </Wrapper>
    );
};