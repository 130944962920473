import React, { FC, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useInvolvedPartiesPicker } from '../../../../../tools/pickerHooks';
import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { SingleWorkElem } from '../../../../Home/SingleWorkElem';
import { ErrorText } from './StakeStyles';
import { Picker } from '../../ClusterDocuments/SignatureForm/PickerInput';
import { ITag } from '../../../../Common/TagPicker/TagPicker';
import { Wrapper } from './LegalEntityContent';
import { IState } from '../../../../../reducers';
import { IClusterReducer } from '../../../../../reducers/clustersReducer';

const PickerWrapper = styled.div`
    margin-top: 6px;
`;

interface IInvolvedPartiesContent {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    organizationId: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const InvolvedPartiesContent: FC<React.PropsWithChildren<IInvolvedPartiesContent>> = ({ error, data, editStake, propertieId, organizationId, setEditingData }) => {
    const [searchHandler, mapResults] = useInvolvedPartiesPicker();
    const [focusedElementTag, setFocusedElementTag] = useState<ITag<any>[]>([]);
    const { currentClusterId } = useSelector<IState, IClusterReducer>(state => state.clusters);

    useEffect(() => {
        setFocusedElementTag(data?.value ? mapResults([data.value]) : []);
    }, [data, editStake]);

    const searchInvolvedParties = useCallback((query: string) => {
        return searchHandler(query, organizationId, currentClusterId);
    }, [currentClusterId, organizationId]);

    const selectElementHandler = useCallback((tags: ITag<any>[]) => {
        // tslint:disable-next-line:no-null-keyword
        const entity = !!tags && tags.length > 0 ? tags[0].data : null;

        setFocusedElementTag(entity ? mapResults([entity]) : []);
        setEditingData({
            ...data,
            value: entity,
            id: propertieId
        });
    }, [setEditingData, mapResults]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <PickerWrapper>
                        <Picker
                            noAvatar
                            focusedElementTag={focusedElementTag}
                            searchHandler={searchInvolvedParties}
                            onSelectElement={selectElementHandler}
                            error={!!error}
                            noResults={
                                <FormattedMessage id="cluster.placeholder.noresult"  />
                            }
                        />
                    </PickerWrapper>
                    {error && <ErrorText>{error}</ErrorText>}
                </>
            ) : (
                <>
                    {focusedElementTag.length > 0 &&
                        <SingleWorkElem data={focusedElementTag[0].data} unreadMessages={0} />
                    }
                </>
            )}
        </Wrapper>
    );
};
