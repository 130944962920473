import React, { ReactNode, FC } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';

interface ILabelProps {
    labelColor?: string;
}

const Label = styled.label<ILabelProps>`
    font-size: ${fontSize[13]};
    margin: 0 0 5px 0;
    ${({ labelColor }) => css`
        color: ${labelColor || colorStack.disabled};
    `}
`;

const FormBlock = styled.div`
    width: 100%;
`;

interface IFieldWrapperProps {
    className?: string;
    error?: boolean;
    required?: boolean;
    labelColor?: string;
    label?: ReactNode;
    id?: string;
}

export const FieldWrapper: FC<React.PropsWithChildren<IFieldWrapperProps>> = ({ className, error, label, labelColor, required, children, id }) => (
    <FormBlock className={cn('lcr-form-block full', className || '', { 'lcr-error': error })}>
        <Label htmlFor={id} labelColor={labelColor}>{label} {required && <sup>*</sup>}</Label>
        {children}
    </FormBlock>
);

export const FormError = styled.div`
    padding-top: .2rem;
    color: ${colorStack.darkRed};
    font-size: ${fontSize[13]};
`;
