import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ISgStakeholder } from '../../../entities/ISoge';
import { Avatar } from '../../Common/Avatar/Avatar';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding-right: 1rem;
`;

const StakeholderData = styled.div`
    display: flex;
    flex-direction: column;
`;

const StakeholderName = styled(Link)`
    color: ${colorStack.darkBlue};
    font-weight: 500;
`;

const StakeholderJob = styled.span`
    font-size: ${fontSize[13]};
    margin-top: 0.2rem;
`;

interface IStakeholderCellProps {
    stakeholder: ISgStakeholder;
}

export const StakeholderCell: FC<React.PropsWithChildren<IStakeholderCellProps>> = ({ stakeholder }) => (
    <Wrapper key={stakeholder.id}>
        <Avatar
            picture={stakeholder.picture}
            firstName={stakeholder.firstName}
            lastName={stakeholder.lastName}
            id={stakeholder.id}
        />
        <StakeholderData>
            <StakeholderName to={`/profile/${stakeholder.id}`}>
                {stakeholder.firstName} {stakeholder.lastName}
            </StakeholderName>
            <StakeholderJob>
                {[stakeholder.jobTitle, stakeholder.cabinet].filter(val => val).join(' - ')}
            </StakeholderJob>
        </StakeholderData>
    </Wrapper>
);
