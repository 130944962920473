import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheckCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { Avatar } from '../../Common/Avatar/Avatar';
import { SelectInput } from '../../Common/SelectInput/SelectInput';
import { IValue } from '../../../entities/IPickers';
import { EDropDownType } from '../../Common/DropDown/DropDown';

const SingleValidatorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${fontSize[13]};
    span {
        font-weight: 500;
        margin: 0 0 0 auto;
    }
    .ms-Dropdown {
        font-size: ${fontSize[13]};
    }
`;

const ValidatorContainer = styled.div`
    display: flex;
    align-items: center;
    margin: .4rem 0;
`;

const PenButton = styled.button`
    cursor: pointer;
    svg {
        margin: 0 0 0 10px;
    }
`;

const ImageWrapper = styled.div`
    height: 24px;
    width: 24px;
`;

const NameSection = styled.div`
    margin: 0 auto 0 .5rem;
    font-weight: 400;
`;

export const EditSection = styled.div`
    margin: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
`;

export const ActionWrapper = styled.div`
    cursor: pointer;
    font-size: ${fontSize[13]};
`;

interface ISingleValidatorProps {
    id: string;
    photo: string;
    firstName: string;
    lastName: string;
    isMandatory: boolean;
    onChangeMandatory?(id: string);
    onRemoveApprover?(id: string);
}

export const SingleValidator: FC<React.PropsWithChildren<ISingleValidatorProps>> = ({ id, photo, firstName, lastName, isMandatory, onChangeMandatory, onRemoveApprover }) => {
    const intl = useIntl();
    const mandatoryOptions = {
        mandatory: 'norms.mandatory',
        optional: 'norms.optional'
    };

    const [editMode, setEditMode] = useState<boolean>(false);

    const changeMandatoryHandler = useCallback((option) => {
        onChangeMandatory(id);
    }, [onChangeMandatory, id]);

    const onRemove = useCallback(() => {
        onRemoveApprover(id);
    }, [onRemoveApprover, id]);

    const changeMode = useCallback(() => {
        setEditMode(state => !state);
    }, []);

    return (
        <SingleValidatorWrapper>
            <ValidatorContainer>
                <Avatar
                    picture={photo}
                    firstName={firstName}
                    lastName={lastName}
                    id={id}
                />
                {!editMode ?
                    <>
                        {isMandatory ?
                            <FormattedMessage id="norms.mandatory"  />
                            :
                            <FormattedMessage id="norms.optional"  />
                        }

                    </>
                    :
                    <SelectInput
                        value={isMandatory ? [{key: 'mandatory', text: 'mandatory', data: 'mandatory'}] : [{key: 'optional', text: 'optional', data: 'optional'}]}
                        onChange={(option: IValue) => changeMandatoryHandler(option.key)}
                        options={Object.keys(mandatoryOptions).map(key => ({
                            key, text: intl.formatMessage({ id: mandatoryOptions[key] }), data: key
                        }))}
                        type={EDropDownType.DEFAULT}
                    />
                }

                {!editMode ? (
                    <PenButton onClick={changeMode}>
                        <FontAwesomeIcon icon={faPen} />
                    </PenButton>
                ) : (
                    <EditSection>
                        <ActionWrapper onClick={changeMode}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </ActionWrapper>
                        <ActionWrapper onClick={onRemove}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </ActionWrapper>
                    </EditSection>
                )}

            </ValidatorContainer>
        </SingleValidatorWrapper>
    );
};
