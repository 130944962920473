import { IContractData } from './IContractData';
import { EClusterPrivacy } from '../../../../entities/IClusters';

export const initialFormValues = (): IContractData => {
    return {
        formData: {
            cluster: {
                clusterTemplateId: undefined,
                clusterTemplateName: undefined,
                clusterTemplateKey: '',
                clusterTemplateColor: ''
            },
            name: '',
            privacy: EClusterPrivacy.Private
        },
        steps: 1
    };
};
