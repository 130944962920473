import React, { FC, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { ErrorText } from './StakeStyles';
import { IProps, Wrapper } from './LegalEntityContent';
import { ISIngleLegalEntity } from '../../../../../entities/ILegalEntities';
import { EProfileType } from '../../../../../entities/IGlobal';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';

const PickerWrapper = styled.div`
    margin-top: 6px;
`;

export const NewDesignLegalEntityContent: FC<React.PropsWithChildren<IProps>> = ({ context, error, data, editStake, propertieId, setEditingData }) => {
    const [companyValue, setCompanyValue] = useState<{ key: string; text: string; data: any }[]>(undefined); // we need any - from backend we have pascal and camel case

    useEffect(() => {
        setCompanyValue(data?.value?.id ? [{
            ...data?.value,
            key: data?.key,
            data: data?.value
        }] : undefined);
    }, []);

    const mapFormValue = useCallback((entity: any) => {
        if (!entity) {
            // tslint:disable-next-line:no-null-keyword
            return null;
        }

        return context === 'PLE' ? ({
            Name: entity.Name,
            id: entity.id
        }) as any : entity;
    }, [context]);

    const onChangeSelectedCompany = (value: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        setCompanyValue(value);
        setEditingData(({ ...data, value: mapFormValue(value?.[0]?.data), id: propertieId }));
    };

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <PickerWrapper>
                        <LegalEntitiesPicker
                            allCompanies={context === 'Cluster'}
                            value={companyValue}
                            onSelectElement={onChangeSelectedCompany}
                        />
                    </PickerWrapper>
                    {error && <ErrorText>{error}</ErrorText>}
                </>
            ) : (
                    <>
                        {(!!companyValue?.[0]?.data && !!companyValue?.[0]?.data?.id) &&
                            <ProfilesSummary
                                key={companyValue?.[0]?.data?.id}
                                type={EProfileType.Company}
                                profile={{
                                    picture: companyValue?.[0]?.data?.picture || companyValue?.[0]?.data?.Picture,
                                    firstName: companyValue?.[0]?.data?.name || companyValue?.[0]?.data?.Name,
                                    id: companyValue?.[0]?.data?.id
                                }}
                            />
                        }
                    </>
                )}
        </Wrapper>
    );
};
