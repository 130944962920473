import React, { FC, Fragment, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { media } from '../../../styleHelpers/breakpoint';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    ${media.tablet`
        flex-wrap: nowrap;
    `}
`;

const ElementWrapper = styled.div<ISharedInnerProps & { lastItem?: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0 .1rem 0 .25rem;
    padding: 0.25rem 0.5rem;

    ${({ withBackground }) => withBackground && css`
        border-radius: 999px;
        background-color: ${colorStack.bodyBg};
    `}
    &:first-child {
        margin-left: 0;
        padding-left: 0;
    }
    &:hover {
        &, span, a {
            color: ${colorStack.middleBlue};
            text-decoration: none;
        }

        ${({ withBackground }) => withBackground && css`
            border-radius: 999px;
            background-color: ${colorStack.lightBlue};
        `}
    }

    &, > span, a {
        font-weight: 500;
        color: ${colorStack.darkGrey};
        ${fontSizeAndHeight[10]}
        ${({ lastItem }) => lastItem && css`
            color: ${colorStack.label};
        `}
    }
`;

const Breadcrumbs = styled.div<ISharedInnerProps>`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ${({ noWrap }) => noWrap && css`
        flex-wrap: nowrap;
    `}
`;

const MenuWrapper = styled.div`
    margin-left: auto;
    padding-left: 1rem;
`;

const Separator = styled.div`
    display: inline-block;
    margin: 0 0.25rem;
    &:after {
        font-weight: 300;
        color: ${colorStack.middleGrey};
        content: '/';
        ${fontSizeAndHeight[10]}
    }
`;

interface ISharedInnerProps {
    withBackground?: boolean;
    noWrap?: boolean;
}

export interface IBreadcrumbItem {
    icon?: ReactNode;
    element: ReactNode;
}

interface IGlobalBreadcrumbsProps extends ISharedInnerProps {
    breadcrumbs: IBreadcrumbItem[];
    menu?: ReactNode;
    hideIcons?: boolean;
}

export const GlobalBreadcrumbs: FC<React.PropsWithChildren<IGlobalBreadcrumbsProps>> = ({ withBackground, breadcrumbs, menu, children, noWrap, hideIcons }) => {
    return (
        <Wrapper>
            <Breadcrumbs withBackground={withBackground} noWrap={noWrap}>
                {breadcrumbs.map(({ element, icon }, index) => (
                    <Fragment key={index}>
                        <ElementWrapper withBackground={withBackground} lastItem={index === breadcrumbs.length - 1}>
                            {(icon && !hideIcons) && icon}
                            {element}
                        </ElementWrapper>
                        {index < breadcrumbs.length - 1 && <Separator />}
                    </Fragment>
                ))}
            </Breadcrumbs>
            {children}
            {menu && (
                <MenuWrapper>
                    {menu}
                </MenuWrapper>
            )}
        </Wrapper>
    );
};
