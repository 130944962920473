import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { ESpinnerSize, Spinner } from '../Spinner/Spinner';

export enum EButtonSize {
    XSMALL = 'XSMALL',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM'
}

export enum EButtonTypeSchema {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    TERTIARY = 'TERTIARY',
    TEXT = 'TEXT'
}

export enum EButtonColorSchema {
    NEUTRAL = 'NEUTRAL',
    ALERT = 'ALERT',
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS'
}

const Wrapper = styled.button<{ size: EButtonSize, typeSchema: EButtonTypeSchema, disabled: boolean, colorSchema: EButtonColorSchema, outlined: boolean }>`
    padding: 6px 1rem;
    border-radius: 8px;
    white-space: nowrap;
    border: 2px solid;
    ${fontSizeAndHeight[16]};
    border-color: ${colorStack.middleBlue};
    background: ${colorStack.middleBlue};
    color: ${colorStack.white};
    font-weight: 500;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
    svg {
        &:nth-child(1){
            margin-right: .5rem;
        }
        &:nth-child(2){
            margin-left: .5rem;
        }
        &:nth-child(3){
            margin-left: .5rem;
        }
    }
    > div {
        margin-left: .5rem;
    }
    ${({ outlined }) => outlined && css`
        background: ${colorStack.white};
        color: ${colorStack.darkBlue};
        border-color: ${colorStack.middleBlue};
    `};
    ${({ size }) => size === EButtonSize.SMALL && css`
        padding: 10px 1rem;
    `};
    ${({ size, outlined }) => size === EButtonSize.MEDIUM && css`
        ${fontSizeAndHeight[25]};
        padding: 10px 1rem;
        ${outlined && css`
            color: ${colorStack.middleBlue};
        `};
    `};
    ${({ colorSchema, outlined, size }) => colorSchema === EButtonColorSchema.ALERT && css`
        border-color: ${colorStack.middleRed};
        background: ${colorStack.middleRed};
        color: ${colorStack.white};
        ${outlined && css`
            color: ${colorStack.darkBlue};
            background: ${colorStack.white};
            ${size === EButtonSize.MEDIUM && css`
                color: ${colorStack.middleRed};
            `}
        `};
    `};
    ${({ colorSchema, outlined, size }) => colorSchema === EButtonColorSchema.WARNING && css`
        border-color: ${colorStack.middleOrange};
        background: ${colorStack.middleOrange};
        color: ${colorStack.white};
        ${outlined && css`
            color: ${colorStack.darkBlue};
            background: ${colorStack.white};
            ${size === EButtonSize.MEDIUM && css`
                color: ${colorStack.middleOrange};
            `}
        `};
    `};
    ${({ colorSchema, outlined, size }) => colorSchema === EButtonColorSchema.SUCCESS && css`
        border-color: ${colorStack.middleGreen};
        background: ${colorStack.middleGreen};
        color: ${colorStack.white};
        ${outlined && css`
            color: ${colorStack.darkBlue};
            background: ${colorStack.white};
            ${size === EButtonSize.MEDIUM && css`
                color: ${colorStack.middleGreen};
            `}
        `};
    `};
    ${({ typeSchema, colorSchema, outlined }) => typeSchema === EButtonTypeSchema.SECONDARY && css`
        color: ${colorStack.darkBlue};
        background: ${colorStack.lightBlue};
        border-color: ${colorStack.lightBlue};
        ${outlined && css`
            border-color: ${colorStack.ligthGrey};
            background: ${colorStack.white};
            color: ${colorStack.middleBlue};
        `};
        ${colorSchema === EButtonColorSchema.ALERT && css`
            border-color: ${colorStack.lightRed};
            background: ${colorStack.lightRed};
            color: ${colorStack.darkRed};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleRed};
            `};
        `};
        ${colorSchema === EButtonColorSchema.WARNING && css`
            border-color: ${colorStack.lightOrange};
            background: ${colorStack.lightOrange};
            color: ${colorStack.darkOrange};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleOrange};
            `};
        `};
        ${colorSchema === EButtonColorSchema.SUCCESS && css`
            border-color: ${colorStack.lightGreen};
            background: ${colorStack.lightGreen};
            color: ${colorStack.darkGreen};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleGreen};
            `};
        `};
    `};
    ${({ typeSchema, colorSchema, outlined }) => typeSchema === EButtonTypeSchema.TERTIARY && css`
        color: ${colorStack.middleBlue};
        background: ${colorStack.bodyBg};
        border-color: ${colorStack.bodyBg};
        ${outlined && css`
            border-color: ${colorStack.ligthGrey};
            background: ${colorStack.white};
            color: ${colorStack.middleBlue};
        `};
        ${colorSchema === EButtonColorSchema.ALERT && css`
            border-color: ${colorStack.bodyBg};
            background: ${colorStack.bodyBg};
            color: ${colorStack.middleRed};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleRed};
            `};
        `};
        ${colorSchema === EButtonColorSchema.WARNING && css`
            border-color: ${colorStack.bodyBg};
            background: ${colorStack.bodyBg};
            color: ${colorStack.middleOrange};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleOrange};
            `};
        `};
        ${colorSchema === EButtonColorSchema.SUCCESS && css`
            border-color: ${colorStack.bodyBg};
            background: ${colorStack.bodyBg};
            color: ${colorStack.middleGreen};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleGreen};
            `};
        `};
    `};
    ${({ typeSchema, colorSchema, outlined }) => typeSchema === EButtonTypeSchema.TEXT && css`
        color: ${colorStack.middleBlue};
        background: transparent;
        border-color: transparent;
        ${outlined && css`
            border-color: transparent;
            background: transparent;
            color: ${colorStack.middleBlue};
        `};
        ${colorSchema === EButtonColorSchema.ALERT && css`
            border-color: transparent;
            background:transparent;
            color: ${colorStack.middleRed};
            ${outlined && css`
                background: transparent;
                border-color: transparent;
                color: ${colorStack.middleRed};
            `};
        `};
        ${colorSchema === EButtonColorSchema.WARNING && css`
            border-color: transparent;
            background: transparent;
            color: ${colorStack.middleOrange};
            ${outlined && css`
                background: transparent;
                border-color: transparent;
                color: ${colorStack.middleOrange};
            `};
        `};
        ${colorSchema === EButtonColorSchema.SUCCESS && css`
            border-color: transparent;
            background: transparent;
            color: ${colorStack.middleGreen};
            ${outlined && css`
                background: transparent;
                border-color: transparent;
                color: ${colorStack.middleGreen};
            `};
        `};
    `};
    ${({ disabled, typeSchema }) => disabled && css`
        cursor: default;
        color: ${colorStack.disabled};
        background: ${colorStack.ligthGrey};
        border-color: ${colorStack.ligthGrey};
        ${typeSchema === EButtonTypeSchema.TEXT && css`
            background: transparent;
            border-color: transparent;
        `};
    `};
`;

interface IProps {
    children: React.ReactNode;
    typeSchema?: EButtonTypeSchema;
    size?: EButtonSize;
    colorSchema?: EButtonColorSchema;
    rightIco?: IconDefinition;
    leftIco?: IconDefinition;
    loading?: boolean;
    disabled?: boolean;
    outlined?: boolean;
    className?: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?(e: React.MouseEvent<HTMLElement>);
}

export const Button: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper
            className={props.className || ''}
            disabled={props.disabled}
            outlined={props.outlined}
            colorSchema={props.colorSchema || EButtonColorSchema.NEUTRAL}
            onClick={props.disabled ? undefined : props.onClick}
            type={props.type || 'button'}
            size={props.size || EButtonSize.XSMALL}
            typeSchema={props.typeSchema || EButtonTypeSchema.PRIMARY}
        >
            {props.leftIco &&
                <FontAwesomeIcon icon={props.leftIco} />
            }
            <span>{props.children}</span>
            {(props.rightIco && !props.loading) &&
                <FontAwesomeIcon icon={props.rightIco} />
            }
            {props.loading &&
                <Spinner size={ESpinnerSize.BUTTONSIZE} />
            }
        </Wrapper>
    );
};