import React, { FC, useState, useCallback, ReactNode, RefObject, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { ITree, ETreeExpandIcon, ITreePathItem, ITreeViewProps } from './ITreeView';
import { colorStack } from '../../../styleHelpers/colors';
import { isArrDifferent } from '../../../tools/arrayTools';

interface IItemLabelProps {
    isActive: boolean;
    level: number;
    levelPadding?: number;
    hasChildren?: boolean;
}

const ItemLabel = styled.span<IItemLabelProps>`
    user-select: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background: ${colorStack.white};
    transition: all .3s;
    ${props => props.isActive && css`
        background: ${colorStack.whiteBlue};
    `}
    ${({ level, levelPadding = 1.5 }) => css`
        padding: 0.5rem 0 0.5rem ${(level || 0) * levelPadding}rem;
    `}
    &:hover {
        background: ${colorStack.whiteBlue};
    }
    ${props => !props.hasChildren && css`
        > span a span {
            font-weight: 400;
            text-align: justify;
            display: block;
        }
    `}
`;

interface IBtnIconProps {
    isClosed: boolean;
    hasChildren: boolean
}

export const BtnIcon = styled.div<IBtnIconProps>`
    margin-right: 0.75rem;
    width: 1rem;
    height: 1rem;
    border: 1px solid ${colorStack.darkBlue};
    position: relative;
    flex-shrink: 0;

    ${props => !props.hasChildren && css`
        visibility: hidden;
    `}

    &:before, &:after {
        background: ${colorStack.darkBlue};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:before {
        content: "";
        width: 0.6rem;
        height: 1px;
    }

    ${props => props.isClosed && css`
        &:after {
            content: "";
            width: 1px;
            height: 0.6rem;
        }
    `}
`;

const FaExpandIcon = styled.div<IBtnIconProps>`
    margin-right: 0.75rem;
    margin-left: 0.5rem;
    position: relative;
    ${props => !props.hasChildren && css`
        visibility: hidden;
    `}
    ${props => !props.isClosed && css`
        transform: translateY(-3px);
    `}
`;

export const Icon = styled.img`
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    flex-shrink: 0;
`;

interface IProps extends ITreeViewProps {
    treeItem: ITree;
    level?: number;
    renderPath: ITreePathItem[];
}

export const Tree: FC<React.PropsWithChildren<IProps>> = ({ treeItem, level, renderIcon, onLastLevelClick, renderItem, noCollapse, renderAdditional, additionalChildrenKey, itemRef, defaultExpandPath, autoExpandLevels, expandIcon, renderPath, forceDefaultExpandPath, closeNodesOnDefaultExpandPathChange, sort, defaultExpanded, className, levelPadding, isLevelCollapsable, onExpandChange }) => {
    const currentLevel = level ?? 0;
    const defaultOpenByPath = (defaultExpandPath || [])[currentLevel] === (treeItem.commonLevelId || treeItem.id);
    const defaultOpenByAutoLevels = typeof autoExpandLevels === 'number' && (isLevelCollapsable ? currentLevel <= autoExpandLevels - 1 : currentLevel <= autoExpandLevels + 1);
    const [childrenOpen, setChildrenOpen] = useState<boolean>(defaultExpanded || defaultOpenByPath || defaultOpenByAutoLevels);
    const hasChildren = !!treeItem?.children?.length || !!treeItem?.[additionalChildrenKey]?.length;

    const isActive = useMemo(() => {
        return !isArrDifferent(defaultExpandPath, renderPath.map(pathItem => (pathItem.commonLevelId || pathItem.id)));
    }, [defaultExpandPath, renderPath]);

    useEffect(() => {
        if (forceDefaultExpandPath) {
            const openedByPath = (defaultExpandPath || [])[currentLevel] === (treeItem.commonLevelId || treeItem.id);
            setChildrenOpen(state => openedByPath || (!closeNodesOnDefaultExpandPathChange && state));
        }
    }, [defaultExpandPath, forceDefaultExpandPath, currentLevel, treeItem.commonLevelId, treeItem.id, closeNodesOnDefaultExpandPathChange]);

    const onClick = useCallback(() => {
        if (hasChildren) {
            setChildrenOpen(!childrenOpen);
        } else {
            onLastLevelClick?.(treeItem, currentLevel);
        }
        onExpandChange?.();
    }, [hasChildren, childrenOpen, currentLevel, onLastLevelClick, onExpandChange]);

    const sortedChildren = sort ? [...(treeItem.children || []).sort((a, b) => (a.order ?? a.orderNumber ?? 0) - (b.order ?? b.orderNumber ?? 0))] : treeItem.children;

    useEffect(() => {
        if ((treeItem?.childContainsMatch || treeItem?.isMatch) && !!treeItem?.children?.length) {
            setChildrenOpen(true);
        }
    }, [treeItem]);

    return (
        <>
            <ItemLabel hasChildren={hasChildren} level={currentLevel} className={className} levelPadding={levelPadding} isActive={isActive} data-lc={`js-lc-documents-tree-${treeItem.name}`}>
                {!noCollapse && (
                    <>
                        {expandIcon === ETreeExpandIcon.Expand ? (
                            <BtnIcon isClosed={!childrenOpen} hasChildren={hasChildren} onClick={onClick} />
                        ) : (
                            <FaExpandIcon isClosed={!childrenOpen} onClick={onClick} hasChildren={hasChildren}>
                                <FontAwesomeIcon icon={faCaretDown} transform={{ rotate: childrenOpen ? 0 : -90 }} color={colorStack.darkGrey} />
                            </FaExpandIcon>
                        )}
                    </>
                )}
                {treeItem.icon && <Icon src={treeItem.icon} />}
                {renderIcon && renderIcon(treeItem)}
                {renderItem?.(treeItem, currentLevel, itemRef, renderPath, isActive, onClick, hasChildren, childrenOpen) || treeItem.name}
            </ItemLabel>
            {childrenOpen && renderAdditional?.(treeItem, currentLevel, renderPath, isActive)}
            {(childrenOpen || noCollapse) && hasChildren && (
                <>
                    {(sortedChildren || []).map((childTreeItem) => (
                            <Tree
                                treeItem={childTreeItem}
                                key={childTreeItem.id || childTreeItem.commonLevelId}
                                level={currentLevel + 1}
                                renderIcon={renderIcon}
                                renderItem={renderItem}
                                onLastLevelClick={onLastLevelClick}
                                noCollapse={noCollapse}
                                renderAdditional={renderAdditional}
                                additionalChildrenKey={additionalChildrenKey}
                                itemRef={itemRef}
                                defaultExpandPath={defaultExpandPath}
                                defaultExpanded={defaultExpanded}
                                autoExpandLevels={autoExpandLevels}
                                expandIcon={expandIcon}
                                forceDefaultExpandPath={forceDefaultExpandPath}
                                closeNodesOnDefaultExpandPathChange={closeNodesOnDefaultExpandPathChange}
                                sort={sort}
                                onExpandChange={onExpandChange}
                                className={className}
                                levelPadding={levelPadding}
                                renderPath={[...renderPath, {
                                    id: childTreeItem.id,
                                    name: childTreeItem.name,
                                    level: currentLevel + 1,
                                    commonLevelId: childTreeItem.commonLevelId
                                }]}
                            />
                    ))}
                </>
            )}
        </>
    );
};
