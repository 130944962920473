import { IRmsInvitation } from '../entities/IRmsInvitation';
import { IPendingInvitation } from '../entities/IPendingInvitation';
import * as actionTypes from '../actions/actionTypes/rmsTypes';

const defaultState = (): IRmsReducer => ({
    invitationsLoaded: false,
    rmsInvitations: [],
    pendingInvitations: []
});

export default (state = defaultState(), action): IRmsReducer => {
    switch (action.type) {
        case actionTypes.GET_RMS_INVITATIONS: {
            return {
                ...state,
                rmsInvitations: action.rmsInvitations,
                invitationsLoaded: true
            };
        }

        case actionTypes.GET_PENDING_INVITATIONS: {
            return {
                ...state,
                pendingInvitations: action.pendingInvitations,
                invitationsLoaded: true
            };
        }

        case actionTypes.RESPOND_TO_RMS_INVITATION: {
            return {
                ...state,
                rmsInvitations: state.rmsInvitations.map((invite: IRmsInvitation) => invite.id === action.invitationId ? {
                    ...invite,
                    responded: { accepted: action.accepted }
                } : invite)
            };
        }

        case actionTypes.HIDE_RMS_INVITATION: {
            return {
                ...state,
                rmsInvitations: state
                    .rmsInvitations
                    .filter((invite: IRmsInvitation) => invite.id !== action.invitationId)
            };
        }

        case actionTypes.LOADING_INVITATIONS: {
            return {
                ...state,
                invitationsLoaded: false
            };
        }

        case actionTypes.HIDE_SENT_INVITATION: {
            return {
                ...state,
                pendingInvitations: state.pendingInvitations.filter(x => x.id !== action.invitationId)
            };
        }

        default: return state;
    }
};

export interface IRmsReducer {
    invitationsLoaded: boolean;
    rmsInvitations: IRmsInvitation[];
    pendingInvitations: IPendingInvitation[];
}