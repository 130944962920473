import * as actionTypes from '../actions/actionTypes/panelsTypes';
import { IPanelSearchItem } from '../entities/IPanel';
import { EPanelPermissions } from '../entities/IPermissions';

const defaultState = (): IPanelsReducer => ({
    searchResultLawyer: [],
    searchResultLawyerLoaded: false,
    panelPermissions: undefined
}) as IPanelsReducer;

export default (state = defaultState(), action): IPanelsReducer => {
    switch (action.type) {
        case actionTypes.GET_SEARCH_LAWYERS: {
            const payload: actionTypes.IPanelTypes['GET_SEARCH_LAWYERS'] = action;
            return {
                ...state,
                searchResultLawyer: payload.searchResultLawyer,
                searchResultLawyerLoaded: true,
                panelPermissions: (payload.permissions || []).reduce((permissions, permissionType) => ({
                    ...permissions,
                    [permissionType]: true
                }), {})
            };
        }

        case actionTypes.CLEAR_PANELS_LAWYERS: {
            return {
                ...state,
                searchResultLawyer: [],
                searchResultLawyerLoaded: false
            };
        }

        default: {
            return state;
        }
    }
};

export interface IPanelsReducer {
    searchResultLawyer: IPanelSearchItem[];
    searchResultLawyerLoaded: boolean;
    panelPermissions: {
        [key in EPanelPermissions]?: boolean;
    }
}