export enum EProfileType {
    Lawyer = 1,
    NoLawyer = 0
}

export enum EProfileDataType {
    School = 'School',
    Expert = 'Expert',
    Expertise = 'Expertise',
    Language = 'Language',
    Juridiction = 'Juridiction',
    Customer = 'Customer',
    Internal = 'Internal',
    Company = 'Company',
    Organization = 'Organization',
    Competence = 'Competence',
    BusinessSector = 'BusinessSector',
    Country = 'Country',
    Talent = 'Talent',
    FirstReferential = 'FirstReferential',
    SecondReferential = 'SecondReferential',
    ThirdReferential = 'ThirdReferential',
    RoleCategory = 'RoleCategory',
    RoleFunction = 'RoleFunction',
    Department = 'Department',
    Team = 'Team'
}

export enum EReferentialType {
    Language = 'Language',
    Expertises = 'Expertises',
    Competences = 'Competences',
    BusinessSectors = 'BusinessSectors',
    Juridictions = 'Juridictions',
    Schools = 'Schools',
    Countries = 'Countries',
    Talents = 'Talents',
    Companies = 'Companies',
    Organizations = 'Organizations'
}
