import React, { useCallback, useState, useEffect, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormikContext } from 'formik';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import { Checkbox } from '../../../../Common/Inputs/Checkbox';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { inputsShadow } from '../../../../../styleHelpers/mixins/shadow';
import * as PopupStyles from '../../../Common/Styles';
import { uuidv4 } from '../../../../../tools/authTools';
import { MandatoryFieldStar } from '../../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { IFormData } from '../../../../../entities/IValidation';
import { SingleValidator } from './SingleValidator';

const SSectionWrapper = styled.div`
    width: 90%;
    display: block;
    margin: 0 auto;
`;

const SCheckbox = styled.div`
    margin: 0 0 1rem 0;
`;

const RecipientWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Order = styled.div<{isStageNumberEmpty: boolean}>`
    vertical-align: center;
    display: flex;
    margin: 0 1rem 0 0;
    align-items: center;
    justify-content: center;
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[16]};
    width: 39px;
    height: 32px;
    border-radius: 4px;
    background: ${colorStack.white};
    ${inputsShadow()};
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
        ${props => props.isStageNumberEmpty && css`
            border: 1px solid ${colorStack.red};
        `}
    }
`;

interface IValidator {
    childId: string;
    technicalId: string;
    childName: string;
    type: number;
    picture: string;
    email: string;
    labelId: string;
    selectedStatusKey: string;
    stageId: number;
    role: {
        id: string,
        name: string,
        key: string
    };
    validatorType: {
        id: string,
        name: string,
        key: string
    }
}

interface IProps {
    errors: FormikErrors<IFormData>;
    clusterId?: string;
    organizationId?: string;
    data: IValidator[];
    setData(data: IValidator[]);
}

export const Step1: FC<React.PropsWithChildren<IProps>> = props => {
    const intl = useIntl();
    const formikContext = useFormikContext<IFormData>();
    const [addApproverDisabled, setAddApproverDisabled] = useState<boolean>(false);
    const [isStageNumberEmpty, setIsStageNumberEmpty] = useState<boolean>(false);

    const changeCheckbox = useCallback((checked: boolean) => {
        formikContext.setFieldValue('isChecked', checked);
    }, []);

    useEffect(() => {
        if (!formikContext.values.isChecked) {
            props.setData(props.data.map(e => ({
                ...e,
                stageId: 1
              })));
        }
    }, [formikContext.values.isChecked]);

    useEffect(() => {
        const isApproverFilled = formikContext.values.stages.find(({validators}) =>  !validators[0].principal.childName || !validators[0].principal.email || !validators[0].validatorType);
        const isStageNumberFilled = props.data.find(({stageId}) => !stageId);
        if (!isStageNumberFilled) {
            setIsStageNumberEmpty(true);
        } else {
            setIsStageNumberEmpty(false);
        }
        if (!isApproverFilled) {
            setAddApproverDisabled(true);
        } else {
            setAddApproverDisabled(false);
        }
    }, [props.data, formikContext]);

    useEffect(() => {
        formikContext.setFieldValue('step1Valid', addApproverDisabled && isStageNumberEmpty);
    }, [addApproverDisabled, isStageNumberEmpty]);

    useEffect(() => {
        if (props.data.length < 2) {
            formikContext.setFieldValue('isChecked', false);
        }
    }, [props.data.length]);

    const addApprover = useCallback(() => {
        if (addApproverDisabled || props.data.length === 0) {
            const id = uuidv4();
            props.setData([...props.data, {
                childId: id,
                technicalId: id,
                childName: '',
                type: 0,
                picture: '',
                email: '',
                labelId: undefined,
                selectedStatusKey: undefined,
                stageId: 1,
                role: {
                    id: '',
                    name: '',
                    key: ''
                },
                validatorType: {
                    id: '',
                    name: '',
                    key: ''
                }
            }]);
            formikContext.setFieldValue('stages', [...formikContext.values.stages, {stageId: formikContext.values.stages.length + 1, validators: [
            {role: {id: '', name: '', key: ''}, validatorType: '', principal: {childId: id, childName: undefined, type: 0, picture: '', email: undefined}, labelId: id} /// ActionType key
            ]}]);
        }
    }, [formikContext.values, formikContext]);

    useEffect(() => {
        formikContext?.setFieldValue('step', 1);
        const isApproverFilled = (formikContext.values.stages.find(({validators}) =>  !validators[0].principal.childName || !validators[0].principal.email || !validators[0].validatorType));
        if (!isApproverFilled) {
            formikContext.setFieldValue('step1Valid', true);
        }
        if (props.data?.length < 1) {
            addApprover();
        }
    }, []);

    useEffect(() => {
        if (props.data?.length < 1) {
            addApprover();
        }
    }, [props.data?.length]);

    const changeValidatorStage = useCallback((e: React.ChangeEvent<HTMLInputElement>, stageIndex: number, validatorIndex: number, validator: IValidator) => {
        const stage = e.target.value;
            props.setData(props.data.map(elem => elem.technicalId === validator.technicalId ? ({
                ...elem,
                stageId: Number(stage) > 0 ? Number(stage) : undefined
            }) : elem));
    }, [formikContext.values, props.data, props.setData]);

    const changeData = useCallback((newData: IValidator[]) => {
        props.setData(newData);
    }, [props.setData]);

    return (
        <>
            <SSectionWrapper>
                <SCheckbox>
                    <Checkbox onChange={changeCheckbox} checked={formikContext.values.isChecked} disabled={props.data?.length < 2} label={intl.formatMessage({ id: 'approval.button.order' })} />
                </SCheckbox>
                {props.data?.map((validator, index) =>
                    <RecipientWrapper key={validator.technicalId}>
                        {formikContext.values.isChecked &&
                            <Order isStageNumberEmpty={!validator.stageId}>
                                <input type="number" onChange={(e) => changeValidatorStage(e, validator.stageId, formikContext.values.stages.find(elem => elem.stageId === validator?.stageId)?.validators.findIndex(elem => elem?.principal?.childId === validator?.childId), validator)} value={validator?.stageId} />
                            </Order>
                        }
                        <SingleValidator
                            validator={validator}
                            stageIndex={validator.stageId}
                            data={props.data}
                            changeData={changeData}
                            validatorIndex={index}
                        />
                    </RecipientWrapper>
                )}
                <Button onClick={addApprover} leftIco={faPlus} typeSchema={EButtonTypeSchema.TEXT} disabled={!addApproverDisabled}><FormattedMessage id="approval.button.addApprover"  /></Button>
                <PopupStyles.RequiredInformation>
                    <PopupStyles.Title>
                        <MandatoryFieldStar />
                        <FormattedMessage id="approval.requiredInfo" />
                    </PopupStyles.Title>
                </PopupStyles.RequiredInformation>
            </SSectionWrapper>
        </>
    );
};
