import * as actionTypes from '../actions/actionTypes/expertiseTypes';
import { IExpertise } from '../entities/Expertise/IExpertise';

export interface IExpertiseReducer {
    filteredExpertises: IExpertise[];
    filteredExpertisesLoaded: boolean;
}

const defaultState = () => ({
    filteredExpertises: [],
    filteredExpertisesLoaded: false
});

export default (state = defaultState(), action): IExpertiseReducer => {
    switch (action.type) {
        case actionTypes.GET_FILTERED_EXPERTISES_COMPLETED: {
            return {
                ...state,
                filteredExpertisesLoaded: true,
                filteredExpertises: action.filteredExpertises as IExpertise[]
            };
        }

        default:
            return state;
    }
};