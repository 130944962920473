import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { Checkbox } from '../../../../Common/Inputs/Checkbox';
import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Container, PrinciplesContainer } from '../common';

export const PolicySponsoring: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    const changeCheckbox = (checked: boolean) => {
        eApprovalContext.modifyData(checked, 'checkbox', props.context, props.stepName, props.stepNumber);
    };

    return (
        <Container>
            <PrinciplesContainer>
            <div dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: `eApproval.wizard.step1.principles.${eApprovalContext.type}` })
            }} />
            </PrinciplesContainer>
            <div>
                <Checkbox onChange={changeCheckbox} checked={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.step1?.checkbox} label={intl.formatMessage({ id: `eApproval.wizard.step1.policyCheckbox.${eApprovalContext.type}` })} />
            </div>
        </Container>
    );
};
