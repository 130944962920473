import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { NewRT } from '../../../../Common/RichText/NewRT';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { IValue } from '../../../../../entities/IPickers';
import { EContextList, EReferentials, IReferencial } from '../../../../../entities/IGlobal';
import { getReferentials } from '../../../../../actions/globalActions';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

export const Step2: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [purposeOptions, setPurposeOptions] = useState<IReferencial[]>(undefined);

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
        dispatch<GetReferentials>(getReferentials('', EReferentials.FreeDeclarationObject, EContextList.EApproval)).then(response => {
            setPurposeOptions(response);
        });
    }, []);

    const onContextChange = (value: string) => {
        if (value !== eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.[props.stepName]?.context) {
            const span = document.createElement('span');
            span.innerHTML = value;
            if (span.innerText?.trim()?.length > 0) {
                eApprovalContext.modifyData(value, 'context', props.context, props.stepName, props.stepNumber);
            } else if (span.innerText?.trim()?.length === 0) {
                eApprovalContext.modifyData('', 'context', props.context, props.stepName, props.stepNumber);
            }
        }
    };

    const onTextChange = (value: string) => {
        eApprovalContext.modifyData(value, 'requestName', props.context, props.stepName, props.stepNumber);
    };

    const onReferentialChange = (option: IValue) => {
        eApprovalContext.modifyData(option?.data, 'purposeOfDeclaration', props.context, props.stepName, props.stepNumber);
    };

    return (
        <Wrapper>
            <Row numberOfElements={2} noBorder>
                <SelectInput
                    value={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.declarationRequest?.purposeOfDeclaration?.id ?
                        [{
                            key: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.declarationRequest?.purposeOfDeclaration?.id,
                            text: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.declarationRequest?.purposeOfDeclaration?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.declarationRequest?.purposeOfDeclaration
                        }] : undefined}
                    onChange={onReferentialChange}
                    inputLabel={<FormattedMessage id={`eApproval.wizard.step2.${[EApprovalTypes.FreeDeclaration]}.purposeOfDeclaration`} />}
                    required
                    options={purposeOptions?.map(elem => ({
                        key: elem.id,
                        text: elem.name,
                        data: elem
                    }))}
                    type={EDropDownType.DEFAULT}
                />
                <TextComponent
                    value={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.declarationRequest?.requestName}
                    onChange={onTextChange}
                    required
                    label={<FormattedMessage id={`eApproval.wizard.step2.${[EApprovalTypes.FreeDeclaration]}.requestName`} />}
                />
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <NewRT
                        label={intl.formatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.FreeDeclaration]}.context` })}
                        required
                        content={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.declarationRequest?.context}
                        onChange={onContextChange}
                    />
                </div>
            </Row>
        </Wrapper>
    );
};