import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { routerNew } from '../routes';
import { ChunkLoader } from '../components/Common/ChunkLoader';

export const CustomRouter = () => {
    return (
        <RouterProvider router={routerNew} fallbackElement={<ChunkLoader />} />
    );
};