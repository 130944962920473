export const SOGE_SET_DOC_LANG = 'SOGE_SET_DOC_LANG';
export const SOGE_SET_NORMATIVE_DOC_ID = 'SOGE_SET_NORMATIVE_DOC_ID';
export const SOGE_SET_STYLES = 'SOGE_SET_STYLES';
export const SOGE_SET_LOADING_NEW_DATA = 'SOGE_SET_LOADING_NEW_DATA';
export const SOGE_SET_STYLES_CONTAINER = 'SOGE_SET_STYLES_CONTAINER';
export const SOGE_SET_CURRENT_UNIQUE_VERSION_ID = 'SOGE_SET_CURRENT_UNIQUE_VERSION_ID';
export const SOGE_SET_TABLE_OF_CONTENTS = 'SOGE_SET_TABLE_OF_CONTENTS';
export const SOGE_GET_VERSION_DETAILS = 'SOGE_GET_VERSION_DETAILS';
export const SOGE_SET_NORMATIVE_DOC_NAME = 'SOGE_SET_NORMATIVE_DOC_NAME';
export const SOGE_SET_REFERENTIALS = 'SOGE_SET_REFERENTIALS';
export const SET_CURRENT_REAL_VERSION_ID = 'SET_CURRENT_REAL_VERSION_ID';
export const SOGE_SET_ALL_VERSIONS = 'SOGE_SET_ALL_VERSIONS';
export const GET_NORM_CONFIG = 'GET_NORM_CONFIG';
export const NORMS_CONFIG_REQUESTED = 'NORMS_CONFIG_REQUESTED';
