import * as actionTypes from '../actions/actionTypes/adminTypes';
import { ISingleCompany } from '../entities/IAdmin';

export interface IAdminReducer {
    company: ISingleCompany;
}

const defaultState = (): IAdminReducer => ({
    company: undefined
});

export default (state = defaultState(), action): IAdminReducer => {
    switch (action.type) {
        case actionTypes.GET_SINGLE_COMPANY: {
            return {
                ...state,
                company: action.company
            };
        }

        case actionTypes.CLEAR_COMPANY: {
            return {
                ...state,
                company: undefined
            };
        }
        default: {
            return state;
        }
    }
};