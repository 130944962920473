import React, { useState, useEffect, FC, createContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes, IReferencial, IUserBrief } from '../../../../entities/IGlobal';
import { ISingleCluster } from '../../../../entities/IClusters';
import { PolicySponsoring } from './SponsoringSteps/Step1';
import { DeclarationSponsoring } from './SponsoringSteps/Step2';
import { EApprovalTypeSelectStep } from './EApprovalTypeSelectStep';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';
import { AttachmentInformationSponsoring } from './SponsoringSteps/Step3';
import { CreatorInformationSponsoring } from './SponsoringSteps/Step5';
import { ExpensesStep1 } from './ExpencesSteps/Step1';
import { ExpensesStep2 } from './ExpencesSteps/Step2';
import { ExpensesStep3 } from './ExpencesSteps/Step3';
import { ExpensesStep5 } from './ExpencesSteps/Step5';
import { GroupingStep1 } from './GroupingSteps/Step1';
import { GroupingStep2 } from './GroupingSteps/Step2';
import { GroupingStep3 } from './GroupingSteps/Step3';
import { GroupingStep4 } from './GroupingSteps/Step4';
import { GroupingStep5 } from './GroupingSteps/Step5';
import { GroupingStep6 } from './GroupingSteps/Step6';
import { GroupingStep8 } from './GroupingSteps/Step8';
import { Confirm } from './Confirm';
import { postEApprovalForm } from '../../../../actions/clustersActions';
import { Step1 } from './FreeDeclaration/Step1';
import { Step2 } from './FreeDeclaration/Step2';
import { Step3 } from './FreeDeclaration/Step3';
import { Step4 } from './FreeDeclaration/Step4';
import { Step5 } from './FreeDeclaration/Step5';
import { Step6 } from './FreeDeclaration/Step6';
import { ExpensesStep4 } from './ExpencesSteps/Step4';
import { GroupingStep7 } from './GroupingSteps/Step7';
import { SponsoringStep4 } from './SponsoringSteps/Step4';
import { ExpensesStep6 } from './ExpencesSteps/Step6';
import { GroupingStep9 } from './GroupingSteps/Step9';
import { SponsoringStep6 } from './SponsoringSteps/Step6';

type PostEApprovalForm = ReturnType<typeof postEApprovalForm>;

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowPopup(showContract: boolean);
}

export enum EApprovalTypes {
    Sponsoring = 'eapproval-sponsoring',
    Grouping = 'eapproval-grouping',
    Expenses = 'eapproval-event-expenses',
    FreeDeclaration = 'eapproval-free-declaration'
}

export interface Message {
    body: string;
    subject: string;
}

export interface ISingleApprover {
    status: {
        type: string;
        name: string;
    };
    approver: IUserBrief;
    stageId?: number;
}

export interface IEApprovalSponsoring {
    step1: {
        checkbox: boolean;
    };
    declaration: {
        typeOfProject: IReferencial;
        nameOfProject: string;
        originOfProject: IReferencial;
        agencyCentre: string;
        beneficiary: ISIngleLegalEntity;
        existAOF: boolean;
        descriptionAOF: string;
        contractDescription: string;
        amountOfContribution: {
            number: string;
            unit: IReferencial;
        };
    };
    attachmentInformation: {
        type: IReferencial;
        attachment: {
            name: string,
            directUri: string,
            size: number,
            fileId: string
        };
    }[];
    creatorInformation: {
        creator: IUserBrief;
        legalEntity: ISIngleLegalEntity;
        jobTitle: string;
        manager: IUserBrief;
    };
    approvers: ISingleApprover[];
    cfo: ISingleApprover
    ceo: ISingleApprover
    approvalMessage: Message;
}

export interface IEApprovalExpenses {
    step1: {
        checkbox: boolean;
    };
    eventDescription: {
        typeOfReception: 0 | 1;
        agenceCentre: string;
        nature: IReferencial;
        date: Date;
        context: string;
        totalAmount: {
            number: string;
            unit: IReferencial;
        };
    };
    participantsInformation: {
        numberOfParticipants: {
            number: string;
            unit: null;
        };
        information: string;
        placeOfEvent: string;
    };
    attachmentInformation: {
        type: IReferencial;
        attachment: {
            name: string,
            directUri: string,
            size: number,
            fileId: string
        };
    }[];
    creatorInformation: {
        creator: IUserBrief;
        legalEntity: ISIngleLegalEntity;
        jobTitle: string;
        manager: IUserBrief;
    };
    approvers: ISingleApprover[];
    cfo: ISingleApprover;
    ceo: ISingleApprover;
    approvalMessage: Message;
}

export interface IEApprovalFreeDeclaration {
    step1: {
        checkbox: boolean;
    };
    declarationRequest: {
        purposeOfDeclaration: IReferencial;
        requestName: string;
        context: string;
    };
    attachmentInformation: {
        type: IReferencial;
        attachment: {
            name: string,
            directUri: string,
            size: number,
            fileId: string
        };
    }[];
    creatorInformation: {
        creator: IUserBrief;
        legalEntity: ISIngleLegalEntity;
        jobTitle: string;
        manager: IUserBrief;
    };
    approvers: ISingleApprover[];
    approversOrderCheckbox: boolean;
    approvalMessage: Message;
}
export interface IEApprovalGrouping {
    step1: {
        checkbox: boolean;
    };
    groupingContext: {
        nameOfProject: string;
        date: Date;
        amountAllocated: {
            number: string;
            unit: IReferencial;
        };
        contractValue: {
            number: string;
            unit: IReferencial;
        };
        reasonForPartnerShip: string;
    };
    partnersRelationship: {
        companyPartners: ISIngleLegalEntity[];
        inEconomicalGrouping: boolean;
        inEconomicalGroupingLegalEntites: ISIngleLegalEntity[];
        isCompeting: boolean;
        isBreakdown: boolean;
        byGeograficalPresence: boolean;
    };
    ethicalAssessment: {
        havePartners: boolean;
        haveObservations: boolean;
        isEthicClause: boolean;
        specification: string;
    };
    groupingDetails: {
        isPartnerShip: boolean;
        jointVenture: boolean;
        isProvision: boolean;
        isPlanSign: boolean;
        groupingBankGuarantees: IReferencial;
        leadCompany: ISIngleLegalEntity;
        groupingCommitment: IReferencial;
    };
    attachmentInformation: {
        type: IReferencial;
        attachment: {
            name: string,
            directUri: string,
            size: number,
            fileId: string
        };
    }[];
    creatorInformation: {
        creator: IUserBrief;
        legalEntity: ISIngleLegalEntity;
        jobTitle: string;
        manager: IUserBrief;
    };
    approvers: ISingleApprover[];
    cfo: ISingleApprover;
    ceo: ISingleApprover;
    approvalMessage: Message;
}

interface IEApprovalData {
    [EApprovalTypes.Sponsoring]?: IEApprovalSponsoring;
    [EApprovalTypes.Expenses]?: IEApprovalExpenses;
    [EApprovalTypes.Grouping]?: IEApprovalGrouping;
    [EApprovalTypes.FreeDeclaration]?: IEApprovalFreeDeclaration;
    techValues: {
        context: EApprovalTypes;
        stepName: string;
        stepNumber: number;
    }
}

export interface IEApprovalContext {
    orgId: string;
    type: EApprovalTypes;
    data: IEApprovalData;
    setType(type: EApprovalTypes);
    modifyData(value: any, field: string, context: EApprovalTypes, stepName: string, stepNumber: number, index?: number);
    removeFromArrays(context: EApprovalTypes, stepName: string, index: number);
}

export interface IMainStepProps {
    context: EApprovalTypes;
    stepName: string;
    stepNumber: number;
}
interface IStep {
    title?: string;
    content: JSX.Element;
}

export const EApprovalContext = createContext<IEApprovalContext>(undefined);

export const EApprovalWizard: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useNavigate();
    const [steps, setSteps] = useState<IStep[]>([]);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [typeOfEApproval, setTypeOfEApproval] = useState<EApprovalTypes>(undefined);
    const [data, setData] = useState<IEApprovalData>(undefined);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);
    const [newClusterData, setNewClusterData] = useState<ISingleCluster>(undefined);
    const [isFirstStep, setIsFirstStep] = useState<boolean>(true);
    const sponsoringStepsArray = [
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step1.title.eapproval-sponsoring' }),
            content: <PolicySponsoring context={EApprovalTypes.Sponsoring} stepName="step1" stepNumber={1} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step2.title.eapproval-sponsoring' }),
            content: <DeclarationSponsoring context={EApprovalTypes.Sponsoring} stepName="declaration" stepNumber={2} />
        },
        {
            content: <AttachmentInformationSponsoring context={EApprovalTypes.Sponsoring} stepName="attachmentInformation" stepNumber={3} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step4.title.eapproval-sponsoring' }),
            content: <CreatorInformationSponsoring context={EApprovalTypes.Sponsoring} stepName="creatorInformation" stepNumber={5} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step5.title.eapproval-sponsoring' }),
            content: <SponsoringStep4 context={EApprovalTypes.Sponsoring} stepName="approvers" stepNumber={4} />
        },
        {
            content: <SponsoringStep6 context={EApprovalTypes.Sponsoring} stepName="approvalMessage" stepNumber={6} />
        },
        {
            content: <Confirm />
        }
    ];
    const expensesStepsArray = [
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step1.title.eapproval-expences' }),
            content: <ExpensesStep1 context={EApprovalTypes.Expenses} stepName="step1" stepNumber={1} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step2.title.eapproval-expences' }),
            content: <ExpensesStep2 context={EApprovalTypes.Expenses} stepName="eventDescription" stepNumber={2} />
        },
        {
            content: <ExpensesStep3 context={EApprovalTypes.Expenses} stepName="attachmentInformation" stepNumber={3} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step6.title.eapproval-expences' }),
            content: <ExpensesStep5 context={EApprovalTypes.Expenses} stepName="creatorInformation" stepNumber={5} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step5.title.eapproval-expences' }),
            content: <ExpensesStep4 context={EApprovalTypes.Expenses} stepName="approvers" stepNumber={4} />
        },
        {
            content: <ExpensesStep6 context={EApprovalTypes.Expenses} stepName="approvalMessage" stepNumber={6} />
        },
        {
            content: <Confirm />
        }
    ];
    const freeDeclarationStepsArray = [
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step1.title.free-declaration' }),
            content: <Step1 context={EApprovalTypes.FreeDeclaration} stepName="step1" stepNumber={1} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step2.title.free-declaration' }),
            content: <Step2 context={EApprovalTypes.FreeDeclaration} stepName="declarationRequest" stepNumber={2} />
        },
        {
            content: <Step3 context={EApprovalTypes.FreeDeclaration} stepName="attachmentInformation" stepNumber={3} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step5.title.eapproval-free-declaration' }),
            content: <Step4 context={EApprovalTypes.FreeDeclaration} stepName="creatorInformation" stepNumber={4} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step5.title.free-declaration' }),
            content: <Step5 context={EApprovalTypes.FreeDeclaration} stepName="approvers" stepNumber={5} />
        },
        {
            content: <Step6 context={EApprovalTypes.FreeDeclaration} stepName="approvalMessage" stepNumber={6} />
        },
        {
            content: <Confirm />
        }
    ];
    const groupingStepsArray = [
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step1.title.eapproval-grouping' }),
            content: <GroupingStep1 context={EApprovalTypes.Grouping} stepName="step1" stepNumber={1} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step2.title.eapproval-grouping' }),
            content: <GroupingStep2 context={EApprovalTypes.Grouping} stepName="groupingContext" stepNumber={2} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step3.title.eapproval-grouping' }),
            content: <GroupingStep3 context={EApprovalTypes.Grouping} stepName="partnersRelationship" stepNumber={3} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step4.title.eapproval-grouping' }),
            content: <GroupingStep4 context={EApprovalTypes.Grouping} stepName="ethicalAssessment" stepNumber={4} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step5.title.eapproval-grouping' }),
            content: <GroupingStep5 context={EApprovalTypes.Grouping} stepName="groupingDetails" stepNumber={5} />
        },
        {
            content: <GroupingStep6 context={EApprovalTypes.Grouping} stepName="attachmentInformation" stepNumber={6} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step7.title.eapproval-grouping' }),
            content: <GroupingStep8 context={EApprovalTypes.Grouping} stepName="creatorInformation" stepNumber={8} />
        },
        {
            title: intl.formatMessage({ id: 'eApproval.wizard.step5.title.grouping' }),
            content: <GroupingStep7 context={EApprovalTypes.Grouping} stepName="approvers" stepNumber={7} />
        },
        {
            content: <GroupingStep9 context={EApprovalTypes.Grouping} stepName="approvalMessage" stepNumber={9} />
        },
        {
            content: <Confirm />
        }
    ];

    useEffect(() => {
        switch (typeOfEApproval) {
            case EApprovalTypes.Sponsoring:
                return setSteps(sponsoringStepsArray);
            case EApprovalTypes.Expenses:
                return setSteps(expensesStepsArray);
            case EApprovalTypes.Grouping:
                return setSteps(groupingStepsArray);
            case EApprovalTypes.FreeDeclaration:
                return setSteps(freeDeclarationStepsArray);
            default:
                return setSteps([]);
        }
    }, [typeOfEApproval]);

    const removeFromArrays = (context: EApprovalTypes, stepName: string, index: number) => {
        setData(state => ({
            ...state,
            [context]: {
                ...state?.[context],
                [stepName]: state?.[context]?.[stepName].filter((elem, i) => i !== index)
            }
        }));
    };

    /** Interfaces are in methods to push value */
    const modifyData = (value: any, field: string, context: EApprovalTypes, stepName: string, stepNumber: number, index?: number) => {
        if (field !== undefined) {
            setData(state => ({
                ...state,
                [context]: {
                    ...state?.[context],
                    [stepName]: index !== undefined ? ((state?.[context]?.[stepName]?.[index] ? state?.[context]?.[stepName] : [...state?.[context]?.[stepName] || [], { [field]: value }]).map((elem, i) => index === i ? ({
                        ...elem,
                        [field]: value
                    }) : elem)) : (field ? {
                        ...state?.[context]?.[stepName],
                        [field]: value
                    } : value)
                },
                techValues: {
                    context,
                    stepName,
                    stepNumber
                }
            }));
        } else {
            setData(state => ({
                ...state,
                techValues: {
                    context,
                    stepName,
                    stepNumber
                }
            }));
        }
    };

    useEffect(() => {
        globalValidation(data?.techValues?.context, data?.techValues?.stepName, data?.techValues?.stepNumber);
    }, [data, typeOfEApproval]);

    const globalValidation = async (context: EApprovalTypes, stepName: string, stepNumber: number) => {
        const approversOrderOn = data?.['eapproval-free-declaration']?.approversOrderCheckbox;
        const approversLenght = data?.[typeOfEApproval]?.approvers?.length > 0;
        let schema = Yup.object().shape({
            [EApprovalTypes.FreeDeclaration]: context === EApprovalTypes.FreeDeclaration && Yup.object({
                step1: stepNumber === 1 && Yup.object({
                    checkbox: Yup.boolean().oneOf([true]).required()
                }),
                declarationRequest: stepNumber === 2 && Yup.object({
                    purposeOfDeclaration: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    requestName: Yup.string().required(),
                    context: Yup.string().required()
                }),
                creatorInformation: stepNumber === 4 && Yup.object({
                    creator: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    legalEntity: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    jobTitle: Yup.string(),
                    manager: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                approvers: stepNumber === 5 && Yup.array().of(Yup.object({
                    status: Yup.object().shape({
                        type: Yup.string().required(),
                        name: Yup.string()
                    }),
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    stageId: approversOrderOn && Yup.number().required().nullable()
                })),
                approvalMessage: stepNumber === 6 && Yup.object({
                    body: Yup.string(),
                    subject: Yup.string().required()
                })
            }),
            [EApprovalTypes.Grouping]: context === EApprovalTypes.Grouping && Yup.object({
                step1: stepNumber === 1 && Yup.object({
                    checkbox: Yup.boolean().oneOf([true]).required()
                }),
                groupingContext: stepNumber === 2 && Yup.object({
                    nameOfProject: Yup.string().required(),
                    date: Yup.string().required(),
                    amountAllocated: Yup.object({
                        number: Yup.string().required(),
                        unit: Yup.object().shape({
                            uniqueKey: Yup.string().required()
                        })
                    }),
                    contractValue: Yup.object({
                        number: Yup.string().required(),
                        unit: Yup.object().shape({
                            uniqueKey: Yup.string().required()
                        })
                    }),
                    reasonForPartnerShip: Yup.string().required()
                }),
                partnersRelationship: stepNumber === 3 && Yup.object({
                    companyPartners: Yup.array()
                        .of(Yup.object().shape({
                            id: Yup.string().required()
                        })).required(),
                    inEconomicalGrouping: Yup.boolean().oneOf([true, false]).required(),
                    inEconomicalGroupingLegalEntites: Yup.array().when('inEconomicalGrouping', {
                        is: true,
                        then: Yup.array().of(Yup.object().shape({
                            id: Yup.string().required()
                        })).required()
                    }),
                    isCompeting: Yup.boolean().oneOf([true, false]).required(),
                    isBreakdown: Yup.boolean().oneOf([true, false]).required(),
                    byGeograficalPresence: Yup.boolean().oneOf([true, false]).required()
                }),
                ethicalAssessment: stepNumber === 4 && Yup.object({
                    havePartners: Yup.boolean().oneOf([true, false]).required(),
                    haveObservations: Yup.boolean().oneOf([true, false]).required(),
                    isEthicClause: Yup.boolean().oneOf([true, false]).required(),
                    specification: Yup.string().when('haveObservations', {
                        is: true,
                        then: Yup.string().required()
                    })
                }),
                groupingDetails: stepNumber === 5 && Yup.object({
                    isPartnerShip: Yup.boolean().oneOf([true, false]).required(),
                    jointVenture: Yup.boolean().oneOf([true, false]).required(),
                    isProvision: Yup.boolean().oneOf([true, false]).required(),
                    isPlanSign: Yup.boolean().oneOf([true, false]).required(),
                    isManufacturer: Yup.boolean().oneOf([true, false]).required(),
                    groupingBankGuarantees: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    leadCompany: Yup.object().shape({
                        id: Yup.string()
                    }),
                    groupingCommitment: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                approvers: (stepNumber === 7  && approversLenght) &&  Yup.array().of(Yup.object({
                    status: Yup.object().shape({
                        type: Yup.string().required(),
                        name: Yup.string()
                    }),
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                })),
                cfo: stepNumber === 7 && Yup.object({
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                ceo: stepNumber === 7 && Yup.object({
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                creatorInformation: stepNumber === 8 && Yup.object({
                    creator: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    legalEntity: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    jobTitle: Yup.string(),
                    manager: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                approvalMessage: stepNumber === 9 && Yup.object({
                    body: Yup.string(),
                    subject: Yup.string().required()
                })
            }),
            [EApprovalTypes.Sponsoring]: context === EApprovalTypes.Sponsoring && Yup.object({
                step1: stepNumber === 1 && Yup.object({
                    checkbox: Yup.boolean().oneOf([true]).required()
                }),
                declaration: stepNumber === 2 && Yup.object({
                    typeOfProject: Yup.object().shape({
                        id: Yup.string().required()
                    }).required(),
                    nameOfProject: Yup.string().required(),
                    originOfProject: Yup.object().shape({
                        id: Yup.string().required()
                    }).required(),
                    agencyCentre: Yup.string(),
                    beneficiary: Yup.object().shape({
                        id: Yup.string().required()
                    }).required(),
                    existAOF: Yup.boolean().oneOf([true, false]).required(),
                    descriptionAOF: Yup.string().when('existAOF', {
                        is: true,
                        then: Yup.string().required()
                    }),
                    contractDescription: Yup.string().required(),
                    amountOfContribution: Yup.object().shape({
                        number: Yup.string().required(),
                        unit: Yup.object().shape({
                            uniqueKey: Yup.string().required()
                        })
                    })
                }),
                approvers: (stepNumber === 4  && approversLenght) &&  Yup.array().of(Yup.object({
                    status: Yup.object().shape({
                        type: Yup.string().required(),
                        name: Yup.string()
                    }),
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                })),
                cfo: stepNumber === 4 && Yup.object({
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                ceo: stepNumber === 4 && Yup.object({
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                creatorInformation: stepNumber === 5 && Yup.object({
                    creator: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    legalEntity: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    jobTitle: Yup.string(),
                    manager: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                approvalMessage: stepNumber === 6 && Yup.object({
                    body: Yup.string(),
                    subject: Yup.string().required()
                })
            }),
            [EApprovalTypes.Expenses]: context === EApprovalTypes.Expenses && Yup.object({
                step1: stepNumber === 1 && Yup.object({
                    checkbox: Yup.boolean().oneOf([true]).required()
                }),
                eventDescription: stepNumber === 2 && Yup.object({
                    typeOfReception: Yup.number().required(),
                    agenceCentre: Yup.string(),
                    nature: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    date: Yup.string().required(),
                    context: Yup.string().required(),
                    totalAmount: Yup.object().shape({
                        number: Yup.string().required(),
                        unit: Yup.object().shape({
                            uniqueKey: Yup.string().required()
                        })
                    }),
                    numberOfParticipants: Yup.object().shape({
                        number: Yup.string().required()
                    }).required()
                }),
                creatorInformation: stepNumber === 5 && Yup.object({
                    creator: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    legalEntity: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    jobTitle: Yup.string(),
                    manager: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                approvers: (stepNumber === 4  && approversLenght) &&  Yup.array().of(Yup.object({
                    status: Yup.object().shape({
                        type: Yup.string().required(),
                        name: Yup.string()
                    }),
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                })),
                cfo: stepNumber === 4 && Yup.object({
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                ceo: stepNumber === 4 && Yup.object({
                    approver: Yup.object().shape({
                        id: Yup.string().required()
                    })
                }),
                approvalMessage: stepNumber === 6 && Yup.object({
                    body: Yup.string(),
                    subject: Yup.string().required()
                })
            })
        });
        if ((data?.[context]?.[stepName]) || !!typeOfEApproval) {
            const result = await schema.isValid(data);
            setIsValid(!!typeOfEApproval && result);
        } else {
            setIsValid(false);
        }
    };

    const setType = (type: EApprovalTypes) => {
        setTypeOfEApproval(type);
    };

    const checkIsFirstStep = (value: boolean) => {
        setIsFirstStep(value);
    };

    useEffect(() => {
        setData(undefined);
        setTypeOfEApproval(undefined);
    }, []);

    const finishHandler = () => {
        setData(undefined);
        setType(undefined);
        props.setShowPopup(false);
        if (isFormFinnished) {
            history(`/orgs/${props.organizationUrlName}/cluster/${newClusterData.id}/overview`);
            setIsFormFinnished(false);
        } else if (props.isHome) {
            history('/');
        }
    };

    const finishDataHandler = () => {
        let finishData = undefined;
        switch (typeOfEApproval) {
            case EApprovalTypes.FreeDeclaration:
                finishData = { ...data['eapproval-free-declaration'] };
                delete finishData?.approversOrderCheckbox;
                delete finishData.step1;
                finishData = {...finishData, approvers: finishData?.approvers?.map(elem => ({...elem, status: elem.status.type}))};
                return finishData;
            case EApprovalTypes.Expenses:
                finishData = {...data['eapproval-event-expenses'], approvers: [...(data['eapproval-event-expenses']?.approvers || []),
                    data['eapproval-event-expenses']?.cfo,
                    data['eapproval-event-expenses']?.ceo
                ]};
                delete finishData.step1;
                delete finishData?.ceo;
                delete finishData?.cfo;
                finishData = {...finishData, approvers: finishData?.approvers?.map(elem => ({...elem, status: elem.status.type}))};
                return finishData;
            case EApprovalTypes.Sponsoring:
                finishData = {...data['eapproval-sponsoring'], approvers: [...(data['eapproval-sponsoring']?.approvers || []),
                    data['eapproval-sponsoring']?.cfo,
                    data['eapproval-sponsoring']?.ceo
                ]};
                delete finishData.step1;
                delete finishData?.ceo;
                delete finishData?.cfo;
                finishData = {...finishData, approvers: finishData?.approvers?.map(elem => ({...elem, status: elem.status.type}))};
                return finishData;
            case EApprovalTypes.Grouping:
                finishData = {...data['eapproval-grouping'], approvers: [...(data['eapproval-grouping']?.approvers || []),
                    data['eapproval-grouping']?.cfo,
                    data['eapproval-grouping']?.ceo
                ]};
                delete finishData.step1;
                delete finishData?.ceo;
                delete finishData?.cfo;
                finishData = {...finishData, approvers: finishData?.approvers?.map(elem => ({...elem, status: elem.status.type}))};
                return finishData;
            default:
                return;
        }
    };

    const confirmHandler = () => {
        return dispatch<PostEApprovalForm>(postEApprovalForm(props.organizationId, typeOfEApproval, finishDataHandler())).then((response) => {
            setStepFinished(true);
            setIsFormFinnished(true);
            setNewClusterData(response);
        }).catch(reason => {
            console.log(reason);
        });
    };

    const clearForm = () => {
        props.setShowPopup(false);
        setData(undefined);
        setType(undefined);
    };

    const initState: IEApprovalContext = {
        orgId: props.organizationId,
        type: typeOfEApproval,
        data,
        modifyData,
        removeFromArrays,
        setType
    };

    return (
        <EApprovalContext.Provider value={initState}>
            <StepsPopup
                showPopup={props.isPopupShown}
                size={EPopupSizes.BIG}
                title={intl.formatMessage({ id: isFirstStep ? 'eApproval.wizard.typeTitle' : `eApproval.wizard.title.${typeOfEApproval}` })}
                isFormValid={isValid}
                finishHandler={finishHandler}
                dirty
                confirmFinished={stepFinished}
                preventClosingOnClickingOutside
                clearForm={clearForm}
                confirmHandler={steps?.length > 0 ? confirmHandler : undefined}
                confirmDisabled={false}
                steps={[
                    {
                        content: <EApprovalTypeSelectStep organizationId={props.organizationId} checkIsFirstStep={checkIsFirstStep} />
                    },
                    ...steps
                ]}
            />
        </EApprovalContext.Provider>
    );
};
