import { action } from '../tools/reduxTools';
import { ValidationResponse, IFormData, ITypesWithStatuses, IGetSingleLastValidation, EStatusesTypes } from '../entities/IValidation';
import { ISnapshotData } from '../entities/IClusters';
import * as actionTypes from './actionTypes/approvalTypes';
import { CLEAR_APPROVAL_OVERVIEW_INFORMATIONS } from './actionTypes/approvalTypes';

export const changeValidationStatus = (resourceId: string, validationRequestId: string, status: EStatusesTypes, principalId?: string) => action<Promise<boolean>>(async (dispatch, getState, http) => {
    return http.validationsApiPatch(`/resources/${resourceId}/validations/${validationRequestId}/setStatus/${status}${principalId ? `?principalId=${principalId}` : ''}`);
});

export const getSingleValidation = (resourceId: string, validationRequestId: string, onlyData?: boolean) => action<Promise<ValidationResponse>>(async (dispatch, getState, http) => {
    return http.validationsApiGet(`/resources/${resourceId}/validations/${validationRequestId}`).then((response: ValidationResponse) => {
        if (!onlyData) {
            dispatch({
                type: actionTypes.SET_APPROVAL_OVERVIEW_INFORMATIONS,
                singleValidation: response
            });
        }
        return response;
    });
});

export const createNewValidation = (body: IFormData) => action<Promise<IFormData>>(async (dispatch, getState, http) => {
    return http.validationsApiPost(`/validations`, body);
});

export const getSnapshotImage = (validationRequestId: string) => action<Promise<{ link: string, name: string }>>(async (dispatch, getState, http) => {
    return http.validationsApiGet(`/validations/${validationRequestId}/snapshot`);
});

export const getApprovalSnapshot = (organizationId: string, clusterId: string) => action<Promise<ISnapshotData>>(async (dispatch, getState, http) => {
    const body = {
        organizationId,
        clusterId
    };

    return http.validationsApiPost(`/validations/new-snapshot`, body);
});

export const getSingleLastValidation = (resourceId: string, type: string) => action<Promise<IGetSingleLastValidation>>(async (dispatch, getState, http) => {
    const body = {
        type
    };
    return http.validationsApiPost(`/resources/${resourceId}/validations?page=0&size=1`, body).then((response: IGetSingleLastValidation) => {
        dispatch({
            type: actionTypes.SET_APPROVAL_OVERVIEW_INFORMATIONS,
            singleValidation: response.items[0]
        });
        return response;
    });
});

export const getTypesWithStatuses = () => action<Promise<ITypesWithStatuses[]>>(async (dispatch, getState, http) => {
    return http.validationsApiGet(`/validations/typesWithStatuses`);
});

export const disableValidationRequest = (resourceId: string, validationRequestId: string) => action<Promise<boolean>>(async (dispatch, getState, http) => {
    return http.validationsApiPost(`/resources/${resourceId}/validations/${validationRequestId}/disable`, {});
});

export const clearApprovalInfo = () => action(async(dispatch) => {
    dispatch({ type: CLEAR_APPROVAL_OVERVIEW_INFORMATIONS});
});