import { IAppConfigType } from '../../entities/IConfiguration';
import { EAuthConstants } from '../../tools/authTools';

export const fetchB2CToken = async (code: string, codeChallenge: string, applicationConfig: IAppConfigType): Promise<Response> => {
    return await fetch(`${applicationConfig.authority}/oauth2/v2.0/token?`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: applicationConfig.clientID,
            scope: `${applicationConfig.b2cScopes[0]} openid profile offline_access`,
            code: code,
            redirect_uri: `${applicationConfig.redirectURI}${EAuthConstants.RequestAuthEndRoute}`,
            code_verifier: codeChallenge
        })
    });
};

export const refreshB2CToken = async (refreshToken: string, applicationConfig: IAppConfigType): Promise<Response> => {
    return await fetch(`${applicationConfig.authority}/oauth2/v2.0/token?`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: applicationConfig.clientID,
            scope: `${applicationConfig.b2cScopes[0]} openid profile offline_access`,
            refresh_token: refreshToken,
            redirect_uri: `${applicationConfig.redirectURI}${EAuthConstants.RequestAuthEndRoute}`
        })
    });
};