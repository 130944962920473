import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';

const Wrapper = styled.div`
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background: ${colorStack.bodyBg};
`;

export const LineSeparator: FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Wrapper />
    );
};