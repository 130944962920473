import React, { useState, useCallback, FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { createNewCluster } from '../../../../actions/clustersActions';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { Step1 } from './Step1';
import { ETemplateTypes, ISingleCluster, EClusterPrivacy, ETemplateKeys } from '../../../../entities/IClusters';
import { Confirm } from './Confirm';
import { useOrganization } from '../../../../tools/organizationHooks';
import { IState } from '../../../../reducers';
import { IClusterReducer } from '../../../../reducers/clustersReducer';

type CreateNewCluster = ReturnType<typeof createNewCluster>;

const initialValues = {
    name: '',
    privacy: EClusterPrivacy.Private
};

const shema = Yup.object().shape({
    name: Yup.string().required().max(100),
    privacy: Yup.string().required()
});

export interface IFormikData {
    formData: {
        cluster: {
            clusterTemplateId: string;
            clusterTemplateName: string;
            clusterTemplateKey: string;
            clusterTemplateColor: string;
        };
        name: string;
        privacy: EClusterPrivacy;
    };
    steps: number;
}

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowPopup(showContract: boolean);
}

export const LobbyingWizard: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useNavigate();
    const { clusterTemplates } = useSelector<IState, IClusterReducer>(state => state.clusters);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [newClusterData, setNewClusterData] = useState<ISingleCluster>(undefined);
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);

    const closePopup = useCallback((resetForm) => {
        resetForm();
        props.setShowPopup(false);
        setStepFinished(false);
        if (isFormFinnished) {
            history(`/orgs/${props.organizationUrlName}/cluster/${newClusterData.id}/overview`);
            setIsFormFinnished(false);
        } else if (props.isHome) {
            history('/');
        }
    }, [props.organizationUrlName, newClusterData, props.isHome]);

    const onSubmit = useCallback((values: { name: string, privacy: EClusterPrivacy }) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<CreateNewCluster>(createNewCluster({
            organizationId: props.organizationId,
            name: values.name,
            description: '',
            privacy: values.privacy,
            organizationUrlName: props.organizationUrlName,
            clusterTemplateId: clusterTemplates?.filter(template => template.templateKey === ETemplateKeys.Lobbying)?.[0].id,
            organizationName: currentOrganization?.details?.name
        })).then(response => {
            setIsFormFinnished(true);
            setNewClusterData(response);
            setIsConfirmButtonDisabled(false);
            setStepFinished(true);
        });
    }, [dispatch, setIsConfirmButtonDisabled, props.organizationId, clusterTemplates]);

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, []);

    return (
        <Formik<{ name: string, privacy: EClusterPrivacy }>
            initialValues={initialValues}
            validationSchema={shema}
            isInitialValid={shema.isValidSync(initialValues)}
            onSubmit={onSubmit}>
            {({ isValid, dirty, submitForm, resetForm, values }) => (
                <StepsPopup
                    showPopup={props.isPopupShown}
                    preventClosingOnClickingOutside
                    size={EPopupSizes.MEDIUM}
                    title={intl.formatMessage({ id: 'lobbying.wizard.title' })}
                    isFormValid={isValid}
                    dirty={dirty}
                    confirmDisabled={isConfirmButtonDisabled}
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    wizardType={ETemplateTypes.Contract}
                    clearForm={() => clearForm(resetForm)}
                    confirmHandler={submitForm}
                    steps={[
                        {
                            title: intl.formatMessage({ id: 'lobbying.wizard.step1.title' }),
                            content: <Step1 />
                        },
                        {
                            content: <Confirm clusterName={newClusterData?.presentation?.name} />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};