import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { EProfileType } from '../../../../../entities/IGlobal';
import { IOrganizationMember } from '../../../../../entities/IOrganization';
import { ErrorText } from './StakeStyles';
import { IValue } from '../../../../../entities/IPickers';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';

export const Wrapper = styled.div`

`;

export interface IPeopleContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error?: string;
    context?: 'PLE' | 'Cluster';
    stakeOptions?: {
        fullWidth?: boolean;
    }
    setEditingData(propertie: any);
}

export const PeopleContent: FC<React.PropsWithChildren<IPeopleContentProps>> = ({ error, data, editStake, stakeOptions, propertieId, setEditingData }) => {
    const [content, setContent] = useState<IOrganizationMember>(data?.value);
    const referentialIdentity = data.configuration?.referentialIdentity;

    useEffect(() => {
        !editStake && setContent(data.value);
    }, [data, editStake]);

    const onSelectElement = (data: IValue[]) => {
        if (data?.length) {
            setFieldValue(data[0].data as IOrganizationMember)
        } else {
            setFieldValue(undefined)
        }
    };

    const setFieldValue = (users: IOrganizationMember) => {
        if (users?.id) {
            setContent(users);
            setEditingData({
                ...data,
                value: referentialIdentity === 'Custom' ? ({
                    firstName: users.firstName,
                    lastName: users.lastName,
                    id: users.id,
                    picture: users.picture,
                    position: undefined,
                    role: undefined,
                    joiningDate: undefined,
                    jobTitle: users.jobTitle,
                    department: undefined,
                    cabinet: { name: users.cabinet },
                    type: EProfileType.Personal
                }) : { ...users, type: EProfileType.Personal },
                id: propertieId
            });
        } else {
            setContent(undefined);
            setEditingData({
                ...data,
                value: undefined,
                id: propertieId
            });
        }

    };

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <div>
                        <UsersPicker
                            value={!!content ? [{ key: content.id, text: `${content.firstName} ${content.lastName}`, data: { ...content, type: EProfileType.Personal } }] : []}
                            onSelectElement={onSelectElement}
                        />
                    </div>
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                <>
                    {!!content &&
                        <ProfilesSummary
                            fullWidth={stakeOptions?.fullWidth}
                            key={content.id}
                            type={EProfileType.Personal}
                            profile={{ ...content }}
                        />
                    }
                </>
            )}
        </Wrapper>
    );
};
