import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { EHintType, Hint } from '../Hint/Hint';
import { LabelWithEllipsis } from '../Label/Label';

const Wrapper = styled.div<{ error: boolean, disabled: boolean }>`
    textarea {
        resize: none;
        border: 2px solid ${colorStack.ligthGrey};
        background: ${colorStack.whiteGrey};
        padding: 1rem;
        border-radius: 8px;
        width: 100%;
        min-height: 250px;
        outline: none;
        color: ${colorStack.content};
        ${fontSizeAndHeight[16]};
        &::placeholder {
            color: ${colorStack.middleGrey};
            font-weight: 400;
            ${fontSizeAndHeight[16]};
            font-family: 'Roboto', sans-serif;
            &:disabled {
                color: ${colorStack.middleGrey};
            }
        }
        &:hover {
            border: 2px solid ${colorStack.middleBlue};
        };
        &:focus {
            border: 2px solid ${colorStack.middleBlue};
        };
        &:focus-visible {
            border: 2px solid ${colorStack.middleBlue};
        };
        &:active {
            border: 2px solid ${colorStack.middleBlue};
        };
        ${({ disabled }) => disabled && css`
            border: 2px solid ${colorStack.disabled};
            background: ${colorStack.ligthGrey};
        `}
        ${({ error }) => error && css`
            border: 2px solid ${colorStack.middleRed};
            &:hover {
                border: 2px solid ${colorStack.middleRed};
            };
            &:focus {
                border: 2px solid ${colorStack.middleRed};
            };
            &:focus-visible {
                border: 2px solid ${colorStack.middleRed};
            };
            &:active {
                border: 2px solid ${colorStack.middleRed};
            };
        `};
    }
`;

interface IProps {
    value: string;
    placeholder?: string;
    disabled?: boolean;
    errorMsg?: string;
    label?: string | React.ReactNode;
    tooltipMsg?: string;
    tooltipTitle?: string;
    required?: boolean;
    onChange(e: React.ChangeEvent<HTMLTextAreaElement>)
}

export const TextArea: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper error={!!props.errorMsg} disabled={props.disabled}>
            {props.label && <LabelWithEllipsis required={props.required} tooltipMsg={props.tooltipMsg} tooltipTitle={props.tooltipTitle}>{props.label}</LabelWithEllipsis>}
            <textarea onChange={props.onChange} value={props.value} disabled={props.disabled} placeholder={props.placeholder} />
            {props.errorMsg &&
                <Hint type={EHintType.ERROR}>{props.errorMsg}</Hint>
            }
        </Wrapper>
    );
};