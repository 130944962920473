import React, { FC } from 'react';
import { Form, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

import { media } from '../../../../styleHelpers/breakpoint';
import { Privacy } from '../../../Common/Privacy/Privacy';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { IFormikData } from '.';
import { IValue } from '../../../../entities/IPickers';
import { useOrganization } from '../../../../tools/organizationHooks';

const FormField = styled.div<{ half?: boolean }>`
    width: 100%;
    padding-bottom: 2rem;

    ${(props) => props.half && css`
        ${media.tabletSm`
            width: 50%;
        `}
    `}
`;

const Wrapper = styled(Form)`
    width: 100%;
`;

interface IProps {
    error: boolean;
}

export const Step1: FC<React.PropsWithChildren<IProps>> = props => {
    const formikContext = useFormikContext<IFormikData>();
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);

    const changeLegalEntity = (value: IValue[]) => {
        formikContext.setFieldValue('formData.legalEntity', value?.[0]?.data || undefined);
    };

    return (
        <Wrapper>
            <FormField>
                <LegalEntitiesPicker
                    allCompanies
                    value={formikContext.values.formData.legalEntity ? [{key: formikContext.values.formData.legalEntity.id, text: formikContext.values.formData.legalEntity.name, data: formikContext.values.formData.legalEntity}] : undefined}
                    label={<FormattedMessage id="assetmanagement.wizard.step1.title" />}
                    required
                    orgId={currentOrganization?.id}
                    errorMsg={props.error ? <FormattedMessage id="assetmanagement.wizard.step1.leError" /> : undefined}
                    onSelectElement={changeLegalEntity}
                />
            </FormField>
                <Privacy field="formData.privacy" />
        </Wrapper>
    );
};
