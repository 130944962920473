import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';

import { searchDocumentElements, setCurrentVersionRealVersionId, downloadWordDocument, downloadPdfDocument, getBranchesForElement, compareFeatureToMainBranch, compareMainToMainBranch, getBranchContent, getNormsConfiguration, getRelatedBranches, getCheckStructureBetweenLanguages, getBranchWithoutContent, getOnlyBranchContent, compareBetweenFeatureBranches, compareBetweenAnyTwoBranches, setUniqueVersionId, getVersions, getTableOfContent} from '../../actions/sogeActions';
import { IBranchWithContents, IBranchCompare, ITableOfContentsVersion, ISgVersionLcid, ICompareVersion, EUserOrigin, EBranchStatus, IFullBranch, INormsConfiguration, IFeatureBranchCompare, EBranchNotificationType, ENormConfigurationType } from '../../entities/ISoge';
import { ELcid } from '../../entities/ILanguage';
import { EAlertType } from '../../entities/IAlert';
import { SgAlignTo } from '../../entities/LegalDoc/ISogeStyles';
import { EVersionStatus } from '../../entities/LegalDoc/INormativeDocumentationVersion';
import { getErrorMessage } from '../errorTools';
import { IState } from '../../reducers';
import { ISogeReducer } from '../../reducers/sogeReducer';
import { IConfigReducer } from '../../reducers/configReducer';
import { IOrganizationReducer } from '../../reducers/organizationReducer';
import { IContextReducer } from '../../reducers/contextReducer';
import { INotification } from '../../entities/INotification';
import { useAlert, useResize } from '../hooks';
import { getUniqueVersions, getSgVersionLcid } from './sogeTools';
import { instanceConfig } from '../../instance';
import { EOrganizationPermissionsBase } from '../../entities/IPermissions';

export const useBranchContent = (documentationId: string, versionId: string, documentElementCommonId: string, branchId: string, triggerChange?: boolean) => {
    const dispatch = useDispatch();
    const [branch, setBranch] = useState<IBranchWithContents>(undefined);
    const [error, setError] = useState<string>(undefined);

    const loadBranch = useCallback(() => {
        if (documentElementCommonId && documentationId && versionId && branchId) {
            setBranch(undefined);
            setError(undefined);

            return dispatch(getBranchContent(documentationId, versionId, documentElementCommonId, branchId))
                .then((branchResponse => {
                    if (branchResponse?.contents) {
                        setBranch(branchResponse);
                    } else {
                        setError('An unexpected error has occurred.');
                    }
                }))
                .catch((err) => {
                    setError(getErrorMessage(err));
                });
        }
    }, [branchId, documentationId, versionId, documentElementCommonId]);

    useEffect(() => {
        loadBranch();
    }, [loadBranch, triggerChange]);

    return {
        branch,
        updateBranch: setBranch,
        error,
        setError,
        loadBranch
    };
};

export const useOnlyBranchContent = (documentationId: string, versionId: string, documentElementCommonId: string, branchId: string) => {
    const dispatch = useDispatch();
    const [branchContent, setBranchContent] = useState<IBranchWithContents>(undefined);
    const [error, setError] = useState<string>(undefined);

    const loadOnlyBranchContent = useCallback(() => {
        if (documentElementCommonId && documentationId && versionId && branchId) {
            setBranchContent(undefined);
            setError(undefined);

            return dispatch(getOnlyBranchContent(documentationId, versionId, documentElementCommonId, branchId))
                .then((branchResponse => {
                    if (branchResponse?.contents) {
                        setBranchContent(branchResponse);
                    } else {
                        setError('An unexpected error has occurred.');
                    }
                }))
                .catch((err) => {
                    setError(getErrorMessage(err));
                });
        }
    }, [branchId, documentationId, versionId, documentElementCommonId]);

    useEffect(() => {
        loadOnlyBranchContent();
    }, [loadOnlyBranchContent]);

    return {
        branchContent,
        error,
        setError,
        loadOnlyBranchContent
    };
};

export const useBranchWithoutContent = (documentationId: string, versionId: string, branchId: string) => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const [branchProps, setBranchProps] = useState<IFullBranch>(undefined);

    const loadBranchWithoutContent = useCallback(async () => {
        if (documentationId && versionId && branchId) {
            setBranchProps(undefined);
            return dispatch(getBranchWithoutContent(documentationId, versionId, branchId))
            .then((response) => {
                setBranchProps(response);
            }).catch((err) => {
                addAlert(getErrorMessage(err), EAlertType.ERROR);
            });
        }
    }, [documentationId, versionId, branchId]);

    useEffect(() => {
        loadBranchWithoutContent();
    }, [loadBranchWithoutContent]);

    return {
        branchProps,
        loadBranchWithoutContent
    };

};

export const useBranchNotification = () => {
    const addAlert = useAlert();
    const { appConfig } = useSelector<IState, IConfigReducer>(state => state.config);
    const { token } = useSelector<IState, IContextReducer>(state => ({
        ...state.context
    }));
    const [connection, setConnection] = useState(undefined);
    const [notificationObject, setNotificationObject] = useState<INotification>(undefined);
    const [isBranchListUpdate, setIsBranchListUpdate] = useState<boolean>(false);
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(appConfig?.signalRUri, { accessTokenFactory: () => token })
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, [appConfig]);

    useEffect(() => {
        if (connection) {
            connection.start().then(result => {
                connection.on('receiveNotification', notification => {
                    if (notification?.data?.RedirectUrl && notification?.data?.IsSuccess) {
                        setNotificationObject(notification);
                    }
                    setIsBranchListUpdate(false);
                    if (notification?.data?.IsSuccess && notification?.key in EBranchNotificationType) {
                        setIsBranchListUpdate(true);
                    }
                });
            }).catch(e => {
                addAlert(getErrorMessage(e), EAlertType.ERROR);
            });
        }
    }, [connection]);

    return {
        notificationObject,
        isBranchListUpdate
    };
};

export const useTocItemNumberingStyle = () => {

    const tocItemNumberingStyle = useCallback((item: string) => {
        if (item !== null) {
            return item?.replace(/\.([^\./(\d)/$1]*)$/, '. $1')?.replace(/\-([^\-]*)$/, ' - $1');
        }
    }, []);

    return {
        tocItemNumberingStyle
    };
};

export const useElementBranches = (documentationId: string, versionId: string, documentElementCommonId: string, branchIds: string[], shouldLoad: boolean) => {
    const dispatch = useDispatch();
    const [branchesState, setBranchesState] = useState<{ allBranches: IFullBranch[], activeBranches: IFullBranch[] }>({ allBranches: undefined, activeBranches: undefined });

    useEffect(() => {
        if (versionId && documentationId && documentElementCommonId && shouldLoad) {
            dispatch(getBranchesForElement(documentationId, documentElementCommonId, versionId)).then(branchesForElement => {
                const branchesForCurrentVersion = (branchesForElement || []).filter(branch => branch.normativeDocumentationVersionId === versionId);
                const activeBranches = branchesForCurrentVersion.filter(branch => branch.status === EBranchStatus.Active);
                setBranchesState({
                    allBranches: branchesForCurrentVersion,
                    activeBranches
                });
            });
        }
    }, [versionId, documentationId, documentElementCommonId, shouldLoad]);

    const uniqueBranchesByIds = useMemo(() => {
        return (branchesState.allBranches || []).filter(branch => (branchIds || []).includes(branch.id));
    }, [branchIds, branchesState.allBranches]);

    return {
        ...branchesState,
        uniqueBranchesByIds
    };
};

export const useRelatedBranches = (documentationId: string, versionId: string, branchId: string, approvalBranchIds?: string[]) => {
    const dispatch = useDispatch();
    const [allRelatedBranches, setAllRelatedBranches] = useState<IFullBranch[]>(undefined);

    useEffect(() => {
        if (documentationId && versionId && branchId) {
            dispatch(getRelatedBranches(documentationId, versionId, branchId)).then(setAllRelatedBranches);
        }
    }, [documentationId, versionId, branchId]);

    const activeRelatedBranches = useMemo(() => {
        return (allRelatedBranches || []).filter(branch => branch.status === EBranchStatus.Active);
    }, [allRelatedBranches]);

    const currentApprovalBranches = useMemo(() => {
        return (allRelatedBranches || []).filter(branch => (approvalBranchIds || []).includes(branch.id));
    }, [approvalBranchIds, allRelatedBranches]);

    return {
        allRelatedBranches,
        activeRelatedBranches,
        currentApprovalBranches
    };
};

export const useConsistencyCheck = (documentationId: string, versionId: string, activeRelatedBranches) => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const [consistencyFullMatch, setConsistencyFullMatch] = useState<boolean>(false);
    const [unmatchedElements, setUnmatchedElelments] = useState<string[]>(undefined);
    const [isCheckEnabled, setIsCheckEnabled] = useState<boolean>(false);

    const launchConsistencyCheck = useCallback(async () => {
        const groupedBranchesIds = [];
        let errorCount = 0;
        if (activeRelatedBranches?.length > 1) {
            activeRelatedBranches?.forEach(element => {
                if (!groupedBranchesIds?.includes(element?.id)) {
                    activeRelatedBranches?.filter(currBranch => currBranch?.languageConsistencyGroupId === element?.languageConsistencyGroupId)
                    .map(el => groupedBranchesIds?.push({id: el?.id, languageConsistencyGroupId: el?.languageConsistencyGroupId}));
                }
            });
            errorCount = 0;
            for (let i = 0; i < groupedBranchesIds?.length; i += 2 ) {
                const groupedElementCommonId = activeRelatedBranches?.find(element => element?.id === groupedBranchesIds[i]?.id).relatedItemCrossId;
                if (errorCount === 0 && (groupedBranchesIds[i]?.languageConsistencyGroupId === groupedBranchesIds[i + 1]?.languageConsistencyGroupId)) {
                    setIsCheckEnabled(true);
                    await dispatch(getCheckStructureBetweenLanguages(documentationId, versionId, groupedElementCommonId, groupedBranchesIds[i]?.id, groupedBranchesIds[i + 1]?.id))
                    .then(response => {
                        if (response?.isLanguageConstencyFullMatch === true) {
                            setConsistencyFullMatch(true);
                            setUnmatchedElelments([]);
                        } else {
                            errorCount++;
                            setConsistencyFullMatch(false);
                            setUnmatchedElelments(response?.unmatchedElements);
                        }
                    }).catch(err => addAlert(getErrorMessage(err), EAlertType.ERROR));
                } else if (groupedBranchesIds[i]?.languageConsistencyGroupId !== groupedBranchesIds[i + 1]?.languageConsistencyGroupId) {
                    setIsCheckEnabled(false);
                }
            }
        } else {
            setConsistencyFullMatch(true);
        }
    }, [activeRelatedBranches, documentationId, versionId]);

    return {
        consistencyFullMatch,
        unmatchedElements,
        isCheckEnabled,
        launchConsistencyCheck
    };
};

export const useElementsSearch = (documentationId: string, versionId: string, lcid: ELcid) => {
    const dispatch = useDispatch();

    const resolveElements = useCallback(async (query: string) => {
        const response = await dispatch(searchDocumentElements(documentationId, versionId, query, lcid));
        return (response?.items || []).map(element => ({
            id: element.id || element.commonId,
            value: element.fullName,
            data: element,
            groupId: element.structureLevelRootCommonLevelId
        }));
    }, [documentationId, versionId, lcid]);

    return resolveElements;
};

export const useCurrentVersionId = (versionId: string) => {
    const history = useNavigate();
    const [realVersionId, setRealVersionId] = useState<string>(versionId === 'current' ? undefined : versionId);
    const { currentRealVersionId, documentationId, documentationLcid, allVersions } = useSelector<IState, ISogeReducer>(state => state.soge);
    const [currentVersions, setCurrentVersions] = useState<ITableOfContentsVersion[]>(undefined);
    const [currDocId, setCurrDocId] = useState<string>('');
    const [documentationCounter, setDocumentationCounter] = useState<number>(0);
    const [isGetVersion, setIsGetVersion] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (versionId === 'current') {
            if (currDocId !== documentationId) {
                setCurrDocId(documentationId);
                setCurrentVersions(undefined);
                setDocumentationCounter(documentationCounter + 1);
            }
            const currentVersionPerLanguage = allVersions && currentVersions && currentVersions?.filter(version => version?.lcid === documentationLcid);
            const currentVersion = currentVersionPerLanguage && (currentVersionPerLanguage?.find(version => version?.documentVersion?.status === EVersionStatus.Current) || currentVersionPerLanguage?.[0]);
            if (currentVersion) {
                dispatch(setUniqueVersionId(currentVersion.id));
                dispatch(setCurrentVersionRealVersionId(currentVersion.versionId));
                setRealVersionId(currentVersion.versionId);
                dispatch(getTableOfContent(documentationId, currentVersion.versionId, documentationLcid));
            }
        } else {
            setRealVersionId(versionId);
        }
    }, [versionId, currentRealVersionId, allVersions, documentationId, documentationLcid, currDocId, currentVersions, documentationCounter]);

    useEffect(() => {
        setTimeout(() => {
            if (documentationCounter === 0) {
                setDocumentationCounter(documentationCounter + 2);
            }
        }, 2000);
        documentationCounter > 1 && documentationId && dispatch(getVersions(documentationId)).then((response) => {
            setCurrentVersions(response);
            setIsGetVersion(false);
        });
    }, [documentationCounter, documentationId]);

    const onVersionChange = useCallback((callback: (version: string) => string) => {
        return (newVersionId: string, status: EVersionStatus) => {
            const version = status === EVersionStatus.Current ? 'current' : newVersionId;
            history(callback(version));
        };
    }, []);

    return {
        realVersionId: versionId === 'current' ? realVersionId : versionId,
        currentVersions,
        documentationCounter,
        setRealVersionId,
        onVersionChange
    };
};

export const useVersionName = (versionId: string, lcid: ELcid) => {
    const { allVersions } = useSelector<IState, ISogeReducer>(state => state.soge);

    const versionName = useMemo(() => {
        return allVersions?.find(version => version.versionId === versionId && version.lcid === lcid)?.name;
    }, [allVersions, versionId, lcid]);

    return versionName;
};

export const useDisplayNormsPdf = (documentationId: string, versionId: string, lcid: ELcid, url: string, subKey: string, token: string) => {
    const npmApiUrl = `${url}/normative-documentations`;
    const [currentBookPdfUrl, setCurrentBookPdfUrl] = useState<string>();
    const [isPdfReaderOpen, setIsPdfReaderOpen] = useState<boolean>(false);
    const [levelId, setLevelId] = useState<string>();
    const [bookLevelId, setBookLevelId] = useState<string>();
    const [bookTitle, setBookTitle] = useState<string>();

    const openPdfReader = useCallback((commonLevelId, bookCommonLevelId, title?: string) => {
        setLevelId(commonLevelId);
        setBookLevelId(bookCommonLevelId);
        setBookTitle(title);
        setCurrentBookPdfUrl(`${npmApiUrl}/${documentationId}/versions/${versionId}/document-elements/${commonLevelId}/contents/pdf-document?lcid=${lcid}&structureLevelRootCommonLevelId=${bookCommonLevelId}&access_token=${token}&subscription-key=${subKey}`);
        setIsPdfReaderOpen(true);
    }, [documentationId, versionId, lcid]);

    const closePdfReader = useCallback(() => {
        setIsPdfReaderOpen(false);
    }, []);

    return {
        currentBookPdfUrl,
        isPdfReaderOpen,
        levelId,
        bookLevelId,
        bookTitle,
        openPdfReader,
        closePdfReader
    };
};

export const useStructureDownload = (documentationId: string, versionId: string, lcid: ELcid, context: 'consultation' | 'management') => {
    const { pageContext: { user } } = useSelector<IState, IContextReducer>(state => state.context);
    const [currentDownloadingElementId, setCurrentDownloadingElementId] = useState<string>(undefined);
    const [currentDownloadingPdfElementId, setCurrentDownloadingPdfElementId] = useState<string>(undefined);
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const versionName = useVersionName(versionId, lcid);

    const downloadConsultationPdfDocument = useCallback(async (levelId: string, structureLevelRootCommonLevelId: string, levelName: string, currLcid?: ELcid) => {
        if (instanceConfig?.sg?.blockDownload && user.userOrigin !== EUserOrigin.LAN) {
            return;
        }

        setCurrentDownloadingPdfElementId(levelId);
        try {
            await dispatch(downloadPdfDocument(documentationId, versionId, levelId, structureLevelRootCommonLevelId, currLcid !== undefined ? currLcid : lcid, undefined, `${levelName}.pdf`, context, versionName));
        } catch (error) {
            addAlert(getErrorMessage(error), EAlertType.ERROR);
        }
        setCurrentDownloadingPdfElementId(undefined);
    }, [documentationId, versionId, lcid, user.userOrigin, versionName, context]);

    const downloadStructureLevel = useCallback(async (levelId: string, structureLevelRootCommonLevelId: string, levelName: string) => {
        if (instanceConfig?.sg?.blockDownload && user.userOrigin !== EUserOrigin.LAN) {
            return;
        }

        setCurrentDownloadingElementId(levelId);
        try {
            await dispatch(downloadWordDocument(documentationId, versionId, levelId, structureLevelRootCommonLevelId, lcid, undefined, `${levelName}.docx`, context, versionName));
        } catch (error) {
            addAlert(getErrorMessage(error), EAlertType.ERROR);
        }
        setCurrentDownloadingElementId(undefined);
    }, [documentationId, versionId, lcid, user.userOrigin, versionName, context]);

    return {
        downloadStructureLevel,
        downloadConsultationPdfDocument,
        currentDownloadingElementId,
        currentDownloadingPdfElementId
    };
};

interface ICopyUrlState {
    copyUrlVersionName: string;
    copyUrlTargetCommonLevelId: string;
    copyUrlArticleCommonLevelId: string;
}

export const useCopyUrl = () => {
    const [copyUrlState, setCopyUrlState] = useState<ICopyUrlState>({
        copyUrlVersionName: undefined,
        copyUrlTargetCommonLevelId: undefined,
        copyUrlArticleCommonLevelId: undefined
    });

    const onCopyUrlClick = useCallback((documentVersionName: string, commonLevelId: string, articleCommonLevelId?: string) => {
        setCopyUrlState({
            copyUrlVersionName: documentVersionName,
            copyUrlTargetCommonLevelId: commonLevelId,
            copyUrlArticleCommonLevelId: articleCommonLevelId
        });
    }, []);

    const closeCopyUrl = useCallback(() => setCopyUrlState({
        copyUrlVersionName: undefined,
        copyUrlTargetCommonLevelId: undefined,
        copyUrlArticleCommonLevelId: undefined
    }), []);

    return {
        ...copyUrlState,
        onCopyUrlClick,
        closeCopyUrl
    };
};

export const useSgContentAlignment = <T extends HTMLElement>(alignMargin: number = 0) => {
    const wrapperRef = useRef<T>();
    const [alignTo, setAlignTo] = useState<SgAlignTo>(undefined);
    const { innerWidth } = useResize();

    useEffect(() => {
        const boundRect = wrapperRef.current?.getBoundingClientRect();
        const offsetWidth = wrapperRef.current?.offsetWidth;

        if (boundRect && offsetWidth) {
            const rightOffset = boundRect?.left + offsetWidth + alignMargin;
            const align: SgAlignTo = rightOffset > window.innerWidth ? 'right' : 'left';
            setAlignTo(align);
        }
    }, [innerWidth]);

    return {
        wrapperRef,
        alignTo,
        innerWidth
    };
};

interface IBranchComparisonState {
    branchComparison: IBranchCompare;
    isLoading: boolean;
}

interface IVersionsState {
    uniqueVersions: ITableOfContentsVersion[];
    versionsLcid: ISgVersionLcid;
}

interface ICompareParams {
    currentElementCommonId: string;
    currentLcid: ELcid;
    currentVersionId: string;
    sourceRootCommonId?: string;
    targetRootCommonId?: string;
    featureBranchesResponse?: IFeatureBranchCompare;
}

export const useSogeFeatureBranchCompare = (documentationId: string, versionId: string, branchId: string) => {
    const dispatch = useDispatch();
    const [featureBranchesResponse, setFeatureBranchesReponse] = useState<IFeatureBranchCompare>();

    useEffect(() => {
        if (documentationId && versionId && branchId) {
            dispatch(compareBetweenFeatureBranches(documentationId, versionId, branchId))?.then((response) => {
                setFeatureBranchesReponse(response);
            });
        }
    }, [documentationId, versionId, branchId]);

    return {
        featureBranchesResponse,
        setFeatureBranchesReponse
    };
};

type CompareType = 'main' | 'feature';

export const useSogeCompare = (compareType: CompareType, orgUrlName: string, documentationId: string, params: ICompareParams, editMode?: boolean, setError?: (msg: string) => void) => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const { organizationPermissions, contextOrganizations } = useSelector<IState, IOrganizationReducer & IContextReducer>(state => ({
        ...state.soge,
        ...state.organization,
        ...state.context
    }));
    const [comparisonState, setComparisonState] = useState<IBranchComparisonState>({ branchComparison: undefined, isLoading: false });
    const [versionsState, setVersionsState] = useState<IVersionsState>({ uniqueVersions: [], versionsLcid: {} });
    const [comparedBranchVersion, setComparedBranchVersion] = useState<ICompareVersion>(undefined);
    const [highlightChanges, setHighlightChanges] = useState<boolean>(false);
    const [sideBySide, setSideBySide] = useState<boolean>(false);

    const currentOrg = contextOrganizations.byUrl[orgUrlName];
    const managePermission = organizationPermissions?.[currentOrg?.id]?.[EOrganizationPermissionsBase.ManageOrganization];

    const setVersions = useCallback((versions: ITableOfContentsVersion[]) => {
        const uniquerVersions = getUniqueVersions(versions);

        setVersionsState({
            uniqueVersions: editMode
                ? uniquerVersions
                : uniquerVersions.filter(version => version.documentVersion?.status === EVersionStatus.Current || version.documentVersion?.status === EVersionStatus.Archived || (managePermission && version.documentVersion?.status === EVersionStatus.New)),
            versionsLcid: getSgVersionLcid(versions)
        });
    }, [editMode]);

    useEffect(() => {
        const isVersionToCompare: boolean = !!(comparedBranchVersion?.language && comparedBranchVersion?.versionId);

        setComparisonState({ branchComparison: undefined, isLoading: isVersionToCompare });
        setError?.(undefined);

        if (isVersionToCompare) {
            const request = compareType === 'main'
                ? dispatch(compareMainToMainBranch(documentationId, params.currentVersionId, params.sourceRootCommonId, comparedBranchVersion?.versionId, params.targetRootCommonId, params.currentLcid, comparedBranchVersion?.language, params.currentElementCommonId))
                : !comparedBranchVersion?.featureBranchId ? dispatch(compareFeatureToMainBranch(documentationId, params.currentVersionId, params.currentElementCommonId, comparedBranchVersion?.versionId, params.currentLcid, comparedBranchVersion?.language))
                : dispatch (compareBetweenAnyTwoBranches(documentationId, params.currentVersionId, params.currentElementCommonId, params?.featureBranchesResponse?.originalBranch?.id, comparedBranchVersion?.featureBranchId));
                request
                .then(comparison => {
                    setComparisonState({ branchComparison: comparison, isLoading: false });
                })
                .catch(compareBranchError => {
                    setError ? setError(getErrorMessage(compareBranchError)) : addAlert(getErrorMessage(compareBranchError), EAlertType.ERROR);
                    setComparisonState({ branchComparison: undefined, isLoading: false });
                });
        }
    }, [
        compareType,
        comparedBranchVersion?.versionId,
        comparedBranchVersion?.language,
        comparedBranchVersion?.versionName,
        params.currentElementCommonId,
        params.currentLcid,
        params.currentVersionId,
        params.sourceRootCommonId,
        params.targetRootCommonId,
        setError
    ]);

    const onChangeCompareBranchVersion = useCallback((targetLanguage: ELcid, targetVersionId: string, targetVersionName: string, featureBranchId?: string) => {
        setComparedBranchVersion({
            versionId: targetVersionId,
            language: targetLanguage,
            versionName: targetVersionName,
            featureBranchId: featureBranchId
        });
    }, [versionsState.uniqueVersions, documentationId, params.currentVersionId, params.currentElementCommonId, params.currentLcid, params.sourceRootCommonId, params.targetRootCommonId]);

    const toggleHighlightChanges = useCallback(() => {
        setHighlightChanges(highlight => !highlight);
    }, []);

    const toggleSideBySide = useCallback(() => {
        setSideBySide(isSideBySide => !isSideBySide);
    }, []);

    return {
        comparisonState,
        comparedBranchVersion,
        highlightChanges,
        versionsState,
        sideBySide,
        setVersions,
        onChangeCompareBranchVersion,
        toggleHighlightChanges,
        toggleSideBySide
    };
};

export const useLatestVersionId = (isGetNewVersions?: boolean) => {
    const { allVersions, documentationId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const [currentAllVersions, setCurrentAllVersions] = useState<ITableOfContentsVersion[]>(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isGetNewVersions) {
            dispatch(getVersions(documentationId))?.then((response) => {
                setCurrentAllVersions(response);
            });
        }
    }, []);

    const latestVersionId = useMemo<string>(() => {
        if (allVersions?.length && currentAllVersions?.length) {
            const latestVersionByStatus = [...(currentAllVersions || [])].sort((a, b) => a.documentVersion.status - b.documentVersion.status)?.[0];

            if (latestVersionByStatus && latestVersionByStatus.documentVersion.status !== EVersionStatus.Archived) {
                return latestVersionByStatus.versionId;
            }
        } else {
            return undefined;
        }
    }, [allVersions, documentationId, currentAllVersions]);

    return {
        latestVersionId,
        currentAllVersions
    };
};

export const useNormsConfiguration = () => {
    const dispatch = useDispatch();
    const { documentationId, normsConfiguration, normsConfigurationRequested } = useSelector<IState, ISogeReducer>(state => state.soge);

    useEffect(() => {
        if (documentationId && !normsConfiguration && !normsConfigurationRequested) {
            dispatch(getNormsConfiguration(documentationId));
        }
    }, [documentationId, normsConfiguration, normsConfigurationRequested]);

    return normsConfiguration || ({} as INormsConfiguration);
};

export const useNormVersionLanguageConfig = (versionId: string, documentationLcid: number) => {
    const dispatch = useDispatch();
    const { documentationId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const [isDatabaseOnlyState, setIsDatabaseOnlyState] = useState<boolean>(false);
    const [isPdfOnlyState, setIsPdfOnlyState] = useState<boolean>(false);
    const [isHybridState, setIsHybridState] = useState<boolean>(false);
    const [currVersionLanguageConfig, setCurrVersionLanguageConfig] = useState<number[]>();
    const { formats } = useNormsConfiguration();
    const currentNormObjectId = useMemo(() => {
        if (formats?.normVersionLanguageConfig && versionId) {
            return Object?.keys(formats?.normVersionLanguageConfig)?.filter((el) => el === versionId);
        }
    }, [formats?.normVersionLanguageConfig, versionId]);
    useEffect(() => {
        if (formats?.normVersionLanguageConfig !== undefined && !!versionId && versionId !== 'undefined') {
            let currentObjectId = currentNormObjectId;
            if (currentObjectId?.length === 0) {
                dispatch(getNormsConfiguration(documentationId)).then(() => {
                    currentObjectId = currentNormObjectId;
                });
            } else if (currentObjectId.length > 0) {
                setCurrVersionLanguageConfig(formats?.normVersionLanguageConfig[currentObjectId[0]]);
                const currentIndexOfObject = Object.keys(formats?.normVersionLanguageConfig).map(el => el).indexOf(String(currentObjectId));
                const currentObjectValues = Object.values(formats?.normVersionLanguageConfig)[currentIndexOfObject];
                if (currentObjectValues !== undefined && documentationLcid !== undefined) {
                    currentObjectValues[documentationLcid] === ENormConfigurationType.Database ? setIsDatabaseOnlyState(true) : setIsDatabaseOnlyState(false);
                    currentObjectValues[documentationLcid] === ENormConfigurationType.PdfOnly ? setIsPdfOnlyState(true) : setIsPdfOnlyState(false);
                    currentObjectValues[documentationLcid] === ENormConfigurationType.Hybrid ? setIsHybridState(true) : setIsHybridState(false);
                }
            }
        }
    }, [formats?.normVersionLanguageConfig, versionId, documentationLcid]);

    return {
        isDatabaseOnlyState,
        isPdfOnlyState,
        isHybridState,
        currVersionLanguageConfig
    };
};

const mockBookColors = [
    '#010035',
    '#C6361B',
    '#E9041E',
    '#E55F50',
    '#EE3B45',
    '#250708',
    '#002B2F',
    '#ffffff'
];

const mockBookImages = [
    require('../../../wwwroot/assets/images/legaldoc/book/0.jpg'), // tslint:disable-line
    require('../../../wwwroot/assets/images/legaldoc/book/1.jpg'), // tslint:disable-line
    require('../../../wwwroot/assets/images/legaldoc/book/2.jpg'), // tslint:disable-line
    require('../../../wwwroot/assets/images/legaldoc/book/3.jpg'), // tslint:disable-line
    require('../../../wwwroot/assets/images/legaldoc/book/4.jpg'), // tslint:disable-line
    require('../../../wwwroot/assets/images/legaldoc/book/5.jpg'), // tslint:disable-line
    require('../../../wwwroot/assets/images/legaldoc/book/6.jpg'), // tslint:disable-line
    require('../../../wwwroot/assets/images/legaldoc/book/7.jpg') // tslint:disable-line
];

export const useMockBookCover = () => {
    const getColor = useCallback((index: number) => {
        return mockBookColors[index % mockBookColors.length];
    }, [mockBookColors]);

    const getImage = useCallback((index: number) => {
        return mockBookImages[index % mockBookImages.length];
    }, [mockBookImages]);

    return { getColor, getImage };
};
