import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ECompanyGroupKey } from '../../../entities/ICompany';
import { IPickerProps, CorePicker, EButtonsTypes } from './CorePicker';
import { getFilteredCompanies } from '../../../actions/adminActions';
import { getUserProfileByQuery } from '../../../actions/profileActions';
import { EProfileType } from '../../../entities/IGlobal';
import { getAllLegalEntities } from '../../../actions/legalEntitiesActions';
import { IValue } from '../../../entities/IPickers';

type GetFilteredCompanies = ReturnType<typeof getFilteredCompanies>;
type GetUserProfileByQuery = ReturnType<typeof getUserProfileByQuery>;
type GetAllLegalEntities = ReturnType<typeof getAllLegalEntities>;

type IProps = IPickerProps & {
    orgId?: string;
    filteredCompanies?: boolean;
    allCompanies?: boolean;
};

const Wrapper = styled.div``;

export const UsersAndLegalEntitiesPicker: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const getData = useCallback(async (query: string): Promise<IValue[]> => {
        // tslint:disable-next-line: no-null-keyword
        let LEResult = new Array();
        // tslint:disable-next-line: no-null-keyword
        let UsersResult = new Array();
        const body = {
            search: query,
            hideCurrentUser: false
        };
        UsersResult = await dispatch<GetUserProfileByQuery>(getUserProfileByQuery(body, 0, 100)).then(res => {
            return res.items.map(person => ({
                key: person.id,
                data: { ...person, type: EProfileType.Personal },
                text: `${person.firstName} ${person.lastName}`
            }));
        });
        if (props.filteredCompanies) {
            await dispatch<GetFilteredCompanies>(getFilteredCompanies(query, 100, ECompanyGroupKey.OurGroup)).then(data => {
                LEResult = [...LEResult, ...(data || []).map(entity => ({
                    key: entity.id,
                    text: entity.name,
                    data: { ...entity, type: EProfileType.Company }
                }))];
            });
        }
        if (props.allCompanies) {
            await dispatch<GetAllLegalEntities>(getAllLegalEntities(query, undefined, 100)).then(res => {
                LEResult = [...LEResult, ...res.items.map(elem => ({
                    key: elem.id,
                    data: { ...elem, type: EProfileType.Company },
                    text: elem.name
                }))];
            });
        }
        return [...LEResult, ...UsersResult];
    }, [props.orgId]);

    return (
        <Wrapper>
            <CorePicker {...props} oldVersion getData={getData} buttonsType={props.buttonsType || EButtonsTypes.All} />
        </Wrapper>
    );
};
