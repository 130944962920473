import { useEffect } from 'react';

export enum EKeyCode {
    Backspace = 8,
    Left = 37,
    Up = 38,
    Right = 39,
    Down = 40,
    Escape = 27,
    Enter = 13,
    Shift = 16
}

export const useEscKey = (callback?: Function) => {
    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            const key = e.which || e.keyCode;
            key === EKeyCode.Escape && typeof callback === 'function' && callback?.();
        };
        document.addEventListener('keydown', close);
        return () => document.removeEventListener('keydown', close);
    }, [callback]);
};
