import React from 'react';
import { Icon } from '../../../entities/IGlobal';

export const Entities = (props: Icon) => (
    <svg width={props.width} height={props.height} {...props} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 440.747 440.747">
        <g>
            <g>
                <path d="M394.24,419.413h-7.893v-183.04c0-5.891-4.776-10.667-10.667-10.667h-28.373v-13.44c0-5.891-4.776-10.667-10.667-10.667
   c-5.891,0-10.667,4.776-10.667,10.667v13.44h-18.347V65.92c0-5.891-4.776-10.667-10.667-10.667h-32.853v-26.88
   c0-5.891-4.776-10.667-10.667-10.667h-22.4v-7.04C231.04,4.776,226.264,0,220.373,0s-10.667,4.776-10.667,10.667v7.68h-21.333
   c-5.65-0.01-10.328,4.387-10.667,10.027v26.88h-32.853c-5.891,0-10.667,4.776-10.667,10.667v159.787h-17.92v-13.44
   c0-5.891-4.776-10.667-10.667-10.667s-10.667,4.776-10.667,10.667v13.44h-30.08c-5.891,0-10.667,4.776-10.667,10.667v183.04h-7.68
   c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667H394.24c5.891,0,10.667-4.776,10.667-10.667
   C404.907,424.189,400.131,419.413,394.24,419.413z M135.04,419.413H75.52V247.04h59.52V419.413z M199.04,39.68h42.667v15.573
   H199.04V39.68z M287.147,419.413h-53.333V404.48c0-5.891-4.776-10.667-10.667-10.667h-4.267c-5.891,0-10.667,4.776-10.667,10.667
   v14.933h-51.84v-183.04V76.587h130.773V419.413z M366.08,419.413h-50.773h-6.827V247.04h57.6V419.413z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M188.8,92.373h-4.48c-5.891-0.045-10.703,4.694-10.748,10.584c-0.027,3.515,1.679,6.817,4.562,8.829V357.76
   c-4.831,3.372-6.014,10.021-2.642,14.852c2.012,2.882,5.314,4.589,8.829,4.562h4.48c5.891,0,10.667-4.776,10.667-10.667V102.4
   C199.128,96.76,194.45,92.363,188.8,92.373z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M223.147,91.733h-4.267c-5.891,0-10.667,4.776-10.667,10.667c0,3.357,1.581,6.519,4.267,8.533v247.04
   c-2.627,1.969-4.201,5.038-4.267,8.32c0,5.891,4.776,10.667,10.667,10.667h4.267c5.891,0,10.667-4.776,10.667-10.667V102.4
   C233.813,96.509,229.038,91.733,223.147,91.733z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M257.493,91.733h-4.053c-5.891,0.034-10.639,4.837-10.605,10.728c0.018,3.214,1.485,6.248,3.992,8.259v247.253
   c-2.547,2.012-4.038,5.074-4.053,8.32c0,5.891,4.776,10.667,10.667,10.667h4.053c5.891,0,10.667-4.776,10.667-10.667V102.4
   C268.16,96.509,263.384,91.733,257.493,91.733z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

);