import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';

const Wrapper = styled.div`
    margin: 2rem 0 0 0;
`;

const Title = styled.div`
    color: ${colorStack.darkBlue};
    margin: 0 0 2rem 0;
    sup {
        color: ${colorStack.red};
    }
`;

const Info = styled.div`
    background: ${colorStack.whiteBlue};
    border-radius: 4px;
    border: 1px solid ${colorStack.middleBlue};
    color: ${colorStack.darkBlue};
    padding: .5rem 1rem;
    width: 100%;
    font-size: ${fontSize[13]};
    svg {
        color: ${colorStack.middleBlue};
        margin: 0 1rem 0 0;
    }
`;

const GrayInfo = styled.div`
    background: ${colorStack.ligthGrey};
    border-radius: 4px;
    border: 1px solid ${colorStack.middleGrey};
    color: ${colorStack.darkBlue};
    padding: 1.5rem 2rem;
    width: 100%;
    font-size: ${fontSize[10]};
    margin-top: 25px;
`;

interface IProps {
    topTextId?: string;
    blueTextId?: string;
    greyTextId?: string
}

export const RequiredInformation: FC<React.PropsWithChildren<IProps>> = props => {
    const intl = useIntl();

    return (
        <Wrapper>
            {props.topTextId &&
                <Title>
                    <MandatoryFieldStar />
                    <FormattedMessage id={props.topTextId} />
                </Title>
            }
            {(props.blueTextId && intl.messages[props.blueTextId] !== '') &&
                <Info>
                    <FontAwesomeIcon icon={faExclamation} />
                    <span dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: props.blueTextId })
                    }} />
                </Info>
            }
            {props.greyTextId &&
                <GrayInfo>
                    <FormattedMessage id={props.greyTextId} />
                </GrayInfo>
            }
        </Wrapper>
    );
};