import queryString from 'query-string-for-all';

import { ELcid } from '../../entities/ILanguage';
import { ITableOfContentsVersion, ISgVersionLcid, IStructure, IStructureResponse, ITableOfContentsNode, ITableOfContentsNodeWithBook, ISogeParent, IFacets } from '../../entities/ISoge';
import { removeDuplicatesByKey } from '../arrayTools';
import { EAmendmentStep } from '../../components/Soge/Amendments/Steps/ISteps';

const BooksIcon = require('../../../wwwroot/assets/images/svgicons/books-color.svg');// tslint:disable-line
const BookClosedIcon = require('../../../wwwroot/assets/images/svgicons/book-closed-color.svg');// tslint:disable-line
const ChapterIcon = require('../../../wwwroot/assets/images/svgicons/chapter.svg');// tslint:disable-line
const SectionIcon = require('../../../wwwroot/assets/images/svgicons/section.svg');// tslint:disable-line

const sogeTocIconMap = {
    'LIVRE': BooksIcon,
    'TITRE': BookClosedIcon,
    'CHAPITRE': ChapterIcon,
    'SECTION': SectionIcon,
    'PARAGRAPHE': SectionIcon,
    'SOUS-SECTION': SectionIcon,
    'SOUS-PARAGRAPHE': SectionIcon
};

export const sogeTocIcon = (key: string): string => sogeTocIconMap[key] || SectionIcon;

export const getSogeDocUrl = (orgUrl: string, id: string, versionId?: string, lcid?: ELcid) => `/orgs/${orgUrl}/documentation/${[id, versionId].filter(part => !!part).join('/')}${lcid ? `?lcid=${lcid}` : window.location.search}`;
export const getSogeArticleUrl = (orgUrl: string, id: string, structureId: string, versionId: string, lcid?: ELcid) => `/orgs/${orgUrl}/documentation/${structureId || 'structure-level'}/${versionId}/article/${id}${lcid ? `?lcid=${lcid}` : ''}`;
export const getSogeCollectionsUrl = (orgUrl: string, versionId?: string) => `/orgs/${orgUrl}/documentation/collections/${versionId || ''}`;
export const getSogeTextsUrl = (orgUrl: string, versionId?: string) => `/orgs/${orgUrl}/documentation/texts/${versionId || ''}`;
export const getSogeSingleCollectionUrl = (orgUrl: string, versionId: string, commonLevelId: string) => `/orgs/${orgUrl}/documentation/collections/${versionId}/${commonLevelId}/texts`;
export const getSogeVersionUrl = (orgUrl: string, versionId: string, structureId?: string, articleId?: string) => `/orgs/${orgUrl}/documentation/versions/${versionId}/structure/${[structureId, articleId].filter(part => !!part).join('/')}`;
export const getSogeSearchUrl = (orgUrl: string, versionId?: string, query?: string) => `/orgs/${orgUrl}/documentation/search/${versionId || ''}${query ? `?${query}` : ''}`;
export const getSogePdfOrWordFullUrl = (legalDocApiUrl: string, normativeDocumentationId: string, versionId: string, elementId: string, structureLevelRootCommonLevelId: string, lcid: ELcid, branchId: string, documentType: 'pdf' | 'word'): string => {
    const query = queryString.stringify({ lcid, structureLevelRootCommonLevelId, branchId });

    return `${legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/document-elements/${elementId}/contents/${documentType}-document?${query}`;
};

export const getBranchUrl = (orgUrl: string, versionId: string, documentElementCommonId: string, branchId: string) => `/orgs/${orgUrl}/documentation/versions/${versionId}/branches/${documentElementCommonId}/${branchId}`;
export const getSogeCreateApprovalUrl = (orgUrl: string, versionId: string, documentElementCommonId: string) => `/orgs/${orgUrl}/documentation/versions/${versionId}/branches/${documentElementCommonId}/request-approval`;
export const getSogeApprovalUrl = (orgUrl: string, versionId: string, documentElementCommonId: string, requestId: string) => `/orgs/${orgUrl}/documentation/versions/${versionId}/branches/${documentElementCommonId}/approve/${requestId}`;
export const getSogeAmendmentUrl = (orgUrl: string, step?: EAmendmentStep, queryObject?: Record<string, any>, amendmentId?: string) => {
    const query: string = queryObject && `?${queryString.stringify(queryObject)}`;
    const stepUrl = step && `/${step}`;
    const amendmentUrl = amendmentId && `/${amendmentId}`;
    return `/orgs/${orgUrl}/documentation/versions/new-amendement${stepUrl || ''}${amendmentUrl || ''}${query || ''}`;
};
export const getNormsManagementUrl = (orgUrl: string) => `/orgs/${orgUrl}/documentation/norms-management`;
export const getNormsAuditTrailUrl = (orgUrl: string, versionId: string, documentElementCommonId: string, branchId: string) => `/orgs/${orgUrl}/documentation/versions/${versionId}/branches/${documentElementCommonId}/${branchId}/audit-trail`;

export const getStyleKey = (id: string, name: string) => `${id}-${name}`;

export const getSgVersionLcid = (versions: ITableOfContentsVersion[]): ISgVersionLcid => {
    return (versions || []).reduce((reducedVersions, version) => ({
        ...reducedVersions,
        [version.versionId]: {
            availableLcid: [
                ...(reducedVersions[version.versionId]?.availableLcid || []),
                version.lcid
            ]
        }
    }), {});
};

export const getUniqueVersions = (versions: ITableOfContentsVersion[]): ITableOfContentsVersion[] => {
    return versions.reduce((reducedVersions, version) => reducedVersions.find(ver => ver.versionId === version.versionId) ? reducedVersions : [...reducedVersions, version], []);
};

export const getElementByPath = (idPath: string[], structureLevels: IStructureResponse | IStructure): IStructure => {
    const getElement = (structure: IStructure[], level = 0, commonIdParents: string[] = []): IStructure => {
        const element = (structure || []).find(structureLevel => (structureLevel.commonLevelId === idPath[level] || structureLevel.id === idPath[level]));

        if (element && level < idPath.length - 1) {
            return getElement(element.children, level + 1, [...commonIdParents, element.commonLevelId]);
        } else {
            return {
                ...element,
                commonIdParents: [...commonIdParents]
            };
        }
    };

    return getElement([structureLevels as IStructure]);
};

export const getFlattenTextsFromToc = (toc: ITableOfContentsNode[]): ITableOfContentsNodeWithBook[] => {
    const flatList: ITableOfContentsNodeWithBook[] = [];
    const parse = (nodes: ITableOfContentsNode[], parent?: ISogeParent) => {
        nodes.forEach(node => {
            const book = parent || {
                commonLevelId: node.commonLevelId,
                fullName: node.name
            };

            if (node.hasReferenceText) {
                flatList.push(Object.assign({}, node, { book }));
            }

            if (node?.children?.length) {
                parse(node.children, book);
            }
        });
    };
    parse(toc || []);
    return flatList;
};

export const mergeFacets = (facetsA: IFacets = {}, facetsB: IFacets = {}): IFacets => {
    const allFacetsKeys = [...Object.keys(facetsA), ...Object.keys(facetsB)];

    const allFacets: IFacets = allFacetsKeys.reduce((facets, facetKey) => ({
        ...facets,
        [facetKey]: removeDuplicatesByKey([
            ...(facetsA[facetKey] || []),
            ...(facetsB[facetKey] || [])
        ], item => item?.id)?.sort((a, b) => a?.name?.localeCompare(b?.name))
    }), {});

    return allFacets;
};

export const documentationMetadataLabels = {
    IndividualThematics: { id: 'norms.search.filter.individualThematic' },
    IssuingDirections: { id: 'norms.search.filter.issuing' },
    MacroProcessuses: { id: 'norms.search.filter.macro' },
    Regulations: { id: 'norms.search.filter.applicableRegulations' },
    ScopeOfApplications: { id: 'norms.search.filter.scopeOfApplication' },
    SubThematics: { id: 'norms.search.filter.subThematic' },
    Thematics: { id: 'norms.search.filter.thematic' }
};

export const SIDEBAR_WIDTH = 50;
