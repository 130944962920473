interface AttachmentObject {
    isValid: boolean;
    message: string;
}

type Area = 'rfp' |'messaging' | 'cle' | 'cluster' | 'company' | 'profile' | 'publication' | 'docusign';

const attachmentRestriction = {
    rfp: {
        // 50 MB
        size: 52428800,
        files: ['pdf', 'msg', 'doc', 'docm', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'fdop', 'ods', 'fods', 'odp', 'fodp', 'jpeg', 'jpg', 'png', 'txt', 'rtf', 'csv'],
        mimeType: [
            'application/pdf',
            'application/msword',
            'application/vnd.ms-word.document.macroenabled.12',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.presentation',
            'image/jpeg',
            'image/png',
            'text/plain',
            'application/rtf',
            'text/csv',
            'application/vnd.ms-outlook'
        ],
        sizeErrorMessage: 'The attachment size exceeds the 50MB limit. You can use Legaldrive to share files bigger than 50MB.',
        typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: pdf, msg, doc, docm, docx, xls, xlsx, ppt, pptx, odt, fdop, ods, fods, odp, fodp, jpeg, jpg, png, txt, rtf, csv'
    },
    messaging: {
         // 25 MB
        size: 26214400,
        files: ['pdf', 'msg', 'doc', 'docm', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'fdop', 'ods', 'fods', 'odp', 'fodp', 'jpeg', 'jpg', 'png', 'txt', 'rtf', 'csv'],
        mimeType: ['pdf', 'doc', 'docm', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'fdop', 'ods', 'fods', 'odp', 'fodp', 'jpeg', 'jpg', 'png', 'txt', 'rtf', 'csv'],
        sizeErrorMessage: 'The attachment size exceeds the 25MB limit. You can use Legaldrive to share files bigger than 25MB.',
        typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: pdf, doc, docm, msg, docx, xls, xlsx, ppt, pptx, odt, fdop, ods, fods, odp, fodp, jpeg, jpg, png, txt, rtf, csv'
    },
    cle: {
         // 100 MB
        size: 104857600,
        files: ['gif', 'pdf', 'doc', 'docm', 'msg', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'fdop', 'ods', 'fods', 'odp', 'fodp', 'jpeg', 'jpg', 'png', 'txt', 'rtf', 'csv'],
        mimeType: ['gif', 'pdf', 'doc', 'docm', 'msg', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'fdop', 'ods', 'fods', 'odp', 'fodp', 'jpeg', 'jpg', 'png', 'txt', 'rtf', 'csv'],
        sizeErrorMessage: 'The attachment size exceeds the 25MB limit. You can use Legaldrive to share files bigger than 25MB.',
        typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: pdf, gif, msg, doc, docm, docx, xls, xlsx, ppt, pptx, odt, fdop, ods, fods, odp, fodp, jpeg, jpg, png, txt, rtf, csv'
    },
    cluster: {
        // 25 MB
       size: 26214400,
       files: ['gif', 'pdf', 'doc', 'docm', 'msg', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'fdop', 'ods', 'fods', 'odp', 'fodp', 'jpeg', 'jpg', 'png', 'txt', 'rtf', 'csv'],
       mimeType: ['gif', 'pdf', 'doc', 'docm', 'msg', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'fdop', 'ods', 'fods', 'odp', 'fodp', 'jpeg', 'jpg', 'png', 'txt', 'rtf', 'csv'],
       sizeErrorMessage: 'The attachment size exceeds the 25MB limit. You can use Legaldrive to share files bigger than 25MB.',
       typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: pdf, gif, doc, docm, msg, docx, xls, xlsx, ppt, pptx, odt, fdop, ods, fods, odp, fodp, jpeg, jpg, png, txt, rtf, csv'
    },
    company: {
        // 10 MB
        size: 10485760,
        files: ['jpeg', 'jpg', 'png', 'gif'],
        mimeType: [
            'image/jpeg',
            'image/png',
            'image/gif'
        ],
        sizeErrorMessage: 'The attachment size exceeds the 10MB limit. You can use Legaldrive to share files bigger than 10MB.',
        typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: jpeg, jpg, png, gif'
    },
    profile: {
        // 10 MB
        size: 10485760,
        files: ['jpeg', 'jpg', 'png', 'gif'],
        mimeType: [
            'image/jpeg',
            'image/png',
            'image/gif'
        ],
        sizeErrorMessage: 'The attachment size exceeds the 10MB limit. You can use Legaldrive to share files bigger than 10MB.',
        typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: jpeg, jpg, png, gif'
    },
    publication: {
        // 10 MB
        size: 10485760,
        files: ['jpeg', 'jpg', 'png', 'gif'],
        mimeType: [
            'image/jpeg',
            'image/png',
            'image/gif'
        ],
        sizeErrorMessage: 'The attachment size exceeds the 10MB limit. You can use Legaldrive to share files bigger than 10MB.',
        typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: jpeg, jpg, png, gif'
    },
    docusign: {
        // 5 MB
        size: 5242880,
        files: ['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'htm', 'html', 'msg', 'pdf', 'rtf', 'txt', 'wpd', 'xps', 'bmp', 'gif', 'jpg', 'jpeg', 'png', 'tif', 'tiff', 'pot', 'potx', 'pps', 'ppt', 'pptm', 'pptx', 'csv', 'xls', 'xlsm', 'xlsx'],
        mimeType: ['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'htm', 'html', 'msg', 'pdf', 'rtf', 'txt', 'wpd', 'xps', 'bmp', 'gif', 'jpg', 'jpeg', 'png', 'tif', 'tiff', 'pot', 'potx', 'pps', 'ppt', 'pptm', 'pptx', 'csv', 'xls', 'xlsm', 'xlsx'],
        sizeErrorMessage: 'Warning: For best performances, Docusign recommends restricting your file uploads to no more than 5 MB',
        typeErrorMessage: 'Your file cannot be uploaded. Only files with the following extensions are allowed: doc, docm, docx, dot, dotm, dotx, htm, html, msg, pdf, rtf, txt, wpd, xps, bmp, gif, jpg, jpeg, png, tif, tiff, pot, potx, pps, ppt, pptm, pptx, csv, xls, xlsm, xlsx'
    }
};

export const checkValidAttachment = (type: string, size: number, area: Area) => {
    const selectedArea = attachmentRestriction[area];
    return selectedArea.size > size && selectedArea.mimeType.includes(type);
};

export const checkAttachmentSize = (size: number, area: Area) => {
    const selectedArea = attachmentRestriction[area];
    if (selectedArea.size > size) {
        return {
            isValid: true,
            message: ''
        } as AttachmentObject;
    } else {
        return {
            isValid: false,
            message: selectedArea.sizeErrorMessage
        } as AttachmentObject;
    }
};

export const checkAttachmentType = (type: string, area: Area) => {
    const selectedArea = attachmentRestriction[area];
    if (selectedArea.mimeType.includes(type)) {
        return {
            isValid: true,
            message: ''
        } as AttachmentObject;
    } else {
        return {
            isValid: false,
            message: selectedArea.typeErrorMessage
        } as AttachmentObject;
    }
};