import React from 'react';

import { Icon } from '../../../entities/IGlobal';
import { colorStack } from '../../../styleHelpers/colors';

export const HeartBook = (props: Icon) => (
    <svg width={props.width} height={props.height} {...props} viewBox="0 0 448 512" fill={colorStack.disabled} xmlns="http://www.w3.org/2000/svg">
        <path d="M448 336v-288C448 21.49 426.5 0 400 0H96C42.98 0 0 42.98 0 96v320c0 53.02 42.98 96 96 96h320c17.67 0 32-14.33 32-31.1c0-11.72-6.607-21.52-16-27.1v-81.36C441.8 362.8 448 350.2 448 336zM149.8 109.1c24-20 59.75-16.38 81.63 5.75L240 123.6l8.625-8.75c22-22.12 57.63-25.75 81.63-5.75c27.5 23 28.88 64.13 4.25 89.13l-84.75 85.5c-5.5 5.5-14.25 5.5-19.75 0l-84.75-85.5C120.8 173.3 122.3 132.1 149.8 109.1zM384 448H96c-17.67 0-32-14.33-32-32c0-17.67 14.33-32 32-32h288V448z"/>
    </svg>
);
