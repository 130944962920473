import * as actionTypes from '../actions/actionTypes/datatableTypes';

export interface IDatatableReducer {
    backgroundDatatableIds: string[];
}

const defaultState = (): IDatatableReducer => ({
    backgroundDatatableIds: []
});

export default (state = defaultState(), action): IDatatableReducer => {
    switch (action.type) {
        case actionTypes.SET_BACKGROUND_DATATABLE_IDS: {
            const payload: actionTypes.IDatatableTypes['SET_BACKGROUND_DATATABLE_IDS'] = action;
            return {
                ...state,
                backgroundDatatableIds: payload.backgroundDatatableIds
            };
        }
        case actionTypes.ADD_BACKGROUND_DATATABLE_ID: {
            const payload: actionTypes.IDatatableTypes['ADD_BACKGROUND_DATATABLE_ID'] = action;
            return {
                ...state,
                backgroundDatatableIds: [payload.backgroundDatatableId].concat(state.backgroundDatatableIds)
            };
        }
        default: {
            return state;
        }
    }
};

