import { get } from 'lodash';

import * as actionTypes from '../actions/actionTypes/profileTypes';
import { IFullUserProfile } from '../entities/IProfile';
import { UserIntercom } from '../entities/UserIntercom';
import { ICheckingMailbox } from '../entities/MailBox/ICheckingMailbox';
import { arrayToObject } from '../tools/arrayTools';
export interface IProfileReducer {
    currentUserProfile: IFullUserProfile;
    currentProfileLoaded: boolean;
    intercomUser: UserIntercom;
    profile: IFullUserProfile;
    profileRequested: boolean;
    profileLoaded: boolean;
    isSuperAdmin: boolean;
    currentUserMailbox: ICheckingMailbox;
    unreadEmailsCount: number;
    currentUserPermissions:  { [key: string]: boolean };
}

const defaultState = (): IProfileReducer => ({
    profile: undefined,
    currentUserProfile: undefined,
    currentProfileLoaded: false,
    profileRequested: false,
    profileLoaded: false,
    intercomUser: undefined,
    isSuperAdmin: false,
    currentUserMailbox: undefined,
    unreadEmailsCount: 0,
    currentUserPermissions: undefined
});

export default (state = defaultState(), action): IProfileReducer => {
    switch (action.type) {
        case actionTypes.GET_CURRENT_USER_PROFILE_COMPLETED: {
            const payload: actionTypes.IProfileTypes['GET_CURRENT_USER_PROFILE_COMPLETED'] = action;
            return {
                ...state,
                currentUserProfile: {
                    ...(state.currentUserProfile || {}),
                    ...payload.currentUserProfile
                },
                currentProfileLoaded: true,
                intercomUser: {
                    user_id: payload.currentUserProfile?.id,
                    name: `${payload.currentUserProfile?.firstName} ${payload.currentUserProfile?.lastName}`,
                    email: payload.currentUserProfile?.emailContact
                },
                currentUserPermissions: arrayToObject(payload.currentUserProfile?.permissions, permission => permission, () => true)
            };
        }

        case actionTypes.GET_PROFILE_REQUESTED: {
            return {
                ...state,
                profileRequested: true
            };
        }

        case actionTypes.GET_PROFILE_COMPLETED: {
            return {
                ...state,
                profileRequested: false,
                profileLoaded: true,
                profile: action.profile as IFullUserProfile
            };
        }

        case actionTypes.GET_USER_SUPER_ADMIN_PERMISSIONS: {
            return {
                ...state,
                isSuperAdmin: action.isSuperAdmin
            };
        }

        case actionTypes.GET_MAILBOX_URLS: {
            return {
                ...state,
                currentUserMailbox: action.result as ICheckingMailbox
            };
        }

        case actionTypes.GET_UNREAD_EMAILS: {
            return {
                ...state,
                unreadEmailsCount: get(action, 'result.Count', 0)
            };
        }
        default: {
            return state;
        }
    }
};