import styled from 'styled-components';
import { rgba } from 'polished';
import { Link } from 'react-router-dom';

import { media } from '../../../styleHelpers/breakpoint';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import boxShadow from '../../../styleHelpers/mixins/shadow';

export const Title = styled.h2`
    font-size: ${fontSize[13]};
    font-weight: 500;
    text-transform: uppercase;
`;

export const Container = styled.div`
    .lcr-grid-cell.lcr-grid12-9{
        float: none;
        margin: auto;
        overflow: hidden;
    }
`;

export const BlockContent = styled.div`
    background: ${colorStack.white};
    ${boxShadow()};
    border-radius: 4px;
    padding: 1rem;
    .title-box{
        margin: 0 0 47px 0;
    }
`;

export const ActiveBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
export const NonActiveBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NotificationsSingle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5vw;
    margin: 0 0 15px 0;
    flex-wrap: wrap;
    cursor:pointer;
    ${media.desktop`
        flex-wrap: nowrap;
        padding: 15px 45px;
    `}
    &.P2PInvitation{
        cursor:default;
        background: ${rgba(colorStack.blue, .1)};
    }
    &.AcceptP2PInvitation{
        border: 1px solid ${colorStack.middleGrey};
        background: ${rgba(colorStack.middleGrey, .1)};
    }
    &.nonActive{
        cursor:default;
        background: ${colorStack.whiteGrey};
    }
`;

export const NotificationsImage = styled(Link)`
    position: relative;
    width: 50px;
    height: 50px;
`;

export const NotificationsContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 20px;
    width: calc(100% - 70px);
    ${media.desktop`
        margin: 0 20px 0 40px;
        width: calc(100% - 240px);
    `}
`;

export const NotificationsText = styled.div`
    color: ${colorStack.black};
    font-size: ${fontSize[10]};
    font-weight: 400;
    padding: 0 30px 0 0;
    ${media.desktop`
        font-size: ${fontSize[13]};
    `}
`;

export const NotificationsMetas = styled.div`
    color: ${colorStack.disabled};
    font-size: ${fontSize[10]};
    margin: 5px 0 0 0;
`;

export const NotificationsShowAll = styled.div`
    text-align: center;
    border-bottom: 3px solid ${colorStack.lightBlue};
    width: 100%;
    padding: 4px 0;
    cursor: pointer;
    color: ${colorStack.darkGrey};
    font-size: ${fontSize[10]};
`;

export const NotificationBadge = styled.div`
    width: 17px;
    height: 17px;
    position: absolute;
    top: 5px;
    right: -6px;
    background: ${colorStack.lightBlue};
    border-radius: 100%;
    &.P2PInvitation{
        background: url('/assets/images/icons/invite.png') no-repeat center;
    }
`;

export const P2PSection = styled.div`
    display: flex;
    width: 100%;
    margin: 20px 0 20px 0;
    ${media.desktop`
        width: 190px;
        margin: 0;
    `}
    .accept{
        background: ${colorStack.lightBlue};
        color: ${colorStack.white};
        border-radius: 25px;
        cursor: pointer;
        padding: 13px 32px;
        text-transform: uppercase;
        font-size: ${fontSize[13]};
        margin: 0 0 0 20px;
    }
    .decline{
        color: ${colorStack.blue};
        cursor: pointer;
        font-size: ${fontSize[13]};
        text-transform: uppercase;
        font-weight: 500;
    }
`;

export const Earlier = styled.div`
    text-align: center;
    position: relative;
    width: 100%;
    margin: 25px 0 41px 0;
    &:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: ${rgba(colorStack.darkBlue, .1)};
        position: absolute;
        top:0;
        bottom:0;
        margin: auto;
    }
    span{
        background: ${colorStack.white};
        position: relative;
        padding: 0 23px;
        font-size: ${fontSize[13]};
    }
`;

export const EmptyArrayInfo = styled.span`
    text-align: center;
    font-size: ${fontSize[16]};
    font-weight: 500;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    h2 {
        margin: 0;
    }
`;

export const BlueText = styled.label`
    color: ${colorStack.middleBlue};
    font-weight: 0.25rem;

    :hover {
        text-decoration: underline;
    }
`;