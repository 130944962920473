import { IntlShape } from 'react-intl';

import { EClusterNature, EClusterPrivacy, EClusterVisibility } from '../entities/IClusters';
import { ECellHeight } from './dataGridTools';

export const clusterNatureTranslation = (nature: EClusterNature, intl: IntlShape) => {
    const clusterNature = {
        [EClusterNature.Project]: intl.formatMessage({ id: 'cluster.nature.project' }),
        [EClusterNature.Dispute]: intl.formatMessage({ id: 'cluster.nature.dispute' }),
        [EClusterNature.Contract]: intl.formatMessage({ id: 'cluster.nature.contract' }),
        [EClusterNature.Support]: intl.formatMessage({ id: 'cluster.nature.support' }),
        [EClusterNature.Other]: intl.formatMessage({ id: 'cluster.nature.other' }),
        [EClusterNature.SendingGift]: intl.formatMessage({ id: 'cluster.nature.sendingGift' }),
        [EClusterNature.ReceivingGift]: intl.formatMessage({ id: 'cluster.nature.receivingGift' })
    };
    return clusterNature[nature] || '';
};

export const clusterPrivacyTranslation = (privacy: EClusterPrivacy, intl: IntlShape) => {
    const clusterPrivacy = {
        [EClusterPrivacy.Private]: intl.formatMessage({ id: 'privacyType.private' }),
        [EClusterPrivacy.Public]: intl.formatMessage({ id: 'privacyType.public' }),
        [EClusterPrivacy.Secret]: intl.formatMessage({ id: 'privacyType.secret' })
    };
    return clusterPrivacy[privacy] || '';
};

export const clusterVisibilityTranslation = (clusterVisibility: EClusterVisibility, intl: IntlShape) => {
    const visibility = {
        [EClusterVisibility.Hidden]: intl.formatMessage({ id: 'clusterVisibility.hidden' }),
        [EClusterVisibility.Visible]: intl.formatMessage({ id: 'clusterVisibility.visible' })
    };
    return visibility[clusterVisibility] || '';
};

export const expandMenuOptionsTranslation = (intl: IntlShape) => {
    const expandMenuOptions = {
        [ECellHeight.Small]: intl.formatMessage({ id: 'clusterStatus.epandTool.small' }),
        [ECellHeight.Medium]: intl.formatMessage({ id: 'clusterStatus.epandTool.medium' }),
        [ECellHeight.Tall]: intl.formatMessage({ id: 'clusterStatus.epandTool.tall' }),
        [ECellHeight.ExtraTall]: intl.formatMessage({ id: 'clusterStatus.epandTool.extraTall' })
    };

    return {
        options: expandMenuOptions,
        getTranslation: (cellHeight: ECellHeight) => expandMenuOptions[cellHeight] || ''
    };
};

export const intlToString = (id: string, intl: IntlShape) => {
    if (id) {
        return intl.formatMessage({ id: id });
    }

    return '';
};