import { IFullUserProfile } from '../../entities/IProfile';

export const GET_PROFILE_REQUESTED = 'GET_PROFILE_REQUESTED';
export const GET_PROFILE_COMPLETED = 'GET_PROFILE_COMPLETED';
export const GET_CURRENT_USER_PROFILE_REQUESTED = 'GET_CURRENT_USER_PROFILE_REQUESTED';
export const GET_CURRENT_USER_PROFILE_COMPLETED = 'GET_CURRENT_USER_PROFILE_COMPLETED';
export const GET_USER_SUPER_ADMIN_PERMISSIONS = 'GET_USER_SUPER_ADMIN_PERMISSIONS';
export const SHOW_PREMIUM_MODAL = 'SHOW_PREMIUM_MODAL';
export const HIDE_PREMIUM_MODAL = 'HIDE_PREMIUM_MODAL';
export const GET_MAILBOX_URLS = 'GET_MAILBOX_URLS';
export const GET_UNREAD_EMAILS = 'GET_UNREAD_EMAILS';
export interface IProfileTypes {
    GET_CURRENT_USER_PROFILE_COMPLETED: {
        currentUserProfile: IFullUserProfile;
    };
}