import React from 'react';

import { Icon } from '../../../entities/IGlobal';

export const Approval = (props: Icon) => (
    <svg width={props.width} height={props.height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0)">
            <path d="M15.8596 12.9293H12.9298V12.3433C12.9298 11.611 12.4795 10.9821 11.8414 10.7182C12.0633 9.38688 12.4193 8.13988 13.5653 6.12981C13.9162 5.51438 14.1017 4.81273 14.1017 4.1007C14.1017 1.83207 12.2597 -0.0169916 9.9608 0.000117786C7.9201 0.0153131 6.31674 1.5043 5.97463 3.29859C5.76295 4.40875 5.99436 5.52242 6.62639 6.43445C7.49319 7.68543 7.9108 9.2325 8.15842 10.7182C7.52033 10.9821 7.07006 11.611 7.07006 12.3433V12.9293H4.14022C2.52471 12.9293 1.21037 14.2436 1.21037 15.8591V17.0702C1.21037 17.3938 1.47272 17.6561 1.79635 17.6561H2.38233V18.2421C2.38233 19.2114 3.17092 20 4.14022 20H15.8596C16.8289 20 17.6175 19.2114 17.6175 18.2421V17.6561H18.2035C18.5271 17.6561 18.7895 17.3938 18.7895 17.0702V15.8591C18.7895 14.2436 17.4751 12.9293 15.8596 12.9293ZM7.58971 5.76703C7.13826 5.11551 6.97354 4.31684 7.12584 3.51813C7.36451 2.26649 8.51338 1.15367 10.039 1.17051C11.6452 1.18824 12.9297 2.50406 12.9297 4.1007C12.9297 4.60953 12.7975 5.11043 12.5472 5.54934C11.3233 7.69602 10.9306 9.05438 10.6754 10.5854H9.32432C9.04213 8.89129 8.57854 7.1941 7.58971 5.76703ZM11.7578 12.3433V12.9293H8.24201V12.3433C8.24201 12.0202 8.50487 11.7573 8.82799 11.7573H11.1719C11.495 11.7574 11.7578 12.0202 11.7578 12.3433ZM16.4456 18.2421C16.4456 18.5652 16.1828 18.8281 15.8596 18.8281H4.14026C3.81717 18.8281 3.55428 18.5652 3.55428 18.2421V17.6561H16.4456V18.2421V18.2421ZM17.6176 16.4842C16.9419 16.4842 3.62197 16.4842 2.38237 16.4842V15.8591C2.38237 14.8898 3.17096 14.1013 4.14026 14.1013C4.42697 14.1013 14.5083 14.1013 15.8596 14.1013C16.8289 14.1013 17.6176 14.8898 17.6176 15.8591V16.4842Z" fill="#949494" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
