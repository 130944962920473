import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';

const NameButton = styled.button`
    cursor: pointer;
    text-align: left;
    font-weight: 500;
    color: ${colorStack.darkBlue};
    &:hover {
        text-decoration: underline;
    }
`;

interface IProps {
    organization: string;
    data: {
        name: string;
        id: string;
    }
}

export const ClusterLinkType: FC<React.PropsWithChildren<IProps>> = props => {
    const history = useNavigate();
    return (
        <NameButton
            onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                return history(`/orgs/${props.organization}/cluster/${props.data.id}/activity`);
            }}
        >
            {props.data.name}
        </NameButton>
    );
};