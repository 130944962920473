/*
    USE:

    ${placeholder({'color': 'blue'})}

*/

import { Styles } from '../types/style';

const placeholder = (styles: Styles): Styles => {
    return {
        ['&::-webkit-input-placeholder']: {
            ...styles,
            fontSize: '.88rem !important',
            fontWeight: '500 !important'
        },
        ['&:-moz-placeholder']: {
            ...styles,
            fontSize: '.88rem !important',
            fontWeight: '500 !important'
        },
        ['&::-moz-placeholder']: {
            ...styles,
            fontSize: '.88rem !important',
            fontWeight: '500 !important'
        },
        ['&:-ms-input-placeholder']: {
            ...styles,
            fontSize: '.88rem !important',
            fontWeight: '500 !important'
        }
    };
};

export default placeholder;