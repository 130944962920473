import React, { useState, useCallback, FC } from 'react';
import styled from 'styled-components';
import { faSearch as iconSearchInactive } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { media } from '../../../styleHelpers/breakpoint';

const Wrapper = styled.form`
    position: relative;
    padding: 0 0 0 15px;
    background: ${colorStack.white};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    border-radius: 4px;
    height: 34px;
    width: auto;
    border: 1px solid ${colorStack.ligthGrey};
    svg {
        font-size: ${fontSize[16]};
        ${media.desktop`
            font-size: inherit;
        `}
    }
    input {
        background: none;
        border: none;
        color: ${colorStack.darkBlue};
        font-size: ${fontSize[13]};
        font-weight: 500;
        height: 100%;
        margin: 0;
        padding: 0;
        width: calc(100% - 25px);
        &::placeholder {
            font-size: ${fontSize[13]};
            color: ${colorStack.disabled};
            font-weight: 400;
        }
    }
`;

const SearchIcon = styled.button`
    display: flex;
    justify-content: center;
    outline: none;
    cursor: pointer;
    align-items: center;
    height: 100%;
    width: 34px;
    color: ${colorStack.darkBlue};
    background: ${colorStack.white};
    border-radius: 4px;
`;

interface ISearchInputProps {
    placeholder?: string;
    value?: string;
    className?: string;
    disableSubmitButton?: boolean;
    onChangeHandler?(e: React.ChangeEvent<HTMLInputElement>);
    onSearchClick?(inputCurrentValue: string);
}

export const SearchInput: FC<React.PropsWithChildren<ISearchInputProps>> = ({ value, onChangeHandler, placeholder, className, onSearchClick, disableSubmitButton }) => {
    const [inputValue, setInputValue] = useState<string>('');

    const inputChangeHandler = useCallback(((newInputValue: React.ChangeEvent<HTMLInputElement>) => {
        newInputValue.stopPropagation();
        onChangeHandler?.(newInputValue);
        setInputValue(newInputValue.target.value);
    }), [onChangeHandler, setInputValue]);

    const onSearchClickHandler = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSearchClick?.(inputValue);
    }, [inputValue, onSearchClick]);

    return (
        <Wrapper className={className || ''} onSubmit={onSearchClickHandler}>
            <input
                type="text"
                placeholder={placeholder || ''}
                value={typeof value === 'string' ? value : inputValue}
                onChange={inputChangeHandler}
            />
            {!disableSubmitButton &&
                <SearchIcon type="submit">
                    <FontAwesomeIcon icon={iconSearchInactive} size="lg" />
                </SearchIcon>
            }
        </Wrapper>
    );
};
