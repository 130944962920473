import { Dispatch } from 'redux';
import { saveAs } from 'file-saver';

import { IUserBrief } from '../entities/IGlobal';
import { action } from '../tools/reduxTools';
import { IState } from '../reducers';
import { Http } from '../tools/http';
import { EResourceType, IExportColoredDatagrid } from '../entities/ICommonApi';
import { getCommonHeaders, parseFileNameAndBlobFromRes } from './assetsmanagementActions';

export const getResourceLockStatus = (resourceType: EResourceType, resourceId: string) => action<Promise<IUserBrief>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.commonApiGet(`/resource-types/${resourceType}/resources/${resourceId}/status`);
});

export const lockResource = (resourceType: EResourceType, resourceId: string, subResourceType?: string, subResourceId?: string) => action<Promise<IUserBrief>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    let body = {};
    if (subResourceType != undefined && subResourceId != undefined) {
        body = {
            subResourceType: subResourceType,
            subResourceId: subResourceId
        };
    }
    return http.commonApiPost(`/resource-types/${resourceType}/resources/${resourceId}/lock`, body);
});

export const unlockResource = (resourceType: EResourceType, resourceId: string) => action<Promise<{subResourceType: string, subResourceId: string}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.commonApiPost(`/resource-types/${resourceType}/resources/${resourceId}/unlock`, {});
});

export const extendLockTimeResource = (resourceType: EResourceType, resourceId: string, extendTime?: string) => action<Promise<{subResourceType: string, subResourceId: string}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.commonApiPost(`/resource-types/${resourceType}/resources/${resourceId}/extend${extendTime ? `?timeExtensionInMinutes=${extendTime}` : ''}`, {});
});

export const uploadAttachment = (body: FormData) => action<Promise<{fileId: string, name: string, contentType, fileReference: string}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.commonApiPost(`/temp/files/upload`, body);
});

export const getNewSasAccessToken = () => action<Promise<{token: string, expiresInMilliseconds: number}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.commonApiGet(`/sas-token/generate`);
});

export const exportDatagrid = (body: IExportColoredDatagrid) => action<Promise<any>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { context, config, language } = getState();
    const { userLanguage } = language;

    if (!body.lcid) {
        body.lcid = userLanguage;
    }

    return fetch(`${config.commonApiUrl}/data-table/export`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: getCommonHeaders(context, config)
    }).then(res => {
        if (res.ok) {
            return res;
        } else {
            return res.text().then(errorMessage => {
                return Promise.reject(errorMessage);
            });
        }
    }).then((response) => {
        return parseFileNameAndBlobFromRes(response);
    }).then(([fileName, blob]) => {
        blob && saveAs(blob, fileName || 'Export.xlsx');
    });
});
