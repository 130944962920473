import React, { FC } from 'react';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import * as Common from '../Common';

interface IAttachmentSectionProps {
    title: string;
    isAttachmentAvailable?: boolean;
    downloadHandler();
}

export const AttachmentSection: FC<React.PropsWithChildren<IAttachmentSectionProps>> = ({ title, isAttachmentAvailable, downloadHandler }) => (
    <Common.TopSection>
        <Common.Position>
            {title}
        </Common.Position>
        {isAttachmentAvailable &&
            <Common.Attachment onClick={downloadHandler}>
                <FontAwesomeIcon icon={faPaperclip} />
                <FormattedMessage id="global.attachment"  />
            </Common.Attachment>
        }
    </Common.TopSection>
);