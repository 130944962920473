import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { media } from '../../../styleHelpers/breakpoint';

interface ITagProps {
    color: 'green' | 'white' | 'brown' | 'blue' | 'successGreen' | 'activeBlue' | 'red' | 'gray' | 'warningYellow' | 'middleBlue';
    arrowType?: 'top' | 'bottom';
    numberOfSelections?: number;
    nomargin?: boolean;
    uppercase?: boolean;
    arrow?: boolean;
    noBorder?: boolean;
    noBreak?: boolean;
    sm?: boolean;
    className?: string;
    clickHandler?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

interface IInnerTagProps extends ITagProps {
    isClickable?: boolean;
}

const TagWrapper = styled.div<IInnerTagProps>`
    font-size: ${fontSize[13]};
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid;
    margin: 0 10px 5px 0;
    display: inline-flex;
    align-items: center;
    word-break: break-all;
    position: static;
    ${media.desktop`
        position: relative;
    `}
    ${props => props.isClickable && css`
        cursor: pointer;
    `}
    transition: border-color .2s ease-in;
    .selections {
        margin: 0 0 0 5px;
    }
    svg {
        margin: 0 0 0 5px;
        pointer-events: none;
    }
    > span {
        margin: 0 5px 0 0;
    }
    ${props => props.nomargin && css`
        margin: 0 10px 0 0;
        > span {
            margin: 0;
        }
    `}
    ${(props) => props.color === 'green' && css`
        color: ${colorStack.white};
        background: ${colorStack.lightBlue};
        border-color: ${colorStack.lightBlue};
        svg {
            fill: ${colorStack.white};
        }
    `}
    ${(props) => props.color === 'white' && css`
        color: ${colorStack.darkBlue};
        background: transparent;
        border-color: ${colorStack.ligthGrey};
        &:hover {
            border-color: ${colorStack.darkBlue};
        }
        svg {
            fill: ${colorStack.darkBlue};
        }
    `}
    ${(props) => props.color === 'white' && props.arrowType === 'top' && css`
        border-color: ${colorStack.darkBlue};
    `}
    ${(props) => props.color === 'brown' && css`
        color: ${colorStack.darkBlue};
        background: ${colorStack.lightOrange};
        border-color: ${colorStack.lightOrange};
    `}
    ${(props) => props.color === 'blue' && css`
        color: ${colorStack.darkBlue};
        background: ${colorStack.whiteBlue};
        border-color: ${colorStack.darkBlue};
        font-weight: 600;
    `}
    ${(props) => props.color === 'middleBlue' && css`
        color: ${colorStack.white};
        background: ${colorStack.middleBlue};
        border-color: ${colorStack.darkBlue};
        font-weight: 600;
    `}
    ${(props) => props.uppercase && css`
        text-transform: uppercase;
    `}

    ${(props) => props.arrowType === 'top' && css`
        > svg {
            margin: 7px 0 0 5px;
        }
    `}

    ${(props) => props.arrowType === 'bottom' && css`
        > svg {
            margin: 0 0 7px 5px;
        }
    `}
    ${props => props.noBorder && css`
        border: none;
    `}
    ${props => props.sm && css`
        font-size:${fontSize[13]};
        padding: 4px 5px;
    `}
    ${props => props.color === 'successGreen' && css`
        color: ${colorStack.white};
        background: ${colorStack.middleGreen};
    `}
    ${props => props.color === 'activeBlue' && css`
        color: ${colorStack.white};
        background: ${colorStack.darkBlue};
    `}
    ${props => props.color === 'red' && css`
        color: ${colorStack.white};
        background: ${colorStack.middleRed};
    `}
    ${props => props.color === 'gray' && css`
        color: ${colorStack.white};
        background: ${colorStack.middleGrey};
    `}
    ${props => props.color === 'warningYellow' && css`
        color: ${colorStack.white};
        background: ${colorStack.darkOrange};
    `}
    ${props => props.noBreak && css`
        word-break: normal;
    `}
`;

export const Tag: FC<React.PropsWithChildren<ITagProps>> = ({ noBorder, color, children, clickHandler, nomargin, uppercase, arrow, arrowType, sm, numberOfSelections = 0, className, noBreak }) => (
    <TagWrapper
        className={className || ''}
        color={color}
        noBorder={noBorder}
        noBreak={noBreak}
        onClick={clickHandler}
        nomargin={nomargin}
        uppercase={uppercase}
        arrowType={arrowType}
        isClickable={!!clickHandler}
        sm={sm}>
        {children}
        {numberOfSelections !== 0 && arrowType === 'bottom' && ` ·  ${numberOfSelections}`}
        {arrow && arrowType === 'top' && <FontAwesomeIcon icon={faSortUp} size="lg"/>}
        {arrow && arrowType === 'bottom' && <FontAwesomeIcon icon={faSortDown} size="lg"/>}
    </TagWrapper>
);
