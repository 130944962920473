
import { EClusterPrivacy } from '../../../../entities/IClusters';
import { IWizardValues } from '../../../../entities/ILegalSupport';
import { IFullUserProfile } from '../../../../entities/IProfile';

export const initialFormValues = (currentUserProfile: IFullUserProfile): IWizardValues => {
    return {
        formData: {
            subject: undefined,
            answerDate: new Date(),
            question: '',
            isRichText: undefined,
            attachment: undefined,
            user: {
                key: currentUserProfile.id,
                text: `${currentUserProfile.firstName} ${currentUserProfile.lastName}`,
                data: currentUserProfile
            },
            jobTitle: currentUserProfile.jobTitle || '',
            legalEntity: undefined,
            manager: currentUserProfile.manager ? ({
                key: currentUserProfile.manager?.id,
                text: `${currentUserProfile.manager?.firstName} ${currentUserProfile.manager?.lastName}`,
                data: currentUserProfile.manager
            }) : undefined,
            readPolicy: false,
            privacy: EClusterPrivacy.Private
        },
        step: 1
    };
};
