import React, { FC, useEffect, useState, Fragment, ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IState } from '../../../reducers';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { IContentObject, ISogeRefSource, EReferenceObjectType, IFootnotesObject } from '../../../entities/ISoge';
import { EContentObjectType, EStyleRef, ISGStyle, IILvlParams, EOfficeXmlType, ISGBookStyles, ISGContentReference, IPrBdrObject, ISGStyleParameters } from '../../../entities/LegalDoc/ISogeStyles';
import { getSogeArticleUrl, getSogeDocUrl } from '../../../tools/legalDocTools/sogeTools';
import { uuidv4 } from '../../../tools/authTools';
import { getTcBdrColor, wJc, getPrBdrCss, getPrBdrSize, getPrBdrVal, isPrBdrEqual, getTextTabs, matchParamKey, floatingImage, inlineImage } from '../../../tools/legalDocTools/sogeContentTools';
import { isNumber, safeJSONParse } from '../../../tools/generalTools';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { SgList } from './SgList';
import { ContentImage } from './ContentImage';
import { Loader } from '../../Common/Loader/Loader';
import { ReferencePreviewPopup } from './ReferencePreviewPopup';
import { TextRun } from './TextRun';

const ContentWrapper = styled.div`
    font-family: ${({ theme }) => theme.font?.secondary}, sans-serif;
    color: ${colorStack.label};
    font-size: ${fontSize[20]};
    padding-right: 0.8rem;
`;

interface IImageWrapperProps {
    inlineImage?: boolean;
}

const ImageWrapper = styled.div<IImageWrapperProps>`
    padding: 1rem 0;
    img {
        max-width: 100%;
    }
    ${props => props.inlineImage && css`
        display: inline;
    `}
`;

const SgTable = styled.table`
    width: 100%;
    border: 1px solid transparent;
    border-collapse: separate;
    border-spacing: 0;

    tr {
        border: 1px solid transparent;
    }

    th {
        background: ${colorStack.ligthGrey};
        text-align: left;
    }

    td, th {
        padding: 0.5rem;

        .text-run {
            word-break: normal;
        }
    }
`;

const TableWrapper = styled.div`
    overflow: auto;
    margin: 1rem 0;
`;

interface ISgCellProps {
    borderTopColor?: string;
    borderRightColor?: string;
    borderBottomColor?: string;
    borderLeftColor?: string;
    shdFill?: string;
}

const SgCell = styled.td<ISgCellProps>`
    border: 1px solid transparent;
    ${props => props.borderTopColor && css`
        border-top-color: ${props.borderTopColor};
    `}
    ${props => props.borderRightColor && css`
        border-right-color: ${props.borderRightColor};
    `}
    ${props => props.borderBottomColor && css`
        border-bottom-color: ${props.borderBottomColor};
    `}
    ${props => props.borderLeftColor && css`
        border-left-color: ${props.borderLeftColor};
    `}
    ${props => props.shdFill && css`
        background-color: #${props.shdFill};
    `}
`;

interface ISgParagraphProps {
    textColor?: string;
    alignment?: string;
    borderBottom?: string;
    borderLeft?: string;
    borderRight?: string;
    borderTop?: string;
    textDirection?: string;
}

const SgParagraph = styled.div<ISgParagraphProps>`
    margin: 0 0 0.5rem;
    font-weight: normal;
    text-align: justify;
    font-size: ${fontSize[20]};
    text-align: ${props => props.alignment};
    ${props => props.textColor && css`
        color: ${props.textColor};
    `}
    ${props => props.borderBottom && css`
        border-bottom: ${props.borderBottom};
    `};
    ${props => props.borderLeft && css`
        border-left: ${props.borderLeft};
    `};
    ${props => props.borderRight && css`
        border-right: ${props.borderRight};
    `};
    ${props => props.borderTop && css`
        border-top: ${props.borderTop};
    `};
    ${props => props.textDirection === 'lrTb' && css`
        writing-mode: vertical-rl;
        white-space: nowrap;
    `}
    ${props => props.textDirection === 'btLr' && css`
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
        white-space: nowrap;
    `}
`;

const SgParagraphGroup = styled(SgParagraph)`
    margin: 0;
`;

const SgXrefLink = styled(Link)`
    text-decoration: underline;
    position: relative;
`;

const SgExternalLink = styled.a`
    text-decoration: underline;
    position: relative;
`;

const FootnoteContent = styled(ReferencePreviewPopup)``;

const FootnoteRef = styled.sup`
    position: relative;
    > span {
        color: ${colorStack.darkBlue};
        font-weight: 500;
        padding: 3px 4px;
        margin-top: -3px;
    }
    ${FootnoteContent} {
        visibility: hidden;
        opacity: 0;
    }
    &:hover {
        ${FootnoteContent} {
            visibility: visible;
            opacity: 1;
        }
    }
`;

const useContentGroups = (bookStyles: ISGBookStyles, contentCollection: IContentObject[]) => {
    const [contentGroups, setContentGroups] = useState<IContentGroup[]>([]);

    useEffect(() => {
        if (!!bookStyles) {
            const contentGroupsArray: IContentGroup[] = [];
            let listElement;

            let prevPrBdrSz: IPrBdrObject;
            let prevPrBdrVal: IPrBdrObject;

            contentCollection?.forEach((content, index) => {
                let ref;
                let refName = content?.style?.parameters?.['w:numPr.w:numId.@w:val'] || content?.style?.parameters?.['w:pStyle.@w:val'];
                let rootStyle: ISGStyle;
                let pathCount = 0;

                do {
                    const levelKey = !isNaN(Number(refName))
                        ? pathCount === 0
                            ? `NormalNum ${refName}`
                            : `AbstractNum ${refName}`
                        : refName;
                    const styleKey = bookStyles.byName[levelKey] || bookStyles.byId[levelKey];
                    const normalRef = bookStyles.allStyles[styleKey]?.parameters?.[EStyleRef.NormalNum];
                    const abstractRef = bookStyles.allStyles[styleKey]?.parameters?.[EStyleRef.AbstractNum];
                    rootStyle = bookStyles.allStyles[styleKey];
                    ref = normalRef || abstractRef;
                    refName = normalRef
                        ? `NormalNum ${ref}`
                        : `AbstractNum ${ref}`;
                    pathCount++;
                } while (!!ref);

                const iLvl = parseInt(content.style?.parameters?.['w:numPr.w:ilvl.@w:val'], 10) || 0;
                const listLevelStyle = rootStyle?.children?.['w:lvl']?.parameters?.[iLvl];

                const bulletLevel = listLevelStyle?.['w:pStyle.@w:val'];
                const isParagraph = content.type === EContentObjectType.Text && content?.officeXmlType === EOfficeXmlType.Paragraph;

                const prBdrSz = getPrBdrSize(content?.style?.parameters);
                const prBdrVal = getPrBdrVal(content?.style?.parameters);

                const pushToBulletGroup = bulletLevel === listElement;
                const pushToPrBdrGroup = isParagraph && isPrBdrEqual(prevPrBdrSz, prBdrSz) && isPrBdrEqual(prevPrBdrVal, prBdrVal);

                if (contentGroupsArray.length === 0 || !(pushToBulletGroup && pushToPrBdrGroup)) {
                    contentGroupsArray.push({
                        content: [content],
                        isBullet: listLevelStyle?.['w:numFmt.@w:val'] === 'bullet',
                        pBdr: isParagraph && { prBdrSz, prBdrVal },
                        rootStyle,
                        iListLevelParams: listLevelStyle,
                        id: index
                    });
                } else {
                    contentGroupsArray[contentGroupsArray.length - 1].content.push(content);
                }

                listElement = bulletLevel;
                prevPrBdrSz = prBdrSz;
                prevPrBdrVal = prBdrVal;
            });

            setContentGroups(contentGroupsArray);
        }
    }, [contentCollection, bookStyles]);

    return contentGroups;
};

interface IContentProps {
    content: IContentObject;
    parentContent?: IContentObject;
    articleId?: string;
    level?: number;
    architextVersionId: string;
    bookStyles?: ISGBookStyles;
    orgUrl: string;
    refersToByReferenceId?: {
        [id: string]: ISogeRefSource;
    };
    versionId: string;
    currentPreviewReferenceId?: string;
    currentPreviewContentId?: string;
    previewContent?: ReactNode;
    disablePrBdrLeft?: boolean;
    disablePrBdrRight?: boolean;
    disablePrBdrBottom?: boolean;
    disablePrBdrTop?: boolean;
    tableStyleParameters?: ISGStyleParameters;
    bookTableStyleParameters?: ISGStyleParameters;
    isDiffView?: boolean;
    footnotes: IFootnotesObject;
    renderTextObject?(content: string): ReactNode;
    onReferenceMouseEnter?(refSource: ISogeRefSource, contentId: string): void;
    onReferenceMouseLeave?(): void;
}

export const Content: FC<React.PropsWithChildren<IContentProps>> = ({ content, articleId, level = 0, renderTextObject, architextVersionId, parentContent, bookStyles, orgUrl, refersToByReferenceId, versionId, currentPreviewReferenceId, currentPreviewContentId, previewContent, tableStyleParameters, bookTableStyleParameters, isDiffView, footnotes, onReferenceMouseEnter, onReferenceMouseLeave }) => {
    const hasChildren = content.children?.length;
    const contentGroups = useContentGroups(bookStyles, content.children || []);
    const [contentId] = useState<string>(uuidv4());

    const renderChildren = useCallback(() => {
        const bookTableStyleVal = content.type === EContentObjectType.Table && content.style?.parameters?.['w:tblStyle.@w:val'];
        const bookTableStyleParams = bookTableStyleVal && bookStyles?.allStyles?.[bookStyles?.byId?.[bookTableStyleVal]]?.parameters;

        if (!bookStyles) {
            return (content.children || []).map((childContent, index) => (
                <Content
                    key={childContent.id || index}
                    content={childContent}
                    articleId={articleId}
                    level={level + 1}
                    renderTextObject={renderTextObject}
                    architextVersionId={architextVersionId}
                    parentContent={content}
                    bookStyles={bookStyles}
                    orgUrl={orgUrl}
                    versionId={versionId}
                    currentPreviewReferenceId={currentPreviewReferenceId}
                    currentPreviewContentId={currentPreviewContentId}
                    previewContent={previewContent}
                    onReferenceMouseEnter={onReferenceMouseEnter}
                    onReferenceMouseLeave={onReferenceMouseLeave}
                    tableStyleParameters={(content.type === EContentObjectType.Table && content.style?.parameters) || tableStyleParameters}
                    bookTableStyleParameters={bookTableStyleParams || bookTableStyleParameters}
                    isDiffView={isDiffView}
                    footnotes={footnotes}
                />
            ));
        } else {
            return contentGroups.map(contentGroup => contentGroup.isBullet ? (
                <SgList key={contentGroup.id} bullet={contentGroup.iListLevelParams?.['w:lvlText.@w:val']}>
                    {contentGroup.content.map((childContent, index) => (
                        <li key={childContent.id || index}>
                            <Content
                                content={childContent}
                                articleId={articleId}
                                architextVersionId={architextVersionId}
                                parentContent={content}
                                bookStyles={bookStyles}
                                orgUrl={orgUrl}
                                refersToByReferenceId={refersToByReferenceId}
                                versionId={versionId}
                                currentPreviewReferenceId={currentPreviewReferenceId}
                                currentPreviewContentId={currentPreviewContentId}
                                previewContent={previewContent}
                                onReferenceMouseEnter={onReferenceMouseEnter}
                                onReferenceMouseLeave={onReferenceMouseLeave}
                                tableStyleParameters={(content.type === EContentObjectType.Table && content.style?.parameters) || tableStyleParameters}
                                bookTableStyleParameters={bookTableStyleParams || bookTableStyleParameters}
                                isDiffView={isDiffView}
                                footnotes={footnotes}
                            />
                        </li>
                    ))}
                </SgList>
            ) : contentGroup.pBdr ? (
                <SgParagraphGroup
                    key={contentGroup.id}
                    borderLeft={getPrBdrCss(contentGroup.pBdr.prBdrSz.left, contentGroup.pBdr.prBdrVal.left)}
                    borderRight={getPrBdrCss(contentGroup.pBdr.prBdrSz.right, contentGroup.pBdr.prBdrVal.right)}
                    borderBottom={getPrBdrCss(contentGroup.pBdr.prBdrSz.bottom, contentGroup.pBdr.prBdrVal.bottom)}
                    borderTop={getPrBdrCss(contentGroup.pBdr.prBdrSz.top, contentGroup.pBdr.prBdrVal.top)}
                >
                    {contentGroup.content.map((childContent, index) => (
                        <Content
                            key={childContent.id || index}
                            content={childContent}
                            articleId={articleId}
                            architextVersionId={architextVersionId}
                            parentContent={content}
                            bookStyles={bookStyles}
                            orgUrl={orgUrl}
                            refersToByReferenceId={refersToByReferenceId}
                            versionId={versionId}
                            currentPreviewReferenceId={currentPreviewReferenceId}
                            currentPreviewContentId={currentPreviewContentId}
                            previewContent={previewContent}
                            onReferenceMouseEnter={onReferenceMouseEnter}
                            onReferenceMouseLeave={onReferenceMouseLeave}
                            tableStyleParameters={(content.type === EContentObjectType.Table && content.style?.parameters) || tableStyleParameters}
                            bookTableStyleParameters={bookTableStyleParams || bookTableStyleParameters}
                            isDiffView={isDiffView}
                            footnotes={footnotes}
                        />
                    ))}
                </SgParagraphGroup>
            ) : (
                <Fragment key={contentGroup.id}>
                    {contentGroup.content.map((childContent, index) => (
                        <Content
                            key={childContent.id || index}
                            content={childContent}
                            articleId={articleId}
                            architextVersionId={architextVersionId}
                            parentContent={content}
                            bookStyles={bookStyles}
                            orgUrl={orgUrl}
                            refersToByReferenceId={refersToByReferenceId}
                            versionId={versionId}
                            currentPreviewReferenceId={currentPreviewReferenceId}
                            currentPreviewContentId={currentPreviewContentId}
                            previewContent={previewContent}
                            onReferenceMouseEnter={onReferenceMouseEnter}
                            onReferenceMouseLeave={onReferenceMouseLeave}
                            tableStyleParameters={(content.type === EContentObjectType.Table && content.style?.parameters) || tableStyleParameters}
                            bookTableStyleParameters={bookTableStyleParams || bookTableStyleParameters}
                            isDiffView={isDiffView}
                            footnotes={footnotes}
                        />
                    ))}
                </Fragment>
            ));
        }

    }, [content.id, level, articleId, contentGroups, contentGroups.length, !!bookStyles, orgUrl, refersToByReferenceId, onReferenceMouseEnter, currentPreviewReferenceId, previewContent]);

    const getRefObject = useCallback((): ISGContentReference => {
        if (content.type === EContentObjectType.ReferenceLink) {
            try {
                return JSON.parse(content.object) as ISGContentReference;
            } catch (error) {
                return undefined;
            }
        } else {
            return undefined;
        }
    }, [content.type, content.object]);

    const styleParams = content?.style?.parameters;

    const isParagraph = content?.officeXmlType === EOfficeXmlType.Paragraph;

    const gridSpan = styleParams?.['w:gridSpan.@w:val'];
    const colSpan = (gridSpan && parseInt(gridSpan, 10)) || 1;
    const bold = !!styleParams?.['w:b.@xmlns:w'] && styleParams?.['w:b.@w:val'] !== '0';
    const underline = !!styleParams?.['w:u.@xmlns:w'] && !!styleParams?.['w:u.@w:val'];
    const reference = getRefObject();
    const isHiddenParagraph = styleParams?.['w:pStyle.@w:val'] === 'NameofContent' || styleParams?.['w:webHidden.@xmlns:w'] || styleParams?.['w:pStyle.@w:val'] === 'NameOfContent' || styleParams?.['w:pStyle.@w:val'] === 'CHAPITRE-ANNEXE';
    const refTo = reference && refersToByReferenceId?.[reference.ReferenceId];
    const refLink = refTo && ((refTo.objectType === EReferenceObjectType.StructureLevel || refTo.objectType === EReferenceObjectType.StructureLevelRoot)
        ? getSogeDocUrl(orgUrl, refTo.objectCommonId || refTo.objectCommonLevelId, versionId)
        : getSogeArticleUrl(orgUrl, refTo.objectCommonId, undefined, versionId)
    );

    const cellBorderBottomVal = styleParams?.['w:tcBorders.w:bottom.@w:val'] || tableStyleParameters?.['w:tblBorders.w:bottom.@w:val'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:bottom.@w:val'];
    const cellBorderLeftVal = styleParams?.['w:tcBorders.w:left.@w:val'] || tableStyleParameters?.['w:tblBorders.w:left.@w:val'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:left.@w:val'];
    const cellBorderRightVal = styleParams?.['w:tcBorders.w:right.@w:val'] || tableStyleParameters?.['w:tblBorders.w:right.@w:val'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:right.@w:val'];
    const cellBorderTopVal = styleParams?.['w:tcBorders.w:top.@w:val'] || tableStyleParameters?.['w:tblBorders.w:top.@w:val'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:top.@w:val'];

    const cellBorderBottomColor = styleParams?.['w:tcBorders.w:bottom.@w:color'] || tableStyleParameters?.['w:tblBorders.w:bottom.@w:color'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:bottom.@w:color'];
    const cellBorderLeftColor = styleParams?.['w:tcBorders.w:left.@w:color'] || tableStyleParameters?.['w:tblBorders.w:left.@w:color'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:left.@w:color'];
    const cellBorderRightColor = styleParams?.['w:tcBorders.w:right.@w:color'] || tableStyleParameters?.['w:tblBorders.w:right.@w:color'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:right.@w:color'];
    const cellBorderTopColor = styleParams?.['w:tcBorders.w:top.@w:color'] || tableStyleParameters?.['w:tblBorders.w:top.@w:color'] || bookTableStyleParameters?.['w:tblPr.w:tblBorders.w:top.@w:color'];

    const borderBottomColor = getTcBdrColor(cellBorderBottomVal, cellBorderBottomColor);
    const borderLeftColor = getTcBdrColor(cellBorderLeftVal, cellBorderLeftColor);
    const borderRightColor = getTcBdrColor(cellBorderRightVal, cellBorderRightColor);
    const borderTopColor = getTcBdrColor(cellBorderTopVal, cellBorderTopColor);

    const paragraphColor = styleParams?.['w:rPr.w:color.@w:val'];
    const alignment = styleParams?.['w:jc.@w:val'] || 'initial';
    const specialExternalLink = styleParams?.FRONTEND_Hyperlink;
    const { prependTab, appendTab, appendBreak, prependBreak } = content.type === EContentObjectType.Text && !isParagraph && getTextTabs(styleParams);

    const vMerge = (content.type === EContentObjectType.TableCell && !!styleParams?.['w:vMerge.@xmlns:w'] && styleParams?.['w:vMerge.@w:val'] === 'restart' && styleParams?.vMergeCount) || undefined;
    const vMergeCount = vMerge && parseInt(vMerge, 10);
    const vMergeRemove = content.type === EContentObjectType.TableCell && !!styleParams?.['w:vMerge.@xmlns:w'] && (!styleParams?.['w:vMerge.@w:val'] || styleParams?.['w:vMerge.@w:val'] === 'continue') && !styleParams?.vMergeCount;
    const rowSpan = isNumber(vMergeCount) && vMergeCount + 1;

    const footnoteJson = content.type === EContentObjectType.FootnoteReference && safeJSONParse<{ id: string }>(content.object);
    const footnoteContents = content.type === EContentObjectType.FootnoteReference && footnotes?.[footnoteJson?.id];

    const isTextRun = content.type === EContentObjectType.Text && !isParagraph && !isHiddenParagraph && !specialExternalLink;
    const textHasStyling = bold || underline || styleParams?.['w:color.@w:val'] || prependTab || appendTab || prependBreak || appendBreak;

    const isInlineImage = content.type === EContentObjectType.Image && matchParamKey(styleParams, inlineImage);

    return (
        <>
            {reference && !!refLink && (
                <SgXrefLink
                    to={refLink}
                    onMouseEnter={(refTo && !!onReferenceMouseEnter) ? () => onReferenceMouseEnter(refTo, contentId) : undefined}
                    onMouseLeave={onReferenceMouseLeave}
                    data-internal-link={Number(!!isDiffView)}
                >
                    {!!hasChildren && renderChildren()}
                    {refTo?.referenceId === currentPreviewReferenceId && contentId === currentPreviewContentId && previewContent && !!hasChildren && (
                        <ReferencePreviewPopup>
                            {previewContent}
                        </ReferencePreviewPopup>
                    )}
                </SgXrefLink>
            )}
            {content.type === EContentObjectType.Hyperlink && (
                <SgExternalLink href={content.object} target="_blank">
                    {!!hasChildren && renderChildren()}
                </SgExternalLink>
            )}
            {specialExternalLink && (
                <SgExternalLink href={specialExternalLink} target="_blank">
                    {content.object || (!!hasChildren && renderChildren())}
                </SgExternalLink>
            )}
            {isTextRun && (
                <>
                    {isDiffView && !textHasStyling ? (
                        <>{renderTextObject?.(content.object) || content.object}</>
                    ) : (
                        <TextRun bold={bold} underline={underline} textColor={styleParams?.['w:color.@w:val']} prependTab={prependTab} appendTab={appendTab} prependBreak={prependBreak} appendBreak={appendBreak}>
                            {renderTextObject?.(content.object) || content.object}
                        </TextRun>
                    )}
                </>
            )}
            {content.type === EContentObjectType.Text && isParagraph && !isHiddenParagraph && !specialExternalLink && (
                <SgParagraph
                    textColor={paragraphColor && `#${paragraphColor}`}
                    alignment={wJc[alignment]}
                    textDirection={parentContent?.type === EContentObjectType.TableCell && parentContent?.style?.parameters?.['w:textDirection.@w:val']}
                >
                    {renderTextObject?.(content.object) || content.object}
                    {!!hasChildren && renderChildren()}
                </SgParagraph>
            )}
            {(content.type === EContentObjectType.Image && content.references?.[0]?.destinationObjectId) && (
                <ImageWrapper inlineImage={isInlineImage}>
                    <ContentImage
                        fileId={content.references?.[0]?.destinationObjectId}
                        articleId={articleId}
                        architextVersionId={architextVersionId}
                        currentVersionId={versionId}
                    />
                </ImageWrapper>
            )}
            {!!hasChildren && content.type === EContentObjectType.Text && !isParagraph && renderChildren()}
            {content.type === EContentObjectType.Table && (
                <TableWrapper>
                    <SgTable>
                        {!!hasChildren && renderChildren()}
                    </SgTable>
                </TableWrapper>
            )}
            {content.type === EContentObjectType.TableRow && (
                <tr>
                    {!!hasChildren && renderChildren()}
                </tr>
            )}
            {content.type === EContentObjectType.TableCell && !vMergeRemove && (
                <>
                    {parentContent?.style?.parameters?.['w:tblHeader.@xmlns:w'] ? (
                        <SgCell
                            as="th"
                            colSpan={colSpan}
                            rowSpan={rowSpan}
                            borderBottomColor={borderBottomColor}
                            borderLeftColor={borderLeftColor}
                            borderRightColor={borderRightColor}
                            borderTopColor={borderTopColor}
                            shdFill={styleParams?.['w:shd.@w:fill']}
                        >
                            {!!hasChildren && renderChildren()}
                        </SgCell>
                    ) : (
                        <SgCell
                            colSpan={colSpan}
                            rowSpan={rowSpan}
                            borderBottomColor={borderBottomColor}
                            borderLeftColor={borderLeftColor}
                            borderRightColor={borderRightColor}
                            borderTopColor={borderTopColor}
                            shdFill={styleParams?.['w:shd.@w:fill']}
                        >
                            {!!hasChildren && renderChildren()}
                        </SgCell>
                    )}
                </>
            )}
            {content.type === EContentObjectType.SdtBlock && (
                <div>
                    {!!hasChildren && renderChildren()}
                </div>
            )}
            {(content.type === EContentObjectType.SdtContentBlock || content.type === EContentObjectType.Bookmark) && (
                <>
                    {!!hasChildren && renderChildren()}
                </>
            )}
            {(content.type === EContentObjectType.FootnoteReference && footnoteJson?.id) && (
                <FootnoteRef>
                    <span>*</span>
                    <FootnoteContent>
                        {(footnoteContents?.contentObjects || []).map((footnoteContentObject, index) => (
                            <Content
                                key={footnoteContentObject.id || index}
                                content={footnoteContentObject}
                                articleId={articleId}
                                architextVersionId={architextVersionId}
                                parentContent={content}
                                bookStyles={bookStyles}
                                orgUrl={orgUrl}
                                refersToByReferenceId={refersToByReferenceId}
                                versionId={versionId}
                                footnotes={footnotes}
                            />
                        ))}
                    </FootnoteContent>
                </FootnoteRef>
            )}
        </>
    );
};

interface IContentObjectProps {
    contentCollection: IContentObject[];
    articleId: string;
    level?: number;
    architextVersionId: string;
    bookId: string;
    orgUrl: string;
    refersToByReferenceId?: {
        [referenceId: string]: ISogeRefSource;
    };
    versionId: string;
    currentPreviewReferenceId?: string;
    currentPreviewContentId?: string;
    previewContent?: ReactNode;
    isDiffView?: boolean;
    footnotes?: IFootnotesObject;
    onReferenceMouseEnter?(refSource: ISogeRefSource, contentId: string): void;
    onReferenceMouseLeave?(): void;
}

interface IContentGroup {
    content: IContentObject[];
    isBullet: boolean;
    pBdr?: {
        prBdrSz: IPrBdrObject;
        prBdrVal: IPrBdrObject;
    };
    rootStyle: ISGStyle;
    iListLevelParams: IILvlParams;
    id: number | string;
}

export const ContentObject: FC<React.PropsWithChildren<IContentObjectProps>> = ({ contentCollection, articleId, architextVersionId, bookId, orgUrl, refersToByReferenceId, versionId, currentPreviewReferenceId, currentPreviewContentId, previewContent, isDiffView, footnotes = {}, onReferenceMouseEnter, onReferenceMouseLeave }) => {
    const { sgStylesContainer, currentUniqueVersionId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const currentData = sgStylesContainer?.[currentUniqueVersionId];
    const styles = currentData?.styles;
    const relatedBookIdToStyle = currentData?.relatedBookIdToStyle;
    const relatedCommonLevelVersionIdToStyle = currentData?.relatedCommonLevelVersionIdToStyle;
    const styleId = relatedBookIdToStyle?.[bookId] || relatedCommonLevelVersionIdToStyle?.[architextVersionId] || relatedCommonLevelVersionIdToStyle?.[bookId];
    const bookStyles = styleId && styles?.[styleId];
    const contentGroups = useContentGroups(bookStyles, contentCollection);

    return (
        <Loader loading={!styles}>
            <ContentWrapper data-content>
                {contentGroups.map(contentGroup => contentGroup.isBullet ? (
                    <SgList key={contentGroup.id} bullet={contentGroup.iListLevelParams?.['w:lvlText.@w:val']}>
                        {contentGroup.content.map((content, index) => (
                            <Fragment key={content.id || index}>
                                {(!!content?.children?.length || content?.object) && (
                                    <li>
                                        <Content
                                            content={content}
                                            articleId={articleId}
                                            architextVersionId={architextVersionId}
                                            bookStyles={bookStyles}
                                            orgUrl={orgUrl}
                                            refersToByReferenceId={refersToByReferenceId}
                                            versionId={versionId}
                                            currentPreviewReferenceId={currentPreviewReferenceId}
                                            currentPreviewContentId={currentPreviewContentId}
                                            previewContent={previewContent}
                                            onReferenceMouseEnter={onReferenceMouseEnter}
                                            onReferenceMouseLeave={onReferenceMouseLeave}
                                            tableStyleParameters={content.type === EContentObjectType.Table && content.style?.parameters}
                                            bookTableStyleParameters={content.type === EContentObjectType.Table && bookStyles?.allStyles?.[bookStyles?.byId?.[content.style?.parameters?.['w:tblStyle.@w:val']]]?.parameters}
                                            isDiffView={isDiffView}
                                            footnotes={footnotes}
                                        />
                                    </li>
                                )}
                            </Fragment>
                        ))}
                    </SgList>
                ) : contentGroup.pBdr ? (
                    <SgParagraphGroup
                        key={contentGroup.id}
                        borderLeft={getPrBdrCss(contentGroup.pBdr.prBdrSz.left, contentGroup.pBdr.prBdrVal.left)}
                        borderRight={getPrBdrCss(contentGroup.pBdr.prBdrSz.right, contentGroup.pBdr.prBdrVal.right)}
                        borderBottom={getPrBdrCss(contentGroup.pBdr.prBdrSz.bottom, contentGroup.pBdr.prBdrVal.bottom)}
                        borderTop={getPrBdrCss(contentGroup.pBdr.prBdrSz.top, contentGroup.pBdr.prBdrVal.top)}
                    >
                        {contentGroup.content.map((content, index) => (
                            <Content
                                key={content.id || index}
                                content={content}
                                articleId={articleId}
                                architextVersionId={architextVersionId}
                                bookStyles={bookStyles}
                                orgUrl={orgUrl}
                                refersToByReferenceId={refersToByReferenceId}
                                versionId={versionId}
                                currentPreviewReferenceId={currentPreviewReferenceId}
                                currentPreviewContentId={currentPreviewContentId}
                                previewContent={previewContent}
                                onReferenceMouseEnter={onReferenceMouseEnter}
                                onReferenceMouseLeave={onReferenceMouseLeave}
                                tableStyleParameters={content.type === EContentObjectType.Table && content.style?.parameters}
                                bookTableStyleParameters={content.type === EContentObjectType.Table && bookStyles?.allStyles?.[bookStyles?.byId?.[content.style?.parameters?.['w:tblStyle.@w:val']]]?.parameters}
                                isDiffView={isDiffView}
                                footnotes={footnotes}
                            />
                        ))}
                    </SgParagraphGroup>
                ) : (
                    <Fragment key={contentGroup.id}>
                        {contentGroup.content.map((content, index) => (
                            <Content
                                key={content.id || index}
                                content={content}
                                articleId={articleId}
                                architextVersionId={architextVersionId}
                                bookStyles={bookStyles}
                                orgUrl={orgUrl}
                                refersToByReferenceId={refersToByReferenceId}
                                versionId={versionId}
                                currentPreviewReferenceId={currentPreviewReferenceId}
                                currentPreviewContentId={currentPreviewContentId}
                                previewContent={previewContent}
                                onReferenceMouseEnter={onReferenceMouseEnter}
                                onReferenceMouseLeave={onReferenceMouseLeave}
                                tableStyleParameters={content.type === EContentObjectType.Table && content.style?.parameters}
                                bookTableStyleParameters={content.type === EContentObjectType.Table && bookStyles?.allStyles?.[bookStyles?.byId?.[content.style?.parameters?.['w:tblStyle.@w:val']]]?.parameters}
                                isDiffView={isDiffView}
                                footnotes={footnotes}
                            />
                        ))}
                    </Fragment>
                ))}
            </ContentWrapper>
        </Loader>
    );
};
