import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { ISingleFilter, SingleFilter } from './SingleFilter';
import { colorStack } from '../../../../styleHelpers/colors';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;

    > div {
        margin: 0;
    }
`;

const ClearAllBox = styled.div`
    cursor: pointer;
    margin: 0;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colorStack.lightBlue};
    svg {
        color: ${colorStack.darkBlue};
    }
    > div {
        width: 32px;
    }
`;

interface IProps {
    className?: string;
    list: ISingleFilter[];
    disabled: boolean;
    onClick?: (item: ISingleFilter) => void;
    clearAll?: () => void;
}

const FilterList: FC<React.PropsWithChildren<IProps>> = ({ className, list, disabled, onClick, clearAll }) => {
    return (
        <Wrapper className={className}>
            <List>
                {list.map((item) => (
                    <SingleFilter
                        disabled={disabled}
                        key={`filter-list-item-${item.id}`}
                        id={item.id}
                        text={item.text}
                        field={item.field}
                        data={item.data}
                        onClick={onClick}
                        bgColor={item.bgColor}
                    />
                ))}
                {clearAll && list.length ?
                    <ClearAllBox onClick={clearAll}>
                        <FontAwesomeIcon icon={faXmark} />
                    </ClearAllBox>
                    : undefined}
            </List>
        </Wrapper>
    );
};

export default FilterList;
