import { IState } from './../reducers/index';
import * as actionTypes from './actionTypes/notificationsTypes';
import { Http } from '../tools/http';
import { INotification, ENotificationStatus, IUserNotificationSettings } from '../entities/INotification';

export const getNotificationsCount = () => ((dispatch, getState, http: Http) => {
    return http.notificationApiGet('/Notifications/my/count?status=NotRead')
        .then(res => {
            dispatch({
                type: actionTypes.GET_NOTIFICATIONS_COUNT,
                notificationsCount: res.count
            });
        });
}) as any;

export const getNotificationsActiveData = () => ((dispatch, getState, http: Http) => {
    return http.notificationApiGet('/Notifications/my?status=NotRead')
        .then(res => {
            dispatch({
                type: actionTypes.GET_NOTIFICATIONS_ACTIVE_DATA,
                notificationsActive: res.items
            });
            dispatch({
                type: actionTypes.NEW_NOTIFICATION_COUNT_LOADED,
                count: res.count || 0
            });
        });
}) as any;

export const getNotificationsNonActiveData = () => ((dispatch, getState, http: Http) => {
    return http.notificationApiGet('/Notifications/my?status=Read')
        .then(res => {
            dispatch({
                type: actionTypes.GET_NOTIFICATIONS_NONACTIVE_DATA,
                notificationsNonActive: res.items
            });

        });
}) as any;

export const changeNotificationStatus = (id: string): Promise<any> => ((dispatch, getState, http) => {
    return http.notificationApiPut(`/Notifications/${id}/set-status?status=Archived`)
        .then(() => {
            dispatch(getNotificationsCount());
            dispatch(getNotificationsActiveData());
        });
}) as any;

export const markAllAsNotNew = () => ((dispatch, getState, http: Http) => {
    return http.notificationApiPut('/Notifications/my/new/as-not-new')
        .then(() => {
            dispatch({
                type: actionTypes.NEW_NOTIFICATION_MARKED_ASS_SEEN
            });
        });
}) as any;

export const getAllNotifications = (page: number = 0, size: number = 8) => ((dispatch, getState, http: Http) => {
    return http.notificationApiGet(`/Notifications/my?page=${page}&size=${size}`);
}) as any;

export const loadMoreActiveNotifications = (page: number) => ((dispatch, getState, http: Http) => {
    return http.notificationApiGet(`/Notifications/my?status=NotRead&page=${page}`).then(result => {
        dispatch({
            type: actionTypes.LOAD_MORE_ACTIVE_NOTIFICATIONS,
            activeNotifications: result.items || []
        });
    });
}) as any;

export const loadMoreNonActiveNotifications = (page: number) => ((dispatch, getState, http: Http) => {
    return http.notificationApiGet(`/Notifications/my?status=Read&page=${page}`).then(result => {
        dispatch({
            type: actionTypes.LOAD_MORE_NONACTIVE_NOTIFICATIONS,
            nonActiveNotifications: result.items || []
        });
    });
}) as any;

export const markNotificationActionAsHandled = (id: string) => ((dispatch, getState, http: Http) => {
    return http.notificationApiPut(`/Notifications/${id}/action-handled`);
}) as any;

export const addRcivedNotification = (notification: INotification) => ((dispatch, getState: () => IState, http: Http) => {
    const { notifications: { notificationsVisible } } = getState();
    console.log('notification', notification, notificationsVisible); // we need this console log. please do not remove!! contact with Mateusz
    dispatch({
        type: actionTypes.SIGNALR_NOTIFICATION_RECIVED,
        notification: notification
    });
}) as any;

export const setNotificationsVisibilityFlag = (areVisible: boolean = true) => ((dispatch, getState, http: Http) => {
    dispatch({
        type: actionTypes.SET_NOTIFICATION_VISIBILITY_FLAG,
        notificationsVisible: areVisible
    });
}) as any;

export const updateNavMenuNotifications = (navMenuNotifications: INotification[]) => ((dispatch, getState, http: Http) => {
    dispatch({
        type: actionTypes.NAV_MENU_NOTIFICATION_UPDATED,
        navMenuNotifications
    });
}) as any;

export const marNavMenuNotificationAsRead = (id: string) => ((dispatch, getState, http: Http) => {
    const { notifications: { navMenuNotifications } } = getState();
    const notificationCopy: INotification[] = [...navMenuNotifications];
    const targetIndex = notificationCopy.findIndex(not => not.id === id);
    if (targetIndex !== -1) {
        notificationCopy[targetIndex].status = ENotificationStatus.Archived;
        dispatch(updateNavMenuNotifications(notificationCopy));
    }
}) as any;

export const markP2PNavMenuNotificationAsRead = (invitationId: string) => ((dispatch, getState, http: Http) => {
    const { notifications: { navMenuNotifications } } = getState();
    const notificationCopy: INotification[] = [...navMenuNotifications];
    const targetIndex = notificationCopy.findIndex(not => !!not.data && not.data.InvitationId === invitationId);

    if (targetIndex !== -1) {
        notificationCopy[targetIndex].data.ActionHandled = true;

        dispatch(updateNavMenuNotifications(notificationCopy));
    }
}) as any;

export const getUserNotificationsSettings = () => ((dispatch, getState, http: Http) => {
    return http.notificationApiGet('/notifications-settings/my')
        .then((res: IUserNotificationSettings) => {
            return res;
        });
}) as any;

export const updateUserNotificationsSettings = (settings: IUserNotificationSettings) => ((dispatch, getState, http: Http) => {
    return http.notificationApiPost('/notifications-settings', settings)
        .then((res: IUserNotificationSettings) => {
            return res;
        });
}) as any;

export const checkNotifForOrganizations = (body: string[]) => ((dispatch, getState, http: Http) => {
    return http.notificationApiPost('/notifications/context/organizations', body).then((response) => {
        return response;
    });
}) as any;

export const acceptDeclineInvitations = (accept: boolean, receiverId: string, inviterId: string, id: string) => ((dispatch, getState, http) => {
    return http
        .apiPost(`/P2PInvitation/respond?inviterId=${inviterId}&receiverId=${receiverId}&accepted=${accept}`)
        .then(() => {
            dispatch(markNotificationActionAsHandled(id));
            dispatch(changeNotificationStatus(id));

            accept && dispatch({
                type: actionTypes.UPDATE_RECIVER_RMS_STATUS,
                reciverRmsStatus: 3
            });
            setTimeout(() => dispatch({
                type: actionTypes.UPDATE_RECIVER_RMS_STATUS,
                reciverRmsStatus: 0
            }), 500);
        });
}) as any;