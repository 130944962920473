export const SET_BACKGROUND_DATATABLE_IDS = 'SET_BACKGROUND_DATATABLE_IDS';
export const ADD_BACKGROUND_DATATABLE_ID = 'ADD_BACKGROUND_DATATABLE_ID';

export interface IDatatableTypes {
    SET_BACKGROUND_DATATABLE_IDS: {
        backgroundDatatableIds: string[]
    },
    ADD_BACKGROUND_DATATABLE_ID: {
        backgroundDatatableId: string
    }
}
