import React, { FC } from 'react';

import { EProfileType } from '../../../../entities/IGlobal';
import { EPillColorSchema, EPillShape, Pill } from '../../Pill/Pill';

export interface ISingleFilter {
    id: string;
    text: string;
    field?: string;
    data?: {
        picture?: string;
        facetType?: string;
    };
    bgColor?: EPillColorSchema;
    onClick?: (item: ISingleFilter) => void;
}

type IProps = ISingleFilter & { disabled: boolean };

const getProfileType = (type: string) => {
    switch (type) {
        case 'UserDto':
            return EProfileType.Personal;
        case 'ClusterSearchViewLegalEntityDto':
            return EProfileType.Company;
        case 'LegalEntityFacetDto':
            return EProfileType.Company;
        default:
            return undefined;
    }
};

export const SingleFilter: FC<React.PropsWithChildren<IProps>> = ({ id, text, field, data, bgColor, disabled, onClick }) => {
    const profileType = getProfileType(data?.facetType);
    return (
        <Pill
            id={id}
            profileType={profileType}
            shape={profileType === EProfileType.Personal ? EPillShape.CIRCLE : EPillShape.SQUARE}
            picture={data?.picture}
            text={text}
            onClick={disabled ? undefined : () => onClick({ id, text, field, bgColor })}
            colorSchema={disabled ? EPillColorSchema.GRAY : bgColor}
        />
    );
};