import React, { forwardRef, PropsWithChildren, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCopy, faXmark, faPercent, faEye, faEyeSlash, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { DEFAULT_CURRENCY_KEY, IReferencial } from '../../../entities/IGlobal';
import { getCurrencies } from '../../../actions/legalEntitiesActions';
import { DropDown, EDropDownType } from '../DropDown/DropDown';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { ESpinnerSize, Spinner } from '../Spinner/Spinner';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';
import { EHintType, Hint } from '../Hint/Hint';
import { IValue } from '../../../entities/IPickers';

// tslint:disable-next-line:no-null-keyword
let _cachedCurrencies: IReferencial[] = null;

const getCachedCurrencies = (dispatch) => {
    if (!_cachedCurrencies) {
        return dispatch(getCurrencies()).then((allCurrencies: IReferencial[]) => {
            _cachedCurrencies = allCurrencies;
            return _cachedCurrencies;
        });
    }

    return Promise.resolve(_cachedCurrencies);
};

const Wrapper = styled.div<{ error: boolean }>`
    :hover {
        .copy-button {
            color: ${colorStack.middleBlue};
        }
    }
    input {
        position: relative;
        border: 2px solid ${colorStack.ligthGrey};
        border-radius: 8px;
        resize: none;
        outline: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        padding: 8px;
        color: ${colorStack.content};
        font-size: ${fontSize[20]};
        -webkit-font-smoothing: antialiased;
        :hover {
            border: 2px solid ${colorStack.middleBlue};
        }
        :active {
            border: 2px solid ${colorStack.middleBlue};
        }
        :focus {
            border: 2px solid ${colorStack.middleBlue};
        }
        &::placeholder {
            color: ${colorStack.middleGrey}!important;
            font-weight: 400!important;
            font-size: 16px!important;
            line-height: 16px!important;
            font-family: 'Roboto', sans-serif;
            &:disabled {
                color: ${colorStack.middleGrey}!important;
            }
        }
        ${props => props.error && css`
            border: 2px solid ${colorStack.middleRed};
        `}
    }

    width: 100%;

    input {
        ${fontSizeAndHeight[16]};
        min-width: 100%;
    }

    > input {
        height: 40px;
        box-sizing: content-box;
        padding: 0 16px;
        font-weight: 400;
        color: ${colorStack.content};
    }
`;

const Loader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InputWrapper = styled.div<{ rightPaddig?: boolean; leftPadding?: boolean; withAvatar?: boolean, disabled?: boolean, asCurrency?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;

    input {
        height: 40px;
    }

    input[type="password"] {
        ${fontSizeAndHeight[20]};
    }

    > input {
        padding: 0 16px;
        ${props => props.leftPadding && css`
            padding-left: 32px;
        `}
    }

    position: relative;
    ${props => props.rightPaddig && css`
        > input, > textarea {
            padding: 4px 52px 4px 16px
        }
    `}
    ${props => props.withAvatar && css`
        input {
            padding: 4px 12px 4px 40px;
        }
    `}
    ${props => props.disabled && css`
        > input, > textarea {
            background: ${colorStack.bodyBg}!important;
            color: ${colorStack.disabled}!important;
            border: 2px solid ${colorStack.ligthGrey} !important;
        }
    `}

    ${props => props.asCurrency && css`
        > input {
            padding-right: 90px;
        }
    `}
`;

const ClearButton = styled.button`
    font-weight: 900;
    font-size: ${fontSize[16]};
    cursor: pointer;
    color: ${colorStack.darkGrey};
    margin-right: 8px;
`;

const LeftIconBox = styled.div`
    position: absolute;
    right: 0;
    width: 16px;
    top: 11px;
    left: 9px;
    svg {
        color: ${colorStack.darkGrey};
    }
`;

const IconsBox = styled.div`
    position: absolute;
    right: 0;
    width: auto;
    display: flex;
    top: 11px;
    right: 9px;
    align-items: center;
    > div {
        margin: 0 .5rem;
    }
`;

const RightSideElementBox = styled.div<{ disabled?: boolean; isFocused?: boolean; redIconOnHover?: boolean, noMouseEvent?: boolean, fontWeight?: number, asCurrency?: boolean }>`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    font-weight: 900;

    ${props => props.fontWeight && css`
        font-weight: ${props.fontWeight};
    `}
    ${fontSizeAndHeight[16]};
    color: ${colorStack.darkGrey};

    ${props => props.noMouseEvent && css`
        pointer-events: none;
    `}

    ${props => props.disabled ? css`
        & {
            cursor: not-allowed;
            pointer-events: none;
        }
        &:hover {
        }
        * {
            cursor: not-allowed;
        }
    ` : css`
        cursor: pointer;
    `}
    svg {
        padding: 12px 8px 12px 8px;
        border-radius: 4px;
        margin-right: 4px;
        background-color: ${colorStack.ligthGrey};
        ${props => (props.isFocused && !props.disabled) && css`
            color: ${props.redIconOnHover ? colorStack.middleRed : colorStack.middleBlue};
        `}
    }

    background: ${colorStack.ligthGrey};

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: center;
    align-items: center;
    justify-content: center;
`;

const RightSideElementBoxNoBg = styled(RightSideElementBox)`
    background: none;

    ${props => props.asCurrency && css`
        margin-right: 20px;
        color: ${colorStack.content};
    `}

    svg {
        color: ${colorStack.darkGrey};
        padding: 16px 8px 16px 8px;

        ${props => props.asCurrency && css`
            width: 16px;
            height: 16px;
            padding: 8px;
            margin-left: 12px;
        `}

        background: none;
    }

`;

export enum ETextComponentSubType {
    PASSWORD = 'PASSWORD',
    CURRENCY = 'CURRENCY',
    PERCENTAGE = 'PERCENTAGE'
}

interface IProps {
    error?: boolean;
    value: string;
    placeholder?: string;
    maxLenght?: number;
    name?: string;
    subType?: ETextComponentSubType;
    disabled?: boolean;
    loading?: boolean;
    clearIcon?: boolean;
    copyIcon?: boolean;
    listIcon?: boolean;
    rightSideElement?: React.ReactNode;
    rightSideElementDisabled?: boolean;
    label?: React.ReactNode;
    currencies?: IReferencial[];
    selectedCurrency?: IValue;
    errorMsg?: string | React.ReactNode;
    autoFocus?: boolean;
    withAvatar?: boolean;
    required?: boolean;
    className?: string;
    leftIco?: IconDefinition;
    tooltipMsg?: string;
    tooltipTitle?: string;
    rightSideElementAction?: () => void;
    onChange?(value: string, selectedCurrency?: IValue, e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    onBlur?(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>);
    onFocus?(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>);
    onClearClick?();
    clearIconFunction?();
    copyIconFunction?();
    onClick?(e: React.MouseEvent<HTMLInputElement>);
}

export const TextComponent = forwardRef<HTMLInputElement | HTMLTextAreaElement, PropsWithChildren<IProps>>((props, ref) => {
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const [passwordHidden, setPasswordHidden] = useState(true);
    const [fakePassword, setFakePassword] = useState(props.value);
    // tslint:disable-next-line:no-null-keyword
    const fakePasswordRef = useRef<HTMLInputElement>(null);
    const [internalError, setInternalError] = useState<boolean>(false);
    const [internalErrorMsg, setInternalErrorMsg] = useState<string>('');
    const [currencies, setCurrencies] = useState<IReferencial[]>(props.currencies || []);
    const [selectedCurrency, setSelectedCurrency] = useState<IValue>(undefined);

    useEffect(() => {
        if (selectedCurrency?.text !== props.selectedCurrency?.text) setSelectedCurrency(props.selectedCurrency);
    }, [props.selectedCurrency]);

    useEffect(() => {
        setCurrencies(props.currencies);
    }, [props.currencies]);

    useEffect(() => {
        if (props.subType === ETextComponentSubType.PASSWORD) {
            if (passwordHidden && fakePasswordRef.current) {
                fakePasswordRef.current.focus();
            } else {
                setTimeout(() => {
                    (ref as RefObject<HTMLInputElement>)?.current?.focus();
                }, 100);
            }
        }
    }, [passwordHidden]);

    const onFocus = (e) => {
        if (props?.onFocus) {
            props.onFocus(e);
        }
        setIsFocused(true);
    };

    const onBlur = (e) => {
        if (props?.onBlur) {
            props.onBlur(e);
        }

        setInternalError(props.required && !props.value);
        setIsFocused(false);
    };

    const onHiddenPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value, undefined, e);
        setFakePassword(e.target.value);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value, selectedCurrency, e);

        const value = e.target.value?.trim();

        clearInternalError();

        if (props.subType === ETextComponentSubType.PASSWORD) {
            setFakePassword(value);
        }

        if (props.required && !value) {
            setInternalError(true);
        }

        if (value && props.subType === ETextComponentSubType.PERCENTAGE) {
            setInternalError(!validatePercentageValue(value));
        }

        if (value && props.subType === ETextComponentSubType.CURRENCY) {
            setInternalError(!validateCurrencyAmount(value));
        }
    };

    const validatePercentageValue = (value: string) => {
        return /^[1-9]\d+$/.test(value);
    };

    const validateCurrencyAmount = (value: string) => {
        return /^(?:(?:0)|(?:[1-9]+))$/.test(value);
    };

    const clearInternalError = () => {
        setInternalError(false);
        setInternalErrorMsg('');
    };

    const currencyOptions = useMemo(() => {
        return (currencies || []).map(c => ({
            key: c.Key,
            text: c.name,
            data: c
        }));
    }, [currencies]);

    const currencyChange = (item: IValue) => {
        setSelectedCurrency(item);
        props.onChange(props.value || '', item);
    };

    return (
        <div>
            {props.label &&
                <LabelWithEllipsis tooltipMsg={props.tooltipMsg} tooltipTitle={props.tooltipTitle} numberOfLines={3}>
                    {props.label}
                    {props.required &&
                        <MandatoryFieldStar />
                    }
                </LabelWithEllipsis>
            }
            <Wrapper error={props.error || internalError} data-lc="js-lc-text-component" className={`text-component ${props.className}`}>
                <InputWrapper
                    rightPaddig={(props.errorMsg !== ' ' && props.errorMsg && props.error) || props.loading}
                    leftPadding={!!props.leftIco}
                    withAvatar={props.withAvatar}
                    disabled={props.disabled}
                    asCurrency={props.subType === ETextComponentSubType.CURRENCY}>
                    {(props.subType === ETextComponentSubType.PASSWORD && passwordHidden) ?
                        <input ref={fakePasswordRef} type="password" name={props.name} onChange={onHiddenPasswordChange} value={fakePassword} />
                        : <input
                            ref={ref as RefObject<HTMLInputElement>}
                            type={(props.subType === ETextComponentSubType.PASSWORD && passwordHidden) ? 'password' : 'text'}
                            disabled={props.disabled}
                            onChange={onInputChange}
                            onBlur={onBlur}
                            onClick={props?.onClick}
                            onFocus={onFocus}
                            value={props.value || ''}
                            placeholder={props.placeholder}
                            name={props.name}
                            autoFocus={props.autoFocus}
                            autoComplete="new-password"
                            maxLength={props.maxLenght}
                        />}
                    {props.leftIco &&
                        <LeftIconBox>
                            <FontAwesomeIcon icon={props.leftIco} />
                        </LeftIconBox>
                    }
                    <IconsBox>
                        {props.loading &&
                            <Loader>
                                <Spinner size={ESpinnerSize.SMALL} />
                            </Loader>
                        }
                        {(props.onClearClick && props.subType === undefined) && (
                            <ClearButton
                                onClick={props.onClearClick}
                                type="button"
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </ClearButton>
                        )}

                    </IconsBox>
                    {props.rightSideElement && (
                        <RightSideElementBox onClick={props.rightSideElementAction} disabled={props.rightSideElementDisabled} isFocused={isFocused}>
                            {props.rightSideElement}
                        </RightSideElementBox>
                    )}
                    {props.clearIcon &&
                        <RightSideElementBox onClick={props.clearIconFunction} disabled={props.rightSideElementDisabled} isFocused={isFocused} redIconOnHover>
                            <FontAwesomeIcon icon={faXmark} />
                        </RightSideElementBox>
                    }
                    {props.copyIcon &&
                        <RightSideElementBox onClick={props.copyIconFunction} className="copy-button" disabled={props.rightSideElementDisabled}>
                            <FontAwesomeIcon icon={faCopy} />
                        </RightSideElementBox>
                    }
                    {props.listIcon && (
                        <RightSideElementBox>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </RightSideElementBox>
                    )}
                    {(props.subType === ETextComponentSubType.PASSWORD) && (
                        <RightSideElementBoxNoBg onClick={() => { setPasswordHidden(state => !state); }}
                            noMouseEvent={props.rightSideElementDisabled}>
                            <FontAwesomeIcon icon={passwordHidden ? faEye : faEyeSlash} />
                        </RightSideElementBoxNoBg>
                    )}
                    {(props.subType === ETextComponentSubType.PERCENTAGE) && (
                        <RightSideElementBoxNoBg noMouseEvent>
                            <FontAwesomeIcon icon={faPercent} />
                        </RightSideElementBoxNoBg>
                    )}
                    {(props.subType === ETextComponentSubType.CURRENCY && currencyOptions.length > 0) && (
                        <RightSideElementBoxNoBg
                            noMouseEvent={props.rightSideElementDisabled}
                            fontWeight={400}
                            asCurrency
                        >
                            <DropDown
                                type={EDropDownType.SEARCH}
                                withSeparator
                                alignRight
                                options={currencyOptions}
                                value={selectedCurrency?.key ? [selectedCurrency] : undefined}
                                onChange={currencyChange}
                            />
                        </RightSideElementBoxNoBg>
                    )}
                    {((props.errorMsg !== ' ' && props.errorMsg && props.error) || (internalError && internalErrorMsg)) &&
                        <Hint type={EHintType.ERROR}>{internalErrorMsg || props.errorMsg}</Hint>
                    }
                </InputWrapper>
            </Wrapper>
        </div>
    );
});
