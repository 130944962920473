import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const UncheckedCheckobox = styled.div<{ disabled: boolean }>`
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: 2px solid ${colorStack.ligthGrey};
    background: ${colorStack.white};
    transition: all .3s;
    &:hover {
        border: 2px solid ${colorStack.middleBlue};
    }
    ${props => props.disabled && css`
        background: ${colorStack.disabled};
        color: ${colorStack.disabled};
        border: 2px solid ${colorStack.disabled};
    `};
`;

const CheckboxContainer = styled.label`
        display: flex;
        align-items: center;
        cursor: pointer;
        input {
            display:none;
        }
    `;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon) <{ disabled: boolean }>`
    height: 16px;
    width: 16px;
    transition: all .3s;
    padding: 2px;
    border-radius: 4px;
    color: ${colorStack.white};
    border: 2px solid ${colorStack.middleBlue};
    background-color: ${colorStack.middleBlue};
    ${props => props.disabled && css`
        background: ${colorStack.disabled};
        color: ${colorStack.middleGrey};
        border: 2px solid ${colorStack.disabled};
    `}
`;

const LabelWrapper = styled.div`
    display:flex;
    margin-left: 10px;
    width: calc(100% - 24px - 10px);
    label {
        cursor: pointer;
    }
`;

const Label = styled.label`
    display: block;
    padding: 0;
    ${fontSizeAndHeight[16]};
    color: ${colorStack.content};
`;

interface IProps {
    checked: boolean;
    defaultChecked?: boolean;
    label?: string;
    disabled?: boolean;
    htmlFor?: string;
    triggerDropdownClose?: boolean;
    onChange?(checked: boolean);
}

export const Checkbox: FC<React.PropsWithChildren<IProps>> = (props) => {

    const changeHandler = () => {
        !props.disabled && props.onChange(!props.checked);
    };

    return (
        <CheckboxContainer data-nonclick={props.triggerDropdownClose}>
            <input
                id={props.htmlFor}
                type="checkbox"
                defaultChecked={props.defaultChecked}
                checked={props.checked}
                disabled={props.disabled}
                data-nonclick={props.triggerDropdownClose}
            />
            {props.checked ? (
                <StyledFontAwesomeIcon icon={faCheck} disabled={props.disabled} onClick={changeHandler} data-nonclick={props.triggerDropdownClose} />
            ) : (
                <UncheckedCheckobox disabled={props.disabled} onClick={changeHandler} />
            )}
            <LabelWrapper data-nonclick={props.triggerDropdownClose} onClick={changeHandler}>
                <Label data-nonclick={props.triggerDropdownClose} dangerouslySetInnerHTML={{ __html: props.label }} />
            </LabelWrapper>
        </CheckboxContainer>
    );
};
