import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ISogeParent, IReferenceText, ISimplifiedReferenceText } from '../../../../entities/ISoge';
import { getSogeDocUrl } from '../../../../tools/legalDocTools/sogeTools';
import { Row, Col } from '../common/SharedComponents';
import { SgTable } from '../../../Common/SgTable/SgTable';
import { LinkButton } from '../../../Common/Buttons/Button';
import { StakeholderTableRow } from '../../Components/StakeholderTableRow';
import { LabelWithEllipsis } from '../../../Common/Label/Label';

const Stakeholders = styled(SgTable)`
    margin: 0 -1rem 1rem -1rem;
`;

const TableHeader = styled(LabelWithEllipsis)`
    margin-bottom: 0;
`;

interface ICollectionRowProps {
    organizationUrlName: string;
    isCurrentVersion: boolean;
    versionId: string;
    book: ISogeParent;
    referenceText: ISimplifiedReferenceText | IReferenceText;
    marginBottom?: boolean;
    isPdfOnlyState?: boolean;
}

export const CollectionRow: FC<React.PropsWithChildren<ICollectionRowProps>> = ({ book, referenceText, organizationUrlName, isCurrentVersion, versionId, marginBottom, isPdfOnlyState }) => {
    const bookUrl = getSogeDocUrl(organizationUrlName, book?.commonLevelId, isCurrentVersion ? 'current' : versionId);

    return (
        <Row marginBottom={marginBottom}>
            <Col>
                <LabelWithEllipsis>
                    <FormattedMessage id="norms.collection" />
                </LabelWithEllipsis>
                <LinkButton to={!isPdfOnlyState && bookUrl}>
                    <span>
                        {book?.fullName}
                    </span>
                </LinkButton>
            </Col>
            <Col>
                {!!(referenceText as IReferenceText)?.stakeholders?.contact?.length && (
                    <Stakeholders
                        noBorder
                        borderSpacing="none"
                        columns={[{
                            header: (
                                <TableHeader>
                                    <FormattedMessage id="global.contact" />
                                </TableHeader>
                            ),
                            shrink: true
                        }, {
                            header: <></>
                        }]}
                    >
                        {((referenceText as IReferenceText).stakeholders.contact || []).map(stakeholder => (
                            <StakeholderTableRow
                                key={stakeholder.id}
                                stakeholder={stakeholder}
                            />
                        ))}
                    </Stakeholders>
                )}
            </Col>
        </Row>
    );
};
