import React, { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faUserFriends, faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { IContextReducer } from '../../reducers/contextReducer';
import { IState } from '../../reducers';
import { ILanguageReducer } from '../../reducers/languageReducer';
import { textColorByCode } from '../../tools/textColorByCode';
import { getTextFromDraft } from '../../tools/draftJS';
import { ISingleWorkElem } from '../../entities/IWork';
import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import boxShadow from '../../styleHelpers/mixins/shadow';
import { PeopleInChargeBrief } from '../Common/PeopleInCharge/PeopleInChargeBrief';
import { media } from '../../styleHelpers/breakpoint';
import { Button, EButtonTypeSchema } from '../Common/Buttons/NewButton';
import { StatusDot } from '../Common/StatusDot/StatusDot';
import { NewRtView } from '../Common/RichText/NewRtView';

const Wrapper = styled.div`
    ${boxShadow()};
    background: ${colorStack.white};
    padding: .5rem 1rem;
    margin: 0 0 1rem 0;
    border-radius: 4px;
`;

const Title = styled(Link)`
    color: ${colorStack.darkBlue};
    font-weight: 600;
    font-size: ${fontSize[16]};
    &:hover {
        text-decoration: none;
    }
    &:visited {
        color: ${colorStack.darkBlue};
    }
`;

const Description = styled.p`
    font-size: ${fontSize[13]};
    color: ${colorStack.middleBlue};
    margin: 1rem 0 0 0;
    text-align: left;
`;

const Metas = styled.div`
    display: flex;
    margin: 1rem 0 0 0;
    align-items: center;
    flex-wrap: wrap;
`;

const Workspace = styled.span`
    font-size: ${fontSize[13]};
    color: ${colorStack.middleGrey};
`;

const Updated = styled.span`
    font-size: ${fontSize[13]};
    color: ${colorStack.middleGrey};
`;

const Template = styled.span<{ templateColor: string }>`
    font-size: ${fontSize[13]};
    color: ${props => props.templateColor ? textColorByCode(props.templateColor) : colorStack.darkBlue};
    background: ${props => props.templateColor || 'transparent'};
    padding: 4px .5rem;
    border-radius: 4px;
    svg {
        margin: 0 .5rem 0 0;
    }
`;

const WithIcon = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.darkBlue};
    display: flex;
    margin: 0 1rem 0 0;
    svg {
        margin: 0 3px 0 0;
    }
`;

const Meta = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 .5rem 0;
    ${media.desktop`
        margin: 0;
    `}
`;

const Bullet = styled.span`
    margin: 0 .5rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 .5rem 0;
`;

interface ISingleWorkElemProps {
    data: ISingleWorkElem;
    unreadMessages: number;
    deleteButton?: boolean;
    removeHandler?(id: string);
}

export const SingleWorkElem: FC<React.PropsWithChildren<ISingleWorkElemProps>> = props => {
    const { contextOrganizations } = useSelector<IState, IContextReducer & ILanguageReducer>(state => ({
        ...state.context,
        ...state.language
    }));
    const organizationName = contextOrganizations.byUrl[props.data.organizationUrlName]?.details?.name;

    const removeHandler = useCallback(() => {
        props.removeHandler(props.data.id);
    }, [props.removeHandler, props.data.id]);

    const hasDescription = useMemo(() => {
        const { description } = props.data;
        const plainText: string = getTextFromDraft(description);
        return typeof plainText === 'string' && !!plainText.trim();
    }, [props.data.description]);

    return (
        <Wrapper>
            <Header>
                <Title to={`/orgs/${props.data.organizationUrlName}/cluster/${props.data.id}`}>{props.data.name}</Title>
                {props.deleteButton &&
                    <Button
                        onClick={removeHandler}
                        leftIco={faTrash}
                        typeSchema={EButtonTypeSchema.TEXT}
                    >
                        <FormattedMessage id="global.delete" />
                    </Button>
                }
            </Header>
            <Description>
                {hasDescription && (
                    <NewRtView content={props.data.description} />
                )}
            </Description>
            <Metas>
                {!!organizationName &&
                    <Meta>
                        <Workspace>{organizationName}</Workspace>
                        <Bullet>&bull;</Bullet>
                    </Meta>
                }
                {props.data.clusterTemplate &&
                    <Meta>
                        <Template templateColor={props.data.clusterTemplate.color}>
                            <span>{props.data.clusterTemplate?.templateName}</span>
                        </Template>
                        <Bullet>&bull;</Bullet>
                    </Meta>
                }
                {props.data.status &&
                    <Meta>
                        <StatusDot
                            status={props.data.status}
                            extended
                        />
                        <Bullet>&bull;</Bullet>
                    </Meta>
                }
                {(props.data.updated?.user && props.data.updated?.date) &&
                    <Meta>
                        <Updated>
                            <FormattedMessage
                                id="cluster.updatedByUser"
                                values={{ date: moment.utc(props.data.updated.date).fromNow(), user: props.data.updated.user }}
                            />
                        </Updated>
                        <Bullet>&bull;</Bullet>
                    </Meta>
                }
                {props.data.peopleInCharge?.length > 0 &&
                    <Meta>
                        <PeopleInChargeBrief peopleInCharge={props.data.peopleInCharge} margin="0 .2rem" />
                        <Bullet>&bull;</Bullet>
                    </Meta>
                }
                {props.unreadMessages > 0 &&
                    <WithIcon>
                        <FontAwesomeIcon icon={faCommentAlt} />
                        {props.unreadMessages}
                    </WithIcon>
                }
                {props.data.numbersOfMembers > 0 &&
                    <WithIcon>
                        <FontAwesomeIcon icon={faUserFriends} />
                        {props.data.numbersOfMembers}
                    </WithIcon>
                }
                {props.data.numberOfDocuments > 0 &&
                    <WithIcon>
                        <FontAwesomeIcon icon={faFileAlt} />
                        {props.data.numberOfDocuments}
                    </WithIcon>
                }
            </Metas>
        </Wrapper>
    );
};
