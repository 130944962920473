import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { ISogeParent } from '../../../../entities/ISoge';
import { useDropdown } from '../../../../tools/hooks';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { WhiteBox } from '../../../Common/WhiteBox/WhiteBox';
import { BreadCrumbsElements } from './BreadCrumbsElements';

const Wrapper = styled.div<{ consultation?: boolean }>`
    margin-bottom: 1rem;
    font-size: ${fontSize[13]};
    position: relative;
    a {
        &:hover {
            text-decoration: underline;
        }
    }
    ${({ consultation }) => consultation && css`
        max-width: 1000px;
        margin: 0 auto 1rem auto;
    `}
`;

const ToggleButton = styled.button`
    display: inline;
    outline: 0;
    background: 0;
    border: 0;
    cursor: pointer;
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[13]};
    margin-left: .5rem;
`;

const ExpandedWrapper = styled(WhiteBox)`
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    padding: .7rem;
    display: flex;
    align-items: flex-start;
    z-index: 1;
    border: 1px solid ${colorStack.ligthGrey};
    > span {
        flex: 1;
    }
`;

interface IBreadcrumbsProps {
    parents: ISogeParent[];
    organizationUrlName?: string;
    versionId?: string;
    icon?: React.ReactNode | IconDefinition;
    collapsable?: boolean;
    className?: string;
    consultation?: boolean;
    getStructureLink?(commonLevelId: string): string;
}

export const Breadcrumbs: FC<React.PropsWithChildren<IBreadcrumbsProps>> = ({ parents = [], organizationUrlName, versionId, icon, collapsable, className, consultation, getStructureLink }) => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    const shouldCollapse = collapsable && parents.length > 2;

    const parentsArray: ISogeParent[] = useMemo(() => {
        return shouldCollapse
            ? [parents[0], { commonLevelId: 'collapsed', fullName: '...' }, parents[parents.length - 1]]
            : parents;
    }, [shouldCollapse, parents]);

    return (
        <Wrapper ref={wrapperRef} className={className || ''} consultation={consultation}>
            <BreadCrumbsElements
                organizationUrlName={organizationUrlName}
                versionId={versionId}
                icon={icon}
                parentsArray={parentsArray}
                getStructureLink={getStructureLink}
            />
            {shouldCollapse && (
                <ToggleButton onClick={toggleDropdown}>
                    <FormattedMessage id="norms.showFullLocation" />
                </ToggleButton>
            )}
            {dropdownOpen && collapsable && (
                <ExpandedWrapper>
                    <BreadCrumbsElements
                        organizationUrlName={organizationUrlName}
                        versionId={versionId}
                        icon={icon}
                        parentsArray={parents}
                        getStructureLink={getStructureLink}
                    />
                    <ToggleButton onClick={closeDropdown}>
                        <FormattedMessage id="global.close" />
                    </ToggleButton>
                </ExpandedWrapper>
            )}
        </Wrapper>
    );
};
