import { ISponsoringData } from '../../../../entities/ActionData/ISponsoringData';
import { EProfileType } from '../../../../entities/IGlobal';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';

export const initialFormValues = (currentUserProfile): ISponsoringData => {
    return {
        formData: {
            templateKey: undefined,
            declarationName: '',
            description: '',
            information: {
                creator: {
                    firstName: currentUserProfile?.firstName,
                    lastName: currentUserProfile?.lastName,
                    jobTitle: '',
                    picture: currentUserProfile?.picture,
                    cabinet: '',
                    emailContact: '',
                    id: currentUserProfile?.id
                },
                jobTitle: currentUserProfile.jobTitle,
                legalEntity: {
                    name: currentUserProfile?.cabinet?.name,
                    picture: currentUserProfile?.cabinet?.picture,
                    id: currentUserProfile?.cabinet?.id,
                    type: EProfileType.Company
                } as ISIngleLegalEntity,
                manager: {
                    firstName: currentUserProfile?.manager?.firstName,
                    lastName: currentUserProfile?.manager?.lastName,
                    jobTitle: currentUserProfile?.manager?.jobTitle,
                    picture: currentUserProfile?.manager?.picture,
                    cabinet: '',
                    emailContact: '',
                    id: currentUserProfile?.manager?.id
                }
            }
        },
        step: 1,
        readPolicy: false
    };
};
