import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { EColumnSubTypes } from '../../../../tools/dataGridTools';

const OrderType = styled.div`
    display: flex;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    background-color: ${colorStack.whiteGrey};
    justify-content: center;
    align-items: center;
    font-weight: 700;
    ${fontSizeAndHeight[20]}
`;

const NumberContainer = styled.span`
    display: inline-flex;
    gap: 0.5rem;
`;

interface IProps {
    subType?: EColumnSubTypes;
}

export const NumberType: FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <>
            {props?.subType === EColumnSubTypes.Order ? (
                <OrderType>{props.children}</OrderType>
            ) : (
                <NumberContainer>{props.children}</NumberContainer>
            )}
        </>
    );
};