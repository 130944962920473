import React from 'react';

import { Icon } from '../../../entities/IGlobal';

export const KeyDates = (props: Icon) => (
    <svg width={props.width} height={props.height} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.009 512.009">
        <g>
            <g>
                <g>
                    <path d="M495.617,100.284c-9.052,0-16.383,7.331-16.383,16.383v351.865H32.765V85.153h53.799c9.043,0,16.383-7.331,16.383-16.383
           c0-9.052-7.331-16.383-16.383-16.383H16.383C7.339,52.387,0,59.718,0,68.77v416.144c0,9.043,7.339,16.374,16.383,16.374h479.234
           c9.043,0,16.383-7.331,16.392-16.374V116.667C512.009,107.615,504.669,100.284,495.617,100.284z"/>
                    <path d="M429.11,85.153h66.507c9.043,0,16.392-7.331,16.392-16.383c0-9.052-7.339-16.383-16.392-16.383H429.11
           c-9.052,0-16.383,7.331-16.383,16.383C412.727,77.822,420.058,85.153,429.11,85.153z"/>
                    <path d="M186.484,85.153h141.427c9.052,0,16.383-7.331,16.383-16.383c0-9.052-7.331-16.383-16.383-16.383H186.484
           c-9.043,0-16.383,7.331-16.383,16.383C170.102,77.822,177.432,85.153,186.484,85.153z"/>
                    <path d="M136.813,122.56c9.043,0,16.383-7.339,16.383-16.383V27.103c0-9.052-7.339-16.383-16.383-16.383
           s-16.383,7.331-16.383,16.383v79.074C120.43,115.229,127.76,122.56,136.813,122.56z"/>
                    <path d="M378.462,122.56c9.043,0,16.374-7.339,16.383-16.383V27.103c0-9.052-7.331-16.383-16.383-16.383
           c-9.052,0-16.392,7.331-16.392,16.383v79.074C362.071,115.229,369.41,122.56,378.462,122.56z"/>
                    <path d="M134.23,247.321c-3.026,9.381,0.107,19.489,8.591,27.76l37.327,36.369l-8.813,51.456
           c-1.597,9.292,0.302,17.714,5.343,23.704c7.508,8.928,20.616,10.836,33.129,4.242l46.202-24.29l46.184,24.299
           c5.059,2.654,10.099,4.003,14.989,3.994c7.215,0,13.658-2.92,18.149-8.245c5.05-5.99,6.94-14.404,5.343-23.695l-8.821-51.456
           l37.371-36.395c8.466-8.271,11.581-18.388,8.555-27.76c-3.035-9.372-11.502-15.753-23.287-17.51l-51.607-7.473l-23.092-46.779
           c-5.245-10.632-13.924-16.738-23.793-16.738c-9.869,0-18.53,6.097-23.775,16.729l-23.092,46.796l-51.669,7.472
           C145.723,231.559,137.256,237.931,134.23,247.321z M222.356,253.533c5.343-0.772,9.966-4.127,12.354-8.972l21.317-43.113
           l21.282,43.122c2.387,4.837,7.002,8.191,12.354,8.963l47.586,6.931l-34.434,33.52c-3.869,3.772-5.635,9.194-4.721,14.519
           l8.094,47.427l-42.554-22.382c-2.387-1.26-5.005-1.881-7.623-1.881s-5.245,0.621-7.623,1.873l-42.599,22.355l8.12-47.4
           c0.914-5.316-0.852-10.738-4.712-14.501l-34.407-33.582L222.356,253.533z"/>
                </g>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

);