import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { fontSize } from '../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../styleHelpers/colors';

const Title = styled.h3`
    font-size: ${fontSize[20]};
    font-weight: 700;
    margin: 1.5rem 0 0 0;
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.font?.secondary}, sans-serif;
    color: ${colorStack.label};
    a {
        color: ${colorStack.label};
    }
`;

interface IArticleTitleProps {
    linkTo?: string;
    title: ReactNode;
    id?: string;
}

export const ArticleTitle: FC<React.PropsWithChildren<IArticleTitleProps>> = ({ linkTo, title, id, children }) => {

    return (
        <Title id={id}>
            {!linkTo ? (
                <span data-diff-title>
                    {title}
                </span>
            ) : (
                <Link data-diff-title to={linkTo}>
                    {title}
                </Link>
            )}
            {children}
        </Title>
    );
};
