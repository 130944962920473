// w:pPr.w:numPr.w:numId.@w:val

export enum EStyleType {
    NumberingAbstract = 'NumberingAbstract',
    NumberingNormal = 'NumberingNormal',
    ParagraphProperties = 'ParagraphProperties',
    RunProperties = 'RunProperties',
    Font = 'Font',
    '_fonts-preamble' = '_fonts-preamble'
}

export interface IILvlParams {
    '@w:ilvl'?: string;
    '@w:tplc'?: string;
    'w:numFmt.@w:val'?: string;
    'w:lvlText.@w:val'?: string;
    'w:lvlJc.@w:val'?: string;
    'w:pPr.w:ind.@w:left'?: string;
    'w:pPr.w:ind.@w:hanging'?: string;
    'w:rPr.w:rFonts.@w:ascii'?: string;
    'w:rPr.w:rFonts.@w:eastAsia'?: string;
    'w:rPr.w:rFonts.@w:hAnsi'?: string;
    'w:rPr.w:rFonts.@w:cs'?: string;
    'w:rPr.w:rFonts.@w:hint'?: string;
}

export interface ISGStyleRelatedObject {
    commonLevelOrVersionId: string;
    id: string;
}

export interface ISGStylesRoot {
    id: string;
    relatedObjects: ISGStyleRelatedObject[];
    styles: ISGStyle[];
}

export interface ISGStyleParameters {
    'w:pStyle.@w:val'?: string;
    'w:pStyle.@xmlns:w'?: string;
    'w:rPr.@xmlns:w'?: string;
    'w:rPr.w:rFonts.@w:eastAsia'?: string;
    'w:rPr.w:lang.@w:eastAsia'?: string;
    'w:numPr.w:ilvl.@w:val'?: string;
    'w:numPr.w:numId.@w:val'?: string;
    'w:tblHeader.@xmlns:w'?: string;
    'w:gridSpan.@w:val'?: string;
    'w:b.@xmlns:w'?: string;
    'w:u.@xmlns:w'?: string;
    'w:u.@w:val'?: string;
    'w:hideMark.@xmlns:w'?: string;
    'w:tcBorders.w:bottom.@w:color'?: string;
    'w:tcBorders.w:left.@w:color'?: string;
    'w:tcBorders.w:right.@w:color'?: string;
    'w:tcBorders.w:top.@w:color'?: string;
    'w:tcBorders.w:bottom.@w:val'?: string;
    'w:tcBorders.w:left.@w:val'?: string;
    'w:tcBorders.w:right.@w:val'?: string;
    'w:tcBorders.w:top.@w:val'?: string;
    'w:tblBorders.w:bottom.@w:color'?: string;
    'w:tblBorders.w:bottom.@w:val'?: string;
    'w:tblBorders.w:left.@w:color'?: string;
    'w:tblBorders.w:left.@w:val'?: string;
    'w:tblBorders.w:right.@w:color'?: string;
    'w:tblBorders.w:right.@w:val'?: string;
    'w:tblBorders.w:top.@w:color'?: string;
    'w:tblBorders.w:top.@w:val'?: string;
    'w:tblStyle.@w:val'?: string;
    'w:tblPr.w:tblBorders.w:bottom.@w:color'?: string;
    'w:tblPr.w:tblBorders.w:bottom.@w:val'?: string;
    'w:tblPr.w:tblBorders.w:left.@w:color'?: string;
    'w:tblPr.w:tblBorders.w:left.@w:val'?: string;
    'w:tblPr.w:tblBorders.w:right.@w:color'?: string;
    'w:tblPr.w:tblBorders.w:right.@w:val'?: string;
    'w:tblPr.w:tblBorders.w:top.@w:color'?: string;
    'w:tblPr.w:tblBorders.w:top.@w:val'?: string;
    'w:rPr.w:color.@w:val'?: string;
    'w:color.@w:val'?: string;
    'w:pBdr.w:bottom.@w:sz'?: string;
    'w:pBdr.w:left.@w:sz'?: string;
    'w:pBdr.w:right.@w:sz'?: string;
    'w:pBdr.w:top.@w:sz'?: string;
    'w:pBdr.w:bottom.@w:val'?: string;
    'w:pBdr.w:left.@w:val'?: string;
    'w:pBdr.w:right.@w:val'?: string;
    'w:pBdr.w:top.@w:val'?: string;
    'w:pBdr.w:bottom.@w:w:color'?: string;
    'w:pBdr.w:left.@w:w:color'?: string;
    'w:pBdr.w:right.@w:w:color'?: string;
    'w:pBdr.w:top.@w:w:color'?: string;
    'w:jc.@w:val'?: string;
    'w:shd.@w:fill'?: string;
    'w:textDirection.@w:val'?: string;
    'FRONTEND_Hyperlink'?: string;
    'w:vMerge.@w:val'?: string;
    'w:vMerge.@xmlns:w'?: string;
    'vMergeCount'?: string;
    'v:shape.@style'?: string;
}

export interface ISGStyle {
    id: string;
    name: string;
    documentLevel: string;
    type: EStyleType;
    parameters: ISGStyleParameters;
    children: {
        'w:lvl'?: {
            parameters?: IILvlParams[]
        }
    };
    officeStyleJson: {
        [key: string]: string;
    };
}

export interface IDocumentationStyle {
    id: string;
    objectId: string;
    objectType: string;
    styles: ISGStyle[];
}

export enum EContentObjectType {
    Text = 10,
    Table = 20,
    TableRow = 21,
    TableCell = 22,
    Image = 30,
    SmartArt = 40,
    Equation = 50,
    Hyperlink = 60,
    ReferenceLink = 62,
    Bookmark = 61,
    CustomReference = 62,
    Attachment = 70,
    Note = 80,
    FootnoteReference = 110,
    EndnoteReference = 120,
    BookmarkStart = 130,
    BookmarkEnd = 140,
    SectionProperties = 150,
    DiagramData = 160,
    DiagramColors = 161,
    DiagramStyles = 162,
    DiagramLayoutDefinitions = 163,
    DiagramPersistLayouts = 164,
    DiagramReference = 165,
    SdtBlock = 170,
    SdtContentBlock = 171
}

export enum EOfficeXmlType {
    Paragraph = 10,
    Run = 11,
    InsertedRun = 12,
    DeletedRun = 13,
    Drawing = 20,
    Hyperlink = 30,
    CustomReference = 31,
    EndnoteReference = 40,
    FootnoteReference = 50,
    Table = 60,
    TableRow = 61,
    TableCell = 62,
    BookmarkStart = 70,
    BookmarkEnd = 80,
    SectionProperties = 90,
    DiagramData = 100,
    DiagramColors = 101,
    DiagramStyles = 102,
    DiagramLayoutDefinitions = 103,
    DiagramPersistLayouts = 104,
    DiagramReference = 105,
    SdtBlock = 110,
    SdtContentBlock = 111,
    Attachment = 120
}

export enum EStyleRef {
    NormalNum = 'w:pPr.w:numPr.w:numId.@w:val',
    AbstractNum = 'w:abstractNumId.@w:val'
}

export interface ISGBookStyles {
    byId: {
        [id: string]: string;
    };
    byName: {
        [id: string]: string;
    };
    allStyles: {
        [idAndName: string]: ISGStyle;
    };
}

export interface ISGContentReference {
    DestinationObjectType: 'StructureLevel' | 'Article';
    DestinationObjectId: string;
    ReferenceId: string;
}

export interface IPrBdrObject {
    bottom: string;
    left: string;
    right: string;
    top: string;
}

export type SgAlignTo = 'left' | 'right';

export interface VStyle {
    width: string;
    height: string;
}
