import React, { FC, useCallback, RefObject, useState, useRef, useLayoutEffect, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { IStructureResponse, ITableOfContentsNode, EBranchStatus, ISogeArticle, IReferenceText } from '../../../entities/ISoge';
import { getSogeDocUrl } from '../../../tools/legalDocTools/sogeTools';
import { useTocItemNumberingStyle } from '../../../tools/legalDocTools/sogeHooks';
import { getStructure } from '../../../actions/sogeActions';
import { IState } from '../../../reducers';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { useOrganization } from '../../../tools/organizationHooks';
import { useUserRolePermissions } from '../../../tools/permissionHooks';
import { LegalDocArticle } from './LegalDocArticle';
import { TreeView, ITreeExpandIcon, ITreePathItem } from '../../Common/TreeView/TreeView';
import { StructureDetailsDropdown } from './StructureDetailsDropdown';
import { Tag } from '../../Common/Tag/Tag';
import { DropdownSpinnerWrapper } from '../Components/Styles';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';
import { LevelReferenceText } from './LevelReferenceText/LevelReferenceText';
import { MetadataPopup } from './common/MetadataPopup';
import { SectionTitle } from './common/SectionTitle';
import { fontSize } from '../../../styleHelpers/fontSizes';

const TocWrapper = styled.div`
    padding: 0 1.5rem;
    margin: 1rem 0 3rem 0;
`;

const LinkWrapper = styled.span`
    display: inline-flex;
    align-items: center;
    width: 100%;
    font-size: ${fontSize[20]};
    font-family: ${({ theme }) => theme.font?.secondary}, sans-serif;
`;

const OverwriteWarning = styled.div`
    display: flex;
    span {
        font-weight: 500;
    }
`;

const TagWrapper = styled.div`
    margin: 0 0 0 .5rem;
`;

const ContentWrapper = styled.div<{ bgImage?: string }>`
    position: relative;
    max-width: 860px;
    margin: 0 auto;
    ${({ bgImage }) => !!bgImage && css`
        &:before {
            content: '';
            position: absolute;
            right: -12px;
            top: 0;
            bottom: 0;
            width: 40%;
            background-image: url(${bgImage});
            background-size: contain;
            background-repeat: no-repeat;
        }
    `}
`;

const Heading = styled.span<{ nonClickable?: boolean }>`
    ${props => props.nonClickable ?
        css`
            font-weight: 400;
            cursor: text;
            user-select: text;
        ` : css`
            font-weight: 600;
        `
    }
    display: inline-block;
    text-align: justify;
`;

interface ILevelMetadata {
    referenceText: IReferenceText;
    loaded: boolean;
}

export interface IStructureLevelCommonProps {
    organizationUrlName: string;
    versionId: string;
    currentDownloadingElementId?: string;
    onStructureDetailsClick?(commonLevelId: string): void;
    onRootStructureDetailsClick?(commonLevelId: string): void;
    onArticleDetailsClick?(articleId: string): void;
    getStructureLink?(item: ITableOfContentsNode): string;
    getArticleUrl?(article: ISogeArticle): string;
    onStructureDownloadClick?(levelId: string, structureLevelRootCommonLevelId: string, levelName: string);
    onArticleDownloadClick?(articleId: string, structureLevelRootCommonLevelId: string, levelName: string);
    onCopyUrlClick?(documentVersionName: string, commonLevelId: string);
}

interface IStructureLevelProps extends IStructureLevelCommonProps {
    structureLevel: IStructureResponse;
    structureId: string;
    disableStructureLinks?: boolean;
    branchStatus?: EBranchStatus;
    isConsultation?: boolean;
    isCurrentVersion?: boolean;
    isDiffView?: boolean;
    isNormsManagement?: boolean;
    onElementIntersection?(name: string, id: string, commonIdPath: string[]);
}

export const StructureLevel: FC<React.PropsWithChildren<IStructureLevelProps>> = ({ structureLevel, organizationUrlName, versionId, structureId, disableStructureLinks, branchStatus, currentDownloadingElementId, isConsultation, isCurrentVersion, isDiffView, isNormsManagement, onStructureDetailsClick, onArticleDetailsClick, onRootStructureDetailsClick, onStructureDownloadClick, getStructureLink, getArticleUrl, onArticleDownloadClick, onCopyUrlClick, onElementIntersection }) => {
    const dispatch = useDispatch();
    const { documentationId, documentationLcid } = useSelector<IState, ISogeReducer>(state => state.soge);
    const [levelMetadata, setLevelMetadata] = useState<ILevelMetadata>(undefined);
    const isReferenceText = !!(isConsultation && structureLevel?.referenceText);
    const [triggerIntersectionObserver, setTriggerIntersectionObserver] = useState<boolean>(false);
    const observer = useRef<IntersectionObserver>();
    const { currentOrganization } = useOrganization(organizationUrlName);
    const {isUserAuthorized} = useUserRolePermissions(currentOrganization?.myRole?.role);
    const { tocItemNumberingStyle } = useTocItemNumberingStyle();
    const [isLevelCollapsable, setIsLevelCollapsable ] = useState<boolean>(false);

    const getChildLevelDetails = useCallback((commonLevelId: string) => {
        setLevelMetadata({ referenceText: undefined, loaded: false });
        dispatch(getStructure(documentationId, commonLevelId, versionId, documentationLcid)).then(childStructure => {
            setLevelMetadata({ referenceText: childStructure?.referenceText, loaded: true });
        });
    }, [documentationId, versionId]);

    const getLevelDetails = useCallback(() => {
        setLevelMetadata({ referenceText: structureLevel?.referenceText, loaded: true });
    }, [structureLevel?.referenceText]);

    const renderTocItem = useCallback((item: ITableOfContentsNode, level: number, itemRef: RefObject<any>, renderPath: ITreePathItem[], isActive?: boolean, expandChildren?: () => void, hasChildren?: boolean, isOpen?: boolean) => (
        <LinkWrapper
            data-diff-structure
            data-common-id={item.commonLevelId}
            className={cn({ 'structure-children-observer': isOpen })}
            data-item-id={item.commonLevelId}
            data-item-name={tocItemNumberingStyle(item.name)}
            data-render-path={[
                ...(structureLevel?.parents || []).map(parent => parent.commonLevelId),
                ...renderPath.map(pathItem => pathItem.commonLevelId)
            ].join(',')}
        >
            {(disableStructureLinks || !item?.children?.length) ? (
                <Heading data-diff-title nonClickable>{tocItemNumberingStyle(item.name)}</Heading>
            ) : (
                <Link to={getStructureLink?.(item) || getSogeDocUrl(organizationUrlName, item.commonLevelId, (isCurrentVersion && isConsultation ? 'current' : versionId))}>
                    <Heading data-diff-title>{tocItemNumberingStyle(item.name)}</Heading>
                </Link>
            )}
            {(onStructureDetailsClick || onStructureDownloadClick || onCopyUrlClick) && (
                <StructureDetailsDropdown
                    commonElementId={item.commonLevelId}
                    isAuthorized={isUserAuthorized}
                    onDetailsClick={onStructureDetailsClick}
                    onDownloadClick={onStructureDownloadClick && (() => onStructureDownloadClick(item.commonLevelId, structureLevel?.parents?.[0]?.commonLevelId, item.name))}
                    onCopyUrlClick={onCopyUrlClick}
                    onMetadataClick={!isReferenceText && getChildLevelDetails}
                    documentVersionName={structureLevel.normativeDocumentationVersion?.name}
                />
            )}
            {currentDownloadingElementId === item.commonLevelId && (
                <DropdownSpinnerWrapper>
                    <Spinner size={ESpinnerSize.SMALL} />
                </DropdownSpinnerWrapper>
            )}
        </LinkWrapper>
    ), [structureLevel, versionId, organizationUrlName, disableStructureLinks, isCurrentVersion, isConsultation, onStructureDetailsClick, getStructureLink, currentDownloadingElementId, onCopyUrlClick, getChildLevelDetails, isReferenceText]);

    useLayoutEffect(() => {
        observer.current = !!onElementIntersection && new IntersectionObserver((entries, observerObjet) => {
            entries.some(entry => {
                entry.isIntersecting && onElementIntersection(
                    entry.target.getAttribute('data-item-name'),
                    entry.target.getAttribute('data-item-id'),
                    (entry.target.getAttribute('data-render-path') || '').split(',')
                );
                return entry.isIntersecting;
            });
        }, {
            threshold: .35
        });

        observer.current && Array.from(document.querySelectorAll('.structure-children-observer')).forEach(element => {
            observer.current.observe(element);
        });

        if (structureLevel?.children?.length === 0 && !!onElementIntersection) {
            onElementIntersection(structureLevel.commonLevelId, structureLevel.id, [structureLevel.commonLevelId]);
        }

        return () => onElementIntersection && observer.current?.disconnect?.();
    }, [structureLevel, onElementIntersection, triggerIntersectionObserver]);

    const onExpandChange = useCallback(() => setTriggerIntersectionObserver(state => !state), []);

    const currentRenderPath = useMemo(() => [...(structureLevel?.parents || []), structureLevel]
        .map(parent => parent.commonLevelId)
        .join(',')
    , []);

    useEffect(() => {
        setIsLevelCollapsable(structureLevel?.shouldCollapseLevelThreeContent);
    }, [structureLevel?.shouldCollapseLevelThreeContent]);

    return (
        <>
            {branchStatus &&
                <OverwriteWarning>
                    <FormattedMessage id="norms.currentContentOfTheBranch" />
                </OverwriteWarning>
            }
            {isReferenceText && (
                <LevelReferenceText
                    referenceText={structureLevel.referenceText}
                    normativeDocumentationVersion={structureLevel.normativeDocumentationVersion}
                    structureLevelName={structureLevel?.name}
                    commonLevelId={structureLevel?.commonLevelId}
                    currentDownloadingElementId={currentDownloadingElementId}
                    isAuthorized={isUserAuthorized}
                    onStructureDownloadClick={onStructureDownloadClick && (() => onStructureDownloadClick(structureLevel?.commonLevelId, structureLevel?.parents?.[0].commonLevelId, structureLevel?.name))}
                    onCopyUrlClick={onCopyUrlClick}
                    onMetadataClick={getLevelDetails}
                    renderPath={currentRenderPath}
                />
            )}
            {structureLevel?.name && structureLevel?.commonLevelId && !isReferenceText && (
                <SectionTitle title={tocItemNumberingStyle(structureLevel?.name)} commonLevelId={structureLevel?.commonLevelId} fixedMaxWidth renderPath={currentRenderPath}>
                    <TagWrapper>
                        {branchStatus === EBranchStatus.Active && (
                            <Tag color="activeBlue" noBorder nomargin sm>
                                <FormattedMessage id="norms.status.active" />
                            </Tag>
                        )}
                        {branchStatus === EBranchStatus.Approved && (
                            <Tag color="successGreen" noBorder nomargin sm>
                                <FormattedMessage id="norms.status.approved" />
                            </Tag>
                        )}
                        {branchStatus === EBranchStatus.Rejected && (
                            <Tag color="gray" noBorder nomargin sm>
                                <FormattedMessage id="norms.status.rejected" />
                            </Tag>
                        )}
                    </TagWrapper>
                    {(onRootStructureDetailsClick || onStructureDownloadClick || onCopyUrlClick) && (
                        <StructureDetailsDropdown
                            commonElementId={structureLevel?.commonLevelId}
                            isAuthorized={isUserAuthorized}
                            onDetailsClick={onRootStructureDetailsClick}
                            onDownloadClick={onStructureDownloadClick && (() => onStructureDownloadClick(structureLevel?.commonLevelId, structureLevel?.parents?.[0]?.commonLevelId, structureLevel?.name))}
                            onCopyUrlClick={onCopyUrlClick}
                            documentVersionName={structureLevel?.normativeDocumentationVersion?.name}
                        />
                    )}
                    {currentDownloadingElementId === structureLevel?.commonLevelId && (
                        <DropdownSpinnerWrapper>
                            <Spinner size={ESpinnerSize.SMALL} />
                        </DropdownSpinnerWrapper>
                    )}
                </SectionTitle>
            )}
            <ContentWrapper>
                {(structureLevel?.articles || []).map(article => (
                    <LegalDocArticle
                        organizationUrlName={organizationUrlName}
                        key={article.id}
                        article={article}
                        structureId={structureId}
                        versionId={versionId}
                        book={structureLevel.parents?.[0]}
                        disableArticleLinks={disableStructureLinks}
                        onArticleDetailsClick={onArticleDetailsClick}
                        getArticleUrl={getArticleUrl}
                        isConsultation={isConsultation}
                        isCurrentVersion={isCurrentVersion}
                        onArticleDownloadClick={onArticleDownloadClick}
                        currentDownloadingElementId={currentDownloadingElementId}
                        onCopyUrlClick={onCopyUrlClick}
                        isDiffView={isDiffView}
                        isAuthorized={isUserAuthorized}
                        isNormsManagement={isNormsManagement}
                        noPadding
                    />
                ))}
                {!!structureLevel?.children?.length && (
                    <TocWrapper>
                        <TreeView
                            expandIcon={ITreeExpandIcon.Arrow}
                            treeData={structureLevel.children}
                            renderItem={renderTocItem}
                            additionalChildrenKey="articles"
                            autoExpandLevels={3}
                            isLevelCollapsable={isLevelCollapsable}
                            defaultExpanded={isDiffView}
                            onExpandChange={onExpandChange}
                            renderAdditional={(item: IStructureResponse, level: number, renderPath?: ITreePathItem[], isActive?: boolean) => (
                                <div
                                    className="structure-children-observer"
                                    data-item-id={item.commonLevelId}
                                    data-item-name={tocItemNumberingStyle(item.name)}
                                    data-render-path={[
                                        ...(structureLevel?.parents || []).map(parent => parent.commonLevelId),
                                        ...renderPath.map(pathItem => pathItem.commonLevelId)
                                    ].join(',')}
                                >
                                    {(item?.articles || []).map(article => (
                                        <LegalDocArticle
                                            noPadding
                                            organizationUrlName={organizationUrlName}
                                            key={article.id}
                                            article={article}
                                            structureId={structureId}
                                            versionId={versionId}
                                            book={structureLevel.parents?.[0]}
                                            disableArticleLinks={disableStructureLinks}
                                            onArticleDetailsClick={onArticleDetailsClick}
                                            getArticleUrl={getArticleUrl}
                                            isConsultation={isConsultation}
                                            isCurrentVersion={isCurrentVersion}
                                            onArticleDownloadClick={onArticleDownloadClick}
                                            currentDownloadingElementId={currentDownloadingElementId}
                                            onCopyUrlClick={onCopyUrlClick}
                                            isDiffView={isDiffView}
                                            isAuthorized={isUserAuthorized}
                                            isNormsManagement={isNormsManagement}
                                        />
                                    ))}
                                </div>
                            )}
                        />
                    </TocWrapper>
                )}
            </ContentWrapper>
            <MetadataPopup
                visible={!!levelMetadata}
                referenceText={levelMetadata?.referenceText}
                book={structureLevel?.parents?.[0]}
                organizationUrlName={organizationUrlName}
                isCurrentVersion={isCurrentVersion}
                versionId={versionId}
                loaded={levelMetadata?.loaded}
                onClose={() => setLevelMetadata(undefined)}
            />
        </>
    );
};
