import styled, { css } from 'styled-components';

import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';

export const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
        overflow-wrap: break-word;
    }
`;

export const ErrorText = styled.div<{ customColor?: string }>`
    color: ${colorStack.darkRed};
    font-size: ${fontSize[10]};
    ${props => props.customColor && css`
        color: ${props.customColor};
    `}
`;