import React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../../styleHelpers/colors';

enum EColors {
    gray = 'gray',
    lightGray = 'lightGray'
}

const SingleParagraph = styled.p<{ color: string }>`
    font-size: ${fontSize[20]};
    font-weight: normal;
    text-align: left;
    ${props => props.color === EColors.gray && css`
        color: ${colorStack.darkGrey};
    `}
    ${props => props.color === EColors.lightGray && css`
        color: ${colorStack.disabled};
    `}
`;

export const Confirm = () => {
    return (
        <div>
            <SingleParagraph color={EColors.gray}>
                <FormattedMessage id="tradeAssociation.wizard.confirm.great" />
            </SingleParagraph>
            <SingleParagraph color={EColors.gray}>
                <FormattedMessage id="tradeAssociation.wizard.confirm.confirmation" />
            </SingleParagraph>
            <SingleParagraph color={EColors.lightGray}>
                <FormattedMessage id="tradeAssociation.wizard.confirm.notificationInfo" />
            </SingleParagraph>
        </div>
    );
};