import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const Header = styled.div`
    padding: 0 1rem 1rem 1rem;
    display: flex;
    align-items: center;
`;

const HeaderText = styled.div`
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[16]};
    font-weight: 500;

    svg {
        margin-right: .5rem;
    }
`;

const RightCol = styled.div`
    margin-left: auto;
`;

interface IPageHeaderPropsBase {
    rightCol?: ReactNode;
    headerText?: ReactNode;
    leftCol?: ReactNode;
}

export const PageHeader: FC<React.PropsWithChildren<IPageHeaderPropsBase>> = props => (
    <Header>
        {props.headerText && (
            <HeaderText>
                {props.headerText}
            </HeaderText>
        )}
        {props.leftCol && (
            <div>
                {props.leftCol}
            </div>
        )}
        <RightCol>
            {props.rightCol}
        </RightCol>
    </Header>
);