import React, { FC } from 'react';
import {
    faFileAlt,
    faFileCode,
    faFileImage,
    faFileWord,
    faFilePowerpoint,
    faFileExcel,
    faFilePdf,
    faFileArchive,
    faFileMusic,
    faFileVideo,
    IconDefinition,
    faDownload
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../../../../styleHelpers/colors';

interface IIcon {
    iconDefinition: IconDefinition;
    color?: string;
}

const fileIcon = {
    iconDefinition: faFileAlt
};

const codeIcon = {
    iconDefinition: faFileCode
};

const imageIcon = {
    iconDefinition: faFileImage
};

const pdfIcon = {
    iconDefinition: faFilePdf,
    color: colorStack.darkOrange
};

const wordIcon = {
    iconDefinition: faFileWord,
    color: colorStack.blue
};

const ppIcon = {
    iconDefinition: faFilePowerpoint,
    color: colorStack.red
};

const excelIcon = {
    iconDefinition: faFileExcel,
    color: colorStack.middleGreen
};

const archiveIcon = {
    iconDefinition: faFileArchive
};

const audioIcon = {
    iconDefinition: faFileMusic
};

const videoIcon = {
    iconDefinition: faFileVideo
};

const mimeToIcon: { [key: string]: IIcon } = {
    'text/plain': fileIcon,

    'text/html': codeIcon,
    'image/svg+xml': codeIcon,
    'text/javascript': codeIcon,
    'application/json': codeIcon,
    'text/x-c++src': codeIcon,
    'text/css': codeIcon,
    'application/xml': codeIcon,
    'text/xml': codeIcon,
    'application/php': codeIcon,
    'application/java-archive': codeIcon,
    'text/x-script.phyton': codeIcon,
    'application/x-bytecode.python': codeIcon,

    'image/bmp': imageIcon,
    'image/gif': imageIcon,
    'image/jpeg': imageIcon,
    'image/png': imageIcon,
    'image/tiff': imageIcon,
    'image/x-tiff': imageIcon,
    'image/webp': imageIcon,

    'application/pdf': pdfIcon,

    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': wordIcon,
    'application/msword': wordIcon,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': wordIcon,
    'application/vnd.oasis.opendocument.text': wordIcon,
    'application/rtf': wordIcon,
    'application/x-rtf': wordIcon,
    'text/richtext': wordIcon,

    'application/powerpoint': ppIcon,
    'application/vnd.ms-powerpoint': ppIcon,
    'application/x-mspowerpoint': ppIcon,
    'application/mspowerpoint': ppIcon,
    'application/vnd.openxmlformats-officedocument.presentationml.template': ppIcon,
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ppIcon,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ppIcon,
    'application/vnd.openxmlformats-officedocument.presentationml.slide': ppIcon,
    'application/vnd.oasis.opendocument.presentation': ppIcon,

    'text/csv': excelIcon,
    'application/vnd.ms-excel': excelIcon,
    'application/vnd.oasis.opendocument.spreadsheet': excelIcon,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': excelIcon,

    'application/x-7z-compressed': archiveIcon,
    'application/zip': archiveIcon,
    'application/x-tar': archiveIcon,
    'application/x-gzip': archiveIcon,
    'application/x-rar-compressed': archiveIcon,
    'application/x-zip-compressed': archiveIcon,
    'application/vnd.rar': archiveIcon,

    'audio/mpeg3': audioIcon,
    'audio/x-mpeg-3': audioIcon,
    'audio/mp3': audioIcon,
    'audio/mpeg': audioIcon,
    'audio/mp4': audioIcon,
    'audio/x-aiff': audioIcon,
    'audio/ogg': audioIcon,
    'audio/vorbis': audioIcon,
    'audio/vnd.wav': audioIcon,
    'audio/vnd.wave,': audioIcon,
    'audio/wav,': audioIcon,
    'audio/wave,': audioIcon,
    'audio/x-wav': audioIcon,
    'audio/aac': audioIcon,

    'video/mp4': videoIcon,
    'application/x-mpegURL': videoIcon,
    'video/MP2T': videoIcon,
    'video/3gpp': videoIcon,
    'video/quicktime': videoIcon,
    'video/x-msvideo': videoIcon,
    'video/x-ms-wmv': videoIcon,
    'video/mpeg': videoIcon,
    'video/ogg': videoIcon
};

interface IFileIconProps {
    mimeType: string;
    isCustomIcon?: boolean;
}

export const FileIcon: FC<React.PropsWithChildren<IFileIconProps>> = ({ mimeType, isCustomIcon }) => {
    const icon = mimeToIcon[mimeType];
    return (
        <FontAwesomeIcon icon={isCustomIcon ? faDownload : (icon?.iconDefinition || faFileAlt)} color={icon?.color || colorStack.darkBlue} style={{fontSize: '16px'}}/>
    );
};
