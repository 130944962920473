import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { cardsShadow } from '../../../styleHelpers/mixins/shadow';

const Wrapper = styled.div`
    width: 100%;
    ${cardsShadow()};
    position: relative;
    color: ${colorStack.content};
    padding: 7px 1rem;
    > span {
        position: relative;
        z-index: 1;
    }
`;

const InnerWrapper = styled.div<{value: number}>`
    background: ${colorStack.whiteBlue};
    width: 0%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: width 0.3s;
    max-width: 100%;
    ${props => props.value && css`
        width: ${`${props.value}%`};
    `}
`;

interface IProps {
    value: number;
}
export const PercentageContent: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper>
            <span>{props.value} %</span>
            <InnerWrapper value={props.value} />
        </Wrapper>
    );
};