import styled from 'styled-components';

import boxShadow from '../../../styleHelpers/mixins/shadow';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';

export const LinkImage = styled.div<{ image: string }>`
    ${boxShadow()};
    border-radius: 4px;
    padding: .5rem;
    display: block;
    width: 100%;
    overflow: hidden;
    background-image: linear-gradient(to bottom, rgba(29, 40, 75, 0) 65%, rgba(12, 12, 12, 0.65) 100%), url(${props => props.image});
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
    &:before {
        background-image: linear-gradient(to bottom, rgba(29, 40, 75, 0.4) 20%, rgb(10, 12, 21) 100%), url(${props => props.image});
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
        transition: opacity .2s ease-in
    }
    &:hover {
        &:before {
            opacity: 1;
        }
        span {
            color: ${colorStack.lightBlue};
        }
    }
    span {
        z-index: 2;
        margin: auto auto .4rem auto;
        font-weight: 500;
        color: ${colorStack.white};
        font-size: ${fontSize[13]};
        transition: color .2s ease-in;
    }
`;
