import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ELcid } from '../../../entities/ILanguage';
import { languageIcons, languageLabels } from '../../../tools/languageTools';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { StatusTag } from '../Components/StatusTag';

export const LangOptionButton = styled.button`
    padding: 0.5rem 0.6rem;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    font-size: ${fontSize[13]};
    font-family: 'Roboto', sans-serif;
    color: ${colorStack.darkGrey};
    font-weight: 400;
    display: flex;
    align-items: center;

    > img {
        margin-right: 0.4rem;
        width: 20px;
    }
`;

export const VersionTag = styled(StatusTag)`
    margin-left: .5rem;
    span {
        font-size: ${fontSize[10]};
    }
`;

const FeatureBranchContainer = styled.div`
    display: flex;
    align-items: center;
`;

const FeatureBranchName = styled.div`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 .5rem 0 .5rem;
    float: left;
    &:before{
        content: '('
    }
    &:after {
        content: ')'
    }
`;

interface ISogeLangOptionProps {
    lcid: ELcid;
    versionId?: string;
    versionName?: string;
    isBranchPreview?: boolean;
    isMainBranch?: boolean;
    status?: number;
    featureBranchId?: string;
    featureBranchName?: string;
    onClick?(lcid: ELcid, versionId?: string, versionName?: string, featureBranchId?: string);
}

export const SogeLangOption: FC<React.PropsWithChildren<ISogeLangOptionProps>> = ({ lcid, onClick, versionId, versionName, isBranchPreview, status, isMainBranch, featureBranchId, featureBranchName }) => {
    const onButtonClick = useCallback(() => {
        onClick?.(lcid, versionId, versionName, featureBranchId);
    }, [lcid, versionId, versionName, featureBranchId]);

    return (
        <LangOptionButton onClick={onButtonClick}>
            {languageIcons[lcid] && <img src={languageIcons[lcid]} />}
            <FormattedMessage id={languageLabels[lcid].id} />
            {isMainBranch && (
                <FeatureBranchName>
                    <FormattedMessage id="norms.mainBranch" />
                </FeatureBranchName>
            )}
            {isBranchPreview && (
                <FeatureBranchContainer>
                    <FeatureBranchName>
                        {featureBranchName}
                    </FeatureBranchName>
                    <VersionTag branchStatus={status} />
                </FeatureBranchContainer>
            )}
        </LangOptionButton>
    );
};