import React, { forwardRef, PropsWithChildren } from 'react';
import Scrollbars from 'react-custom-scrollbars';

interface IOrganizationsScrollerProps {
    scrollerDisabled?: boolean;
}

export const OrganizationsScroller = forwardRef<HTMLDivElement, PropsWithChildren<IOrganizationsScrollerProps>>(({ scrollerDisabled, children }, ref) => {
    return scrollerDisabled ? (
        <div ref={ref} key={'custom'}>{children}</div>
    ) : (
        <Scrollbars>{children}</Scrollbars>
    );
});
