import { IReferencial } from '../../entities/IGlobal';

export const SET_REFERENTIAL = 'SET_REFERENTIAL';
export const SET_CURRENCY_RATIO = 'SET_CURRENCY_RATIO';
export const SET_PROGRESS_DATA = 'SET_PROGRESS_DATA';

export interface IGlobalTypes {
    SET_REFERENTIAL: {
        referentialsList: {
            [key: string]: {
                [key: string]: IReferencial[];
            }
        }
    }
    SET_CURRENCY_RATIO: {
        currencyRatio: {
            [key: string]: {
                [key: string]: number
            }
        }
    }
    SET_PROGRESS_DATA: {
        progressData: number;
    }
}