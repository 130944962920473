import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { isFontAwesomeIcon } from '../../../tools/generalTools';
import { useEscKey } from '../../../tools/keyboardTools';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { media } from '../../../styleHelpers/breakpoint';
import { ESpinnerSize, Spinner } from '../Spinner/Spinner';
import { Button, EButtonTypeSchema } from '../Buttons/NewButton';
import { EIconButtonTypeSchema, IconButton } from '../Buttons/IconButton';

interface IWrapperProps {
    disableMaxWidth?: boolean;
    noScroller?: boolean;
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${rgba(colorStack.black, .6)};
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    .popup-inner {
        border-radius: 4px;
        background: ${colorStack.white};
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        word-break: break-word;
    }
    .text {
        color: ${colorStack.darkBlue};
        font-size: ${fontSize[13]};
        margin: 0 0 5px 0;
    }
    .center {
        text-align: center;
    }
    .title {
        color: ${colorStack.darkBlue};
        font-size: ${fontSize[16]};
        font-weight: 500;
        > svg {
            margin-right: .5rem;
        }
    }
    .button-box {
        margin: 15px 0 0 0;
        display: flex;
        align-items: center;
    }
`;

const Scroller = styled.div<IWrapperProps>`
    padding: 1.5rem;
    max-height: 100%;
    overflow-y: auto;
    ${({ disableMaxWidth }) => !disableMaxWidth && css`
        max-width: 90%;
        ${media.desktop`
            max-width: 40%;
        `}
    `}
    ${({ noScroller }) => noScroller && css`
        overflow: visible;
    `}
`;

const Title = styled.div<{ withBorder: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1rem 0;
    ${({ withBorder }) => withBorder && css`
        border-bottom: 1px solid ${colorStack.ligthGrey};
        padding-bottom: 0.5rem;
    `}
`;

const SpinnerWrapper = styled.div`
    margin: 0 0 0 .5rem;
`;

const BodyContainer = styled.span`
    display:inline-flex;
`;

interface IPopupProps {
    isVisible: boolean;
    text?: string | React.ReactNode;
    confirmButtnonText?: string | React.ReactNode;
    confirmHardButtonText?: string | React.ReactNode;
    confirmForceRenameButtonText?: string | React.ReactNode;
    cancelButtonText?: string | React.ReactNode;
    textCenter?: boolean;
    title?: string | React.ReactNode;
    confirmIsSubmit?: boolean;
    confirmDisabled?: boolean;
    cancelDisabled?: boolean;
    loading?: boolean;
    disableMaxWidth?: boolean;
    noButtons?: boolean;
    titleBorder?: boolean;
    ico?: React.ReactNode | IconDefinition;
    disableDefaultStyles?: boolean;
    noScroller?: boolean;
    picto?: React.ReactNode;
    isHardRenameVisible?: boolean;
    confirmAction?();
    confirmHardAction?();
    cancelAction?();
}

export const Popup: FC<React.PropsWithChildren<IPopupProps>> = (props) => {
    useEscKey(!props.cancelDisabled && props.isVisible && props.cancelAction);

    return (
        <>
            {props.isVisible &&
                <Wrapper>
                    <Scroller disableMaxWidth={props.disableMaxWidth} noScroller={props.noScroller}>
                        <div className={props.disableDefaultStyles ? '' : 'popup-inner'}>
                            {props.title &&
                                <Title withBorder={props.titleBorder}>
                                    <span className="title">
                                        {!!props.ico && (
                                            <>
                                                {isFontAwesomeIcon(props.ico)
                                                    ? <FontAwesomeIcon icon={props.ico} />
                                                    : props.ico
                                                }
                                            </>
                                        )}
                                        {props.title}
                                    </span>
                                    {props.cancelAction &&
                                        <IconButton typeSchema={EIconButtonTypeSchema.TERTIARY} icon={faTimes} onClick={props.cancelAction} />
                                    }
                                </Title>
                            }
                            {!!props.text && !props.picto ? (
                                <span className={classnames('text', { 'center': props.textCenter })}>
                                    {props.text}
                                </span>
                            ) : (
                                <BodyContainer>
                                    <div>{props?.picto}</div>
                                    <span>{props.text}</span>
                                </BodyContainer>
                            )}
                            {props.children}
                            {!props.noButtons && (
                                <div className="button-box">
                                    {props.cancelAction &&
                                        <Button
                                            typeSchema={EButtonTypeSchema.TEXT}
                                            disabled={props.cancelDisabled}
                                            onClick={props.cancelAction}
                                            type="button"
                                        >
                                            {props.cancelButtonText || <FormattedMessage id="global.cancel" />}
                                        </Button>
                                    }
                                    {(props.confirmAction || props.confirmIsSubmit) && !props?.isHardRenameVisible &&
                                        <Button
                                            disabled={props.confirmDisabled}
                                            onClick={props.confirmAction}
                                            type={props.confirmIsSubmit ? 'submit' : 'button'}
                                        >
                                            {props.confirmButtnonText || <FormattedMessage id="global.confirm" />}
                                        </Button>
                                    }
                                    {props.confirmHardAction &&
                                        <Button
                                            disabled={props.confirmDisabled}
                                            onClick={props.confirmHardAction}
                                            type={props.confirmIsSubmit ? 'submit' : 'button'}
                                        >
                                            {props.confirmHardButtonText || <FormattedMessage id="global.confirm" />}
                                        </Button>
                                    }
                                    {props?.isHardRenameVisible &&
                                        <Button
                                            disabled={props.confirmDisabled}
                                            type={props.confirmIsSubmit ? 'submit' : 'button'}
                                        >
                                            {props.confirmForceRenameButtonText || <FormattedMessage id="global.confirm" />}
                                        </Button>
                                    }
                                    {props.loading && (
                                        <SpinnerWrapper>
                                            <Spinner size={ESpinnerSize.SMALL} />
                                        </SpinnerWrapper>
                                    )}
                                </div>
                            )}
                        </div>
                    </Scroller>
                </Wrapper>
            }
        </>
    );
};
