import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { Avatar } from '../Avatar/Avatar';
import { EProfileType } from '../../../entities/IGlobal';

const PersonRow = styled.div<{ fullWidth?: boolean; onlyAvatar?: boolean }>`
    ${props => (!props.fullWidth && !props.onlyAvatar) && css`
        padding: 0.4rem;
        margin-bottom: 2px;
    `}
    display: flex;
    align-items: center;
    a {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        ${props => !props.onlyAvatar && css`
            margin-right: 2rem;
        `}
    }
    > div {
        width: 100%;
    }
`;

const UserImage = styled.div<{ onlyAvatar?: boolean }>`
    margin-right: 0.5rem;
    ${props => props.onlyAvatar && css`
        margin-right: 1rem;
    `}
`;

const PersonName = styled.div`
    font-weight: 600;
    color: ${colorStack.label};
    margin: 0 1rem 0 0;
    ${fontSizeAndHeight[13]};
`;

const Tooltip = styled.div<{ position: 'left' | 'right', top: number, left: number }>`
    ${boxShadow()};
    position: fixed;
    border: 1px solid ${colorStack.ligthGrey};
    ${props => props.top && css`
        top: ${`${props.top + 40}px`};
    `};
    ${props => (props.left && props.position === 'left') && css`
        left: ${`${props.left}px`};
    `};
    background: ${colorStack.white};
    padding: 1rem;
    width: 350px;
    z-index: 99;
    ${props => (props.left && props.position === 'right') && css`
        left: ${`${props.left - 350}px`};
    `}
`;

const TopSection = styled.div`
    display: flex;
`;

const Meta = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: .5rem 0 0 0;
    span {
        ${fontSizeAndHeight[10]};
        color: ${colorStack.disabled};
        font-weight: 400;
    }
    a {
        text-decoration: none;
    }
`;

interface IProfilesSummaryProps {
    type: EProfileType;
    profile: {
        picture: string;
        firstName: string;
        id: string;
        lastName?: string;
        jobTitle?: string;
        department?: {
            name: string;
            id: string;
        };
        cabinet?: any;
    };
    email?: string;
    fullWidth?: boolean;
    onlyAvatar?: boolean;
    preventRedirection?: boolean
}

const ProfileSummaryDetails: FC<IProfilesSummaryProps> = props => {
    return (
        <UserImage onlyAvatar={props.onlyAvatar}>
            <Avatar
                picture={props.profile?.picture}
                firstName={props.profile?.firstName}
                lastName={props.profile?.lastName}
                id={props.profile?.id}
                type={props.type}
                preventRedirection={props?.preventRedirection}
                extended={!props.onlyAvatar}
            />
        </UserImage>
    );
};

export const ProfilesSummary: FC<IProfilesSummaryProps> = props => {
    return (
        <>
            {props.profile &&
                <PersonRow fullWidth={props.fullWidth} onlyAvatar={props.onlyAvatar}>
                    <ProfileSummaryDetails
                        type={props?.type}
                        onlyAvatar={props?.onlyAvatar}
                        preventRedirection={props?.preventRedirection}
                        fullWidth={props?.fullWidth}
                        profile={{
                            picture: props?.profile?.picture,
                            id: props?.profile?.id,
                            firstName: props?.profile?.firstName,
                            jobTitle: props?.profile?.jobTitle,
                            lastName: props?.profile?.lastName,
                            department: props?.profile?.department,
                            cabinet: props?.profile?.cabinet
                        }}
                        email={props.email}
                    />
                </PersonRow>
            }
        </>
    );
};