import { useMemo, useCallback, useEffect, useState } from 'react';
import { IntlShape, MessageDescriptor } from 'react-intl';

import { EOrganizationRole } from '../entities/IOrganization';

export const useRoleTranslations = (intl: IntlShape) => {
    const translationsObject = useMemo(() => {
        const translations: { [key: string]: MessageDescriptor } = {
            'Organizations.OutsideCollaborator': { id: 'permissions.outsideCollaborator'},
            'Organizations.Member': { id: 'permissions.organization.member' },
            'Organizations.Manager': { id: 'permissions.manager' },
            'Organizations.Owner': { id: 'permissions.owner' },
            'Clusters.Member': { id: 'permissions.member' },
            'Clusters.Editor': { id: 'permissions.editor' },
            'Clusters.Owner': { id: 'permissions.owner' },
            'Cluster.Organization.Member': { id: 'permissions.member' },
            'Cluster.Organization.Owner': { id: 'permissions.owner' },
            'Platform.Owner': { id: 'permissions.owner' },
            'Platform.Member': { id: 'permissions.editor' },
            'Platform.Inactive': { id: 'permissions.inactive' },
            'Platform.Guest': { id: 'permissions.member' },
            'Groups.Owner': { id: 'permissions.owner' },
            'Groups.Member': { id: 'permissions.member' }
        };
        return translations;
    }, []);

    const getTranslation = useCallback((key: string) => {
        return translationsObject[key]
            ? intl.formatMessage(translationsObject[key])
            : key;
    }, [translationsObject]);

    return getTranslation;
};

export const useUserRolePermissions = (role: number) => {
    const [isUserAuthorized, setIsUserAuthorized] = useState<boolean>(false);

    useEffect(() => {
        setIsUserAuthorized(role === EOrganizationRole?.Owner || role === EOrganizationRole?.Member);
    }, [role]);

    return {
        isUserAuthorized
    };
};
