import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
`;
export const Confirm: FC = () => {
    const intl = useIntl();
    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: `eApproval.wizard.confirm` })
            }} />
        </Wrapper>
    );
};