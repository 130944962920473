import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import parseHtml, { domToReact, Element } from 'html-react-parser';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';

import { IComparedStructureElement, renderDiffTextRun } from '../../../tools/legalDocTools/sogeDiffTools';
import { extToMime } from '../../../tools/clusterFilesystemTools';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { ArticleTitle } from '../LegalDocument/common/ArticleTitle';
import { SectionTitle } from '../LegalDocument/common/SectionTitle';
import { AttachmentWrapper } from '../LegalDocument/common/ArticleAttachment';
import { FileIcon } from '../../Organization/ClustersV2/ClusterDocuments/FileManager/FileIcon';

const Attachment = styled(AttachmentWrapper).attrs({ className: 'diff-attachment' as string })`
    margin: 0 1rem;
`;

const LevelHeading = styled.div`
    padding: .5rem 0;
    margin: 0 1rem;
    font-weight: 600;
    font-size: ${fontSize[16]};
    display: flex;
    align-items: baseline;
    > svg {
        margin-right: .75rem;
        margin-left: .5rem;
    }
`;

const ArticleWrapper = styled.div`
    margin: 0 1rem;
`;

interface IDiffObjectProps {
    diffElement: IComparedStructureElement;
}

export const DiffObject: FC<React.PropsWithChildren<IDiffObjectProps>> = ({ diffElement }) => {
    const { isArticle, isSection, isAttachment, added, removed, structureTitle, commonId, diffResultHtml, attachmentExt, attachmentSrc, attachmentFallback } = diffElement;
    const itemTitle = useMemo(() => parseHtml(renderDiffTextRun(structureTitle || '', added, removed)), [structureTitle]);

    const articleDiff = useMemo(() => parseHtml(diffResultHtml || '', {
        replace: (domNode: Element) => {
            if (domNode?.name === 'a' && domNode?.attribs?.['data-internal-link'] && !!parseInt(domNode.attribs['data-internal-link'], 10)) {
                return (
                    <Link to={domNode.attribs.href} className={domNode.attribs.class || ''}>
                        {domToReact(domNode.children)}
                    </Link>
                );
            }
        }
    }), [diffResultHtml]);

    return (
        <>
            {isSection && (
                <SectionTitle commonLevelId={commonId} title={itemTitle} />
            )}
            {(!isArticle && !isSection && !isAttachment) && (
                <LevelHeading>
                    <FontAwesomeIcon icon={faSortDown} className={cn({ added, removed })} />
                    {itemTitle}
                </LevelHeading>
            )}
            {isArticle && (
                <ArticleWrapper>
                    <ArticleTitle title={itemTitle} />
                    <div className={cn({ added, removed })}>
                        {articleDiff}
                    </div>
                </ArticleWrapper>
            )}
            {isAttachment && (
                <Attachment className={cn({ added, removed })}>
                    <FileIcon mimeType={extToMime[attachmentExt]} />
                    {attachmentSrc && <img src={attachmentSrc} />}
                    {attachmentFallback && <span>{attachmentFallback}</span>}
                </Attachment>
            )}
        </>
    );
};
