import { action } from '../tools/reduxTools';
import { UPDATE_PDF_OVERLAY, SHOW_PDF_OVERLAY, HIDE_PDF_OVERLAY, CLEAR_PDF_OVERLAY } from './actionTypes/pdfOverlayTypes';
import { IPdfOverlay } from '../entities/IPdfOverlay';

export const setPdfOverlay = ({ pdfUrl, pdfName, display = true }: IPdfOverlay) => action(async (dispatch) => {
    dispatch({ type: UPDATE_PDF_OVERLAY, pdfOverlay: { pdfUrl, pdfName, display } });
});

export const showPdfOverlay = () => action(async (dispatch) => {
    dispatch({ type: SHOW_PDF_OVERLAY });
});

export const hidePdfOverlay = () => action(async (dispatch) => {
    dispatch({ type: HIDE_PDF_OVERLAY });
});

export const clearPdfOverlay = () => action(async (dispatch) => {
    dispatch({ type: CLEAR_PDF_OVERLAY });
});