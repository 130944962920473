import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const ColorBar = styled.div`
    background-color: ${colorStack.white};
    transition: background-color .3s;
    height: 2px;
    width: 100%;
    border-radius: 0 0 4px 4px;
`;

const Wrapper = styled.div`
    border: 1px solid ${colorStack.ligthGrey};
    color: ${colorStack.darkBlue};
    border-radius: 4px;
    margin: 10px 20px 5px 20px;
    display: flex;
    flex-direction: column;
    input {
        font-size: ${fontSize[25]};
        margin-top: 10px;
        margin-left: 10px;
        width: 90%;
        &:focus + textarea + ${ColorBar} {
            background-color: ${colorStack.middleBlue};
        }
        &::placeholder{
            font-size: ${fontSize[25]} !important;
        }
    }
    textarea {
        font-size: ${fontSize[13]};
        height: 250px;
    }
    input, textarea {
        white-space: pre;
        border: none;
        outline: none;
        background: ${colorStack.white};
        resize: none;
        &:focus + ${ColorBar} {
            background-color: ${colorStack.middleBlue};
        }
    }
`;

interface IProps {
    inputValue: string;
    textareaValue: string;
    mandatoryStarSubject?: boolean;
    mandatoryStarMessage?: boolean;
    inputChange(e: React.ChangeEvent<HTMLInputElement>);
    textareaChange(e: React.ChangeEvent<HTMLTextAreaElement>);
}

export const SubjectMessage: FC<React.PropsWithChildren<IProps>> = props => {
    const intl = useIntl();
    return (
        <Wrapper>
            <input
                type="text"
                value={props.inputValue}
                placeholder={intl.formatMessage({ id: 'global.subject' }, {star: props.mandatoryStarSubject ? '*' : ''})}
                onChange={props.inputChange}
            />
            <textarea
                placeholder={intl.formatMessage({ id: 'global.message'}, {star: props.mandatoryStarMessage ? '*' : ''})}
                value={props.textareaValue} onChange={props.textareaChange}
            />
            <ColorBar />
        </Wrapper>
    );
};
