import * as actionTypes from '../actions/actionTypes/cookiesTypes';
/* cookieBlock is a solution for the future. It blocks the display of certain page elements depending on the acceptance of cookies policy  */
const defaultState = (): ICookiesReducer => ({
    showCookie: undefined,
    cookieBlock: undefined
}) as ICookiesReducer;

export default (state = defaultState(), action): ICookiesReducer => {
    switch (action.type) {
        case actionTypes.ACCEPT_COOKIES: {
            return {
                ...state,
                showCookie: true
            };
        }
        case actionTypes.DECLINE_COOKIES: {
            return {
                ...state,
                showCookie: false
            };
        }
        case actionTypes.BLOCK_COOKIES: {
            return {
                ...state,
                cookieBlock: true
            };
        }
        case actionTypes.UNBLOCK_COOKIES: {
            return {
                ...state,
                cookieBlock: false
            };
        }
        default: {
            return state;
        }
    }
};

export interface ICookiesReducer {
    showCookie: boolean;
    cookieBlock: boolean;
}