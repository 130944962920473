import { ILegalClusterConfigType, ELegalClusterEnv } from '../entities/IConfiguration';
import * as actionTypes from '../actions/actionTypes/configTypes';
import * as configActions from '../actions/configActions';

const defaultState = (): IConfigReducer => ({
    appConfig: {} as ILegalClusterConfigType,
    appConfigLoaded: false,
    apiUrl: undefined,
    equitisApiUrl: undefined,
    subKey: undefined,
    paymentUrl: undefined,
    subKeyPaymentApi: undefined,
    cmsApiUrl: undefined,
    subKeyCms: undefined,
    notificationUrl: undefined,
    rfpApiUrl: undefined,
    messagingApiUrl: undefined,
    signalRUri: undefined,
    organizationsApiUrl: undefined,
    clustersApiUrl: undefined,
    panelsApiUrl: undefined,
    permissionsApiUrl: undefined,
    profilesApiUrl: undefined,
    validationsApiUrl: undefined,
    languageApiUrl: undefined,
    legalDocApiUrl: undefined,
    pushPublicKey: undefined,
    platformName: undefined,
    isLocalHost: undefined,
    finalRegistration: undefined,
    commonApiUrl: undefined,
    isWopiIntegrationActive: undefined
});

export default (state = defaultState(), action): IConfigReducer => {
    switch (action.type) {
        case actionTypes.GET_APPLICATION_CONFIG: {
            const getConfigAction = action as configActions.IGetApplicationConfig; // not needed, this just adds nice code autocompletion
            const splittedApiURI = (getConfigAction?.appConfig?.apiURI || '').split('##');
            const splittedCmsApiURI = (getConfigAction?.appConfig?.cmsApiURI || '').split('##');
            const equitisApiUrl = getConfigAction?.appConfig?.equitisApi;
            const notificationUrl = getConfigAction?.appConfig?.notificationUrl;
            const rfpApiUrl = getConfigAction?.appConfig?.rfpApi;
            const messagingApiUrl = getConfigAction?.appConfig?.messagingApi;
            const signalRUri = getConfigAction?.appConfig?.signalRUri;
            const organizationsApiUrl = getConfigAction?.appConfig?.organizationsApi;
            const clustersApiUrl = getConfigAction?.appConfig?.clustersApi;
            const panelsApiUrl = getConfigAction?.appConfig?.panelsApi;
            const permissionsApiUrl = getConfigAction?.appConfig?.permissionsApi;
            const profilesApiUrl = getConfigAction?.appConfig?.profilesApi;
            const commonApiUrl = getConfigAction?.appConfig?.uriCommonApi;
            const validationsApiUrl = getConfigAction?.appConfig?.validationsApi;
            const languageApiUrl = getConfigAction?.appConfig?.languageApi;
            const legalDocApiUrl = getConfigAction?.appConfig?.legalDocApi;
            const pushPublicKey = getConfigAction?.appConfig?.pushPublicKey;
            const platformName = getConfigAction?.appConfig?.platform;
            const isLocalHost = getConfigAction?.appConfig?.isLocalHost;
            const finalRegistration = getConfigAction?.appConfig?.isRegistrationFinalScreenActive;
            const isWopiIntegrationActive = getConfigAction?.appConfig?.isWopiIntegrationActive;

            return {
                ...state,
                appConfig: getConfigAction.appConfig,
                appConfigLoaded: true,
                apiUrl: splittedApiURI[0],
                subKey: splittedApiURI[1],
                cmsApiUrl: splittedCmsApiURI[0],
                subKeyCms: splittedCmsApiURI[1],
                equitisApiUrl,
                notificationUrl,
                rfpApiUrl,
                messagingApiUrl,
                signalRUri,
                organizationsApiUrl,
                clustersApiUrl,
                panelsApiUrl,
                permissionsApiUrl,
                profilesApiUrl,
                commonApiUrl,
                validationsApiUrl,
                languageApiUrl,
                legalDocApiUrl,
                pushPublicKey,
                platformName,
                isLocalHost,
                finalRegistration,
                isWopiIntegrationActive
            };
        }

        default:
            return state;
    }
};

export interface IConfigReducer {
    appConfig: ILegalClusterConfigType;
    appConfigLoaded: boolean;
    apiUrl: string;
    subKey: string;
    paymentUrl: string;
    notificationUrl: string;
    messagingApiUrl: string;
    signalRUri: string;
    subKeyPaymentApi: string;
    cmsApiUrl: string;
    subKeyCms: string;
    equitisApiUrl: string;
    rfpApiUrl: string;
    organizationsApiUrl: string;
    clustersApiUrl: string;
    panelsApiUrl: string;
    permissionsApiUrl: string;
    profilesApiUrl: string;
    commonApiUrl: string;
    validationsApiUrl: string;
    languageApiUrl: string;
    legalDocApiUrl: string;
    pushPublicKey: string;
    platformName: ELegalClusterEnv;
    isLocalHost: boolean;
    finalRegistration: boolean;
    isWopiIntegrationActive: boolean;
}