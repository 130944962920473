import React, { FC } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';

const DateWrapper = styled.span`
    color: ${colorStack.content};
    white-space: nowrap;
    word-break: break-all;
`;

export const DateType: FC<React.PropsWithChildren<{datetime?: boolean}>> = props => {
    const dateFormat = props.datetime ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY';
    return (
        <DateWrapper>{props.children ? moment(new Date(props.children as string)).local().format(dateFormat) : ''}</DateWrapper>
    );
};