import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';
import { SgAlignTo } from '../../../entities/LegalDoc/ISogeStyles';
import { fontSize } from '../../../styleHelpers/fontSizes';

export const DropdownWrapper = styled.div`
    position: relative;
    margin-right: 1rem;
`;

interface ILangDropdownButtonProps {
    isOpen: boolean;
    isBold?: boolean;
}

export const LangDropdownButton = styled.span<ILangDropdownButtonProps>`
    display: flex;
    align-items: center;
    > img {
        margin-right: 0.4rem;
        width: 20px;
    }
    > span {
        display: none;
        ${media.tablet`
            display: inline;
            margin-right: 0.4rem;
            font-size: ${fontSize[13]};
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            color: ${colorStack.darkGrey};
            ${props => props.isBold && css`
                font-weight: 600;
            `}
        `}
    }
    svg {
        margin: 0 0 7px 0;
    }
    ${props => props.isOpen && css`
        svg {
            margin: 7px 0 0 0;
        }
    `}
`;

export const SgDropdown = styled.div<{ alignTo?: SgAlignTo, isCropped?: boolean }>`
    position: absolute;
    right: 0;
    z-index: 9;
    background: ${colorStack.white};
    box-shadow: 0 2px 4px 0 ${rgba(colorStack.darkBlue, 0.10)};
    border-radius: 3px;
    ${({ alignTo }) => alignTo === 'left' && css`
        right: auto;
        left: 0;
    `}
    ${props => props.isCropped && css`
        max-height: 11rem;
        overflow: auto;
    `}
`;

export const DropdownSpinnerWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
`;