import React, { FC, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { Checkbox } from '../../../../Common/Inputs/Checkbox';
import { ErrorText } from './StakeStyles';

const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

const SCheckbox = styled(Checkbox)`
    margin-bottom: 10px;
`;

interface ICheckboxContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const CheckboxContent: FC<React.PropsWithChildren<ICheckboxContentProps>> = ({ error, data, editStake, propertieId, setEditingData }) => {
    // tslint:disable-next-line:no-null-keyword
    const [content, setContent] = useState<boolean | null>(null);

    useEffect(() => {
        setContent(data.value);
    }, [data, editStake]);

    const changeState = useCallback(() => {
        setContent(!data.value);
        setEditingData({
            ...data,
            value: !data.value,
            id: propertieId
        });
    }, [data, propertieId, setEditingData]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <SCheckbox checked={content} onChange={changeState} />
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                <SCheckbox checked={content}/>
            )}
        </Wrapper>
    );
};
