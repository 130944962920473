import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { EProfileType } from '../../../../entities/IGlobal';
import { UsersNumber } from '../../UsersNumber/UsersNumber';

const WrapperLink = styled(Link)`
    display: flex;
    &:hover { text-decoration: none; }
`;

export enum EPeoleContext {
    ORGANIZATION = 'ORGANIZATION',
    CLUSTER = 'CLUSTER',
    GROUP = 'GROUP',
    PANEL = 'PANEL'
}
export interface IMember {
    id: string;
    picture: string;
    firstName: string;
    lastName: string;
    type: EProfileType;
}
interface IProps {
    members: IMember[];
    organizationUrlName?: string;
    id?: string;
    context: EPeoleContext;
    linkPanel?: string;
}

export const People: FC<React.PropsWithChildren<IProps>> = props => {
    const [link, setLink] = useState<string>('');

    useEffect(() => {
        if (props.context === EPeoleContext.CLUSTER) {
            setLink(`/orgs/${props.organizationUrlName}/cluster/${props.id}/members`);
        }
        if (props.context === EPeoleContext.ORGANIZATION) {
            setLink(`/orgs/${props.organizationUrlName}/settings#Members`);
        }
        if (props.context === EPeoleContext.GROUP) {
            setLink(`/groups/${props.id}`);
        }
        if (props.context === EPeoleContext.PANEL) {
            setLink(props.linkPanel);
        }
    }, [props.context]);

    return (
        <WrapperLink to={link}>
            <UsersNumber members={props.members} preventRedirection />
        </WrapperLink>
    );
};