import { EClusterType } from './IClusters';
import { EContextList, EReferentials } from './IGlobal';

export interface IClusterDynamicStakes {
    stakeFamily: EStakeFamily | string;
    stakeFamilyId: string;
    stakeFamilyOrder: number;
    stakeListItems: IClusterDynamicStakeListItem[];
    stakeFamilyType: string;
    stakeFamilyKey: string;
}

export enum EStakeFamily {
    Parties = 'Parties'
}

export enum EClusterStakePropertyName {
    Entity = 'Entity'
}

export enum EStakeFamilyType {
    Date = 'Date'
}

export interface IClusterDynamicStakeListItem {
    isDisabled: boolean;
    stakeListName: string;
    isMultistakeAvailable: boolean;
    stakeListOrder: string;
    clusterStakes: IClusterSingleDynamicStake[];
    stakeDefinitionId: string;
    key: EPropertieKey;
    configuration: IStakeConfiguration;
}

export interface IStakeConfiguration {
    defaultDates: string[];
    titleReferentialIdentityContext: EContextList;
    titleReferentialIdentityId: string;
    titleReferentialIdentityType: EReferentials;
}
export interface IClusterStakeProperty {
    name: EClusterStakePropertyName | string;
    id: string;
    value: any;
    type: EStakeDetailsType;
    subType: EStakeDetailsSubType;
    configuration?: ISingleStakeDefinitionGetConfiguration;
    key: EPropertieKey;
    newPropertyIdentifier?: string;
    customIcon?: string;
    isMandatory?: boolean;
    isEditable?: boolean;
    referentialIdentity?: string;
}

export interface IClusterSingleDynamicStake {
    id?: string;
    newElementIdentifier?: string;
    isTemplateItem?: boolean;
    key?: string;
    lastModificationDate?: string;
    name?: string;
    properties?: IClusterStakeProperty[];
    stakeDefinitionId?: string;
    stakeDefinitionVersion?: string;
    updatedBy?: string;
    type: EStakeContainerType;
    subType?: EClusterSingleDynamicStakeSubType;
}
export enum EClusterSingleDynamicStakeSubType {
    PartyStakeTitle = 'PartyStakeTitle',
    Standard = 'Standard'
}

export interface ISingleStakeDefinitionList {
    id: string;
    key: string;
    name: string;
    stakeFamily: string;
    type: EStakeContainerType;
}

export enum EConfigurationOfAvailableUnits {
    DefinedValues = 0,
    DynamicValues = 10
}
export interface ISingleStakeDefinitionGetConfiguration {
    validators?: {
        configuration: {
            maxValue: number;
            minValue: number;
            isIndicativeMax?: boolean;
            isIndicativeMin?: boolean;
            unit?: string;
        };
        id: string;
        name: string;
    }[];
    precision?: number;
    defaultReferentialKey?: string;
    rules?: [];
    value?: any;
    availableUnits?: {
        valueKeys: string[];
        referentialIdentity: string;
        referentialType: EReferentials;
        referentialContext: EContextList;
        configuration: EConfigurationOfAvailableUnits;
        ValueKeys?: string[];
        ReferentialIdentity?: string;
        ReferentialType?: EReferentials;
        ReferentialContext?: EContextList;
        Configuration?: EConfigurationOfAvailableUnits;
    }
    referentialIdentity?: string;
    ReferentialIdentity?: string;
    referentialType?: EReferentials;
    ReferentialType?: EReferentials;
    referentialContext?: EContextList;
    ReferentialContext?: EContextList;
    multipleAttachment?: boolean;
    ParentPropertyId?: string;
    parentPropertyId?: string;
    items?: ITranslationItem[];
    titleReferentialIdentityContext?: EContextList;
    titleReferentialIdentityId?: string;
    titleReferentialIdentityType?: EReferentials;
}
export interface ITranslationItem {
    translationKey: string;
    enumElement: string;
}
export interface ISingleStakeDefinitionGet {
    properties: IClusterStakeProperty[];
    lastModification: string;
    name: string;
    key: string;
    id: string;
    stakeFamily: string;
    stakeListName: string;
    stakeFamilyId: string;
    stakeListKey: string;
    type: number;
    isMultistakesAvailable: boolean;
}

export enum EStakeFamilyKeys {
    SponsoringProjectHolderAssessment = 'SponsoringProjectHolderAssessment',
    SponsoringLineManagerAssessment = 'SponsoringLineManagerAssessment',
    CommunicationCoordinatorAssessment = 'CommunicationCoordinatorAssessment',
    LegalAdviserAssessment = 'LegalAdviserAssessment',
    ComplianceDepartmentAssessment = 'ComplianceDepartmentAssessment',
    Ownership = 'Ownership',
    CorporateBodies = 'CorporateBodies',
    LegalRepresentatives = 'LegalRepresentatives',
    LobbyingProjectStatus = 'LobbyingProjectStatus',
    LobbyingBeneficiaryEntities = 'LobbyingBeneficiaryEntities',
    LobbyingDeclarant = 'LobbyingDeclarant',
    LobbyingAction = 'LobbyingAction',
    Notes = 'Notes',
    LobbyingThirdPartyAndMemberOrganization = 'LobbyingThirdPartyAndMemberOrganization',
    LobbyingConsultingIntermediary = 'LobbyingConsultingIntermediary',
    LegalSupportAnswer = 'LegalSupportAnswer',
    LegalSupportRequestor = 'LegalSupportRequestor',
    EApprovalSponsoringDocuments = 'EApprovalSponsoringDocuments',
    EApprovalEventExpensesDocuments = 'EApprovalEventExpensesDocuments',
    EApprovalGroupingDocuments = 'EApprovalGroupingDocuments',
    EApprovalFreeDeclarationDocuments = 'EApprovalFreeDeclarationDocuments'
}

export enum EPropertieKey {
    EApprovalFreeDeclarationDocuments = 'eapproval-free-declaration-documents',
    EApprovalEventExpensesDocuments = 'eapproval-event-expenses-documents',
    EApprovalGroupingDocuments = 'eapproval-grouping-documents',
    EApprovalSponsoringDocuments = 'eapproval-sponsoring-documents',
    SponsoringGroupShares = 'sponsoring-group-shares',
    SponsoringDocumentsEApprovalNature = 'sponsoring-documents-eApproval-nature',
    GroupingDocumentsEApprovalNature = 'grouping-documents-eApproval-nature',
    EventExpensesDocumentsEApprovalNature = 'event-expenses-documents-eApproval-nature',
    FreeDeclarationDocumentsEApprovalNature = 'free-declaration-documents-eApproval-nature',
    SponsoringDocumentsEApprovalFile = 'sponsoring-documents-eApproval-file',
    GroupingDocumentsEApprovalFile = 'grouping-documents-eApproval-file',
    EventExpensesDocumentsEApprovalFile = 'event-expenses-documents-eApproval-file',
    LegalSupportRequestor = 'legal-support-requestor',
    LegalSupportRequest = 'legal-support-request',
    RequestorEntity = 'requestor-entity',
    LegalSupportanswerDate = 'answer-date',
    LegalSupportanswerPersonInCharge = 'answer-person-in-charge',
    LegalSupportAnswer = 'legal-support-answer',
    LobbyingProjectStatus = 'lobbying-project-status',
    EapprovalRequest = 'eapproval-request',
    LobbyingDeclarant = 'lobbying-declarant',
    LobbyingTypeOfCollectivity = 'type-of-collectivity',
    AdministrativeAuthorityName = 'administrative-authority-name',
    LobbyingBeneficiaryEntities = 'lobbying-beneficiary-entities',
    LobbyingDeclarantQuality = 'lobbying-declarant-quality',
    LobbyingThirdPartyAndMemberOrganization = 'lobbying-third-party-and-member-organization',
    LobbyingConsultingIntermediary = 'lobbying-consulting-intermediary',
    LobbyingConsultingIntermediaryEntities = 'lobbying-consulting-intermediary-entities',
    LobbyingThirdPartyAndMemberOrganizationEntities = 'lobbying-third-party-and-member-organization-entities',
    LobbyingObjectQualification = 'lobbying-object-qualification',
    LobbyingCategoryOfPerson = 'category-of-person',
    LobbyingNameOfPublicOfficial = 'name-of-public-official',
    LobbyingAction = 'lobbying-action',
    LobbyingCostIncurred = 'cost-incurred',
    LobbyingTotalRessources = 'lobbying-total-ressources',
    LobbyingTypeOfAction = 'type-of-action',
    BeneficiaryEntity = 'beneficiary-entity',
    ConsultingEntity = 'consulting-entity',
    ThirdPartiesEntity = 'third-parties-entity',
    Document = 'document',
    Documents = 'documents',
    DateStart = 'date-start',
    DateEnd = 'date-end',
    RenewalDenunciationLimitDate = 'renewal-denunciation-limit-date',
    EndDate = 'end-date',
    BalanceSheetDate = 'balance-sheet-date',
    Position = 'position',
    Quality = 'quality',
    UserRepresentative = 'user-representative',
    Representatives = 'representatives',
    Terms = 'terms',
    LegalEntityRepresentative = 'legal-entity-representative',
    Attachments = 'attachments',
    AttachmentsFranchise = 'attachments-franchise',
    AttachementsDistirbution = 'attachements-distirbution',
    AttachementsSupply = 'attachements-supply',
    AliasesAndRegistrations = 'aliases-and-registrations',
    RadioButton = 'radio-button',
    Description = 'description',
    NewManager = 'gift-creator-new-manager',
    JourneyCreatorNewManager = 'journey-creator-new-manager',
    NonRegisteredUserCountry = 'non-registered-user-country',
    NonRegisteredUserEmail = 'non-registered-user-email',
    NonRegisteredUserLastName = 'non-registered-user-last-name',
    NonRegisteredUserFirstName = 'non-registered-user-first-name',
    NonRegisteredUserCabinet = 'non-registered-user-cabinet',
    NonRegisteredUserUserJob = 'non-registered-user-job',
    NonRegisteredUserUserCountry = 'non-registered-user-country',
    NonRegisteredUserAddress = 'non-registered-user-address',
    DocumentType = 'document-type',
    DocumentAttachedFile = 'document-attached-file',
    Email = 'email',
    Boolean = 'boolean',
    JourneyPartiesRelationshipNature = 'journey-parties-relationship-nature',
    MandateHolder = 'mandate-holder',
    GiftCreatorManager = 'gift-creator-manager',
    CircumstancesAndContext = 'circumstances-and-context',
    GiftPartiesRelationshipDescription = 'gift-parties-relationship-description',
    JourneyPartiesRelationshipDescription = 'journey-parties-relationship-description',
    JourneyAmount = 'journey-amount',
    PLECLE = 'ple-cle',
    OtherSideParty = 'involved-parties-other-sides',
    OurSideParty = 'involved-parties-our-sides',
    JourneyCreator = 'journey-creator',
    JourneyCreatorManager = 'journey-creator-manager',
    JourneyCreatorLegalEntity = 'journey-creator-legal-entity',
    CostCenterSponso = 'cost-center-sponso',
    Function = 'function',
    GiftDescription = 'gift-description',
    GiftPartiesRelationship = 'gift-parties-relationship',
    SendingGiftDonors = 'sending-gift-donors',
    ReceivingGiftDonors = 'receiving-gift-donors',
    SendingGiftBeneficiaries = 'sending-gift-beneficiaries',
    ReceivingGiftBeneficiaries = 'receiving-gift-beneficiaries',
    SendingInvitationBeneficiaries = 'sending-invitation-beneficiaries',
    ReceivingInvitationBeneficiaries = 'receiving-invitation-beneficiaries',
    SendingInvitationDonors = 'sending-invitation-donors',
    ReceivingInvitationDonors = 'receiving-invitation-donors',
    InvitationDescription = 'invitation-description',
    InvitationPartiesRelationship = 'invitation-parties-relationship',
    Investors = 'investors',
    Nature = 'nature',
    JourneyValue = 'journey-value',
    Date = 'date',
    JourneyCreatorCountry = 'journey-creator-country',
    NonRegisteredUserRelationshipNature = 'non-registered-user-relationship-nature',
    NonRegisteredUserThirdParty = 'non-registered-user-third-party',
    NonRegisteredUserPersonRisk = 'non-registered-user-person-risk',
    NonRegisteredUserSensitivePerson = 'non-registered-user-sensitive-person',
    NonRegisteredUserUnitAmount = 'non-registered-user-unit-amount',
    NonRegisteredUserTotalAmountReceived = 'non-registered-user-total-amount-received',
    NonRegisteredUserTotalAmountSent = 'non-registered-user-total-amount-sent',
    NonRegisteredUserLegalEntity = 'non-registered-user-legal-entity',
    Declarants = 'declarants',
    DeclarantPerson = 'declarant-person',
    Declarations = 'declarations',
    ManagementDecisions = 'management-decisions',
    DeclarationCertificate = 'declaration-certificate',
    CoiDecisionMaker = 'coi-decision-maker',
    DecisionDate = 'decision-date',
    SponsoringOverviewProjectHolders = 'sponsoring-overview-project-holders',
    SponsoringOverviewProjectDescriptionsJustifications = 'sponsoring-overview-project-descriptions-justifications',
    SponsoringOverviewContributions = 'sponsoring-overview-contributions',
    SponsoringOverviewBudgets = 'sponsoring-overview-budgets',
    SponsoringOverviewProfiles = 'sponsoring-overview-profiles',
    SponsoringOverviewHistoryRelations = 'sponsoring-overview-history-relations',
    Notes = 'notes',
    LinkDescription = 'link-description',
    NotesMultilined = 'notes-multilined',
    AssessmentProjectHolder = 'assessment-project-holder',
    AssessmentLineManager = 'assessment-line-manager',
    AssessmentCommunicationCoordinator = 'assessment-communication-coordinator',
    AssessmentLegalAdviser = 'assessment-legal-adviser',
    AssessmentComplianceDepartment = 'assessment-compliance-department',
    AssessmentAuthor = 'assessment-author',
    AssessmentStatus = 'assessment-status',
    AssessmentDate = 'assessment-date',
    LastRenewalDate = 'last-renewal-date',
    SponsoringOverviewDocuments = 'sponsoring-overview-documents',
    SponsoringTotalProjectBudget = 'sponsoring-total-project-budget',
    SponsoringContributionAmount = 'sponsoring-contribution-amount',
    SponsoringProfileLegalEntity = 'sponsoring-profile-legal-entity',
    CompanyShares = 'company-shares',
    MandateStatus = 'mandate-status',
    Comments = 'comments',
    ChangeStatusDate = 'change-status-date',
    ChangeStatusAuthor = 'change-status-author',
    BeneficiaryLegalForm = 'beneficiary-legal-form',
    BeneficiaryNationalNumber = 'beneficiary-national-number',
    BeneficiaryFullAdress = 'beneficiary-full-adress',
    ThirdPartiesLegalForm = 'third-parties-legal-form',
    ThirdPartiesNationalNumber = 'third-parties-national-number',
    ThirdPartiesFullAdress = 'third-parties-full-adress',
    TotalTime = 'total-time',
    TotalCosts = 'total-costs',
    ConsultingLegalForm = 'consulting-legal-form',
    ConsultingNationalNumber = 'consulting-national-number',
    ConsultingFullAdress = 'consulting-full-adress',
    DeclarantTeam = 'declarant-team',
    DeclarantDepartment = 'declarant-deppartment',
    DeclarantManager = 'declarant-manager',
    DeclarantEntity = 'declarant-entity',
    ActionPerson = 'action-person',
    TimeSpent = 'time-spent',
    DeclarentFirstReferential = 'declarant-first-referential',
    DeclarentSecondReferential = 'declarant-second-referential',
    DeclarentThirdReferential = 'declarant-third-referential',
    InvestmentVehicle = 'investment-vehicles',
    GroupmentProjectHolder = 'groupment-project-holder',
    ContextProject = 'context-project',
    PartnersRelationship = 'partners-relationship',
    EthicalAssessment = 'ethical-assessment',
    GroupingDetails = 'grouping-details',
    ProjectDescriptionContribution = 'project-description-contribution',
    JourneyDeclarant = 'journey-declarant',
    EventDescription = 'event-description',
    PoaParties = 'poa-parties',
    DelegationsDetails = 'delegations-details',
    StatusAndFormality = 'status-and-formalities',
    Scopes = 'scopes',
    POWERS = 'powers'
}

export enum EMandateStatus {
    Active = 'Active',
    Inactive = 'Inactive',
    Expired = 'Expired',
    EmminentEnd = 'EmminentEnd',
    NoStatus = 'NoStatus'
}

export enum EStakeDetailsType {
    Text = 'Text',
    Simple = 'Simple',
    Number = 'Number',
    NumberAndRisk = 'NumberAndRisk',
    Date = 'Date',
    LegalDate = 'LegalDate',
    Reference = 'Reference',
    Cluster = 'Cluster',
    Person = 'Person',
    People = 'People',
    MultiplePeople = 'MultiplePeople',
    Address = 'Address',
    Boolean = 'Boolean',
    Attachment = 'Attachment',
    MultipleAttachments = 'MultipleAttachments',
    LegalEntity = 'LegalEntity',
    MultipleLegalEntity = 'MultipleLegalEntity',
    URL = 'URL',
    Quality = 'Quality',
    SingleSelect = 'SingleSelect',
    MultipleSelect = 'MultipleSelect',
    Party = 'Party',
    MultipleParty = 'MultipleParty',
    NumberAndSelect = 'NumberAndSelect',
    CurrencyNumberAndSingleSelect = 'CurrencyNumberAndSingleSelect',
    CheckboxBoolean = 'CheckboxBoolean',
    Enum = 'Enum',
    Registrations = 'Registrations',
    Label = 'Label',
    FilteredByOurGroup= 'FilteredByOurGroup'
}

export enum EStakeDetailsSubType {
    Simple = 'Simple',
    Subsidiary = 'Subsidiary',
    Radio = 'Radio',
    Currency = 'Currency',
    Percentage = 'Percentage',
    MultiLined = 'MultiLined',
    Duration = 'Duration',
    FilteredByOurGroup = 'FilteredByOurGroup',
    Parent = 'Parent',
    Status = 'Status'
}

export enum EStakeContainerType {
    Standard = 0,
    UserParty = 10,
    LegalEntityParty = 11,
    Shareholder = 20,
    Participations = 21,
    GeneralCommitment = 30,
    ShareholderMeetingInformation = 31,
    Notes = 40,
    RelatedEntities = 41,
    Contact = 50,
    Economic = 60,
    Registration = 70,
    Documents = 80,
    GiftDescription = 90,
    GiftPartiesRelationship = 91,
    GiftCreator = 92,
    GiftSecondParty = 93,
    GiftParty = 94,
    InvolvedParty = 95,
    AuthorizedActivities = 96,
    MainRent = 97,
    AdditionnalRent = 98,
    BuildingFeesCommitment = 99,
    QuotationPrice = 100,
    LeaseTaxes = 101,
    ConstructionWorkRepaire = 102,
    RentCancellation = 103,
    QuotationPresentation = 104,
    QuotationRequestBody = 106,
    LeaseAssignment = 107,
    SecurityDeposit = 108,
    RentIndexation = 109,
    LeaseParties = 110,
    DamagePovision = 111,
    ContractDuration = 112,
    BuildingFees = 113,
    Designation = 114,
    LeaseContractTypology = 115,
    SubLeasing = 116,
    RenewalProvision = 117,
    RentInsurance = 118,
    InvitationDescription = 120,
    InvitationSecondParty = 121,
    MandateTypology = 122,
    DelegationDuration = 123,
    StatusAndFormality = 124,
    PoaCompany = 125,
    Delegator = 126,
    Delegate = 127,
    ObjectOfPower = 128,
    Scope = 130,
    Means = 131,
    ContractingParties = 132,
    ProjectDescription = 133,
    ContractTypologyMerieux = 134,
    FinancingPlan = 135,
    OwnershipOfRights = 136,
    TransferOfRights = 137,
    CancellationOfThePoa = 138
}

export interface INumberStakeType {
    value: {
        number: number;
        unit: string;
    };
    precision?: number;
    units?: string[];
    validators: {
        configuration: {
            maxValue: number;
            minValue: number;
            unit: string;
        };
        id: string;
        name: string;
    }[];
}

export interface ITextStakeType {
    value: string;
    validators: {
        configuration: {
            maxValue: number;
            minValue: number;
        };
        id: string;
        name: string;
    }[];
}

export interface IStakeEditingObject {
    stakeDefinitionId: string | number;
    type: EStakeContainerType;
    properties: {
        id: string;
        name: string;
        value: any;
        type: string;
        subType: string;
        configuration: ISingleStakeDefinitionGetConfiguration;
        referentialIdentity?: string;
    }[];
}

export interface ILegalEntityObject {
    address: any;
    dataType: string;
    id: string;
    isPublic: string;
    legalForm: string;
    name: string;
    picture: string;
    size: string;
    typeOfActivity: string;
    typeOfLegalEntity: string;
}

export interface IAttachmentResponse {
    parents: any;
    name: string;
    type: string;
    lastModificationDate: string;
    contentType: string;
    directUri: string;
    fileId: string;
}

export interface IStakeDataGridSettings {
    columnsSettings: IStakeDataGridColumnSettings[];
    dataOrders: any;
    id: string;
    organizationId: string;
    stakeDefinitionId: string;
    userId: string;
}

export interface IStakeDataGridColumnSettings {
    field: string;
    type: 'date' | 'datetime' | 'number';
    headerText: string;
    isVisible: boolean;
    width: number;
}

export interface IStakeDataGridSource {
    [key: string]: any;
    clusterId: string;
    clusterName: string;
    clusterType: EClusterType;
}
