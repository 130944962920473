import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';
import { fontSize } from '../../../styleHelpers/fontSizes';

export const DataRow = styled.div`
    display: flex;
    margin-bottom: 1.3rem;
    flex-wrap: wrap;
`;

export const DataCol = styled.div`
    margin-right: 2rem;
    margin-bottom: .5rem;
    display: flex;
    flex-direction: column;
`;

export const DataHeader = styled.span`
    font-size: ${fontSize[13]};
    margin-bottom: 0.2rem;
    color: ${colorStack.disabled};
    display: inline-block;
`;

export const SectionHeader = styled.div`
    margin: .5rem 0 1rem 0;
    font-weight: 600;
    font-size: ${fontSize[13]};
`;

export const BorderedSection = styled.div`
    margin-bottom: 1rem;
    border-bottom: 1px solid ${colorStack.ligthGrey};
`;

export const TagsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${media.tablet`
        flex-wrap: wrap;
        flex-direction: row;
    `}
    > div {
        ${media.tablet`
            width: 50%;
        `}
    }
`;

export const TagsRow = styled.div`
    margin-bottom: 1rem;
`;

export const TagLabel = styled.div`
    font-size: ${fontSize[13]};
    margin-bottom: 0.5rem;
    color: ${colorStack.disabled};
`;