import { IAddressGlobal, IViewReferential } from '../IGlobal';
import { IRegistrations } from '../ILegalEntities';
import { EClusterApplicableLaw, IClusterPersonInCharge, IClusterReferential, ITimelineItem, IClusterExpertise, IClusterInformation } from '../IClusters';

export interface IMainInformation {
    picture: string;
    address: IAddressGlobal;
    typeOfLegalEntity: { id: string; name: string};
    registeredCapital?: {number: Number; unit: IViewReferential};
    legalForm: { id: string; name: string};
    name: string;
    registrations?: IRegistrations[];
    websiteUrl?: string;
    phoneNumber?: string;
    nationality: { id: string; name: string};
    isPublic: boolean;
    group: { id: string; name: string};
    pleId?: string;
}

export interface IPresentation {
    description: string;
    name: string;
    visibility?: number;
    peopleInCharge: IClusterPersonInCharge[];
    firstReferential: any;
    secondReferential: any;
    thirdReferential: any;
    startDate: Date;
    endDate: Date | string;
    tags: { name: string }[];
    timeline?: ITimelineItem[];
    expertises?: IClusterExpertise[];
    geographies?: IClusterInformation[];
    applicableLaw?: EClusterApplicableLaw;
    businessSectors?: IClusterInformation[];
    nature?: string;
    status?: string;
    privacy?: string;
    organizationId?: string;
    id?: string;
}

export const PresentationEditField = {
    PLE: {
        description: 'presentation',
        peopleInCharge: 'personsInCharge',
        visibility: 'isVisible',
        firstReferential: 'firstReferential',
        secondReferential: 'secondReferential',
        thirdReferential: 'thirdReferential',
        startDate: 'dateBegin',
        endDate: 'dateEnd',
        tags: 'tags',
        expertises: '',
        geographies: '',
        applicableLaw: '',
        businessSectors: '',
        nature: '',
        status: '',
        privacy: '',
        name: 'name'
    },
    Cluster: {
        description: 'presentation.description',
        peopleInCharge: 'presentation.peopleInCharge',
        firstReferential: 'presentation.firstReferential',
        secondReferential: 'presentation.secondReferential',
        thirdReferential: 'presentation.thirdReferential',
        startDate: 'presentation.startDate',
        endDate: 'presentation.endDate',
        tags: 'specification.tags',
        expertises: 'specification.expertises',
        geographies: 'specification.geographies',
        applicableLaw: 'specification.applicableLaw',
        businessSectors: 'specification.businessSectors',
        nature: 'presentation.nature',
        status: 'presentation.status',
        privacy: 'presentation.privacy',
        name: 'presentation.name'
    }
};

export const MainInformationEditField = {
    PLE: {
        name: 'name',
        address: 'address',
        typeOfLegalEntity: 'typeOfLegalEntity',
        legalForm: 'legalForm',
        alias: 'registrations',
        websiteUrl: 'websiteUrl',
        phoneNumber: 'phoneNumber',
        nationality: 'nationality',
        picture: 'picture',
        registrations: 'registrations',
        public: 'isPublic',
        group: 'group',
        capital: 'registeredCapital'
    },
    Cluster: {
        name: 'referencedItem.referencedItem.name',
        address: 'referencedItem.referencedItem.address',
        typeOfLegalEntity: 'referencedItem.referencedItem.typeOfLegalEntity',
        legalForm: 'referencedItem.referencedItem.legalForm',
        alias: 'referencedItem.referencedItem.alias',
        websiteUrl: 'referencedItem.referencedItem.websiteUrl',
        phoneNumber: 'referencedItem.referencedItem.phoneNumber',
        nationality: 'referencedItem.referencedItem.nationality',
        picture: 'referencedItem.referencedItem.picture',
        registrations: 'referencedItem.referencedItem.registrations',
        public: 'referencedItem.referencedItem.public',
        group: 'referencedItem.referencedItem.group',
        capital: 'referencedItem.referencedItem.registeredCapital'
    }
};