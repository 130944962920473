import React, { useCallback, useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Formik, Form, Field, FieldProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeMerge } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';

import { getOrganizationMembersSearch } from '../../../actions/organizationActions';
import { getBranchReferenceTextValidators } from '../../../actions/sogeActions';
import { IValidator, IFullBranch } from '../../../entities/ISoge';
import { EAlertType } from '../../../entities/IAlert';
import { IOrganizationMember } from '../../../entities/IOrganization';
import { getTextFromDraft } from '../../../tools/draftJS';
import { removeDuplicatesByKey, flatArray, updateObjectsInArray } from '../../../tools/arrayTools';
import { useAlert } from '../../../tools/hooks';
import { getErrorMessage } from '../../../tools/errorTools';
import { typed } from '../../../tools/generalTools';
import { isValidEmail } from '../../../tools/emailChecker';
import { uuidv4 } from '../../../tools/authTools';
import { PageHeader } from '../Components/PageHeader';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { WhiteBox } from '../../Common/WhiteBox/WhiteBox';
import { FormikFieldInput } from '../../Common/Input/Input';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { ApprovalFormHeader } from '../Components/ApprovalFormHeader';
import { SingleValidator } from './SingleValidator';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';
import { Loader } from '../../Common/Loader/Loader';
import { NewRT } from '../../Common/RichText/NewRT';
import { LabelWithEllipsis } from '../../Common/Label/Label';
import { StaticDataPicker } from '../../Common/Pickers/StaticDataPicker';
import { IValue } from '../../../entities/IPickers';

type GetOrganizationMembersSearch = ReturnType<typeof getOrganizationMembersSearch>;

const Row = styled.div`
    display: flex;
`;

const FormCol = styled.div`
    width: 70%;
    padding-right: 1rem;
    border-right: 1px solid ${colorStack.ligthGrey};
`;

const ValidatorsCol = styled.div`
    width: 30%;
    padding-left: 1rem;
    .ms-Persona-secondaryText {
        display: none;
    }
`;

const ApprovalLabel = styled(LabelWithEllipsis as any)`
    font-size: ${fontSize[13]};
    color: ${colorStack.disabled};
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin: .5rem 0 0 0;
`;

const SpinnerWrapper = styled.div`
    margin-left: .5rem;
    display: inline-block;
    vertical-align: middle;
`;

interface IApprovalForm {
    name: string;
    description: string;
    descriptionFormatted?: string;
}

const ApprovalFormSchema = Yup.object().shape<IApprovalForm>({
    name: Yup.string().required(),
    description: Yup.string().required()
});

interface IApprovalRequestFormProps {
    branches: IFullBranch[];
    versionName: string;
    organizationId: string;
    organizationUrlName: string;
    documentationId: string;
    versionId: string;
    onCancel();
    onCreateApproval(title: string, descrpition?: string, validators?: IValidator[]): Promise<unknown>;
}
export const ApprovalRequestForm: FC<React.PropsWithChildren<IApprovalRequestFormProps>> = ({ branches, versionName, organizationId, organizationUrlName, documentationId, versionId, onCreateApproval, onCancel }) => {
    const [addingValidator, setAddingValidator] = useState<boolean>(false);
    const intl = useIntl();
    const [metadataValidatorsRequested, setMetadataValidatorsRequested] = useState<boolean>(false);
    const [validators, setValidators] = useState<IValidator[]>([]);
    const dispatch = useDispatch();
    const addAlert = useAlert();

    useEffect(() => {
        if (branches?.length) {
            setMetadataValidatorsRequested(true);
            Promise.all(branches.map(approvalBranch => dispatch(getBranchReferenceTextValidators(documentationId, versionId, approvalBranch.id, approvalBranch.lcid))))
                .then(validatorsResponses => {
                    const allValidators = flatArray(validatorsResponses);

                    const allValidatorsWihtoutDuplicates = removeDuplicatesByKey(
                        allValidators,
                        validator => validator.id,
                        (a, b) => ({ ...a, ...b, isMandatory: a.isMandatory || b.isMandatory })
                    );

                    setValidators(allValidatorsWihtoutDuplicates);
                    setMetadataValidatorsRequested(false);
                });
        }
    }, [documentationId, versionId, branches]);

    const onSubmit = useCallback(async (values: IApprovalForm, helpers: FormikHelpers<IApprovalForm>) => {
        try {
            await onCreateApproval(values.name, values.descriptionFormatted, validators);
        } catch (error) {
            addAlert(getErrorMessage(error), EAlertType.ERROR);
            helpers.setSubmitting(false);
        }
    }, [validators]);

    const onStartAddingValidators = useCallback(() => {
        setAddingValidator(state => !state);
    }, []);

    const onChangeMandatory = useCallback((id: string) => {
        setValidators(currentValidators => updateObjectsInArray(
            currentValidators,
            validator => validator.id === id,
            validator => ({ ...validator, isMandatory: !validator.isMandatory })
        ));
    }, []);

    const onRemoveApprover = useCallback((id: string) => {
        setValidators(currentValidators => currentValidators.filter(validator => validator.id !== id));
    }, []);

    const resolvePeopleResults = useCallback((filterText: string = '') => {
        const isEmail = isValidEmail(filterText || '');

        return dispatch<GetOrganizationMembersSearch>(getOrganizationMembersSearch(organizationId, filterText)).then((res: IOrganizationMember[]) => {
            if (!res?.length && isEmail) {
                return [{
                    text: filterText.trim(),
                    key: `invite-${uuidv4()}`,
                    data: {
                        text: filterText.trim(),
                        firstName: filterText.trim(),
                        lastName: '',
                        secondaryText: intl.formatMessage({ id: 'global.inviteByEmail' }),
                        imageUrl: '/assets/images/svgicons/mail.svg',
                        className: 'invite-email-persona',
                        emailAddress: filterText.trim(),
                        id: `invite-${uuidv4()}`
                    }
                }];
            }
            return res
                .filter(user => user.id && !(validators || []).find(validator => validator.id === user.id)?.id)
                .map(user => ({
                    text: `${user.firstName || ''} ${user.lastName || ''}`,
                    key: user.id,
                    data: {
                        text: `${user.firstName || ''} ${user.lastName || ''}`,
                        imageUrl: user.picture,
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName
                    }
                }));
        });
    }, [organizationId, validators]);

    const selectPeople = useCallback((selectedUsers: IValue[]) => {
        const newValidators = selectedUsers.map(user => typed<IValidator>({
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            isMandatory: true,
            emailContact: user.data.emailAddress,
            jobTitle: '',
            department: '',
            picture: user.data.imageUrl,
            cabinet: '',
            id: user.data.id
        }));

        setValidators(currentValidators => [...currentValidators, ...newValidators]);
        setAddingValidator(false);
    }, []);

    return branches?.length > 0 && (
        <Formik<IApprovalForm>
            initialValues={{
                name: branches.map(branch => branch.name).join(' & '),
                description: ''
            }}
            onSubmit={onSubmit}
            validationSchema={ApprovalFormSchema}
        >
            {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form>
                    <PageHeader
                        headerText={(
                            <>
                                <FontAwesomeIcon icon={faCodeMerge} />
                                <FormattedMessage id="norms.header.approvalRequest" />
                            </>
                        )}
                        rightCol={(
                            <>
                                <Button typeSchema={EButtonTypeSchema.TEXT} onClick={onCancel} disabled={isSubmitting}>
                                    <FormattedMessage id="global.cancel" />
                                </Button>
                                <Button type="submit" leftIco={faCodeMerge} disabled={isSubmitting}>
                                    <FormattedMessage id="norms.button.createApprovalRequest" />
                                </Button>
                                {isSubmitting && (
                                    <SpinnerWrapper>
                                        <Spinner size={ESpinnerSize.SMALL} />
                                    </SpinnerWrapper>
                                )}
                            </>
                        )}
                    />
                    <WhiteBox marginBottom>
                        <ApprovalFormHeader branches={branches} versionName={versionName} organizationUrlName={organizationUrlName} />
                        <Row>
                            <FormCol>
                                <Field
                                    name="name"
                                    render={({ field }: FieldProps) => (
                                        <FormikFieldInput
                                            field={field}
                                            errors={errors}
                                            touched={touched}
                                            label="Name"
                                        />
                                    )}
                                    label="Name"
                                />
                                <ApprovalLabel><FormattedMessage id="norms.label.description" /></ApprovalLabel>
                                <NewRT
                                    content={values.description}
                                    onChange={value => {
                                        setFieldValue('description', getTextFromDraft(value || ''));
                                        setFieldValue('descriptionFormatted', value);
                                    }}
                                />
                                {errors.description && (
                                    <div className="lcr-error">
                                        {errors.description}
                                    </div>
                                )}
                            </FormCol>
                            <ValidatorsCol>
                                <Loader loading={metadataValidatorsRequested}>
                                    <ApprovalLabel><FormattedMessage id="norms.validators" /></ApprovalLabel>
                                    {(validators || []).map(validator => (
                                        <SingleValidator
                                            key={validator.id}
                                            id={validator.id}
                                            firstName={validator.firstName}
                                            lastName={validator.lastName}
                                            photo={validator.picture}
                                            isMandatory={validator.isMandatory}
                                            onChangeMandatory={onChangeMandatory}
                                            onRemoveApprover={onRemoveApprover}
                                        />
                                    ))}
                                    {addingValidator && (
                                        <StaticDataPicker
                                            getData={resolvePeopleResults}
                                            onSelectElement={selectPeople}
                                        />
                                    )}
                                    {addingValidator ? (
                                        <ButtonGroup>
                                            <Button typeSchema={EButtonTypeSchema.TEXT} onClick={onStartAddingValidators}>
                                                <FormattedMessage id="global.cancel" />
                                            </Button>
                                        </ButtonGroup>
                                    ) : (
                                        <Button typeSchema={EButtonTypeSchema.TEXT} onClick={onStartAddingValidators} leftIco={faPlusCircle}>
                                            <FormattedMessage id="norms.button.addNewValidator" />
                                        </Button>
                                    )}
                                </Loader>
                            </ValidatorsCol>
                        </Row>
                    </WhiteBox>
                </Form>
            )}
        </Formik>
    );
};
