import { ReactNode, RefObject } from 'react';

export interface ITreePathItem {
    name: string;
    level: number;
    id: string;
    commonLevelId?: string;
}

export interface ITree<T = unknown> {
    name: string;
    icon?: string;
    level?: string;
    children?: ITree<T>[];
    id: string;
    commonLevelId?: string;
    orderNumber?: number;
    order?: number;
    data?: T;
    childContainsMatch?: boolean;
    isMatch?: boolean;
}

export enum ETreeExpandIcon {
    Expand,
    Arrow
}
export enum ETreeFolderNames {
    ConversationFiles = 'Conversation Files',
    OverviewFiles = 'Overview Files',
    SignedDocuments = 'Signed Documents',
    RecycleBin = 'Recycle bin',
    OverviewVersions = 'Overview Versions',
    Documents = 'Documents'
}

export interface ITreeViewProps {
    additionalChildrenKey?: string;
    noCollapse?: boolean;
    itemRef?: RefObject<any>; // it's not a bug, this can be any type of element
    defaultExpandPath?: string[];
    autoExpandLevels?: number;
    isLevelCollapsable?: boolean;
    defaultExpanded?: boolean;
    expandIcon?: ETreeExpandIcon;
    forceDefaultExpandPath?: boolean;
    closeNodesOnDefaultExpandPathChange?: boolean;
    sort?: boolean;
    className?: string;
    levelPadding?: number;
    renderIcon?(item: ITree): ReactNode;
    onLastLevelClick?(item: ITree, level: number);
    renderItem?(item: ITree, level: number, itemRef?: RefObject<any>, renderPath?: ITreePathItem[], isActive?: boolean, expandChildren?: () => void, hasChildren?: boolean, isOpen?: boolean): ReactNode; // it's not a bug, this can be any type of element
    renderAdditional?(item: ITree, level: number, renderPath?: ITreePathItem[], isActive?: boolean): ReactNode;
    onExpandChange?();
}
