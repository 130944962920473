import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactDom from 'react-dom';

import { getFileMetadata } from '../../../actions/clustersFilesystemActions';
import { PdfViewer } from './PdfViewer';

interface IFilesystemPdfViewerProps {
    document: string;
    pdfName: string;
    clusterId: string;
    currentPath: string[];
    closeViewer();
}

export const FilesystemPdfViewer: FC<React.PropsWithChildren<IFilesystemPdfViewerProps>> = (props) => {
    const dispatch = useDispatch();

    const downloadHandler = useCallback(() => {
        dispatch(getFileMetadata(props.clusterId, props.currentPath, props.pdfName)).then(response => {
            response?.directUri && (window.location.href = response?.directUri);
        });
    }, [props.currentPath, props.pdfName, props.clusterId]);

    // TODO: waiting for API for corect document
    return ReactDom.createPortal(
        <PdfViewer
            document={props.document}
            pdfName={props.pdfName}
            onDownloadClick={downloadHandler}
            onCloseClick={props.closeViewer}
        />, document.getElementById('react-app')
    );
};