export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';
export const GET_NOTIFICATIONS_ACTIVE_DATA = 'GET_NOTIFICATIONS_ACTIVE_DATA';
export const GET_NOTIFICATIONS_NONACTIVE_DATA = 'GET_NOTIFICATIONS_NONACTIVE_DATA';
export const UPDATE_RECIVER_RMS_STATUS = 'UPDATE_RECIVER_RMS_STATUS';
export const NEW_NOTIFICATION_COUNT_LOADED = 'NEW_NOTIFICATION_COUNT_LOADED';
export const NEW_NOTIFICATION_MARKED_ASS_SEEN = 'NEW_NOTIFICATION_MARKED_ASS_SEEN';
export const LOAD_MORE_ACTIVE_NOTIFICATIONS = 'LOAD_MORE_ACTIVE_NOTIFICATIONS';
export const LOAD_MORE_NONACTIVE_NOTIFICATIONS = 'LOAD_MORE_NONACTIVE_NOTIFICATIONS';
export const SIGNALR_NOTIFICATION_RECIVED = 'SIGNALR_NOTIFICATION_RECIVED';
export const SET_NOTIFICATION_VISIBILITY_FLAG = 'SET_NOTIFICATION_VISIBILITY_FLAG';
export const NAV_MENU_NOTIFICATION_UPDATED = 'NAV_MENU_NOTIFICATION_UPDATED';
