import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { rgba } from 'polished';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';

import { colorStack } from '../../../styleHelpers/colors';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { checkAttachmentSize, checkAttachmentType } from '../../../tools/attachment';
import { FileButton } from '../Buttons/Button';
import { Button, EButtonTypeSchema } from '../Buttons/NewButton';
import { Avatar, EAvatarSize } from '../Avatar/Avatar';

const Wrapper = styled.div`
    position: relative;
    .image-wrapper {
        position: relative;
        width: 160px;
        margin: 0 auto;
        img {
            width: 160px;
            height: 160px;
        }
        button.lcr-button {
            position: absolute;
            bottom: 0;
            right: 0;
            background: white;
        }
    }
    .cropper-wrapper {
        button.lcr-button {
            position: absolute;
            bottom: -10px;
            left: -20px;
            background: white;
        }
    }
`;

const Popup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(colorStack.black, .4)};
    z-index: 999;
`;

const ImageCropperWrapper = styled.div`
    ${boxShadow()};
    max-width: 1100px;
    max-height: 70vh;
    min-width: 270px;
    min-height: 290px;
    background: ${colorStack.white};
    padding: 1rem;
    position: relative;
    border-radius: 4px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 1rem;
`;

const Inner = styled.div`
    width: 100%;
    max-height: 55vh;
    .ReactCrop__image {
        max-height: 50vh;
    }
`;

const RightSide = styled.div`
    display: flex;
    gap: 1rem;
`;

interface IImageCropperProps {
    currentImageUrl: string | ArrayBuffer;
    returnImage(filePicture: File, canvasString: string | ArrayBuffer);
    closeHandler();
    onChange?(canvasString: string);
}

interface ImageCropperState {
    crop: any;
    croppedImageUrl: {};
    croppSrc: any;
    attachmentError: boolean;
    attachmentErrorMessage: string;
    fileConstructorSupport: boolean;
}

export default class ImageCropper extends Component<IImageCropperProps, ImageCropperState> {

    imageRef: React.RefObject<HTMLImageElement> = createRef();
    canvas: HTMLCanvasElement;
    // tslint:disable-next-line:no-null-keyword
    fileUrl = null;

    state: ImageCropperState = {
        // tslint:disable-next-line:no-null-keyword
        croppedImageUrl: null,
        // tslint:disable-next-line:no-null-keyword
        croppSrc: null,
        crop: {
            x: 0,
            y: 0,
            aspect: 1,
            width: 160
        },
        attachmentError: false,
        attachmentErrorMessage: '',
        fileConstructorSupport: false
    };

    componentDidMount() {
        try {
            // tslint:disable-next-line:no-unused-expression
            new File([new Blob(['test text'], { type: 'text/plain' })], 'test.png');
            this.setState({
                fileConstructorSupport: true
            });
        } catch (e) {
            this.setState({
                fileConstructorSupport: false
            });
        }
    }
    componentWillUnmount() {
        if (this.canvas) {
            this.canvas.remove();
        }
    }
    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    }

    getCroppedImg(image, crop, fileName) {
        this.canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        this.canvas.width = crop.width;
        this.canvas.height = crop.height;
        const ctx = this.canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve) => {
            this.canvas.toBlob((blob: any) => {
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    dataURItoBlob = (dataURI: string) => {

        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([new Blob([ia], { type: mimeString })], `id-${new Date().getTime()}.${mimeString.split('/')[1]}`);
    }

    makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            ).then(croppedImageUrl => this.setState({ croppedImageUrl }));
        }
    }

    onImageLoaded = (image) => {
        this.imageRef = image;
        this.makeClientCrop(this.state.crop);
    }

    onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            const attachSize = checkAttachmentSize(file.size, 'profile');
            const attachType = checkAttachmentType(file.type, 'profile');
            if (attachSize.isValid && attachType.isValid) {
                reader.addEventListener('load', () => {
                    this.setState({
                        croppSrc: reader.result
                    });
                });
                this.setState({
                    attachmentError: false,
                    attachmentErrorMessage: ''
                });
                reader.readAsDataURL(file);
            } else {
                this.setState({
                    attachmentError: true,
                    attachmentErrorMessage: attachType.message || attachSize.message
                });
            }
        }
    }

    onloadURLPicture = (event: any) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            const reader = new FileReader();
            const file = event.currentTarget.files[0];
            reader.onload = (e) => {
                this.props.returnImage(file, reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    saveHandler = () => {
        this.props.returnImage(this.dataURItoBlob(this.canvas.toDataURL()), this.canvas.toDataURL());
    }

    render() {
        const { currentImageUrl } = this.props;
        const { fileConstructorSupport, croppSrc, crop, attachmentError, attachmentErrorMessage } = this.state;
        return (
            <Wrapper>
                <Popup>
                    <ImageCropperWrapper>
                        <ButtonsWrapper>
                            <FileButton onChange={fileConstructorSupport ? this.onSelectFile : this.onloadURLPicture} accept="image/x-png,image/gif,image/jpeg">
                                <FormattedMessage id="legalEntity.button.uploadALogo" />
                            </FileButton>
                            <RightSide>
                                {fileConstructorSupport &&
                                    <Button onClick={this.props.closeHandler} typeSchema={EButtonTypeSchema.TERTIARY}>
                                        <FormattedMessage id="global.close" />
                                    </Button>
                                }
                                {croppSrc &&
                                    <Button onClick={this.saveHandler} leftIco={faSave}>
                                        <FormattedMessage id="global.save" />
                                    </Button>
                                }
                            </RightSide>
                        </ButtonsWrapper>
                        {croppSrc ?
                            <Inner>
                                {fileConstructorSupport ?
                                    (
                                        <ReactCrop
                                            src={croppSrc}
                                            crop={crop}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                            minWidth={160}
                                            minHeight={160}
                                        />
                                    ) : (
                                        <Avatar
                                            picture={croppSrc || currentImageUrl}
                                            preventRedirection
                                            firstName=""
                                            lastName=""
                                            id=""
                                            size={EAvatarSize.TINY}
                                        />
                                    )
                                }
                            </Inner>
                            :
                            <div className="image-wrapper">
                                <Avatar
                                    picture={currentImageUrl as string}
                                    preventRedirection
                                    firstName=""
                                    lastName=""
                                    id=""
                                    size={EAvatarSize.XLARGE}
                                />
                            </div>

                        }
                        {attachmentError &&
                            <div className="error-message">{attachmentErrorMessage}</div>
                        }
                    </ImageCropperWrapper>
                </Popup>
            </Wrapper>
        );
    }
}
