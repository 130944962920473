import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';

const Wrapper = styled.a`
    text-decoration: underline!important;
    color: ${colorStack.content};
`;

export const MailType: FC<React.PropsWithChildren<unknown>> = props => {
    return (
        <Wrapper href={`mailto:${props.children}`}>
            {props.children}
        </Wrapper>
    );
};