import { ICampaign, CampaignDto, IGetDraftCampaign } from '../entities/ICampaigns';
import { action, downloadFile } from '../tools/reduxTools';

// probably obsolete
export enum ECampaignStatus {
    Draft = 0,
    Active = 1,
    Completed = 2,
    Closed = 3
}

export const createCampaign = (organizationId: string, data: ICampaign, isDraft: boolean ) => action<Promise<CampaignDto>>((dispatch, getState, http) => {
    const body = {
        // tslint:disable-next-line:no-null-keyword
        journey: null,
        name: data.campaignName,
        type: data.type,
        actionUrl: data.actionUrl,
        subject: data.subject,
        message: data.message,
        // tslint:disable-next-line:no-null-keyword
        principals: data?.persons || null,
        // tslint:disable-next-line:no-null-keyword
        csvFileDetails: data?.csvFile || null,
        campaignStatus: isDraft ? ECampaignStatus.Draft : ECampaignStatus.Active
    };
    return http.organizationsApiPost(`/organizations/${organizationId}/campaigns`, body);
});

export const updateCampaign = (organizationId: string, campaignId: string,  data: ICampaign, isDraft: boolean ) => action<Promise<CampaignDto>>((dispatch, getState, http) => {
    const body = {
        // tslint:disable-next-line:no-null-keyword
        journey: null,
        name: data.campaignName,
        type: data.type,
        actionUrl: data.actionUrl,
        subject: data.subject,
        message: data.message,
        // tslint:disable-next-line:no-null-keyword
        principals: data.persons || null,
        // tslint:disable-next-line:no-null-keyword
        csvFileDetails: data?.csvFile || null,
        campaignStatus: isDraft ? ECampaignStatus.Draft : ECampaignStatus.Active
    };
    return http.organizationsApiPut(`/organizations/${organizationId}/campaigns/${campaignId}`, body);
});

export const getSingleCampaign = (organizationId: string, campaignId: string) => action<Promise<IGetDraftCampaign>>((dispatch, getState, http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/campaigns/${campaignId}`);
});

export const getCampaignTemplate = (organizationId: string) => action<Promise<void>>((dispatch, getState, http) => {
    const { context, config } = getState();
    const url = `${config.organizationsApiUrl}/organizations/${organizationId}/campaigns/recipients/csv-template`;
    return downloadFile(url, 'recipients.csv',  context.token, config.subKey);
});

export const validateCsvFile = (organizationId: string, body: {name: string, fileId: string, contentType: string, fileReference: string}) => action<Promise<IGetDraftCampaign>>((dispatch, getState, http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/campaigns/recipients/csv/validate`, body);
});
