import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { RadioButton } from '../../../../Common/Inputs/RadioButton';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { NewRT } from '../../../../Common/RichText/NewRT';

const Wrapper = styled.div`
    width: 100%;
    overflow: hidden;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

const RadioInputSection = styled.div`
    display: flex;
    label {
        margin-right: 29px;
    }
`;

export const GroupingStep4: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
    }, []);

    const onBooleanChange = (field: string, value: boolean) => {
        eApprovalContext.modifyData(value, field, props.context, props.stepName, props.stepNumber);
    };

    const onRichTextChange = (value: string) => {
        if (value !== eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.specification) {
            const span = document.createElement('span');
            span.innerHTML = value;
            if (span.innerText?.trim()?.length > 0) {
                eApprovalContext.modifyData(value, 'specification', props.context, props.stepName, props.stepNumber);
            } else if (span.innerText?.trim()?.length === 0) {
                eApprovalContext.modifyData('', 'specification', props.context, props.stepName, props.stepNumber);
            }
        }
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis numberOfLines={4} tooltipTitle={intl.formatMessage({ id: `eApproval.wizard.step4.${[EApprovalTypes.Grouping]}.isEthicClause.tooltip` })} required>
                        <FormattedMessage id={`eApproval.wizard.step4.${[EApprovalTypes.Grouping]}.isEthicClause`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isEthicClause === true}
                            onClick={() => onBooleanChange('isEthicClause', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isEthicClause === false}
                            onClick={() => onBooleanChange('isEthicClause', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis numberOfLines={3} tooltipTitle={intl.formatMessage({ id: `eApproval.wizard.step4.${[EApprovalTypes.Grouping]}.havePartners.tooltip` })} required>
                        <FormattedMessage id={`eApproval.wizard.step4.${[EApprovalTypes.Grouping]}.havePartners`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.havePartners === true}
                            onClick={() => onBooleanChange('havePartners', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.havePartners === false}
                            onClick={() => onBooleanChange('havePartners', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis numberOfLines={3} tooltipTitle={intl.formatMessage({ id: `eApproval.wizard.step4.${[EApprovalTypes.Grouping]}.haveObservations.tooltip` })} required>
                        <FormattedMessage id={`eApproval.wizard.step4.${[EApprovalTypes.Grouping]}.haveObservations`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.haveObservations === true}
                            onClick={() => onBooleanChange('haveObservations', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.haveObservations === false}
                            onClick={() => onBooleanChange('haveObservations', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            {eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.haveObservations === true &&
                <Row numberOfElements={1} noBorder>
                    <div>
                        <NewRT
                            label={intl.formatMessage({ id: `eApproval.wizard.step4.${[EApprovalTypes.Grouping]}.specification` })}
                            required
                            content={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.specification}
                            onChange={onRichTextChange}
                        />
                    </div>
                </Row>
            }
        </Wrapper>
    );
};
