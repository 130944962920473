import React, { ReactNode, PropsWithChildren, forwardRef } from 'react';
import { FieldProps, FormikTouched, FormikErrors, useField } from 'formik';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { FieldWrapper, FormError } from './Common';
import { TextComponent } from '../Inputs/TextComponent';
import { IValue } from '../../../entities/IPickers';

const ChildWrapper = styled.div`
    margin: 0.5rem 0;
    font-size: ${fontSize[13]};
    color: ${colorStack.darkBlue};

        input {
        ::placeholder {
            color: red;
        }
    }
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    > div {
        &:first-child {
            margin-right: .5rem;
            width: 100%;
        }
    }
`;

interface IInputProps {
    className?: string;
    error?: ReactNode;
    errorContent?: ReactNode;
    label?: ReactNode;
    required?: boolean;
    value?: string | number;
    name?: string;
    autoFocus?: boolean;
    labelColor?: string;
    button?: ReactNode;
    disabled?: boolean;
    onChange?(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string, selectedCurrency?: IValue): void;
    onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
    onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
}

export const Input = forwardRef<HTMLInputElement, PropsWithChildren<IInputProps>>(({ className, error, errorContent, label, required, value, name, children, autoFocus, labelColor, button, disabled, onChange, onBlur, onFocus }, ref) => (
    <FieldWrapper
        className={className || ''}
        error={!!error}
        label={label}
        labelColor={labelColor}
        required={required}
    >
        <InputWrapper>
            <TextComponent ref={ref} onChange={(valueString, currency, e) => onChange(e, valueString, currency)} onBlur={onBlur} value={value as string} name={name} autoFocus={autoFocus} onFocus={onFocus} disabled={disabled} />
            {button &&
                <div>
                    {button}
                </div>
            }
        </InputWrapper>
        {error && (
            <FormError>
                {errorContent || error}
            </FormError>
        )}
        {children && (
            <ChildWrapper>{children}</ChildWrapper>
        )}
    </FieldWrapper>
));

interface IFormikFieldInputProps<T = object> {
    field?: FieldProps['field'];
    errors?: FormikErrors<T>;
    touched?: FormikTouched<T>;
    label: ReactNode;
    className?: string;
    required?: boolean;
    fieldName?: keyof T;
    autoFocus?: boolean;
    disabled?: boolean;
    onFocus?();
}

export const FormikFieldInput = <T extends object>({ field, touched, errors, label, children, required, className, fieldName, autoFocus, disabled, onFocus }: PropsWithChildren<IFormikFieldInputProps<T>>) => {
    const [contextField, meta, helpers] = useField(fieldName as string);
    const fieldProps = fieldName ? contextField : field;
    const fieldError = fieldName ? (meta.touched && meta.error) : (touched[fieldProps.name] && errors[fieldProps.name]);
    const fieldErrorContent = fieldName ? meta.error : errors[fieldProps.name];

    return (
        <Input
            {...fieldProps}
            error={fieldError}
            errorContent={fieldErrorContent}
            label={label}
            className={className || ''}
            required={required}
            autoFocus={autoFocus}
            onFocus={onFocus}
            disabled={disabled}
        >
            {children}
        </Input>
    );
};
