import React, { createContext, FC, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { ITheme } from '../entities/IConfiguration';
import * as themeActions from '../actions/themeActions';
import { getWithExpiry, storeWithExpiry } from './storage';
import { typed, deviceType } from './generalTools';

const themeCacheTtl = 1000 * 60 * 5;
const themeContext = createContext<IThemeContext>(undefined);

export interface IThemeContext {
    theme: ITheme;
    setTheme: React.Dispatch<React.SetStateAction<ITheme>>;
}

export const ThemeProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const dispatch = useDispatch();
    const [theme, setTheme] = useState<ITheme>((() => {
        const storedTheme = getWithExpiry('theme');
        return storedTheme && typed<ITheme>(JSON.parse(storedTheme));
    })());

    useEffect(() => {
        !theme && dispatch(themeActions.getTheme()).then(response => {
            storeWithExpiry('theme', JSON.stringify(response), themeCacheTtl);
            setTheme(response);
        });
    }, []);

    const mergedTheme = useMemo(() => ({
        ...theme,
        ...(THEME || {}) as ITheme,
        ...deviceType()
    }), [theme]);

    const contextProviderValue = useMemo(() => ({ theme: mergedTheme, setTheme }), [mergedTheme, setTheme]);

    return (
        <StyledThemeProvider theme={mergedTheme}>
            <themeContext.Provider value={contextProviderValue}>
                {children}
            </themeContext.Provider>
        </StyledThemeProvider>
    );
};

export const useThemeService = () => {
    const dispatch = useDispatch();
    const context = useContext(themeContext);

    const getTheme = useCallback(async () => {
        return dispatch(themeActions.getTheme());
    }, []);

    const saveTheme = useCallback((theme: ITheme) => new Promise<void>((resolve) => {
        // mock, will be replaced with API call
        setTimeout(() => {
            context.setTheme(theme);
            storeWithExpiry('theme', JSON.stringify(theme), themeCacheTtl);
            resolve();
        }, 1000);
    }), []);

    return {
        theme: context.theme,
        getTheme,
        saveTheme
    };
};
