import { ILabelTranslation } from './ILabelTranslation';

export interface ISearch {
    items: ISearchUser[],
    facets: {}[],
    count: number
}

export interface ISearchUser {
    firstName: string;
    lastName: string;
    picture: string;
    cabinet: string;
    id: string;
    jobTitle: string;
}

export const filtersMap = {
    Relation: 'RelationIds',
    ExpertisesList: 'ExpertisesIds',
    CompetencesList: 'SpecialitiesIds',
    CountryList: 'CountriesIds',
    RoleFunctionList: 'RoleFunctionListIds',
    Cabinet: 'CompaniesIds',
    BusinessSectorsList: 'BusinessSectorsIds',
    BarAdmissionList: 'BarAdmissionIds',
    LanguageList: 'LanguageIds'
};

export interface IFacetsNames {
    [key: string]: {
        id: string;
    }
}

export const FacetsNames: IFacetsNames = {
    BarAdmissionList: { id: 'search.filter.barAdmissions' },
    BusinessSectorsList: { id: 'search.filter.sectors' },
    Cabinet: { id: 'search.filter.companies' },
    CompetencesList: { id: 'search.filter.specialties' },
    CountryList: { id: 'search.filter.countries' },
    ExpertisesList: { id: 'search.filter.areas' },
    LanguageList: { id: 'search.filter.languages' },
    RoleFunctionList: { id: 'search.filter.functions' },
    Relation: { id: 'search.filter.myRelations' },
    Department: { id: 'search.filter.department' },
    JobTitle: { id: 'search.filter.jobTitle' },
    barAdmissions: { id: 'search.filter.barAdmissions' },
    businessSectors: { id: 'search.filter.sectors' },
    cabinet: { id: 'search.filter.companies' },
    competences: { id: 'search.filter.specialties' },
    countries: { id: 'search.filter.countries' },
    expertises: { id: 'search.filter.areas' },
    languages: { id: 'search.filter.languages' },
    roleFunctions: { id: 'search.filter.functions' },
    roleCategories: { id: 'search.filter.categories' },
    relation: { id: 'search.filter.myRelations' },
    talents: { id: 'search.filter.talents' },
    teams: { id: 'search.filter.teams' },
    departments: { id: 'search.filter.department' },
    jobTitle: { id: 'search.filter.jobTitle' },
    firstReferentials: { id: 'search.filter.firstReferentials' },
    secondReferentials: { id: 'search.filter.secondReferentials' },
    thirdReferentials: { id: 'search.filter.thirdReferentials' },
    firstReferential: { id: 'search.filter.firstReferentials' },
    secondReferential: { id: 'search.filter.secondReferentials' },
    thirdReferential: { id: 'search.filter.thirdReferentials' },
    legalEntities: { id: 'search.filter.legalEntities' },
    areaOfResponsibilities: { id: 'search.filter.areaOfResponsibilities' },
    items: { id: 'search.filter.items' },
    powers: { id: 'search.filter.powers' },
    financialLimit: { id: 'search.filter.financialLimit' },
    levels: { id: 'search.filter.level' },
    canSubdelegate: { id: 'search.filter.canSubdelegate' },
    financialLimits: { id: 'search.filter.financialLimits' }
};

export const ClusterFacetsNames: IFacetsNames = {
    geographies: { id: 'search.filter.countries' },
    businessSectors: { id: 'search.filter.sectors' },
    expertises: { id: 'search.filter.areas' },
    specialities: { id: 'search.filter.specialties' },
    statuses: { id: 'search.filter.statuses' },
    accounts: { id: 'search.filter.accounts' },
    tags: { id: 'search.filter.tags' },
    natures: { id: 'search.filter.natures' },
    involvedParties: { id: 'search.filter.involvedParties' },
    firstReferentials: { id: 'search.filter.firstReferentials' },
    secondReferentials: { id: 'search.filter.secondReferentials' },
    thirdReferentials: { id: 'search.filter.thirdReferentials' },
    peopleInCharge: { id: 'search.filter.peopleInCharge' }
};

export const PanelFacetsNames: IFacetsNames = {
    baradmissions: { id: 'search.filter.barAdmissions' },
    expertises: { id: 'search.filter.areas' },
    geographies: { id: 'search.filter.countries' },
    specialities: { id: 'search.filter.specialties' },
    datatypes: { id: 'search.filter.dataType' },
    firstreferentials: { id: 'search.filter.firstReferentials' },
    secondreferentials: { id: 'search.filter.secondReferentials' },
    thirdreferentials: { id: 'search.filter.thirdReferentials' },
    tags: { id: 'search.filter.tags' }
};

export const LegalEntitiesNames: IFacetsNames = {
    Country: { id: 'search.filter.country' },
    LegalForm: { id: 'search.filter.legalForm' },
    TypeOfLegalEntity: { id: 'search.filter.typeOfLegalEntity' }
};

export interface SearchFacets {
    barAdmissions: SingleFacet[];
    businessSectors: SingleFacet[];
    competences: SingleFacet[];
    countries: SingleFacet[];
    departments: SingleFacet[];
    expertises: SingleFacet[];
    firstReferentials: SingleFacet[];
    languages: SingleFacet[];
    roleCategories: SingleFacet[];
    roleFunctions: SingleFacet[];
    secondReferentials: SingleFacet[];
    talents: SingleFacet[];
    teams: SingleFacet[];
    thirdReferentials: SingleFacet[];
}

export interface SingleFacet {
    Key: string;
    id: string;
    logicalId: string;
    name: string;
    parents: string;
}

export interface SingleFacetValue {
    Name: ILabelTranslation[];
    id: string;
}

export interface ISearchResult<T = any> {
    originalResult?: T;
}
