import React, { ComponentProps, ComponentType, LazyExoticComponent, Suspense } from 'react';

import { ESpinnerSize, Spinner } from '../components/Common/Spinner/Spinner';

const lazyBlock = <T extends ComponentType<React.PropsWithChildren<any>>>(LazyComponent: LazyExoticComponent<T>, spinnerSize:  ComponentProps<typeof Spinner>['size'] = ESpinnerSize.SMALL) =>
    (props: ComponentProps<T>) => (
        <Suspense fallback={<Spinner size={spinnerSize} />}>
            <LazyComponent {...props} />
        </Suspense>
    );

export default lazyBlock;
