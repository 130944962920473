import React, { FC } from 'react';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { AreaOfResponsabilities } from './AreaOfResponsabilities';

export interface IStakeCoupleCollection {
    couple: [IClusterStakeProperty, IClusterStakeProperty];
    editStake: boolean;
    errors: IStakeError;
    setEditingData(couple: [IClusterStakeProperty, IClusterStakeProperty]);
}

export interface IStakeError {
    aggregatedErrors: {
        propertyId: string;
        stakeInstanceId: string;
        errorCode: number;
        message: string;
    }[]
    errorCode: number;
    message: string;
}

const components = {
    'area-of-resp': AreaOfResponsabilities
};

export const StakeCoupleCollection: FC<React.PropsWithChildren<IStakeCoupleCollection>> = (props) => {
    const [parent] = props.couple;
    const Component = components[parent?.key];

    return <>{!!Component && <Component {...props} />}</>;
};
