import { faAlignLeft, faCheckSquare, faCaretCircleDown, faMapMarkedAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';

import { IClusterSingleDynamicStake, IClusterStakeProperty, EPropertieKey } from '../../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../../styleHelpers/fontSizes';
import { addressFormatterGlobal } from '../../../../../../tools/addressFormatter';
import { Checkbox } from '../../../../../Common/Inputs/Checkbox';
import * as Common from '../Common';
import { AttachmentSection } from './AttachmentSection';

const Name = styled.span`
    font-size: ${fontSize[16]};
    font-weight: 500;
    color: ${colorStack.darkBlue};
`;

interface IDonorLayoutProps {
    stake: IClusterSingleDynamicStake;
    propertyByKey: {
        [key in EPropertieKey]?: IClusterStakeProperty;
    }
    downloadHandler();
}

export const DonorLayout: FC<React.PropsWithChildren<IDonorLayoutProps>> = ({ stake, propertyByKey, downloadHandler }) => {
    return (
        <Common.Wrapper>
            <a id={stake.id} />
            <AttachmentSection
                title={stake.name}
                downloadHandler={downloadHandler}
            />
            <Common.PartyInfoWrapper>
                <Common.DetailSection>
                    <Common.LeftSide>
                        <Common.EntityDetails>
                            <Name>
                                {propertyByKey[EPropertieKey.NonRegisteredUserFirstName]?.value || ''} {propertyByKey[EPropertieKey.NonRegisteredUserLastName]?.value || ''}
                            </Name>
                            <Common.AdditionalInfo>
                                {propertyByKey[EPropertieKey.NonRegisteredUserUserJob]?.value} - {propertyByKey[EPropertieKey.NonRegisteredUserCabinet]?.value}
                            </Common.AdditionalInfo>
                        </Common.EntityDetails>
                    </Common.LeftSide>
                    <Common.RightSide>
                        {propertyByKey[EPropertieKey.NonRegisteredUserAddress]?.value &&
                            <>
                                <Common.IconWrapper>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    <span>{propertyByKey[EPropertieKey.NonRegisteredUserAddress]?.name}</span>
                                </Common.IconWrapper>
                                <Common.Representatives>
                                    {addressFormatterGlobal(propertyByKey[EPropertieKey.NonRegisteredUserAddress].value)}
                                </Common.Representatives>
                            </>
                        }
                        {propertyByKey[EPropertieKey.JourneyPartiesRelationshipNature]?.value?.value &&
                            <>
                                <Common.IconWrapper>
                                    <FontAwesomeIcon icon={faCaretCircleDown} />
                                    <span>{propertyByKey[EPropertieKey.JourneyPartiesRelationshipNature]?.name}</span>
                                </Common.IconWrapper>
                                <Common.Representatives>
                                    {propertyByKey[EPropertieKey.JourneyPartiesRelationshipNature].value.value}
                                </Common.Representatives>
                            </>
                        }
                    </Common.RightSide>
                </Common.DetailSection>
                <Common.BottomSection>
                    {typeof propertyByKey[EPropertieKey.Boolean]?.value === 'boolean' &&
                        <Common.InlineProperty>
                            <Common.IconWrapper>
                                <FontAwesomeIcon icon={faCheckSquare} />
                                <span>{propertyByKey[EPropertieKey.Boolean]?.name}</span>
                            </Common.IconWrapper>
                            <Checkbox checked={propertyByKey[EPropertieKey.Boolean].value} />
                        </Common.InlineProperty>
                    }
                    {propertyByKey[EPropertieKey.JourneyPartiesRelationshipDescription]?.value &&
                        <Common.InlineProperty>
                            <Common.IconWrapper>
                                <FontAwesomeIcon icon={faAlignLeft} />
                                <span>{propertyByKey[EPropertieKey.JourneyPartiesRelationshipDescription]?.name}</span>
                            </Common.IconWrapper>
                            <Common.Representatives>
                                {propertyByKey[EPropertieKey.JourneyPartiesRelationshipDescription].value}
                            </Common.Representatives>
                        </Common.InlineProperty>
                    }
                </Common.BottomSection>
            </Common.PartyInfoWrapper>
        </Common.Wrapper>
    );
};