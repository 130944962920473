import React, { FC, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { rgba } from 'polished';

import { colorStack } from '../../../styleHelpers/colors';
import { EPopupSizes } from '../../../entities/IGlobal';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { Button, EButtonColorSchema, EButtonSize, EButtonTypeSchema } from '../Buttons/NewButton';
import { MultilineTextWithEllipsis } from '../TextWithEllipsis/MultilineTextWithEllipsis';

const Wrapper = styled.div <{ offsetLeft?: string }>`
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${rgba(colorStack.label, .75)};
    top: 0;
    left: 0;
    ${props => props.offsetLeft && css`
        left: ${props.offsetLeft};
    `}
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    .custom-iframe-wopi {
        width: 100%;
        height: 70vh;
    }
`;

const InnerWrapper = styled.div`
    max-height: 95vh;
    border-radius: 8px;
    background: ${colorStack.white};
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    gap: .5rem;
`;

const Content = styled.div<{ size: EPopupSizes }>`
    padding: 1.5rem;
    color: ${colorStack.content};
    min-width: 250px;
    ${props => props.size === EPopupSizes.XSMALL && css`
        width: auto;
        max-width: 560px;
    `}
    ${props => props.size === EPopupSizes.SMALL && css`
        width: 560px;
    `}
    ${props => props.size === EPopupSizes.MEDIUM && css`
        width: 660px;
    `}
    ${props => props.size === EPopupSizes.BIG && css`
        width: 760px;
    `}
    ${props => props.size === EPopupSizes.MONSTER && css`
        width: 960px;
    `}
    ${props => props.size === EPopupSizes.FULL && css`
        width: calc(100vw - 130px);
    `}
`;

const Title = styled.span`
    font-weight: 700;
    color: ${colorStack.content};
    ${fontSizeAndHeight[20]};
`;

const Top = styled.div`
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    background: ${colorStack.whiteGrey};
    border-radius: 8px 8px 0 0;
    align-items: center;
    svg {
        cursor: pointer;
        margin-left: 1rem;
        font-size: 24px;
    }
`;

export interface IProps {
    showPopup: boolean;
    title?: string;
    noButtonText?: React.ReactNode;
    yesButtonText?: React.ReactNode;
    loading?: boolean;
    noButtonLoading?: boolean;
    size?: EPopupSizes;
    disabled?: boolean;
    children: React.ReactNode;
    offsetLeft?: string;
    hideButtonWithNoHandlerFunction?: boolean;
    dataNonClick?: boolean;
    noButtonStyles?: {
        typeSchema?: EButtonTypeSchema;
        colorSchema?: EButtonColorSchema;
    }
    yesButtonStyles?: {
        typeSchema?: EButtonTypeSchema;
        colorSchema?: EButtonColorSchema;
    }
    additionalButtons?: React.ReactNode;
    noHandler?();
    closePopup?();
    yesHandler?(e?: React.MouseEvent<HTMLButtonElement>);
}

export const ConfirmPopup: FC<React.PropsWithChildren<IProps>> = props => {
    const confirmPopupWrapper = useRef<HTMLDivElement>();

    const closeHandler = () => {
        if (props?.closePopup) {
            return props?.closePopup();
        } else {
            return props?.noHandler();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (confirmPopupWrapper.current && !confirmPopupWrapper.current.contains(event.target)) {
                closeHandler();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props?.noHandler]);

    return (
        <>
            {props.showPopup &&
                <Wrapper offsetLeft={props.offsetLeft} data-nonclick={props.dataNonClick}>
                    <InnerWrapper ref={confirmPopupWrapper}>
                        <Top>
                            <Title>
                                <MultilineTextWithEllipsis numberOfLines={1} noTooltip>
                                    {props.title}
                                </MultilineTextWithEllipsis>
                            </Title>
                            <FontAwesomeIcon icon={faXmark} onClick={closeHandler} color={colorStack.darkGrey} />
                        </Top>
                        <Content size={props.size || EPopupSizes.SMALL}>
                            {props.children}
                        </Content>
                        <Buttons>
                            {props.noHandler && !props.hideButtonWithNoHandlerFunction &&
                                <Button
                                    onClick={props.noHandler}
                                    size={EButtonSize.SMALL}
                                    typeSchema={props?.noButtonStyles?.typeSchema || EButtonTypeSchema.TERTIARY}
                                    colorSchema={props?.noButtonStyles?.colorSchema}
                                    loading={props.noButtonLoading}
                                    disabled={props.noButtonLoading}
                                >
                                    {props.noButtonText || <FormattedMessage id="global.no" />}
                                </Button>
                            }
                            {props.additionalButtons}
                            {props.yesHandler &&
                                <Button
                                    onClick={props.yesHandler}
                                    size={EButtonSize.SMALL}
                                    loading={props.loading}
                                    disabled={props.loading || props.disabled}
                                    typeSchema={props?.yesButtonStyles?.typeSchema}
                                    colorSchema={props?.yesButtonStyles?.colorSchema}
                                >
                                    {props.yesButtonText || <FormattedMessage id="global.yes" />}
                                </Button>
                            }
                        </Buttons>
                    </InnerWrapper>
                </Wrapper>
            }
        </>
    );
};
