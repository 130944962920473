// isArrDifferent
// compares arrays by length and shallow equal

export const isArrDifferent = <T extends unknown>(a: T[] = [], b: T[] = []): boolean => a.length !== b.length || a.some((el, index) => el !== b[index]);

// removeArrDuplicates
// removes duplicates from array of simple values (array of strings, numbers etc, NOT objects)

//      how to use:

//      removeArrDuplicates(['a', 'b', 'c', 'a', 'b', 'd'])
//      will return:
//      ['a', 'b', 'c', 'd']

// @ts-ignore
export const removeArrDuplicates = <T = unknown>(arr: T[]): T[] => [...new Set(arr)];

// removeArrDuplicates
// removes duplicates from array of objects
// immutable function, returns new array

//      how to use:

//      given array:
//      arr = [
//          { id: 'abc', name: 'lorem' },
//          { id: 'def', name: 'ipsum' },
//          { id: 'ghi', name: 'ipsum' }
//      ]
//      removeDuplicatesByKey(arr, item => item.name)
//      will return:
//      [
//          { id: 'abc', name: 'lorem' },
//          { id: 'ghi', name: 'ipsum' }
//      ]
//
//      with merger, given array:
//      arr = [
//          { id: '1', value: true },
//          { id: '1', value: false },
//          { id: '2', value: true }
//      ]
//      we want to remove duplicated objects with `id: '1'`, but leave `value: true`
//      removeDuplicatesByKey(arr, item => item.id, (a, b) => { ...a, ...b, value: (a.value || b.value) ? true : false })

export const removeDuplicatesByKey = <T = unknown>(arr: T[], callback: (object: T) => string, objectMerger?: (prev: T, next: T) => T): T[] => {
    const arrayAsObject: { [key: string]: T } = arr.reduce((acc, item) => ({
        ...acc,
        [callback(item)]: !!acc?.[callback(item)] && !!objectMerger ? objectMerger(acc[callback(item)], item) : item
    }), {});
    return Object.values(arrayAsObject);
};

// updateObjectsInArray
// updates one or more objects in array of objects when callback returns true
// immutable function, returns new array

//      how to use:

//      updateObjectsInArray(arr, item => item.id === someId, { someProp: true })
//      or:
//      updateObjectsInArray(arr, item => item.id === someId, item => { someProp: true, otherProp: item.whateva })
//      or:
//      updateObjectsInArray(arr, item => item.id === someId, item => ({ ...item, someProp: true, otherProp: item.whateva }))

export const updateObjectsInArray = <T = unknown>(arr: T[], callback: ((object: T, index: number) => boolean), updater: Partial<T> | ((obj: T) => Partial<T>)): T[] => {
    return (arr || []).map((item, index) => {
        if (callback(item, index)) {
            return {
                ...item,
                ...(typeof updater === 'function' ? updater(item) : updater)
            };
        } else {
            return item;
        }
    });
};

// arrayToObject
// convert array of objects to object with objects.
// uses value returned from the callback as key

//      how to use:

//      given array:
//      arr = [
//          { id: 'abc', name: 'lorem' },
//          { id: 'def', name: 'ipsum' }
//      ]
//      arrayToObject(arr, item => item.id)
//      will return object:
//      {
//          abc: { id: 'abc', name: 'lorem' },
//          def: { id: 'def', name: 'ipsum' }
//      }

export const arrayToObject = <T = unknown, R = T>(arr: T[], callback: (object: T) => string, reduceItem?: (item: T, index: number, arr: T[]) => R): { [key: string]: R } => {
    return (arr || []).reduce((obj, item, index) => ({
        ...obj,
        [callback(item)]: reduceItem ? reduceItem(item, index, arr) : item
    }), {});
};

export const flatArray = <T>(arr: Array<Array<T>>): Array<T> => [].concat.apply([], arr);

export const flatTree = <T>(tree: T, getChildren: (element: T) => T[], parseElement?: (element: T) => T): T[] => {
    const result: T[] = [];

    const parse = (node: T) => {
        const children = getChildren(node);
        children?.length && children.forEach(child => {
            result.push(parseElement ? parseElement(child) : child);
            parse(child);
        });
    };

    result.push(parseElement ? parseElement(tree) : tree);
    parse(tree);

    return result;
};

export const findInTree = <T>(tree: T, getChildren: (element: T) => T[], comparator: (element: T) => boolean) => {
    let result: T = undefined;
    const parse = (childNode: T): void => {
        if (comparator(childNode)) {
            result = { ...childNode };
        } else {
            (getChildren(childNode) || []).forEach(child => parse(child));
        }
    };

    parse(tree);

    return result;
};

export const chunkingArray = (inputArray: any[], perChunk: number = 10) => {

    const result: any[][] = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);

    return result;
};