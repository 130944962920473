import { ReactNode } from 'react';

import { ISearchResult } from './ISearch';
import { IPrincipal } from './IPermissions';
import { IFullUserProfile } from './IProfile';

export interface IRoleManagerUser<T = any> extends ISearchResult<T> {
    lastName: string;
    firstName: string;
    id: string;
    picture: string;
    role: string | number;
    roleId?: string;
    type?: string;
    email?: string;
    isShadow?: boolean;
}

export interface ISplittedList {
    [role: string]: IPrincipal[];
}

export interface IRoleManagerEmailInvitation {
    email: string;
    role: string;
    roleId: string;
}

export interface IRoleManagerProps {
    users: IPrincipal[];
    name: string;
    withRoleSelect?: boolean;
    defaultRole?: string;
    withInviteMessage?: boolean;
    resourceId?: string;
    resourceType: string;
    roles: {
        key: string | number;
        text: string;
        roleName: string;
        roleId: string;
        tooltip?: string | JSX.Element | JSX.Element[];
        header(users: IPrincipal[]): ReactNode;
    }[];
    onRoleChange(userId: string, roleKey: string | number);
    onRemoveUser?(userId: string, userName: string);
    searchResolve?(query: string): Promise<unknown> | void;
    addSearchResolve?(query: string): Promise<IFullUserProfile[]>;
    onAddUsers?(message: string, users: IRoleManagerUser[], emailInvitations?: IRoleManagerEmailInvitation[]);
}

export const EMAIL_INVITATION: string = 'EMAIL_INVITATION';

export enum EUserPrivacy {
    RegisteredUsers = 'RegisteredUsers',
    Hidden = 'Hidden'
}
