import { OrganizationPermissions } from '../../entities/IPermissions';

export const SET_ORGANIZATIONS_NAME = 'SET_ORGANIZATIONS_NAME';
export const GET_LAST_ORGS_MEMBERS = 'GET_LAST_ORGS_MEMBERS';
export const CLEAR_ORGS_LAST_MEMBERS = 'CLEAR_ORGS_LAST_MEMBERS';
export const GET_ORGS_SEARCH = 'GET_ORGS_SEARCH';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const GET_USEFUL_LINKS = 'GET_USEFUL_LINKS';
export const SET_ORGANIZATION_PERMISSIONS = 'SET_ORGANIZATION_PERMISSIONS';
export const UPDATE_ORGANIZATION_PERMISSIONS = 'UPDATE_ORGANIZATION_PERMISSIONS';

export interface IOrganizationsTypes {
    SET_ORGANIZATION_PERMISSIONS: {
        list: {
            id: string;
            permissions: OrganizationPermissions[];
        }[];
    };
    UPDATE_ORGANIZATION_PERMISSIONS: {
        organizationId: string;
        permissions: OrganizationPermissions[];
    }
}
