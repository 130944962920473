import React from 'react';
import { useIntl } from 'react-intl';

export const SuccessfulSubmission = () => {
    const intl = useIntl();
    return (
        <div>
            <span dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'coi.popup.success.great' })
            }} />
            <span dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'coi.popup.success.confirmation' })
            }} />
            <span dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'coi.popup.success.notificationInfo' })
            }} />
        </div>
    );
};