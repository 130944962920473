import React, { FC, useRef, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { media, sizes } from '../../../../styleHelpers/breakpoint';
import { Menu } from './Menu';
import { useResize } from '../../../../tools/hooks';

interface IWrapperProps {
    wide?: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
    ${media.desktop`
        flex: 1 1 auto;
        margin: 0;
        width: ${({ wide }) => wide ? 280 : 230}px;
    `}
`;

interface IProps {
    withNormsConsultationToc?: boolean;
    bookCommonLevelId?: string;
}

export const OrganizationMenu: FC<React.PropsWithChildren<IProps>> = ({ withNormsConsultationToc, bookCommonLevelId }) => {
    const wrapperRef = useRef<HTMLDivElement>();
    const [parentWrapperPosition, setParentWrapperPosition] = useState<DOMRect>();
    const { innerWidth } = useResize();

    useLayoutEffect(() => {
        setParentWrapperPosition(innerWidth >= sizes.desktop && wrapperRef.current?.getBoundingClientRect?.());
    }, [innerWidth]);

    return (
        <Wrapper ref={wrapperRef} wide={withNormsConsultationToc}>
            <Menu parentWrapperPosition={parentWrapperPosition} withNormsConsultationToc={withNormsConsultationToc} bookCommonLevelId={bookCommonLevelId} />
        </Wrapper>
    );
};
