import { IPdfOverlay } from '../entities/IPdfOverlay';
import { UPDATE_PDF_OVERLAY, SHOW_PDF_OVERLAY, HIDE_PDF_OVERLAY, CLEAR_PDF_OVERLAY } from '../actions/actionTypes/pdfOverlayTypes';

export interface IPdfOverlayReducer extends IPdfOverlay {}

const defaultState = (): IPdfOverlayReducer => ({
    pdfUrl: '',
    pdfName: '',
    display: false
} as IPdfOverlay);

export default (state = defaultState(), action): IPdfOverlayReducer => {
    switch (action.type) {
        case UPDATE_PDF_OVERLAY: {
            const pdfOverlay = action.pdfOverlay as IPdfOverlay;
            return {
                ...state,
                ...pdfOverlay
            };
        }

        case SHOW_PDF_OVERLAY: {
            return {
                ...state,
                display: true
            };
        }

        case HIDE_PDF_OVERLAY: {
            return {
                ...state,
                display: false
            };
        }

        case CLEAR_PDF_OVERLAY: {
            return defaultState();
        }

        default: {
            return state;
        }
    }
};
