import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { ISogeParent } from '../../../../entities/ISoge';
import { useOrganization } from '../../../../tools/organizationHooks';
import { getSogeCollectionsUrl, getSogeDocUrl } from '../../../../tools/legalDocTools/sogeTools';
import { isFontAwesomeIcon } from '../../../../tools/generalTools';

const Wrapper = styled.span`
    > span {
        &:after {
            content: ' > ';
        }
        &:last-child {
            &:after {
                content: '';
            }
            a {
                text-decoration: none;
                font-weight: bold;
            }
        }
    }
`;

const IconWrapper = styled.span`
    margin-right: .3rem;
`;

interface IBreadCrumbsElementsProps {
    organizationUrlName?: string;
    versionId?: string;
    icon?: React.ReactNode | IconDefinition;
    parentsArray: ISogeParent[];
    getStructureLink?(commonLevelId: string): string;
}

export const BreadCrumbsElements: FC<React.PropsWithChildren<IBreadCrumbsElementsProps>> = ({ organizationUrlName, versionId, icon, parentsArray, getStructureLink }) => {
    const { currentOrganization } = useOrganization(organizationUrlName);

    return (
        <Wrapper>
            {currentOrganization && organizationUrlName && (
                <span>
                    <Link to={getSogeCollectionsUrl(organizationUrlName, versionId)}>
                        {icon && (
                            <IconWrapper>
                                {isFontAwesomeIcon(icon) ? <FontAwesomeIcon icon={icon} /> : icon}
                            </IconWrapper>
                        )}
                        {currentOrganization?.details.name}
                    </Link>
                </span>
            )}
            {(parentsArray || []).map(parent => (
                <span key={parent.commonLevelId}>
                    {(organizationUrlName || getStructureLink) ? (
                        <Link to={getStructureLink?.(parent.commonLevelId) || getSogeDocUrl(organizationUrlName, parent.commonLevelId, versionId)}>
                            {parent.fullName}
                        </Link>
                    ) : (
                        <>{parent.fullName}</>
                    )}
                </span>
            ))}
        </Wrapper>
    );
};
