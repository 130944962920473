import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';

const BooleanWrapper = styled.span`
    color: ${colorStack.content};
`;

export const BooleanType: FC<React.PropsWithChildren<unknown>> = props => {
    const intl = useIntl();
    return (
        <BooleanWrapper>{props.children === true ? intl?.formatMessage({ id: 'global.yes' }) : (props.children === null ? intl?.formatMessage({ id: 'global.blank' }) : intl?.formatMessage({ id: 'global.no' }))}</BooleanWrapper>
    );
};