import React, { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { Checkbox } from '../../../../Common/Inputs/Checkbox';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../../styleHelpers/colors';
import { ITradeAssociationData } from '../../../../../entities/ActionData/ITradeAssociationData';

const CBContainer = styled.div``;

const Container = styled.div`
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[16]};
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 2rem;
`;

const PrinciplesContainer = styled.div`
    margin-bottom: 1rem;
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
`;

export const Step3: FC<React.PropsWithChildren<unknown>> = props => {
    const intl = useIntl();
    const { setFieldValue, values } = useFormikContext<ITradeAssociationData>();

    useEffect(() => {
        setFieldValue('step', 3);
    }, []);

    const changeCheckbox = useCallback((checked: boolean) => {
        setFieldValue('noLinkPolicy', checked);
    }, []);

    return (
        <Container>
            <PrinciplesContainer>
                <div dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: 'tradeAssociation.certificate.tradeAssociation.textList' })
                }} />
            </PrinciplesContainer>
            <CBContainer>
                <Checkbox onChange={changeCheckbox} checked={values.noLinkPolicy} label={intl.formatMessage({ id: 'tradeAssociation.wizard.step3.checkBoxLabel' })} />
            </CBContainer>
        </Container>
    );
};