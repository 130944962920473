import React, { FC, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { getClusterTemplates } from '../../../../actions/clustersActions';
import { IClusterTemplate } from '../../../../entities/IClusters';
import { SelectCard } from '../../../Common/SelectCard/SelectCard';
import { EApprovalContext, EApprovalTypes, IEApprovalContext } from '.';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
        margin: 0 0 1rem 0;
    }
`;

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

interface IProps {
    organizationId: string;
    checkIsFirstStep(isFirstStep: boolean);
}

export const EApprovalTypeSelectStep: FC<IProps> = (props) => {
    const [templates, setTemplates] = useState<IClusterTemplate[]>(undefined);
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    useEffect(() => {
        if (!templates) {
            dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
                setTemplates(clusterTemplates);
            });
        }
        eApprovalContext.modifyData(undefined, undefined, undefined, undefined, undefined);
        props.checkIsFirstStep(true);
    }, []);

    useEffect(() => {
        return () => {
            props.checkIsFirstStep(false);
        };
      }, []);

    const changeType = (type: EApprovalTypes) => {
        if (type !== eApprovalContext.type) {
            eApprovalContext.setType(type);
        }
    };

    return (
        <Wrapper>
            {templates?.map(elem =>
                <SelectCard
                    key={elem.id}
                    onClick={changeType}
                    value={elem.id}
                    imageKey={elem.templateKey}
                    templateKey={elem.templateKey}
                    isChecked={eApprovalContext.type === elem.templateKey}
                    name={`eApproval.wizard.type.name.${elem.templateKey}`}
                    description={`eApproval.wizard.type.description.${elem.templateKey}`}
                />
            )}

        </Wrapper>
    );
};