export const getTextFromDraft = (text: string) => {
    const element = document.createElement('div');

    element.insertAdjacentHTML('afterbegin', text);

    return element.innerText;
};

export const rawStringToHtml = (content: string): string => {
    const element = document.createElement('div');

    element.insertAdjacentHTML('afterbegin', content);

    return element.innerHTML;
};

export const getAsHtml = (editorContent: string, editorState: any) => {
    // tslint:disable-next-line:no-null-keyword
    return !!getTextFromDraft(editorContent) ? rawStringToHtml(editorContent) : null;
};
