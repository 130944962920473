import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

export enum EHintType {
    DEFAULT = 'DEFAULT',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING'
}

const Wrapper = styled.div<{type: EHintType}>`
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: ${colorStack.label};
    svg {
        margin-right: 4px;
        ${fontSizeAndHeight[10]};
    }
    span {
        ${fontSizeAndHeight[10]};
    }
    ${({type}) => type === EHintType.ERROR && css`
        color: ${colorStack.middleRed};
    `};
    ${({type}) => type === EHintType.SUCCESS && css`
        color: ${colorStack.middleGreen};
    `};
    ${({type}) => type === EHintType.WARNING && css`
        color: ${colorStack.middleOrange};
    `};
`;

interface IProps {
    type: EHintType;
    children: React.ReactNode;
}

export const Hint: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper type={props.type}>
            <FontAwesomeIcon icon={faCircleInfo} />
            <span>{props.children}</span>
        </Wrapper>
    );
};