import React, { cloneElement, ComponentProps, ReactElement } from 'react';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useDropdown } from '../../../tools/hooks';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { IconButton, EIconButtonTypeSchema } from './IconButton';

const MenuWrapper = styled.div`
    position: relative;
    display: inline-flex;
    :last-child {
        div {
            right: 0;
            left: auto;
        }
    }
    [role='menu'] { top: calc(100% + 2px); }

    button:disabled {
        background-color: transparent;
        border-color: transparent;
        svg {
            color: inherit;
        }
    }

    &[data-position='right'] [role='menu'] { right: 0; }
`;

const defaultButton = <IconButton typeSchema={EIconButtonTypeSchema.TERTIARY} icon={faEllipsisH} />;

interface IDropdownMenuProps extends ComponentProps<typeof ContextMenu> {
    align?: 'right' | 'left';
    button?: ReactElement;
}

export const DropdownMenu = ({ links, align= 'left', button = defaultButton }: IDropdownMenuProps) => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();
    return (
        <MenuWrapper ref={wrapperRef} data-position={align}>
            { cloneElement(button, { onClick: toggleDropdown, disabled: !links.length }) }
            { dropdownOpen && <ContextMenu links={links.map(({ action, ...link }) => ({...link, action: () => {
                closeDropdown();
                action();
            }}))} /> }
        </MenuWrapper>
    );
};
