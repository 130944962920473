import { ETrackingType, ITracker } from '../../entities/IAnalytics';

export const ADD_TRACKING = 'ADD_TRACKING';

export interface IAnalyticsTypes {
    [ADD_TRACKING]: {
        trackingType: ETrackingType;
        tracker: ITracker;
    }
}
