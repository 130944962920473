import React, { useState, useCallback, useMemo, useEffect, FC } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { ISogeArticle, EReferenceObjectType, ISogeRefSource, ISogeParent } from '../../../entities/ISoge';
import { EVersionStatus } from '../../../entities/LegalDoc/INormativeDocumentationVersion';
import { IState } from '../../../reducers';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { getSogeArticle, downloadArticleAttachment } from '../../../actions/sogeActions';
import { useTocItemNumberingStyle } from '../../../tools/legalDocTools/sogeHooks';
import { EAlertType } from '../../../entities/IAlert';
import { useAlert } from '../../../tools/hooks';
import { getErrorMessage } from '../../../tools/errorTools';
import { getSogeArticleUrl } from '../../../tools/legalDocTools/sogeTools';
import { arrayToObject } from '../../../tools/arrayTools';
import { StructureDetailsDropdown } from './StructureDetailsDropdown';
import { ContentObject } from './ContentObject';
import { DropdownSpinnerWrapper } from '../Components/Styles';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';
import { ArticleHistoryPopup } from './common/ArticleHistoryPopup';
import { MetadataPopup } from './common/MetadataPopup';
import { ArticleAttachment } from './common/ArticleAttachment';
import { ArticleTitle } from './common/ArticleTitle';

interface IWrapperProps {
    noPadding: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
    margin-bottom: 3rem;
    max-width: 836px;

    ${props => !props.noPadding && css`
        padding: 0 1.5rem;
    `}
`;

interface ILegalDocArticleProps {
    article: ISogeArticle;
    structureId?: string;
    versionId?: string;
    organizationUrlName: string;
    noPadding?: boolean;
    book: ISogeParent;
    disableArticleLinks?: boolean;
    isConsultation?: boolean;
    isCurrentVersion?: boolean;
    currentDownloadingElementId?: string;
    isDiffView?: boolean;
    isAuthorized?: boolean;
    isNormsManagement?: boolean;
    onArticleDetailsClick?(articleId: string): void;
    getArticleUrl?(article: ISogeArticle): string;
    onArticleDownloadClick?(articleId: string, structureLevelRootCommonLevelId: string, levelName: string);
    onCopyUrlClick?(documentVersionName: string, commonLevelId: string, articleId: string);
}

export const LegalDocArticle: FC<React.PropsWithChildren<ILegalDocArticleProps>> = ({ article, versionId, structureId, organizationUrlName, noPadding, book, disableArticleLinks, isConsultation, isCurrentVersion, currentDownloadingElementId, isDiffView, isAuthorized, isNormsManagement, onArticleDetailsClick, getArticleUrl, onArticleDownloadClick, onCopyUrlClick }) => {
    const { stylesLoaded, languageData, documentationLcid, documentationId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const { sgStylesContainer, currentUniqueVersionId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const [previewCache, setPreviewCache] = useState<{ [id: string]: ISogeArticle }>({});
    const [currentPreviewId, setCurrentPreviewId] = useState<{ referenceId: string, contentId: string }>(undefined);
    const [historyPopupVisible, setHistoryPopupVisible] = useState<boolean>(false);
    const [metadataPopupVisible, setMetadataPopupVisible] = useState<boolean>(false);
    const currentVersionStatus = languageData?.[versionId]?.[documentationLcid]?.status;
    const { tocItemNumberingStyle } = useTocItemNumberingStyle();
    const [referenceArticleTitle] = useState<string>(' ');

    const onHoverReference = useCallback(async (refSource: ISogeRefSource, contentId: string) => {
        if (refSource?.objectType === EReferenceObjectType.Article) {
            const hoveredArticle = previewCache[refSource.referenceId] || await dispatch(getSogeArticle(versionId, documentationId, refSource.objectId, documentationLcid));

            !previewCache[refSource.referenceId] && setPreviewCache(cache => ({
                ...cache,
                [refSource.referenceId]: hoveredArticle
            }));

            setCurrentPreviewId({ referenceId: refSource.referenceId, contentId });
        }

    }, [documentationId, versionId, documentationLcid, previewCache]);

    const hideReferencePreview = useCallback(() => {
        setCurrentPreviewId(undefined);
    }, []);

    const refersToByReferenceId: { [referenceId: string]: ISogeRefSource } = useMemo(() => {
        return arrayToObject(article.architextLastVersion?.refersTo || [], ref => ref.referenceId);
    }, [article.commonArticleId]);

    const docTitle = article.title ? (
        <span>{article.title}</span>
    ) : article?.codeNumber && (
        <FormattedMessage id="norms.articleNumber" values={{ codeNumber: article.codeNumber }} />
    );

    const urlVersionId = isConsultation && isCurrentVersion ? 'current' : versionId;

    const onDownloadClick = useCallback(async (fileId: string, extension: string) => {
        try {
            await dispatch(downloadArticleAttachment(documentationId, versionId, article.id, article.architextLastVersion.id, fileId, extension));
        } catch (error) {
            addAlert(getErrorMessage(error), EAlertType.ERROR);
        }
    }, [documentationId, versionId, article.id, article.architextLastVersion?.articleVersion]);

    const shouldDisplayMetadata = article.architextLastVersion?.thematics?.length ||
                                  article.architextLastVersion?.paratexts?.length ||
                                  article.architextLastVersion?.refersTo?.length ||
                                  article.architextLastVersion?.mentionedIn?.length ||
                                  article.attachments?.length;

    return (
        <Wrapper noPadding={noPadding} data-diff-article data-common-id={article.commonArticleId}>
            <ArticleTitle id={article?.codeNumber !== undefined && article?.codeNumber} title={docTitle} linkTo={!disableArticleLinks && (getArticleUrl?.(article) || getSogeArticleUrl(organizationUrlName, article.commonArticleId, structureId, urlVersionId))}>
                {(onArticleDetailsClick || onArticleDownloadClick || onCopyUrlClick) && (!isNormsManagement ? document?.getElementById(article?.codeNumber)?.textContent?.toString() !== referenceArticleTitle : isNormsManagement) && (
                    <StructureDetailsDropdown
                        commonElementId={article.commonArticleId || article.id}
                        isAuthorized={isAuthorized}
                        onDetailsClick={onArticleDetailsClick}
                        onDownloadClick={onArticleDownloadClick && (() => onArticleDownloadClick(article.id, book.commonLevelId || article?.parents?.[0]?.commonLevelId, article.title || article.codeNumber))}
                        onCopyUrlClick={onCopyUrlClick}
                        onHistoryClick={() => setHistoryPopupVisible(true)}
                        onMetadataClick={shouldDisplayMetadata && (() => setMetadataPopupVisible(true))}
                        documentVersionName={article.architextLastVersion?.articleVersion?.documentVersionName}
                        levelId={structureId}
                    />
                )}
                {currentDownloadingElementId === article?.id && (
                    <DropdownSpinnerWrapper>
                        <Spinner size={ESpinnerSize.SMALL} />
                    </DropdownSpinnerWrapper>
                )}
            </ArticleTitle>
            {!!article.architextLastVersion?.contentObjects?.length && stylesLoaded && (
                <ContentObject
                    contentCollection={article.architextLastVersion.contentObjects}
                    articleId={article.id}
                    architextVersionId={article.architextLastVersion.id}
                    bookId={book?.commonLevelId}
                    orgUrl={organizationUrlName}
                    refersToByReferenceId={refersToByReferenceId}
                    versionId={versionId}
                    onReferenceMouseEnter={onHoverReference}
                    onReferenceMouseLeave={hideReferencePreview}
                    currentPreviewReferenceId={currentPreviewId?.referenceId}
                    currentPreviewContentId={currentPreviewId?.contentId}
                    isDiffView={isDiffView}
                    footnotes={article.footnotes}
                    previewContent={previewCache[currentPreviewId?.referenceId] && (
                        <ContentObject
                            contentCollection={previewCache[currentPreviewId?.referenceId].architextLastVersion.contentObjects}
                            articleId={previewCache[currentPreviewId?.referenceId].id}
                            architextVersionId={previewCache[currentPreviewId?.referenceId].architextLastVersion.id}
                            bookId={book?.commonLevelId}
                            orgUrl={organizationUrlName}
                            refersToByReferenceId={refersToByReferenceId}
                            versionId={versionId}
                        />
                    )}
                />
            )}
            {(article?.attachments || []).map(attachment => attachment.fileId && (
                <ArticleAttachment
                    key={attachment.destinationObjectId}
                    attachment={attachment}
                    onDownloadAttachment={onDownloadClick}
                    article={article}
                    currentVersionId={versionId}
                />
            ))}
            {historyPopupVisible && (
                <ArticleHistoryPopup
                    article={article}
                    structureId={structureId}
                    organizationUrlName={organizationUrlName}
                    versionId={versionId}
                    isConsultation={isConsultation}
                    onClose={() => setHistoryPopupVisible(false)}
                />
            )}
            {metadataPopupVisible && (
                <MetadataPopup
                    visible
                    loaded
                    referenceText={{
                        thematics: article.architextLastVersion?.thematics,
                        details: article.architextLastVersion?.paratexts
                    }}
                    utcDate={{firstPublicationDateUtc: article.firstPublicationDateUtc, publishDateOfLastModificationUtc: article.publishDateOfLastModificationUtc}}
                    refersTo={article.architextLastVersion?.refersTo}
                    mentionedIn={article.architextLastVersion?.mentionedIn}
                    book={book}
                    versionId={versionId}
                    organizationUrlName={organizationUrlName}
                    isCurrentVersion={currentVersionStatus !== EVersionStatus.New}
                    urlVersion={urlVersionId}
                    articleVersion={article.architextLastVersion?.articleVersion}
                    onClose={() => setMetadataPopupVisible(false)}
                />
            )}
        </Wrapper>
    );
};
