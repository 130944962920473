import React, { ReactNode, useCallback, useState, useEffect, FC } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { rgba } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHistory, faCogs, faCodeBranch, faCodeMerge, faDownload, faTachometerAltFast, faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import {
    faHistory as solidHistory,
    faCogs as solidCogs,
    faTachometerAltFast as solidTachometerAltFast,
    faBooks as solidBooks,
    faFileInvoice as solidFileInvoice,
    faCodeBranch as solidCodeBranch,
    faCodeMerge as solidCodeMerge,
    faDownload as solidDownload
} from '@fortawesome/pro-solid-svg-icons';

import { ITableOfContentsData, ITableOfContentsVersion, ISogeParent } from '../../../entities/ISoge';
import { EVersionStatus } from '../../../entities/LegalDoc/INormativeDocumentationVersion';
import { useOrganization } from '../../../tools/organizationHooks';
import { ELcid } from '../../../entities/ILanguage';
import { useUserRolePermissions } from '../../../tools/permissionHooks';
import { IState } from '../../../reducers';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { getVersions } from '../../../actions/sogeActions';
import { colorStack } from '../../../styleHelpers/colors';
import { headersShadow } from '../../../styleHelpers/mixins/shadow';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { media } from '../../../styleHelpers/breakpoint';
import LangSelect from '../Components/LangSelect';
import { LinkButton } from '../../Common/Buttons/Button';
import { VersionSelect } from './VersionSelect';
import { GlobalBreadcrumbs } from '../../Common/Breadcrumbs/GlobalBreadcrumbs';
import { SearchInput } from '../../Common/SearchInput/SearchInput';
import { Disclaimer } from './Disclaimer';
import { TocDropdown } from '../Components/TocDropdown';
import { EOrganizationPermissionsBase } from '../../../entities/IPermissions';

type GetVersions = ReturnType<typeof getVersions>;

const MenuItems = styled.div`
    display: flex;
    align-items: center;
`;

const RightSide = styled(MenuItems)`
    margin-left: auto;
    width: 100%;
    margin-top: .5rem;
    ${media.tabletSm`
        width: auto;
        margin-top: 0;
    `}
`;

const StyledTocDropdown = styled(TocDropdown)`
    margin-left: auto;
    ${media.tablet`
        margin-left: 0;
    `}
    > button {
        border: 0;
        background-color: ${colorStack.whiteGrey};
        color: ${colorStack.darkGrey};
        padding: 0.55rem 0.35rem;
        > span {
            font-size: ${fontSize[13]};
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
        }
        &:hover {
            box-shadow: none;
            background-color: ${rgba(colorStack.darkBlue, .1)};
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.75rem 3rem;
    flex-wrap: wrap;
    background: ${colorStack.whiteGrey};
    ${headersShadow()};
    border-radius: 4px;
    margin-bottom: 1rem;
`;

const HeaderText = styled.div`
    font-size: ${fontSize[20]};
    color: ${colorStack.middleBlue};
    font-family: 'Roboto', sans-serif;
    a, a:hover, a:visited {
        color: ${colorStack.middleBlue};
    }
`;

const ManageIcons = styled.div`
    display: flex;
`;

const VersionIcons = styled(ManageIcons)`
    width: 100%;
    margin-top: .5rem;
`;

interface ISingleIconProps {
    active?: boolean;
    dir?: 'column' | 'row';
}

const SingleIcon = styled(Link) <ISingleIconProps>`
    margin: 0 1rem 0 0;
    display: flex;
    cursor: pointer;
    align-items: center;
    span {
        font-size: ${fontSize[10]};
        color: ${colorStack.darkBlue};
    }
    ${props => (props.dir || 'column') === 'column' ? css`
        flex-direction: column;
        span {
            margin: 6px 0 0 0;
        }
    ` : css`
        padding: 0.5rem 0;
        flex-direction: row;
        span {
            font-size:${fontSize[13]};
            margin: 0 0 0 6px;
        }
    `}
    svg {
        color: ${colorStack.darkBlue};
    }
    ${props => props.active && css`
        font-weight: 600;
        span, svg {
            color: ${colorStack.middleBlue};
        }
    `}
    &:last-child {
        margin-right: 0;
    }
`;

const SearchForm = styled.form`
    display: none;
    ${media.tablet`
        display: block;
        flex: 1;
        margin: 0 2rem 0 1rem;
    `}
`;

const mobileHeaderBtn = () => css`
    span {
        display: none;
    }
    svg {
        transform: translateX(5px);
    }
    ${media.tablet`
        svg {
            transform: none;
        }
        span {
            display: inline;
        }
    `}
`;

const ManageEditBtn = styled(LinkButton)`
    ${mobileHeaderBtn()}
`;

interface ISogeHeaderProps {
    header?: ReactNode;
    breadCrumbsTitle?: ReactNode;
    tableOfContents?: ITableOfContentsData;
    versionId?: string;
    urlVersionId?: string;
    currentStructureId?: string;
    organizationUrlName: string;
    parents?: ISogeParent[];
    editMode?: boolean;
    editVersionId?: string;
    hideVersion?: boolean;
    hideLang?: boolean;
    hideManageNorm?: boolean;
    editPage?: 'versions' | 'amendements' | 'settings' | 'branches' | 'reporting';
    consultationPage?: 'collections' | 'allTexts' | 'search';
    editSubPage?: 'content' | 'branches' | 'approvals' | 'branch-datagrid';
    branchCount?: number;
    approvalCount?: number;
    withSearch?: boolean;
    editBranchesPath?: string;
    subMenu?: ReactNode;
    onVersionChange?(versionId: string, status: EVersionStatus);
    setLimitedAccess?(isLimited: boolean);
}

export const SogeHeader: FC<React.PropsWithChildren<ISogeHeaderProps>> = ({ editPage, header, tableOfContents, versionId, currentStructureId, organizationUrlName, editSubPage, editMode, hideVersion, hideLang, editVersionId, branchCount, approvalCount, hideManageNorm, withSearch, editBranchesPath, consultationPage, urlVersionId, subMenu, onVersionChange, setLimitedAccess }) => {
    const { documentationId, documentationLcid, allVersions } = useSelector<IState, ISogeReducer>(state => ({
        ...state.soge
    }));
    const history = useNavigate();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentOrgPermissions, currentOrganization } = useOrganization(organizationUrlName);
    const { isUserAuthorized } = useUserRolePermissions(currentOrganization?.myRole?.role);
    const [searchValue, setSearchValue] = useState<string>('');
    const [currVersionLanguages, setCurrVersionLanguages] = useState<ELcid[]>([]);
    const [versions, setVersions] = useState<ITableOfContentsVersion[]>([]);

    const onSearchSubmit = useCallback((e?: React.FormEvent) => {
        e?.preventDefault();
        history(`/orgs/${organizationUrlName}/documentation/search?query=${encodeURIComponent(searchValue)}`);
    }, [organizationUrlName, searchValue]);

    useEffect(() => {
        const currVersion = versionId && allVersions && allVersions.filter((el) => el.versionId === versionId).map((el) => el?.lcid);
        setCurrVersionLanguages(currVersion);
    }, [versionId, allVersions]);

    useEffect(() => {
        documentationId && documentationLcid && dispatch<GetVersions>(getVersions(documentationId, documentationLcid)).then((response) => {
            setVersions(response);
        });
    }, [documentationId, documentationLcid]);

    return (
        <>
            <GlobalBreadcrumbs noWrap breadcrumbs={[]}>
                <Disclaimer />
            </GlobalBreadcrumbs>
            <Wrapper>
                <HeaderText>
                    {header}
                </HeaderText>
                {withSearch && (
                    <SearchForm onSubmit={onSearchSubmit}>
                        <SearchInput
                            value={searchValue}
                            placeholder={intl.formatMessage({ id: 'norms.placeholder.searchDocumentation' })}
                            onChangeHandler={e => setSearchValue(e.target.value)}
                            onSearchClick={() => onSearchSubmit()}
                        />
                    </SearchForm>
                )}
                <RightSide>
                    {!hideVersion && isUserAuthorized && (
                        <VersionSelect
                            setLimitedAccess={setLimitedAccess}
                            isOwner={currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization]}
                            isGlobalVersionSelect
                            onVersionChange={onVersionChange}
                            currentVersionId={versionId}
                            labelHidden
                            bgColor={colorStack.whiteGrey}
                            versions={versions}
                        />
                    )}
                    {!hideLang && (
                        <LangSelect languages={currVersionLanguages} versionId={versionId} bgColor={colorStack.whiteGrey} />
                    )}
                    {editMode &&
                        <ManageIcons>
                            {editBranchesPath && (
                                <SingleIcon data-lc="js-lc-button-production-management" to={editBranchesPath} active={editPage === 'branches'}>
                                    <FontAwesomeIcon icon={editPage === 'branches' ? solidTachometerAltFast : faTachometerAltFast} />
                                    <FormattedMessage id="norms.branches.title" />
                                </SingleIcon>
                            )}
                            <SingleIcon data-lc="js-lc-button-version-history" to={`/orgs/${organizationUrlName}/documentation/manage-versions`} active={editPage === 'versions'}>
                                <FontAwesomeIcon icon={editPage === 'versions' ? solidHistory : faHistory} />
                                <FormattedMessage id="norms.versions" />
                            </SingleIcon>
                            <SingleIcon data-lc="js-lc-button-dashboards" to={`/orgs/${organizationUrlName}/documentation/reporting`} active={editPage === 'reporting'}>
                                <FontAwesomeIcon icon={editPage === 'reporting' ? solidFileInvoice : faFileInvoice} />
                                <FormattedMessage id="norms.reporting" />
                            </SingleIcon>
                            <SingleIcon data-lc="js-lc-button-settings" to={`/orgs/${organizationUrlName}/documentation/versions/settings`} active={editPage === 'settings'}>
                                <FontAwesomeIcon icon={editPage === 'settings' ? solidCogs : faCogs} />
                                <FormattedMessage id="norms.settings" />
                            </SingleIcon>
                        </ManageIcons>
                    }
                </RightSide>
                {!!editVersionId && (
                    <VersionIcons>
                        <SingleIcon to={`/orgs/${organizationUrlName}/documentation/versions/${editVersionId}/branches`} dir="row" active={editSubPage === 'branches'}>
                            <FontAwesomeIcon icon={editSubPage === 'branches' ? solidCodeBranch : faCodeBranch} />
                            <span dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({ id: 'norms.branchCount' }, {
                                    branchCount: branchCount
                                })
                            }} />
                        </SingleIcon>
                        <SingleIcon to={`/orgs/${organizationUrlName}/documentation/versions/${editVersionId}/approvals`} dir="row" active={editSubPage === 'approvals'}>
                            <FontAwesomeIcon icon={editSubPage === 'approvals' ? solidCodeMerge : faCodeMerge} />
                            <span dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({ id: 'norms.approvalCount' }, {
                                    approvalCount: approvalCount
                                })
                            }} />
                        </SingleIcon>
                        <SingleIcon to={`/orgs/${organizationUrlName}/documentation/versions/${editVersionId}/branches/datagrid`} dir="row" active={editSubPage === 'branch-datagrid'}>
                            <FontAwesomeIcon icon={editSubPage === 'branch-datagrid' ? solidDownload : faDownload} />
                            <FormattedMessage id="norms.branchDatagrid" />
                        </SingleIcon>
                        <SingleIcon to={`/orgs/${organizationUrlName}/documentation/versions/${editVersionId}`} dir="row" active={editSubPage === 'content'}>
                            <FontAwesomeIcon icon={editSubPage === 'content' ? solidBooks : faBook} />
                            <FormattedMessage id="norms.content" />
                        </SingleIcon>
                    </VersionIcons>
                )}
                {subMenu && (
                    <VersionIcons>
                        {subMenu}
                    </VersionIcons>
                )}
            </Wrapper>
        </>
    );
};
