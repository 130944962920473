import { ISingleInvolvedPartie } from './IClusters';
import { IEnumObject, IReferencial, IUserBrief } from './IGlobal';

export type IHyperlinkDataType = {
    [key: string]:
    | {
        routeName: 'Campaign';
        text: string;
        hyperlinkParameters: {
            campaignId: string;
        }
    }
    | {
        routeName: 'Cluster';
        text: string;
        hyperlinkParameters: {
            clusterId: string;
        }
    }
    | {
        routeName: 'DraftCampaign';
        text: string;
        hyperlinkParameters: {
            campaignId: string;
        }
    }
};

export interface IClusterLinkDataType {
    [key: string]: { name: string, id: string }
}

export interface ISignatureLinkDataType {
    [key: string]: { title: string, clusterId: string }
}

export interface IApprovalLinkDataType {
    [key: string]: { clusterId: string, validationId: string, name: string }
}

export interface IEnumDataType {
    [key: string]: IEnumObject;
}

export interface IPartyDataType {
    [key: string]: ISingleInvolvedPartie[]
}

export interface IPersonDataType {
    [key: string]: IUserBrief
}

export interface IDateDataType {
    [key: string]: string
}

export interface IDateColoredDataType {
    [key: string]: string
}

export interface IDateAgoDataType {
    [key: string]: string
}

export interface IStatusDataType {
    [key: string]: string
}

export interface IExclamationMarkDataType {
    [key: string]: 'True' | 'No'
}

export interface IBooleanDataType {
    [key: string]: boolean
}

export interface INumberDataType {
    [key: string]: { number: string, unit: IReferencial, translatedUnit: string }
}

export enum EColumnType {
    STRING = 'string',
    datetime = 'datetime',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
    dateAgo = 'dateAgo',
    dateColored = 'dateColored',
    text = 'text',
    party = 'party',
    people = 'people',
    clusterLink = 'clusterLink',
    person = 'person',
    legalEntity = 'legalEntity',
    referentials = 'referentials',
    mail = 'mail',
    status = 'status',
    exclamationMark = 'exclamationMark',
    richText = 'richText',
    approvalLink = 'approvalLink',
    signatureLink = 'signatureLink',
    enum = 'enum',
    numberWithConditionalColor = 'numberWithConditionalColor',
    hyperlink = 'hyperlink',
    booleanColored = 'booleanColored',
    comment = 'comment',
    pill = 'pill',
    lastEditedBy = 'lastEditedBy',
    overview = 'overview',
    clusterTemplate = 'clusterTemplate',
    extendedAvatar = 'extendedAvatar',
    dropdown = 'dropdown',
    button = 'button',
    ACTIVITYMESSAGE = 'activityMessage',
    ATTACHMENTS = 'attachments',
    universalSwitcher = 'universalSwitcher',
    LAST_EDITED_BY_RELATIVE_DATE = 'lastEditedByrelativeDate',
    HYPERLINK_MESSAGES = 'hyperlinkmessages',
    HYPERLINK_DOCUMENTS = 'hyperlinkdocuments',
    HYPERLINK_APPROVAL = 'hyperlinkapproval',
    NUMBER_HASH = 'number#',
    ENUM_STATUS = 'enumstatus',
    NUMBER_ORDER = 'numberorder',
    OVERVIEW_CLUSTER = 'overviewcluster',
    OVERVIEW_ORGANIZATION = 'overvieworganization'
}

export enum EOverviewColumnSubType {
    organization = 'organization',
    cluster = 'cluster'
}

export enum EColoredDatagridField {
    Status = 'status',
    LastStatusUpdate = 'lastStatusUpdate'
}

export enum EColoredDatagridViewParentTypeValue {
    Organization = 'Organization',
    Cluster = 'Cluster',
    Platform = 'Platform'
}

export enum EColoredDatagridViewTableType {
    FAQ = 'custom-faq',
    QnA = 'custom-rights-cluster',
    AllQnA = 'custom-rights-organization',
    Clausier = 'custom-clauses-organization',
    SL = 'custom-sideletter-cluster',
    CompositeSL = 'custom-sideletter-organization',
    AllRights = 'custom-all-rights-organization',
    OrganizationSearch = 'custom-search-explore-organization',
    LandlordOwner = 'custom-landlord-owner-organization',
    Approval = 'custom-approval-organization',
    OrganizationSettingsTabsMembers = 'custom-settings-tabs-members-organization',
    OrganizationSettingsTabsPendingInvitation = 'custom-settings-tabs-pending-invitation-organization',
    ClusterSettingsTabsMembers = 'custom-settings-members-cluster',
    ClusterSettingsTabsPendingInvitation = 'custom-settings-tabs-pending-invitation-cluster',
    ClusterSettingsTabsAccessRequest = 'custom-settings-tabs-access-request-cluster',
    PlatformMembers = 'custom-members-platform',
    PlatformWorkspaces = 'custom-workspaces-platform',
    PlatformPendingInvitations = 'custom-pending-invitations-platform',
    PlatformGroups = 'custom-groups-platform',
    PlatformSingleGroupMembers = 'custom-single-group-members-platform'
}
