import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

import { IItem } from '../../../../entities/IFilters';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { ResultBox } from '../../ResultsBox/ResultBox';
import { EFilterType } from '../../../../entities/IGlobal';

const FilterBox = styled(ResultBox)`
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 99;
`;

const FilterContainer = styled.div`
    position: relative;
`;

const FilterButton = styled.button<{ open: boolean, active: boolean }>`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.content};
    padding: 10px 12px;
    border-radius: 8px;
    background: ${colorStack.whiteGrey};
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid ${colorStack.ligthGrey};
    transition: all .3s;
    ${props => props.active && css`
        background: ${colorStack.whiteBlue};
    `}
    ${props => props.open && css`
        border: 2px solid ${colorStack.middleBlue};
    `}
    > svg {
        margin: 0 0 0 2rem;
        color: ${colorStack.darkGrey};
    }
`;

interface FilterSelectProps {
    label: string;
    values: IItem[]
    type: EFilterType;
    selected?: IItem[];
    open?: boolean;
    onFilterChange?(ids: (string | IItem)[]);
    onOpen?();
    onClose?();
    setStaticSelected?(item: any[]);
}

export const FilterSelect = ({ label, values, open, onClose, onOpen, onFilterChange, setStaticSelected, type, selected: _selected = [] }: FilterSelectProps) => {
    const [selected, setSelected] = useState<IItem[]>(_selected);

    useEffect(() => setSelected(_selected), [_selected]);

    const onSaveHandler = () => {
        setStaticSelected(selected);
        onFilterChange?.(selected.map((item) => {
            return (type === EFilterType.date ? item : item.id);
        }));
        onClose?.();
    };

    return (
        <FilterContainer>
            <FilterButton open={open} active={!!_selected?.length} onClick={open ? onClose : onOpen}>
                {label}{selected?.length ? <>&nbsp;<strong>{`(${selected.length})`}</strong></> : undefined}
                <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />
            </FilterButton>
            {open &&
                <FilterBox
                    multiSelect
                    items={values}
                    selectedItems={selected}
                    translationKey={undefined}
                    type={type}
                    onSave={onSaveHandler}
                    onSelectItem={(item) => setSelected(selection => selection.find(({ id }) => id === item.id) ?
                        selection.filter(({ id }) => id !== item.id) : [...selection, { ...item, visible: true }]
                    )}
                    onClear={() => { setSelected([]); onFilterChange?.([]); onClose?.(); setStaticSelected(undefined); }}
                />
            }
        </FilterContainer>
    );
};
