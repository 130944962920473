const getAllCookies = (): { [key: string]: string } => document.cookie.split(';').reduce((cookies, cookie) => {
    const splitted = cookie.split('=');
    return {
        ...cookies,
        [(splitted[0] || '').trim()]: splitted[1] || ''
    };
}, {});

export const setCookie = (cname: string, cvalue: string, exdays: number = 365) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const setSessionCookie = (cname: string, cvalue: string) => {
    const expires = `expires=${-1}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const removeCookie = (cname: string) => {
    const expires = `expires=${new Date(1)}`;
    document.cookie = `${cname}=;${expires};path=/`;
};

export const getCookie = (cname: string) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
};

const createStorage = (storageType: 'session' | 'local'): Storage => ({
    ...(getAllCookies()),
    setItem(key: string, value: string) {
        this[key] = value;
        (storageType === 'session' ? setSessionCookie : setCookie)(key, value);
    },
    getItem(key: string) {
        return getCookie(key);
    },
    removeItem(key: string) {
        delete this[key];
        removeCookie(key);
    }
} as Storage);

export let localStorage: Storage = window.localStorage;
export let sessionStorage: Storage = window.localStorage;

try {
    if (!window.localStorage || !window.sessionStorage) throw 'exception';
    window.localStorage.setItem('storage_test', '1');
    window.localStorage.removeItem('storage_test');
} catch (e) {
    localStorage = createStorage('local');
    sessionStorage = createStorage('session');

    (window as any).localStorage = localStorage;
    (window as any).sessionStorage = sessionStorage;
    window.localStorage.__proto__ = localStorage;
    window.sessionStorage.__proto__ = sessionStorage;
}

interface IExpStorage {
    value: string;
    expiry: number;
}

export const storeWithExpiry = (key: string, value: string, ttl: number) => {
    const now = new Date();

    const item: IExpStorage = {
        value,
        expiry: now.getTime() + ttl // ttl in miliseconds
    };

    localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string, returnLastValue?: boolean) => {
    try {
        const json = localStorage.getItem(key);
        const item = JSON.parse(json) as IExpStorage;
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return returnLastValue ? item.value : undefined;
        }

        return item.value;
    } catch (e) {
        return undefined;
    }
};
