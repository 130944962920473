import React, { Fragment, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { cardsShadow } from '../../../styleHelpers/mixins/shadow';
import { Button, EButtonTypeSchema } from '../Buttons/NewButton';
import { Delete } from '../Icons';

const Wrapper = styled.div`
    & > button {
        align-items: center;
    }
`;

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const ListItem = styled.li<{ editMode?: boolean }>`
    border-radius: 8px;
    position: relative;
    padding: 1rem ${fontSize[25]};

    & > svg {
        display: none;
    }

    ${({ editMode }) => editMode && css`
        &:focus,
        &:hover {
            background-color: ${colorStack.whiteGrey};

            & > svg {
                display: block;
            }
        }
    `}
`;

const RemoveItemButton = styled(Delete)`
    position: absolute;
    cursor: pointer;
    right: -10px;
    top: -10px;
`;

const Separator = styled.div`
    height: 1px;
    margin: 10px 1rem;
    background-color: ${colorStack.ligthGrey};
`;

interface ISpliceList<T extends { id: string }> {
    items: T[];
    addButtonText: ReactNode;
    disabled?: boolean;
    editMode: boolean;
    itemRenderer(item: T, index: number): ReactNode;
    onAddItem(): void;
    onRemoveItem(id: T['id'], index: number): void;
}

export const SpliceList = <T extends { id: string }>(props: ISpliceList<T>) => {
    const items = (props.items || []).map((item, index) => (
        <Fragment key={item.id}>
            <ListItem editMode={props.editMode}>
                {props.itemRenderer(item, index)}
                {props.editMode && <RemoveItemButton onClick={() => !props.disabled && props.onRemoveItem(item.id, index)} width={20} />}
            </ListItem>
            {(props.editMode || (index < (props.items.length - 1))) && <Separator />}
        </Fragment>
    ));

    return (
        <Wrapper>
            <List>{items}</List>
            {props.editMode && (
                <Button type="button"
                    typeSchema={EButtonTypeSchema.TEXT}
                    disabled={props.disabled}
                    onClick={props.onAddItem}
                    data-lc="js-lc-add-elem"
                    leftIco={faPlus}
                >
                    {props.addButtonText}
                </Button>
            )}
        </Wrapper>
    );
};
