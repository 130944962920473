export const RFP_STATE_UPDATE = 'RFP_STATE_UPDATE';
export const RFP_SAVE_REQUESTED = 'RFP_SAVE_REQUESTED';
export const RFP_SAVE_COMPLETED = 'RFP_SAVE_COMPLETED';
export const RFP_SAVE_FAILED = 'RFP_SAVE_FAILED';
export const RFP_LOAD_REQUESTED = 'RFP_LOAD_REQUESTED';
export const RFP_LOAD_COMPLETED = 'RFP_LOAD_COMPLETED';
export const RFP_LOAD_ISSUED_LIST = 'RFP_LOAD_ISSUED_LIST';
export const RFP_LOAD_RECIVED_LIST = 'RFP_LOAD_RECIVED_LIST';
export const RFP_SUBMIT_REQUESTED = 'RFP_SUBMIT_REQUESTED';
export const RFP_SUBMIT_COMPLETED = 'RFP_SUBMIT_COMPLETED';
export const RFP_RESET_STATE = 'RFP_RESET_STATE';
export const RFP_ISSUED_LIST_REQUESTED = 'RFP_ISSUED_LIST_REQUESTED';
export const RFP_RECIVED_LIST_REQUESTED = 'RFP_RECIVED_LIST_REQUESTED';
export const RFP_UPLOAD_FILE_REQUESTED = 'RFP_UPLOAD_FILE_REQUESTED';
export const RFP_UPLOAD_FILE_PROGRESS_CHANGE = 'RFP_UPLOAD_FILE_PROGRESS_CHANGE';
export const RFP_UPLOAD_FILE_COMPLETED = 'RFP_UPLOAD_FILE_COMPLETED';
export const RFP_DOWNLOAD_FILE_REQUESTED = 'RFP_DOWNLOAD_FILE_REQUESTED';
export const RFP_DOWNLOAD_FILE_COMPLETED = 'RFP_DOWNLOAD_FILE_COMPLETED';
export const RFP_CANCEL_GET_EXPERTS = 'RFP_CANCEL_GET_EXPERTS';