import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { IState } from '../reducers';
import { IContextReducer } from '../reducers/contextReducer';
import { IOrganizationReducer } from '../reducers/organizationReducer';

export const useOrganization = (urlName: string) => {
    const { contextOrganizations, organizationPermissions } = useSelector<IState, IContextReducer & IOrganizationReducer>(state => ({
        ...state.context,
        ...state.organization
    }));

    const currentOrganization = useMemo(() => {
        return contextOrganizations?.byUrl[urlName];
    }, [contextOrganizations, urlName]);

    const currentOrgPermissions = useMemo(() => {
        return currentOrganization?.id && organizationPermissions?.[currentOrganization.id];
    }, [currentOrganization?.id, organizationPermissions]);

    return {
        currentOrganization,
        currentOrgPermissions
    };
};
