import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { faList, faDownload, faTag, faShareAll, faHistory } from '@fortawesome/pro-light-svg-icons';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { IconButton } from '../../Common/Buttons/IconButton';
import { DropdownMenu } from '../../Common/Buttons/DropdownMenu';

const Wrapper = styled.div`
    margin: 0 0 0 auto;
    display: inline-block;
    vertical-align: middle;
    font-size: ${fontSize[10]};
    > button {
        padding: 0rem;
    }
`;

interface IStructureDetailsDropdownProps {
    commonElementId: string;
    levelId?: string;
    documentVersionName?: string;
    isAuthorized?: boolean;
    onDetailsClick?(commonElementId: string): void;
    onDownloadClick?();
    onCopyUrlClick?(documentVersionName: string, commonElementId: string, levelId?: string);
    onMetadataClick?(commonElementId: string): void;
    onHistoryClick?();
}

export const StructureDetailsDropdown: FC<React.PropsWithChildren<IStructureDetailsDropdownProps>> = props => {
    const wrapperRef = useRef<HTMLDivElement>();

    const onDetailsClick = useCallback(() => {
        props.onDetailsClick?.(props.commonElementId);
    }, [props.commonElementId, props.onDetailsClick]);

    const onCopyUrlClick = useCallback(() => {
        props.onCopyUrlClick?.(props.documentVersionName, props.commonElementId, props.levelId);
    }, [props.commonElementId, props.levelId, props.documentVersionName, props.onCopyUrlClick]);

    const onMetadataClick = useCallback(() => {
        props.onMetadataClick?.(props.commonElementId);
    }, [props.onMetadataClick, props.commonElementId]);

    return (
        <Wrapper ref={wrapperRef} data-details-dropdown>
            <DropdownMenu
                button={<IconButton icon={faInfo} />}
                links={[
                    {
                        customRender: <Button typeSchema={EButtonTypeSchema.TEXT} leftIco={faList} onClick={onDetailsClick}>
                            <FormattedMessage id="norms.label.editMetadata" />
                        </Button>,
                        visible: !!props.onDetailsClick
                    },
                    {
                        customRender: <Button typeSchema={EButtonTypeSchema.TEXT} leftIco={faHistory} onClick={props.onHistoryClick}>
                        <FormattedMessage id="global.history" />
                    </Button>,
                        visible: !!props.onHistoryClick
                    },
                    {
                        customRender: <Button typeSchema={EButtonTypeSchema.TEXT} leftIco={faTag} onClick={onMetadataClick}>
                        <FormattedMessage id="norms.label.seeMore" />
                    </Button>,
                        visible: !!props.onMetadataClick
                    },
                    {
                        customRender: <Button typeSchema={EButtonTypeSchema.TEXT} leftIco={faShareAll} onClick={onCopyUrlClick}>
                        <FormattedMessage id="global.share" />
                    </Button>,
                        visible: !!props.onCopyUrlClick
                    },
                    {
                        customRender: <Button typeSchema={EButtonTypeSchema.TEXT} leftIco={faDownload} onClick={props.onDownloadClick}>
                        <FormattedMessage id="norms.button.downloadElement" />
                    </Button>,
                        visible: !!props.onDownloadClick
                    }
                ]}
            />
        </Wrapper>
    );
};
