import React from 'react';
import { Icon } from '../../../entities/IGlobal';

export const Participation = (props: Icon) => (

    <svg width={props.width} height={props.height} {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
        <g>
            <path d="M297.641,408.496c-1.957-1.685-4.261-3.028-6.91-4.032c-2.65-1.003-5.484-1.505-8.499-1.505   c-3.099,0-5.973,0.502-8.622,1.505c-2.65,1.004-4.952,2.347-6.91,4.032c-1.957,1.686-3.485,3.692-4.586,6.019   c-1.1,2.329-1.65,4.815-1.65,7.464c0,2.648,0.55,5.136,1.65,7.463c1.101,2.329,2.63,4.334,4.586,6.019   c1.958,1.686,4.26,3.03,6.91,4.033c2.648,1.002,5.522,1.505,8.622,1.505c3.016,0,5.849-0.503,8.499-1.505   c2.648-1.003,4.953-2.347,6.91-4.033c1.957-1.685,3.504-3.691,4.648-6.019c1.141-2.327,1.712-4.815,1.712-7.463   c0-2.649-0.571-5.135-1.712-7.464C301.145,412.188,299.597,410.182,297.641,408.496z M286.39,427.758   c-1.143,1.607-2.528,2.408-4.158,2.408c-1.631,0-3.016-0.802-4.158-2.408c-1.142-1.605-1.712-3.531-1.712-5.778   c0-2.247,0.569-4.172,1.712-5.778c1.142-1.605,2.527-2.408,4.158-2.408c1.63,0,3.016,0.803,4.158,2.408   c1.14,1.606,1.712,3.532,1.712,5.778C288.101,424.227,287.53,426.153,286.39,427.758z" />
            <path d="M229.769,391.161c3.014,0,5.849-0.5,8.499-1.505c2.648-1.002,4.952-2.347,6.91-4.032c1.957-1.685,3.504-3.69,4.647-6.019   c1.141-2.327,1.712-4.815,1.712-7.464c0-2.648-0.571-5.135-1.712-7.463c-1.143-2.327-2.69-4.334-4.647-6.019   c-1.958-1.685-4.262-3.028-6.91-4.033c-2.65-1.002-5.485-1.505-8.499-1.505c-3.1,0-5.973,0.503-8.622,1.505   c-2.65,1.005-4.953,2.348-6.91,4.033s-3.485,3.692-4.586,6.019c-1.101,2.329-1.651,4.815-1.651,7.463   c0,2.649,0.55,5.137,1.651,7.464c1.101,2.329,2.629,4.334,4.586,6.019s4.26,3.03,6.91,4.032   C223.795,390.661,226.668,391.161,229.769,391.161z M225.61,366.362c1.141-1.605,2.526-2.407,4.158-2.407   c1.629,0,3.014,0.802,4.158,2.407c1.141,1.607,1.712,3.533,1.712,5.778c0,2.248-0.571,4.174-1.712,5.779   c-1.144,1.606-2.529,2.407-4.158,2.407c-1.632,0-3.018-0.801-4.158-2.407c-1.142-1.605-1.713-3.531-1.713-5.779   C223.898,369.895,224.468,367.969,225.61,366.362z" />
            <polygon points="293.727,362.029 283.577,353 252.881,393.569 218.273,431.971 228.423,441 260.585,399.226  " />
            <path d="M142.988,113.805c-2.258-1.938-4.979-3.619-8.161-5.043c-3.185-1.423-6.751-2.729-10.699-3.915   c-0.887-0.237-1.734-0.492-2.538-0.771c-0.807-0.276-1.572-0.493-2.297-0.653V92.746c2.256,0.397,4.492,0.969,6.71,1.719   c2.216,0.753,4.211,1.563,5.985,2.432c2.095,0.95,4.029,1.978,5.803,3.085l10.155-19.101c-1.693-0.79-3.548-1.641-5.562-2.551   c-2.015-0.909-4.171-1.78-6.468-2.611c-2.297-0.83-4.696-1.56-7.193-2.195c-2.499-0.632-5.078-1.107-7.738-1.423V60h-9.671v11.983   c-3.789,0.397-7.355,1.304-10.7,2.729c-3.345,1.423-6.267,3.322-8.765,5.695c-2.499,2.373-4.473,5.202-5.924,8.483   c-1.45,3.283-2.176,7.059-2.176,11.331c0,3.085,0.424,5.813,1.27,8.187c0.846,2.372,2.155,4.49,3.929,6.347   c1.772,1.86,4.03,3.52,6.771,4.983c2.739,1.465,6.002,2.829,9.792,4.093c2.497,0.872,4.996,1.622,7.495,2.255v10.44   c-2.901-0.395-5.742-1.086-8.523-2.076c-2.78-0.988-5.219-1.997-7.314-3.025c-2.499-1.187-4.836-2.452-7.012-3.797L80,147.915   c4.353,2.532,9.268,4.627,14.75,6.288c5.479,1.661,11.002,2.729,16.562,3.203V172h9.671v-14.356   c3.707-0.238,7.294-0.89,10.76-1.957c3.464-1.068,6.548-2.649,9.248-4.747c2.7-2.094,4.876-4.784,6.529-8.068   c1.651-3.281,2.478-7.255,2.478-11.923c0-3.954-0.605-7.315-1.814-10.085C146.978,118.097,145.243,115.744,142.988,113.805z    M113.005,101.406c-1.612-0.632-2.822-1.324-3.626-2.076c-0.807-0.751-1.209-1.721-1.209-2.907c0-2.293,1.612-3.637,4.836-4.034   V101.406z M123.402,135.694c-0.807,0.634-2.176,1.029-4.111,1.187v-8.78c1.612,0.635,2.901,1.364,3.869,2.195   c0.967,0.83,1.451,1.801,1.451,2.907C124.611,134.231,124.207,135.063,123.402,135.694z" />
            <path d="M476.47,107.094C486.143,95.743,492,81.047,492,65c0-35.841-29.159-65-65-65s-65,29.159-65,65   c0,16.047,5.857,30.743,15.53,42.094C356.465,117.859,342,139.765,342,165v67h70v150h-41.978C362.649,325.651,314.328,282,256,282   s-106.649,43.651-114.022,100H100V232h130V0H0v232h70v180h71.978c7.373,56.349,55.694,100,114.022,100s106.649-43.651,114.022-100   H442V232h70v-67C512,139.765,497.535,117.859,476.47,107.094z M30,202V30h170v172H30z M256,482c-46.869,0-85-38.131-85-85   s38.131-85,85-85s85,38.131,85,85S302.869,482,256,482z M427,30c19.299,0,35,15.701,35,35s-15.701,35-35,35s-35-15.701-35-35   S407.701,30,427,30z M482,202H372v-37c0-19.299,15.701-35,35-35h40c19.299,0,35,15.701,35,35V202z" />
        </g>
    </svg>
);