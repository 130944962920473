import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faCog, faQuoteLeft, faCopyright } from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { instanceConfig } from '../../../instance';
import { IFullUserProfile } from '../../../entities/IProfile';
import { media } from '../../../styleHelpers/breakpoint';
import { SingleElement } from './Components/SingleElement';
import { IConfigReducer } from '../../../reducers/configReducer';
import { IState } from '../../../reducers';
import { ELegalClusterEnv } from '../../../entities/IConfiguration';
import { IBillingSubscription } from '../../../entities/Billing/Billing';
import { getBillingSubscriptions } from '../../../actions/billingActions';
import { modalsShadow } from '../../../styleHelpers/mixins/shadow';

type GetBillingSubscriptions = ReturnType<typeof getBillingSubscriptions>;

const { headerMenu } = instanceConfig;

const UserMenu = styled.div`
    ${media.desktop`
        position: absolute;
        top: 42px;
        right: 0;
        background: ${colorStack.white};
        ${modalsShadow()};
        overflow: hidden;
        border-radius: 8px;
    `}
`;

const Title = styled.div`
    font-size: ${fontSize[16]};
    color: ${colorStack.content};
    font-weight: 700;
    padding: 1rem 1rem .5rem 1rem;
`;

const LogoutButton = styled.button`
    cursor: pointer;
    outline: 0;
    margin-top: .5rem;
    padding: 1rem 0;
    font-weight: 500;
    border: 0;
    background: ${colorStack.whiteGrey};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colorStack.middleBlue};
    transition: color .2s ease;
    svg {
        margin-right: .5rem;
    }
    &:hover {
        color: ${colorStack.darkBlue};
    }
`;

interface IUserMenuDropdownProps {
    currentUserProfile: IFullUserProfile;
    closeDropdown?(): void;
}

export const UserMenuDropdown: FC<React.PropsWithChildren<IUserMenuDropdownProps>> = ({ currentUserProfile, closeDropdown }) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { platformName, isLocalHost } = useSelector<IState, IConfigReducer>(state => state.config);
    const [subscriptions, setSubscriptions] = useState<IBillingSubscription[]>(undefined);

    useEffect(() => {
        dispatch<GetBillingSubscriptions>(getBillingSubscriptions()).then((res) => setSubscriptions(res));
    }, []);

    const onLogout = useCallback(() => {
        if (IS_TEAMS_INSTANCE) {
            history('/teams/logout-start');
        } else {
            history('/logout');
        }
    }, []);

    return (
        <UserMenu>
            <Title>
                <FormattedMessage id="topnav.account" />
            </Title>
            {headerMenu.profile &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.viewMyProfile" />}
                    link={`/profile/${currentUserProfile?.id}`}
                    imagePath={currentUserProfile?.picture}
                    actionHandler={closeDropdown}
                />
            }
            {(isLocalHost || platformName === ELegalClusterEnv.lcDev || platformName === ELegalClusterEnv.lcRec || platformName === ELegalClusterEnv.lcDemo || platformName === ELegalClusterEnv.lcProd) && subscriptions?.length > 0 &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.billing" />}
                    link={'/billing'}
                    icon={faMoneyBill}
                    actionHandler={closeDropdown}
                />
            }
            {headerMenu.settings &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.settings" />}
                    link="/settings"
                    icon={faCog}
                    actionHandler={closeDropdown}
                />
            }
            {headerMenu.terms &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.information.terms" />}
                    link="/terms"
                    icon={faQuoteLeft}
                    actionHandler={closeDropdown}
                />
            }
            {headerMenu.privacyPolicy &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.information.privacy" />}
                    link="/copyrights"
                    icon={faCopyright}
                    actionHandler={closeDropdown}
                />
            }
            <LogoutButton onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOut} size="lg" />
                <FormattedMessage id="topnav.logout" />
            </LogoutButton>
        </UserMenu>
    );
};
