import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';

interface ITextProperties {
    bold?: boolean;
    underline?: boolean;
    textColor?: string;
    prependTab?: boolean;
    appendTab?: boolean;
}

const Wrapper = styled.span<ITextProperties>`
    word-break: break-word;
    color: ${colorStack.label};
    ${props => !!props.bold && css`
        font-weight: bold;
    `}
    ${props => !!props.underline && css`
        text-decoration: underline;
    `}
    ${props => !!props.textColor && props.textColor !== 'auto' && css`
        color: #${props.textColor};
    `}
    ${props => !!props.prependTab && css`
        &:before {
            content: ' ';
        }
    `}
    ${props => !!props.appendTab && css`
        &:after {
            content: ' ';
        }
    `}
`;

interface ITextRunProps extends ITextProperties {
    prependBreak?: boolean;
    appendBreak?: boolean;
}

export const TextRun: FC<React.PropsWithChildren<ITextRunProps>> = ({ bold, underline, textColor, prependTab, appendTab, prependBreak, appendBreak, children }) => {
    return (
        <Wrapper bold={bold} underline={underline} textColor={textColor} prependTab={prependTab} appendTab={appendTab} className="text-run">
            {prependBreak && <br />}
            {children}
            {appendBreak && <br />}
        </Wrapper>
    );
};
