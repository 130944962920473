import { DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';

export interface INewDesignTheme extends DefaultTheme {
    name: 'newDesign';
}

interface INewDesignDecorator {
    (theme: INewDesignTheme): FlattenSimpleInterpolation;
}

export const newDesignTheme: INewDesignTheme = {
    iOS: true,
    IE: true,
    Android: true,
    name: 'newDesign',
    colors: {
        primary: colorStack.darkBlue,
        secondary: colorStack.blue
    },
    font: {
        primary: 'Roboto',
        secondary: 'sans-serif'
    },
    fontImport: null
};

const isNewDesignTheme = (theme: DefaultTheme): theme is INewDesignTheme => {
    return !!theme && (theme as INewDesignTheme).name !== undefined;
};

export const newDesignThemeSwitch = (props: { theme: DefaultTheme }, fn: INewDesignDecorator) => {
    return isNewDesignTheme(props?.theme) ? fn(props.theme) : ``;
};
