import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const Circle = styled.div<{disabled: boolean, isActive: boolean}>`
    background: ${colorStack.white};
    border-radius: 100%;
    width: 16px;
    height: 16px;
    position: relative;
    left: 4px;
    transition: all 0.3s;
    ${({ disabled }) => disabled && css`
        background: ${colorStack.disabled};
    `}
    ${({ isActive }) => isActive === true && css`
        left: 22px;
    `}
`;

const Box = styled.div<{disabled: boolean, isActive: boolean}>`
    border-radius: 999px;
    width: 42px;
    height: 24px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color .2s ease;
    background: ${colorStack.middleBlue};
    ${({ disabled }) => disabled && css`
        background: ${colorStack.ligthGrey};
        cursor: default;
    `};
    ${({ isActive }) => !isActive && css`
        background: ${colorStack.ligthGrey};
    `};
`;

const Wrapper = styled.div<{disabled: boolean}>`
    display: flex;
    align-items: center;
    flex-direction: row;
    > span {
        ${fontSizeAndHeight[16]};
        transition: color .2s ease;
        color: ${colorStack.content};
        ${({ disabled }) => disabled && css`
            color: ${colorStack.disabled};
        `};
    }
`;
interface IProps {
    state: boolean | null;
    activeText?: string;
    className?: string;
    notActiveText?: string;
    disabled?: boolean;
    changeHandler?(value: boolean | null);
}

export const Switch: FC<React.PropsWithChildren<IProps>> = props => {

    const onChange = () => {
        props.changeHandler(!props.state);
    };

    return (
        <Wrapper disabled={props.disabled} className={props.className}>
            <Box isActive={props.state} onClick={!props.disabled ? onChange : undefined} disabled={props.disabled}>
                <Circle isActive={props.state} disabled={props.disabled} />
            </Box>
            {(props.activeText && props.notActiveText) &&
                <span>
                    {props.state ? props.activeText : props.notActiveText}
                </span>
            }
        </Wrapper>
    );
};
