import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { EProfileType } from '../../../../entities/IGlobal';
import { getEntityLink } from '../../../../tools/clusterTools';
import { Avatar, EAvatarSize } from '../../Avatar/Avatar';
import { MultilineTextWithEllipsis } from '../../TextWithEllipsis/MultilineTextWithEllipsis';

const Wrapper = styled(Link)`
    display: flex;
    align-items: center;
`;

const NoRedirectWrapper = styled.div`
    display: flex;
    cursor: default;
    align-items: center;
`;

const GroupWrapper = styled.div`
    display: flex;
    align-items: center;
    :hover {
        text-decoration: underline;
    }
`;

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    gap: .2rem;
    width: calc(100% - 56px - 1rem);
    margin-left: 1rem;
    > div:first-child span {
        font-weight: 600;
    }
`;

export interface IExtendedAvatarProps {
    childId: string;
    firstLine: string;
    picture: string;
    principalType: EProfileType;
    secondLine: string;
    groupAction?();
}

export const ExtendedAvatar: FC<React.PropsWithChildren<IExtendedAvatarProps>> = props => {
    const Content = <>
        <Avatar
            id={props.childId}
            firstName={props.firstLine}
            lastName=""
            size={EAvatarSize.MEDIUM}
            picture={props.picture}
            preventRedirection={props.principalType?.toLowerCase() === EProfileType.Group || !props.childId}
        />
        <RightSide>
            <MultilineTextWithEllipsis numberOfLines={1}>{props.firstLine}</MultilineTextWithEllipsis>
            <MultilineTextWithEllipsis numberOfLines={1}>{props.secondLine}</MultilineTextWithEllipsis>
        </RightSide>
    </>;

    return (
        <>
            {props.principalType?.toLowerCase() === EProfileType.Group ? (
                <GroupWrapper onClick={props.groupAction}>
                    {Content}
                </GroupWrapper>
            ) : (
                <>
                    {props.childId ? (
                        <Wrapper target="_blank" to={getEntityLink(props.childId, undefined, props.principalType?.toLowerCase() as EProfileType)}>
                            {Content}
                        </Wrapper>
                    ) : (
                        <NoRedirectWrapper>
                            {Content}
                        </NoRedirectWrapper>
                    )}

                </>
            )}
        </>
    );
};