import * as jsDiff from 'diff';
import * as jsondiffpatch from 'jsondiffpatch';

export interface IDomList {
    textValue?: string;
    tag?: string;
    sentenceDiff?: jsDiff.Change[];
}

export interface IDiffStructureElement {
    structureTitle: string;
    isArticle?: boolean;
    isAttachment?: boolean;
    domList?: IDomList[];
    commonId?: string;
    isSection?: boolean;
    isDeletedArticle?: boolean;
    attachmentExt?: string;
    attachmentFallback?: string;
    attachmentSrc?: string;
}

export interface IComparedStructureElement extends IDiffStructureElement {
    leftDomList?: IDomList[];
    rightDomList?: IDomList[];
    removed?: boolean;
    added?: boolean;
    diffResultHtml?: string;
    id?: string;
}

export interface IModifiedSentence {
    newNode: IDomList;
    oldNode: IDomList;
    textDiff: jsDiff.Change[];
}

export enum ENodeType {
    ELEMENT_NODE = 1,
    TEXT_NODE = 3
}

export const voidElements = ['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr'];

export const renderAttributes = (attrs: Attr[]) => attrs.map(attr => `${attr.name}="${attr.value}"`).join(' ');

export const renderTag = (tagName: string, attributes: Attr[], children: IDomList[] = []): IDomList[] => {
    const tag = (tagName || '').toLowerCase();
    const isVoidElement = voidElements.indexOf(tag) !== -1;

    if (isVoidElement) {
        return [{ tag: `<${tag} ${renderAttributes(attributes)} />` }];
    } else {
        return [
            { tag: `<${tag} ${renderAttributes(attributes)}>` },
            ...children,
            { tag: `</${tag}>` }
        ];
    }
};

export const joinTextNodes = (nodes: IDomList[]): IDomList[] => {
    const result: IDomList[] = [];
    let tempString: string = undefined;

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        if (typeof node.textValue === 'string') {
            tempString = `${tempString || ''}${node.textValue}`;
        } else {
            typeof tempString !== 'undefined' && result.push({ textValue: tempString });
            tempString = undefined;
            result.push(node);
        }
    }

    typeof tempString !== 'undefined' && result.push({ textValue: tempString });
    return result;
};

export const domToHtmlStringList = (element: HTMLElement): IDomList[] => {
    const parse = (node: Node): IDomList[] => {
        if (node.nodeType === ENodeType.TEXT_NODE) {
            return [{ textValue: node.nodeValue }];
        }

        if (node.nodeType === ENodeType.ELEMENT_NODE) {
            const nodeAsElement = (node as Element);
            const tagName = nodeAsElement?.tagName?.toLowerCase?.();

            return renderTag(
                tagName,
                Array.from(nodeAsElement.attributes),
                Array.from(node.childNodes || []).reduce<IDomList[]>((listNodes, childNode) => [...listNodes, ...parse(childNode)], [])
            );
        }
        return [];
    };

    const domList = Array.from(element.childNodes || []).reduce<IDomList[]>((listNodes, childNode) => [...listNodes, ...parse(childNode)], []);
    return joinTextNodes(domList);
};

export const renderDiffTextRun = (textValue: string, added: boolean, removed: boolean) => added ? `<ins>${textValue}</ins>` : removed ? `<del>${textValue}</del>` : textValue;

export const rebuildHtml = (diff: jsDiff.ArrayChange<IDomList>[], modifiedSentences: IModifiedSentence[]) => {
    modifiedSentences.forEach(modifiedSentence => {
        diff.some(diffObject => (diffObject.value || []).some(value => {
            if (value.textValue === modifiedSentence.newNode.textValue) {
                value.sentenceDiff = modifiedSentence.textDiff;
                return true;
            }
        }));

        diff.some(diffObject => (diffObject.value || []).some(value => {
            if (value.textValue === modifiedSentence.oldNode.textValue) {
                value.textValue = undefined;
                return true;
            }
        }));
    });

    return diff
        .reduce<string>((htmlString, diffObject) => {
            const values = diffObject.value.map(({ textValue, tag, sentenceDiff }) => {
                if (sentenceDiff) {
                    return sentenceDiff.map(sentenceDiffObject =>
                        renderDiffTextRun(sentenceDiffObject.value, sentenceDiffObject.added, sentenceDiffObject.removed)
                    ).join('');
                } else {
                    const textNode = textValue && renderDiffTextRun(textValue, diffObject.added, diffObject.removed);
                    return tag || textNode || '';
                }
            });
            return `${htmlString}${values.join('')}`;
        }, '');
};

export const mergeIdenticallyStyledTextRunsInDom = (articleContent: HTMLElement) => {
    Array.from(articleContent.querySelectorAll('.text-run:first-child') || []).forEach((run: HTMLElement) => {
        Array.from(run.parentElement.children || []).forEach((node: HTMLElement) => {
            if ((node.className || '').match('text-run') && node.previousElementSibling && node.previousElementSibling.className === node.className) {
                const htmlContent = node.innerHTML;
                node.previousElementSibling.insertAdjacentHTML('beforeend', htmlContent);
                node.remove();
            }
        });
    });
};

export const getStructureList = (rootElement: HTMLElement): IDiffStructureElement[] => {
    return Array.from(rootElement?.querySelectorAll('[data-diff-structure], [data-diff-article], [data-diff-attachment]') || []).map((domElement: HTMLElement) => {
        const isArticle = domElement.hasAttribute('data-diff-article');
        const isAttachment = domElement.hasAttribute('data-diff-attachment');
        const structureTitle = (domElement.querySelector('[data-diff-title]') as HTMLElement)?.innerText;
        const commonId = domElement.dataset.commonId;
        const articleDom = isArticle && domToHtmlStringList(domElement.querySelector('[data-content]'));
        const imageSrc = isAttachment && domElement.querySelector('img')?.src;
        const attachmentFallback = isAttachment && domElement.querySelector('span')?.innerText;
        const attachmentCompareText = isAttachment && `${imageSrc || attachmentFallback}.${domElement.dataset.ext}`;

        const structureElement: IDiffStructureElement = {
            structureTitle: isAttachment ? attachmentCompareText : structureTitle,
            isArticle,
            isAttachment,
            commonId,
            domList: articleDom,
            isSection: !!domElement.dataset.section,
            isDeletedArticle: false,
            attachmentExt: domElement.dataset.ext,
            attachmentFallback,
            attachmentSrc: imageSrc
        };

        return structureElement;
    });
};

export const prepareArticlesInStructure = (structureDiff: jsDiff.ArrayChange<IDiffStructureElement>[], leftStructureByCommonId: Record<string, IDiffStructureElement>, rightStructureByCommonId: Record<string, IDiffStructureElement>) => {
    const result: IComparedStructureElement[] = [];

    for (let i = 0; i < structureDiff.length; i++) {
        const structureDiffObject = structureDiff[i];

        const items: IComparedStructureElement[] = (structureDiffObject.value || []).map((object, index) => {
            const leftDomList = object.isArticle && (leftStructureByCommonId[object.commonId]?.domList || []);
            const rightDomList = object.isArticle && (rightStructureByCommonId[object.commonId]?.domList || []);
            return {
                ...object,
                leftDomList: structureDiffObject.added ? rightDomList : leftDomList,
                rightDomList: structureDiffObject.removed ? leftDomList : rightDomList,
                added: structureDiffObject.added,
                removed: structureDiffObject.removed,
                domList: undefined,
                id: `${object.commonId}-${index}-${i}`
            };
        });

        result.push(...items);
    }

    return result;
};

export const getModifiedSentencesDiff = (diffpatcher: jsondiffpatch.DiffPatcher, leftDomList: IDomList[], rightDomList: IDomList[]) => {
    const textDelta = diffpatcher.diff(leftDomList.filter(node => !node.tag), rightDomList.filter(node => !node.tag));

    return Object.keys(textDelta || {}).reduce<IModifiedSentence[]>((sentences, deltaKey) => {
        const isRemovedKey = deltaKey[0] === '_';
        const addedKey = isRemovedKey && deltaKey.replace('_', '');

        if (isRemovedKey && textDelta[addedKey]) {
            const oldNode = textDelta[deltaKey]?.[0] as IDomList;
            const newNode = textDelta[addedKey]?.[0] as IDomList;
            const textDiff = jsDiff.diffSentences(oldNode.textValue, newNode.textValue);

            return [...sentences, { newNode, oldNode, textDiff }];
        } else {
            return sentences;
        }
    }, []);
};
