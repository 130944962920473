import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDoNotEnter, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as Components from './Components';
import { INotification, ENotificationStatus, ENotificationType, ENotificationChannel } from '../../../../entities/INotification';
import { DateStandardFormat } from '../../../Common/Date/DateStandardFormat';
import { colorStack } from '../../../../styleHelpers/colors';
import { arrayToObject } from '../../../../tools/arrayTools';
import { IState } from '../../../../reducers';
import { Avatar, EAvatarSize } from '../../../Common/Avatar/Avatar';
import { EProfileType } from '../../../../entities/IGlobal';
import * as globalActions from '../../../../actions/globalActions';
import { ILanguageReducer } from '../../../../reducers/languageReducer';
import { NotificationsImage } from '../../../Routes/Notifications/Components';

interface INavMenuNotificationProps {
    notification: INotification;
    isSelectedNotification?: boolean;
    notificationPage?: boolean;
    openNotification?(notification: INotification): void;
    onNotificationClick?(): void;
    acceptOrDeclineInvitationToOrganization?(notificationId: string, organizationId: string, accepted: boolean);
    handleP2PAction?(accept: boolean, notification: INotification): void;
    marNotificationAsRead?(id: string): void;
}

interface ISelftState {
    nameTranslation: string;
}

class NavMenuNotification extends Component<INavMenuNotificationProps & WrappedComponentProps & typeof globalActions & ILanguageReducer, ISelftState> {

    state: ISelftState = {
        nameTranslation: ''
    };

    componentDidMount() {
        if (this.props.notification?.data?.Title?.FacetType === 'MixedReferentialElement') {
            this.props.getReferentialsByUniqueKey(this.props.notification?.data?.Title?.id).then(res => {
                this.setState({
                    nameTranslation: res.name.find(elem => elem.lcid === this.props.userLanguage).label
                });
            });
        }
    }

    openNotification = () => {
        const { openNotification, onNotificationClick, notification, marNotificationAsRead } = this.props;

        openNotification?.(notification);
        marNotificationAsRead?.(notification.id);
        onNotificationClick?.();
    }

    acceptInvitation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { notification, handleP2PAction } = this.props;
        handleP2PAction?.(true, notification);
        event.stopPropagation();
    }

    declineInvitation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { notification, handleP2PAction } = this.props;
        handleP2PAction?.(false, notification);
        event.stopPropagation();
    }

    acceptOrganizationInvitation = () => this.handleOrganizationInvitation();

    declineOrganizationInvitation = () => this.handleOrganizationInvitation(false);

    handleOrganizationInvitation = (isAccepted: boolean = true) => {
        const { notification, acceptOrDeclineInvitationToOrganization } = this.props;
        const organizationId = notification.data.Id || notification.data.id;

        acceptOrDeclineInvitationToOrganization?.(notification.id, organizationId, isAccepted);
    }

    mapNotificationDataToTranslationValues = (notification: INotification) => {
        return arrayToObject(
            Object.keys(notification.data || {}),
            key => key,
            key => (typeof notification.data[key] === 'string' && <strong>{notification.data[key]}</strong>) || undefined
        );
    }

    mapNotificationSenderDetailsToTranslationValues = (notification: INotification) => {
        return ({
            FirstName: <strong>{notification?.senderDisplayDetails?.firstName}</strong>,
            LastName: <strong>{notification?.senderDisplayDetails?.lastName}</strong>
        });
    }

    ObjectName = (notification: INotification) => {
        return notification?.data?.ObjectName;
    }

    isCustomNotificationRender = (notificationKey: string) => {
        if (notificationKey === ENotificationType.ValidatorVotedOnBehalf || notificationKey === ENotificationType.ValidationRequestClosedNotificationForCreatorUser || notificationKey === ENotificationType.SignatureUpdated) {
            return true;
        }
        return false;
    }

    render() {
        const { notification, isSelectedNotification, notificationPage, handleP2PAction, acceptOrDeclineInvitationToOrganization, intl } = this.props;
        const isActive = notification.status === ENotificationStatus.NotRead;
        return (
            <Components.Wrapper key={notification.id} onClick={this.openNotification} isActive={isActive} notificationPage={notificationPage}>
                <Components.LeftWrapper>
                    <NotificationsImage as={notification.senderDisplayDetails ? Link : 'a'} to={notification.senderDisplayDetails && `/profile/${notification.senderDisplayDetails.userId}`}>
                        {notification.channelId === ENotificationChannel.LegalDoc ? (
                            <>
                                {notification.key === ENotificationType.Information && (
                                    <FontAwesomeIcon icon={faCheckCircle} color={colorStack.middleGreen} size="2x" />
                                )}
                                {notification.key === ENotificationType.Exception && (
                                    <FontAwesomeIcon icon={faDoNotEnter} color={colorStack.middleRed} size="2x" />
                                )}
                                {notification.key === ENotificationType.Warning && (
                                    <FontAwesomeIcon icon={faExclamationTriangle} color={colorStack.darkOrange} size="2x" />
                                )}
                            </>
                        ) : (
                            <>
                                <Avatar
                                    picture={notification?.senderDisplayDetails?.picture}
                                    firstName={notification?.senderDisplayDetails?.firstName}
                                    lastName={notification?.senderDisplayDetails?.lastName}
                                    id={notification?.senderDisplayDetails?.userId}
                                    type={EProfileType.Personal}
                                    preventRedirection
                                    size={EAvatarSize.SMALL}
                                />
                                <Components.Badge className={notification.key} />
                            </>
                        )}
                    </NotificationsImage>
                    <Components.Content>
                        <Components.Text>
                            {(notification?.data?.MoveBetweenBooksLanguageConsistencyMessage && !this.isCustomNotificationRender(notification?.key)) && (
                                <div>
                                    <FormattedMessage id="notificationsLabels.MoveBetweenBooksLanguageConsistencyMessage" />
                                    {notification?.data?.MoveBetweenBooksLanguageConsistencyMessage?.split(' ').map((el) => (
                                        el?.includes('SystemInfo.SourceBranch') ? (
                                            <span>{el?.includes('TR') ? <FormattedMessage id="notificationsLabels.SystemInfo.SourceBranch" /> : notification?.data?.SystemInfo?.SourceBranch}&nbsp;</span>
                                        ) : el?.includes('SystemInfo.TargetBranch') ? (
                                            <span>{el?.includes('TR') ? <FormattedMessage id="notificationsLabels.SystemInfo.TargetBranch" /> : notification?.data?.SystemInfo?.TargetBranch}&nbsp;</span>
                                        ) : (
                                            <span>{el} &nbsp;</span>
                                        )
                                    ))}
                                </div>
                            )}
                            {(!notification?.data?.MoveBetweenBooksLanguageConsistencyMessage && !this.isCustomNotificationRender(notification?.key)) && (
                                <FormattedMessage
                                    id={`notificationsLabels.${notification?.key}`}
                                    values={{
                                        ...this.ObjectName,
                                        ...this.mapNotificationSenderDetailsToTranslationValues(notification),
                                        ...this.mapNotificationDataToTranslationValues(notification),
                                        // add manual mapping here if needed:
                                        RfpTitle: <strong>{notification?.data?.RfpTitle || notification?.data?.rfpTitle || 'No title'}</strong>,
                                        'Title.Name': <strong>{notification?.data?.Title?.FacetType === 'MixedReferentialElement' ? this.state.nameTranslation : notification?.data?.Title?.Name}</strong>,
                                        'Name': <strong>{notification?.data?.Title?.FacetType === 'MixedReferentialElement' ? this.state.nameTranslation : notification?.data?.Title?.Name}</strong>
                                    }}
                                />
                            )}
                            {notification.key === ENotificationType.ValidatorVotedOnBehalf && (
                                <FormattedMessage
                                    id={`notificationsLabels.${notification?.key}`}
                                    values={{
                                        InvokerName: <strong>{notification?.data?.invokerName} </strong>,
                                        ValidatorStatusTranslationKey: <strong>{notification?.data?.validatorStatusTranslationKey && intl.formatMessage({ id: notification?.data?.validatorStatusTranslationKey })?.toLowerCase()}</strong>
                                    }}
                                />
                            )}
                            {notification.key === ENotificationType.ValidationRequestClosedNotificationForCreatorUser && (
                                <FormattedMessage
                                    id={`notificationsLabels.${notification?.key}`}
                                    values={{
                                        UserName: <strong>{notification?.data?.userName}</strong>,
                                        ClusterName: <strong>{notification?.data?.resourceName}</strong>,
                                        ApprovalStatus: <strong>{notification?.data?.validationRequestStatusTranslationKey && intl.formatMessage({ id: notification?.data?.validationRequestStatusTranslationKey })?.toLowerCase()}</strong>
                                    }}
                                />
                            )}
                            {notification.key === ENotificationType.SignatureUpdated && (
                                <FormattedMessage
                                    id={`notificationsLabels.${notification?.key}`}
                                    values={{
                                        EnvelopeName: <strong>{notification?.data?.envelopeName}</strong>,
                                        Status: <strong>{intl.formatMessage({ id: notification?.data?.statusTranslationKey })?.toLowerCase()}</strong>
                                    }}
                                />
                            )}
                        </Components.Text>
                        <Components.Metas>
                            <DateStandardFormat date={notification.creationDateTime} showTime />
                        </Components.Metas>
                    </Components.Content>
                    {(notification.key === ENotificationType.P2PInvitation && !notification?.data?.ActionHandled) && !!handleP2PAction && (
                        <Components.P2PSection>
                            <Components.DisabledButton className="decline" onClick={this.declineInvitation} disabled={isSelectedNotification} />
                            <Components.DisabledButton className="accept" onClick={this.acceptInvitation} disabled={isSelectedNotification} />
                        </Components.P2PSection>
                    )}
                    {notification.key === ENotificationType.InvitationToOrganization && !notification?.data?.ActionHandled && !!acceptOrDeclineInvitationToOrganization && (
                        <Components.P2PSection>
                            <Components.DisabledButton className="decline" onClick={this.declineOrganizationInvitation} disabled={isSelectedNotification} />
                            <Components.DisabledButton className="accept" onClick={this.acceptOrganizationInvitation} disabled={isSelectedNotification} />
                        </Components.P2PSection>
                    )}
                </Components.LeftWrapper>
            </Components.Wrapper>
        );
    }
}

export default connect((state: IState) => ({
    ...state.global,
    ...state.language
}),
    {
        ...globalActions
    })(injectIntl(NavMenuNotification));
