import React, { useEffect, useState } from 'react';
import { useFormikContext, FormikErrors } from 'formik';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import { IBeneficiary, IFormData, EDeclarationTypeEnum } from '../../../../entities/IDeclaration';
import { EmailRegExp } from '../../../../tools/emailChecker';
import { Button, EButtonTypeSchema } from '../../../Common/Buttons/NewButton';
import { MandatoryFieldStar } from '../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { SingleBeneficiaryBox } from './SingleBeneficiaryBox';
import * as PopupStyles from '../../Common/Styles';
import { EProfileType } from '../../../../entities/IGlobal';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
    .add {
        margin-left: 9%;
    }
`;

export const Beneficiary = () => {
    const { setFieldValue, values, errors } = useFormikContext<IFormData>();
    const [addBeneficiaryDisabled, setAddBeneficiaryDisabled] = useState<boolean>(false);

    useEffect(() => {
        setFieldValue('step', 4);
    }, []);

    useEffect(() => {
        const isBeneficiaryFilled = values.formData.thirdParties.find(({ fullName, userData, legalEntity, email, relationshipNature, sensitivePerson }) => fullName === '' || userData?.id === ''
            || legalEntity === null || legalEntity?.name === '' || legalEntity?.id === '' || !email?.match(EmailRegExp) || !relationshipNature || sensitivePerson === undefined);
        if (!isBeneficiaryFilled) {
            setAddBeneficiaryDisabled(true);
        } else {
            setAddBeneficiaryDisabled(false);
        }
    }, [values]);

    const addBeneficiary = () => {
        if (addBeneficiaryDisabled) {
            setFieldValue(
                'formData.thirdParties',
                [...values.formData.thirdParties, {
                    userData: {
                        firstName: '',
                        lastName: '',
                        picture: '',
                        Address: '',
                        DataType: '',
                        IsPublic: true,
                        LegalForm: {
                            id: '',
                            Name: '',
                            Parents: []
                        },
                        Name: '',
                        Picture: '',
                        Size: '',
                        TypeOfLegalEntity: {
                            id: '',
                            Name: '',
                            Parents: []
                        },
                        id: ''
                    },
                    fullName: '',
                    jobTitle: '',
                    legalEntity: {
                        legalForm: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        type: EProfileType.Company,
                        typeOfLegalEntity: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        isPublic: undefined,
                        description: '',
                        registeredCapital: {
                            number: {},
                            unit: '',
                            baseNumber: {},
                            baseUnit: ''
                        },
                        phoneNumber: '',
                        websiteUrl: '',
                        registrations: [{
                            aliasType: {
                                id: '',
                                name: '',
                                parents: [
                                    ''
                                ]
                            },
                            aliasValue: '',
                            date: ''
                        }],
                        nationality: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        group: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        dateBegin: '',
                        dateEnd: '',
                        name: '',
                        picture: '',
                        presentation: '',
                        address: {
                            number: '',
                            street: '',
                            city: '',
                            state: '',
                            zipCode: '',
                            country: '',
                            countryShort: ''
                        },
                        id: ''
                    }
                }]
            );
        }
    };

    useEffect(() => {
        if (values.formData.thirdParties?.length === 0) {
            setFieldValue(
                'formData.thirdParties',
                [{
                    userData: {
                        firstName: '',
                        lastName: '',
                        picture: '',
                        Address: '',
                        DataType: '',
                        IsPublic: true,
                        LegalForm: {
                            id: '',
                            Name: '',
                            Parents: []
                        },
                        Name: '',
                        Picture: '',
                        Size: '',
                        TypeOfLegalEntity: {
                            id: '',
                            Name: '',
                            Parents: []
                        },
                        id: ''
                    },
                    fullName: '',
                    jobTitle: '',
                    legalEntity: {
                        legalForm: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        type: EProfileType.Company,
                        typeOfLegalEntity: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        isPublic: undefined,
                        description: '',
                        registeredCapital: {
                            number: {},
                            unit: '',
                            baseNumber: {},
                            baseUnit: ''
                        },
                        phoneNumber: '',
                        websiteUrl: '',
                        registrations: [{
                            aliasType: {
                                id: '',
                                name: '',
                                parents: [
                                    ''
                                ]
                            },
                            aliasValue: '',
                            date: ''
                        }],
                        nationality: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        group: {
                            id: '',
                            name: '',
                            parents: [
                                ''
                            ]
                        },
                        dateBegin: '',
                        dateEnd: '',
                        name: '',
                        picture: '',
                        presentation: '',
                        address: {
                            number: '',
                            street: '',
                            city: '',
                            state: '',
                            zipCode: '',
                            country: '',
                            countryShort: ''
                        },
                        id: ''
                    }
                }]
            );
        }
    }, [values.formData.thirdParties.length]);

    return (
        <Wrapper>
            {values.formData.thirdParties.map((beneficiary, index) =>
                <SingleBeneficiaryBox key={index} index={index} errors={errors?.formData?.thirdParties?.[index] as FormikErrors<IBeneficiary>} />
            )}
            {values?.formData?.declaration?.type !== EDeclarationTypeEnum.receivedInvitation && (
                <Button onClick={addBeneficiary} leftIco={faPlus} typeSchema={EButtonTypeSchema.TEXT} disabled={!addBeneficiaryDisabled}>
                    <FormattedMessage id={(values.formData.declaration.type === EDeclarationTypeEnum.offeredGift || values.formData.declaration.type === EDeclarationTypeEnum.offeredInvitation)
                        ? 'giftinvitation.button.addBeneficiary'
                        : 'giftinvitation.button.addDonor'}
                    />
                </Button>
            )}
            <PopupStyles.RequiredInformation>
                <PopupStyles.Title>
                    <MandatoryFieldStar />
                    <FormattedMessage id="giftinvitation.requiredInfo" />
                </PopupStyles.Title>
            </PopupStyles.RequiredInformation>
        </Wrapper>
    );
};
