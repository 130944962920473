import React, { FC, useCallback, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div<{ disabled: boolean }>`
    height: 24px;
    [type="radio"] {
        position: absolute;
        left: -9999px;
    };
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 32px;
        cursor: pointer;
        display: flex;
        width: 100%;
        padding-top: 4px;
        flex-wrap: nowrap;
        transition: all 0.2s ease;
        white-space: nowrap;
        color: ${colorStack.content};
        ${fontSizeAndHeight[16]};
        ${({ disabled }) => disabled && css`
            color: ${colorStack.disabled};
        `};
    };
    [type="radio"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        transition: all 0.2s ease;
        border: 2px solid ${colorStack.middleBlue};
        background: ${colorStack.whiteBlue};
        ${({ disabled }) => disabled && css`
            border: 2px solid ${colorStack.disabled};
            background: ${colorStack.bodyBg};
        `};
    };
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        transition: all 0.2s ease;
        height: 20px;
        border: 2px solid ${colorStack.ligthGrey};
        border-radius: 100%;
        background: ${colorStack.white};
    };
    [type="radio"]:checked + label:hover:before,
    [type="radio"]:not(:checked) + label:hover:before {
        border: 2px solid ${colorStack.middleBlue};
        ${({ disabled }) => disabled && css`
            border: 2px solid ${colorStack.ligthGrey};
        `};
    };
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: ${colorStack.middleBlue};
        position: absolute;
        top: 7px;
        left: 7px;
        border-radius: 100%;
        transition: all 0.2s ease;
        ${({ disabled }) => disabled && css`
            background: ${colorStack.bodyBg};
        `};
    };
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
        ${({ disabled }) => disabled && css`
            color: ${colorStack.disabled};
        `};
    };
    [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
        ${({ disabled }) => disabled && css`
            background-color: ${colorStack.disabled};
        `};
    };
`;

const Label = styled.label`
    display: block;
    padding: 0;
    ${fontSizeAndHeight[16]};
    color: ${colorStack.content};
`;

interface IProps {
    label?: string;
    name?: string;
    value?: string | boolean;
    checked?: boolean;
    disabled?: boolean;
    testingDataAttr?: string;
    onClick?(e: React.MouseEvent<HTMLButtonElement>);
}

export const RadioButton: FC<React.PropsWithChildren<IProps>> = props => {

    const onClickHandler = useCallback((e) => {
        if (!props.disabled) {
            props.onClick?.(e);
        }
    }, [props.onClick, props.disabled]);

    return (
        <Wrapper
            disabled={props.disabled}
            onClick={onClickHandler}
        >
            <input
                type="radio"
                name={props.name}
                value={props.value && props.value.toString()}
                checked={props.checked}
            />
            <Label dangerouslySetInnerHTML={{ __html: props.label }} />
        </Wrapper>
    );
};
