import * as actionTypes from './actionTypes/onboardingTypes';
import { IFullUserProfile } from '../entities/IProfile';
import { Http } from '../tools/http';
import { EProfileType } from '../tools/profileConstants';

export const getOnboardingProfileScore = (currentProfile: IFullUserProfile) => {

    let score = 0;
    const profileScoreDetails = {
        picture: false,
        expertisesList: false,
        companyList: false,
        degreesList: false,
        roleCategory: false
    };

    if (currentProfile.picture && (currentProfile.picture || '').trim()) {
        ++score;
        profileScoreDetails.picture = true;
    }

    if (currentProfile?.expertises?.length || currentProfile.profileType !== EProfileType.Lawyer) {
        ++score;
        profileScoreDetails.expertisesList = true;
    }

    if (currentProfile?.companies?.length) {
        ++score;
        profileScoreDetails.companyList = true;
    }

    if (currentProfile?.degrees?.length) {
        ++score;
        profileScoreDetails.degreesList = true;
    }

    if (currentProfile?.roleFunctions?.length) {
        ++score;
        profileScoreDetails.roleCategory = true;
    }

    return {
        type: actionTypes.ONBOARDING_GET_PROFILE_SCORE,
        score: score / 5,
        profileScoreDetails
    };
};

export const getOnboardingInviteScore = () => ((dispatch, getState, http: Http) => {
    http.apiGet('/invitations/sent/count').then(response => {
        dispatch({
            type: actionTypes.ONBOARDING_GET_INVITE_SCORE,
            score: parseInt(response, 10)
        });
    });
}) as any;
