export enum ECategoryStatus {
    Prefered = 'Prefered',
    Accepted = 'Accepted',
    Declined = 'Declined'
}

export interface IFinancialCategory {
    status: ECategoryStatus;
    name: string;
}

export interface IFinancialsForm {
    comment: string;
    isAlternativeFeeOpen: boolean;
    hourlyBilling: IFinancialCategory; 
    contingentFee: IFinancialCategory; 
    percentageFee: IFinancialCategory;
    fixedFee: IFinancialCategory; 
    retainer: IFinancialCategory; 
    courtAwardFee: IFinancialCategory; 
    valueBilling: IFinancialCategory; 
    taskBased: IFinancialCategory; 
}
