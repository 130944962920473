import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';
import { MultilineTextWithEllipsis } from '../TextWithEllipsis/MultilineTextWithEllipsis';
import { ToolTipWrapper } from '../ToolTip/ToolTipWrapper';

const Wrapper = styled.div<{ disabled: boolean }>`
    display: flex;
    max-width: 90%;
    margin-bottom: 4px;
    > label {
        max-width: 100%;
        > div {
            display: inline-block;
        }
    }
    span {
        ${props => props.disabled && css`
            color: ${colorStack.disabled};
        `};
    }
`;

const Label = styled.label<{ disabled: boolean }>`
    display: block;
    padding: 0;
    ${fontSizeAndHeight[13]};
    font-style: normal;
    font-weight: 700;
    color: ${colorStack.content};
    ${props => props.disabled && css`
        color: ${colorStack.disabled};
    `};
`;

interface IProps {
    required?: boolean;
    disabled?: boolean;
    tooltipMsg?: string;
    tooltipTitle?: string;
    numberOfLines?: number;
    onClick?();
}

export const LabelWithEllipsis: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper disabled={props.disabled} onClick={props.onClick}>
            {(props.numberOfLines > 1 && (props.tooltipTitle || props.required)) ? (
                <>
                    <Label disabled={props.disabled}>
                        {props.children}
                        {props.tooltipTitle &&
                            <ToolTipWrapper withIco title={props.tooltipTitle} content={props.tooltipMsg} />
                        }
                        {props.required &&
                            <MandatoryFieldStar />
                        }
                    </Label>
                </>
            ) : (
                <>
                    <Label disabled={props.disabled}>
                        <MultilineTextWithEllipsis numberOfLines={props.numberOfLines || 1}>
                            {props.children}
                        </MultilineTextWithEllipsis>
                    </Label>
                    {props.tooltipTitle &&
                        <ToolTipWrapper withIco title={props.tooltipTitle} content={props.tooltipMsg} />
                    }
                    {props.required &&
                        <MandatoryFieldStar />
                    }
                </>
            )}
        </Wrapper>
    );
};
