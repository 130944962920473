import React, { FC, ReactNode } from 'react';
import { rgba } from 'polished';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered, faFolder } from '@fortawesome/pro-solid-svg-icons';

import { IColumnSettings } from '../../../entities/IClusters';
import { EColumnType, EColoredDatagridField } from '../../../entities/IDatagrid';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { getStatusColor, getValidationBackground } from '../../../tools/statusHelper';
import { NumberType } from './CellTypeComponents/Number';
import { TextType } from './CellTypeComponents/Text';
import { CommentType } from './CellTypeComponents/Comment';
import { EnumType } from './CellTypeComponents/Enum';
import { PillType } from './CellTypeComponents/Pill';
import { PartyType } from './CellTypeComponents/Party';
import { ReferentialsType } from './CellTypeComponents/Referentials';
import { ClusterLinkType } from './CellTypeComponents/ClusterLink';
import { DateType } from './CellTypeComponents/Date';
import { DateAgoType } from './CellTypeComponents/DateAgo';
import { BooleanType } from './CellTypeComponents/Boolean';
import { MailType } from './CellTypeComponents/Mail';
import { StatusType } from './CellTypeComponents/Status';
import { EColumnSubTypes } from '../../../tools/dataGridTools';
import { HyperLinkSwitcher } from './CellTypeComponents/HyperLink';
import { LastEditedType } from './CellTypeComponents/LastEdited';
import { Overview } from './CellTypeComponents/Overview';
import { ClusterIconType } from './CellTypeComponents/ClusterIcon';
import { ButtonType } from './CellTypeComponents/Button';
import { DropdownType } from './CellTypeComponents/Dropdown';
import { ExtendedAvatar } from './CellTypeComponents/ExtendedAvatar';
import { People } from './CellTypeComponents/People';
import { UniversalSwitcher } from './CellTypeComponents/UniversalSwitcher';
import { Attachments } from './CellTypeComponents/Attachments';

const Tr = styled.tr<{ hasColoredColumn: boolean; coloredRowSchema: boolean; statusColor: string; backgroungColor: string}>`
    margin: 0 0 2px 0;
    background: ${colorStack.white};
   ${({coloredRowSchema, backgroungColor}) => coloredRowSchema && css`
        background: ${rgba(backgroungColor, .4)};
    `}
    cursor: pointer;
    td {
        margin: 0 1px;
        height: 72px;
        vertical-align: middle;
        padding: 0 0.8rem;
        ${fontSizeAndHeight[13]};
        ${({statusColor}) => statusColor && css`
            --status-color: ${statusColor};
            --status-color-transparent: ${statusColor === colorStack.disabled ? colorStack.bodyBg : rgba(statusColor, .4)};
        `}
        ${({hasColoredColumn, statusColor}) => hasColoredColumn && css`
            &:first-child {
                width: 8px;
                min-width: 8px;
                max-width: 8px;
                padding: 1rem 0;
                background: ${ statusColor === colorStack.disabled ? colorStack.ligthGrey : 'var(--status-color)'};
                border-left: none!important;
                border-right: none!important;
            }
            &:nth-child(2) {
                border-left: 2px solid rgba(0,0,0, 0.1) !important;
            }
            `}
        :has(.type-icon-button) {
            padding: 0!important;
        }
        :has(.type-icon) {
            padding: 0!important;
        }
        > a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
`;

const LoadingTd = styled.td`
    &:before {
        content: '';
        display: block;
        background: ${colorStack.ligthGrey};
        border-radius: 30px;
        height: 15px;
    }
`;

const Td = styled.td<{isStatus?: boolean}>`
   ${({ isStatus }) => isStatus && css`
        padding: 0!important;
   `}
`;

const WrapperLink = styled(Link)`
    display: flex;
    &:hover { text-decoration: none; }
`;

interface IProps {
    data: any;
    settings: IColumnSettings[];
    loading?: boolean;
    coloredFieldName?: EColoredDatagridField;
    coloredRowSchema?: EColoredDatagridField;
    onClick?(id: string, clusterId: string);
}

const subTypePrefix: Partial<Record<EColumnSubTypes, ReactNode>> = {
    [EColumnSubTypes.Id]: '#',
    [EColumnSubTypes.Order]: '#',
    [EColumnSubTypes.Messages]: <FontAwesomeIcon icon={faBarsStaggered} color={colorStack.darkGrey} />,
    [EColumnSubTypes.Documents]: <FontAwesomeIcon icon={faFolder} color={colorStack.darkGrey} />,
    [EColumnSubTypes.MultiStake]: ''
};

const subTypeToDateFormat: Partial<Record<EColumnSubTypes, 'datetime' | 'date' | 'relativeDate'>> = {
    [EColumnSubTypes.Relative]: 'relativeDate',
    [EColumnSubTypes.Date]: 'date',
    [EColumnSubTypes.Datetime]: 'datetime'
};

export const SingleRow: FC<React.PropsWithChildren<IProps>> = props => {
    const { organization } = useParams<{ organization: string }>();
    const statusColor = getStatusColor(props.data?.[EColoredDatagridField.Status]?.translationKey) ||
        getStatusColor(props.data?.[props.coloredFieldName]?.value) ||
        getStatusColor(props.data?.[EColoredDatagridField.Status]?.value) ||
        getStatusColor(props.data?.[props.coloredFieldName]?.translationKey) ||
        colorStack.whiteGrey;

    const getBacgroundColor = getValidationBackground(props.data?.[EColoredDatagridField.Status]?.translationKey) || colorStack.whiteGrey;

    const typeMapper = (type: EColumnType, subType: EColumnSubTypes | null, field: string) => {
        switch (type) {
            case EColumnType.enum:
                if (!props.data?.[field]?.translationKey) return '-';
                return (field === EColoredDatagridField.Status || subType === EColumnSubTypes.Status) ? (
                    <StatusType
                        translationKey={props.data[field].translationKey}
                        date={props.data[field]?.date}
                        text={props.data[field]?.text}
                    />
                ) : (
                    <EnumType translationKey={props.data[field].translationKey} />
                );
            case EColumnType.text:
                return <TextType>{props.data?.[field]}</TextType>;
            case EColumnType.number:
                return <NumberType subType={subType}>{subTypePrefix[subType]}{props.data?.[field]}</NumberType>;
            case EColumnType.comment:
                return <CommentType>{props.data?.[field] > 0 && props.data?.[field]}</CommentType>;
            case EColumnType.pill:
                return <PillType elements={props.data?.[field]} />;
            case EColumnType.party:
                return <PartyType elements={props.data?.[field]} />;
            case EColumnType.person:
                return <PartyType elements={props.data?.[field]} />;
            case EColumnType.people:
                return <People
                    members={props.data?.[field].people}
                    context={props.data?.[field].context}
                    organizationUrlName={props.data?.[field].organizationUrlName}
                    id={props.data?.[field].id}
                    linkPanel={props.data?.[field].linkPanel}
                />;
            case EColumnType.referentials:
                return <ReferentialsType>{(props.data?.[field] || [])?.map(elem => elem?.value).join(', ')}</ReferentialsType>;
            case EColumnType.hyperlink:
                return <HyperLinkSwitcher {...{
                    routeName: props.data?.[field].routeName,
                    data: {...props.data?.[field]}
                }}>{subTypePrefix[subType] ? (
                    <>
                        {subTypePrefix[subType]}{props.data?.[field].text}
                    </>
                ) : props.data?.[field].text}
                </HyperLinkSwitcher>;
            case EColumnType.clusterLink:
                return <ClusterLinkType data={props.data?.[field]} organization={organization}/>;
            case EColumnType.date:
                return <DateType>{props.data?.[field] || ''}</DateType>;
            case EColumnType.dateAgo:
                return <DateAgoType>{props.data?.[field]}</DateAgoType>;
            case EColumnType.lastEditedBy:
                // FIXME use cluster-overview hard-coded dependency
                return props.data?.['cluster-overview']?.organizationUrlName ? <WrapperLink to={`/orgs/${props.data?.['cluster-overview'].organizationUrlName}/cluster/${props.data?.['cluster-overview'].clusterId}/activity`}>
                    <LastEditedType user={props.data?.[field]?.user} date={props.data?.[field]?.date} format={subTypeToDateFormat[subType]} preventRedirection/>
                </WrapperLink> : <LastEditedType user={props.data?.[field]?.user} date={props.data?.[field]?.date} format={subTypeToDateFormat[subType]} preventRedirection/>;
            case EColumnType.boolean:
                return <BooleanType>{props.data?.[field]}</BooleanType>;
            case EColumnType.mail:
                return <MailType>{props.data?.[field]}</MailType>;
            case EColumnType.overview:
                return <Overview {...props.data?.[field]} subType={subType} />;
            case EColumnType.clusterTemplate:
                // FIXME use cluster-overview hard-coded dependency
                return props.data?.['cluster-overview']?.organizationUrlName ? <WrapperLink to={`/orgs/${props.data?.['cluster-overview'].organizationUrlName}/cluster/${props.data?.['cluster-overview'].clusterId}/overview`}>
                    <ClusterIconType clusterTemplate={props.data?.[field]} />
                </WrapperLink> : <ClusterIconType clusterTemplate={props.data?.[field]} />;
            case EColumnType.extendedAvatar:
                return <ExtendedAvatar {...props.data?.[field]} />;
            case EColumnType.dropdown:
                return <DropdownType {...props.data?.[field]} />;
            case EColumnType.button:
                return <ButtonType {...props.data?.[field]} />;
            case EColumnType.universalSwitcher:
                return <UniversalSwitcher {...props.data?.[field]} />;
            case EColumnType.ATTACHMENTS:
                return <Attachments {...props.data?.[field]} />;
            default:
                // tslint:disable-next-line: no-null-keyword
                return null;
        }
    };

    const clickHandler = () => {
        props.onClick && props.onClick(props.data.id, props.data.clusterId);
    };

    return (
        <Tr hasColoredColumn={!!props.coloredFieldName} coloredRowSchema={!!props.coloredRowSchema} statusColor={statusColor} backgroungColor={getBacgroundColor} onClick={clickHandler}>
            {props.loading ? (
                <>
                    {props.coloredFieldName && <LoadingTd/>}
                    {props.settings?.map(({field}) => <LoadingTd key={field}/>)}
                </>
            ) : (
                <>
                    {props.coloredFieldName && <Td/>}
                    {props.settings?.map(({field, type, subType}) => <Td key={field} isStatus={(field === EColoredDatagridField.Status || subType === EColumnSubTypes.Status) && type === EColumnType.enum}>{typeMapper(type, subType, field)}</Td>)}
                </>
            )}
        </Tr>
    );
};
