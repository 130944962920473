import styled, { css } from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { media } from '../../../../styleHelpers/breakpoint';

export const Label = styled.div`
    opacity: .5;
    color: ${colorStack.darkBlue};
    margin-bottom: 0.8rem;
    font-size: ${fontSize[13]};
`;

export const PresentationSection = styled.div`
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    ${media.desktop`
        flex-direction: row;
    `};
`;

export const RowItem = styled.div`
    margin: 0 0.6rem;

    ${media.tabletSm`
        margin: 0 1rem;
    `}

    ${media.tablet`
        margin: 0 2rem;
    `}

    &:first-child {
        margin-left: 0;
    }
`;

export const PresentationRow = styled.div`
    display: flex;
`;

export const EditPen = styled.div`
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
        margin-right: 0.5rem;
    }
`;

export const AddNewElementWrapper = styled.div<{creatingNew?: boolean}>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px;
    > svg {
        margin: 0 5px 0 0;
    }
    > span {
        font-weight: 500;
    }
    ${props => props.creatingNew && css`
        opacity: .5;
        cursor: default;
    `}
`;