import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { IValue } from '../../../entities/IPickers';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { ContextMenu } from '../ContextMenu/ContextMenu';

const CustomElem = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    padding: .8rem 1rem;
    font-weight: 400;
    text-align: left;
    transition: all .3s;
    cursor: pointer;
    display: flex;
    &:hover {
        background: ${colorStack.lightBlue};
        font-weight: 500;
    }
`;

const ColorCircle = styled.div<{ color: string }>`
    border-radius: 100%;
    margin-right: .5rem;
    width: 16px;
    height: 16px;
    ${props => props.color && css`
        background: ${props.color};
    `}
`;

interface IProps {
    options: IValue[];
    value: IValue[];
    onChange(item: IValue);
}

export const StatusMenu = (props: IProps) => {
    return (
        <ContextMenu links={props.options?.map(elem => ({
            customRender:
                <CustomElem onClick={() => props.onChange(elem)}>
                    <ColorCircle color={elem.data.color} /><FormattedMessage id={elem.text} />
                </CustomElem>,
            visible: true
        }))} />
    );
};