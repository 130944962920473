import * as actionTypes from '../actions/actionTypes/searchTypes';
import { SearchFacets, ISearchUser } from '../entities/ISearch';

export interface ISearchReducer {
    searchResult: {
        items: ISearchUser[],
        facets: SearchFacets,
        count: number
    };
    searchResultLoaded: boolean;
}

const defaultState = (): ISearchReducer => ({
    searchResult: undefined,
    searchResultLoaded: false
}) as ISearchReducer;

export default (state = defaultState(), action): ISearchReducer => {
    switch (action.type) {

        case actionTypes.GET_SEARCH_RESULT: {
            return {
                ...state,
                searchResult: action.searchResult,
                searchResultLoaded: true
            };
        }

        case actionTypes.CLEAR_SEARCH_RESULT: {
            return {
                ...state,
                searchResult: undefined,
                searchResultLoaded: false
            };
        }

        default: {
            return state;
        }
    }
};