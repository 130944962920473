import * as actionTypes from '../actions/actionTypes/recommendationTypes';
import { IRecommendedCompany } from '../entities/RFP/IRecommendedCompany';
import { IRecomendedLawyer } from '../entities/RFP/IRecomendedLawyer';

interface ICompanySearchResult {
    Companies: IRecommendedCompany[];
    Count: number;
}

interface IRecomendedLawyersExtraInfo {
    count: number;
    values: IRecomendedLawyer[];
}

export interface IRecommendationsReducer {
    loadingRecomendedLawyers?: boolean;
    recomendedLawyers?: IRecomendedLawyersExtraInfo;
    loadingCompaniesResult?: boolean;
    companiesResult?: ICompanySearchResult;
    loadingLawyersFromNetwork?: boolean;
    lawyersFromNetwork?: IRecomendedLawyersExtraInfo;
}

const defaultState = (): IRecommendationsReducer => ({
    loadingRecomendedLawyers: false,
    recomendedLawyers: undefined,
    loadingCompaniesResult: false,
    companiesResult: undefined,
    loadingLawyersFromNetwork: false,
    lawyersFromNetwork: undefined
});

export default (state = defaultState(), action): IRecommendationsReducer => {
    switch (action.type) {
        case actionTypes.RECOMMENDED_COMPANIES_REQUESTED: {
            return {
                ...state,
                loadingCompaniesResult: true
            };
        }

        case actionTypes.RECOMMENDED_COMPANIES_LOADED: {
            return {
                ...state,
                loadingCompaniesResult: false,
                companiesResult: action.companiesResult as ICompanySearchResult
            };
        }

        case actionTypes.RECOMMENDED_LAWYERS_REQUESTED: {
            return {
                ...state,
                loadingRecomendedLawyers: true
            };
        }

        case actionTypes.RECOMMENDED_LAWYERS_LOADED: {
            return {
                ...state,
                loadingRecomendedLawyers: false,
                recomendedLawyers: action.recomendedLawyers as IRecomendedLawyersExtraInfo
            };
        }

        case actionTypes.RECOMMENDED_LAWYERS_FROM_NETWORK_REQUESTED: {
            return {
                ...state,
                loadingLawyersFromNetwork: true
            };
        }

        case actionTypes.RECOMMENDED_LAWYERS_FROM_NETWORK_LOADED: {
            return {
                ...state,
                loadingLawyersFromNetwork: false,
                lawyersFromNetwork: action.lawyersFromNetwork as IRecomendedLawyersExtraInfo
            };
        }

        default:
            return state;
    }
};