import React, { useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { ICOIData } from '../../../../entities/ActionData/ICOIData';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';
import { EProfileType, IUserBrief } from '../../../../entities/IGlobal';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { IValue } from '../../../../entities/IPickers';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { UsersPicker } from '../../../Common/Pickers/UsersPicker';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { EAlertType, EAlertTypeSchema } from '../../../../entities/IAlert';
import { MandatoryFieldStar } from '../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { colorStack } from '../../../../styleHelpers/colors';
import { getCompanyById } from '../../../../actions/legalEntitiesActions';
import { getProfileById } from '../../../../actions/profileActions';
import { Alert } from '../../../Common/Alert/Alert';

type GetCompanyById = ReturnType<typeof getCompanyById>;
type GetProfileById = ReturnType<typeof getProfileById>;

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    position: relative;
    background: white;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const InputSection = styled.div<{ row?: boolean; text?: boolean; radio?: boolean }>`
    padding: 20px 28px;
    width: 50%;
    ${props => props.row && css`
        display: flex;
        flex-direction: row;
    `}
    ${props => props.text && css`
        display: inline-flex;
        align-items: center;
    `}
    ${props => props.radio && css`
        label {
            margin-right: 29px;
        }
    `}
`;

const Title = styled.div`
    color: ${colorStack.content};
    margin: 0 0 2rem 0;
    sup {
        color: ${colorStack.red};
    }
`;

export const YourInformation = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { setFieldValue, values, errors } = useFormikContext<ICOIData>();
    const [managerValue, setManagerValue] = useState<{ key: string; text: string; data: IUserBrief; }[]>(undefined);
    const [declarantValue, setDeclarantValue] = useState<{ key: string; text: string; data: IUserBrief; }[]>(undefined);
    const [entityValue, setEntityValue] = useState<IValue[]>(undefined);
    const [inputBlur, setInputBlur] = useState({
        fullName: false,
        jobTitle: false,
        legalEntity: false,
        country: false,
        manager: false
    });

    useEffect(() => {
        setFieldValue('step', 4);
    }, []);

    const fillLegalEntityInformation = useCallback((data: ISIngleLegalEntity) => {
        setFieldValue(`formData.coiInformation.legalEntity`, data ? {
            name: data?.name,
            picture: data?.picture,
            id: data?.id,
            type: data.type
            // tslint:disable-next-line:no-null-keyword
        } : null);
    }, []);

    const onChangeSelectedEntity = useCallback((element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        setEntityValue(element);
        fillLegalEntityInformation(element?.[0]?.data);
    }, []);

    useEffect(() => {
        values.formData.coiInformation?.legalEntity && !entityValue && (
            setEntityValue(values.formData?.coiInformation?.legalEntity?.id ? [{
                key: values.formData?.coiInformation?.legalEntity?.id,
                text: values.formData?.coiInformation?.legalEntity?.name,
                data: values.formData?.coiInformation?.legalEntity
            }] : undefined)
        );
    }, []);

    const fillUserInformation = async (userId: string) => {
        if (userId) {
            const result = await dispatch<GetProfileById>(getProfileById(userId));
            const entityInfo = result.cabinet ? await dispatch<GetCompanyById>(getCompanyById(result.cabinet?.id)) : undefined;
            setFieldValue(`formData.coiInformation`, {
                creator: {
                    firstName: result?.firstName,
                    lastName: result?.lastName,
                    jobTitle: result?.jobTitle || '',
                    picture: result?.picture,
                    cabinet: entityInfo,
                    emailContact: result?.emailContact || '',
                    id: result.id
                },
                jobTitle: result?.jobTitle || '',
                manager: result?.manager || {
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    picture: '',
                    cabinet: '',
                    emailContact: '',
                    id: ''
                },
                legalEntity: {
                    ...entityInfo, type: EProfileType.Company
                }
            });
            setManagerValue(result.manager ? [{
                key: result.manager.id || '',
                text: '',
                data: {
                    firstName: result.manager.firstName || '',
                    lastName: result.manager.lastName || '',
                    jobTitle: result.manager.jobTitle || '',
                    picture: result.manager.picture || '',
                    cabinet: result.manager.cabinet || '',
                    id: result.manager.id || '',
                    type: EProfileType.Personal
                }
            }] : undefined);
            setEntityValue(entityInfo ? [{
                key: entityInfo?.id,
                text: entityInfo?.name,
                data: {...entityInfo, type: EProfileType.LegalEntity}
            }] : undefined);
        } else {
            setFieldValue(`formData.coiInformation`, {
                creator: {
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    picture: '',
                    cabinet: '',
                    emailContact: '',
                    id: ''
                },
                jobTitle: '',
                manager: {
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    picture: '',
                    cabinet: '',
                    emailContact: '',
                    id: ''
                },
                // tslint:disable-next-line:no-null-keyword
                legalEntity: null
            });
            setManagerValue(undefined);
            setEntityValue(undefined);
        }
    };

    useEffect(() => {
        if (!!values.formData.coiInformation.manager?.id) {
            setManagerValue([{
                key: values.formData.coiInformation.manager.id || '',
                text: '',
                data: {
                    firstName: values.formData.coiInformation.manager.firstName || '',
                    lastName: values.formData.coiInformation.manager.lastName || '',
                    jobTitle: values.formData.coiInformation.manager.jobTitle || '',
                    picture: values.formData.coiInformation.manager.picture || '',
                    cabinet: values.formData.coiInformation.manager.cabinet || '',
                    id: values.formData.coiInformation.manager.id || '',
                    type: EProfileType.Personal
                }
            }]);
        }
        setDeclarantValue(values.formData?.coiInformation?.creator?.id ? [{
            key: values.formData?.coiInformation?.creator?.id,
            text: '',
            data: {
                firstName: values.formData?.coiInformation?.creator.firstName,
                lastName: values.formData?.coiInformation?.creator?.lastName,
                jobTitle: values.formData?.coiInformation?.creator?.jobTitle,
                picture: values.formData?.coiInformation?.creator?.picture,
                cabinet: values.formData?.coiInformation?.creator?.cabinet,
                id: values.formData?.coiInformation?.creator?.id,
                type: EProfileType.Personal
            }
        }] : undefined);
    }, []);

    const onChangeManagerSelected = useCallback((element: { key: string; text: string; data: IUserBrief; }[]) => {
        setManagerValue(element);
        setFieldValue(`formData.coiInformation.manager`, {
            firstName: element?.[0]?.data?.firstName || '',
            lastName: element?.[0]?.data?.lastName || '',
            picture: element?.[0]?.data?.picture,
            id: element?.[0]?.data?.id
        });

    }, [values.formData.coiInformation.manager?.firstName]);

    const onChangeDeclarant = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        setDeclarantValue(element);
        fillUserInformation(element?.[0]?.data?.id);
    };

    const handleBlur = useCallback((name: string) => {
        setInputBlur({
            ...inputBlur,
            [name]: true
        });
    }, [inputBlur]);

    return (
        <Wrapper>
            <InnerWrapper>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <FormattedMessage id="coi.popup.yourInfo.fullName" />
                        </LabelWithEllipsis>
                        <UsersPicker
                            value={declarantValue}
                            onSelectElement={onChangeDeclarant}
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis>
                            <FormattedMessage id="coi.popup.yourInfo.jobTitle" />
                        </LabelWithEllipsis>
                        <TextComponent
                            value={values.formData.coiInformation.jobTitle}
                            error={inputBlur.jobTitle && !!errors?.formData?.coiInformation?.jobTitle}
                            onChange={(value: string) => setFieldValue('formData.coiInformation.jobTitle', value)}
                            onBlur={() => handleBlur('jobTitle')}
                        />
                    </InputSection>
                </Row>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <FormattedMessage id="coi.popup.yourInfo.legalEntity" />
                        </LabelWithEllipsis>
                        <LegalEntitiesPicker
                            value={entityValue}
                            onSelectElement={onChangeSelectedEntity}
                            filteredCompanies
                            hideButtons
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <FormattedMessage id="coi.popup.yourInfo.manager" />
                        </LabelWithEllipsis>
                        <UsersPicker
                            value={managerValue}
                            onSelectElement={onChangeManagerSelected}
                        />
                    </InputSection>
                </Row>
            </InnerWrapper>
            <Title>
                <MandatoryFieldStar />
                <FormattedMessage id="coi.popup.requiredInfo" />
            </Title>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.INFO}
            >
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'coi.popup.requiredInfo.text' }) }} />
            </Alert>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.DEFAULT}
            >
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'coi.popup.grayInfoBox.text' }) }} />
            </Alert>
        </Wrapper>
    );
};
