import { ReactNode } from 'react';

import { action } from '../tools/reduxTools';
import { ADD_ALERT, UPDATE_ALERT, REMOVE_ALERT } from './actionTypes/alertTypes';
import { IAlert, EAlertType } from '../entities/IAlert';
import { delay } from '../tools/generalTools';
import { uuidv4 } from '../tools/authTools';

export const addAlert = (content: ReactNode, type: EAlertType) => action(async (dispatch) => {
    const alertId = uuidv4();
    const alert: IAlert = {
        id: alertId,
        content,
        type
    };

    dispatch({ type: ADD_ALERT, alert });
    await delay(10);
    dispatch({ type: UPDATE_ALERT, alert: { visible: true }, alertId });
});

export const hideAndRemoveAlert = (alertId: string | number) => action(async(dispatch) => {
    dispatch({ type: UPDATE_ALERT, alert: { visible: false }, alertId });
    await delay(600);
    dispatch({ type: REMOVE_ALERT, alertId });
});
