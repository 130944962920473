import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { getTimeFromNow } from '../../../../tools/date';

const DateWrapper = styled.span`
    color: ${colorStack.content};
`;

export const DateAgoType: FC<React.PropsWithChildren<unknown>> = props => {
    const intl = useIntl();
    return (
        <DateWrapper>{intl?.formatMessage({ id: 'global.dateAgo' }, { date: getTimeFromNow(props.children as string) })}</DateWrapper>
    );
};