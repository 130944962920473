import { EContentObjectType, ISGStyle, EOfficeXmlType } from './LegalDoc/ISogeStyles';
import { ELcid } from './ILanguage';
import { INormReferential, INormReferentialItem, EReferentialType } from './IAdmin';
import { IFacetsSearchResponse } from './IEntity';
import { ITag } from '../components/Common/TagPicker/TagPicker';
import { IDocumentVersion } from './LegalDoc/INormativeDocumentationVersion';
import { IFullUserProfile } from './IProfile';

export enum ESogeDocumentLevel {
    Book = 'Book',
    Title = 'Title',
    Chapter = 'Chapter',
    Section = 'Section'
}

export enum EArticleType {
    NumberedAutomatically = 10,
    NumberedManually = 11,
    Unnumbered = 19,
    Named = 20,
    NamedWithNumbering = 30,
    NamedWithoutNumbering = 40,
    Hidden = 90,
    Deleted = 100,
    Temporary = 110
}

export interface ITableOfContentsNode {
    id: string;
    level?: ESogeDocumentLevel;
    commonLevelId?: string;
    name: string;
    children: ITableOfContentsNode[];
    hasReferenceText?: boolean;
    childContainsMatch?: boolean;
    isMatch?: boolean;
}

export interface ITableOfContentsNodeWithBook extends ITableOfContentsNode {
    book: ISogeParent;
}

export interface IBaseArticle {
    orderNumber: number;
    type: EArticleType;
    title: string;
    codeNumber: string;
    architextId: string;
    id: string;
}

export interface IStructureLevelWithBaseArticles {
    name: string;
    level: string;
    commonLevelId: string;
    children: IStructureLevelWithBaseArticles[];
    articles: IBaseArticle[];
    orderNumber: number;
    id: string;
}

export interface IStructureLevelRootWithBaseArticles extends IStructureLevelWithBaseArticles {
    normativeDocumentationVersion: IDocumentVersion;
}

export interface IFlattenTableOfContent {
    structureLevels: IFlattenStructureLevel[];
    name: string;
    versionId: string;
    documentVersion: IDocumentVersion;
    id: string;
}

export interface IFlattenStructureLevel {
    name: string;
    level?: string;
    commonLevelId?: string;
    orderNumber: number;
    id: string;
    isArticle?: boolean; // always empty - just for type compability in React Sortable

}

export interface ITableOfContentsVersion {
    name: string;
    versionId: string;
    isShowThisLevel: boolean;
    isLastPublishedVersion: boolean;
    documentVersion: IDocumentVersion;
    id: string;
    commonLevelId?: string;
    lcid: ELcid;
    resultsCount?: string;
}

export interface ITableOfContentsData extends ITableOfContentsVersion {
    structureLevels: ITableOfContentsNode[];
}

export interface ISgVersionLcid {
    [versionId: string]: {
        availableLcid: ELcid[];
    }
}

export interface IReorderElementsPayload {
    orderNumber: number;
    type: EStructureLevelType;
    rootCommonLevelId?: string;
    lcid: ELcid;
}

export interface ITableOfContentsResponse {
    items: ITableOfContentsData[];
    count: number;
    page: number;
    size: number;
}

export interface ISogeArticleLanguage {
    lcid: ELcid;
    code: string;
}

export interface ISogeArticleVersion {
    creationDate: string;
    number: string;
    updateDate: string;
    firstPublicationDateUtc: Date;
    publishDateOfLastModificationUtc: string;
    normativeDocumentationId: string;
    normativeDocumentationInstanceVersionId: string;
    validationDate: string;
    lastReviewDate: string;
    publicationDate: Date;
    isDeleted: boolean;
    deletionDate: string;
    archivingDate: Date;
    documentVersionName: string;
}

export interface ISogeRefSource {
    objectCommonId: string;
    objectCommonLevelId: string;
    objectId: string;
    objectName: string;
    objectNormativeDocumentationInstanceVersionId: string;
    objectType: EReferenceObjectType;
    referenceId: string;
}

export interface ISogeParent {
    fullName: string;
    commonLevelId: string;
}

export interface IContentObject {
    id: string;
    type: EContentObjectType;
    officeXmlType: EOfficeXmlType;
    object: string;
    style: ISGStyle;
    children: IContentObject[];
    references: {
        destinationObjectId: string;
        destinationObjectType: number;
        destinationObjectName: string;
        destinationLink: string;
        wordToLink: string[];
    }[];
}

export interface IFootnote {
    id: string;
    contentObjects: IContentObject[];
}

export enum EArchitextStatus {
    Active = 'Active',
    Moved = 'Moved',
    Archived = 'Archived'
}

export type IFootnotesObject = Record<string, IFootnote>;

export interface IBaseSogeArticle {
    codeNumber: string;
    commonArticleId: string;
    numbering: string;
    title?: string;
}

export interface ISogeArticle extends IBaseSogeArticle {
    architextLastVersion: {
        articleId: string;
        contentObjects: IContentObject[];
        id: string;
        language: ISogeArticleLanguage;
        status: EArchitextStatus;
        thematics: ISgArticleTag[];
        paratexts: ISgArticleDetail[];
        type: string;
        articleVersion: ISogeArticleVersion;
        mentionedIn: ISogeRefSource[];
        refersTo: ISogeRefSource[];
    };
    id: string;
    architextId: string;
    initialDocumentNumber: string;
    parents?: ISogeParent[]; // only in article API, not in structure API
    orderNumber: number;
    attachments: IArticleAttachment[];
    firstPublicationDateUtc: Date;
    publishDateOfLastModificationUtc: Date
    footnotes: IFootnotesObject;
}

export interface IArticleAttachment {
    destinationObjectId: string;
    contentObjectId: string;
    fileId: string;
    iconFileId: string;
    fileName: string;
}

export interface ISogeOtherArticleVersion {
    articleName: string;
    documentVersionName: string;
    versionDate: string;
    id: string;
    versionId: string;
}

export enum EStructureStatus {
    Default = 0,
    Active = 10,
    Moved = 20,
    Archived = 30
}

export interface IBaseStructure {
    articles: IBaseSogeArticle[];
    children?: IBaseStructure[];
    commonLevelId: string;
    id: string;
    level: string;
    name: string;
    numbering: string;
    order?: number;
}

export interface IStructure extends IBaseStructure {
    articles: ISogeArticle[];
    children?: IStructure[];
    nameWithoutNumbering: string;
    orderNumber: number;
    status: EStructureStatus;
    commonIdParents?: string[];
}

export interface IStructureResponse {
    articles: ISogeArticle[];
    children?: IStructure[];
    id: string;
    level: string;
    name: string;
    nameWithoutNumbering: string;
    numbering: string;
    relatedStructureLevelIdInLatestVersion?: string;
    normativeDocumentationVersion?: IDocumentVersion;
    parents: ISogeParent[];
    orderNumber: number;
    commonLevelId: string;
    status: EStructureStatus;
    referenceText: IReferenceText;
    shouldCollapseLevelThreeContent?: boolean;
}

export interface INormativeDocumentation {
    name: string;
    documentVersion: IDocumentVersion;
    id: string;
}

export interface INormativeDocumentationsArchiveResponse {
    items: INormativeDocumentation[];
    size: number;
    page: number;
    count: number;
}

export interface IFacet {
    name: string;
    id: string;
}

export interface IFacets {
    [key: string]: IFacet[];
}

export interface INormativeDocumentationsSearchResponse<T> {
    facets: IFacets;
    items: T[];
    size: number;
    page: number;
    count: number;
}

export interface ISearchArticle {
    id: string;
    commonArticleId: string;
    codeNumber: string;
    structurePath: string;
    structureIdsPath: string;
    architext: ISearchArchitext;
    highlights: string[];
    title: string;
    parents: ISogeParent[];
    footnotes: IFootnotesObject;
}

export interface ISearchArchitext {
    id: string;
    language: {
        lcid: number;
        code: string;
    };
    articleVersion: {
        number: number;
        updatedInNormativeDocumentationInstanceId: string;
        updatedInNormativeDocumentationInstanceName: string;
        normativeDocumentationInstanceVersionId: string;
        updateDate: string;
        creationDate: string;
    };
    contentObjects: IContentObject[];
    thematics: ISgArticleTag[];
    mentionedIn: ISogeRefSource[];
    refersTo: ISogeRefSource[];
    paratexts: {
        name: string;
        type: string;
        value: string;
    }[];
}

export enum EArticlesSearchOrder {
    Relevance = 10,
    Ascending = 30,
    Descending = 40,
    Modification = 20
}

export interface IArticlesSearchRequest {
    defaultNormativeDocumentationId: string;
    query: string;
    filters: SearchFiltersType;
    orderType: number;
}

export const SGDocLanguageKey = 'sgdoclanguage';

export enum EStructureLevelType {
    StructureLevelRoot = 10,
    StructureLevel = 20,
    StructureArticle = 30
}

export enum EReferenceObjectType {
    Article = 10,
    StructureLevel = 20,
    StructureLevelRoot = 21,
    NormativeDocumentationInstance = 30,
    NormativeDocumentation = 40,
    BlobStorage = 50,
    ExternalLink = 60
}

export enum ESogeBullet {
    Square = 61607
}

export enum EBranchType {
    Main = 0,
    Feature = 1
}

export enum ECommitType {
    Normal = 10,
    Validation = 20,
    Publication = 30
}

export enum EVersionFilterStatus {
    None = 0,
    New = 1,
    WorkInProgress = 2,
    Current = 4,
    Archived = 8,
    Mine = 16
}

export enum EBranchFilterTypes {
    None = 0,
    Active = 1,
    Accepted = 2,
    Abandoned = 4,
    Mine = 8,
    WaitingForApproval = 16
}

export enum EBranchStatus {
    Active = 10,
    WaitingForApproval = 15,
    Approved = 20,
    Rejected = 30
}

export enum EApproverStatus {
    Default = 0,
    WaitingForUpdate = 1,
    Approved = 2,
    Rejected = 3
}

export enum ERequestStatus {
    Active = 0,
    Abandoned = 1,
    Completed = 2,
    WaitingForApproval = 3
}

export interface IElementName {
    lcid: number;
    data: string;
}

export interface IBreadcrumbList {
    data: string[];
    lcid: number;
}

export interface IBranchBookGroup {
    documentNames: IElementName[];
    rootCommonId: string;
    elementGrouppedBranches: IBranchGroup[];
}

export interface IBranchGroup {
    documentElementCommonId: string;
    documentElementUserFriendlyNames: IElementName[];
    branches: IBranch[];
    breadcrumbs: IBreadcrumbList [];
}

export type BranchId = string;

export interface IBranch {
    creationDate: Date;
    id: BranchId;
    lastModificationDate: Date;
    lastModifiedBy: ISgUser;
    lcid: ELcid;
    name: string;
    requestCreated: boolean;
    status: EBranchStatus;
    description: string;
    normativeDocumentationVersionId: string;
    isMinorChange: boolean;
}

export interface IBranchConsistencyMatch {
    isLanguageConstencyFullMatch: boolean;
    unmatchedElements: string[];
}

export interface IBranchSearchItem {
    commonLevelId: string;
    createdBy: ISgUser;
    creationDate: Date;
    description: string;
    id: BranchId;
    isMinorChange: boolean;
    lastModificationDate: Date;
    lastModifiedBy: ISgUser;
    lcid: ELcid;
    name: string;
    requestCreated: boolean;
    status: EBranchStatus;
}

export interface IFeatureBranchReponse {
    lcid: ELcid;
    name: string;
    status: EBranchStatus;
    creationDate: Date;
    description: string;
    isMinorChange: boolean;
    branchGroupId: string;
    languageConsistencyGroupId: string;
    id: BranchId;
    normativeDocumentationVersionId?: string;
}

export enum EBranchNotificationType {
    BranchCreateSucceeded = 'BranchCreateSucceeded',
    BranchAbandonSucceeded = 'BranchAbandonSucceeded',
    ApprovalCompleteSucceeded = 'ApprovalCompleteSucceeded',
    ApprovalRejectSucceeded = 'ApprovalRejectSucceeded',
    AmendmentRequestUploadSucceeded = 'AmendmentRequestUploadSucceeded',
    AutomaticallyCreatedBranch = 'AutomaticallyCreatedBranch',
    CommitAmendmentRequestSucceeded = 'CommitAmendmentRequestSucceeded'
}

export interface ISgStakeholder {
    emailContact: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    department: string;
    picture: string;
    cabinet: string;
    id: string;
}

export interface ISgArticleStatus {
    name: string;
    key: string;
    id: string;
}

export interface IRelatedRequest {
    name: string;
    branchIds: string[];
    status: ERequestStatus;
    createdByUser: ISgUser;
    creationDate: string;
    id: string;
}

export interface ISgArticleTag {
    name: string;
    id: string;
}

export interface ISgArticleDetail {
    name: string;
    type: string;
    referentialId: string;
    referentialType: EReferentialType;
    selectedValues: ISgArticleTag[];
    value: string[];
}

export interface IArticleDetails {
    articleTitle: string;
    codeNumber: string;
    normativeDocumentationId: string;
    parentText: string;
    referenceText: string;
    lcid: ELcid;
    stakeholders: {
        contact: ISgStakeholder[];
        validators: ISgStakeholder[];
        reviewers: ISgStakeholder[];
        coordinators: ISgStakeholder[];
    };
    thematics: ISgArticleTag[];
    details: ISgArticleDetail[];
    status: ISgArticleStatus;
    version: {
        number: number;
        creationDate: Date;
        updateDate: Date;
        validationDate: Date;
        lastReviewDate: Date;
        publicationDate: Date;
        deletionDate: Date;
        isDeleted: boolean;
        documentVersionName: string;
        normativeDocumentationId: string;
        normativeDocumentationInstanceVersionId: string;
    };
    id: string;
}

export interface IReferenceText {
    id: string;
    thematics: ISgArticleTag[];
    stakeholders: {
        contact: ISgStakeholder[];
        validators: ISgStakeholder[];
        reviewers: ISgStakeholder[];
        coordinators: ISgStakeholder[];
    };
    details: ISgArticleDetail[];
    version: {
        number: number;
        updateDate: Date;
        normativeDocumentationId: string;
        normativeDocumentationInstanceVersionId: string;
        creationDate: Date;
        validationDate: Date;
        lastReviewDate: Date;
        publicationDate: Date;
        archivingDate: Date;
        isDeleted: boolean;
        deletionDate: Date;
        firstPublicationDateUtc: Date;
        publishDateOfLastModificationUtc: Date;
    };
}

export interface ISimplifiedReferenceText {
    thematics: ISgArticleTag[];
    details: ISgArticleDetail[];
}

export interface ILevelDetails {
    name: string;
    isBranchReferenceTextEnabled: boolean;
    lcid: ELcid;
    referenceText: IReferenceText;
}

export interface ISgUser {
    firstName: string;
    lastName: string;
    jobTitle: string;
    department: string;
    picture: string;
    cabinet: string;
    id: string;
}

export interface ISgDocVersion extends IDocumentVersion {
    createdBy?: ISgUser;
    publishedBy?: ISgUser;
}

export enum EApprovalRequestStatus {
    Active = 0,
    Abandoned = 1,
    Completed = 2,
    WaitingForApproval = 3
}

export enum EApprovalRequestFilterTypes {
    None = 0,
    Active = 1,
    Approved = 2,
    Abandoned = 4,
    Mine = 8
}

export interface ISgApprovalBranch {
    name: string;
    status: EBranchStatus;
    creationDate: Date;
    lastModificationDate: Date;
    lastModifiedBy: ISgUser;
    requestCreated: boolean;
    id: string;
}

export interface ISgApprovalRequest {
    name: string;
    description: string;
    status: EApprovalRequestStatus;
    sourceBranchIds: string[];
    createdByUser: ISgUser;
    creationDate: Date;
    id: string;
    isMine: boolean;
    requestor: ISgUser;
    approvers: IApprover[];
    relatedItems: {
        relatedItemCrossId: string;
        relatedItemType: ERelatedItemType;
    }[];
}

export interface INewAmendment {
    elementId?: string;
    elementCommonId?: string;
    elementName?: string;
    elementType?: EDocumentElementType;
    versionId?: string;
    versionName?: string;
    lcid?: ELcid;
    structureLevelRootCommonLevelId?: string;
    request?: IAmendmentRequest;
    amendmentChecked?: boolean;
    editingById?: boolean;
    existingBranchId?: string;
    existingBranchCommonElementId?: string;
    existingBranchName?: string;
}

export enum EDocumentElementType {
    StructureLevelRoot = 10,
    StructureLevel = 20,
    Article = 30
}

export interface ISogeElement {
    id: string;
    commonId: string;
    fullName: string;
    documentElementType: EDocumentElementType;
    hasReferenceText: boolean;
    normativeDocumentationInstanceId?: string;
    structureLevelRootCommonLevelId: string;
    parents?: ISogeParent[];
}

export enum EAmendmentRequestStatus {
    Active = 10,
    BranchCreated = 20,
    Approved = 30,
    Rejected = 40
}

export enum EAmendmentRequestFilterTypes {
    None = 0,
    Active = 10,
    BranchCreated = 20,
    Approved = 30,
    Rejected = 40,
    Mine = 16
}

export interface IAmendmentSearchPayload {
    isMine?: boolean;
    statuses?: string[];
    createdBy?: string[];
    publicationDates?: string[];
}

export interface IAmendmentFacet {
    Key: string;
    id: string;
    name: string;
}

export interface IAmendmentSearchFacets {
    createdBy: IAmendmentFacet[];
    publicationDates: IAmendmentFacet[];
    statuses: IAmendmentFacet[];
}

export interface IAmendmentRequest {
    id: string;
    normativeDocumentationId: string;
    normativeDocumentationInstanceId: string;
    branchId: string;
    branchName: string;
    status: EAmendmentRequestStatus;
    creationDate: Date;
    createdByUser: string;
    name: string;
    description: string;
    relatedItemCrossId: string;
    relatedItemType: EDocumentElementType;
    lastAmendmentCommitId: string;
    normativeDocumentationVersionId: string;
    lcid: ELcid;
    structureLevelRootCommonLevelId: string;
    documents: {
        id: string;
        name: string;
        creationDate: Date;
        createdByUser: string;
    }[];
}

export interface IAmendmentRequestContents extends IAmendmentRequest {
    contents: IStructureResponse;
}

export interface IBranchCompare {
    source: IStructureResponse;
    target: IStructureResponse;
    id: string
}

export interface IFeatureBranchCompare {
    originalBranch: IFeatureBranchReponse;
    otherComparableBranches: IFeatureBranchReponse[];
}

export interface IValidationReport {
    validationErrors: ValidationError[];
}

export type ReportSummary = Record<keyof typeof EValidationErrorType, string[]>;

export interface OoxmlError {
    errorType: string;
    description: string;
    path: string;
}

export enum EValidationErrorType {
    ArticleAdded = 'ArticleAdded',
    ArticleDeleted = 'ArticleDeleted',
    WrongNumberingInArticle = 'WrongNumberingInArticle',
    WrongNumberingInLevel = 'WrongNumberingInLevel',
    LevelDeleted = 'LevelDeleted',
    LevelAdded = 'LevelAdded',
    WrongNameInLevel = 'WrongNameInLevel',
    ArticleDuplicated = 'ArticleDuplicated',
    ArticleModified = 'ArticleModified',
    LevelDuplicated = 'LevelDuplicated',
    StyleNew = 'StyleNew',
    StyleProhibited = 'StyleProhibited',
    MissingReferenceDestination = 'MissingReferenceDestination',
    ArticleLacksNumbering = 'ArticleLacksNumbering',
    ArticlePlaceholderModified = 'ArticlePlaceholderModified',
    LevelPlaceholderModified  = 'LevelPlaceholderModified',
    SourceAndTargetLevelsAreTheSame = 'SourceAndTargetLevelsAreTheSame',
    NoMoreLevelsForNesting = 'NoMoreLevelsForNesting',
    LevelNestingExceeded = 'LevelNestingExceeded'

}

export enum EValidationSeverity {
    ForInformation = 10,
    ForAction = 20
}

export interface ValidationError {
    description: string;
    errorType: EValidationErrorType;
    elementId: string;
    elementUserFriendlyName: string;
    severity: EValidationSeverity;
}

export type SearchDocumentElementsSearch = IFacetsSearchResponse<SearchDocumentElementsSearchItem, SearchDocumentElementsSearchFacets>;

export type BranchSearch = IFacetsSearchResponse<IBranchSearchItem, SearchDocumentElementsSearchFacets>;

export interface SearchDocumentElementsSearchItem {
    annualReviewDate: string;
    creationDate: Date;
    elementId: string;
    expectedUpdateDate: Date;
    fullName: string;
    id: string;
    language: {
        lcid: number;
        code: string;
    };
    normativeDocumentationId: string;
    normativeDocumentationInstanceId: string;
    paratexts: {
        name: string;
        type: string;
        value: string[]; // depricated
        selectedValue: ISgArticleTag[];
    }[];
    plan: ISgArticleTag;
    publicationDate: Date;
    status: EObjectStatus;
    thematics: ISgArticleTag[];
    type: ERelatedItemType;
    updateDate: Date;
    validityEndDate: Date;
    validityStartDate: Date;
    versionId: string;
    topParentNameManualIdentifying: string;
}

export interface SearchDocumentElementsSearchFacets {
    emittingBuSu: {
        name: string;
        id: string;
    }[];
    plan: {
        name: string;
        id: string;
    }[];
    signatories: {
        name: string;
        id: string;
    }[];
    signatoryNames: {
        name: string;
        id: string;
    }[];
    status: {
        name: string;
        id: string;
    }[];
    thematics: {
        name: string;
        id: string;
    }[];
    type: {
        name: string;
        id: string;
    }[];

}

export interface DataGridSettings {
    columnsSettings: {
        isVisible: boolean;
        headerText: string;
        field: string;
    }[];
    id: string;
    normativeDocumentationId: string;
    userId: string;
}

export const normElementsNames: IFacetsNames = {
    emittingBuSu: { id: 'search.filter.emittingBuSu' },
    plan: { id: 'search.filter.plan' },
    signatories: { id: 'search.filter.signatories' },
    signatoryNames: { id: 'search.filter.signatoryNames' },
    thematics: { id: 'search.filter.thematics' },
    type: { id: 'search.filter.type' },
    creationDate: { id: 'search.filter.creationDate' },
    updateDate: { id: 'search.filter.updateDate' },
    publicationDate: { id: 'search.filter.publicationDate' },
    validityStartDate: { id: 'search.filter.validityStartDate' },
    validityEndDate: { id: 'search.filter.validityEndDate' },
    expectedUpdateDate: { id: 'search.filter.expectedUpdateDate' }
};

export const amendmentElementsNames: IFacetsNames = {
    // TODO: waiting for API
};

export interface IFacetsNames {
    [key: string]: {
        id: string;
    }
}

export enum ERelatedItemType {
    StructureLevelRoot = 10,
    StructureLevel = 20,
    Article = 30,
    StyleCollection = 40,
    ReferenceText = 50
}

export enum EObjectStatus {
    Active = 10,
    Moved = 20,
    Archived = 30
}

export interface IFullBranch {
    id: BranchId;
    normativeDocumentationId: string;
    normativeDocumentationInstanceId: string;
    normativeDocumentationVersionId: string;
    description: string;
    createdByUser: string;
    type: EBranchType;
    lastCommitId: string;
    lastCommitType: ECommitType;
    relatedItemCrossId: string;
    relatedItemType: ERelatedItemType;
    pullRequestIds: string[];
    textStatus: boolean;
    referenceTextId: string;
    lcid: ELcid;
    name: string;
    status: EBranchStatus;
    creationDate: Date;
    lastModificationDate: Date;
    lastModifiedBy: ISgUser;
    requestCreated: boolean;
    amendmentRequestId: string;
    isMinorChange: boolean;
    bucketHash: string;
    languageConsistencyGroupId: string;
    branchGroupId: string;
}

export interface IBranchWithContents extends IFullBranch {
    contents: IStructureResponse;
}

export interface IListAmendment {
    id: string;
    normativeDocumentationId: string;
    normativeDocumentationInstanceId: string;
    normativeDocumentationVersionId: string;
    branchId: string;
    name: string;
    description: string;
    status: EAmendmentRequestStatus;
    creationDate: Date;
    createdByUser: ISgUser;
    relatedItemCommonId: string;
    structureLevelRootCommonLevelId: string;
    structureLevelRootName: string;
}

export interface IReferentialState {
    loaded: boolean;
    identitiesIds: string[];
    identitiesById: {
        [id: string]: INormReferential & {
            itemsIds: string[];
            itemById: {
                [id: string]: INormReferentialItem;
            }
        }
    };
    allItemsById: {
        [id: string]: INormReferentialItem;
    };
    identitiesByType: {
        [key in EReferentialType]?: INormReferential[];
    }
}

export interface IDetailsForm {
    thematics: ISgArticleTag[];
    details: ISgArticleDetail[];
    stakeholders: {
        contact: ISgStakeholder[];
        validators: ISgStakeholder[];
        reviewers: ISgStakeholder[];
    };
}

interface IVersionBranch extends IBranch {
    structureLevel: IStructure;
}

export interface IVersionOverviewResponse {
    normTitle: string;
    branchesSummary: IVersionBranch[];
}

export interface StatusedBranches {
    activeBranches: IBranch[];
    approvedBranches: IBranch[];
    abandonedBranches: IBranch[];
}

export interface IManagerListItem {
    id: string;
    isArticle: boolean;
    commonLevelId?: string;
    commonArticleId?: string;
    name?: string; // structure name
    nameWithoutNumbering?: string; // structure name without numbering
    codeNumber?: string; // article number
    title?: string; // article title
    isDeleted: boolean;
    isMoved?: boolean;
    orderNumber: number;
    numbering?: string;
    levelStyle?: string;
    commonIdParents?: string[];
    type?: EStructureLevelType;
}

export interface ICreateStructure {
    level?: string;
    name: string;
    numbering: number | string;
    commonLevelId?: string;
    previousElementCommonId?: string;
}

export interface IRenameArticle {
    articleId: string;
    commonArticleId: string;
    newName: string;
    ignoreArticleNumberDupplicatedError?: boolean;
}

export interface IArticleAdd {
    articleType?: EArticleType;
    title?: string;
    codeNumber?: string;
    coordinates?: string[];
    previousElementCommonId?: string;
}

export interface IRenameStructure {
    name: string;
    lcid: ELcid;
    id: string;
}

export interface IValidator {
    emailContact: string;
    isMandatory: boolean;
    firstName: string;
    lastName: string;
    jobTitle: string;
    department: string;
    picture: string;
    cabinet: string;
    id: string;
}

export interface IApprover extends IValidator {
    status?: EApproverStatus;
    attachments?: IApprovalAttachment[];
}

export interface IIValidatorPayload {
    isMandatory: boolean;
    id?: string;
    emailAddress?: string;
}

export interface ICreateApproval {
    name: string;
    description: string;
    branchIds: string[];
    approvers: IIValidatorPayload[];
}

export interface IApprovalRequest {
    name: string;
    description: string;
    sourceBranchIds: string[];
    status: ERequestStatus;
    creationDate: string;
    createdByUser: ISgUser;
    approvers: IApprover[];
    id: string;
    isMine: boolean;
}

export interface IApprovalAttachment {
    id: string;
    fileName: string;
}

export interface IOverviewStatistic {
    total: number;
    created: number;
    modified: number;
    archived: number;
}

export interface IOverviewStatisticsList {
    'ARTICLE': IOverviewStatistic;
    'LIVRE': IOverviewStatistic;
    'CHAPTIRE': IOverviewStatistic;
    'PARAGRAPHE': IOverviewStatistic;
    'SECTION': IOverviewStatistic;
    'SOUS-PARAGRAPHE': IOverviewStatistic;
    'SOUS-SECTION': IOverviewStatistic;
    'TITRE': IOverviewStatistic;
}

export interface IStructureLevelConfiguration {
    allowedChildStyles: {
        [key: string]: number;
    };
    allowedNumberingsForAllowedStyles: {
        [key: string]: 'upperRoman' | 'upperLetter' | 'lowerLetter' | 'decimal' | 'regex';
    };
}

export interface ICompareVersion {
    versionId: string;
    language: ELcid;
    versionName: string;
    featureBranchId?: string;
}

export interface ICreateBookResponse {
    structureLevelRootCommonId: string;
    structureLevelRootId: string;
}

export interface IUnmatchedBooksResponse {
    structureLevelRootCommonLevelId: string;
    bookName: string;
    lcid: string;
}

export interface IBook {
    commonLevelId: string;
    preamble: string;
    title: string;
    imageFileId: string;
    colorBlockHex: string;
    structureLevelRootId: string;
    numbering: string;
    orderNumber?: number;
    staticPdfExists?: boolean;
    allVersions?: IOtherVersions[];
    lastUpdateDate?: string;
}

export interface IBooksResponse {
    books: IBook[];
    documentVersion: IDocumentVersion;
    id: string;
    versionId: string;
    name: string;
}

export interface IOtherVersions {
    commonLevelId: string;
    id: string;
    lcid: ELcid;
    normativeDocumentationInstanceId: string;
    title: string;
}

export interface IBookWithOtherVersions extends IBook {
    allVersions: IOtherVersions[];
}

export interface IBooksWithOtherVersionsResponse {
    booksWithOtherVersions: IBookWithOtherVersions[];
    documentVersion: IDocumentVersion;
    id: string;
    versionId: string;
    name: string;
}

export interface IBookImageResponse {
    imageUrl: string;
}

export interface IIReferenceTextWithLevel extends IReferenceText {
    commonLevelId: string;
    levelTitle: string;
    preamble: string;
    parents: ISogeParent[];
}

export interface IReferenceTextWithLevelAndBook extends IIReferenceTextWithLevel {
    bookTitle: string;
    rootCommonLevelId: string;
}

export interface IBookTextsResponse {
    commonLevelId: string;
    referenceTextsOrdered: IIReferenceTextWithLevel[];
    title: string;
}

export interface ISearchReferenceText extends IReferenceText {
    commonLevelId: string;
    bookTitle: string;
    relatedItemCrossId: string;
    relatedLevelName: string;
    rootCommonLevelId: string;
    preamble: string;
    parents: ISogeParent[];
}

export type SearchFiltersType = {
    [key: string]: string[]
};

export enum EUserOrigin {
    Internet = 'Internet',
    LAN = 'LAN'
}

export enum EExportType {
    Pdf = 0,
    Docx = 1
}

export enum ENormConfigurationType {
    Database = 1,
    PdfOnly = 2,
    Hybrid = 3
}

export interface IFlattenVersionGridDataSource {
    fullName: string;
    status: string,
    type: string;
    'language.code': string;
    'Publication Date': string;
    creationDate: string;
    updateDate: string;
    publishDateOfLastModificationUtc: string;
    validityStartDate: string;
    validityEndDate: string;
    expectedUpdateDate: string;
    annualReviewDate: string;
    name: string;
    'Thematic': string;
    'Sub-thematic': string;
    'Individual Thematic': string;
    'Macro-Process': string;
    'Applicable Regulations': string;
    'Origin Directive Title': string;
    'Issuing BU_SU': string;
    'Issuing Direction(s)': string;
    'Scope of application (BU/SU)': string;
    'BU_SU Signatory(ies)': string;
    'Owning Division': string;
}

export interface INewBranchForm {
    title: string;
    lcid: ELcid;
    element?: string;
    description?: string;
    relatedItemType?: EDocumentElementType;
    structureLevelRootCommonLevelId?: string;
    selectedElement?: ITag[];
}

export interface IBaseMoveElementPayload {
    sourceParentCommonId: string;
    targetParentCommonId: string;
    lcid: ELcid;
    type: EStructureLevelType;
    previousElementCommonId?: string;
}

export interface IMoveElementPayload extends IBaseMoveElementPayload {
    codeNumber: string;
}

export interface IMoveMultipleElementsPayload extends IBaseMoveElementPayload {
    itemsToMoveCommonIds: string[];
}

interface IBaseCreateBranch {
    relatedItemType: EDocumentElementType;
    structureLevelRootCommonLevelId: string;
    relatedItemCrossId: string;
}

export interface IMoveBetweenBooksPayload {
    lcid: ELcid;
    codeNumber?: string;
    type: EStructureLevelType;
    previousElementCommonId: string;
    sourceParentData: IBaseCreateBranch;
    targetParentData: IBaseCreateBranch;
}

export interface INormsConfiguration {
    isAutoNumberingOn: boolean;
    isLanguageConsistencyOn: boolean;
    isContainersOn: boolean;
    isExportPdfInConsultationOn: boolean;
    isExportWordInConsultationOn: boolean;
    doesLeftTocShowAllBooks: boolean;
    isSearchEnabled: boolean;
    isNormManagementEnabled: boolean;
    isUiLanguageSynchronizedWithDocumentLanguage: boolean;
    formats: {
        normVersionLanguageConfig: {
            [key: string]: number[];
        };
    }
}

export interface INumberingSuggestion {
    numbering: string;
}

export interface IMoveModalState {
    sourceItem: IManagerListItem[];
    destinationLevel: IStructure;
    precisePositionSet: boolean;
    previousElementCommonId?: string;
    isLoading?: boolean;
}

export interface IMoveLevelSummary {
    before: IBaseStructure;
    after: IBaseStructure;
}

export type IExtendedPersonaProps = IFullUserProfile & {
    firstName: string;
    lastName: string;
    emailAddress?: string;
};

export enum EDocumentOperationType {
    ArticleCreate = 0,
    ArticleDelete = 1,
    ArticleMove = 2,
    ArticleReorder = 3,
    StructureLevelCreate = 4,
    StructureLevelDelete = 5,
    StructureLevelMove = 6,
    StructureLevelReorder = 7,
    ElementMove = 8,
    ElementReorder = 9,
    BranchReactivate = 10,
    StructureLevelRename = 11,
    ArticleRename = 12,
    StructureLevelDetailsUpdate  = 13,
    ElementMoveBetweenBooks = 14,
    BranchCreate = 15,
    MoveMultipleElements = 16,
    BranchAbandon = 17
}

export enum EElementOperationType {
    Creation = 10,
    Archiving = 20,
    Modification = 30,
    PermanentlyDeleted = 40
}

export enum ELogSeverityType {
    Info,
    Warning,
    Error
}

export interface IAuditTrail {
    branchId: string;
    branchName: string;
    dateCreatedUtc: Date;
    documentOperation: EDocumentOperationType;
    elementCommonId: string;
    elementId: string;
    elementName: string;
    elementOperation: EElementOperationType;
    relatedCommitId: string;
    severity: ELogSeverityType;
    userFirstName: string;
    userFriendlyMessage: string;
    userFullName: string;
    userId: string;
    userLastName: string;

}

export interface IAuditTrailCommit {
    commitId: string;
    commitName: string;
    auditTrailSortedByDateDesc: IAuditTrail[];
}

export interface IAuditTrailResponse {
    branchId: string;
    auditTrailsInOrderOfCommitsDesc: IAuditTrailCommit[];
}
