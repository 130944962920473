import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers/';
import * as http from './tools/http';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const httpProvider = {};

const store = createStore(reducers, composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(httpProvider))
));

Object.keys(http).forEach(key => {
    httpProvider[key] = http[key](store);
});

if (module.hot) {
    module.hot.accept('./reducers/', () => {
        store.replaceReducer(require('./reducers/')['default']); // tslint:disable-line:no-string-literal
    });
}

export default store;
