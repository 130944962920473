import * as actionTypes from '../actions/actionTypes/organizationsTypes';
import { IOrganizationMember, IOrganizationLink } from '../entities/IOrganization';
import { IFullUserProfile } from '../entities/IProfile';
import { OrganizationPermissions } from '../entities/IPermissions';

export interface IOrganizationReducer {
    currentOrganizationUrlName: string;
    lastOrgsMembers: IOrganizationMember[];
    lastOrgsMembersLoaded: boolean;
    orgsSearchCount: number;
    orgsSearchFacets: any;
    orgsSearchValue: IFullUserProfile[];
    orgsSearchLoaded: boolean;
    usefulLinks: IOrganizationLink[];
    usefulLinksLoaded: boolean;
    permissionsLoaded: boolean;
    organizationPermissions: {
        [key: string]: {
            [key in OrganizationPermissions]?: boolean;
        }
    }
}

const defaultState = (): IOrganizationReducer => ({
    currentOrganizationUrlName: '',
    lastOrgsMembers: [],
    lastOrgsMembersLoaded: false,
    orgsSearchCount: 0,
    orgsSearchFacets: undefined,
    orgsSearchValue: [],
    orgsSearchLoaded: false,
    usefulLinks: [],
    usefulLinksLoaded: false,
    permissionsLoaded: false,
    organizationPermissions: undefined
});

export default (state = defaultState(), action): IOrganizationReducer => {
    switch (action.type) {

        case actionTypes.GET_ORGS_SEARCH: {
            return {
                ...state,
                orgsSearchCount: action.orgsSearch.count,
                orgsSearchFacets: action.orgsSearch.facets,
                orgsSearchValue: action.orgsSearch.items,
                orgsSearchLoaded: true
            };
        }

        case actionTypes.GET_USEFUL_LINKS: {
            return {
                ...state,
                usefulLinks: action.usefulLinks,
                usefulLinksLoaded: true
            };
        }

        case actionTypes.CLEAR_ORGS_LAST_MEMBERS: {
            return {
                ...state,
                lastOrgsMembers: [],
                lastOrgsMembersLoaded: false
            };
        }

        case actionTypes.CLEAR_SEARCH_RESULT: {
            return {
                ...state,
                orgsSearchCount: 0,
                orgsSearchFacets: [],
                orgsSearchValue: [],
                orgsSearchLoaded: false
            };
        }

        case actionTypes.SET_ORGANIZATIONS_NAME: {
            return {
                ...state,
                currentOrganizationUrlName: action.name
            };
        }

        case actionTypes.GET_LAST_ORGS_MEMBERS: {
            return {
                ...state,
                lastOrgsMembers: action.members,
                lastOrgsMembersLoaded: true
            };
        }

        case actionTypes.SET_ORGANIZATION_PERMISSIONS: {
            const payload: actionTypes.IOrganizationsTypes['SET_ORGANIZATION_PERMISSIONS'] = action;
            return {
                ...state,
                permissionsLoaded: true,
                organizationPermissions: payload.list.reduce((organizations, item) => ({
                    ...organizations,
                    [item.id]: item.permissions.reduce((permissions, permission) => ({
                        ...permissions,
                        [permission]: true
                    }), {})
                }), {})
            };
        }

        case actionTypes.UPDATE_ORGANIZATION_PERMISSIONS: {
            const payload: actionTypes.IOrganizationsTypes['UPDATE_ORGANIZATION_PERMISSIONS'] = action;
            return {
                ...state,
                permissionsLoaded: true,
                organizationPermissions: {
                    ...state.organizationPermissions,
                    [payload.organizationId]: payload.permissions.reduce((permissions, permission) => ({
                        ...permissions,
                        [permission]: true
                    }), {})
                }
            };
        }

        default: {
            return state;
        }
    }
};
