import { saveAs } from 'file-saver';

import { Http } from '../tools/http';
import { action } from '../tools/reduxTools';
import { IDates, IOverviewDates } from '../entities/IDates';
import { ELcid } from '../entities/ILanguage';

export const getOverviewDates = (clusterId: string) => action<Promise<IOverviewDates[]>>((dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiGet(`/clusters/${clusterId}/dates/overview?lcid=${language.userLanguage || ELcid.En}`);
});

export const addNewKeyDate = (clusterId: string, body: IDates, stakeFamilyKey: string, stakeListKey: string) => action<Promise<IDates>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/dates?stakeFamilyKey=${stakeFamilyKey}&stakeListKey=${stakeListKey}`, body);
});

export const updateKeyDate = (clusterId: string, dateId: string, body: IDates) => action<Promise<IDates>>((dispatch, getState, http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/dates/${dateId}`, body);
});
export const removeDate = (clusterId: string, dateId: string) => action<Promise<IDates>>((dispatch, getState, http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/dates/${dateId}`);
});

export const exportDatesAsICS = (clusterId: string, dateId: string, businessIdValue: number, clusterName: string, clusterUrl: string) => ((dispatch, getState, http: Http) => {
    const { context, config, language } = getState();
    const body = {
        businessIdValue: businessIdValue,
        clusterName: clusterName,
        clusterUrl: clusterUrl
    };
    return fetch(`${config.clustersApiUrl}/clusters/${clusterId}/dates/${dateId}/export-ics?lcid=${language.userLanguage || ELcid.En}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': `Bearer ${context.token}`,
            'Ocp-Apim-Subscription-Key': config.subKey,
            'Content-Type': 'application/json'
        }
    }).then(res => {
        return res.blob();
    }).then(blob => {
        blob && saveAs(blob, 'dates.ics');
    });
}) as any;