import { EStakeContainerType, IClusterStakeProperty } from '../entities/IClusterStake';

export const isStanderdView = (type: number) => {
    return (type === EStakeContainerType.Standard
        || type === EStakeContainerType.Shareholder
        || type === EStakeContainerType.Participations
        || type === EStakeContainerType.GeneralCommitment
        || type === EStakeContainerType.ShareholderMeetingInformation
        || type === EStakeContainerType.Notes
        || type === EStakeContainerType.Contact
        || type === EStakeContainerType.Economic
        || type === EStakeContainerType.RelatedEntities
        || type === EStakeContainerType.GiftDescription
        || type === EStakeContainerType.GiftPartiesRelationship
        || type === EStakeContainerType.AuthorizedActivities
        || type === EStakeContainerType.MainRent
        || type === EStakeContainerType.AdditionnalRent
        || type === EStakeContainerType.BuildingFeesCommitment
        || type === EStakeContainerType.QuotationPrice
        || type === EStakeContainerType.LeaseTaxes
        || type === EStakeContainerType.ConstructionWorkRepaire
        || type === EStakeContainerType.RentCancellation
        || type === EStakeContainerType.QuotationPresentation
        || type === EStakeContainerType.QuotationRequestBody
        || type === EStakeContainerType.LeaseAssignment
        || type === EStakeContainerType.SecurityDeposit
        || type === EStakeContainerType.RentIndexation
        || type === EStakeContainerType.DamagePovision
        || type === EStakeContainerType.ContractDuration
        || type === EStakeContainerType.BuildingFees
        || type === EStakeContainerType.Designation
        || type === EStakeContainerType.LeaseContractTypology
        || type === EStakeContainerType.RenewalProvision
        || type === EStakeContainerType.SubLeasing
        || type === EStakeContainerType. ProjectDescription
        || type === EStakeContainerType.ContractTypologyMerieux
        || type === EStakeContainerType.FinancingPlan
        || type === EStakeContainerType.OwnershipOfRights
        || type === EStakeContainerType.TransferOfRights
        || type === EStakeContainerType.InvitationDescription
        || type === EStakeContainerType.RentInsurance);
};

export const isPartyView = (type: number) => {
    return (type === EStakeContainerType.LegalEntityParty
        || type === EStakeContainerType.UserParty
        || type === EStakeContainerType.GiftCreator
        || type === EStakeContainerType.GiftSecondParty
        || type === EStakeContainerType.GiftParty
        || type === EStakeContainerType.ContractingParties
        || type === EStakeContainerType.InvolvedParty
        || type === EStakeContainerType.InvitationSecondParty
        || type === EStakeContainerType.LeaseParties);
};

export const prepareProperties = (properties: IClusterStakeProperty[]) => {
    const { singles, couples } = (properties || []).reduce(({ byIds, indexesByIds, singles, couples }, property) => {
        byIds.set(property.id, property);

        if (!!property.configuration?.parentPropertyId) {
            couples.push([byIds.get(property.configuration.parentPropertyId), property]);
            singles.splice(indexesByIds.get(property.configuration?.parentPropertyId), 1);
        } else {
            indexesByIds.set(property.id, singles.push(property) - 1);
        }
        return { byIds, indexesByIds, singles, couples };
    }, { byIds: new Map(), indexesByIds: new Map(), singles: [], couples: [] });

    return [couples, singles];
};
