import { get } from 'lodash';

import * as actionTypes from '../actions/actionTypes/proposalTypes';
import { IBusinessSectorEntity, IJuridiction, IFullUserProfile } from '../entities/IProfile';
import { IProposalExtra } from '../entities/RFP/ProposalExtra';
import { IExtraRequirement, IRfpAttachment, IRfpAttachmentContainer, IMilestone } from '../entities/RFP/IRfpExtra';
import { IRFPSummary } from '../entities/RFP/IRFPSummaty';
import { ICountry } from '../entities/ICountry';
import { IExpertiseDto } from '../entities/Expertise/IExpertiseDto';
import { ICompetence } from '../entities/ICompetence';
import { ILocation } from './rfpReducer';

export enum EProposalStatus {
    Draft = 'Draft',
    Submitted = 'Submitted',
    Withdrawn = 'Withdrawn',
    Rejected = 'Rejected',
    Awarded = 'Awarded'
}

export interface IProposalReducer {
    description?: string;
    conflictChecked?: boolean;
    confidentialityStatment?: string;
    missionScope?: string;
    workSchedule?: string;
    feeArrangements?: string;
    currency?: string;
    minValue?: number;
    maxValue?: number;
    additionalComments?: string;
    termsAndConditions?: string;
    tAndSFile?: ArrayBuffer | string;
    showRecommendations?: boolean;
    selectedPartners?: IFullUserProfile[];
    messageToRequestor?: string;
    proposalStatus?: EProposalStatus;
    requestForProposalId?: string;
    id?: string;
    savingPropsalRequested?: boolean;
    loadProposalRequested?: boolean;
    proposalLoaded?: boolean;
    requestForProposalTitle?: string;
    additionalMarketingInformation?: string;
    extras?: IProposalExtra[];
    additionalSections?: IExtraRequirement[];
    rfpSummary?: IRFPSummary;
    loadingRFPSummary?: boolean;
    rfpSummaryLoaded?: boolean;
    owner?: IFullUserProfile;
    selectedRequestor?: IFullUserProfile;
    rfpTitle?: string;
    rfpDescription?: string;
    invoicingConditions?: string;
    expertises?: IExpertiseDto[];
    location?: ILocation[];
    businessSector?: IJuridiction[];
    deliverables?: IMilestone[];
    showConflictPopUp?: boolean;
    attachments?: IRfpAttachment[];
    proposalIsUploadingFile?: boolean;
    proposalUploadingFileName?: string;
    proposalUploadingFileProgress?: number;
    proposalCurrentlyDownloadingFiles?: {
        [fileId: string]: boolean;
    };
    proposalUploadingFileContainer?: IRfpAttachmentContainer;
    companyId?: string;
    onBehalfOfMyself?: boolean;
}

const defaultState = (): IProposalReducer => ({
    description: '',
    conflictChecked: true,
    confidentialityStatment: '',
    missionScope: '',
    workSchedule: '',
    feeArrangements: '',
    currency: '$',
    minValue: 0,
    maxValue: 0,
    additionalComments: '',
    termsAndConditions: '',
    tAndSFile: undefined,
    showRecommendations: false,
    selectedPartners: [],
    messageToRequestor: undefined,
    proposalStatus: EProposalStatus.Draft,
    requestForProposalId: undefined,
    requestForProposalTitle: '',
    id: undefined,
    savingPropsalRequested: false,
    loadProposalRequested: false,
    additionalMarketingInformation: '',
    extras: [],
    additionalSections: [],
    rfpSummary: undefined,
    loadingRFPSummary: false,
    owner: undefined,
    selectedRequestor: undefined,
    rfpTitle: '',
    rfpDescription: '',
    invoicingConditions: '',
    location: [],
    expertises: [],
    businessSector: [],
    deliverables: [],
    showConflictPopUp: false,
    proposalCurrentlyDownloadingFiles: {},
    companyId: undefined,
    onBehalfOfMyself: false
});

const loadExtras = (action: any, state: IProposalReducer) => {
    return (get(action, 'expectedItems', []) || []).map(extra => ({
        ...extra,
        comment: get((state.extras || []).find(requir => requir.title === extra.title), 'comment', '')
    } as IProposalExtra));
};

const getAllExpertises = (action: any) => {
    const expertises = (get(action, 'expertises') || [{ id: undefined, competencesList: [] } as IExpertiseDto]).map(expertise => ({
        name: expertise.name,
        id: expertise.id,
        competencesList: (expertise.competencesList || []).map(competence => ({
            name: competence.name,
            id: competence.id
        }))
    }));
    return expertises.length > 0 ? expertises : [{ id: undefined, competencesList: [] } as IExpertiseDto];
};

const getLocations = (action: any) => {
    const barAdmissionsList = (get(action, 'barAdmissions') || []).map(bar => ({
        Name: bar.name,
        id: bar.id
    } as IJuridiction));
    return (get(action, 'coutries') || [{ id: undefined, barAdmissionsList: [] } as ILocation]).map(country => ({
        Name: country.name,
        id: country.id,
        barAdmissionsList: barAdmissionsList
    } as ILocation));
};

const loadIEntityList = (action: any, name: string) => {
    return (get(action, name) || []).map(entity => ({
        Name: entity.name,
        id: entity.id
    }));
};

export const loadDate = (action: any, field: string) => {
    const rawDate = get(action, field, undefined);

    return rawDate !== '0001-01-01T00:00:00' ? rawDate : undefined;
};

export default (state = defaultState(), action): IProposalReducer => {
    switch (action.type) {
        case actionTypes.PROPOSAL_STATE_UPDATE: {
            return {
                ...state,
                ...action.state as IProposalReducer
            };
        }

        case actionTypes.SAVING_PROPOSAL_COMPLETED: {
            return {
                ...state,
                id: get(action, 'proposalId'),
                savingPropsalRequested: false,
                proposalLoaded: true
            };
        }

        case actionTypes.SAVING_PROPOSAL_REQUESTED: {
            return {
                ...state,
                savingPropsalRequested: true
            };
        }

        case actionTypes.LOAD_PROPOSAL_COMPLETED: {
            return {
                ...state,
                attachments: get(action, 'attachments', []) || [],
                loadProposalRequested: false,
                proposalLoaded: true,
                id: get(action, 'id'),
                proposalStatus: get(action, 'proposalStatus'),
                selectedPartners: (get(action, 'teamMembers', []) || []).map(member => ({
                    id: member.usedId,
                    lastName: member.lastName,
                    firstName: member.firstName,
                    cabinet: member.cabinet,
                    emailContact: member.userEmail,
                    picture: member.picture,
                    expertises: (member.expertisesList || []).map(exp => ({ id: exp.id, Name: exp.name}))
                } as IFullUserProfile)),
                description: get(action, 'description', ''),
                conflictChecked: get(action, 'conflictCheckStatement', true),
                confidentialityStatment: get(action, 'confidentiality', ''),
                missionScope: get(action, 'scope', ''),
                feeArrangements: get(action, 'feeArangements', ''),
                minValue: get(action, 'minimalEstimatedCost', 0) || 0,
                maxValue: get(action, 'maximalEstimatedCost', 0) || 0,
                currency: get(action, 'currency', '$') || '$',
                additionalComments: get(action, 'additionalCostComments', ''),
                workSchedule: get(action, 'timeline', ''),
                additionalMarketingInformation: get(action, 'additionalMarketingInformation', ''),
                extras: get(action, 'extras', []),
                additionalSections: get(action, 'additionalSections', []),
                requestForProposalId: get(action, 'requestForProposal.id'),
                owner: {
                    firstName: get(action, 'owner.firstName', ''),
                    lastName: get(action, 'owner.lastName', ''),
                    picture: get(action, 'owner.picture', ''),
                    id: get(action, 'owner.usedId', ''),
                    emailContact: get(action, 'owner.userEmail', '')
                } as IFullUserProfile,
                invoicingConditions: action.invoicingConditions || '',
                expertises: getAllExpertises(action),
                location: getLocations(action),
                businessSector: loadIEntityList(action, 'businessSectorDto'),
                deliverables: (action.deliverables || []).map(deliverable => deliverable && ({
                    message: deliverable.message,
                    expectedDate: new Date(loadDate(deliverable, 'expectedDate'))
                } as IMilestone)),
                companyId: get(action, 'companyId', undefined),
                onBehalfOfMyself: get(action, 'onBehalfOfMyself', true)
            };
        }

        case actionTypes.LOAD_PROPOSAL_REQUESTED: {
            return {
                ...state,
                loadProposalRequested: true
            };
        }

        case actionTypes.RESET_PROPOSAL_STATE: {
            return defaultState();
        }

        case actionTypes.RFP_SUMMARY_LOADING_REQUESTED: {
            return {
                ...state,
                loadingRFPSummary: true
            };
        }

        case actionTypes.RFP_SUMMARY_LOADING_COMPLETED: {
            return {
                ...state,
                loadingRFPSummary: false,
                rfpSummaryLoaded: true,
                rfpSummary: {
                    attachments: (action.attachments || []),
                    owner: action.owner,
                    title: action.title,
                    briefDescription: action.briefDescription,
                    problemStatement: action.problemStatement,
                    missionObjective: action.missionObjective,
                    missionStart: loadDate(action, 'missionStart'),
                    missionEnd: loadDate(action, 'missionEnd'),
                    proposalsExpectedBy: action.proposalsExpectedBy !== '0001-01-01T00:00:00' ? action.proposalsExpectedBy : undefined,
                    proposalsAwardedBy: action.proposalsAwardedBy !== '0001-01-01T00:00:00' ? action.proposalsAwardedBy : undefined,
                    businessSectors: (get(action, 'businessSectors', []) || []).map(sector => ({
                        id: sector.id,
                        Name: sector.name
                    } as IBusinessSectorEntity)),
                    coutries: (get(action, 'coutries', []) || []).map(sector => ({
                        id: sector.id,
                        Name: sector.name
                    } as ICountry)),
                    expertises: (get(action, 'expertises', []) || []).map(sector => ({
                        id: sector.id,
                        name: sector.name,
                        competencesList: (sector.competencesList || []).map(sectorElem => ({
                            id: sectorElem.id,
                            name: sectorElem.name
                        }))
                    })),
                    barAdmissions: (get(action, 'barAdmissions', []) || []).map(sector => ({
                        id: sector.id,
                        Name: sector.name
                    } as IJuridiction)),
                    financials: (get(action, 'financials', []) || undefined),
                    financialSection: get(action, 'financialSection', {}) || {},
                    milestones: (get(action, 'milestones', []) || []),
                    expectedItems: (get(action, 'expectedItems', []) || []),
                    lawyerWillDescribeTheScopeOfActions: action.lawyerWillDescribeTheScopeOfActions,
                    onBehalfOfThirdParty: action.onBehalfOfThirdParty,
                    decomposition: action.decomposition,
                    individual: action.individual,
                    lawyerShouldScritclyFollowCompanyPricingPolicy: action.lawyerShouldScritclyFollowCompanyPricingPolicy,
                    iWantToDeclareAcceptableFeeArrangements: action.iWantToDeclareAcceptableFeeArrangements,
                    detailsOfTheCompany: {
                        description: get(action, 'detailsOfTheCompany.description'),
                        email: get(action, 'detailsOfTheCompany.email'),
                        companyName: get(action, 'detailsOfTheCompany.companyName'),
                        phone: get(action, 'detailsOfTheCompany.phone')
                    }
                } as IRFPSummary,
                extras: loadExtras(action, state),
                requestForProposalTitle: action.title
            };
        }

        case actionTypes.PROPOSAL_UPLOAD_FILE_REQUESTED: {
            return {
                ...state,
                proposalIsUploadingFile: true,
                proposalUploadingFileName: action.fileName,
                proposalUploadingFileProgress: 0,
                proposalUploadingFileContainer: action.container
            };
        }

        case actionTypes.PROPOSAL_UPLOAD_FILE_PROGRESS_CHANGE: {
            return {
                ...state,
                proposalUploadingFileProgress: action.progress
            };
        }

        case actionTypes.PROPOSAL_UPLOAD_FILE_COMPLETED: {
            return {
                ...state,
                proposalIsUploadingFile: false,
                proposalUploadingFileName: undefined,
                proposalUploadingFileProgress: 0
            };
        }

        case actionTypes.PROPOSAL_DOWNLOAD_FILE_REQUESTED: {
            return {
                ...state,
                proposalCurrentlyDownloadingFiles: {
                    ...state.proposalCurrentlyDownloadingFiles,
                    [action.fileId]: true
                }
            };
        }

        case actionTypes.PROPOSAL_DOWNLOAD_FILE_COMPLETED: {
            return {
                ...state,
                proposalCurrentlyDownloadingFiles: {
                    ...state.proposalCurrentlyDownloadingFiles,
                    [action.fileId]: false
                }
            };
        }

        default: return state;
    }
};
