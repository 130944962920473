import {useIntl} from 'react-intl';

export const customTranslations = () => {
    const intl = useIntl();

    return {
        'PdfViewer': {
            'PdfViewer': intl.formatMessage({ id: 'pdfViewer.pdfViewer' }),
            'Cancel': intl.formatMessage({ id: 'pdfViewer.cancel' }),
            'Download file': intl.formatMessage({ id: 'pdfViewer.downloadFile' }),
            'Download': intl.formatMessage({ id: 'pdfViewer.download' }),
            'Enter Password': intl.formatMessage({ id: 'pdfViewer.enterPassword' }),
            'File Corrupted': intl.formatMessage({ id: 'pdfViewer.fileCorrupted' }),
            'File Corrupted Content': intl.formatMessage({ id: 'pdfViewer.fileCorruptedContent' }),
            'Fit Page': intl.formatMessage({ id: 'pdfViewer.fitPage' }),
            'Fit Width': intl.formatMessage({ id: 'pdfViewer.fitWidth' }),
            'Automatic': intl.formatMessage({ id: 'pdfViewer.automatic' }),
            'Go To First Page': intl.formatMessage({ id: 'pdfViewer.goToFirstPage' }),
            'Invalid Password': intl.formatMessage({ id: 'pdfViewer.invalidPassword' }),
            'Next Page': intl.formatMessage({ id: 'pdfViewer.nextPage' }),
            'OK': intl.formatMessage({ id: 'pdfViewer.ok' }),
            'Open': intl.formatMessage({ id: 'pdfViewer.open' }),
            'Page Number': intl.formatMessage({ id: 'pdfViewer.pageNumber' }),
            'Previous Page': intl.formatMessage({ id: 'pdfViewer.previousPage' }),
            'Go To Last Page': intl.formatMessage({ id: 'pdfViewer.goToLastPage' }),
            'Zoom': intl.formatMessage({ id: 'pdfViewer.zoom' }),
            'Zoom In': intl.formatMessage({ id: 'pdfViewer.zoomIn' }),
            'Zoom Out': intl.formatMessage({ id: 'pdfViewer.zoomOut' }),
            'Page Thumbnails': intl.formatMessage({ id: 'pdfViewer.pageThumbnails' }),
            'Bookmarks': intl.formatMessage({ id: 'pdfViewer.bookmarks' }),
            'Print': intl.formatMessage({ id: 'pdfViewer.print' }),
            'Password Protected': intl.formatMessage({ id: 'pdfViewer.passwordProtected' }),
            'Copy': intl.formatMessage({ id: 'pdfViewer.copy' }),
            'Text Selection': intl.formatMessage({ id: 'pdfViewer.textSelection' }),
            'Panning': intl.formatMessage({ id: 'pdfViewer.panning' }),
            'Text Search': intl.formatMessage({ id: 'pdfViewer.textSearch' }),
            'Find in document': intl.formatMessage({ id: 'pdfViewer.findInDocument' }),
            'Match case': intl.formatMessage({ id: 'pdfViewer.matchCase' }),
            'Apply': intl.formatMessage({ id: 'pdfViewer.apply' }),
            'GoToPage': intl.formatMessage({ id: 'pdfViewer.goToPage' }),
            'No matches': intl.formatMessage({ id: 'pdfViewer.noMatches' }),
            'No Text Found': intl.formatMessage({ id: 'pdfViewer.noTextFound' }),
            'Undo': intl.formatMessage({ id: 'pdfViewer.undo' }),
            'Redo': intl.formatMessage({ id: 'pdfViewer.redo' }),
            'Annotation': intl.formatMessage({ id: 'pdfViewer.annotation' }),
            'Highlight': intl.formatMessage({ id: 'pdfViewer.highlight' }),
            'Underline': intl.formatMessage({ id: 'pdfViewer.Underline' }),
            'Strikethrough': intl.formatMessage({ id: 'pdfViewer.strikethrough' }),
            'Delete': intl.formatMessage({ id: 'pdfViewer.delete' }),
            'Opacity': intl.formatMessage({ id: 'pdfViewer.opacity' }),
            'Color edit': intl.formatMessage({ id: 'pdfViewer.colorEdit' }),
            'Opacity edit': intl.formatMessage({ id: 'pdfViewer.opacityEdit' }),
            'highlight': intl.formatMessage({ id: 'pdfViewer.highlight' }),
            'underline': intl.formatMessage({ id: 'pdfViewer.underline' }),
            'strikethrough': intl.formatMessage({ id: 'pdfViewer.strikethrough' }),
            'Server error': intl.formatMessage({ id: 'pdfViewer.serverError' }),
            'Open text': intl.formatMessage({ id: 'pdfViewer.openText' }),
            'First text': intl.formatMessage({ id: 'pdfViewer.firstText' }),
            'Previous text': intl.formatMessage({ id: 'pdfViewer.previousText' }),
            'Next text': intl.formatMessage({ id: 'pdfViewer.nextText' }),
            'Last text': intl.formatMessage({ id: 'pdfViewer.lastText' }),
            'Zoom in text': intl.formatMessage({ id: 'pdfViewer.zoomInText' }),
            'Zoom out text': intl.formatMessage({ id: 'pdfViewer.zoomOutText' }),
            'Selection text': intl.formatMessage({ id: 'pdfViewer.selectionText' }),
            'Pan text': intl.formatMessage({ id: 'pdfViewer.panText' }),
            'Print text': intl.formatMessage({ id: 'pdfViewer.printText' }),
            'Seach text': intl.formatMessage({ id: 'pdfViewer.seachText' }),
            'Annotation Edit text': intl.formatMessage({ id: 'pdfViewer.annotationEditText' }),
            'Line Thickness': intl.formatMessage({ id: 'pdfViewer.lineThickness' }),
            'Line Properties': intl.formatMessage({ id: 'pdfViewer.lineProperties' }),
            'Start Arrow': intl.formatMessage({ id: 'pdfViewer.startArrow' }),
            'End Arrow': intl.formatMessage({ id: 'pdfViewer.endArrow' }),
            'Line Style': intl.formatMessage({ id: 'pdfViewer.lineStyle' }),
            'Fill Color': intl.formatMessage({ id: 'pdfViewer.fillColor' }),
            'Line Color': intl.formatMessage({ id: 'pdfViewer.lineColor' }),
            'None': intl.formatMessage({ id: 'pdfViewer.none' }),
            'Open Arrow': intl.formatMessage({ id: 'pdfViewer.openArrow' }),
            'Closed Arrow': intl.formatMessage({ id: 'pdfViewer.closedArrow' }),
            'Round Arrow': intl.formatMessage({ id: 'pdfViewer.roundArrow' }),
            'Square Arrow': intl.formatMessage({ id: 'pdfViewer.squareArrow' }),
            'Diamond Arrow': intl.formatMessage({ id: 'pdfViewer.diamondArrow' }),
            'Cut': intl.formatMessage({ id: 'pdfViewer.cut' }),
            'Paste': intl.formatMessage({ id: 'pdfViewer.paste' }),
            'Delete Context': intl.formatMessage({ id: 'pdfViewer.deleteContext' }),
            'Properties': intl.formatMessage({ id: 'pdfViewer.properties' }),
            'Add Stamp': intl.formatMessage({ id: 'pdfViewer.addStamp' }),
            'Add Shapes': intl.formatMessage({ id: 'pdfViewer.addShapes' }),
            'Stroke edit': intl.formatMessage({ id: 'pdfViewer.strokeEdit' }),
            'Change thickness': intl.formatMessage({ id: 'pdfViewer.changeThickness' }),
            'Add line': intl.formatMessage({ id: 'pdfViewer.addLine' }),
            'Add arrow': intl.formatMessage({ id: 'pdfViewer.addArrow' }),
            'Add rectangle': intl.formatMessage({ id: 'pdfViewer.addRectangle' }),
            'Add circle': intl.formatMessage({ id: 'pdfViewer.addCircle' }),
            'Add polygon': intl.formatMessage({ id: 'pdfViewer.addPolygon' }),
            'Add Comments': intl.formatMessage({ id: 'pdfViewer.addComments' }),
            'Comments': intl.formatMessage({ id: 'pdfViewer.comments' }),
            'No Comments Yet': intl.formatMessage({ id: 'pdfViewer.noCommentsYet' }),
            'Accepted': intl.formatMessage({ id: 'pdfViewer.accepted' }),
            'Completed': intl.formatMessage({ id: 'pdfViewer.completed' }),
            'Cancelled': intl.formatMessage({ id: 'pdfViewer.cancelled' }),
            'Rejected': intl.formatMessage({ id: 'pdfViewer.rejected' }),
            'Leader Length': intl.formatMessage({ id: 'pdfViewer.leaderLength' }),
            'Scale Ratio': intl.formatMessage({ id: 'pdfViewer.scaleRatio' }),
            'Calibrate': intl.formatMessage({ id: 'pdfViewer.calibrate' }),
            'Calibrate Distance': intl.formatMessage({ id: 'pdfViewer.calibrateDistance' }),
            'Calibrate Perimeter': intl.formatMessage({ id: 'pdfViewer.calibratePerimeter' }),
            'Calibrate Area': intl.formatMessage({ id: 'pdfViewer.calibrateArea' }),
            'Calibrate Radius': intl.formatMessage({ id: 'pdfViewer.calibrateRadius' }),
            'Calibrate Volume': intl.formatMessage({ id: 'pdfViewer.calibrateVolume' }),
            'Depth': intl.formatMessage({ id: 'pdfViewer.depth' }),
            'Closed': intl.formatMessage({ id: 'pdfViewer.closed' }),
            'Round': intl.formatMessage({ id: 'pdfViewer.round' }),
            'Square': intl.formatMessage({ id: 'pdfViewer.square' }),
            'Diamond': intl.formatMessage({ id: 'pdfViewer.diamond' }),
            'Edit': intl.formatMessage({ id: 'pdfViewer.edit' }),
            'Comment': intl.formatMessage({ id: 'pdfViewer.comment' }),
            'Comment Panel': intl.formatMessage({ id: 'pdfViewer.commentPanel' }),
            'Set Status': intl.formatMessage({ id: 'pdfViewer.setStatus' }),
            'Post': intl.formatMessage({ id: 'pdfViewer.post' }),
            'Page': intl.formatMessage({ id: 'pdfViewer.page' }),
            'Add a comment': intl.formatMessage({ id: 'pdfViewer.addComment' }),
            'Add a reply': intl.formatMessage({ id: 'pdfViewer.addReply' }),
            'Import Annotations': intl.formatMessage({ id: 'pdfViewer.importAnnotations' }),
            'Export Annotations': intl.formatMessage({ id: 'pdfViewer.exportAnnotations' }),
            'Add': intl.formatMessage({ id: 'pdfViewer.add' }),
            'Clear': intl.formatMessage({ id: 'pdfViewer.clear' }),
            'Bold': intl.formatMessage({ id: 'pdfViewer.bold' }),
            'Italic': intl.formatMessage({ id: 'pdfViewer.italic' }),
            'Strikethroughs': intl.formatMessage({ id: 'pdfViewer.strikethroughs' }),
            'Underlines': intl.formatMessage({ id: 'pdfViewer.underlines' }),
            'Superscript': intl.formatMessage({ id: 'pdfViewer.superscript' }),
            'Subscript': intl.formatMessage({ id: 'pdfViewer.subscript' }),
            'Align left': intl.formatMessage({ id: 'pdfViewer.alignLeft' }),
            'Align right': intl.formatMessage({ id: 'pdfViewer.alignRight' }),
            'Center': intl.formatMessage({ id: 'pdfViewer.center' }),
            'Justify': intl.formatMessage({ id: 'pdfViewer.justify' }),
            'Font color': intl.formatMessage({ id: 'pdfViewer.fontColor' }),
            'Text Align': intl.formatMessage({ id: 'pdfViewer.textAlign' }),
            'Text Properties': intl.formatMessage({ id: 'pdfViewer.textProperties' }),
            'Draw Signature': intl.formatMessage({ id: 'pdfViewer.drawSignature' }),
            'Create': intl.formatMessage({ id: 'pdfViewer.create' }),
            'Font family': intl.formatMessage({ id: 'pdfViewer.fontFamily' }),
            'Font size': intl.formatMessage({ id: 'pdfViewer.fontSize' }),
            'Free Text': intl.formatMessage({ id: 'pdfViewer.freeText' }),
            'Import Failed': intl.formatMessage({ id: 'pdfViewer.importFailed' }),
            'File not found': intl.formatMessage({ id: 'pdfViewer.fileNotFound' }),
            'Export Failed': intl.formatMessage({ id: 'pdfViewer.exportFailed' }),
            'Dynamic': intl.formatMessage({ id: 'pdfViewer.dynamic' }),
            'Standard Business': intl.formatMessage({ id: 'pdfViewer.standardBusiness' }),
            'Sign Here': intl.formatMessage({ id: 'pdfViewer.signHere' }),
            'Custom Stamp': intl.formatMessage({ id: 'pdfViewer.customStamp' }),
            'InitialFieldDialogHeaderText': intl.formatMessage({ id: 'pdfViewer.initialFieldDialogHeaderText' }),
            'HandwrittenInitialDialogHeaderText': intl.formatMessage({ id: 'pdfViewer.handwrittenInitialDialogHeaderText' }),
            'SignatureFieldDialogHeaderText': intl.formatMessage({ id: 'pdfViewer.signatureFieldDialogHeaderText' }),
            'HandwrittenSignatureDialogHeaderText': intl.formatMessage({ id: 'pdfViewer.handwrittenSignatureDialogHeaderText' }),
            'Draw-hand Signature': intl.formatMessage({ id: 'pdfViewer.drawHandSignature' }),
            'Type Signature': intl.formatMessage({ id: 'pdfViewer.typeSignature' }),
            'Upload Signature': intl.formatMessage({ id: 'pdfViewer.uploadSignature' }),
            'Browse Signature Image': intl.formatMessage({ id: 'pdfViewer.browseSignatureImage' }),
            'Save Signature': intl.formatMessage({ id: 'pdfViewer.saveSignature' }),
            'Save Initial': intl.formatMessage({ id: 'pdfViewer.saveInitial' }),
            'Highlight context': intl.formatMessage({ id: 'pdfViewer.highlightContext' }),
            'Underline context': intl.formatMessage({ id: 'pdfViewer.underlineContext' }),
            'Strikethrough context': intl.formatMessage({ id: 'pdfViewer.strikethroughContext' }),
            'FormDesigner': intl.formatMessage({ id: 'pdfViewer.formDesigner' }),
            'SubmitForm': intl.formatMessage({ id: 'pdfViewer.submitForm' }),
            'Search text': intl.formatMessage({ id: 'pdfViewer.searchText' }),
            'Draw Ink': intl.formatMessage({ id: 'pdfViewer.drawInk' }),
            'Revised': intl.formatMessage({ id: 'pdfViewer.revised' }),
            'Reviewed': intl.formatMessage({ id: 'pdfViewer.reviewed' }),
            'Received': intl.formatMessage({ id: 'pdfViewer.received' }),
            'Confidential': intl.formatMessage({ id: 'pdfViewer.confidential' }),
            'Approved': intl.formatMessage({ id: 'pdfViewer.approved' }),
            'Not Approved': intl.formatMessage({ id: 'pdfViewer.notApproved' }),
            'Witness': intl.formatMessage({ id: 'pdfViewer.witness' }),
            'Initial Here': intl.formatMessage({ id: 'pdfViewer.initialHere' }),
            'Draft': intl.formatMessage({ id: 'pdfViewer.draft' }),
            'Final': intl.formatMessage({ id: 'pdfViewer.final' }),
            'For Public Release': intl.formatMessage({ id: 'pdfViewer.forPublicRelease' }),
            'Not For Public Release': intl.formatMessage({ id: 'pdfViewer.notForPublicRelease' }),
            'For Comment': intl.formatMessage({ id: 'pdfViewer.forComment' }),
            'Void': intl.formatMessage({ id: 'pdfViewer.void' }),
            'Preliminary Results': intl.formatMessage({ id: 'pdfViewer.preliminaryResults' }),
            'Information Only': intl.formatMessage({ id: 'pdfViewer.informationOnly' }),
            'Enter Signature as Name': intl.formatMessage({ id: 'pdfViewer.enterSignatureAsName' }),
            'Textbox': intl.formatMessage({ id: 'pdfViewer.textbox' }),
            'Password': intl.formatMessage({ id: 'pdfViewer.password' }),
            'Check Box': intl.formatMessage({ id: 'pdfViewer.checkBox' }),
            'Radio Button': intl.formatMessage({ id: 'pdfViewer.radioButton' }),
            'Dropdown': intl.formatMessage({ id: 'pdfViewer.dropdown' }),
            'List Box': intl.formatMessage({ id: 'pdfViewer.listBox' }),
            'Signature': intl.formatMessage({ id: 'pdfViewer.signature' }),
            'Delete FormField': intl.formatMessage({ id: 'pdfViewer.deleteFormField' }),
            'FormDesigner Edit text': intl.formatMessage({ id: 'pdfViewer.formDesignerEditText' }),
            'in': intl.formatMessage({ id: 'pdfViewer.in' }),
            'm': intl.formatMessage({ id: 'pdfViewer.m' }),
            'ft_in': intl.formatMessage({ id: 'pdfViewer.ftIn' }),
            'ft': intl.formatMessage({ id: 'pdfViewer.ft' }),
            'p': intl.formatMessage({ id: 'pdfViewer.p' }),
            'cm': intl.formatMessage({ id: 'pdfViewer.cm' }),
            'mm': intl.formatMessage({ id: 'pdfViewer.mm' }),
            'pt': intl.formatMessage({ id: 'pdfViewer.pt' }),
            'cu': intl.formatMessage({ id: 'pdfViewer.cu' }),
            'sq': intl.formatMessage({ id: 'pdfViewer.sq' }),
            'General': intl.formatMessage({ id: 'pdfViewer.general' }),
            'Appearance': intl.formatMessage({ id: 'pdfViewer.appearance' }),
            'Options': intl.formatMessage({ id: 'pdfViewer.options' }),
            'Textbox Properties': intl.formatMessage({ id: 'pdfViewer.textboxProperties' }),
            'Name': intl.formatMessage({ id: 'pdfViewer.name' }),
            'Tooltip': intl.formatMessage({ id: 'pdfViewer.tooltip' }),
            'Value': intl.formatMessage({ id: 'pdfViewer.value' }),
            'Form Field Visibility': intl.formatMessage({ id: 'pdfViewer.formFieldVisibility' }),
            'Read Only': intl.formatMessage({ id: 'pdfViewer.readOnly' }),
            'Required': intl.formatMessage({ id: 'pdfViewer.required' }),
            'Checked': intl.formatMessage({ id: 'pdfViewer.checked' }),
            'Show Printing': intl.formatMessage({ id: 'pdfViewer.showPrinting' }),
            'Formatting': intl.formatMessage({ id: 'pdfViewer.formatting' }),
            'Fill': intl.formatMessage({ id: 'pdfViewer.fill' }),
            'Border': intl.formatMessage({ id: 'pdfViewer.border' }),
            'Border Color': intl.formatMessage({ id: 'pdfViewer.borderColor' }),
            'Thickness': intl.formatMessage({ id: 'pdfViewer.thickness' }),
            'Max Length': intl.formatMessage({ id: 'pdfViewer.maxLength' }),
            'List Item': intl.formatMessage({ id: 'pdfViewer.listItem' }),
            'Export Value': intl.formatMessage({ id: 'pdfViewer.exportValue' }),
            'Dropdown Item List': intl.formatMessage({ id: 'pdfViewer.dropdownItemList' }),
            'List Box Item List': intl.formatMessage({ id: 'pdfViewer.listBoxItemList' }),
            'Delete Item': intl.formatMessage({ id: 'pdfViewer.deleteItem' }),
            'Up': intl.formatMessage({ id: 'pdfViewer.up' }),
            'Down': intl.formatMessage({ id: 'pdfViewer.down' }),
            'Multiline': intl.formatMessage({ id: 'pdfViewer.multiline' }),
            'Initial': intl.formatMessage({ id: 'pdfViewer.initial' }),
            'Export XFDF': intl.formatMessage({ id: 'pdfViewer.exportXFDF' }),
            'Import XFDF': intl.formatMessage({ id: 'pdfViewer.importXFDF' })
        }
    };
};