import React, { FC } from 'react';
import styled from 'styled-components';

import { Attachment } from '../../Attachment/Attachment';

const Wrapper = styled.div``;

interface IProps {
    contentType: string;
    directUri: string;
    fileId: string;
    name: string;
}

export const Attachments: FC<IProps> = props => {
    return (
        <Wrapper>
            <Attachment id={props.fileId} fileName={props.name} />
        </Wrapper>
    );
};