import { useCallback, useMemo, Reducer, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import { addressFormatter } from './addressFormatter';
import { EProfileDataType } from './profileConstants';
import { FacetsNames, LegalEntitiesNames, ISearch, ISearchUser } from '../entities/ISearch';
import { IReferencial, EReferentials, ISearchResponse, EContextList } from '../entities/IGlobal';
import { IInvolvedPartyRequest } from '../entities/IClusters';
import { ISearchLegalEntitiesValue } from '../entities/ILegalEntities';
import { IItemResults } from '../entities/IFilters';
import { getAllLegalEntities } from '../actions/legalEntitiesActions';
import { getSearchResult } from '../actions/searchActions';
import { getReferentials } from '../actions/globalActions';
import { getRelatedCluster } from '../actions/clustersActions';
import { ITag } from '../components/Common/TagPicker/TagPicker';

interface ISearchFn<T> {
    (queryOrType: string | EProfileDataType | EReferentials, context: string, clusterId: string): Promise<T[]>;
}

interface IFormatTagValueFn<T> {
    (item: T): string;
}

interface IFormatTagFn<T> {
    (items: T[]): ITag<T>[];
}

interface IState<T, U = string> {
    searching: boolean;
    results: ITag<T>[];
    error: U;
}

const initialState = {
    searching: false,
    results: undefined,
    error: undefined
};

type Action<T, U = string> =
    | { type: 'SEARCH' }
    | { type: 'SEARCH_SUCCESS'; results: IState<T, U>['results'] }
    | { type: 'SEARCH_ERROR'; error: IState<T, U>['error'] };

const reducer = <T, U>(state: IState<T, U>, action: Action<T, U>): IState<T, U> => {
    switch (action.type) {
        case 'SEARCH':
            return { ...state, searching: true, results: undefined, error: undefined };
        case 'SEARCH_SUCCESS':
            return { ...state, searching: false, results: action.results };
        case 'SEARCH_ERROR':
            return { ...state, searching: false, error: action.error };
        default:
            return { ...initialState };
    }
};

const useSearchLegalEntities = (): [ISearchFn<IItemResults>, IFormatTagValueFn<ISearchLegalEntitiesValue>] => {
    const dispatch = useDispatch();
    const filters = useMemo(() =>
        Object.keys(LegalEntitiesNames).reduce((result, item) => ({ ...result, [item]: [] }), {})
        , []);

    const searchFn = useCallback((query: string): Promise<IItemResults[]> => {
        return dispatch(getAllLegalEntities(query, filters, 15))
            .then(response => response.items);
    }, [filters]);

    const formatFn = useCallback((item: ISearchLegalEntitiesValue) => {
        return `${item.name} ${item.address ? ` - ${addressFormatter(item.address)}` : ''}`;
    }, [addressFormatter]);

    return [searchFn, formatFn];
};

const useSearchPeoples = (): [ISearchFn<ISearchUser>, IFormatTagValueFn<ISearchUser>] => {
    const dispatch = useDispatch();
    const filters = useMemo(() =>
        Object.keys(FacetsNames).reduce((result, item) => ({ ...result, [item]: [] }), {})
        , []);

    const searchFn = useCallback((query: string): Promise<ISearchUser[]> => {
        return dispatch<Promise<ISearch>>(getSearchResult(query, 0, 20, filters))
            .then(response => response.items);
    }, [filters]);

    const formatFn = useCallback((item: ISearchUser) => {
        return `${item.firstName} ${item.lastName}`;
    }, []);

    return [searchFn, formatFn];
};

const useReferentials = (): [ISearchFn<IReferencial>, IFormatTagValueFn<IReferencial>] => {
    const dispatch = useDispatch();

    const searchFn = useCallback((type: EProfileDataType | EReferentials, context: EContextList): Promise<IReferencial[]> => {
        return dispatch<Promise<IReferencial[]>>(getReferentials('', type, context))
            .then(response => response);
    }, []);

    const formatFn = useCallback((item: IReferencial) => item.name, []);

    return [searchFn, formatFn];
};

const useInvolvedParties = (): [ISearchFn<IInvolvedPartyRequest>, IFormatTagValueFn<IInvolvedPartyRequest>] => {
    const dispatch = useDispatch();

    const searchFn = useCallback((query: string, organizationId: string, clusterId: string): Promise<IInvolvedPartyRequest[]> => {
        return dispatch<Promise<ISearchResponse<IInvolvedPartyRequest>>>(getRelatedCluster(query, organizationId, clusterId))
            .then(response => response.items);
    }, []);

    const formatFn = useCallback((item: IInvolvedPartyRequest) => item.name, []);

    return [searchFn, formatFn];
};

type PickerReturnValue<T> = [ISearchFn<ITag<T>>, IFormatTagFn<T>, IState<T>['results'], IState<T>['searching'], IState<T>['error']];

const usePicker = <T extends { id: string }>(searchFn: ISearchFn<T>, formatFn: IFormatTagValueFn<T>): PickerReturnValue<T> => {
    const [state, dispatch] = useReducer<Reducer<IState<T>, Action<T>>>(reducer, { ...initialState });

    const mapResults = useCallback((items: T[]): ITag<T>[] => {
        return (items || []).map(item => ({ id: item.id, value: formatFn(item), data: item }));
    }, [formatFn]);

    const search = useCallback(async (queryOrType: string | EProfileDataType | EReferentials, context: string, clusterId: string): Promise<ITag<T>[]> => {
        try {
            dispatch({ type: 'SEARCH' });
            const results = mapResults(await searchFn(queryOrType || '', context, clusterId));
            dispatch({ type: 'SEARCH_SUCCESS', results });
            return results;
        } catch (err) {
            dispatch({ type: 'SEARCH_ERROR', error: err });
        }
    }, [mapResults, searchFn]);

    return [search, mapResults, state.results, state.searching, state.error];
};

export const useLegalEntitiesPicker = () => {
    const [searchFn, formatFn] = useSearchLegalEntities();
    return usePicker<ISearchLegalEntitiesValue | IItemResults>(searchFn, formatFn);
};

export const usePeoplesPicker = () => {
    const [searchFn, formatFn] = useSearchPeoples();
    return usePicker<ISearchUser>(searchFn, formatFn);
};

export const useReferentialsPicker = () => {
    const [searchFn, formatFn] = useReferentials();
    return usePicker<IReferencial>(searchFn, formatFn);
};

export const useInvolvedPartiesPicker = () => {
    const [searchFn, formatFn] = useInvolvedParties();
    return usePicker<IInvolvedPartyRequest>(searchFn, formatFn);
};
