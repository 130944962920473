import { ILegalClusterConfigType } from '../entities/IConfiguration';
import * as actionTypes from './actionTypes/configTypes';
import { action } from '../tools/reduxTools';

export const getApplicationConfig = () => action<Promise<ILegalClusterConfigType>>((dispatch) => {
    return fetch('api/Config/GetLegalClusterConfig')
        .then(response => response.json() as Promise<ILegalClusterConfigType>)
        .then(appConfig => {
            dispatch({
                type: actionTypes.GET_APPLICATION_CONFIG,
                appConfig
            } as IGetApplicationConfig);

            return appConfig;
        });
});

export interface IGetApplicationConfig {
    type: string,
    appConfig: ILegalClusterConfigType
}
