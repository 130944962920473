import * as actionTypes from '../actions/actionTypes/avatarTypes';
import { colorStack } from '../styleHelpers/colors';

export interface IAvatarReducer {
    currentColorIndex: number;
    usersColor: {[key: string]: string};
    colors: string[];
}

const defaultState = (): IAvatarReducer => ({
    currentColorIndex: 0,
    usersColor: {},
    colors: [colorStack.lightPurple, colorStack.lightRed, colorStack.lightGreen, colorStack.lightOrange, colorStack.middleGrey]
});

export default (state = defaultState(), action): IAvatarReducer => {
    switch (action.type) {
        case actionTypes.SET_COLOR_USER: {
            const payload: actionTypes.IAvatarTypes['SET_COLOR_USER'] = action.data;
            const colorIndex = !state.usersColor[payload.id] ? (state.currentColorIndex === state.colors.length - 1 ? 0 : state.currentColorIndex + 1) : state.currentColorIndex;
            return {
                ...state,
                usersColor: !state.usersColor[payload.id] ? {...state.usersColor, [payload.id]: state.colors[colorIndex]} : state.usersColor,
                currentColorIndex:  colorIndex
            };
        }
        default: {
            return state;
        }
    }
};