import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { fontSize } from '../../../../styleHelpers/fontSizes';

interface ITitleProps {
    fixedMaxWidth?: boolean;
}

const Title = styled.h2<ITitleProps>`
    margin: 1rem;
    font-size: ${fontSize[25]};
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    span {
        font-family: ${({ theme }) => theme.font?.primary}, Helvetica, sans-serif;
        font-weight: 600;
        &:after {
            display: none;
        }
    }
    ${({ fixedMaxWidth }) => fixedMaxWidth && css`
        max-width: 812px;
        margin: 1rem auto;
    `}
`;

interface ISectionTitleProps extends ITitleProps {
    title: ReactNode;
    commonLevelId: string;
    className?: string;
    renderPath?: string;
}

export const SectionTitle: FC<React.PropsWithChildren<ISectionTitleProps>> = ({ title, commonLevelId, children, className, fixedMaxWidth, renderPath }) => {
    return (
        <Title
            data-diff-structure
            data-common-id={commonLevelId}
            data-section
            className={cn('structure-children-observer', className || '')}
            fixedMaxWidth={fixedMaxWidth}
            data-item-id={commonLevelId}
            data-item-name={title}
            data-render-path={renderPath}
        >
            <span data-diff-title>
                {title}
            </span>
            {children}
        </Title>
    );
};
