import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getReferentials, getUnits } from '../actions/globalActions';
import { EContextList, EReferentials, IReferencial } from '../entities/IGlobal';
import { EProfileDataType } from './profileConstants';

type GetReferentials = ReturnType<typeof getReferentials>;
type GetUnits = ReturnType<typeof getUnits>;

export const  findRefByUniqueKey = (uniqueKey: string, referentials: IReferencial[]): IReferencial | undefined => {
    return referentials?.find(elem => elem.Key === uniqueKey);
};

export const TranslateReferentials: FC<React.PropsWithChildren<{ uniqueKey: string, type?: EProfileDataType | EReferentials, context?: EContextList }>> = (props) => {
    const dispatch = useDispatch();
    const [translatedRef, setTranslatedRef] = useState<string>('');
    useEffect(() => {
        if (props.type && props.context) {
            dispatch<GetReferentials>(getReferentials('', props.type, props.context)).then(res => {
                setTranslatedRef(findRefByUniqueKey(props.uniqueKey, res)?.name);
            });
        } else {
            dispatch<GetUnits>(getUnits()).then(res => setTranslatedRef(res.items.find(elem => elem.Key === props.uniqueKey)?.name));
        }
    }, [props.uniqueKey]);

    return (
        <>{translatedRef}</>
    );
};

// TODO: refactor this code to use redux after changes in task: 43240