import React, { FC } from 'react';
import styled from 'styled-components';

import { IBranchWithContents } from '../../../entities/ISoge';

import { Breadcrumbs } from '../LegalDocument/Breadcrumbs/BreadCrumbs';

const Wrapper = styled.div`
    padding: .5rem 1rem 0 1rem;
    > strong {
        margin-bottom: 1rem;
        display: block;
    }
`;

interface IPreviewHeaderProps {
    branch: IBranchWithContents;
}

export const PreviewHeader: FC<React.PropsWithChildren<IPreviewHeaderProps>> = ({ branch }) => {
    return (
        <Wrapper>
            <strong>Preview of your modification</strong>
            {branch?.contents && (
                <Breadcrumbs parents={(branch.contents.parents || [])} />
            )}
        </Wrapper>
    );
}
