import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { cardsShadow } from '../../../styleHelpers/mixins/shadow';

export enum EToolTipTheme {
    DARK = 'DARK',
    LIGHT = 'LIGHT'
}

export enum ETooltipArrowDirection {
    NONE = 'NONE',
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}

const Wrapper = styled.div<{ colorsSchema: EToolTipTheme, width?: number, top: number, left: number }>`
    background: ${colorStack.content};
    position: fixed;
    ${fontSizeAndHeight[16]};
    ${cardsShadow};
    border-radius: 8px;
    z-index: 999999;
    padding: .5rem 1rem;
    ${({width}) => width && css`
        max-width: ${`${width}px`};
    `}
    white-space: normal;
    display: inline-flex;
    flex-direction: column;
    top: 0;
    left: 0;
    ${props => props.colorsSchema === EToolTipTheme.LIGHT && css`
        background: ${colorStack.whiteGrey};
    `}
    ${({top}) => top && css`
        top: ${`${top}px`};
    `}
    ${({left}) => left && css`
        left: ${`${left}px`};
    `}
`;

const Triangle = styled.div<{ colorsSchema: EToolTipTheme, direction: ETooltipArrowDirection }>`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 10px solid transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 10px solid ${colorStack.content};
    bottom: 100%;
    width: 16px;
    ${props => props.colorsSchema === EToolTipTheme.LIGHT && css`
        border-bottom: 10px solid ${colorStack.whiteGrey};
    `}
    ${props => props.direction === ETooltipArrowDirection.NONE && css`
        display: none;
    `}
    ${props => props.direction === ETooltipArrowDirection.BOTTOM && css`
        bottom: -20px;
        border-top: 10px solid ${props.colorsSchema === EToolTipTheme.DARK ? colorStack.content : colorStack.whiteGrey};
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 10px solid transparent;
    `}
    ${props => props.direction === ETooltipArrowDirection.LEFT && css`
        left: -20px;
        margin: 0;
        right: auto;
        bottom: auto;
        top: calc((100% / 2) - 8px);
        border-top: 8px solid transparent;
        border-right: 10px solid ${props.colorsSchema === EToolTipTheme.DARK ? colorStack.content : colorStack.whiteGrey};
        border-left: 10px solid transparent;
        border-bottom: 8px solid transparent;
    `}
    ${props => props.direction === ETooltipArrowDirection.RIGHT && css`
        right: -20px;
        margin: 0;
        left: auto;
        bottom: auto;
        top: calc((100% / 2) - 8px);
        border-top: 8px solid transparent;
        border-right: 10px solid transparent;
        border-left: 10px solid ${props.colorsSchema === EToolTipTheme.DARK ? colorStack.content : colorStack.whiteGrey};
        border-bottom: 8px solid transparent;
    `}
`;

const Title = styled.pre<{ colorsSchema: EToolTipTheme }>`
    color: ${colorStack.white}!important;
    font-weight: 500;
    word-wrap: break-word;
    white-space: pre-wrap;
    ${props => props.colorsSchema === EToolTipTheme.LIGHT && css`
        color: ${colorStack.content};
    `}
`;

const Content = styled.span<{ colorsSchema: EToolTipTheme }>`
    font-weight: 500;
    color: ${colorStack.disabled}!important;
`;

interface IProps {
    theme?: EToolTipTheme;
    fixedWidth?: string;
    arrowDirection?: ETooltipArrowDirection;
    title: string;
    content?: string;
    top?: number;
    left?: number;
    setTooltipWidth?(width: number);
}

export const ToolTip: FC<React.PropsWithChildren<IProps>> = props => {
    const wrapperRef = useRef<HTMLDivElement>();
    const addictionalSafeSpace = 20;
    const [contentWidth, setContentWidth] = useState<number>(0);

    useEffect(() => {
        setContentWidth(wrapperRef?.current?.clientWidth);
        props.setTooltipWidth && props.setTooltipWidth(wrapperRef?.current?.clientWidth + addictionalSafeSpace);
    }, [wrapperRef?.current?.clientWidth, props.content]);

    return ReactDOM.createPortal(
        <Wrapper ref={wrapperRef} colorsSchema={props.theme || EToolTipTheme.DARK} width={Number(props?.fixedWidth) || contentWidth} top={props.top || 0} left={props.left || 0}>
            <Triangle colorsSchema={props.theme || EToolTipTheme.DARK} direction={props.arrowDirection || ETooltipArrowDirection.TOP} />
            <Title colorsSchema={props.theme || EToolTipTheme.DARK}>{props.title}</Title>
            <Content colorsSchema={props.theme || EToolTipTheme.DARK}>{props.content}</Content>
        </Wrapper>, document.getElementById('tooltip-root')
    );
};