import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';

export enum EBadgeSize {
    XSMALL = 'XSMALL',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    MLARGE = 'MLARGE',
    LARGE = 'LARGE'
}

export enum EBadgeShape {
    CIRCLE = 'CIRCLE',
    SQUARE = 'SQUARE'
}

export enum EBadgeColor {
    BLUE = 'BLUE',
    YELLOW = 'YELLOW',
    RED = 'RED',
    GREEN = 'GREEN',
    PURPLE = 'PURPLE',
    GRAY = 'GRAY',
    DARKBLUE = 'DARKBLUE'
}

const Placeholder = styled.span<{ size: EBadgeSize }>`
    padding: 8px;
    ${props => [EBadgeSize.MEDIUM, EBadgeSize.MLARGE, EBadgeSize.LARGE].includes(props.size) && css`
        padding: 8px 16px 8px 16px;
    `}
`;

const Wrapper = styled.div<{ color: string, shape: EBadgeShape, outlined: boolean, noContent: boolean, withIcon: boolean, size: EBadgeSize, forceWhiteText: boolean }>`
    color: ${colorStack.content};
    ${props => (props.noContent || props.forceWhiteText) && css`
        color: ${colorStack.white};
    `}
    font-weight: 500;
    ${fontSizeAndHeight[13]}
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    width: fit-content;

    ${props => props.outlined && css`
        box-sizing: border-box;
        border: 1.5px solid ${colorStack.white};
    `}
    ${props => props.size === EBadgeSize.XSMALL && css`
        min-width: 16px;
        ${props.noContent && css`
            width: 16px;
        `}
        height: 16px;
        ${fontSizeAndHeight[10]}
        border-radius: ${props.shape === EBadgeShape.SQUARE ? '4px' : '100%'};
    `}
    ${props => props.size === EBadgeSize.SMALL && css`
        min-width: 24px;
        ${props.noContent && css`
            width: 24px;`
        }
        height: 24px;
        ${fontSizeAndHeight[10]}
        border-radius: ${props.shape === EBadgeShape.SQUARE ? '4px' : '100%'};
    `}
    ${props => props.size === EBadgeSize.MEDIUM && css`
        min-width: 32px;
        ${props.noContent && css`
            width: 32px;`
        }
        height: 32px;
        border-radius: ${props.shape === EBadgeShape.SQUARE ? '8px' : '100%'};
    `}
    ${props => props.size === EBadgeSize.MLARGE && css`
        min-width: 40px;
        ${props.noContent && css`
            width: 40px;`
        }
        height: 40px;
        border-radius: ${props.shape === EBadgeShape.SQUARE ? '8px' : '100%'};
    `}
    ${props => props.size === EBadgeSize.LARGE && css`
        min-width: 56px;
        ${props.noContent && css`
            width: 56px;`
        }
        ${fontSizeAndHeight[16]}
        height: 56px;
        border-radius: ${props.shape === EBadgeShape.SQUARE ? '8px' : '100%'};
    `}

    ${props => props.shape === EBadgeShape.CIRCLE && css`
        border-radius: 999px;
    `}

    ${props => props.size === EBadgeSize.XSMALL && css`
    `}
    ${props => props.color === EBadgeColor.BLUE && css`
        background-color: ${colorStack.blue};
    `}
    ${props => props.color === EBadgeColor.YELLOW && css`
        background-color: ${colorStack.orange};
    `}
    ${props => props.color === EBadgeColor.RED && css`
        background-color: ${colorStack.red};
    `}
    ${props => props.color === EBadgeColor.GREEN && css`
        background-color: ${colorStack.green};
    `}
    ${props => props.color === EBadgeColor.PURPLE && css`
        background-color: ${colorStack.purple};
    `}
    ${props => props.color === EBadgeColor.DARKBLUE && css`
        background-color: ${colorStack.darkBlue};
    `}
    ${props => props.color === EBadgeColor.GRAY && css`
        background-color: ${colorStack.middleGrey};
    `}
`;

interface IBadgeProps {
    color: EBadgeColor;
    size?: EBadgeSize;
    shape?: EBadgeShape;
    placeholder?: string;
    outlined?: boolean;
    icon?: IconDefinition;
    onClick?();
}

const resolveIconSize = (size: EBadgeSize): SizeProp => {
    switch (size) {
        case EBadgeSize.XSMALL:
            return 'xs';

        case EBadgeSize.SMALL:
        case EBadgeSize.MEDIUM:
            return 'sm';

        case EBadgeSize.MLARGE:
            return 'lg';

        case EBadgeSize.LARGE:
            return 'xl';
        // tslint:disable-next-line:no-unused-expression
        default: '';
    }
};

export const Badge = ({ icon, outlined, placeholder, shape = EBadgeShape.CIRCLE, size = EBadgeSize.MEDIUM, ...props }: IBadgeProps) => (
    <Wrapper
        {...props}
        size={size}
        shape={shape}
        noContent={!placeholder}
        data-nonclick
        forceWhiteText={props.color === EBadgeColor.DARKBLUE}
        outlined={!!outlined}
        withIcon={!!icon}
    >
        {/* tslint:disable-next-line:no-null-keyword */}
        {placeholder ? <Placeholder data-nonclick size={size}>{placeholder}</Placeholder> : null}
        {/* tslint:disable-next-line:no-null-keyword */}
        {(!placeholder && icon) ? <FontAwesomeIcon icon={icon} size={resolveIconSize(size)} /> : null}
    </Wrapper>
);
