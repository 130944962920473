export const currencies = {
    EUR: {
        symbol: '€',
        smallUnit: 100
    },
    USD: {
        symbol: '$',
        smallUnit: 100
    },
    GBP: {
        symbol: '£',
        smallUnit: 100
    },
    JPY: {
        symbol: '¥',
        smallUnit: 1
    }
};
export const currencySymbol = (currency: string): string => {
    if (currencies[currency.toLocaleUpperCase()]) {
        return currencies[currency.toLocaleUpperCase()].symbol;
    }
    return currency;
};

export const currencyAmount = (currency: string, amount: number): number => {
    if (currencies[currency.toLocaleUpperCase()]) {
        return amount / currencies[currency.toLocaleUpperCase()].smallUnit;
    }
    return amount / 100;
};

export const getDecimal = (amount: number): number => {
    return parseFloat((amount - Math.trunc(amount)).toFixed(2)) * 100;
};
