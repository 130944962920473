import React, { useEffect, FC } from 'react';
import { useFormikContext } from 'formik';

import { EDeclarationTypeEnum } from '../../../../../entities/IDeclaration';
import { ICOIData } from '../../../../../entities/ActionData/ICOIData';
import { NoLinkOfInterest } from './Components/NoLinkOfInterest';
import { LinkOfInterest } from './Components/LinkOfInterest';

interface IProps {
    declarationType: string;
}

export const Step3: FC<React.PropsWithChildren<IProps>> = ({ declarationType }) => {
    const formikContext = useFormikContext<ICOIData>();

    useEffect(() => {
        formikContext.setFieldValue('step', 3);
    }, []);

    return (
        <>
            {declarationType === EDeclarationTypeEnum.CoiNoLink
                ? <NoLinkOfInterest />
                : <LinkOfInterest declarationType={declarationType} />
            }
        </>
    );
};