import React, { useState, useCallback, useMemo, FC } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheckCircle, faTrashAlt, faDownload } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { FileButton } from '../../Common/Buttons/Button';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { colorStack } from '../../../styleHelpers/colors';
import { EditSection, ActionWrapper } from './SingleValidator';
import { Tag } from '../../Common/Tag/Tag';
import { Popup as AttachmentPopup } from '../../Common/Popup/Popup';
import { EApproverStatus, IApprover, IApprovalAttachment } from '../../../entities/ISoge';
import { Avatar, EAvatarSize } from '../../Common/Avatar/Avatar';
import { SelectInput } from '../../Common/SelectInput/SelectInput';
import { DropdownMenu } from '../../Common/Buttons/DropdownMenu';
import { IValue } from '../../../entities/IPickers';
import { EDropDownType } from '../../Common/DropDown/DropDown';

const PenButton = styled.button`
    cursor: pointer;
    svg {
        margin: 0 0 0 10px;
    }
`;

const UserWrapper = styled.div`
    display: flex;
`;

const AttachmentSection = styled.button`
    display: flex;
    text-decoration: underline;
    cursor: pointer;
`;

const AttachmentPicker = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 400px;
`;

const AttachmentsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface ISingleApproverProps {
    approver: IApprover;
    editable: boolean;
    changeMandatory?(id: string, isMandatory: boolean);
    changeStatus?(id: string, newStatus: string);
    removeApprover?(id: string);
    uploadDocument?(id: string, files?: FileList);
    downloadDocument?(approverId: string, fileId: string, fileName: string);
}

export const SingleApprover: FC<React.PropsWithChildren<ISingleApproverProps>> = ({ approver, editable, changeMandatory, changeStatus, removeApprover, uploadDocument, downloadDocument }) => {
    const intl = useIntl();
    const buttonStyles = useMemo(() => ({
        [EApproverStatus.Default]: intl.formatMessage({ id: 'norms.status.default' }),
        [EApproverStatus.WaitingForUpdate]: intl.formatMessage({ id: 'norms.status.waitingForUpdate' }),
        [EApproverStatus.Approved]: intl.formatMessage({ id: 'norms.status.approve' }),
        [EApproverStatus.Rejected]: intl.formatMessage({ id: 'norms.status.reject' })
    }), []);

    const buttonColor = useMemo(() => ({
        [EApproverStatus.Default]: colorStack.darkBlue,
        [EApproverStatus.WaitingForUpdate]: colorStack.darkOrange,
        [EApproverStatus.Approved]: colorStack.middleGreen,
        [EApproverStatus.Rejected]: colorStack.darkRed
    }), []);

    const mandatoryOptions = useMemo(() => ({
        mandatory: 'norms.mandatory',
        optional: 'norms.optional'
    }), []);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const changeMandatoryHandler = useCallback((option) => {
        changeMandatory(approver.id, !approver.isMandatory);
    }, [approver.id, approver.isMandatory]);

    const changeApproverStatus = useCallback((id: string, status: string) => {
        changeStatus(id, status);
    }, []);

    const onEdit = useCallback(() => {
        setEditMode(state => !state);
    }, []);

    const removeApproverHandler = useCallback(() => {
        removeApprover(approver.id);
    }, [approver.id]);

    const onUploadFiles = useCallback((e?: React.ChangeEvent<HTMLInputElement>) => {
        uploadDocument(approver.id, e?.target?.files);
    }, [approver.id]);

    const openPopup = useCallback(() => {
        setShowPopup(state => !state);
    }, []);

    const onDownloadFile = useCallback((attachment: IApprovalAttachment) => {
        downloadDocument(approver.id, attachment.id, attachment.fileName);
    }, [approver.id]);

    return (
        <tr>
            <td>
                <UserWrapper>
                    {editable &&
                        <>
                            {!editMode ? (
                                <PenButton onClick={onEdit}>
                                    <FontAwesomeIcon icon={faPen} />
                                </PenButton>
                            ) : (
                                <EditSection>
                                    <ActionWrapper onClick={onEdit}>
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                    </ActionWrapper>
                                    <ActionWrapper onClick={removeApproverHandler}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </ActionWrapper>
                                </EditSection>
                            )}
                        </>
                    }
                    <Avatar
                        picture={approver.picture}
                        firstName={approver.firstName}
                        lastName={approver.lastName}
                        id={approver.id}
                        extended
                        size={EAvatarSize.TINY}
                    />
                </UserWrapper>
            </td>
            <td>
                {(editMode && editable) &&
                    <SelectInput
                        value={approver.isMandatory ? ([{key: 'mandatory', text: 'mandatory', data: 'mandatory'}]) : ([{key: 'optional', text: 'optional', data: 'optional'}])}
                        onChange={(option: IValue) => changeMandatoryHandler(option.key)}
                        options={Object.keys(mandatoryOptions).map(key => ({
                            key, text: intl.formatMessage({ id: mandatoryOptions[key] }), data: key
                        }))}
                        type={EDropDownType.DEFAULT}
                    />
                }
                {!editMode &&
                    <strong>
                        {approver.isMandatory ?
                            <FormattedMessage id="norms.mandatory" />
                            :
                            <FormattedMessage id="norms.optional" />
                        }

                    </strong>
                }
            </td>
            <td>
                {editable ?
                    <DropdownMenu
                        button={<Button>{buttonStyles[approver.status]}</Button>}
                        links={Object.keys(buttonStyles).filter(key => Number(key) as EApproverStatus !== approver.status).map(value => ({
                            customRender: <Button typeSchema={EButtonTypeSchema.TERTIARY} key={value} onClick={() => changeApproverStatus(approver.id, value)}>
                                {buttonStyles[value]}
                            </Button>,
                            visible: true
                        }))}
                    />
                    :
                    <>
                        {approver.status === EApproverStatus.Approved && (
                            <Tag color="successGreen" noBorder nomargin sm>
                                <div>
                                    <FormattedMessage id="norms.status.approved" />
                                </div>
                            </Tag>
                        )}
                        {approver.status === EApproverStatus.Default && (
                            <Tag color="white" noBorder nomargin sm>
                                <div>
                                    <FormattedMessage id="norms.status.default" />
                                </div>
                            </Tag>
                        )}
                        {approver.status === EApproverStatus.Rejected && (
                            <Tag color="red" noBorder nomargin sm>
                                <div>
                                    <FormattedMessage id="norms.status.rejected" />
                                </div>
                            </Tag>
                        )}
                        {approver.status === EApproverStatus.WaitingForUpdate && (
                            <Tag color="warningYellow" noBorder nomargin sm>
                                <div>
                                    <FormattedMessage id="norms.status.waitingForUpdate" />
                                </div>
                            </Tag>
                        )}
                    </>
                }
            </td>
            <td>
                <AttachmentsWrapper>
                    <FileButton multiple onChange={onUploadFiles}>
                        <FormattedMessage id="norms.button.uploadDocument" />
                    </FileButton>
                    {approver.attachments?.length > 0 &&
                        <AttachmentSection onClick={openPopup}>
                            <FormattedMessage
                                id="norms.attachmentsCount"
                                values={{
                                    count: approver.attachments.length
                                }} />
                        </AttachmentSection>
                    }
                    <AttachmentPopup
                        cancelAction={openPopup}
                        isVisible={showPopup}
                        title={<FormattedMessage id="norms.downloadAttachments" />}
                    >
                        <AttachmentPicker>
                            {approver.attachments.map(attachment => (
                                <div key={attachment.id}>
                                    <Button typeSchema={EButtonTypeSchema.TEXT} onClick={() => onDownloadFile(attachment)} leftIco={faDownload} >
                                        {attachment.fileName}
                                    </Button>
                                </div>
                            ))}
                        </AttachmentPicker>
                    </AttachmentPopup>
                </AttachmentsWrapper>
            </td>
        </tr>
    );
};
