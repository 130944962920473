export enum EVersionStatus {
    New = 10,
    WorkInProgress = 20,
    Current = 30,
    Archived = 40
}

export interface IDocumentVersion {
    id: string;
    publicationDate: Date;
    archivedDate: Date;
    creationDate: Date;
    name: string;
    status: EVersionStatus;
}

export enum ENormativeDocumentationOperationType {
    VersionMarkedAsCurrent = 10,
    VersionArchived = 20,
    VersionCreated = 30,
    VersionAvailableForQuantum = 40,
    AllPublishedTextsGeneratedViaSFTP = 50
}

export enum ENormativeDocumentationOperationStatus {
    NotStarted = 0,
    InProgress = 1,
    Completed = 2
}

export interface IDocumentationVersionOperation {
    operationType: ENormativeDocumentationOperationType;
    operationStatus: ENormativeDocumentationOperationStatus;
    versionId: string;
    versionName: string;
}

export interface IIDocumentVersionPublishResult {
    publishedNormativeDocumentationVersion: IDocumentVersion;
    archivedNormativeDocumentationVersion: IDocumentVersion;
    operations: IDocumentationVersionOperation[];
}

export interface ISogeVersionDetails {
    normativeDocumentationName: string;
    normativeDocumentationVersionName: string;
    versionStatus: EVersionStatus;
    branchesCounter: number;
    approvalRequestCounter: number;
}
