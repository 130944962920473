import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useOrganization } from './organizationHooks';
import { ICreateNewClusterPayload, createNewCluster } from '../actions/clustersActions';
import { EProfileType } from '../entities/IGlobal';

export const getEntityLink = (id: string, organizationUrlName: string, type: EProfileType = EProfileType.Personal) => {
    const links = {
        [EProfileType.Personal]: `/profile/${id}`,
        [EProfileType.Company]: `/company/${id}`,
        [EProfileType.LegalEntity]: `/company/${id}`,
        [EProfileType.Cluster]: `/orgs/${organizationUrlName}/cluster/${id}`,
        [EProfileType.Group]: `/admin-console/${id}/settings`
    };
    return links[type] || '';
};

export type NewClusterFormValues = Pick<ICreateNewClusterPayload, 'name' | 'privacy'>;

export const useCreateNewCluster = () => {
    const dispatch = useDispatch();
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);

    return useCallback((payload: NewClusterFormValues & { clusterTemplateId: ICreateNewClusterPayload['clusterTemplateId'] }) =>
        dispatch(
            createNewCluster({
                organizationId: currentOrganization?.id,
                organizationUrlName: currentOrganization?.details?.urlName,
                organizationName: currentOrganization?.details?.name,
                ...payload
            })
        )
        , [currentOrganization]);
};
