export interface IAppConfigType {
    clientID: string;
    authority: string;
    authorityResetPassword: string;
    b2cScopes: string[];
    redirectURI: string;
}

export interface ILegalClusterConfigType {
    applicationConfig: IAppConfigType;
    apiURI: string;
    cmsApiURI: string;
    signalRUri: string;
    notificationUrl: string;
    equitisApi: string;
    organizationsApi: string;
    clustersApi: string;
    rfpApi: string;
    messagingApi: string;
    panelsApi: string;
    permissionsApi: string;
    profilesApi: string;
    validationsApi: string;
    languageApi: string;
    uriCommonApi: string;
    pdfViewerApi: string;
    searchArticlesUrl: string;
    legalDocApi: string;
    intercomAppId: string;
    environment: string;
    publishKeyStripe: string;
    premiumProductId: string;
    defaultLanguage: string;
    pushPublicKey: string;
    platform: ELegalClusterEnv;
    isLocalHost: boolean;
    isWopiIntegrationActive: boolean;
    appInsights: { instrumentationKey: string };
    webflowHomepage: { lang: string; url: string }[];
    sg?: { trackingUrl: string; trackingId: string };
    smh?: { trackingUrl: string; trackingId: string };
    isRegistrationFinalScreenActive: boolean;
}

export enum ELegalClusterEnv {
    lcProd = 'legalcluster-prod',
    lcDemo = 'legalcluster-demo',
    lcDev = 'legalcluster-dev',
    lcRec = 'legalcluster-rec'
}

export interface IInstanceConfig {
    orgContextFlag?: string;
    stonly: boolean;
    mainConfig: {
        clientName: 'default' | 'soge' | 'teams';
        clientDisplayName: string;
        clientLogo: string;
        footerOn: boolean;
    };
    homePage: { centerWorkspace: boolean; leftWorkspace: boolean; newsBox: boolean };
    leftMenu: {
        menuVisible: boolean;
        create: boolean;
        overview: boolean;
        activity: boolean;
        ecosystem: boolean;
        clusters: boolean;
        norms: boolean;
        settings: boolean;
        news: boolean;
        reporting: boolean;
        charts: boolean;
        entities: boolean;
        services: boolean;
        signature: boolean;
        campaign: boolean;
        approval: boolean;
        dates: boolean;
    };
    sidebar: { search: boolean; home: boolean };
    routes: string[];
    headerMenu: {
        menuVisible: boolean;
        logoDesktop: string;
        logoMobile: string;
        search: boolean;
        home: boolean;
        network: boolean;
        expertise: boolean;
        publications: boolean;
        notifications: boolean;
        messaging: boolean;
        profile: boolean;
        solutions: boolean;
        privacy: boolean;
        payment: boolean;
        settings: boolean;
        terms: boolean;
        privacyPolicy: boolean;
    };
    headerUserMenu: { center: boolean; plans: boolean; privacy: boolean };
    settings: { password: boolean; email: boolean; notification: boolean };
    personalSidebarMenuSections: {
        profileBoost: boolean;
        rfp: boolean;
        browse: boolean;
        collaboration: boolean;
        code: boolean;
        mobileApp: boolean;
    };
    intercom: boolean;
    redirectToWebflow: boolean;
    gtm: boolean;
    tracking: { component: string };
    enableTracking: boolean;
    enableSignalR: boolean;
    getClusters: boolean;
    getCMS: boolean;
    wopie: boolean;
    clusters: { addNewStakeButton: boolean; signature: boolean; approval: boolean };
    sg?: {
        // soge specifi config
        blockDownload?: boolean;
    };
}

export interface ITheme {
    colors: { primary: string; secondary: string };
    font: { primary: string; secondary: string };
    fontImport: string;
    normBooks?: 'colors' | 'images';
}
