import React from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const MandatoryField = styled.span`
    color: ${colorStack.darkRed};
    ${fontSizeAndHeight[13]};
`;

export const MandatoryFieldStar = () => {
    return (
        <MandatoryField>
            &nbsp;*&nbsp;
        </MandatoryField>
    );
};