import React, { FC, useCallback, useState } from 'react';
import { createContext, useMemo } from 'react';

import { removeArrDuplicates } from '../../../tools/arrayTools';

type ExpandPath = string[];

export interface ISidebarTocContext {
    expandPath: ExpandPath;
    onElementIntersection(name: string, id: string, commonIdPath: ExpandPath): void;
}

export const sidebarTocContext = createContext<ISidebarTocContext>(undefined);

export const SidebarTocProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [expandPath, setExpandPath] = useState<ExpandPath>(undefined);

    const onElementIntersection = useCallback((name: string, id: string, commonIdPath: ExpandPath) => {
        setExpandPath(removeArrDuplicates(commonIdPath));
    }, []);

    const providerValue = useMemo(() => {
        return ({
            expandPath,
            onElementIntersection
        });
    }, [expandPath, onElementIntersection]);

    return (
        <sidebarTocContext.Provider value={providerValue}>
            {children}
        </sidebarTocContext.Provider>
    );
};
