import React, { useState, useCallback, useEffect, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { DatesPopup } from '../StakeContainers/DatesPopup/DatesPopup';
import { StepsPopup } from '../../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes, IReferencial } from '../../../../../entities/IGlobal';
import { IDates } from '../../../../../entities/IDates';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { getReferentials } from '../../../../../actions/globalActions';
import { DatePickerWrapper } from '../../../../Common/DatePicker/DatePicker';

type GetReferentials = ReturnType<typeof getReferentials>;

const AllWrapper = styled.div`
    > span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

const RowWrapper = styled.div<{ blue: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div:first-child { width: 49%;}
    > button { width: 49%; margin: 0;}
    ${props => props.blue && css`
        > button {
            svg {
                color: ${colorStack.middleBlue};
            }
        }
    `}
`;

interface IProps {
    editStake: boolean;
    data: IClusterStakeProperty;
    propertieId: string;
    organizationId: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const LegalDateContent: FC<React.PropsWithChildren<IProps>> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showEventPopup, setShowEventPopup] = useState<boolean>(false);
    const [title, setTitle] = useState<IReferencial>(undefined);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [popupData, setPopupData] = useState<IDates>(undefined);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', props.data.configuration.titleReferentialIdentityType, props.data.configuration.titleReferentialIdentityContext)).then(response => {
            setTitle(response.filter(elem => elem.Key === props.data.configuration.defaultReferentialKey)?.[0]);
        });
    }, [props.data.configuration.defaultReferentialKey]);

    const changeDate = useCallback((dateProp: Date) => {
        const changeHour = dateProp ? new Date(new Date(dateProp).setHours(7)) : undefined;
        props.setEditingData({
            ...props.data,
            value: props.data?.value?.title ? ({
                ...props.data.value,
                endAt: changeHour
            }) : ({
                actors: [],
                description: '',
                isReminderActivated: false,
                // tslint:disable-next-line:no-null-keyword
                reminder: null,
                // tslint:disable-next-line:no-null-keyword
                endAt: changeHour,
                // tslint:disable-next-line:no-null-keyword
                status: null,
                // tslint:disable-next-line:no-null-keyword
                title: title,
                // tslint:disable-next-line:no-null-keyword
                type: null,
                // tslint:disable-next-line:no-null-keyword
                startAt: null,
                // tslint:disable-next-line:no-null-keyword
                recuring: null,
                // tslint:disable-next-line:no-null-keyword
                nextOccurrenceDate: null
            }),
            id: props.propertieId
        });
    }, [props.data, props.propertieId, props.setEditingData, title]);

    const changeReminder = useCallback(() => {
        setShowEventPopup(state => !state);
    }, [props.data, props.propertieId]);

    const getDataFromPopup = useCallback((data: IDates) => {
        setPopupData(data);
    }, []);

    const confirmHandler = useCallback(() => {
        return new Promise((resolve) => {
            props.setEditingData({
                ...props.data,
                value: popupData,
                id: props.propertieId
            });
            resolve(true);
            setShowEventPopup(false);
        });
    }, [props.data, popupData, props.propertieId, props.setEditingData]);

    const validationDates = useCallback((valid: boolean) => {
        setFormValid(valid);
    }, []);

    // tslint:disable-next-line:no-null-keyword
    const date = (props.data.value?.endAt || props.data.value?.recuring?.frequency?.startDate) ? new Date(props.data.value?.endAt || props.data.value?.recuring?.frequency?.startDate) : null;

    return (
        <AllWrapper>
            {props.editStake ? (
                <>
                    <RowWrapper blue={props.data.value?.isReminderActivated}>
                        <DatePickerWrapper
                            // tslint:disable-next-line:no-null-keyword
                            value={date}
                            onChange={changeDate}
                            placeholder={intl.formatMessage({ id: 'cluster.placeholder.selectDate' })}
                        />
                        <Button typeSchema={EButtonTypeSchema.TERTIARY} onClick={changeReminder} leftIco={faBell}><FormattedMessage id="dates.label.setReminder" /></Button>
                        <StepsPopup
                            showPopup={showEventPopup}
                            title={<FormattedMessage id="dates.title.popup.mainDate" />}
                            confirmDisabled={false}
                            finishHandler={changeReminder}
                            size={EPopupSizes.MEDIUM}
                            sendButtonType="button"
                            isFormValid={formValid}
                            lastButtonText={<FormattedMessage id="global.cancel" />}
                            sendButtonText={<FormattedMessage id="global.save" />}
                            confirmHandler={confirmHandler}
                            dirty
                            steps={[
                                {
                                    content: <DatesPopup
                                        hardTitle
                                        currentOrgId={props.organizationId}
                                        isValid={validationDates}
                                        getDataFromPopup={getDataFromPopup}
                                        currentDate={props.data.value ? ({
                                            ...props.data.value,
                                            title: title
                                        }) : ({
                                            actors: [],
                                            description: '',
                                            isReminderActivated: false,
                                            // tslint:disable-next-line:no-null-keyword
                                            reminder: null,
                                            // tslint:disable-next-line:no-null-keyword
                                            endAt: null,
                                            // tslint:disable-next-line:no-null-keyword
                                            startAt: null,
                                            // tslint:disable-next-line:no-null-keyword
                                            status: null,
                                            // tslint:disable-next-line:no-null-keyword
                                            title: title,
                                            // tslint:disable-next-line:no-null-keyword
                                            type: null
                                        })}
                                        configuration={{
                                            defaultDates: [],
                                            titleReferentialIdentityContext: props.data.configuration.titleReferentialIdentityContext,
                                            titleReferentialIdentityId: props.data.configuration.titleReferentialIdentityId,
                                            titleReferentialIdentityType: props.data.configuration.titleReferentialIdentityType
                                        }}
                                    />
                                }
                            ]}
                        />
                    </RowWrapper>
                </>
            ) : (
                <>
                    <span>{date && moment.utc(date).local().format('DD MMM YYYY')}</span>
                </>
            )
            }
        </AllWrapper>
    );
};