import React, { useState, DragEvent, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faTrash, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { faFileImage, faFilePdf, faFileWord, faFileExcel, faSquareQuestion, faXmark, faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { TextWithEllipsis } from '../TextWithEllipsis/TextWithEllipsis';
import { LabelWithEllipsis } from '../Label/Label';
import { ContextMenu, ISingleLink } from '../ContextMenu/ContextMenu';
import { uuidv4 } from '../../../tools/authTools';
import { useDropdown } from '../../../tools/hooks';
import { IState } from '../../../reducers';
import { IGlobalReducer } from '../../../reducers/globalReducer';

const computeHumanFriendlyFileSize = (fileSize: number) => {
    if (fileSize >= 10e8) {
        return `${(fileSize * 10e-10).toFixed(2)}GB`;
    }

    if (fileSize >= 10e5) {
        return `${(fileSize * 10e-7).toFixed(2)}MB`;
    }

    return `${Math.ceil(fileSize * 10e-4)}KB`;
};

const FileWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    margin-bottom: .5rem;
`;

const GlobalWrapper = styled.div<{ withLabel: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    ${({ withLabel }) => withLabel && css`
        margin-top: 4px;
    `}
`;

const Wrapper = styled.div<{ minHeight: boolean }>`
    display: flex;
    width: 100%;
    height: 242px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 311px;
    padding: 1rem;
    gap: 1rem;
    border: 1px dashed ${colorStack.ligthGrey};
    border-radius: 8px;
    transition: all 1s ease;
    &[aria-disabled="true"] {
        opacity: 0.4;
    }
    ${props => props.minHeight && css`
        height: auto;
    `}
`;

const MenuWrapper = styled.div<{}>`
    position: relative;
`;

const ThreeDotsMenu = styled.div<{}>`
    display: flex;
    ${fontSizeAndHeight[25]}
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
        color: ${colorStack.darkGrey};
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${colorStack.darkGrey};
`;

const Action = styled.div`
    cursor: pointer;
`;

const FileInfo = styled.div<{ withSizeOrDate: boolean, withProgress: boolean, error: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    box-sizing: content-box;
    border: 1px solid ${colorStack.ligthGrey};
    padding: 4px 8px 4px 4px;
    ${({ withSizeOrDate, withProgress }) => (withSizeOrDate || withProgress) && css`
        gap: 16px;
        padding: 16px;
    `}
    ${({error}) => error && css`
        border-color: ${colorStack.middleRed};
    `}

    &:hover {
        background-color: ${colorStack.whiteBlue};
    }

    &:focus {
        outline: 1.5px solid ${colorStack.ligthGrey};
    }
`;

const FileNameSizeAndDate = styled.div<{}>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 130px;
`;

const FileName = styled(TextWithEllipsis) <{}>`
    font-weight: 500;
    ${fontSizeAndHeight[13]}
    color: ${colorStack.content};
`;

const FileSizeAndDate = styled.div<{}>`
    color: ${colorStack.darkGrey};
    ${fontSizeAndHeight[10]}
`;

const IconWrapper = styled.div<{ color: string, withSizeOrDate: boolean }>`
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ withSizeOrDate }) => withSizeOrDate && css`
        width: 32px;
        height: 32px;
        border-radius: 8px;
    `}

    ${({ color }) => css`
        background-color: ${color};
        svg {
            ${fontSizeAndHeight[16]}
            color: ${colorStack.white};
        }
    `}
`;

const CloudAndTextWrapper = styled.div`
    ${fontSizeAndHeight[31]};
    display: inline-flex;
    align-items: center;
    height: 32px;
    gap: 12px;
`;

const TextWrapper = styled.div`
    display: block;
    display: flex;
    flex-direction: column;
    gap: 2px;
    > span {
        height: 16px;
    }
`;

const Text = styled.span<{}>`
    display: inline-flex;
    ${fontSizeAndHeight[13]}
    font-weight: 500;
`;

const HiddenBrowseButton = styled.input<{}>`
    display: none !important;
`;

const BrowseButtonLabel = styled.label<{}>`
    ${fontSizeAndHeight[16]}
    color: ${colorStack.middleBlue};
    cursor: pointer;

`;

const SubText = styled.span<{}>`
    display: block;
    ${fontSizeAndHeight[10]}
    font-weight: 400;
    color: ${colorStack.darkGrey};
`;

const UploadProgressWrapper = styled.div<{ progressPercentage: number }>`
    display: flex;
    flex-direction: column;
    width: 237px;
    color: ${colorStack.darkGrey};
    ${fontSizeAndHeight[10]}
`;

const UploadProgressSegment = styled.div<{ progressPercentage: number }>`
    position: relative;
    height: 8px;
    width: 100%;
    background-color: ${colorStack.ligthGrey};
    border-radius: 8px;
    margin: 8px 0;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        height: 8px;
        border-radius: 8px;
        background-color: ${colorStack.blue};
        ${({ progressPercentage }) => css`
            width: calc(${progressPercentage}%);
        `}
    }
`;

const UploadErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 130px;
`;

const ErrorText = styled.span`
    ${fontSizeAndHeight[10]}
    color: ${colorStack.middleRed};
`;

const DownloadIconWrapper = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export enum EUploaderFileFormat {
    DOCX = 'DOCX',
    JPEG = 'JPEG',
    JPG = 'JPG',
    PDF = 'PDF',
    PNG = 'PNG',
    XLS = 'XLS',
    XLSX = 'XLSX'
}

export interface IFileUploaderChangeEvent {
    rawFile: File;
    fileName: string;
    date: Date;
    fileId: string;
    mimeType: string;
    fileSizeInBytes: number;
    humanFriendlyFileSize: string;
}

export interface IUploderFileError {
    fileId: string;
    error: string;
}

interface ISingleFile {
    fileName: string;
    fileId: string;
    fileSize: number;
    fileDate?: Date;
}

interface IProps {
    fileFormats: EUploaderFileFormat[];
    files: ISingleFile[];
    label?: string;
    required?: boolean;
    error?: IUploderFileError[];
    threeDotsMenuActions?: ISingleLink[];
    onFileChange?(e: IFileUploaderChangeEvent): void;
    downloadAction?(e): void;
    removeAction?(id: string): void;
    cancelAction?(id: string): void;
}

export const Uploader = (props: IProps) => {
    const { progressData } = useSelector<IState, IGlobalReducer>(state => state.global);
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();
    const [proccedFile, setProccedFile] = useState<ISingleFile>(undefined);
    const [files, setFiles] = useState<ISingleFile[]>(props.files || []);
    const id = uuidv4();

    useEffect(() => {
        setFiles(props.files.reverse());
        if (props.files.filter(elem => elem.fileName === proccedFile?.fileName).length > 0) {
            setProccedFile(undefined);
        }
    }, [props.files]);

    const processFile = (fileObject: File) => {
        const fileSize = fileObject.size;
        const fileName = fileObject.name;
        const mimeType = fileObject.type;
        const date = new Date(fileObject.lastModified);
        const fileId = uuidv4();
        // remember the file as well for retrying in case it fails
        setProccedFile({ fileName, fileId, fileSize, fileDate: undefined });
        props.onFileChange?.({
            rawFile: fileObject,
            fileSizeInBytes: fileSize,
            date,
            fileName: fileName,
            mimeType,
            fileId,
            humanFriendlyFileSize: computeHumanFriendlyFileSize(fileSize)
        });
    };

    const fileFormat = (name: string) => {
        if (name) {
            const format = name.split('.').pop();
            switch (format?.toLowerCase()) {
                case 'docx':
                    return EUploaderFileFormat.DOCX;
                case 'jpg':
                    return EUploaderFileFormat.JPG;
                case 'jpeg':
                    return EUploaderFileFormat.JPEG;
                case 'pdf':
                    return EUploaderFileFormat.PDF;
                case 'png':
                    return EUploaderFileFormat.PNG;
                case 'xls':
                    return EUploaderFileFormat.XLS;
                case 'xlsx':
                    return EUploaderFileFormat.XLSX;
                default: return EUploaderFileFormat.DOCX;
            }
        }
        // tslint:disable-next-line:no-null-keyword
        return null;
    };

    const relevantFileIcon = (name: string) => {
        if (fileFormat(name) === null) {
            return faSquareQuestion;
        }

        switch (fileFormat(name)) {
            case EUploaderFileFormat.DOCX:
                return faFileWord;
            case EUploaderFileFormat.JPEG:
            case EUploaderFileFormat.JPG:
            case EUploaderFileFormat.PNG:
                return faFileImage;
            case EUploaderFileFormat.PDF:
                return faFilePdf;
            case EUploaderFileFormat.XLS:
            case EUploaderFileFormat.XLSX:
                return faFileExcel;
            default: return faFileWord;
        }
    };

    const onDragEnter = (e: DragEvent<HTMLElement>) => {
        if (!!proccedFile) {
            return;
        }
    };

    const onDragLeave = (e: DragEvent<HTMLElement>) => {
        if (!!proccedFile) {
            return;
        }
    };

    const onDrop = (e: DragEvent<HTMLElement>) => {
        e.preventDefault();

        if (!!proccedFile) {
            return;
        }

        const fileObject = e.dataTransfer.files[0];
        fileObject && processFile(fileObject);
    };

    const onDragOver = (e: DragEvent<HTMLElement>) => {
        e.preventDefault();

        if (!!proccedFile) {
            return;
        }
    };

    const onDialogUploadChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileObjectName = e.target.value;

        if (fileObjectName) {
            const fileObject = e.target.files[0];
            processFile(fileObject);
        }
    };

    const humanFriendlyFileSize = (fileSize: number) => {
        return computeHumanFriendlyFileSize(fileSize);
    };

    const acceptableFileExtensionsAttr: string = useMemo(() => {
        return props.fileFormats.map(format => `.${format.toLowerCase()}`).join(',');
    }, [props.fileFormats]);

    const iconWrapperColor = (name: string) => {
        switch (relevantFileIcon(name)) {
            case faFileExcel:
                return colorStack.green;

            case faFilePdf:
                return colorStack.red;

            case faFileImage:
                return colorStack.orange;

            case faFileWord:
                return colorStack.blue;

            case faSquareQuestion:
                return colorStack.disabled;

            default:
                return colorStack.content;
        }
    };

    const removeBadFile = (fileId: string) => {
        props.cancelAction(fileId);
        setProccedFile(undefined);
    };

    const singleFileElem = (singleFile: ISingleFile) => {
        const isProccedFile = proccedFile?.fileName === singleFile.fileName;
        return <FileInfo error={props.error?.filter(elem => elem.fileId === singleFile.fileId)?.length > 0} tabIndex={-1} withSizeOrDate={singleFile.fileSize !== undefined || !!singleFile.fileDate} withProgress={isProccedFile}>
            <IconWrapper color={iconWrapperColor(singleFile.fileName)} withSizeOrDate={singleFile.fileSize !== undefined || !singleFile.fileDate}>
                <FontAwesomeIcon icon={relevantFileIcon(singleFile.fileName)} />
            </IconWrapper>
            {props.error?.filter(elem => elem.fileId === singleFile.fileId)?.length > 0 &&
                <>
                    <UploadErrorWrapper>
                        <FileName>{singleFile.fileName}</FileName>
                        <ErrorText>{props.error?.filter(elem => elem.fileId === singleFile.fileId)?.[0]?.error || ''}</ErrorText>
                    </UploadErrorWrapper>
                    <Action onClick={() => removeBadFile(singleFile.fileId)}><FontAwesomeIcon icon={faXmark} /></Action>
                </>
            }
            {props.error?.filter(elem => elem.fileId === singleFile.fileId)?.length === 0 && (isProccedFile ?
                <UploadProgressWrapper progressPercentage={progressData}>
                    <FileName>{singleFile.fileName}</FileName>
                    <UploadProgressSegment progressPercentage={progressData} />
                    <FormattedMessage id="global.percentComplete" values={{ percentage: progressData }} />
                </UploadProgressWrapper>
                : <FileNameSizeAndDate>
                    <FileName>{singleFile.fileName}</FileName>
                    {(singleFile.fileSize || singleFile.fileDate) &&
                        <FileSizeAndDate>
                            {humanFriendlyFileSize(singleFile.fileSize)} {singleFile.fileDate && '.'} {moment.utc(singleFile.fileDate).local().format('DD MMM, YYYY')}
                        </FileSizeAndDate>
                    }
                </FileNameSizeAndDate>
            )}
            <ActionsWrapper>
                {props.error?.filter(elem => elem.fileId === singleFile.fileId)?.length === 0 && isProccedFile && props.cancelAction &&
                    <Action onClick={() => props.cancelAction(singleFile.fileId)}><FontAwesomeIcon icon={faXmark} /></Action>
                }
                {props.error?.filter(elem => elem.fileId === singleFile.fileId)?.length === 0 && singleFile.fileId && props.downloadAction &&
                    <Action onClick={(e) => props.downloadAction(e)}><DownloadIconWrapper><FontAwesomeIcon icon={faTrash} /></DownloadIconWrapper></Action>
                }
                {props.error?.filter(elem => elem.fileId === singleFile.fileId)?.length === 0 && singleFile.fileId && props.removeAction &&
                    <Action onClick={() => props.removeAction(singleFile.fileId)}><FontAwesomeIcon icon={faTrash} /></Action>
                }
            </ActionsWrapper>
        </FileInfo>;
    };

    return (
        <>
            {props.label && (
                <LabelWithEllipsis required={props.required}>
                    {props.label}
                </LabelWithEllipsis>
            )}
            <GlobalWrapper withLabel={!!props.label}>
                <FileWrapper>
                    {proccedFile && singleFileElem(proccedFile)}
                    {files?.map(elem => singleFileElem(elem))}
                </FileWrapper>
                <Wrapper
                    minHeight={files.length > 0 || !!proccedFile}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    aria-disabled={!!proccedFile}
                >
                    <CloudAndTextWrapper>
                        <FontAwesomeIcon icon={faCloud} />
                    </CloudAndTextWrapper>
                    <TextWrapper>
                        <form>
                            <Text>
                                <FormattedMessage id="upload.dragAndDropFilesOr" />&nbsp;
                                <HiddenBrowseButton id={id} type="file" disabled={!!proccedFile} accept={acceptableFileExtensionsAttr} onChange={proccedFile ? undefined : onDialogUploadChoice} />
                                <BrowseButtonLabel htmlFor={id}>
                                    <FormattedMessage id="upload.browse" />
                                </BrowseButtonLabel>
                            </Text>
                        </form>
                        <SubText><FormattedMessage id="upload.supportedFormats" />&nbsp;{props.fileFormats.join(', ')}</SubText>
                    </TextWrapper>
                    {props.threeDotsMenuActions?.length > 0 &&
                        <MenuWrapper ref={wrapperRef}>
                            <ThreeDotsMenu onClick={toggleDropdown}><FontAwesomeIcon icon={faEllipsis} scale={1.5} /></ThreeDotsMenu>
                            {dropdownOpen && <ContextMenu links={props.threeDotsMenuActions.map(link => ({
                                ...link,
                                action: () => { toggleDropdown(); link.action(); }
                            }))} />}
                        </MenuWrapper>
                    }
                </Wrapper>
            </GlobalWrapper>
        </>
    );
};
