import React, { useEffect, useState, useCallback, FC } from 'react';
import styled from 'styled-components';

import { ISearchLegalEntitiesValue } from '../../../../../entities/ILegalEntities';
import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { ErrorText } from './StakeStyles';
import { Modify } from '../../../../../tools/generalTools';
import { IValue } from '../../../../../entities/IPickers';
import { EProfileType } from '../../../../../entities/IGlobal';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';

const Wrapper = styled.div`
    > span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

type IMultipleLegalEntityContent = Modify<IClusterStakeProperty, {
    value: ISearchLegalEntitiesValue[];
}>;

interface IProps {
    data: IMultipleLegalEntityContent;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IMultipleLegalEntityContent);
}

export const MultipleLegalEntityContent: FC<React.PropsWithChildren<IProps>> = ({ error, data, editStake, propertieId, setEditingData }) => {
    const [content, setContent] = useState<IValue[]>(undefined);

    useEffect(() => {
        data?.value?.length > 0 && setContent(data?.value?.map(elem => ({
            key: elem.id,
            text: elem.name,
            data: elem
        })));
    }, [data, editStake]);

    const setFieldValue = useCallback((value: IValue[]) => {
        setContent(value);
        setEditingData({
            ...data,
            value: value.map(elem => elem.data),
            id: propertieId
        });
    }, [propertieId, data, setEditingData]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <LegalEntitiesPicker
                        value={content}
                        onSelectElementMulti={setFieldValue}
                        multiSelect
                        allCompanies
                    />
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                    <>
                        {!!content?.length && content.map(entity =>
                            <ProfilesSummary
                                key={entity?.data?.id}
                                type={EProfileType.Company}
                                profile={{
                                    picture: entity?.data?.Picture,
                                    firstName: entity?.data?.Name || entity?.data?.name,
                                    id: entity?.data?.id
                                }}
                            />
                        )}
                    </>
                )}
        </Wrapper>
    );
};
