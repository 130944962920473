import * as actionTypes from '../actions/actionTypes/workTypes';
import { IWorkTypes } from '../actions/actionTypes/workTypes';
import { IClusterTemplateFacet, ISingleWorkElem } from '../entities/IWork';

export interface IWorkReducer {
    readonly workResult: ISingleWorkElem[];
    readonly templates: IClusterTemplateFacet[];
    readonly itemsCount: number;

}

const defaultState = (): IWorkReducer => ({
    workResult: [],
    templates: [],
    itemsCount: 0
});

export default (state = defaultState(), action): IWorkReducer => {
    switch (action.type) {
        case actionTypes.GET_WORKS_ELEMENTS: {
            const payload: IWorkTypes['GET_WORKS_ELEMENTS'] = action.data;
            return {
                ...state,
                workResult: payload.workResult,
                templates: payload.templates,
                itemsCount: payload.itemsCount
            };
        }
        default: {
            return state;
        }

    }
};
