import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { EMenuItem } from '../../entities/IMenu';

export const UPDATE_MENU_TITLE = 'UPDATE_MENU_TITLE';

export interface IMenuTypes {
    UPDATE_MENU_TITLE: {
        menuItem: EMenuItem;
        withHeader?: boolean;
        icon?: IconDefinition;
    };
}
