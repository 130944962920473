import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { EStakeDetailsSubType } from '../../../../../entities/IClusterStake';
import { DEFAULT_CURRENCY_KEY, EContextList, EReferentials, IReferencial, IViewReferential } from '../../../../../entities/IGlobal';
import { getReferentials } from '../../../../../actions/globalActions';
import { numberFormatter } from '../../../../../tools/numberTools';
import { EProfileDataType } from '../../../../../tools/profileConstants';
import { ETextComponentSubType, TextComponent } from '../../../../Common/Inputs/TextComponent';
import { IValue } from '../../../../../entities/IPickers';
import { NewDesignDropdown } from '../../../../Common/CustomDropdown/NewDesignDropdown';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { PercentageContent } from '../../../../Common/PercentageContent/PercentageContent';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    > div:first-child {
        width: 100%;
    }
`;

export interface INumberComponentValue {
    default: {
        number: number;
        unit: IViewReferential;
    };
    current: {
        number: number;
        unit: IViewReferential;
    };
}

interface IProps {
    value: INumberComponentValue;
    unitsConfig?: {
        type: EProfileDataType | EReferentials;
        context: EContextList;
    };
    useOnlyBase?: boolean;
    editMode: boolean;
    error?: {
        errorCode: number;
        message: string;
    };
    disabled: boolean;
    percentageString?: boolean;
    subType: EStakeDetailsSubType
    precision?: number;
    onChange(value: string, currency: IValue);
}

export const NumberComponent: FC<IProps> = props => {
    const dispatch = useDispatch();
    const [allUnits, setAllUnits] = useState<IReferencial[]>([]);
    const defaultCurrencyRef = useMemo(() => allUnits.find(elem => elem.Key === DEFAULT_CURRENCY_KEY), [allUnits]);

    useEffect(() => {
        if (props.unitsConfig.type && props.unitsConfig.context && allUnits.length === 0) {
            dispatch<GetReferentials>(getReferentials('', props.unitsConfig.type, props.unitsConfig.context)).then(res => {
                setAllUnits(res);
                if (res.length === 1) {
                    changeUnit({
                        key: res[0]?.Key, text: res[0]?.name, data: {
                            key: res[0]?.id,
                            parentId: res[0]?.referentialIdentityId,
                            uniqueKey: res[0]?.Key
                        }
                    });
                }
            });
        }
    }, [props.unitsConfig]);

    useEffect(() => {
        if (!props.value.current.unit && !props.value.default.unit && defaultCurrencyRef) {
            changeUnit({
                key: defaultCurrencyRef.Key,
                text: defaultCurrencyRef.name,
                data: {
                    key: defaultCurrencyRef.id,
                    parentId: defaultCurrencyRef.referentialIdentityId,
                    uniqueKey: defaultCurrencyRef.Key
                }
            });
        }
    }, [props.value?.current?.number, defaultCurrencyRef]);

    const changeNumber = (value: string, currency: IValue) => {
        const regex = /^\d*\.?(?:\d{1,6})?$/g;
        if (regex.test(value) || value === '') {
            if (props.subType === EStakeDetailsSubType.Percentage && value && parseInt(value, 10) > 100) {
                props.onChange('100', currency);
            } else {
                props.onChange(value, currency);
            }
        }
    };

    const changeUnit = (currency: IValue) => {
        // tslint:disable-next-line:no-null-keyword
        props.onChange(props.value?.current?.number ? props.value?.current?.number?.toString() : null, currency);
    };

    const getSubType = (type: EStakeDetailsSubType) => {
        if (type === EStakeDetailsSubType.Currency) return ETextComponentSubType.CURRENCY;
        if (type === EStakeDetailsSubType.Percentage) return ETextComponentSubType.PERCENTAGE;
        return undefined;
    };

    return (
        <Wrapper>
            {props.editMode ? (
                <>
                    <TextComponent
                        value={props.value?.current?.number !== null ? props.value?.current?.number?.toString() : props.value?.default?.number?.toString()}
                        disabled={props.disabled}
                        subType={getSubType(props.subType)}
                        onChange={changeNumber}
                        currencies={allUnits?.sort((a, b) => a.name.localeCompare(b.name))}
                        selectedCurrency={
                            ((props.value.current?.unit?.key || props.value.default?.unit?.key) && allUnits)
                                ?
                                {
                                    key: props.value.current.unit?.key.toString() || props.value.default.unit?.key.toString(),
                                    text: allUnits?.find(elem => elem.id === props.value.current?.unit?.key)?.name || allUnits?.find(elem => elem.id === props.value.default.unit?.key)?.name,
                                    data: props.value.current.unit || props.value.default.unit
                                }
                                :
                                undefined
                        }
                    />
                    {(props.subType !== EStakeDetailsSubType.Currency && props.subType !== EStakeDetailsSubType.Percentage) && (
                        <>
                            {allUnits?.length > 0 &&
                                <NewDesignDropdown
                                    value={(props.value.current?.unit?.key && allUnits) ? [{ key: props.value.current.unit?.key.toString(), text: allUnits?.find(elem => (elem.Key === props.value.current.unit.key || elem.id === props.value.current.unit.key))?.name, data: props.value.current.unit }] : undefined}
                                    dropdownWidth={180}
                                    disabled={props.disabled}
                                    onChange={(option: IValue) => changeUnit(option)}
                                    options={allUnits?.sort((a, b) => a.name.localeCompare(b.name))?.map(elem => ({
                                        key: elem.id,
                                        text: elem.name,
                                        data: elem
                                    }))}
                                    type={EDropDownType.SEARCH}
                                />
                            }
                        </>

                    )
                    }
                </>
            ) : (
                <>
                    {props.value?.current.number !== null &&
                        <>
                            {props.subType === EStakeDetailsSubType.Percentage ? (
                                <>
                                    {props.percentageString ? (
                                        <span>{props.value?.current.number} %</span>
                                    ) : (
                                        <PercentageContent value={Number(props.value?.current.number) || 0} />
                                    )}
                                </>
                            ) : (
                                <span>{props.value?.current?.number !== null && numberFormatter(props.useOnlyBase ? props.value?.default?.number : Number(props.value?.current?.number)?.toFixed(props.precision || 2))} {(allUnits && props.value?.current?.unit) && allUnits.find(elem => elem.Key === (props.useOnlyBase ? props.value?.default?.unit.uniqueKey : props.value?.current?.unit.uniqueKey))?.name}</span>
                            )}
                        </>
                    }
                </>
            )}
        </Wrapper>
    );
};