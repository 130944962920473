import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { EStakeDetailsType, IClusterStakeProperty, EStakeDetailsSubType } from '../../../../../entities/IClusterStake';
import { media } from '../../../../../styleHelpers/breakpoint';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { SetPropertiesIcon } from '../StakeComponents/SetPropertiesIcon';
import { TextContent } from '../StakeComponents/TextContent';
import { BooleanContent } from '../StakeComponents/BooleanContent';
import { DateContent } from '../StakeComponents/DateContent';
import { LegalDateContent } from '../StakeComponents/LegalDateContent';
import { SingleSelectContent } from '../StakeComponents/SingleSelectContent';
import { MultiSelectContent } from '../StakeComponents/MultiSelectContent';
import { LegalEntityContent } from '../StakeComponents/LegalEntityContent';
import { NewDesignLegalEntityContent } from '../StakeComponents/NewDesignLegalEntityContent';
import { MultipleLegalEntityContent } from '../StakeComponents/MultipleLegalEntityContent';
import { PeopleContent } from '../StakeComponents/PeopleContent';
import { NewDesignPeopleContent } from '../StakeComponents/NewDesignPeopleContent';
import { MultiplePeopleContent } from '../StakeComponents/MultiplePeopleContent';
import { AttachmentContent } from '../StakeComponents/AttachmentContent';
import { PartyContent } from '../StakeComponents/PartyContent';
import { AddressContent } from '../StakeComponents/AddressContent';
import { NumberAndSelect } from '../StakeComponents/NumberAndSelectContent';
import { CheckboxContent } from '../StakeComponents/CheckboxContent';
import { useNewDesignContext } from '../../../../NewDesignCommon/NewDesignSwitch/NewDesignSwitch';
import { newDesignThemeSwitch } from '../../../../NewDesignCommon/NewDesignSwitch/newDesignTheme';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { EnumSelectContent } from '../StakeComponents/EnumSelectContent';
import { InvolvedPartiesContent } from '../StakeComponents/InvolvedPartiesContent';
import { NumberComponent } from '../StakeComponents/Number';
import { IValue } from '../../../../../entities/IPickers';

const RowLeft = styled.div`
    display: flex;
    font-size: ${fontSize[13]};
    color: ${colorStack.disabled};
    width: 100%;
    font-weight: 500;
    margin: 0 0 1rem 0;
    ${media.desktop`
        width: 25%;
        margin: 0;
    `};
    span {
        margin: 0 0 0 1rem;
    }
    svg {
        width: 16px!important;
    }
`;

const RowRight = styled.div<{ withMargin?: boolean }>`
    word-wrap: break-word;
    width: 100%;
    ${media.desktop`
        width: 75%;
    `};
    ${(props) => newDesignThemeSwitch(props, () =>
    css`
            margin-top: ${props.withMargin ? '6px' : '0'};
            ${media.desktop`
                width: 100%;
            `};
        `
)}
`;

interface IStakeRowContentProps {
    stakeDefinitionId: string;
    propertie: IClusterStakeProperty;
    editStake: boolean;
    errors: any // because we receive different objects;
    context: 'PLE' | 'Cluster';
    stakeOptions?: {
        peopleContent?: {
            fullWidth?: boolean;
        }
    };
    stakeListName?: string;
    currentClusterId?: string;
    organizationId?: string;
    base?: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export enum EItemNameById {
    Name = 'referencedItem.referencedItem.name',
    WebSite = 'referencedItem.referencedItem.websiteUrl',
    PhoneNumber = 'referencedItem.referencedItem.phoneNumber'
}

const checkFieldTitle = (data: IStakeRowContentProps) => {
    const canEditField = data.propertie.id === EItemNameById.Name ? false : data.editStake;
    return canEditField;
};

export const StakeCollection: FC<React.PropsWithChildren<IStakeRowContentProps>> = (props) => {
    const newDesign = useNewDesignContext();
    const withMargin = newDesign.active && ![
        EStakeDetailsType.LegalEntity?.toLocaleLowerCase(),
        EStakeDetailsType.People?.toLocaleLowerCase()
    ].includes(props.propertie?.type?.toLocaleLowerCase());

    const getSubType = (type: EStakeDetailsSubType) => {
        if (type === EStakeDetailsSubType.Currency) return EStakeDetailsSubType.Currency;
        if (type === EStakeDetailsSubType.Percentage) return EStakeDetailsSubType.Percentage;
        return undefined;
    };

    const changeNumberValue = (value: string, currency: IValue) => {
        const regex = /^\d*\.?(?:\d{1,6})?$/g;
        const referentialIdentity = props.propertie.configuration?.referentialIdentity;
        if (regex.test(value) || value === '') {
            const valueIdentity = referentialIdentity === 'Custom' ?
                // tslint:disable-next-line:no-null-keyword
                parseInt(value, 10) : ({ number: (value !== '' ? value.replace(' ', '') : null), unit: currency ? { key: currency.data.id || currency.data.key, parentId: currency.data.referentialIdentityId || currency.data.parentId, uniqueKey: currency.data.Key || currency.data.uniqueKey } : null });
            props.setEditingData({
                ...props.propertie,
                value: props.propertie.type === EStakeDetailsType.NumberAndRisk ? {
                    riskValue: props.propertie.value?.riskValue,
                    numericValue: valueIdentity
                } : valueIdentity,
                id: props.propertie.id
            });
        }
        if (value === null && currency?.key) {
            // tslint:disable-next-line:no-null-keyword
            const valueIdentity = ({ number: null, unit: currency ? { key: currency.data.id || currency.data.key, parentId: currency.data.referentialIdentityId || currency.data.parentId, uniqueKey: currency.data.Key || currency.data.uniqueKey } : null });
            props.setEditingData({
                ...props.propertie,
                value: props.propertie.type === EStakeDetailsType.NumberAndRisk ? {
                    riskValue: props.propertie.value?.riskValue,
                    numericValue: valueIdentity
                } : valueIdentity,
                id: props.propertie.id
            });
        }
    };

    return (
        <>
            <a id={props.propertie.id} />
            {!newDesign.active &&
                <RowLeft>
                    <SetPropertiesIcon type={props.propertie?.customIcon || props.propertie?.subType || props.propertie.type} />
                    <span>{props.propertie?.name}</span>
                </RowLeft>
            }
            {newDesign.active && <LabelWithEllipsis>{props.propertie?.name}</LabelWithEllipsis>}
            <RowRight withMargin={withMargin}>
                {(props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Number.toLocaleLowerCase() || props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.NumberAndRisk.toLocaleLowerCase()) &&
                    <>
                        {(props.propertie?.value?.number > 0 || props.propertie.isEditable) &&
                            <NumberComponent
                                editMode={props.editStake && props.propertie.isEditable !== false}
                                disabled={false}
                                percentageString
                                unitsConfig={{
                                    type: props.propertie.configuration?.availableUnits?.referentialType || props.propertie.configuration?.availableUnits?.ReferentialType,
                                    context: props.propertie.configuration?.availableUnits?.referentialContext || props.propertie.configuration?.availableUnits?.ReferentialContext
                                }}
                                value={{
                                    default: {
                                        number: props.propertie.value?.baseNumber || props.propertie.value?.numericValue?.baseNumber || props.propertie.configuration?.value?.baseNumber,
                                        unit: props.propertie.value?.baseUnit || props.propertie.value?.numericValue?.baseUnit || props.propertie.configuration?.value?.baseUnit
                                    },
                                    current: {
                                        number: props.propertie.value?.number || props.propertie.value?.numericValue?.number || props.propertie.configuration?.value?.number,
                                        unit: props.propertie.value?.unit || props.propertie.value?.numericValue?.unit || props.propertie.configuration?.value?.unit
                                    }
                                }}
                                subType={getSubType(props.propertie.subType)}
                                precision={props.propertie?.configuration?.precision}
                                onChange={changeNumberValue}
                            />
                        }
                    </>
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Text.toLocaleLowerCase() &&
                    <TextContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={checkFieldTitle(props)}
                        setEditingData={props.setEditingData}
                        simple={props.propertie.subType === EStakeDetailsSubType.Simple}
                    />
                }
                {(props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Boolean.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() === EStakeDetailsType.CheckboxBoolean.toLocaleLowerCase()) &&
                    <CheckboxContent
                        data={props.propertie}
                        propertieId={props.propertie.id}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? props?.errors : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Boolean.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() !== EStakeDetailsType.CheckboxBoolean.toLocaleLowerCase() &&
                    <BooleanContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Date.toLocaleLowerCase() &&
                    <DateContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.LegalDate.toLocaleLowerCase() &&
                    <LegalDateContent
                        propertieId={props.propertie.id}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        organizationId={props.organizationId}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Attachment.toLocaleLowerCase() &&
                    <AttachmentContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        stakeDefinitionId={props.stakeDefinitionId}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.MultipleAttachments.toLocaleLowerCase() &&
                    <AttachmentContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? props?.errors : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        multipleAttachment={true}
                        stakeDefinitionId={props.stakeDefinitionId}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.LegalEntity.toLocaleLowerCase() && !newDesign.active &&
                    <LegalEntityContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? props?.errors : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.propertie?.subType?.toLocaleLowerCase() !== EStakeDetailsSubType.Subsidiary.toLocaleLowerCase() ? 'Cluster' : props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.MultipleLegalEntity.toLocaleLowerCase() &&
                    <MultipleLegalEntityContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.People.toLocaleLowerCase() && !newDesign.active &&
                    <PeopleContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                        stakeOptions={props.stakeOptions?.peopleContent}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.MultiplePeople.toLocaleLowerCase() &&
                    <MultiplePeopleContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.SingleSelect.toLocaleLowerCase() &&
                    <SingleSelectContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={{ ...props.propertie, value: props.propertie.value?.key ? { key: props.propertie.value.key, text: props.propertie.value.value, data: props.propertie } : undefined }}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.MultipleSelect.toLocaleLowerCase() &&
                    <MultiSelectContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Party.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() !== EStakeDetailsType.Cluster.toLocaleLowerCase() &&
                    <PartyContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.MultipleParty.toLocaleLowerCase() &&
                    <PartyContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Address.toLocaleLowerCase() &&
                    <AddressContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.NumberAndSelect.toLocaleLowerCase() &&
                    <NumberAndSelect
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.LegalEntity.toLocaleLowerCase() && newDesign.active &&
                    <NewDesignLegalEntityContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.People.toLocaleLowerCase() && newDesign.active &&
                    <NewDesignPeopleContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                        stakeOptions={props.stakeOptions?.peopleContent}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Enum.toLocaleLowerCase() &&
                    <EnumSelectContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Party.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() === EStakeDetailsType.Cluster.toLocaleLowerCase() && newDesign.active &&
                    <InvolvedPartiesContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        organizationId={props.organizationId}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === EStakeDetailsType.Cluster.toLocaleLowerCase() &&
                    <InvolvedPartiesContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || (props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : []))?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        organizationId={props.organizationId}
                        setEditingData={props.setEditingData}
                    />
                }
            </RowRight>
        </>
    );
};
