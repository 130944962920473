import React, { FC, useEffect, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { IState } from '../../../reducers';
import { IAvatarReducer } from '../../../reducers/avatarReducer';
import { setUserColor } from '../../../actions/avatarActions';

type SetUserColor = ReturnType<typeof setUserColor>;

const Wrapper = styled.div<{ size?: { width: number, height: number }, bigView: boolean }>`
    position: relative;
    border-radius: 8px;
    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ${props => props.bigView && css`
        height: 190px;
    `}
`;

const CurrentItem = styled.img<{ size?: { width: number, height: number }, bigView: boolean }>`
    border-radius: 8px;
    height: 32px;
    width: 32px;
    border: 1px solid ${colorStack.white};
    ${props => props.size && css`
        height: ${`${props.size.height - 2}px`};
        width: ${`${props.size.width - 2}px`};
    `}
    ${props => props.bigView && css`
        height: 100%;
        width: 100%;
        border: none;
        display: block;
        object-fit: cover;
        border-radius: 0;
    `}
`;

const TextWrapper = styled.div<{ bgColor: string; size?: { width: number, height: number }, bigView: boolean }>`
    ${props => props.bgColor && css`
        background: ${props.bgColor};
    `}
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    ${props => props.bigView && css`
        height: 100%;
        width: 100%;
        border-radius: 0;
    `}
    > div {
        margin: auto 0;
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
        font-weight: 500;
        ${props => props.bigView && css`
            font-size: ${fontSize[31]};
    `}
    }
`;

// there is a similar component 'Avatar.tsx'. Carefully choose the one you need.

interface IProps {
    picture: string;
    name: string;
    id: string;
    bigView?: boolean;
    size?: {
        width: 20 | 28 | 32 | 42 | 64;
        height: 20 | 28 | 32 | 42 | 64;
    };
}

export const WorkspaceLogo: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const [initials, setInitials] = useState<string>(undefined);

    const { usersColor } = useSelector<IState, IAvatarReducer>(state => state.avatar);

    const selectInitials = props.name?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').split(' ').map(item => item.toUpperCase().substring(0, 1)).join('');

    useEffect(() => {
        if (!props.picture) {
            dispatch<SetUserColor>(setUserColor(props.id));
            setInitials(props.bigView ? selectInitials : selectInitials.substring(0, 2));
        }
    }, [props?.bigView, props?.picture, selectInitials]);

    return (
        <Wrapper size={{ width: props.size?.width || 32, height: props.size?.height || 32 }} bigView={props.bigView}>
            {props.picture ? (
                <CurrentItem src={props.picture} alt={props.name} size={{ width: props.size?.width || 32, height: props.size?.height || 32 }} bigView={props.bigView} />
            ) : (
                    <TextWrapper
                        bgColor={usersColor?.[props.id]}
                        size={{ width: props.size?.width || 32, height: props.size?.height || 32 }}
                        bigView={props.bigView}
                    >
                        <div>
                            {initials}
                        </div>
                    </TextWrapper>
                )}
        </Wrapper >
    );
};
