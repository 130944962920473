import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    ${fontSizeAndHeight[13]};
    font-weight: 500;
    color: ${colorStack.disabled};
    text-align: center;
`;

export const NoData: FC<React.PropsWithChildren<unknown>> = props => {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
};