import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';

const LinkButton = styled(Link) <{ $active?: boolean }>`
    position: relative;
    text-decoration: none;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    ${props => props.$active && css`
        border-radius: 4px;
        background: ${colorStack.white};
    `}
    span, svg {
        font-size: ${fontSize[16]};
        color: ${colorStack.darkGrey};
        stroke: ${colorStack.disabled};
        fill: ${colorStack.disabled};
        text-decoration: none;
        ${props => props.$active && css`
            color: ${colorStack.middleBlue};
            text-decoration: none;
            stroke: ${colorStack.middleBlue};
            fill: ${colorStack.middleBlue};
        `}
    }
`;

export const MenuItemLabel = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    span {
        width: 130px;
        text-align: left;
    }
`;

interface IProps {
    to: string;
    active: boolean;
    className?: string;
    dataLc?: string;
}

export const MenuItem: FC<React.PropsWithChildren<IProps>> = ({ to, active, children, className, dataLc }) => (
    <LinkButton data-lc={dataLc} to={to} $active={active} className={className}>
        <MenuItemLabel>{children}</MenuItemLabel>
    </LinkButton>
);
