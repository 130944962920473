import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

export const TableSpacer = styled.td`
    hr {
        margin: 0.35rem 0;
    }
`;

interface ITableProps {
    shrink?: number[];
    colNoWrap?: number[];
    noBorder?: boolean;
    center?: boolean;
    noWrap?: boolean;
    borderSpacing?: 'none' | 'small' | 'normal';
    useGroups?: boolean;
    oddColor?: string;
    evenColor?: string;
    headerBgColor?: string;
    customBorderSpacing?: boolean;
}

const borderSpacingToNumber = {
    none: 0,
    small: .25,
    normal: .65
};

const Table = styled.table<ITableProps>`
    width: 100%;
    border-collapse: separate;
    ${props => css`
        border-spacing: 0 ${borderSpacingToNumber[props.borderSpacing || 'normal']}rem;
    `}
    text-align: left;
    ${props => !props.customBorderSpacing && css`
        margin: 1rem 0;
    `}
    font-size: ${fontSize[13]};

    th {
        padding: 0 1rem;
        ${props => props.borderSpacing === 'none' && !props.customBorderSpacing && css`
            padding-bottom: 0.5rem;
        `}
    }

    th, td {
        vertical-align: middle;
        ${props => props.noWrap && css`
            white-space: nowrap;
        `}
        ${props => props.center && css`
            text-align: center;
        `}
    }

    td {
        padding: 0 1rem;
        ${props => !props.noBorder && css`
            padding: 0.75rem 1rem;
        `}
        ${props => !props.useGroups && !props.noBorder && css`
            border-top: 1px solid ${colorStack.ligthGrey};
            border-bottom: 1px solid ${colorStack.ligthGrey};
        `}
    }

    ${({ headerBgColor }) => headerBgColor && css`
        thead, thead th {
            background-color: ${headerBgColor};
        }
    `}

    tbody tr {
        td {
            ${props => !props.noBorder && css`
                &:first-child {
                    border-left: 1px solid ${colorStack.ligthGrey};
                    ${!props.useGroups && css`
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    `}
                }
                &:last-child {
                    border-right: 1px solid ${colorStack.ligthGrey};
                    ${!props.useGroups && css`
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    `}
                }
            `}
            ${props => (props.shrink || []).map(col => css`
                &:nth-child(${col}) {
                    white-space: nowrap;
                    width: 1px;
                }
            `)}
            ${props => (props.colNoWrap || []).map(col => css`
                &:nth-child(${col}) {
                    white-space: nowrap;
                }
            `)}
        }
        ${TableSpacer} {
            border: 0;
            padding: 0.5rem 0;
            &:first-child, &:last-child {
                border: 0;
            }
        }

        ${({ oddColor }) => oddColor && css`
            &:nth-child(odd) {
                background-color: ${oddColor};
            }
        `}
        ${({ evenColor }) => evenColor && css`
            &:nth-child(even) {
                background-color: ${evenColor};
            }
        `}
    }
`;

interface IGroupRowProps {
    isFirst?: boolean;
    isLast?: boolean;
}

export const GroupRow = styled.tr<IGroupRowProps>`
    td {
        border-top: 0;
        border-bottom: 0;
        border-radius: 0;
        ${props => props.isFirst && css`
            border-top: 1px solid ${colorStack.ligthGrey};
            &:first-child {
                border-top-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
            }
        `}
        ${props => props.isLast && css`
            border-bottom: 1px solid ${colorStack.ligthGrey};
            &:first-child {
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-bottom-right-radius: 4px;
            }
        `}
    }
`;

interface IEllipsisCellProps {
    maxWidth?: number;
    rows?: number;
}

export const EllipsisCell = styled.div<IEllipsisCellProps>`
    max-width: ${({ maxWidth }) => maxWidth ?? 300}px;
    overflow: hidden;
    position: relative;
    max-height: ${({ rows }) => (rows ?? 2) * 1.2}em;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: ${colorStack.white};
    }
`;

interface ITableCoulumn {
    id?: string | number;
    shrink?: boolean;
    header: ReactNode;
    noWrap?: boolean;
}

interface ISgTableProps {
    columns: ITableCoulumn[];
    noBorder?: boolean;
    className?: string;
    center?: boolean;
    noWrap?: boolean;
    borderSpacing?: 'none' | 'small' | 'normal';
    useGroups?: boolean;
    oddColor?: string;
    evenColor?: string;
    headerBgColor?: string;
    customBorderSpacing?: boolean;
}

export const SgTable: FC<React.PropsWithChildren<ISgTableProps>> = ({ columns, children, noBorder, className, noWrap, center, borderSpacing, useGroups, oddColor, evenColor, headerBgColor, customBorderSpacing }) => {
    const shrink = columns.reduce((shrinkVar, col, index) => col.shrink ? [...shrinkVar, index + 1] : shrinkVar, []);
    const colNoWrap: number[] = columns.reduce((noWrapVar, col, index) => col.noWrap ? [...noWrapVar, index + 1] : noWrapVar, []);

    return (
        <Table shrink={shrink} colNoWrap={colNoWrap} noBorder={noBorder} noWrap={noWrap} center={center} className={className || ''} borderSpacing={borderSpacing} useGroups={useGroups} oddColor={oddColor} evenColor={evenColor} headerBgColor={headerBgColor} customBorderSpacing={customBorderSpacing}>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={column.id ?? index}>
                            {column.header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </Table>
    );
};