/*
    USE:

    ${boxShadow()}

*/
import { rgba } from 'polished';

import { Styles } from '../types/style';
import { colorStack } from '../colors';

const boxShadow = (spread?: number): Styles => {
    const spreadString = typeof spread === 'number' ? `${spread}px ` : '';
    return {
        boxShadow: `0px 3px 3px ${rgba(colorStack.darkBlue, .05)}, 0px 2px 2px ${rgba(colorStack.darkBlue, .07)}, 0px 1px 1px ${spreadString}${rgba(colorStack.darkBlue, .1)}`
    };
};
// 0px .3px .9px ${rgba(colorStack.black, .07)}, 0px 1.6px 3.6px ${rgba(colorStack.black, .11)};
export const newDesignBoxShadow = (): Styles => {
    return {
        boxShadow: `0px .3px .9px ${rgba(colorStack.black, .07)}, 0px 1.6px 3.6px ${rgba(colorStack.black, .11)}`
    };
};

export const inputsShadow = (): Styles => {
    return {
        boxShadow: `inset 0px 1px 6px ${rgba(colorStack.black, .08)}`
    };
};

export const sectionsShadow = (): Styles => {
    return {
        boxShadow: `none`
    };
};

export const headersShadow = (): Styles => {
    return {
        boxShadow: `0px 1px 6px ${rgba(colorStack.black, .08)}`
    };
};

export const cardsShadow = (): Styles => {
    return {
        boxShadow: `0px 4px 12px ${rgba(colorStack.black, .16)}`
    };
};

export const modalsShadow = (): Styles => {
    return {
        boxShadow: `0px 8px 32px ${rgba(colorStack.black, .2)}`
    };
};

export const newDesignBoxShadowString = (): string => `0px 4px 12px ${rgba(colorStack.black, .16)}`;

export default boxShadow;