import React, { useState, useCallback, FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { createNewCluster } from '../../../../actions/clustersActions';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { ETemplateTypes, ISingleCluster } from '../../../../entities/IClusters';
import { initialFormValues } from './InitialFormValues';
import { IContractData } from './IContractData';
import { Confirm } from './Steps/Confirm';
import { useOrganization } from '../../../../tools/organizationHooks';

type CreateNewCluster = ReturnType<typeof createNewCluster>;

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setIsContractPopupOn(showContract: boolean);
}
export const ContractPopup: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useNavigate();
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [newClusterData, setNewClusterData] = useState<ISingleCluster>(undefined);
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);

    const closePopup = useCallback((resetForm) => {
        resetForm();
        props.setIsContractPopupOn(false);
        setStepFinished(false);
        if (props.isHome) {
            history('/');
        } else {
            newClusterData?.id && history(`/orgs/${props.organizationUrlName}/cluster/${newClusterData.id}`);
        }
    }, [props.organizationUrlName, newClusterData, props.isHome]);

    const onSubmit = useCallback((values: IContractData) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<CreateNewCluster>(createNewCluster({
            organizationId: props.organizationId,
            name: values.formData.name,
            description: '',
            privacy: values.formData.privacy,
            organizationUrlName: props.organizationUrlName,
            organizationName: currentOrganization?.details?.name,
            clusterTemplateId: values.formData.cluster.clusterTemplateId
        })).then(response => {
            setIsConfirmButtonDisabled(false);
            setStepFinished(true);
            setNewClusterData(response);
        });
    }, [dispatch, setIsConfirmButtonDisabled, props.organizationId]);

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, []);

    return (
        <Formik<IContractData> initialValues={initialFormValues()}
            validationSchema={() => Yup.lazy((values: any) => {
                const stepNr = values.step;
                return Yup.object().shape({
                    formData: Yup.object().shape({
                        cluster: Yup.object({
                            clusterTemplateKey: Yup.string().required()
                        }),
                        name: stepNr === 2 && Yup.string().required().max(100),
                        privacy: stepNr === 2 && Yup.string().required()
                    })
                });
            })}
            onSubmit={onSubmit}>
            {({ isValid, dirty, submitForm, resetForm }) => (
                <StepsPopup
                    showPopup={props.isPopupShown}
                    size={EPopupSizes.MEDIUM}
                    title={intl.formatMessage({ id: 'contract.title' })}
                    isFormValid={isValid}
                    dirty={dirty}
                    preventClosingOnClickingOutside
                    confirmDisabled={isConfirmButtonDisabled}
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    wizardType={ETemplateTypes.Contract}
                    clearForm={() => clearForm(resetForm)}
                    confirmHandler={submitForm}
                    steps={[
                        {
                            title: intl.formatMessage({ id: 'contract.popup.step1.title' }),
                            content: <Step1 organizationId={props.organizationId} />
                        },
                        {
                            title: intl.formatMessage({ id: 'contract.popup.step2.title' }),
                            content: <Step2 organizationId={props.organizationId} />
                        },
                        {
                            content: <Confirm />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};