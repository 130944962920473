import React from 'react';
import styled, { css } from 'styled-components';

import { ESortTypes } from '../../../entities/AssetManagement/global';
import { IColumnSettings } from '../../../entities/IClusters';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { HeaderCell } from './HeaderCell';
import { EColumnType } from '../../../entities/IDatagrid';

const Wrapper = styled.thead<{ hasColoredColumn: boolean; whiteHeader: boolean }>`
    tr {
        th {
            position: sticky;
            top: 0;
            background: ${colorStack.bodyBg};
            z-index: 9;
            padding-inline-end: 0.5rem;
            padding-block-end: 0.25rem;
            color: ${colorStack.label};
            ${fontSizeAndHeight[13]};
            font-weight: 500;
            padding: 8px 8px 0 0;
            text-align: left;
            vertical-align: bottom;
            ${({ whiteHeader }) => whiteHeader && css`
                background: ${colorStack.white};
            `}
            ${({ hasColoredColumn }) => hasColoredColumn && css`
                &:first-child {
                    width: 8px;
                    min-width: 8px;
                    max-width: 8px;
                    padding: 8px 0;
                }
            `}
        }
    }
`;

const TableHead = styled.th<{ resizable: boolean }>`
    box-sizing: border-box;
    ${({ resizable }) => resizable && css`
        resize: horizontal;
        overflow: hidden;
    `}
`;

interface IHeaderProps {
    data: IColumnSettings[];
    directions?: Record<string, ESortTypes>;
    hasColoredColumn?: boolean;
    whiteHeader?: boolean;
    setDirection(order: ESortTypes, field: string);
}

export const Header = (props: IHeaderProps) => {

    const createSizesData = (data: IColumnSettings) => {
        let width: number | string = 'auto';
        let maxWidth: number | string = 'auto';
        let minWidth: number | string = 'auto';
        let finalType = `${data.type}${data.subType || ''}` as EColumnType;

        switch (finalType) {
            case EColumnType.date:
            case EColumnType.dateAgo:
                maxWidth = 112;
                minWidth = 112;
                width = 112;
                break;
            case EColumnType.number:
                maxWidth = 56;
                width = 56;
                break;
            case EColumnType.NUMBER_HASH:
            case EColumnType.NUMBER_ORDER:
                maxWidth = 72;
                width = 72;
                break;
            case EColumnType.extendedAvatar:
                minWidth = 232;
                break;
            case EColumnType.hyperlink:
            case EColumnType.HYPERLINK_MESSAGES:
            case EColumnType.HYPERLINK_DOCUMENTS:
            case EColumnType.HYPERLINK_APPROVAL:
                minWidth = 112;
                break;
            case EColumnType.overview:
            case EColumnType.OVERVIEW_CLUSTER:
            case EColumnType.OVERVIEW_ORGANIZATION:
                minWidth = 384;
                break;
            case EColumnType.people:
                width = 112;
                minWidth = 112;
                break;
            case EColumnType.enum:
                width = 92;
                minWidth = 92;
                break;
            case EColumnType.ENUM_STATUS:
                width = 192;
                minWidth = 192;
                break;
            case EColumnType.boolean:
                width = 56;
                minWidth = 56;
                break;
            case EColumnType.clusterTemplate:
                width = 72;
                minWidth = 72;
                break;
            case EColumnType.pill:
            case EColumnType.referentials:
                width = 176;
                minWidth = 176;
                break;
            case EColumnType.party:
            case EColumnType.person:
                width = 192;
                minWidth = 192;
                break;
            case EColumnType.text:
                width = 384;
                minWidth = 384;
                break;
            case EColumnType.dropdown:
                width = 232;
                minWidth = 232;
                break;
            case EColumnType.universalSwitcher:
                width = 0;
                minWidth = 'fit-content';
                break;
            case EColumnType.button:
                minWidth = 'fit-content';
                width = 0;
                break;
            case EColumnType.lastEditedBy:
            case EColumnType.LAST_EDITED_BY_RELATIVE_DATE:
                width = 200;
                minWidth = 200;
                break;
            default:
                width = 'auto';
                maxWidth = 'auto';
                minWidth = 'auto';
                break;
        }

        return ({
            width, maxWidth, minWidth
        });
    };

    return (
        <Wrapper hasColoredColumn={props.hasColoredColumn} whiteHeader={props.whiteHeader}>
            <tr>
                {props.hasColoredColumn && <th />}
                {props.data?.map((elem) => {
                    return (
                        <TableHead key={elem.field} style={createSizesData(elem)} resizable={elem.resizable} >
                            <HeaderCell
                                onSort={(direction) => props.setDirection(direction, elem.field)}
                                direction={props.directions?.[elem.field]}
                                headerText={elem.headerText}
                            />
                        </TableHead>);
                })}
            </tr>
        </Wrapper>
    );
};
