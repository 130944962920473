import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

export enum EIconButtonTypeSchema {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    TERTIARY = 'TERTIARY'
}
export enum EIconButtonSize {
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL'
}
export enum EIconButtonShape {
    SQUARE = 'SQUARE',
    CIRCLE = 'CIRCLE'
}
export enum EIconButtonColorSchema {
    NEUTRAL = 'NEUTRAL',
    ALERT = 'ALERT',
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS'
}

const Wrapper = styled.button<{ shape: EIconButtonShape, size: EIconButtonSize, typeSchema: EIconButtonTypeSchema, disabled: boolean, colorSchema: EIconButtonColorSchema, outlined: boolean }>`
    border-radius: 8px;
    border: 2px solid;
    font-size: 1rem;
    border-color: ${colorStack.middleBlue};
    background: ${colorStack.middleBlue};
    color: ${colorStack.white};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    ${({ shape }) => shape === EIconButtonShape.CIRCLE && css`
        border-radius: 100%;
    `}
    ${({ outlined }) => outlined && css`
        background: ${colorStack.white};
        color: ${colorStack.darkBlue};
        border-color: ${colorStack.middleBlue};
    `};
    ${({ size }) => size === EIconButtonSize.MEDIUM && css`
        width: 40px;
        height: 40px;
    `};
    ${({ size, outlined }) => size === EIconButtonSize.LARGE && css`
        ${fontSizeAndHeight[25]};
        width: 56px;
        height: 56px;
        font-size: 24px;
        ${outlined && css`
            color: ${colorStack.middleBlue};
        `};
    `};
    ${({ colorSchema, outlined, size }) => colorSchema === EIconButtonColorSchema.ALERT && css`
        border-color: ${colorStack.middleRed};
        background: ${colorStack.middleRed};
        color: ${colorStack.white};
        ${outlined && css`
            color: ${colorStack.darkBlue};
            background: ${colorStack.white};
            ${size === EIconButtonSize.MEDIUM && css`
                color: ${colorStack.middleRed};
            `}
        `};
    `};
    ${({ colorSchema, outlined, size }) => colorSchema === EIconButtonColorSchema.WARNING && css`
        border-color: ${colorStack.middleOrange};
        background: ${colorStack.middleOrange};
        color: ${colorStack.white};
        ${outlined && css`
            color: ${colorStack.darkBlue};
            background: ${colorStack.white};
            ${size === EIconButtonSize.MEDIUM && css`
                color: ${colorStack.middleOrange};
            `}
        `};
    `};
    ${({ colorSchema, outlined, size }) => colorSchema === EIconButtonColorSchema.SUCCESS && css`
        border-color: ${colorStack.middleGreen};
        background: ${colorStack.middleGreen};
        color: ${colorStack.white};
        ${outlined && css`
            color: ${colorStack.darkBlue};
            background: ${colorStack.white};
            ${size === EIconButtonSize.MEDIUM && css`
                color: ${colorStack.middleGreen};
            `}
        `};
    `};
    ${({ typeSchema, colorSchema, outlined }) => typeSchema === EIconButtonTypeSchema.SECONDARY && css`
        color: ${colorStack.darkBlue};
        background: ${colorStack.lightBlue};
        border-color: ${colorStack.lightBlue};
        ${outlined && css`
            border-color: ${colorStack.ligthGrey};
            background: ${colorStack.white};
            color: ${colorStack.middleBlue};
        `};
        ${colorSchema === EIconButtonColorSchema.ALERT && css`
            border-color: ${colorStack.lightRed};
            background: ${colorStack.lightRed};
            color: ${colorStack.darkRed};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleRed};
            `};
        `};
        ${colorSchema === EIconButtonColorSchema.WARNING && css`
            border-color: ${colorStack.lightOrange};
            background: ${colorStack.lightOrange};
            color: ${colorStack.darkOrange};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleOrange};
            `};
        `};
        ${colorSchema === EIconButtonColorSchema.SUCCESS && css`
            border-color: ${colorStack.lightGreen};
            background: ${colorStack.lightGreen};
            color: ${colorStack.darkGreen};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleGreen};
            `};
        `};
    `};
    ${({ typeSchema, colorSchema, outlined }) => typeSchema === EIconButtonTypeSchema.TERTIARY && css`
        color: ${colorStack.middleBlue};
        background: ${colorStack.bodyBg};
        border-color: ${colorStack.bodyBg};
        ${outlined && css`
            border-color: ${colorStack.ligthGrey};
            background: ${colorStack.white};
            color: ${colorStack.middleBlue};
        `};
        ${colorSchema === EIconButtonColorSchema.ALERT && css`
            border-color: ${colorStack.bodyBg};
            background: ${colorStack.bodyBg};
            color: ${colorStack.middleRed};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleRed};
            `};
        `};
        ${colorSchema === EIconButtonColorSchema.WARNING && css`
            border-color: ${colorStack.bodyBg};
            background: ${colorStack.bodyBg};
            color: ${colorStack.middleOrange};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleOrange};
            `};
        `};
        ${colorSchema === EIconButtonColorSchema.SUCCESS && css`
            border-color: ${colorStack.bodyBg};
            background: ${colorStack.bodyBg};
            color: ${colorStack.middleGreen};
            ${outlined && css`
                background: ${colorStack.white};
                border-color: ${colorStack.ligthGrey};
                color: ${colorStack.middleGreen};
            `};
        `};
    `};
    ${({ disabled, typeSchema }) => disabled && css`
        cursor: default;
        color: ${colorStack.disabled};
        background: ${colorStack.ligthGrey};
        border-color: ${colorStack.ligthGrey};
    `};
`;

interface IProps {
    icon: IconDefinition;
    typeSchema?: EIconButtonTypeSchema;
    size?: EIconButtonSize;
    colorSchema?: EIconButtonColorSchema;
    disabled?: boolean;
    shape?: EIconButtonShape;
    outlined?: boolean;
    className?: string;
    onClick?(e: React.MouseEvent<HTMLElement>);
}

export const IconButton: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper
            disabled={props.disabled}
            outlined={props.outlined}
            colorSchema={props.colorSchema || EIconButtonColorSchema.NEUTRAL}
            onClick={props.disabled ? undefined : props.onClick}
            type="button"
            shape={props.shape || EIconButtonShape.SQUARE}
            size={props.size || EIconButtonSize.SMALL}
            typeSchema={props.typeSchema || EIconButtonTypeSchema.PRIMARY}
            className={props.className}
        >
            <FontAwesomeIcon icon={props.icon} />
        </Wrapper>
    );
};