import styled from 'styled-components';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';

export const RequiredInformation = styled.div`
    margin: 2rem 0 0 0;
`;

export const Title = styled.div`
    color: ${colorStack.content};
    margin: 0 0 2rem 0;
    font-weight: 600;
    sup {
        color: ${colorStack.red};
    }
`;

export const Info = styled.div`
    background: ${colorStack.whiteBlue};
    border-radius: 4px;
    border: 1px solid ${colorStack.middleBlue};
    color: ${colorStack.darkBlue};
    padding: .5rem 1rem;
    width: 100%;
    font-size: ${fontSize[13]};
    svg {
        color: ${colorStack.middleBlue};
        margin: 0 1rem 0 0;
    }
`;

export const GrayInfo = styled.div`
    background: ${colorStack.ligthGrey};
    border-radius: 4px;
    border: 1px solid ${colorStack.middleGrey};
    color: ${colorStack.darkBlue};
    padding: 1.5rem 2rem;
    width: 100%;
    font-size: ${fontSize[10]};
    margin-top: 25px;
`;
