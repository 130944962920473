import React, { FC, useEffect, useMemo, useRef } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import browserLanguage from 'in-browser-language';
import moment from 'moment';

import { IState } from './reducers';
import { IContextReducer } from './reducers/contextReducer';
import { ILanguageReducer } from './reducers/languageReducer';
import { getLanguages, setUserLanguage, getTranslationsForCurrentLang, getUserPrefferedLanguage } from './actions/languageActions';
import { IConfigReducer } from './reducers/configReducer';
import { ELcid } from './entities/ILanguage';
import { languageList } from './tools/languageTools';
import { isPageWithoutAutorization } from './constants/routes';

type GetUserPrefferedLanguage = ReturnType<typeof getUserPrefferedLanguage>;
type GetLanguages = ReturnType<typeof getLanguages>;
type SetUserLanguage = ReturnType<typeof setUserLanguage>;
type GetTranslationsForCurrentLang = ReturnType<typeof getTranslationsForCurrentLang>;

export const IntlProviderComponent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const dispatch = useDispatch();
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const didMountRef = useRef(false);
    const { isLoggedIn, userLanguage, appConfig, translations } = useSelector<IState, IContextReducer & ILanguageReducer & IConfigReducer>(state => ({
        ...state.context,
        ...state.language,
        ...state.config
    }));

    const browserUserLanguage = useMemo(() => {
        return browserLanguage.pick(['fr', 'en', 'pl', 'es', 'it', 'uk', 'ru', 'de', 'pt'], appConfig.defaultLanguage);
    }, []); // no need for dependencies, we want browserUserLanguage to be picked just once, and appConfig needs to be guaranteed to be already loaded here

    useEffect(() => {
        if (params.get('lcid')) {
            setLanguage(Number(params.get('lcid')));
        } else if (didMountRef.current) {
            if (isLoggedIn) {
                dispatch<GetUserPrefferedLanguage>(getUserPrefferedLanguage()).then(res => {
                    if (res > 0) {
                        setLanguage(res);
                    } else {
                        initLanguages();
                    }
                });
            } else {
                initLanguages();
            }
        }
        if (isPageWithoutAutorization()) {
            initLanguages();
        }
        didMountRef.current = true;
    }, [isLoggedIn]);

    const setLanguage = (lcid: number) => {
        moment.locale(languageList[lcid]);
        dispatch<SetUserLanguage>(setUserLanguage(Number(lcid)));
        dispatch<GetTranslationsForCurrentLang>(getTranslationsForCurrentLang(Number(lcid)));
    };

    const initLanguages = () => {
        dispatch<GetLanguages>(getLanguages()).then(managedLanguages => {
            const selectedLang = managedLanguages?.find(language => language.Code === browserUserLanguage);
            const selectedLcid = selectedLang?.Lcid || ELcid.Fr;
            setLanguage(selectedLcid);
        });
    };

    const messages = useMemo(() => translations[userLanguage], [translations, userLanguage]);
    const locale = useMemo(() => languageList[userLanguage || ELcid.En], [userLanguage]);

    return (
        <IntlProvider locale={locale} messages={messages || {}} defaultRichTextElements={{
            br: (...chunks) => '<br />',
            span: (...chunks) => `<span>${chunks}</span>`,
            em: (...chunks) => `<em>${chunks}</em>`,
            li: (...chunks) => `<li>${chunks}</li>`,
            b: (...chunks) => `<b>${chunks}</b>`,
            i: (...chunks) => `<i>${chunks}</i>`,
            ul: (...chunks) => `<ul>${chunks}</ul>`,
            strong: (...chunks) => `<strong>${chunks}</strong>`
        }}>
            {children}
        </IntlProvider>
    );
};

export default IntlProviderComponent;