import React, { FC, useEffect, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { IState } from '../../../reducers';
import { IConfigReducer } from '../../../reducers/configReducer';
import { IContextReducer } from '../../../reducers/contextReducer';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { VStyle } from '../../../entities/LegalDoc/ISogeStyles';
import { multiplyPt } from '../../../tools/legalDocTools/sogeContentTools';
import { ESpinnerSize, Spinner } from '../../../components/Common/Spinner/Spinner';

interface IImgProps {
    vStyle?: VStyle;
}

const Img = styled.img<IImgProps>`
    ${({ vStyle }) => vStyle?.width && css`
        width: ${multiplyPt(vStyle.width)};
        max-width: 100%;
    `}
    ${({ vStyle }) => (!vStyle?.width && vStyle?.height) && css`
        height: ${multiplyPt(vStyle.height)};
    `}
`;

interface IContentImageProps {
    articleId: string;
    architextVersionId: string;
    fileId: string;
    className?: string;
    currentVersionId: string;
    vStyle?: VStyle;
    fallback?: ReactNode;
}

export const ContentImage: FC<React.PropsWithChildren<IContentImageProps>> = ({ articleId, architextVersionId, fileId, className, currentVersionId, vStyle, fallback }) => {
    const [imgUrl, setImgUrl] = useState<string>(undefined);
    const { token, subKey, legalDocApiUrl, documentationId } = useSelector<IState, ISogeReducer & IConfigReducer & IContextReducer>(state => ({
        ...state.context,
        ...state.config,
        ...state.soge
    }));

    useEffect(() => {
        fileId && fetch(`${legalDocApiUrl}/normative-documentations/${documentationId}/versions/${currentVersionId}/articles/${articleId}/versions/${architextVersionId}/files/${fileId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Ocp-Apim-Subscription-Key': subKey,
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (response.ok) {
                return response.blob();
            } else {
                throw new Error();
            }
        }).then(blob => {
            const reader = new FileReader();
            reader.onload = () => setImgUrl(reader.result as string);
            reader.readAsDataURL(blob);
        }).catch(() => {
            setImgUrl('');
        });

        !fileId && setImgUrl('');
    }, [articleId, architextVersionId, fileId, legalDocApiUrl, subKey, token]);

    return (
        <>
            {typeof imgUrl === 'undefined' && <Spinner size={ESpinnerSize.SMALL} />}
            {!!imgUrl && (
                <Img src={imgUrl} className={className} vStyle={vStyle} />
            )}
            {!imgUrl && typeof imgUrl !== 'undefined' && <span>{fallback}</span>}
        </>
    );
};
