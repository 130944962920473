import React, { useState, useCallback, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { IState } from '../../../../reducers';
import { IProfileReducer } from '../../../../reducers/profileReducer';
import { ISponsoringData } from '../../../../entities/ActionData/ISponsoringData';
import { ETemplateTypes, ISingleCluster } from '../../../../entities/IClusters';
import { createSponsoringCluster } from '../../../../actions/clustersActions';
import { initialFormValues } from './InitialFormValues';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { Step4 } from './Steps/Step4';
import { Confirm } from './Steps/Confirm';
import { useAlert } from '../../../../tools/hooks';
import { EAlertType } from '../../../../entities/IAlert';

type PostSponsoringWizard = ReturnType<typeof createSponsoringCluster>;

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    organizationName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowSponsoringWizard(showSponsoringWizard: boolean);
}

export const SponsoringPopup: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const intl = useIntl();
    const addAlert = useAlert();
    const { currentUserProfile } = useSelector<IState, IProfileReducer>((state) => state.profile);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [clusterData, setClusterData] = useState<ISingleCluster>(undefined);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);

    const closePopup = useCallback((resetForm: any) => {
        resetForm();
        props.setShowSponsoringWizard(false);
        setStepFinished(false);
        if (isFormFinnished) {
            clusterData?.id && history(`/orgs/${props.organizationUrlName}/cluster/${clusterData.id}/overview`);
            setIsFormFinnished(false);
        } else if (props.isHome) {
            history('/');
        }
    }, [props.organizationUrlName, clusterData, isFormFinnished]);

    const submitFormData = useCallback((form: ISponsoringData) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<PostSponsoringWizard>(createSponsoringCluster(props.organizationId, form)).then(res => {
            setIsConfirmButtonDisabled(false);
            setStepFinished(true);
            setClusterData(res);
            setIsFormFinnished(true);
            addAlert(<FormattedMessage id="sponsoring.popup.success" />, EAlertType.SUCCESS);
        })
            .catch((reason) => {
                addAlert(<span>{reason.data.message}</span>, EAlertType.ERROR);
            });
    }, [dispatch, setIsConfirmButtonDisabled, props.organizationId]);

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, []);

    return (
        <Formik<ISponsoringData>
            initialValues={initialFormValues(currentUserProfile)}
            validationSchema={() => Yup.lazy((values: any) => {
                const stepNr = values.step;
                return Yup.object().shape({
                    readPolicy: stepNr === 2 && Yup.boolean().oneOf([true]),
                    formData: Yup.object().shape({
                        templateKey: Yup.string().required(),
                        declarationName: stepNr === 3 && Yup.string().required().max(100),
                        information: Yup.object({
                            creator: Yup.object({
                                id: stepNr === 4 && Yup.string().required()
                            }),
                            jobTitle: stepNr === 4 && Yup.string(),
                            legalEntity: Yup.object({
                                id: stepNr === 4 && Yup.string().required()
                            }),
                            manager: Yup.object({
                                id: stepNr === 4 && Yup.string().required()
                            })
                        })
                    })
                });
            })}
            onSubmit={submitFormData}
        >
            {({ isValid, dirty, submitForm, resetForm }) => (
                <StepsPopup
                    showPopup={props.isPopupShown}
                    size={EPopupSizes.MEDIUM}
                    title={intl.formatMessage({ id: 'sponsoring.popup.title' })}
                    lastButtonText={intl.formatMessage({ id: 'sponsoring.popup.lastButtonText' })}
                    confirmDisabled={isConfirmButtonDisabled}
                    isFormValid={isValid}
                    preventClosingOnClickingOutside
                    dirty={dirty}
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    confirmHandler={submitForm}
                    clearForm={() => clearForm(resetForm)}
                    wizardType={ETemplateTypes.Sponsoring}
                    steps={[
                        {
                            title: intl.formatMessage({
                                id: 'sponsoring.popup.step1.title'
                            }),
                            content: <Step1 organizationId={props.organizationId} />
                        },
                        {
                            content: <Step2 />
                        },
                        {
                            title: intl.formatMessage({
                                id: 'sponsoring.popup.step3.title'
                            }),
                            content: <Step3 organizationId={props.organizationId} />
                        },
                        {
                            title: intl.formatMessage({
                                id: 'sponsoring.popup.step4.title'
                            }),
                            content: <Step4 />
                        },
                        {
                            content: <Confirm clusterName={clusterData?.presentation?.name} />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};
