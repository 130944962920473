import styled, { css } from 'styled-components';
import { ESogeBullet } from '../../../entities/ISoge';
import { colorStack } from '../../../styleHelpers/colors';

interface ISgListProps {
    bullet?: string;
}

export const SgList = styled.ul<ISgListProps>`
    margin-left: 1.5rem;
    word-break: normal;

    li {
        position: relative;
        margin: 0.5rem 0;
    }

    ${props => !!props.bullet && css`
        li:before {
            margin-left: -1.5rem;
            position: absolute;
            ${(props.bullet || '').charCodeAt(0) === ESogeBullet.Square ? css`
                content: '';
                width: 7px;
                height: 7px;
                background: ${colorStack.disabled};
                top: 6px;
            ` : css`
                content: '${props.bullet}';
            `}
        }
    `}
`;
