import React, { FC, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IClusterSingleDynamicStake, EStakeContainerType, IClusterStakeProperty, EPropertieKey } from '../../../../../entities/IClusterStake';
import { downloadAttachment } from '../../../../../actions/stakeActions';
import { IState } from '../../../../../reducers';
import { IClusterReducer } from '../../../../../reducers/clustersReducer';
import { arrayToObject } from '../../../../../tools/arrayTools';
import { DonorLayout } from './LayoutTemplates/DonorLayout';
import { BeneficiaryLayout } from './LayoutTemplates/BeneficiaryLayout';
import { PartiesLayout } from './LayoutTemplates/PartiesLayout';

type DownloadAttachment = ReturnType<typeof downloadAttachment>;

interface IPartyContainerProps {
    stake: IClusterSingleDynamicStake;
}

interface IPropertyByKey {
    propertyKey: {
        [key in EPropertieKey]?: IClusterStakeProperty;
    }
}

export const PartyContainer: FC<React.PropsWithChildren<IPartyContainerProps>> = ({ stake }) => {
    const dispatch = useDispatch();
    const { currentClusterId } = useSelector<IState, IClusterReducer>(state => state.clusters);
    const property: IPropertyByKey = useMemo(() => {
        return ({
            propertyKey: arrayToObject((stake.properties || []), propertie => propertie.key)
        });
    }, [stake.properties]);

    const downloadHandler = useCallback(() => {
        dispatch<DownloadAttachment>(downloadAttachment(currentClusterId, 'Overview Files', property.propertyKey?.attachments?.value?.name)).then(response => {
            response?.directUri && (window.location.href = response?.directUri);
        });
    }, [property]);

    return (
        <>
            {(stake.type === EStakeContainerType.InvitationSecondParty
                || stake.type === EStakeContainerType.GiftSecondParty
            ) &&
                <DonorLayout
                    stake={stake}
                    propertyByKey={property?.propertyKey}
                    downloadHandler={downloadHandler}
                />
            }
            {stake.type === EStakeContainerType.GiftCreator &&
                <BeneficiaryLayout
                    stake={stake}
                    propertyByKey={property?.propertyKey}
                    downloadHandler={downloadHandler}
                />
            }
            {(stake.type === EStakeContainerType.LegalEntityParty
                || stake.type === EStakeContainerType.GiftParty
                || stake.type === EStakeContainerType.UserParty
                || stake.type === EStakeContainerType.ContractingParties
                || stake.type === EStakeContainerType.InvolvedParty
                || stake.type === EStakeContainerType.LeaseParties
            ) &&
                <PartiesLayout
                    stake={stake}
                    propertyByKey={property?.propertyKey}
                    downloadHandler={downloadHandler}
                />
            }
        </>
    );
};