import { get } from 'lodash';
import moment from 'moment';

import * as actionTypes from '../actions/actionTypes/rfpTypes';
import { IExpertiseDto } from '../entities/Expertise/IExpertiseDto';
import { ICountry } from '../entities/ICountry';
import { IBusinessSectorEntity, IFullUserProfile, IJuridiction } from '../entities/IProfile';
import { ECategoryStatus, IFinancialCategory, IFinancialsForm } from '../entities/RFP/IFinancialsForm';
import { IPersonaliseMessage } from '../entities/RFP/IPersonaliseMessage';
import { IProposalList } from '../entities/RFP/IProposalList';
import { IRfpCompany } from '../entities/RFP/IRfpCompany';
import { IExtraRequirement, IMilestone, IRfpAttachment } from '../entities/RFP/IRfpExtra';
import { IRFPList } from '../entities/RFP/IRFPList';
import { IUserInformation } from '../entities/RFP/IUserInformation';

export interface ILocation extends ICountry {
    barAdmissionsList: IJuridiction[];
    name?: string;
}

export interface IRfpReducer {
    currentRfpId?: string;
    loadRfpRequested?: boolean;
    saveRfpRequested?: boolean;
    organizationId?: string;
    rfpLoaded?: boolean;
    title?: string;
    description?: string;
    companyDetails?: IRfpCompany;
    expectedAction?: string;
    describeScope?: boolean;
    selectedExpertises?: IExpertiseDto[];
    selectedLocations?: ILocation[];
    behalfOfProporsal?: 'myself' | 'mycompany' | 'thirdparty';
    missionStartDate?: Date;
    missionEndDate?: Date;
    financialsForm?: IFinancialsForm;
    showAlternativeFee?: boolean;
    expectProporsalDate?: Date;
    awardProporsalDate?: Date;
    displayedCategories?: string[];
    businessSectors?: IBusinessSectorEntity[];
    selectedLawyers?: IFullUserProfile[];
    milestonesList?: IMilestone[];
    showRecomendation?: boolean;
    personaliseMessageList?: IPersonaliseMessage[];
    extraRequirements?: IExtraRequirement[];
    issuedRFPs?: IRFPList[];
    recivedProposals?: IProposalList[];
    loadingIssuedRFP?: boolean;
    loadingRecivedProposals?: boolean;
    currentRfpOwner?: IUserInformation, currentRfpSubmitting?: boolean;
    rfpStatus?: string;
    declareFeeArragements?: boolean;
    followMyPolicy?: boolean;
    companyPolicyComment?: string;
    proposalsExpectedBy?: Date;
    proposalsAwardedBy?: Date;
    attachments?: IRfpAttachment[];
    rfpIsUploadingFile?: boolean;
    rfpUploadingFileName?: string;
    rfpUploadingFileProgress?: number;
    rfpCurrentlyDownloadingFiles?: { [fileId: string]: boolean; };
    isSimpleRfp?: boolean;
}

const defaultState = (): IRfpReducer => ({
    currentRfpId: undefined,
    organizationId: undefined,
    isSimpleRfp: false,
    loadRfpRequested: false,
    rfpLoaded: false,
    title: '',
    description: '',
    companyDetails: undefined,
    expectedAction: '',
    rfpStatus: 'Draft',
    describeScope: false,
    selectedExpertises: [],
    selectedLocations: [],
    behalfOfProporsal: 'myself',
    financialsForm: {
        hourlyBilling: { status: ECategoryStatus.Accepted } as IFinancialCategory,
        contingentFee: { status: ECategoryStatus.Accepted } as IFinancialCategory,
        percentageFee: { status: ECategoryStatus.Accepted } as IFinancialCategory,
        fixedFee: { status: ECategoryStatus.Accepted } as IFinancialCategory,
        retainer: { status: ECategoryStatus.Accepted } as IFinancialCategory,
        courtAwardFee: { status: ECategoryStatus.Accepted } as IFinancialCategory,
        valueBilling: { status: ECategoryStatus.Accepted } as IFinancialCategory,
        taskBased: { status: ECategoryStatus.Accepted } as IFinancialCategory
    } as IFinancialsForm,
    declareFeeArragements: true,
    showAlternativeFee: false,
    missionStartDate: undefined,
    missionEndDate: undefined,
    expectProporsalDate: undefined,
    awardProporsalDate: undefined,
    displayedCategories: [
        'hourlyBilling', 'contingentFee', 'percentageFee', 'fixedFee',
        'retainer', 'courtAwardFee', 'valueBilling', 'taskBased'
    ],
    businessSectors: [],
    selectedLawyers: [
        { id: undefined } as IFullUserProfile,
        { id: undefined } as IFullUserProfile, { id: undefined } as IFullUserProfile
    ],
    milestonesList: [{ message: '', expectedDate: new Date() } as IMilestone],
    showRecomendation: false,
    personaliseMessageList: [],
    extraRequirements: [],
    issuedRFPs: [],
    recivedProposals: [],
    loadingIssuedRFP: true,
    loadingRecivedProposals: true,
    followMyPolicy: false,
    companyPolicyComment: '',
    proposalsExpectedBy: moment(new Date()).add(1, 'day').toDate(),
    proposalsAwardedBy: moment(new Date()).add(1, 'day').toDate(),
    attachments: [],
    rfpCurrentlyDownloadingFiles: {}
});

const loadSelectedLawyer = (action: any) => {
    const selectedLawyers =
        (get(action, 'recipients', []) ||
            []).map(recipient => ({
                firstName: recipient.firstName,
                lastName: recipient.lastName,
                id: recipient.usedId,
                emailContact: recipient.userEmail,
                picture: recipient.picture,
                cabinet: {
                    id: get(recipient, 'cabinet.id'),
                    name: get(recipient, 'cabinet.name')
                },
                notation: recipient.notation
            } as IFullUserProfile));

    const defaultList = [
        { id: undefined } as IFullUserProfile,
        { id: undefined } as IFullUserProfile, { id: undefined } as IFullUserProfile
    ].slice(selectedLawyers.length);

    return [...selectedLawyers, ...defaultList];
};

const getLocations = (action: any) => {
    const barAdmissionsList =
        (get(action, 'barAdmissions') ||
            []).map(bar => ({ Name: bar.name, id: bar.id } as IJuridiction));
    return (get(action, 'coutries') ||
        [{ id: undefined, barAdmissionsList: [] } as ILocation])
        .map(country => ({
            name: country.name,
            id: country.id,
            barAdmissionsList: barAdmissionsList
        } as ILocation));
};

const getAllExpertises = (action: any) => {
    const expertises =
        (get(action, 'expertises') || [
            { id: undefined, competencesList: [] } as IExpertiseDto
        ]).map(expertise => ({
            name: expertise.name,
            id: expertise.id,
            competencesList: (expertise.competencesList ||
                []).map(competence => ({
                    name: competence.name,
                    id: competence.id
                }))
        }));
    return expertises.length > 0 ?
        expertises :
        [{ id: undefined, competencesList: [] } as IExpertiseDto];
};

export default (state = defaultState(), action): IRfpReducer => {
    switch (action.type) {
        case actionTypes.RFP_STATE_UPDATE: {
            return { ...state, ...action.state as IRfpReducer };
        }

        case actionTypes.RFP_SAVE_REQUESTED: {
            return { ...state, saveRfpRequested: true };
        }

        case actionTypes.RFP_SAVE_COMPLETED: {
            return {
                ...state,
                rfpLoaded: true,
                currentRfpId: action.rfpId,
                saveRfpRequested: false
            };
        }

        case actionTypes.RFP_SAVE_FAILED: {
            return { ...state, saveRfpRequested: false };
        }

        case actionTypes.RFP_LOAD_REQUESTED: {
            return { ...state, loadRfpRequested: true };
        }

        case actionTypes.RFP_LOAD_COMPLETED: {
            return {
                ...state,
                loadRfpRequested: false,
                rfpLoaded: true,
                isSimpleRfp: get(action, 'isSimpleRfp'),
                currentRfpId: get(action, 'id'),
                description: get(action, 'briefDescription'),
                title: get(action, 'title'),
                expectedAction: get(action, 'problemStatement'),
                behalfOfProporsal: get(action, 'individual') === true ?
                    'myself' :
                    get(action, 'onBehalfOfThirdParty') === true ?
                        'thirdparty' :
                        'mycompany',
                extraRequirements:
                    (get(action, 'expectedItems', []) ||
                        []).map(requirement => requirement && ({
                            title: requirement.title,
                            description:
                                requirement.description
                        })),
                currentRfpOwner: get(action, 'owner'),
                rfpStatus: get(action, 'rfpStatus'),
                selectedLawyers: loadSelectedLawyer(action),
                missionStartDate: new Date(get(action, 'missionStart', '')),
                missionEndDate: new Date(get(action, 'missionEnd', '')),
                milestonesList:
                    (get(action, 'milestones') ||
                        [
                            { message: '', expectedDate: new Date() } as IMilestone
                        ]).map(milestone => milestone && ({
                            message: milestone.message,
                            expectedDate: new Date(
                                milestone.expectedDate || '')
                        } as IMilestone)),
                describeScope:
                    get(action, 'lawyerWillDescribeTheScopeOfActions', false),
                followMyPolicy: get(
                    action, 'lawyerShouldScritclyFollowCompanyPricingPolicy'),
                declareFeeArragements:
                    get(action, 'iWantToDeclareAcceptableFeeArrangements'),
                financialsForm: get(action, 'financials') || {
                    hourlyBilling: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory,
                    contingentFee: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory,
                    fixedFee: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory,
                    percentageFee: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory,
                    retainer: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory,
                    courtAwardFee: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory,
                    valueBilling: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory,
                    taskBased: { status: ECategoryStatus.Accepted } as
                        IFinancialCategory
                } as IFinancialsForm,
                showAlternativeFee: get(action, 'showAlternativeFee') as
                    boolean,
                businessSectors: (get(action, 'businessSectors', []) ||
                    []).map(sector => ({
                        Name: sector.name,
                        id: sector.id
                    } as IBusinessSectorEntity)),
                selectedExpertises: getAllExpertises(action),
                selectedLocations: getLocations(action),
                personaliseMessageList:
                    (get(action, 'recipients', []) ||
                        []).map(lawyer => ({
                            userId: get(lawyer, 'usedId', ''),
                            message:
                                get(lawyer, 'personalizedMessage', ''),
                            conflictCheck:
                                (get(lawyer, 'conflictCheck', false) ||
                                    false)
                        } as IPersonaliseMessage)),
                companyDetails: {
                    description: get(action, 'detailsOfTheCompany.description'),
                    email: get(action, 'detailsOfTheCompany.email'),
                    companyName: get(action, 'detailsOfTheCompany.companyName'),
                    phone: get(action, 'detailsOfTheCompany.phone'),
                    id: get(action, 'detailsOfTheCompany.id')
                },
                attachments: get(action, 'attachments', []) || [],
                companyPolicyComment:
                    get(action, 'financialSection.companyPricingPolicy', '')
            };
        }

        case actionTypes.RFP_ISSUED_LIST_REQUESTED: {
            return { ...state, loadingIssuedRFP: true };
        }

        case actionTypes.RFP_LOAD_ISSUED_LIST: {
            return {
                ...state,
                loadingIssuedRFP: false,
                issuedRFPs: action.issuedRFPs as IRFPList[]
            };
        }

        case actionTypes.RFP_RECIVED_LIST_REQUESTED: {
            return { ...state, loadingRecivedProposals: true };
        }

        case actionTypes.RFP_LOAD_RECIVED_LIST: {
            return {
                ...state,
                loadingRecivedProposals: false,
                recivedProposals: action.recivedProposals as IProposalList[]
            };
        }

        case actionTypes.RFP_SUBMIT_REQUESTED: {
            return { ...state, currentRfpSubmitting: true };
        }

        case actionTypes.RFP_SUBMIT_COMPLETED: {
            return { ...state, currentRfpSubmitting: false };
        }

        case actionTypes.RFP_UPLOAD_FILE_REQUESTED: {
            return {
                ...state,
                rfpIsUploadingFile: true,
                rfpUploadingFileName: action.fileName,
                rfpUploadingFileProgress: 0
            };
        }

        case actionTypes.RFP_UPLOAD_FILE_PROGRESS_CHANGE: {
            return { ...state, rfpUploadingFileProgress: action.progress };
        }

        case actionTypes.RFP_UPLOAD_FILE_COMPLETED: {
            return {
                ...state,
                rfpIsUploadingFile: false,
                rfpUploadingFileName: undefined,
                rfpUploadingFileProgress: 0
            };
        }

        case actionTypes.RFP_DOWNLOAD_FILE_REQUESTED: {
            return {
                ...state,
                rfpCurrentlyDownloadingFiles: {
                    ...state.rfpCurrentlyDownloadingFiles,
                    [action.fileId]: true
                }
            };
        }

        case actionTypes.RFP_DOWNLOAD_FILE_COMPLETED: {
            return {
                ...state,
                rfpCurrentlyDownloadingFiles: {
                    ...state.rfpCurrentlyDownloadingFiles,
                    [action.fileId]: false
                }
            };
        }

        case actionTypes.RFP_RESET_STATE: {
            return defaultState();
        }

        default:
            return state;
    }
};
