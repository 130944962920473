import React, { FC } from 'react';
import styled from 'styled-components';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { useDropdown } from '../../../tools/hooks';
import { IState } from '../../../reducers';
import { IProfileReducer } from '../../../reducers/profileReducer';
import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';
import NotificationList from './NotificationList/NotificationList';
import { UserMenuDropdown } from './UserMenuDropdown';
import { Avatar } from '../../Common/Avatar/Avatar';

const Wrapper = styled.div`
    padding-right: 1.5rem;
    margin-left: auto;
    ${media.tablet`
        width: 100%;
        display: flex;
    `}
`;

const RightMenu = styled.div`
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    > div {
        margin: 0 0 0 1rem;
    }
`;

const MenuItem = styled.div`
    position: relative;
    cursor: pointer;
`;

const UserMenuBox = styled.div`
    position: relative;
    display: none;
    ${media.desktop`
        display: block;
    `}
`;

const ImageBox = styled.button`
    display: flex;
    align-items: center;
    outline: 0;
    cursor: pointer;
    border: 0;
    svg {
        cursor: pointer;
        margin: 0 0 0 4px;
        color: ${colorStack.darkGrey};
    }
`;

export const DesktopNavMenu: FC<React.PropsWithChildren<unknown>> = () => {
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    return (
        <>
            {!!currentUserProfile &&
                <Wrapper role="naviagation">
                    <RightMenu>
                        <MenuItem>
                            <NotificationList />
                        </MenuItem>
                        <UserMenuBox ref={wrapperRef}>
                            <ImageBox onClick={toggleDropdown}>
                                <Avatar
                                    preventRedirection={true}
                                    picture={currentUserProfile?.picture}
                                    firstName={currentUserProfile.firstName}
                                    lastName={currentUserProfile.lastName}
                                    id={currentUserProfile.id}
                                />
                                <FontAwesomeIcon icon={faCaretDown} />
                            </ImageBox>
                            {dropdownOpen && (
                                <UserMenuDropdown currentUserProfile={currentUserProfile} closeDropdown={closeDropdown} />
                            )}
                        </UserMenuBox>
                    </RightMenu>
                </Wrapper>
            }
        </>
    );
};
