export const PROPOSAL_STATE_UPDATE = 'PROPOSAL_STATE_UPDATE';
export const SAVING_PROPOSAL_REQUESTED = 'SAVING_PROPOSAL_REQUESTED';
export const SAVING_PROPOSAL_COMPLETED = 'SAVING_PROPOSAL_COMPLETED';
export const LOAD_PROPOSAL_REQUESTED = 'LOAD_PROPOSAL_REQUESTED';
export const LOAD_PROPOSAL_COMPLETED = 'LOAD_PROPOSAL_COMPLETED';
export const PROPOSAL_SUBMISSION_REQUESTED = 'PROPOSAL_SUBMISSION_REQUESTED';
export const PROPOSAL_SUBMISSION_COMPLETED = 'PROPOSAL_SUBMISSION_COMPLETED';
export const RESET_PROPOSAL_STATE = 'RESET_PROPOSAL_STATE';
export const RFP_SUMMARY_LOADING_REQUESTED = 'RFP_SUMMARY_LOADING_REQUESTED';
export const RFP_SUMMARY_LOADING_COMPLETED = 'RFP_SUMMARY_LOADING_COMPLETED';
export const PROPOSAL_UPLOAD_FILE_REQUESTED = 'PROPOSAL_UPLOAD_FILE_REQUESTED';
export const PROPOSAL_UPLOAD_FILE_PROGRESS_CHANGE = 'PROPOSAL_UPLOAD_FILE_PROGRESS_CHANGE';
export const PROPOSAL_UPLOAD_FILE_COMPLETED = 'PROPOSAL_UPLOAD_FILE_COMPLETED';
export const PROPOSAL_DOWNLOAD_FILE_REQUESTED = 'PROPOSAL_DOWNLOAD_FILE_REQUESTED';
export const PROPOSAL_DOWNLOAD_FILE_COMPLETED = 'PROPOSAL_DOWNLOAD_FILE_COMPLETED';