import styled, { css } from 'styled-components';

interface IRowProps {
    center?: boolean;
    marginBottom?: boolean;
}

export const Row = styled.div<IRowProps>`
    display: flex;
    ${({ center }) => center && css`
        align-items: center;
    `}
    ${({ marginBottom }) => marginBottom && css`
        margin-bottom: 1rem;
    `}
`;

export const Col = styled.div`
    margin-right: 1rem;
`;

interface ILegalDocLinkProps {
    icon?: string;
}

export const LegalDocLinks = styled.div`
    > ul {
        margin: 0 0 1rem 0;
        padding: 0;
        list-style: none;
    }
`;

export const LegalDocLink = styled.li<ILegalDocLinkProps>`
    display: flex;
    margin: 0.5rem 0;
    ${({ icon }) => !!icon && css`
        &:before {
            content: '';
            display: inline-block;
            margin-right: 0.8rem;
            width: 1rem;
            height: 1rem;
            background-image: url(${icon});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            vertical-align: middle;
        }
    `}
`;
