import React from 'react';

import { Icon } from '../../../entities/IGlobal';

export const Graph = (props: Icon) => (
    <svg version="1.1" width={props.width} height={props.height} {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
        <g>
            <g>
                <path d="M472.019,365.888h-14.903V347.07c0-44.556-36.249-80.803-80.804-80.803h-31.295H274.35v-69.593h44.7
       c22.045,0,39.98-17.935,39.98-39.981V59.645c0-22.045-17.935-39.981-39.98-39.981H199.623c-22.045,0-39.98,17.935-39.98,39.981
       v97.047c0,22.045,17.935,39.981,39.98,39.981h44.7v69.593h-69.666h-38.303c-44.556,0-80.802,36.248-80.802,80.803v18.818H39.981
       C17.935,365.888,0,383.823,0,405.869v46.486c0,22.045,17.935,39.98,39.981,39.98h61.166c22.045,0,39.98-17.935,39.98-39.98
       v-46.486c0-22.046-17.935-39.981-39.98-39.981H85.578V347.07c0-27.997,22.778-50.775,50.775-50.775h38.303h69.666v69.593h-15.569
       c-22.046,0-39.981,17.935-39.981,39.981v46.486c0,22.045,17.935,39.98,39.981,39.98h61.166c22.045,0,39.98-17.935,39.98-39.98
       v-46.486c0-22.046-17.935-39.981-39.98-39.981H274.35v-69.593h70.668h31.296c27.998,0,50.776,22.778,50.776,50.775v18.818h-16.237
       c-22.045,0-39.98,17.935-39.98,39.981v46.486c0,22.045,17.935,39.98,39.98,39.98h61.166c22.046,0,39.981-17.935,39.981-39.98
       v-46.486C512,383.823,494.065,365.888,472.019,365.888z M101.148,395.915c5.488,0,9.953,4.466,9.953,9.954v46.486
       c0,5.488-4.465,9.953-9.953,9.953H39.982c-5.488,0-9.954-4.465-9.954-9.953v-46.486c0-5.488,4.466-9.954,9.954-9.954H101.148z
        M289.92,395.915c5.488,0,9.953,4.466,9.953,9.954v46.486c0,5.488-4.465,9.953-9.953,9.953h-61.166
       c-5.488,0-9.954-4.465-9.954-9.953v-46.486c0-5.488,4.466-9.954,9.954-9.954H289.92z M199.623,166.647
       c-5.488,0-9.953-4.466-9.953-9.954V59.645c0-5.488,4.465-9.954,9.953-9.954h119.426c5.488,0,9.953,4.465,9.953,9.954v97.047
       c0,5.488-4.465,9.954-9.953,9.954H199.623z M481.973,452.356c0,5.488-4.466,9.953-9.954,9.953h-61.166
       c-5.488,0-9.953-4.465-9.953-9.953v-46.486c0-5.488,4.465-9.954,9.953-9.954h61.166c5.488,0,9.954,4.465,9.954,9.954V452.356z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
);