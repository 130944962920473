import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../../reducers';
import { IContextReducer } from '../../../reducers/contextReducer';
import { EUserOrigin } from '../../../entities/ISoge';
import { instanceConfig } from '../../../instance';

export const SgDownloadButton: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { pageContext: { user } } = useSelector<IState, IContextReducer>(state => state.context);

    return (
        <>
            {!(instanceConfig?.sg?.blockDownload && user.userOrigin !== EUserOrigin.LAN) && children}
        </>
    );
};
