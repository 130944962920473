import { ISGStyleParameters, IPrBdrObject, VStyle } from '../../entities/LegalDoc/ISogeStyles';
import { ISogeArticle, IContentObject } from '../../entities/ISoge';
import { isNumber } from '../generalTools';
import { arrayToObject } from '../arrayTools';

export const wJc = {
    start: 'left',
    end: 'right',
    center: 'center',
    both: 'justify',
    distribute: 'justify'
};

export const pBdr = {
    single: 'solid',
    dashDotStroked: 'dashed',
    dashed: 'dashed',
    dashSmallGap: 'dashed',
    dotDash: 'dashed',
    dotDotDash: 'dotted',
    dotted: 'dotted',
    double: 'double',
    doubleWave: 'solid',
    inset: 'inset',
    nil: 'none',
    none: 'none',
    outset: 'outset',
    thick: 'solid',
    triple: 'double',
    wave: 'solid'
};

export const getTcBdrColor = (val: string, color: string) => {
    if (val === 'nil') {
        return 'transparent';
    }
    if (color === 'auto') {
        return 'initial';
    }
    if (!!color) {
        return `#${color}`;
    }
};

export const getPrBdrCss = (size: string, style: string) => {
    return size && style && `${parseInt(size, 10) / 4}px ${pBdr[style] || 'solid'}`;
};

export const getPrBdrSize = (styleParams: ISGStyleParameters): IPrBdrObject => ({
    bottom: styleParams?.['w:pBdr.w:bottom.@w:sz'],
    left: styleParams?.['w:pBdr.w:left.@w:sz'],
    right: styleParams?.['w:pBdr.w:right.@w:sz'],
    top: styleParams?.['w:pBdr.w:top.@w:sz']
});

export const getPrBdrVal = (styleParams: ISGStyleParameters): IPrBdrObject => ({
    bottom: styleParams?.['w:pBdr.w:bottom.@w:val'],
    left: styleParams?.['w:pBdr.w:left.@w:val'],
    right: styleParams?.['w:pBdr.w:right.@w:val'],
    top: styleParams?.['w:pBdr.w:top.@w:val']
});

export const isPrBdrEqual = (a: IPrBdrObject, b: IPrBdrObject): boolean => {
    return (
        a?.bottom &&
        a?.right &&
        a?.left &&
        a?.top &&
        b?.bottom &&
        b?.right &&
        b?.left &&
        b?.top &&
        a.bottom === b.bottom &&
        a.right === b.right &&
        a.left === b.left &&
        a.top === b.top
    );
};

export const getTextTabs = (styleParams: ISGStyleParameters) => {
    const params = Object.keys(styleParams || {});
    const tabChar = params.find(paramKey => paramKey.match(/^ORDER_\d+TabChar$/));
    const breakChar = params.find(paramKey => paramKey.match(/^ORDER_\d+Break$/));
    const textOrderParam = (tabChar || breakChar) && params.find(paramKey => paramKey.match(/^ORDER_\d+Text$/));
    const tabOrder = tabChar && textOrderParam && parseInt(tabChar.split('ORDER_')?.[1]?.split('TabChar')?.[0], 10);
    const breakOrder = breakChar && textOrderParam && parseInt(breakChar.split('ORDER_')?.[1]?.split('Break')?.[0], 10);
    const textOrder = textOrderParam && parseInt(textOrderParam.split('ORDER_')?.[1]?.split('Text')?.[0], 10);
    const prependTab = isNumber(tabOrder) && isNumber(textOrder) && tabOrder <= textOrder;
    const appendTab = isNumber(tabOrder) && isNumber(textOrder) && tabOrder > textOrder;
    const prependBreak = isNumber(breakOrder) && isNumber(textOrder) && breakOrder <= textOrder;
    const appendBreak = isNumber(breakOrder) && isNumber(textOrder) && breakOrder > textOrder;

    return { prependTab, appendTab, prependBreak, appendBreak };
};

export const findContentObjectById = (article: ISogeArticle, contentObjectId: string): IContentObject => {
    const rootContentObjects = article.architextLastVersion?.contentObjects || [];

    const findObject = (contentObjects: IContentObject[]) => {
        for (let contentObject of contentObjects) {
            if (contentObject.id === contentObjectId) return contentObject;
            const childObject = contentObject.children?.length && findObject(contentObject.children);
            if (childObject) return childObject;
        }
    };

    return findObject(rootContentObjects);
};

export const parseStyleString = (styleString: string = ''): VStyle => {
    const tokens = styleString.split(';')?.filter?.(token => !!token);
    return arrayToObject(
        tokens,
        strToken => (strToken || '').split(':')[0],
        strToken => (strToken || '').split(':')[1]
    ) as unknown as VStyle;
};

export const floatingImage = 'wp:anchor';
export const inlineImage = 'wp:inline';

export const matchParamKey = (styleParams: ISGStyleParameters, matchKey: string) => Object.keys(styleParams || {}).some((param: string) => param.match(matchKey));

export const multiplyPt = (strValue: string, mult = 1.5) =>  {
    const isPt = strValue.match(/pt$/);
    if (!isPt) return strValue;

    const value = parseFloat(strValue.split('pt')[0]);
    if (isNaN(value)) return strValue;

    return `${value * mult}pt`;
};
