import React, { FC, useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../styleHelpers/fontSizes';

interface IButtonsWrapperProps {
    fullWidth?: boolean;
    direction: string;
    scrollMode?: boolean;
}

const ButtonsWrapper = styled.div<IButtonsWrapperProps>`
    display: flex;
    margin-bottom: .5rem;
    align-items: flex-end;

    ${(props) => props.fullWidth && css`
        width: 100%;
        justify-content: space-between;
    `}
    ${(props) => props.direction === 'column' && css`
        margin: 0 40px 0 0;
        flex-direction: column;
        align-items: flex-start;
    `}
    ${props => props.scrollMode && css`
        overflow: auto;
    `}
`;

interface IButtonProps {
    direction: string;
    isActive: boolean;
    fullWidth?: boolean;
    small?: boolean;
    rowButton?: boolean;
    scrollMode?: boolean;
}

const Button = styled.button<IButtonProps>`
    cursor: pointer;
    outline: 0;
    display: flex;
    justify-content: center;
    color: ${colorStack.darkGrey};
    border-bottom: 3px solid transparent;
    padding: 0.5rem 0;
    align-items: center;
    flex-direction: column;

    > img, svg {
        margin-bottom: 1rem;
        height: 21px;
    }

    + button {
        margin-left: 2rem;
    }

    &:hover {
        color: ${colorStack.darkBlue};
    }

    ${props => props.small && css`
        ${fontSizeAndHeight[10]};

        > img, svg {
            margin-bottom: 0.5rem;
        }
    `}

    ${props => props.direction === 'column' && css`
        flex-direction: row-reverse;
        padding: 12px;
        border-radius: 10px;
        background: ${colorStack.white};
        margin: 0 0 10px 0;
        border: none;
        width: 250px;
        justify-content: space-between;
        font-weight: 500;
        text-transform: uppercase;
        ${fontSizeAndHeight[13]};
        + button {
            margin-left: 0;
        }
        img, svg {
            margin-bottom: 0;
            border-left: 1px solid ${colorStack.ligthGrey};
            padding: 0 0 0 20px;
            margin: 0 0 0 20px;
            height: 17px;
            width: 40px;
        }
    `}

    ${props => (props.isActive && props.direction === 'column') && css`
        color: ${colorStack.darkBlue};
        img, svg {
            height: 17px;
        }
    `};

    ${props => props.isActive && css`
        color: ${colorStack.darkBlue};
        border-color: ${colorStack.darkBlue};
    `}

    ${props => props.direction === 'column' && css`
        + button {
            margin: 0 0 10px 0;
        }
    `}

    ${props => props.rowButton && css`
        flex-direction: row;

        img, svg {
            margin-bottom: 0;
            margin-right: 0.5rem;
        }
    `}
    ${props => props.scrollMode && css`
        white-space: nowrap;
    `}
`;

interface IProps {
    small?: boolean;
    fullWidth?: boolean;
    tabs: {
        activeIcon?: string | JSX.Element;
        notActiveIcon?: string | JSX.Element;
        title: string | React.ReactNode;
        tab: React.ReactNode;
        rowButton?: boolean;
        id?: string;
    }[];
    direction?: 'column' | 'row';
    scrollMode?: boolean;
    setCurrentTab?: number;
    forceTabById?: string;
    onTabChange?(index: number);
}

export const Tabs: FC<React.PropsWithChildren<IProps>> = ({ tabs, small, fullWidth, setCurrentTab, scrollMode, direction = 'row', forceTabById, onTabChange }) => {
    const [currentTab, setTab] = useState<number>(setCurrentTab || 0);

    const onTabClick = useCallback((index) => {
        setTab(index);
        onTabChange?.(index);
    }, [currentTab, onTabChange]);

    const forcedTab = useMemo(() => {
        return forceTabById && (tabs || []).find(tab => tab.id === forceTabById);
    }, [tabs, forceTabById]);

    return (
        <>
            <ButtonsWrapper fullWidth={fullWidth} direction={direction} scrollMode={scrollMode}>
                {(tabs || []).map((tab, index) => (
                    <Button key={index} direction={direction} onClick={() => onTabClick(index)} isActive={index === currentTab} small={small} fullWidth={fullWidth} rowButton={tab.rowButton} scrollMode={scrollMode}>
                        {(!!tab.activeIcon && index === currentTab) && (
                            <>
                                {typeof tab.activeIcon === 'string' ? (
                                    <img src={tab.activeIcon as string} />
                                ) : tab.activeIcon}
                            </>
                        )}
                        {(!!tab.notActiveIcon && index !== currentTab) && (
                            <>
                                {typeof tab.notActiveIcon === 'string' ? (
                                    <img src={tab.notActiveIcon as string} />
                                ) : tab.notActiveIcon}
                            </>
                        )}
                        {tab.title}
                    </Button>
                ))}
            </ButtonsWrapper>
            {forcedTab?.tab || (tabs[currentTab] && tabs[currentTab].tab)}
        </>
    );
};
