import { saveAs } from 'file-saver';

import { Http } from './http';
import { IState } from '../reducers';

export const action = <T, S = IState>(fn: (dispatch, getState: () => S, http: Http) => any): T => {
    return ((dispatch, getState, http) => fn(dispatch, getState, http)) as any as T;
};

// TODO: move to http, use ApiService and pass token automatically
export const downloadFile = async (url: string, fileName: string, token: string, subKey: string, body?: Object) => {
    return await fetch(url, {
        method: body ? 'POST' : 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': subKey,
            'Content-Type': 'application/json'
        },
        body: body && JSON.stringify(body)
    }).then(async res => {
        if (res.ok) {
            return await res.blob();
        } else {
            throw await res.json();
        }
    }).then(blob => {
        blob && saveAs(blob, fileName);
        return blob;
    });
};

export type IDispatch<T> = T & {
    type: string;
};
