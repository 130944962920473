import _ from 'lodash';

export const objectKeysToCamelCase = <T = unknown>(anyCaseObject): T => {
    if (!_.isObject(anyCaseObject)) {
        return anyCaseObject;
    } else if (_.isArray(anyCaseObject)) {
        return anyCaseObject.map((property) => objectKeysToCamelCase(property)) as any;
    }
    return _.reduce(anyCaseObject, (camelCaseObj, value, key) => {
        return {
            ...camelCaseObj,
            [_.camelCase(key)]: objectKeysToCamelCase(value)
        } as any;
    }, {});
};

export const removeKeyFromObject = <T extends Object>(object: T, key: keyof T | (keyof T)[]) => {
    const newObject: T = { ...object };
    const keysToRemove = key instanceof Array ? key : [key];
    keysToRemove.forEach(removeKye => {
        delete newObject[removeKye];
    });
    return newObject;
};
