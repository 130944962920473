import { rgba } from 'polished';
import { css } from 'styled-components';

import { colorStack } from './colors';
import { ECellHeight } from '../tools/dataGridTools';

// overrides ./assets/css/lcr-legacy-grid.css loaded as stylesheet in ./Views/index.html
export const gridStyles = css`
.e-grid .e-groupdroparea.e-grouped {
  background-color: ${colorStack.whiteBlue};
}

.e-grid .e-groupheadercell {
  background-color: ${colorStack.darkBlue};
}

.e-grid .e-groupheadercell:hover {
  background-color: ${colorStack.darkBlue};
}

.default-warning {
  background: ${rgba(colorStack.middleRed, .3)};
}

.cell-height-1 {
  height: ${ECellHeight.Small}px !important;
  .custom-cell-even, .custom-cell-odd {
    height: ${ECellHeight.Small}px !important;
  }
}

.cell-height-2 {
  height: ${ECellHeight.Medium}px !important;
  .custom-cell-even, .custom-cell-odd {
    height: ${ECellHeight.Medium}px !important;
  }
}

.cell-height-3 {
  height: ${ECellHeight.Tall}px !important;
  .custom-cell-even, .custom-cell-odd {
    height: ${ECellHeight.Tall}px !important;
  }
}

.cell-height-4 {
  height: ${ECellHeight.ExtraTall}px !important;
  .custom-cell-even, .custom-cell-odd {
    height: ${ECellHeight.ExtraTall}px !important;
  }
}
`;