import React, { FC } from 'react';

import { EColumnType } from '../../../../entities/IDatagrid';
import { ButtonType, ISingleRowButtonProps } from './Button';
import { ExtendedAvatar, IExtendedAvatarProps } from './ExtendedAvatar';

interface IExtendedAvatarPropsType {
    type: EColumnType.extendedAvatar;
    extendedAvatar: IExtendedAvatarProps;
}

interface IButtonPropsType {
    type: EColumnType.button;
    button: ISingleRowButtonProps;
}

type UniversalSwitcherProps = IExtendedAvatarPropsType | IButtonPropsType;

export const UniversalSwitcher: FC<UniversalSwitcherProps> = (props) => {

    const typeMapper = (type: EColumnType, data: Omit<UniversalSwitcherProps, 'type'>) => {
        switch (type) {
            case EColumnType.extendedAvatar:
                return <ExtendedAvatar {...data?.[type]} />;
            case EColumnType.button:
                return <ButtonType {...data?.[type]} />;
            default:
                // tslint:disable-next-line: no-null-keyword
                return null;
        }
    };

    return (
        <>
            {typeMapper(props.type, props)}
        </>
    );
};