import { get } from 'lodash';

import { INotification, ENotificationType, ENotificationChannel } from '../entities/INotification';

export const notificationRedirect = (notification: INotification, orgName?: string) => {
    if (notification.key === ENotificationType.ChannelMessageMentioned || notification.key === ENotificationType.SendingGift || notification.key === ENotificationType.ReceivingGift) {
        if (notification.data.ThreadId) {
            window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/overview/${notification.data.ThreadId}`);
        } else {
            window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/overview`);
        }
    }

    if (notification.key === ENotificationType.AddedToCluster) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/overview`);
    }

    if (notification.key === ENotificationType.AddMemberToOrganization) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/dashboard`);
    }

    if (notification.key === ENotificationType.CampaignSubmitted) {
        window.open(notification.data.RedirectUrl, notification.data.RedirectUrl.includes(location.host) ? '_self' : '_blank');
    }

    if (notification.key === ENotificationType.RequestAccessToCluster) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/settings?tab=Members&subTab=AccessRequest`);
    }
    if (notification.key === ENotificationType.RequestAccessToResource) {
        window.location.replace(`/orgs/${orgName}/cluster/${notification.data.ResourceId}/settings?tab=Members`);
    }

    if (notification.data.Domain === ENotificationType.Profile) {
        window.location.replace(`/profile/${notification.senderDisplayDetails.userId}`);
    }

    if (notification.data.Domain === ENotificationType.LegalscoreDetails) {
        window.location.replace(`/legalscore/${notification.receiverId}`);
    }

    if (notification.data.Domain === ENotificationType.EvaluationPage) {
        window.location.replace(`/case/evaluate?caseId=${notification.data.CaseId}&juryEmail=${notification.data.JuryEmail}`);
    }

    if (notification.data.Domain === ENotificationType.Equitis) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/quotation/${notification.data.QuotationId}`);
    }
    if ((notification.data.Domain || notification.key) === ENotificationType.ClusterDateReminder) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/overview`);
    }

    if (notification.key === ENotificationType.SharedElement) {
        if (get(notification, 'data.ItemUrl')) {
            window.open(notification.data.ItemUrl, '_blank');
        } else {
            window.location.replace('/drive?account=shared');
        }
    }

    if (notification.key === ENotificationType.ChannelMessageCreated) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/activity/${notification.data.Id}`);
    }

    if (
        notification.key === ENotificationType.RfpCreated ||
        notification.key === ENotificationType.RfpDeadlineExpired ||
        notification.key === ENotificationType.RfpChangedStatus ||
        notification.key === ENotificationType.RfpClosed ||
        notification.key === ENotificationType.RfpUpdated
    ) {
        window.location.replace('/manage-proposals');
    }

    if (
        notification.key === ENotificationType.ProposalChangedStatus ||
        notification.key === ENotificationType.ProposalCreated ||
        notification.key === ENotificationType.ProposalUpdated
    ) {
        window.location.replace('/manage-requests');
    }

    if (notification.key === ENotificationType.ConversationMembersAdded) {
        window.location.replace('/messaging');
    }

    if (notification.key === ENotificationType.ValidationRequestCreated) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ResourceId}`);
    }

    if (notification.channelId === ENotificationChannel.LegalDoc && (notification.data?.PathToBranch || notification.data?.UrlToBranch) && !notification.data?.UrlToAmendment) {
        notification.data?.PathToBranch && window.location.replace(notification.data.PathToBranch);
        notification.data?.UrlToBranch && (window.location = notification.data.UrlToBranch);
    }

    if (notification.channelId === ENotificationChannel.LegalDoc && (notification.data?.UrlToAmendment)) {
        notification.data?.UrlToAmendment && (window.location = notification.data.UrlToAmendment);
    }

    if (notification.channelId === ENotificationChannel.BackgroudOperation && (notification.data?.RedirectUrl)) {
        notification.data?.RedirectUrl !== null && (window.location = notification.data.RedirectUrl);
    }

    if (notification.key === ENotificationType.SignatureUpdated) {
        window.location.replace(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/documents?path=Documents`);
    }

    if ([ENotificationType.ValidationRequestCompleted, ENotificationType.ValidationRequestRejected, ENotificationType.ValidationRequestWithdrawn, ENotificationType.ValidationRequestNewStageStarted, ENotificationType.ValidationRequestClosedNotificationForCreatorUser as string].includes(notification.key)) {
        window.location.replace(`/orgs/${notification.data.organizationUrlName}/cluster/${notification.data.resourceId}/overview?panelType=Approval&validationId=${notification.data.validationRequestId}&clusterId=${notification.data.resourceId}`);
    }

    if ([ENotificationType.ValidatorVotedOnBehalf as string].includes(notification.key)) {
        window.location.replace(`/orgs/${notification.data.organizationUrlName}/cluster/${notification.data.clusterId}/overview?panelType=Approval&validationId=${notification.data.validationRequestId}&clusterId=${notification.data.clusterId}`);
    }
};