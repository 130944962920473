import { AnalyticsScriptName } from '../entities/IAnalytics';

export const isTrackingScriptAdded = (): boolean => !!document.querySelector(`[${AnalyticsScriptName}]`);

export const addTrackingScript = (url: string): void => {
    const trackingScript = document.createElement('script');
    const scriptTag = document.getElementsByTagName('script')[0];
    trackingScript.type = 'text/javascript';
    trackingScript.async = true;
    trackingScript.defer = true;
    trackingScript.src = url;
    trackingScript.setAttribute(AnalyticsScriptName, '1');
    scriptTag.parentNode.insertBefore(trackingScript, scriptTag);
};
