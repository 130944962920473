import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

export interface ILevel1MenuItem {
    name?: any
    imagePath?: string;
    faIcon?: IconDefinition;
    svgIcon?: ReactNode;
    link?: string;
    orgUrlName?: string;
    orgId?: string;
    fullMenu?: boolean;
    className?: string;
    onClick?(...args: unknown[]);
}

export enum EMenuItem {
    home = 'home',
    people = 'people',
    entities = 'entities',
    administration = 'administration',
    messaging = 'messaging',
    notifications = 'notifications',
    privacy = 'privacy',
    settings = 'settings',
    billing = 'billing',
    profile = 'profile',
    network = 'network',
    invite = 'invite',
    search = 'search',
    companies = 'companies',
    dashboard = 'dashboard',
    norms = 'norms',
    searchInNorms = 'searchInNorms',
    normsManagement = 'normsManagement',
    quotation = 'quotation',
    panel = 'panel',
    myrequests = 'myrequests',
    explore = 'explore',
    askQuotation = 'askQuotation',
    entitiesDashboard = 'entitiesDashboard',
    participationDashboard = 'participationDashboard',
    chart = 'chart',
    mandateDashboard = 'mandateDashboard',
    workspaceUsers = 'workspaceUsers',
    signature = 'signature',
    keyDate = 'keyDate',
    approval = 'approval',
    POA = 'POA',
    poaDashboard = 'poaDashboard',
    sponsoringDashboard = 'sponsoringDashboard',
    campaignDashboard = 'campaignDashboard',
    request = 'request',
    KnowledgeManagement = 'KnowledgeManagement',
    publications = 'publications',
    projectDashboard = 'projectDashboard',
    actionsDashboard = 'actionsDashboard',
    LPA = 'LPA',
    FAQ = 'FAQ',
    SideLetter = 'SideLetter',
    Clausier = 'Clausier',
    AllQNA = 'AllQNA',
    AllRights = 'allRights',
    CompositeSideLetter = 'compositeSideLetter',
    AllFAQ = 'AllFAQ',
    Tracker = 'Tracker',
    landLordDashboard= 'landLordDashboard',
    landlordLeaseDashboard = 'landlordLease',
    landlordLotDashboard = 'landlordLot',
    landlordOccupantDashboard = 'landlordOccupant',
    landlordOwnerDashboard = 'landlordOwner',
    instancesDashboard = 'instancesDashboard',
    tradeAssociationDashboard = 'tradeAssociationDashboard'
}
