import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { ETextComponentSubType, TextComponent } from '../../../../Common/Inputs/TextComponent';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { IValue } from '../../../../../entities/IPickers';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { getReferentials } from '../../../../../actions/globalActions';
import { DEFAULT_CURRENCY_KEY, EContextList, EReferentials, IReferencial } from '../../../../../entities/IGlobal';
import { RadioButton } from '../../../../Common/Inputs/RadioButton';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { NewRT } from '../../../../Common/RichText/NewRT';
import { DatePickerWrapper } from '../../../../Common/DatePicker/DatePicker';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

const RadioInputSection = styled.div`
    display: flex;
    label {
        margin-right: 29px;
    }
`;

export const ExpensesStep2: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [natureOptions, setNatureOptions] = useState<IReferencial[]>(undefined);
    const [unitItems, setUnitItems] = useState<IReferencial[]>(undefined);

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
    }, []);

    useEffect(() => {
        if (!eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.totalAmount?.number && unitItems) {
            const defaultCurrency = unitItems?.find((elem) => elem.Key === DEFAULT_CURRENCY_KEY);
            eApprovalContext.modifyData({
                number: undefined,
                // tslint:disable-next-line:no-null-keyword
                baseNumber: null,
                unit: {
                    key: defaultCurrency?.id,
                    parentId: defaultCurrency?.referentialIdentityId,
                    uniqueKey: defaultCurrency?.Key
                }
            }, 'totalAmount', props.context, props.stepName, props.stepNumber);
        }
    }, [unitItems, eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.totalAmount]);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', EReferentials.EventNature, EContextList.EApproval)).then(response => {
            setNatureOptions(response);
        });
        dispatch<GetReferentials>(getReferentials('', EReferentials.Currency, EContextList.Platform)).then(response => {
            setUnitItems(response);
        });
    }, []);

    const onReferentialChange = (option: IValue) => {
        eApprovalContext.modifyData(option?.data, 'nature', props.context, props.stepName, props.stepNumber);
    };

    const onTextComponentChange = (value: string) => {
        eApprovalContext.modifyData(value, 'agenceCentre', props.context, props.stepName, props.stepNumber);
    };

    const onBooleanChange = (value: number) => {
        eApprovalContext.modifyData(value, 'typeOfReception', props.context, props.stepName, props.stepNumber);
    };

    const onDateChange = (endAt: Date) => {
        const changeHour = endAt ? new Date(new Date(endAt).setHours(7)) : undefined;
        eApprovalContext.modifyData(changeHour, 'date', props.context, props.stepName, props.stepNumber);
    };

    const onCurrencyChange = (valueText: string, unit: IValue) => {
        const value = valueText.replace(',', '.').replaceAll(' ', '');
        if (/^\d+\.?\d{0,6}$/.test(value) || value === '') {
            eApprovalContext.modifyData({
                number: valueText,
                // tslint:disable-next-line:no-null-keyword
                baseNumber: null,
                unit: {
                    key: unit?.data.key,
                    parentId: unit?.data.parentId || unit?.data.referentialIdentityId,
                    uniqueKey: unit?.data.uniqueKey || unit?.data.Key
                }
            }, 'totalAmount', props.context, props.stepName, props.stepNumber);
        }
    };

    const onContextChange = (value: string) => {
        if (value !== eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.context) {
            const span = document.createElement('span');
            span.innerHTML = value;
            if (span.innerText?.trim()?.length > 0) {
                eApprovalContext.modifyData(value, 'context', props.context, props.stepName, props.stepNumber);
            } else if (span.innerText?.trim()?.length === 0) {
                eApprovalContext.modifyData('', 'context', props.context, props.stepName, props.stepNumber);
            }
        }
    };

    const onTextChange = (value: string) => {
        eApprovalContext.modifyData(value, 'placeOfEvent', props.context, props.stepName, props.stepNumber);
    };

    const onNumberChange = (value: string) => {
        const totalAmountData = eApprovalContext.data?.[EApprovalTypes.Expenses].eventDescription?.totalAmount;
        if (/^\d+\.?\d{0,6}$/.test(value) || value === '') {
            // tslint:disable-next-line:no-null-keyword
            eApprovalContext.modifyData({ number: value, unit: null }, 'numberOfParticipants', props.context, props.stepName, props.stepNumber);
        }
    };

    return (
        <Wrapper>
            <Row numberOfElements={2} noBorder>
                <div>
                    <LabelWithEllipsis required numberOfLines={3}>
                        <FormattedMessage id={`eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.typeOfReception`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.typeOfReception.yes` })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.typeOfReception === 1}
                            onClick={() => onBooleanChange(1)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.typeOfReception.no` })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.typeOfReception === 0}
                            onClick={() => onBooleanChange(0)}
                        />
                    </RadioInputSection>
                </div>
                <div></div>
            </Row>
            <Row numberOfElements={2} noBorder>
                <SelectInput
                    value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.nature?.id ?
                        [{
                            key: eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.nature?.id,
                            text: eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.nature?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.nature
                        }] : undefined}
                    onChange={onReferentialChange}
                    inputLabel={<FormattedMessage id={`eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.nature`} />}
                    required
                    options={natureOptions?.map(elem => ({
                        key: elem.id,
                        text: elem.name,
                        data: elem
                    }))}
                    type={EDropDownType.DEFAULT}
                />
                <TextComponent
                    value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.agenceCentre}
                    label={intl.formatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.agenceCentre` })}
                    onChange={onTextComponentChange}
                />
            </Row>
            <Row numberOfElements={2} noBorder>
                <DatePickerWrapper
                    required
                    label={intl.formatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.date` })}
                    value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.date}
                    onChange={onDateChange}
                />
                {unitItems?.length > 0 &&
                    <TextComponent
                        label={intl.formatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.totalAmount` })}
                        required
                        value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.totalAmount?.number}
                        onChange={onCurrencyChange}
                        subType={ETextComponentSubType.CURRENCY}
                        currencies={unitItems?.sort((a, b) => a.name.localeCompare(b.name))}
                        selectedCurrency={(eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.totalAmount?.unit?.uniqueKey && unitItems) ? {
                            key: eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.totalAmount?.unit?.uniqueKey,
                            text: unitItems?.find(elem => elem.Key === eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.totalAmount?.unit?.uniqueKey)?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.totalAmount?.unit
                        } : undefined}
                    />
                }
            </Row>
            <Row numberOfElements={2} noBorder>
                <TextComponent
                    label={intl.formatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Expenses]}.numberOfParticipants` })}
                    value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.numberOfParticipants?.number}
                    onChange={onNumberChange}
                    required
                />
                <TextComponent
                    value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.placeOfEvent}
                    label={intl.formatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Expenses]}.placeOfEvent` })}
                    onChange={onTextChange}
                />
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <NewRT
                        label={intl.formatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Expenses]}.context` })}
                        required
                        content={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.context}
                        onChange={onContextChange}
                    />
                </div>
            </Row>
        </Wrapper>
    );
};
