import React, { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { geocodeByAddress } from 'react-places-autocomplete';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { GoogleAddressViewGlobal } from '../../../../Common/GoogleAddress/GoogleAddressView';
import { GoogleAddressEditor, IAddressObject } from '../../../../Common/GoogleAddress/GoogleAddressEditor';
import { IAddressGlobal } from '../../../../../entities/IGlobal';

const Wrapper = styled.div`
    > div {
        margin: 0;
    }
`;

interface IAddressContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const AddressContent: FC<React.PropsWithChildren<IAddressContentProps>> = ({ error, data, editStake, propertieId, setEditingData }) => {
    // tslint:disable-next-line:no-null-keyword
    const [content, setContent] = useState<IAddressGlobal>(data?.value || null);

    useEffect(() => {
        // tslint:disable-next-line:no-null-keyword
        setContent(data.value || {
            number: '',
            street: '',
            city: '',
            state: '',
            country: '',
            countryShort: '',
            zipCode: ''
        });
    }, [data, editStake]);

    const changeAddress = useCallback((value: IAddressObject | string) => {
        const valueObject = typeof value === 'string' ? (data.id.match('Address') ? ({
            Number: '',
            Street: '',
            City: value,
            State: '',
            Country: '',
            CountryShort: '',
            ZipCode: ''
        }) : ({
            number: '',
            street: '',
            city: value,
            state: '',
            country: '',
            countryShort: '',
            zipCode: ''
        })) : (data.id.match('Address') ? ({
            Number: value?.street_number || '',
            Street: (value?.route || value?.neighborhood) || '',
            City: value?.locality || '',
            State: value?.administrative_area_level_1 || '',
            Country: value?.country || '',
            CountryShort: content.CountryShort || '',
            ZipCode: value?.postal_code || ''
        }) : ({
            number: value?.street_number || '',
            street: (value?.route || value?.neighborhood) || '',
            city: value?.locality || '',
            state: value?.administrative_area_level_1 || '',
            country: value?.country || '',
            countryShort: content.countryShort || '',
            zipCode: value?.postal_code || ''
        }));
        setContent(valueObject as IAddressGlobal);
        setEditingData({
            ...data,
            value: valueObject,
            id: propertieId
        });
    }, [content, setEditingData, data]);

    const selectAddress = useCallback((address: string) => {
        geocodeByAddress(address)
            .then(results => {
                const addressObject: IAddressObject = {};
                const componentForm = {
                    street_number: 'short_name',
                    route: 'long_name',
                    neighborhood: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'short_name',
                    country: 'long_name',
                    postal_code: 'short_name'
                };
                for (let i = 0; i < results[0].address_components.length; i++) {
                    let addressType = results[0].address_components[i].types[0];
                    if (componentForm[addressType]) {
                        if (addressType === 'country') {
                            if (data.id === 'Address') {
                                setContent(currentState => ({
                                    ...currentState,
                                    CountryShort: results[0].address_components[i].short_name
                                }));
                                setEditingData({
                                    ...data,
                                    value: {
                                        ...data.value,
                                        CountryShort: results[0].address_components[i].short_name
                                    }
                                });
                            } else {
                                setContent(currentState => ({
                                    ...currentState,
                                    countryShort: results[0].address_components[i].short_name
                                }));
                                setEditingData({
                                    ...data,
                                    value: {
                                        ...data.value,
                                        countryShort: results[0].address_components[i].short_name
                                    }
                                });
                            }
                        }
                        addressObject[addressType] = results[0].address_components[i][componentForm[addressType]];
                    }
                }
                changeAddress(addressObject);
            });

    }, [changeAddress, content, data]);
    return (
        <Wrapper>
            {editStake ? (
                <GoogleAddressEditor
                    onChangeAddress={(newValue: string) => changeAddress(newValue.replace(/^\s+/g, ''))}
                    onSelectAddress={selectAddress}
                    address={content}
                />
            ) : (
                <GoogleAddressViewGlobal address={content} />
            )}
        </Wrapper>
    );
};