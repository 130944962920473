import React, { useState, useCallback, useEffect, FC } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MentionItem } from 'react-mentions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeMerge, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { PageHeader } from '../Components/PageHeader';
import { ApprovalFormHeader } from '../Components/ApprovalFormHeader';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { WhiteBox } from '../../Common/WhiteBox/WhiteBox';
import { SgTable } from '../../Common/SgTable/SgTable';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { SingleApprover } from './SingleApprover';
import { StatusTag } from '../Components/StatusTag';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';
import { Popup } from '../../Common/Popup/Popup';
import { IState } from '../../../reducers';
import { IProfileReducer } from '../../../reducers/profileReducer';
import { IApprovalRequest, EApproverStatus, IApprover, ERequestStatus, IFullBranch, IIValidatorPayload } from '../../../entities/ISoge';
import { changeStatusSelectedApprover, completeApproval, editApprover, deleteApprover, addApprover, uploadApproverAttachment, downloadApproverAttachment, abandonApproval } from '../../../actions/sogeActions';
import { getOrganizationMembersSearch } from '../../../actions/organizationActions';
import { IOrganizationMember } from '../../../entities/IOrganization';
import { useAlert } from '../../../tools/hooks';
import { useNormsConfiguration } from '../../../tools/legalDocTools/sogeHooks';
import { EAlertType } from '../../../entities/IAlert';
import { isValidEmail } from '../../../tools/emailChecker';
import { typed } from '../../../tools/generalTools';
import { uuidv4 } from '../../../tools/authTools';
import { DropdownMenu } from '../../Common/Buttons/DropdownMenu';
import { NewRtView } from '../../Common/RichText/NewRtView';
import { StaticDataPicker } from '../../Common/Pickers/StaticDataPicker';
import { IValue } from '../../../entities/IPickers';

const Description = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
`;

const RichTextWrapper = styled.div`
    margin: 15px 0;
`;

const DescriptionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    > span {
        color: ${colorStack.middleGrey};
        font-size: ${fontSize[13]};
        font-weight: 400;
        margin: auto 0;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin: .5rem 0 0 0;
`;

const AddSection = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
`;

const SpinnerWrapper = styled.div`
    margin-left: .5rem;
    display: flex;
    align-items: center;
`;

const ApproveSection = styled.div`
    display: flex;
    gap: 1rem;
`;

const SectionName = styled.div`
    display: flex;
    align-items: center;
`;

const RelatedBranchWrapper = styled.div`
    margin: .2rem .5rem;
`;

const ContextMenuWrapper = styled.div`
    div {
        display:flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem
    }
`;

interface IApproveFormProps {
    branches: IFullBranch[];
    versionName: string;
    approval: IApprovalRequest;
    versionId: string;
    documentationId: string;
    organizationId: string;
    organizationUrlName: string;
    unmatchedElements?: string[];
    allRelatedBranches?: IFullBranch[];
    branchId?: string;
    consistencyFullMatch?: boolean;
    isLanguageConsistencyOn?: boolean;
    updateView();
    checkStructureBetweenBranches();
}

export const ApproveForm: FC<React.PropsWithChildren<IApproveFormProps>> = ({ updateView, documentationId, versionId, branches, versionName, approval, organizationId, organizationUrlName, unmatchedElements, allRelatedBranches, branchId, consistencyFullMatch, isLanguageConsistencyOn, checkStructureBetweenBranches }) => {
    const intl = useIntl();
    const history = useNavigate();
    const buttonStyles = {
        [EApproverStatus.Default]: intl.formatMessage({ id: 'norms.status.default' }),
        [EApproverStatus.WaitingForUpdate]: intl.formatMessage({ id: 'norms.status.waitingForUpdate' }),
        [EApproverStatus.Approved]: intl.formatMessage({ id: 'norms.status.approve' }),
        [EApproverStatus.Rejected]: intl.formatMessage({ id: 'norms.status.reject' })
    };
    const dispatch = useDispatch();
    const { name, description, createdByUser, creationDate, status, id, isMine } = approval;
    const [approvers, setApprovers] = useState<IApprover[]>([]);
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    const [comment, setComment] = useState<{ value: string; mentions: MentionItem[] }>({
        value: '',
        mentions: []
    });
    const [approversCheck, setApproversCheck] = useState<boolean>(true);
    const [addingApprover, setAddingApprover] = useState<boolean>(false);
    const [selectedPeople, setSelectedPeople] = useState<IApprover[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [pendingComplete, setPengingComplete] = useState<boolean>(false);
    const currentApprover = !isMine && approvers.find(approver => approver.id === currentUserProfile.id);
    const addAlert = useAlert();
    const { isContainersOn } = useNormsConfiguration();
    const [isCompleteApproveInitialized, setIsCompleteApproveInitialized] = useState<boolean>(false);
    const [isRejectApproveInitialized, setIsRejectApproveInitialized] = useState<boolean>(false);
    const [consistencyPopup, setConsistencyPopup] = useState<boolean>(false);
    const [isApprovalInitialized, setIsApprovalInitialized] = useState<boolean>(false);

    const onStartAddingValidators = useCallback(() => {
        setAddingApprover(state => !state);
    }, []);

    const onAddNewValidator = useCallback(() => {
        const user = (selectedPeople || []).reduce<IIValidatorPayload[]>((parsedUser, selectedUser) => [
            ...parsedUser,
            selectedUser.id.match('invite') ? { emailAddress: selectedUser.emailContact, isMandatory: true } : { id: selectedUser.id, isMandatory: true }
        ], [])?.[0];

        user && dispatch(addApprover(documentationId, versionId, id, user))
            .then(() => {
                updateView();
            });
        setAddingApprover(false);
        setSelectedPeople([]);
    }, [selectedPeople]);

    const resolvePeopleResults = useCallback((filterText: string = '') => {
        const isEmail = isValidEmail(filterText || '');

        return dispatch(getOrganizationMembersSearch(organizationId, filterText)).then((res: IOrganizationMember[]) => {
            if (!res?.length && isEmail) {
                return [{
                    text: filterText.trim(),
                    key: `invite-${uuidv4()}`,
                    data: {
                        text: filterText.trim(),
                        firstName: filterText.trim(),
                        lastName: '',
                        secondaryText: intl.formatMessage({ id: 'global.inviteByEmail' }),
                        imageUrl: '/assets/images/svgicons/mail.svg',
                        className: 'invite-email-persona',
                        id: `invite-${uuidv4()}`,
                        emailAddress: filterText.trim()
                    }
                }];
            }
            const resp = res
                .filter(user => user.id && !(approvers || []).find(approver => approver.id === user.id)?.id)
                .filter(user => !(selectedPeople || []).find(selectedPerson => selectedPerson.id === user.id)?.id)
                .map(user => ({
                    text: `${user.firstName} ${user.lastName}`,
                    key: user.id,
                    data: {
                        text: `${user.firstName} ${user.lastName}`,
                        imageUrl: user.picture,
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName
                    }
                }));
            return resp;
        });
    }, [organizationId, approvers, selectedPeople]);

    const selectPeople = useCallback((selectedUsers: IValue[]) => {
        setSelectedPeople(selectedUsers.map(user => typed<IApprover>({
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            isMandatory: true,
            emailContact: user.data.emailAddress,
            jobTitle: '',
            department: '',
            picture: user.data.imageUrl,
            cabinet: '',
            id: user.data.id
        })));
    }, []);

    useEffect(() => {
        setApprovers(approval.approvers || []);
    }, [approval]);

    useEffect(() => {
        approvers && setApproversCheck(approvers.filter(approver => approver.status !== EApproverStatus.Approved && approver.isMandatory).length !== 0);
    }, [approvers]);

    const onCommentInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>, newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => {
        setComment({
            value: e.target.value,
            mentions
        });
    }, []);

    const sendComment = useCallback(() => {
        // TODO:
    }, [comment]);

    const changeApproverStatus = useCallback((approverId: string, approverStatus: string) => {
        dispatch(changeStatusSelectedApprover(documentationId, versionId, id, approverId, { status: Number(approverStatus) })).then(() => {
            updateView();
        });
    }, [id]);

    const completeApprovalHandler = useCallback(() => {
        setPengingComplete(true);
        setIsCompleteApproveInitialized(false);
        dispatch(completeApproval(documentationId, versionId, approval.id)).then((response) => {
            if (isContainersOn) {
                setIsCompleteApproveInitialized(true);
            } else {
                addAlert(<FormattedMessage id="norms.requestCompleted" />);
            }
            updateView();
            setPengingComplete(false);
            setConsistencyPopup(false);
        }).catch((error) => {
            addAlert(
                <FormattedMessage
                    id="norms.error.somethingWentWrong"
                    values={{ error }} />,
                EAlertType.ERROR);
            setPengingComplete(false);
            setConsistencyPopup(false);
        });
    }, [isContainersOn]);

    const checkConsistencyBeforeApproval = useCallback(async () => {
        if (allRelatedBranches?.length > 1 && isLanguageConsistencyOn) {
            try {
                setPengingComplete(true);
                await checkStructureBetweenBranches();
                setIsApprovalInitialized(true);
                setPengingComplete(false);
            } catch (error) {
                addAlert(
                    <FormattedMessage
                        id="norms.error.somethingWentWrong"
                        values={{ error }} />,
                    EAlertType.ERROR);
            }
        } else {
            completeApprovalHandler();
        }
    }, [checkStructureBetweenBranches, completeApprovalHandler, consistencyFullMatch, isLanguageConsistencyOn]);

    useEffect(() => {
        if (isApprovalInitialized === true) {
            completeApprovalHandler();
            setIsApprovalInitialized(false);
        }
    }, [isApprovalInitialized]);

    const editApproverMandatory = useCallback((approverId: string, isMandatory: boolean) => {
        dispatch(editApprover(documentationId, versionId, id, approverId, isMandatory)).then(() => {
            setApprovers(currentApprovers => currentApprovers.map(approver => approver.id === approverId ? ({ ...approver, isMandatory }) : approver));
        });
    }, [approvers, documentationId, versionId, id]);

    const removeApprover = useCallback((approverId: string) => {
        dispatch(deleteApprover(documentationId, versionId, id, approverId)).then(() => {
            setApprovers(currentApprovers => currentApprovers.filter(approver => approver.id !== approverId));
        });
    }, [approvers, documentationId, versionId, id]);

    const onApprovalAbandon = useCallback(() => {
        if (!!approval?.id) {
            setPengingComplete(true);
            dispatch(abandonApproval(documentationId, versionId, approval.id))
                .then(() => {
                    setIsRejectApproveInitialized(true);
                    setPengingComplete(false);
                    updateView();
                })
                .catch(() => {
                    setPengingComplete(false);
                });
        }
    }, [approval?.id, documentationId, versionId]);

    const uploadAttachments = useCallback(async (approverId: string, files?: FileList) => {
        const uploadedFiles = Array.from(files);
        if (uploadedFiles?.length > 0 && approverId) {
            setIsUploading(true);

            try {
                await dispatch(uploadApproverAttachment(documentationId, versionId, id, approverId, uploadedFiles))
                    .then(() => {
                        updateView();
                        setIsUploading(false);
                    });
            } catch (error) {
                addAlert(
                    <FormattedMessage
                        id="norms.error.somethingWentWrong"
                        values={{ error }} />,
                    EAlertType.ERROR);
                setIsUploading(false);
            }
        }
    }, [documentationId, versionId, id]);

    const downloadAttachment = useCallback((approverId: string, fileId: string, fileName: string) => {
        dispatch(downloadApproverAttachment(documentationId, versionId, approval.id, approverId, fileId, fileName));
    }, [documentationId, versionId, approval.id]);

    const onCloseFinalizeApprovalPopup = useCallback(() => {
        history(`/orgs/${organizationUrlName}/documentation/versions/${versionId}/branches`);
    }, []);

    return (
        <>
            <PageHeader
                headerText={(
                    <>
                        <FontAwesomeIcon icon={faCodeMerge} />
                        <span>{name}</span>
                    </>
                )}
                rightCol={status === ERequestStatus.Active ? (
                    allRelatedBranches && (
                        <ApproveSection>
                            <Button
                                leftIco={faCodeMerge}
                                onClick={checkConsistencyBeforeApproval}
                                disabled={approversCheck || pendingComplete}
                            >
                                <FormattedMessage id="norms.button.completeRequest" />
                            </Button>
                            <Button leftIco={faTrashAlt} onClick={onApprovalAbandon} disabled={pendingComplete}>
                                <FormattedMessage id="norms.status.reject" />
                            </Button>
                            {pendingComplete && (
                                <SpinnerWrapper>
                                    <Spinner size={ESpinnerSize.SMALL} />
                                </SpinnerWrapper>
                            )}
                        </ApproveSection>
                    )
                ) : (
                    <StatusTag noBreak status={status} />
                )}
            />
            <WhiteBox marginBottom>
                <ApprovalFormHeader
                    branches={branches}
                    versionName={versionName}
                    organizationUrlName={organizationUrlName}
                    rightCol={(status === ERequestStatus.Active && currentApprover) && (
                        <ContextMenuWrapper>
                            <DropdownMenu
                                button={<Button>{buttonStyles[currentApprover.status]}</Button>}
                                links={Object.keys(buttonStyles).filter(key => Number(key) as EApproverStatus !== currentApprover.status).map(value => ({
                                    customRender: <Button key={value} onClick={() => changeApproverStatus(currentApprover.id, value)}>
                                        {buttonStyles[value]}
                                    </Button>,
                                    visible: true
                                }))}
                            />
                        </ContextMenuWrapper>
                    )}
                />
                <SgTable
                    noBorder
                    columns={[{
                        header:
                            <SectionName>
                                {approvers.length > 0 && <FormattedMessage id="norms.approvers" />}
                                {isUploading &&
                                    <SpinnerWrapper>
                                        <Spinner size={ESpinnerSize.SMALL} />
                                    </SpinnerWrapper>
                                }
                            </SectionName>
                    }, {
                        header: <span></span>
                    }, {
                        header: <span></span>
                    }, {
                        header: <span></span>
                    }]}
                >
                    {approvers.map(approver => (
                        <SingleApprover
                            key={approver.id}
                            approver={approver}
                            changeStatus={changeApproverStatus}
                            changeMandatory={editApproverMandatory}
                            removeApprover={removeApprover}
                            editable={isMine && status === ERequestStatus.Active}
                            uploadDocument={uploadAttachments}
                            downloadDocument={downloadAttachment}
                        />
                    ))}
                </SgTable>
                {(isMine && status === ERequestStatus.Active) &&
                    <AddSection>
                        {addingApprover &&
                            <StaticDataPicker
                                getData={resolvePeopleResults}
                                onSelectElement={selectPeople}
                            />
                        }
                        {addingApprover ?
                            <ButtonGroup>
                                <Button typeSchema={EButtonTypeSchema.TERTIARY} onClick={onStartAddingValidators}>
                                    <FormattedMessage id="global.cancel" />
                                </Button>
                                <Button onClick={onAddNewValidator} disabled={selectedPeople.length === 0} >
                                    <FormattedMessage id="global.add" />
                                </Button>
                            </ButtonGroup>
                            :
                            <div>
                                <Button typeSchema={EButtonTypeSchema.TERTIARY} onClick={onStartAddingValidators} leftIco={faPlusCircle}>
                                    <FormattedMessage id="norms.button.newApprover" />
                                </Button>
                            </div>
                        }
                    </AddSection>
                }
            </WhiteBox>
            <WhiteBox marginBottom>
                <Description>
                    <DescriptionHeader>
                        <FormattedMessage id="tempid" />
                    </DescriptionHeader>
                    <RichTextWrapper>
                        <NewRtView content={description} />
                    </RichTextWrapper>
                </Description>
            </WhiteBox>
            <Popup
                isVisible={!!isCompleteApproveInitialized}
                text={<FormattedMessage id="norms.branchCompleteApprovalStarted" />}
                cancelAction={onCloseFinalizeApprovalPopup}
                cancelButtonText={<FormattedMessage id="global.close" />}
            />
            <Popup
                isVisible={!!isRejectApproveInitialized}
                text={<FormattedMessage id="norms.branchRejectionStarted" />}
                cancelAction={onCloseFinalizeApprovalPopup}
                cancelButtonText={<FormattedMessage id="global.close" />}
            />
            {unmatchedElements && Object.keys(unmatchedElements).length > 0 && (
                <Popup
                    isVisible={consistencyPopup}
                    cancelAction={() => setConsistencyPopup(false)}
                    cancelButtonText={<FormattedMessage id="global.close" />}
                >
                    <div>
                        <FormattedMessage id="norms.branchNotConsistent" />
                        <RelatedBranchWrapper>
                            {allRelatedBranches?.filter(currBranch => currBranch?.id !== branchId)?.map((el, index) => (
                                <div key={index}>-{el?.name}</div>
                            ))}
                        </RelatedBranchWrapper>
                        <FormattedMessage id="norms.notConsistentElements" />
                        <RelatedBranchWrapper>
                            {Object.keys(unmatchedElements)?.map((el, index) => (
                                <div key={index}>{unmatchedElements[el]}</div>
                            ))}
                        </RelatedBranchWrapper>
                    </div>
                </Popup>
            )}
        </>
    );
};
