import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { EnumType } from './Enum';

export const StatusType = styled(EnumType)`
    display: inline-flex !important;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    gap: 0.8rem;
    font-weight: 500;
    background-color: var(--status-color-transparent, ${colorStack.whiteGrey});
    &::before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 1rem;
        background-color: var(--status-color, ${colorStack.ligthGrey});
    }
`;
