import styled, { css } from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';

export const Container = styled.div`
    width: 100%;
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[16]};
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 2rem;
`;

export const PrinciplesContainer = styled.div`
    margin-bottom: 1rem;
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
`;

export const TypeTitle = styled.div`
    ${fontSizeAndHeight[20]}
    font-weight: 700;
`;

export const GreyBox = styled.div<{horizontal?: boolean}>`
    background: ${colorStack.whiteGrey};
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    position: relative;
    ${props => props.horizontal && css`
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        > div {
            width: 50%;
        }
    `}
`;

export const BadgeWrapper = styled.div`
    position: absolute;
    right: .5rem;
    top: .5rem;
    z-index: 9;
    cursor: pointer;
    width: auto !important;
`;