import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';

const Content = styled.span`
    color: ${colorStack.content};
`;

export const ReferentialsType: FC<React.PropsWithChildren<unknown>> = props => {
    return (
        <Content>{props.children}</Content>
    );
};