import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import * as actionTypes from '../actions/actionTypes/menuTypes';
import { EMenuItem } from '../entities/IMenu';

export interface IMenuReducer {
    menuItem: EMenuItem;
    icon: IconDefinition;
    withHeader: boolean;
}

const defaultState = (): IMenuReducer => ({
    menuItem: EMenuItem.home,
    withHeader: false,
    icon: undefined
});

export default (state = defaultState(), action): IMenuReducer => {
    switch (action.type) {
        case actionTypes.UPDATE_MENU_TITLE: {
            const payload: actionTypes.IMenuTypes['UPDATE_MENU_TITLE'] = action;
            return {
                ...state,
                menuItem: payload.menuItem,
                withHeader: !!payload?.withHeader,
                icon: payload?.icon
            };
        }
        default: {
            return state;
        }
    }
};