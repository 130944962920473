import { INewAmendment } from '../../../../entities/ISoge';

export enum EAmendmentStep {
    Scope = 'scope',
    Check = 'check',
    Metadata = 'metadata',
    Commit = 'commit'
}

export interface IStepComponentProps {
    amendment: INewAmendment;
    organizationUrlName: string;
    branchId?: string;
    onNextStepClick();
    updateAmendment(amendment: INewAmendment | ((amendment: INewAmendment) => INewAmendment));
}
