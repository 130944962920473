import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactDom from 'react-dom';

import { getFileMetadata } from '../../../actions/clustersFilesystemActions';
import { downloadDocument } from '../../../actions/legalEntitiesActions';
import { ImageViewer } from './ImageViewer';

type DownloadDocument = ReturnType<typeof downloadDocument>;

interface IFilesystemImageViewerProps {
    imageName: string;
    entityId?: string;
    documentId?: string;
    clusterId: string;
    displayDirectUrl: string;
    currentPath: string[];
    closeViewer();
}

export const FilesystemImageViewer: FC<React.PropsWithChildren<IFilesystemImageViewerProps>> = (props) => {
    const { displayDirectUrl, clusterId, imageName, closeViewer, currentPath, entityId, documentId } = props;
    const dispatch = useDispatch();

    const downloadHandler = useCallback(() => {
        if (clusterId) {
            dispatch(getFileMetadata(clusterId, currentPath, imageName)).then(response => {
                response?.directUri && (window.location.href = response?.directUri);
            });
        } else {
            dispatch<DownloadDocument>(downloadDocument(entityId, documentId)).then(response => {
                response?.directUri && (window.location.href = response?.directUri);
            });
        }
    }, [currentPath, imageName, clusterId, entityId, documentId]);

    return ReactDom.createPortal(
        <ImageViewer
            imageName={imageName}
            onCloseClick={closeViewer}
            onDownloadClick={downloadHandler}
            image={displayDirectUrl}
        />, document.getElementById('react-app')
    );
};
