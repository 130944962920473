import * as actionTypes from '../actions/actionTypes/exploreTypes';
import { IItemResults } from '../entities/IFilters';

export interface IExploreReducer {
    items: Map<string, IItemResults>;
    facets: {[key: string]: any};
    count: number;
    itemsOnPageCount: number;
    page: number;
    size: number;
}

const defaultState = (): IExploreReducer => ({
    items: new Map<string, IItemResults>(),
    facets: undefined,
    count: undefined,
    itemsOnPageCount: undefined,
    page: undefined,
    size: undefined
});

export default (state = defaultState(), action): IExploreReducer => {
    switch (action.type) {
        case actionTypes.CLEAR_EXPLORE_ELEMENTS: {
            return {
                items: new Map<string, IItemResults>(),
                facets: undefined,
                count: undefined,
                itemsOnPageCount: undefined,
                page: undefined,
                size: undefined
            };
        }
        default: {
            return state;
        }

    }
};
