import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    ${fontSizeAndHeight[25]};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    > svg {
        color: ${colorStack.darkGrey};
    }
`;

interface IProps {
    icon?: IconDefinition;
    text: string;
}

export const MainTitle: FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <Wrapper>
            {props.icon &&
                <FontAwesomeIcon icon={props.icon} />
            }
            {props.text}
        </Wrapper>
    );
};