import { ReactNode } from 'react';

export enum EAlertType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
    DEFAULT = 'DEFAULT',
    DONE = 'DONE'
}

export enum EAlertTypeSchema {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    TERTIARY = 'TERTIARY'
}

export interface IAlert {
    id: string;
    type: EAlertType;
    content: ReactNode;
    visible?: boolean;
}
