export const GET_COMPANY_COMPLETED = 'GET_COMPANY_COMPLETED';
export const GET_COMPANY_REQUESTED = 'GET_COMPANY_REQUESTED';
export const GET_PUBLIC_COMPANIES_REQUESTED = 'GET_PUBLIC_COMPANIES_REQUESTED';
export const GET_PUBLIC_COMPANIES_COMPLETED = 'GET_PUBLIC_COMPANIES_COMPLETED';
export const GET_PRIVATE_COMPANIES_REQUESTED = 'GET_PRIVATE_COMPANIES_REQUESTED';
export const GET_PRIVATE_COMPANIES_COMPLETED = 'GET_PRIVATE_COMPANIES_COMPLETED';
export const CLEAR_LOADED_COMPANY = 'CLEAR_LOADED_COMPANY';
export const GET_COMPANY_EXPERTISES_REQUESTED = 'GET_COMPANY_EXPERTISES_REQUESTED';
export const GET_COMPANY_EXPERTISES_COMPLETED = 'GET_COMPANY_EXPERTISES_COMPLETED';
export const GET_COMPANY_ARTICLES_REQUESTED = 'GET_COMPANY_ARTICLES_REQUESTED';
export const GET_COMPANY_ARTICLES_COMPLETED = 'GET_COMPANY_ARTICLES_COMPLETED';
export const FAILED_ACTION_CREATE_COMPANY = 'FAILED_ACTION_CREATE_COMPANY';
export const SUCCESSFULL_ACTION_CREATE_COMPANY = 'SUCCESSFULL_ACTION_CREATE_COMPANY';
export const FAILED_ACTION_EDIT_COMPANY = 'FAILED_ACTION_EDIT_COMPANY';
export const SUCCESSFULL_ACTION_EDIT_COMPANY = 'SUCCESSFULL_ACTION_EDIT_COMPANY';
export const FAILED_ACTION_DELETE_COMPANY = 'FAILED_ACTION_DELETE_COMPANY';
export const SUCCESSFULL_ACTION_DELETE_COMPANY = 'SUCCESSFULL_ACTION_DELETE_COMPANY';
export const GET_FILTERED_COMPANIES_COMPLETED = 'GET_FILTERED_COMPANIES_COMPLETED';
export const GET_ALL_COMPANIES_COMPLETED = 'GET_ALL_COMPANIES_COMPLETED';
export const GET_ALL_COMPANIES_REQUESTED = 'GET_ALL_COMPANIES_REQUESTED';
export const GET_TRUSTED_COMPANIES_COMPLETED = 'GET_TRUSTED_COMPANIES_COMPLETED';
export const GET_ORGANIZATION_REQUESTED = 'GET_ORGANIZATION_REQUESTED';
export const GET_ORGANIZATION_COMPLETED = 'GET_ORGANIZATION_COMPLETED';
export const GET_PUBLIC_ORGANIZATION_REQUESTED = 'GET_PUBLIC_ORGANIZATION_REQUESTED';
export const GET_PUBLIC_ORGANIZATION_COMPLETED = 'GET_PUBLIC_ORGANIZATION_COMPLETED';
export const CLEAR_LOADED_ORGANIZATION = 'CLEAR_LOADED_ORGANIZATION';
export const GET_ORGANIZATION_EXPERTISES_REQUESTED = 'GET_ORGANIZATION_EXPERTISES_REQUESTED';
export const GET_ORGANIZATION_EXPERTISES_COMPLETED = 'GET_ORGANIZATION_EXPERTISES_COMPLETED';