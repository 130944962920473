import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    position: relative;
    > div {
        width: 21px;
        position: relative;
        margin: 0 auto;
    }
    svg {
        color: ${colorStack.middleGrey};
        ${fontSizeAndHeight[20]};
    }
    span {
        background: ${colorStack.middleGrey};
        ${fontSizeAndHeight[10]};
        padding: 3px 6px 1px 6px;
        border-radius: 50px;
        color: ${colorStack.white};
        font-weight: 500;
        position: absolute;
        right: -10px;
        top: -6px;
    }
`;

export const CommentType: FC<React.PropsWithChildren<unknown>> = props => {
    return (
        <Wrapper><div><FontAwesomeIcon icon={faMessage} /><span>{props.children}</span></div></Wrapper>
    );
};