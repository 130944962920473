import React, { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { faTag } from '@fortawesome/pro-regular-svg-icons';

import { IReferenceText, ISogeParent, ISimplifiedReferenceText, EReferenceObjectType, ISogeRefSource, ISogeArticleVersion } from '../../../../entities/ISoge';
import { IState } from '../../../../reducers';
import { ISogeReducer } from '../../../../reducers/sogeReducer';
import { getSogeArticleUrl, getSogeDocUrl, documentationMetadataLabels } from '../../../../tools/legalDocTools/sogeTools';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { Popup } from '../../../Common/Popup/Popup';
import { TagsWrapper, TagsRow, TagLabel, SectionHeader, DataRow, DataCol, DataHeader } from '../../Components/DetailsComponents';
import { TagPickerColor } from '../../../Common/TagPicker/TagPicker';
import { Tag } from '../../../Common/TagPicker/Tag';
import { LegalDocLinks, LegalDocLink } from './SharedComponents';
import { CollectionRow } from '../LevelReferenceText/CollectionRow';
import { Loader } from '../../../Common/Loader/Loader';
import { DateStandardFormat } from '../../../Common/Date/DateStandardFormat';
import { LabelWithEllipsis } from '../../../Common/Label/Label';

const referIcon = require('../../../../../wwwroot/assets/images/sogeicons/refer.svg'); // tslint:disable-line
const linkIcon = require('../../../../../wwwroot/assets/images/svgicons/link.svg'); // tslint:disable-line

const SubsectionTitle = styled.div`
    font-weight: 500;
    font-size: ${fontSize[13]}m;
`;

const metadataLabels = {
    'Individual Thematic': documentationMetadataLabels.IndividualThematics,
    'Macro-Process': documentationMetadataLabels.MacroProcessuses,
    'Applicable Regulations': documentationMetadataLabels.Regulations,
    'Scope of application (BU/SU)': documentationMetadataLabels.ScopeOfApplications,
    'Sub-thematic': documentationMetadataLabels.SubThematics,
    'Thematic': documentationMetadataLabels.Thematics
};

interface IMetadataPopupProps {
    visible: boolean;
    referenceText: ISimplifiedReferenceText | IReferenceText;
    utcDate?: {firstPublicationDateUtc: Date, publishDateOfLastModificationUtc: Date};
    book: ISogeParent;
    organizationUrlName: string;
    isCurrentVersion: boolean;
    versionId: string;
    urlVersion?: string;
    loaded: boolean;
    refersTo?: ISogeRefSource[];
    mentionedIn?: ISogeRefSource[];
    articleVersion?: ISogeArticleVersion;
    isPdfOnlyState?: boolean;
    onClose();
}

export const MetadataPopup: FC<React.PropsWithChildren<IMetadataPopupProps>> = ({ visible, versionId, isCurrentVersion, referenceText, utcDate, organizationUrlName, book, loaded, refersTo, mentionedIn, urlVersion, articleVersion, isPdfOnlyState, onClose }) => {
    const { normReferentials, documentationLcid } = useSelector<IState, ISogeReducer>(state => state.soge);
    const thematicReferentialIdentity = normReferentials?.identitiesByType?.Thematic?.[0];
    const referenceTextVersion = (referenceText as IReferenceText)?.version || articleVersion;

    return (
        <Popup
            noButtons
            titleBorder
            disableMaxWidth
            isVisible={visible}
            title={<FormattedMessage id="global.metadata" />}
            cancelAction={onClose}
            ico={faTag}
        >
            <CollectionRow
                marginBottom
                versionId={versionId}
                isCurrentVersion={isCurrentVersion}
                referenceText={referenceText}
                organizationUrlName={organizationUrlName}
                book={book}
                isPdfOnlyState={isPdfOnlyState}
            />
            <Loader label={<FormattedMessage id="global.loadingMetadata" />} loading={!loaded}>
                {!!referenceText ? (
                    <>
                        <TagsWrapper>
                            <TagsRow>
                                <LabelWithEllipsis>
                                    <FormattedMessage id={metadataLabels.Thematic.id} />
                                </LabelWithEllipsis>
                                {(referenceText?.thematics || []).map((thematic, index) => (
                                    <Tag key={thematic.id || index} tagColor={TagPickerColor.LightBlue}>
                                        {(normReferentials?.identitiesById?.[thematicReferentialIdentity?.id]?.itemById[thematic.id]?.name.find(name => name.lcid === documentationLcid)?.label) || thematic.name}
                                    </Tag>
                                ))}
                            </TagsRow>
                            {(normReferentials?.identitiesByType?.Information || []).map(referentialIdentity => {
                                const referentials = (referenceText?.details || []).find(detail => detail.referentialId === referentialIdentity.id)?.selectedValues || [];
                                const metaLabel = metadataLabels[referentialIdentity.name];

                                return (
                                    <Fragment key={referentialIdentity.id}>
                                        {!!referentials.length && (
                                            <TagsRow>
                                                <TagLabel>
                                                    {metaLabel ? (
                                                        <FormattedMessage id={metaLabel.id} />
                                                    ) : (
                                                        <>{referentialIdentity.name}</>
                                                    )}
                                                </TagLabel>
                                                {referentials.map(referential => (
                                                    <Tag key={referential.id} tagColor={TagPickerColor.LightBlue}>
                                                        {normReferentials?.allItemsById?.[referential.name]?.name?.find(ref => ref.lcid === documentationLcid)?.label || referential.name}
                                                    </Tag>
                                                ))}
                                            </TagsRow>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </TagsWrapper>
                        {!!refersTo?.length &&
                            <LegalDocLinks>
                                <SubsectionTitle>
                                    <FormattedMessage id="norms.refersTo" />
                                </SubsectionTitle>
                                <ul>
                                    {refersTo?.map(ref => ref?.objectName && (
                                        <LegalDocLink icon={linkIcon} key={ref.referenceId}>
                                            <Link data-lc="js-lc-link-refersto" to={!isPdfOnlyState && (ref?.objectType === EReferenceObjectType.Article
                                                ? getSogeArticleUrl(organizationUrlName, ref?.objectCommonId, undefined, urlVersion)
                                                : getSogeDocUrl(organizationUrlName, ref?.objectCommonId || ref?.objectCommonLevelId, urlVersion))
                                            }>
                                                {ref?.objectName}
                                            </Link>
                                        </LegalDocLink>
                                    ))}
                                </ul>
                            </LegalDocLinks>
                        }
                        {!!mentionedIn?.length && (
                            <LegalDocLinks>
                                <SubsectionTitle>
                                    <FormattedMessage id="norms.mentionedIn" />
                                </SubsectionTitle>
                                <ul>
                                    {mentionedIn.map(ref => ref?.objectName && (
                                        <LegalDocLink icon={referIcon} key={ref.referenceId}>
                                            <Link data-lc="js-lc-link-mentionedin" to={!isPdfOnlyState && (ref?.objectType === EReferenceObjectType.Article
                                                ? getSogeArticleUrl(organizationUrlName, ref?.objectCommonId, undefined, urlVersion)
                                                : getSogeDocUrl(organizationUrlName, ref?.objectCommonId || ref?.objectCommonLevelId, urlVersion))
                                            }>
                                                <FormattedMessage id="norms.articleNumber" values={{ codeNumber: ref?.objectName }} />
                                            </Link>
                                        </LegalDocLink>
                                    ))}
                                </ul>
                            </LegalDocLinks>
                        )}
                        {referenceTextVersion && (
                            <>
                                <SectionHeader>
                                    <FormattedMessage id="norms.header.dates" />
                                </SectionHeader>
                                <DataRow>
                                    {!utcDate?.publishDateOfLastModificationUtc && referenceTextVersion?.publishDateOfLastModificationUtc && !String(referenceTextVersion?.publishDateOfLastModificationUtc).match('0001') ? (
                                        <DataCol>
                                            <DataHeader>
                                                <FormattedMessage id="norms.dates.lastModification" />
                                            </DataHeader>
                                            <DateStandardFormat date={referenceTextVersion.publishDateOfLastModificationUtc} />
                                        </DataCol>
                                    ) : utcDate?.publishDateOfLastModificationUtc && !String(utcDate?.publishDateOfLastModificationUtc).match('0001') && (
                                        <DataCol>
                                            <DataHeader>
                                                <FormattedMessage id="norms.dates.lastModification" />
                                            </DataHeader>
                                            <DateStandardFormat date={utcDate?.publishDateOfLastModificationUtc} />
                                        </DataCol>
                                    )}
                                    {!utcDate?.firstPublicationDateUtc && referenceTextVersion.firstPublicationDateUtc && !String(referenceTextVersion.firstPublicationDateUtc).match('0001') ? (
                                        <DataCol>
                                            <DataHeader>
                                                <FormattedMessage id="norms.dates.publication" />
                                            </DataHeader>
                                            <DateStandardFormat date={referenceTextVersion.publicationDate} />
                                        </DataCol>
                                    ) : utcDate?.firstPublicationDateUtc && !String(utcDate?.firstPublicationDateUtc).match('0001') && (
                                        <DataCol>
                                            <DataHeader>
                                                <FormattedMessage id="norms.dates.publication" />
                                            </DataHeader>
                                            <DateStandardFormat date={utcDate?.firstPublicationDateUtc} />
                                        </DataCol>
                                    )}
                                    {referenceTextVersion?.archivingDate && !String(referenceTextVersion?.archivingDate).match('0001') && (
                                        <DataCol>
                                            <DataHeader>
                                                <FormattedMessage id="norms.dates.archiving" />
                                            </DataHeader>
                                            <DateStandardFormat date={referenceTextVersion.archivingDate} />
                                        </DataCol>
                                    )}
                                </DataRow>
                            </>
                        )}
                    </>
                ) : (
                    <LabelWithEllipsis>
                        <FormattedMessage id="global.noMetadata" />
                    </LabelWithEllipsis>
                )}
            </Loader>
        </Popup>
    );
};
