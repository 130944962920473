import React, { FC } from 'react';

import { IValue } from '../../../../entities/IPickers';
import { EDropDownType } from '../../DropDown/DropDown';
import { SelectInput } from '../../SelectInput/SelectInput';

interface IProps {
    value: IValue[];
    options: IValue[];
    type?: EDropDownType;
    action(selectedOption: IValue);
}

export const DropdownType: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <>
            {props.options &&
                <SelectInput
                    value={props.value}
                    type={props.type || EDropDownType.SEARCH}
                    onChange={(value: IValue) => props.action(value)}
                    options={props.options}
                />
            }
        </>
    );
};
