import { ISingleCluster, IClusterTemplate } from '../../entities/IClusters';
import { ClusterPermissions } from '../../entities/IPermissions';

export const GET_CLUSTERS_LIST = 'GET_CLUSTERS_LIST';
export const SET_CURRENT_CLUSTER = 'SET_CURRENT_CLUSTER';
export const GET_CURRENT_CLUSTER = 'GET_CURRENT_CLUSTER';
export const GET_INITIAL_CLUSTER_DATA = 'GET_INITIAL_CLUSTER_DATA';
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
export const SET_CURRENT_TEAM = 'SET_CURRENT_TEAM';
export const GET_ALL_MY_TEAMS = 'GET_ALL_MY_TEAMS';
export const GET_ALL_CHANNELS = 'GET_ALL_CHANNELS';
export const GET_CHANNEL_MESSAGES = 'GET_CHANNEL_MESSAGES';
export const GET_SINGLE_CHANNEL_MESSAGE = 'GET_SINGLE_CHANNEL_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const RECEIVE_THREAD_MESSAGE = 'RECEIVE_THREAD_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const CLEAR_CHANNEL_MESSAGES = 'CLEAR_CHANNEL_MESSAGES';
export const GET_CLUSTERS_SEARCH_RESULT = 'GET_CLUSTERS_SEARCH_RESULT';
export const GET_CLUSTER_DOCUMENTS = 'GET_CLUSTER_DOCUMENTS';
export const CLEAR_CLUSTER_DOCUMENTS = 'CLEAR_CLUSTER_DOCUMENTS';
export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const GET_CLUSTER_PHOTOS = 'GET_CLUSTER_PHOTOS';
export const CLUSTERS_SEARCH_LOADING = 'CLUSTERS_SEARCH_LOADING';
export const GET_AREQUEST_ACCESS_USER = 'GET_AREQUEST_ACCESS_USER';
export const SET_CURRENT_CHANNEL_NAME = 'SET_CURRENT_CHANNEL_NAME';
export const SET_CURRENT_TEAM_CHANNEL_ID = 'SET_CURRENT_TEAM_CHANNEL_ID';
export const SET_MESSAGE_PAGE = 'SET_MESSAGE_PAGE';
export const CLEAR_MESSAGE_PAGE = 'CLEAR_MESSAGE_PAGE';
export const CLEAR_CLUSTERS_LIST = 'CLEAR_CLUSTERS_LIST';
export const CHANGE_CLUSTER_PROPERTIES_VIEW = 'CHANGE_CLUSTER_PROPERTIES_VIEW';
export const CHANGE_CLUSTER_MEMBERS_VIEW = 'CHANGE_CLUSTER_MEMBERS_VIEW';
export const CHANGE_CLUSTER_DOCUMENTS_VIEW = 'CHANGE_CLUSTER_DOCUMENTS_VIEW';
export const REMOVE_CLUSTER_CHANNEL_THREAD = 'REMOVE_CLUSTER_CHANNEL_THREAD';
export const CLEAR_CLUSTER_DATA = 'CLEAR_CLUSTER_DATA';
export const UPDATE_CLUSTER_PERMISSIONS = 'UPDATE_CLUSTER_PERMISSIONS';
export const GET_CURRENT_CLUSTER_TEMPLATES = 'GET_CURRENT_CLUSTER_TEMPLATES';
export const REFRESH_APPROVAL_INFO = 'REFRESH_APPROVAL_INFO';

export interface IClusterTypes {
    GET_INITIAL_CLUSTER_DATA: {
        clusterData: ISingleCluster;
        permissions: ClusterPermissions[];
    };
    REFRESH_APPROVAL_INFO: {
        clusterData: ISingleCluster;
    };
    GET_CURRENT_CLUSTER: {
        currentClusterData: ISingleCluster;
    };
    UPDATE_CLUSTER_PERMISSIONS: {
        permissions: ClusterPermissions[];
    };
    GET_CURRENT_CLUSTER_TEMPLATES: {
        clusterTemplates: IClusterTemplate[];
    };
}
