import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { EProfileType } from '../../../entities/IGlobal';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { Avatar, EAvatarSize } from '../Avatar/Avatar';
import { Badge, EBadgeColor, EBadgeShape, EBadgeSize } from '../Badge/Badge';

export const MembersNumber = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100%;
    color: ${colorStack.content};
    display: flex;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    background: ${colorStack.ligthGrey};
    border: 1px solid ${colorStack.white};
    ${fontSizeAndHeight[10]};
`;

const Avatars = styled.div<{selectVersion: boolean}>`
    display: flex;
    margin-left: 0.5rem;
    > div {
        position: relative;
        margin-left: -7px;
        &:nth-child(1) {
            z-index: 1;
        }
        &:nth-child(2) {
            z-index: 2;
        }
        &:nth-child(3) {
            z-index: 3;
        }
        &:nth-child(4) {
            z-index: 4;
            font-size: ${fontSize[10]};
            white-space: nowrap;
            margin-left: -7px;
        }
        &:first-of-type {
            right: 0px;
        }
    }
    ${({selectVersion}) => selectVersion && css`
        align-items: center;
        > div {
            margin-left: 0;
        }
    `};
`;

const CarretBox = styled.div`
    color: ${colorStack.darkGrey};
    svg {
        margin-left: 4px;
        margin-top: -8px;
        font-size: 1.2rem;
    }
`;

interface IProps {
    members: {
        id: string;
        picture: string;
        firstName: string;
        lastName: string;
        type: EProfileType;
    }[];
    selectVersion?: boolean;
    preventRedirection?: boolean;
}

export const UsersNumber: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Avatars selectVersion={props.selectVersion} >
            {props.members?.slice(0, (props.selectVersion ? 1 : 3)).map((member) =>
                <Avatar
                    key={member.id}
                    type={member?.type}
                    picture={member?.picture}
                    id={member?.id}
                    firstName={member?.firstName}
                    lastName={member?.lastName}
                    size={props.selectVersion ? EAvatarSize.XSMALL : EAvatarSize.TINY}
                    preventRedirection={props.preventRedirection}
                />
            )}
            {(props.members?.length > 3 && !props.selectVersion) &&
                <Badge color={EBadgeColor.GRAY} size={EBadgeSize.SMALL} shape={EBadgeShape.CIRCLE} placeholder={`+${props.members.length < 102 ? props.members.length - 3 : 99}`} />
            }
            {props.selectVersion &&
                <CarretBox>
                    <FontAwesomeIcon icon={faCaretDown} />
                </CarretBox>
            }
        </Avatars>
    );
};